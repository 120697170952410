import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 8px 0;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 54px;
    border: none;
    /* background: linear-gradient(238.54deg, #ff4026 5.1%, #9025fa 102.97%); */
    background: linear-gradient(90deg, #399bff -21.2%, #09a2f9 120.18%);
    border-radius: 8px;
    color: #fff;
    font-size: 14px;
    position: relative;
    font-weight: 500;

    /* font-size: 14px;
    color: #101010;
    font-weight: 500; */

    > img {
      position: absolute;
      right: 20px;
    }

    &:disabled {
      background: #efefef;
    }
  }
`;

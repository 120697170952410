import styled from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 552;
  background: rgba(227, 229, 236, 0.65);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 90%;
  max-width: 400px;
  /* max-width: 580px; */
  /* height: 230px; */
  top: calc(50vh - (230px / 2));
  /* left: calc(50vw - (90% / 2)); */
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 552;
  position: fixed;
  box-shadow: 0px 11px 45px rgba(0, 0, 0, 0.04);
  border-radius: 16px;

  > div {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 20px 16px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      text-align: center;
      color: #000000;
    }

    > span {
      font-size: 13px;
      text-align: center;
      color: #838383;
    }
  }

  > button {
    width: 100%;
    height: 45px;
    border: none;
    background: none;
    border-top: 0.5px solid #d8dee5;
    color: #9d9d9d;
  }

  .confirm {
    color: #ff303c;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  position: relative;
  /* min-height: ${({ desktopMode }) => (desktopMode ? '80vh' : '100vh')}; */
  /* height: ${({ desktopMode }) => (desktopMode ? '80vh' : '100vh')}; */
  height: 80vh;
  /* top: calc(50vh - (575px / 2)); */
  /* left: calc(50vw - (500px / 2)); */

  @media (max-width: 720px) {
    height: 100vh;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  /* height: 50vh; */

  @media (max-width: 720px) {
    height: 100vh;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  max-width: 674px;
`;

export const Instruction = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  text-align: center;

  @media (max-width: 414px) {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 94%;
  }
`;

export const NextButton = styled.button`
  position: absolute;
  height: 50px;
  background: #2b8ff5;
  border: 1px solid #2b8ff5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  max-width: 674px;
  bottom: 16px;
  left: 16px;
  right: 16px;

  @media (max-width: 720px) {
  }

  > p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 6px;
  }

  > img {
    position: absolute;
    right: 16px;
  }
`;

import React, { useState, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import api from '../../../../services/api';

// import SearchInput from '../../../../components/SearchInput';

import {
  loadRegionsRequest,
  addCityRequest,
  addNeighborhoodRequest,
  editValueRegionRequest,
  editValueUfRequest,
  editNameCityRequest,
  editValueCityRequest,
  editNameNeighborhoodRequest,
  editValueNeighborhoodRequest,
  removeCityRequest,
  removeNeighborhoodRequest,
} from '../../../../store/modules/freight/actions';

import {
  Container,
  Body,
  DivName,
  DivText,
  DivRegion,
  InputNumber,
  DivMain,
  DivExpansive,
  DivState,
  DivCity,
  DivNeighborhood,
} from './styles';
import Header from './Header';

import RightArrowIcon from '../../../../assets/icons/freight/rightarrow.svg';
import DownArrowIcon from '../../../../assets/icons/freight/downarrow.svg';
import DeleteIcon from '../../../../assets/icons/freight/delete.svg';

function FlatRate() {
  const [expansiveId, setExpansiveId] = useState();
  const [expansive, setExpansive] = useState(false);
  const [name, setName] = useState('');

  // const [data, setData] = useState();

  const data = useSelector(state => state.freight.regions);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadRegionsRequest());

    async function load() {
      await api.get('/configfreight').then(response => {
        setName(response.data.flatrate_name_to_client);
      });
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function saveData() {
    await api.post('/updatefreight', { flatrate_name_to_client: name });
    toast.success('Informação salva');
  }

  async function addCity(regionId, ufId) {
    dispatch(addCityRequest(regionId, ufId));
  }

  async function addNeighborhood(regionId, ufId, cityId) {
    dispatch(addNeighborhoodRequest(regionId, ufId, cityId));
  }

  async function editValueRegion(oldValue, value, regionId) {
    if (oldValue !== value) {
      if (!value || value === '') {
        dispatch(editValueRegionRequest(null, regionId));
      } else dispatch(editValueRegionRequest(value, regionId));
    }
  }

  async function editValueUf(oldValue, value, ufId) {
    if (oldValue !== value) {
      if (!value || value === '') {
        dispatch(editValueUfRequest(null, ufId));
      } else dispatch(editValueUfRequest(value, ufId));
    }
  }

  async function editNameCity(oldName, name, cityId) {
    if (oldName !== name) {
      dispatch(editNameCityRequest(name, cityId));
    }
  }

  async function editValueCity(oldValue, value, cityId) {
    if (oldValue !== value) {
      if (!value || value === '') {
        dispatch(editValueCityRequest(null, cityId));
      } else dispatch(editValueCityRequest(value, cityId));
    }
  }

  async function editNameNeighborhood(oldName, name, neighborhoodId) {
    if (oldName !== name) {
      dispatch(editNameNeighborhoodRequest(name, neighborhoodId));
    }
  }

  async function editValueNeighborhood(oldValue, value, neighborhoodId) {
    if (oldValue !== value) {
      if (!value || value === '') {
        dispatch(editValueNeighborhoodRequest(null, neighborhoodId));
      } else dispatch(editValueNeighborhoodRequest(value, neighborhoodId));
    }
  }

  async function deleteCity(regionId, ufId, cityId) {
    dispatch(removeCityRequest(regionId, ufId, cityId));
  }

  async function deleteNeighborhood(regionId, ufId, cityId, neighborhoodId) {
    dispatch(removeNeighborhoodRequest(regionId, ufId, cityId, neighborhoodId));
  }

  function handleExpansive(id) {
    setExpansiveId(id);
    setExpansive(!expansive);
  }

  return (
    <Container>
      <Header title="Taxa fixa" url="/freight/configuration" />
      <Body>
        <DivName>
          <strong>Nome da entrega</strong>
          <span>
            Defina o nome que será exibido quando o cliente calcular o frete,
            por exemplo: “Motoboy”, “Delivery”, etc. Se você deixar em branco,
            irá aparecer como “Entrega padrão”.
          </span>

          <input
            type="text"
            placeholder="Ex: “Motoboy”"
            value={name}
            onChange={e => setName(e.target.value)}
            onBlur={() => saveData()}
          />
        </DivName>

        <DivText>
          <strong>Defina os valores das taxas por local</strong>
          <span>
            Deixe o valor da taxa em branco se você não quiser definir uma taxa
            para determinado local.
          </span>
        </DivText>

        {data &&
          data.map(d => (
            <DivRegion key={d.id}>
              <DivMain>
                <div className="left" onClick={() => handleExpansive(d.id)}>
                  <div>
                    {(expansiveId === d.id && expansive && (
                      <img src={DownArrowIcon} alt="arrow" />
                    )) || <img src={RightArrowIcon} alt="arrow" />}
                  </div>

                  <span>{d.regions.name}</span>
                </div>
                <div className="right">
                  <InputNumber
                    type="number"
                    placeholder="R$"
                    defaultValue={d.value}
                    onBlur={e => editValueRegion(d.value, e.target.value, d.id)}
                  />
                </div>
              </DivMain>

              {expansiveId === d.id && expansive && (
                <DivExpansive>
                  {d.ufs &&
                    d.ufs.map(u => (
                      <DivState key={u.id}>
                        <div>
                          <span>{u.Uf.name}</span>
                          <input
                            type="number"
                            placeholder="R$"
                            className="inputNumber"
                            defaultValue={u.value}
                            onBlur={e =>
                              editValueUf(u.value, e.target.value, u.id)
                            }
                          />
                        </div>
                        <button
                          type="button"
                          onClick={() => addCity(d.id, u.id)}
                        >
                          Adicionar cidade
                        </button>

                        {u.cities &&
                          u.cities.map(cit => (
                            <DivCity key={cit.id}>
                              <div>
                                <input
                                  type="text"
                                  placeholder="Cidade"
                                  defaultValue={cit.name}
                                  onBlur={e =>
                                    editNameCity(
                                      cit.name,
                                      e.target.value,
                                      cit.id
                                    )
                                  }
                                />
                                <input
                                  type="number"
                                  placeholder="R$"
                                  className="inputNumber"
                                  defaultValue={cit.value}
                                  onBlur={e =>
                                    editValueCity(
                                      cit.value,
                                      e.target.value,
                                      cit.id
                                    )
                                  }
                                />

                                <img
                                  src={DeleteIcon}
                                  alt="delete"
                                  onClick={() => deleteCity(d.id, u.id, cit.id)}
                                />
                              </div>
                              <button
                                type="button"
                                onClick={() =>
                                  addNeighborhood(d.id, u.id, cit.id)
                                }
                              >
                                Adicionar Bairro
                              </button>

                              {cit.neighborhoods &&
                                cit.neighborhoods.map(n => (
                                  <DivNeighborhood key={n.id}>
                                    <div>
                                      {/* <span>{n.name}</span> */}
                                      <input
                                        type="text"
                                        placeholder="Bairro"
                                        defaultValue={n.name}
                                        onBlur={e =>
                                          editNameNeighborhood(
                                            n.name,
                                            e.target.value,
                                            n.id
                                          )
                                        }
                                      />
                                      <input
                                        type="number"
                                        placeholder="R$"
                                        className="inputNumber"
                                        defaultValue={n.value}
                                        onBlur={e =>
                                          editValueNeighborhood(
                                            n.value,
                                            e.target.value,
                                            n.id
                                          )
                                        }
                                      />

                                      <img
                                        src={DeleteIcon}
                                        alt="delete"
                                        onClick={() =>
                                          deleteNeighborhood(
                                            d.id,
                                            u.id,
                                            cit.id,
                                            n.id
                                          )
                                        }
                                      />
                                    </div>
                                  </DivNeighborhood>
                                ))}
                            </DivCity>
                          ))}
                      </DivState>
                    ))}
                </DivExpansive>
              )}
            </DivRegion>
          ))}
      </Body>
    </Container>
  );
}

export default FlatRate;

/* eslint-disable jsx-a11y/accessible-emoji */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import publicIp from 'public-ip';
import { Link, useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import InfiniteScroll from 'react-infinite-scroll-component';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { toast } from 'react-toastify';
import LabelDomain from '~/components/LabelDomain';
import { seePlansTopbarBannerEvent } from '~/functions/events';
import NoImage from '../../../assets/icons/noimage.png';

import DragAndDropPointsIcon from '../../../assets/icons/dashboard/dragPoints.svg';

import dataPlans from '../../../data/plans.json';

// import gridIcon from '../../../assets/icons/gridIcon.svg';
// import aboutIcon from '../../../assets/icons/aboutIcon.svg';

// import gridIconDisabled from '../../../assets/icons/gridIconDisabled.svg';
// import aboutIconDisabled from '../../../assets/icons/aboutIconDisabled.svg';

import plusIcon from '../../../assets/icons/plusIcon.svg';
import addIcon from '../../../assets/icons/addIcon.svg';

import Loader from '../../../assets/icons/loader.gif';
import StockIcon from '../../../assets/icons/StockIcon';

import CancelIcon from '../../../assets/icons/cancelx-black.svg';
import CancelGreyIcon from '../../../assets/icons/cancelx.svg';

import FirstProductPopUp from '../../../components/Popups/FirstProduct';
import CopyLinkPopUp from '../../../components/Popups/CopyLink';

import ProPlanModal from '../../../components/Popups/ProPlan';

import Header from '../../../components/HeaderSandwich';
import LinkList from '../../../components/Linklist';

import TagModal from '../../../components/TagModal';
// import IndicationModal from '../../../components/IndicationModal';
import EditModal from '../../../components/EditTagPanel/Responsive';

import {
  Container,
  DivProPlan,
  LabelSubscriptionExpired,
  LabelMaintenanceMode,
  DivTabs,
  Tab,
  StyledDebounceInput,
  // DebounceInputStock,
  DivTags,
  ButtonSeeMore,
  LabelPro,
  LabelSoon,
  TagAdd,
  Tag,
  // Loader,
  DivProductButton,
  DivEmpty,
  ButtonSubmit,
  DivTabStock,
  DivLinks,
  DivStockProducts,
  DivStockProductsLeft,
  DivStockProductsRight,
  DivPreview,
  AddVariation,
  DivAddVariation,
  DivItems,
  DivItem,
  // DivMaintenance,
} from './styles';

import TabPromotion from './TabPromotion';
import TabAbout from './TabAbout';

import api from '../../../services/api';

import {
  // ListLinkPreview,
  ListProductsPaginationRequest,
  ListTagsRequest,
  UpdateOrderRequest,
  removeTagRequest,
  listPromotionRequest,
  listPaymentMethodsRequest,
  ListCountRequest,
  updateStockRequest,
  updateHasMoreSuccess,
  updatePageSuccess,
  clearStateInfiniteScrollSuccess,
} from '../../../store/modules/linkPrev/actions';
// import LabelDownloadApp from '~/components/LabelDownloadApp';

export default function DashboardResponsive({ location }) {
  const [links, setLinks] = useState([]);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showEditTagModal, setShowEditTagModal] = useState(false);
  const [showFirstProductPopUp, setShowFirstProductPopUp] = useState(false);
  const [showCopyLinkPopUp, setShowCopyLinkPopUp] = useState(false);
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [showIndicationModal, setShowIndicationModal] = useState(false);
  const [tagId, setTagId] = useState(0);
  const [masterId, setMasterId] = useState(null);
  const [indexSubCategory, setIndexSubCategory] = useState();
  const [showSeeMore, setShowSeeMore] = useState(false);
  const [search, setSearch] = useState('');
  const [indexMenu, setIndexMenu] = useState(1);
  const [pro, setPro] = useState();
  const [attributes, setAttributes] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [userConfigurations, setUserConfigurations] = useState();

  const [showEditVariationModal, setShowEditVariationModal] = useState(false);
  const [showVariationModal, setShowVariationModal] = useState(false);
  const [showAddVariantModal, setShowAddVariantModal] = useState(false);
  const [showAddSubVariantModal, setShowAddSubVariantModal] = useState(false);
  const [inputVariationName, setInputVariationName] = useState('');
  const [newSubs, setNewSubs] = useState([]);
  const [variationName, setVariationName] = useState('');
  const [variationId, setVariationId] = useState(0);

  const [disableButtonSaveVariation, setDisableButtonSaveVariation] = useState(
    false
  );

  const [subCategories, setSubCategories] = useState([]);

  const [tabAllProducts, setTabAllProducts] = useState(true);
  const [tabNoStock, setTabNoStock] = useState(false);
  const [noStockLinks, setNoStockLinks] = useState([]);
  // const [loadProducts, setLoadProducts] = useState(false);

  const history = useHistory();
  const { boot, shutdown } = useIntercom();

  const dispatch = useDispatch();
  const username = useSelector(
    state => state.user && state.user.profile && state.user.profile.username
  );

  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );

  // const profile = useSelector(state => state.user && state.user.profile);

  const whatsapp = useSelector(state => state.user.profile.whatsapp);
  const linkss = useSelector(state => state.linkPrev.links);
  const linksQuantity = useSelector(state => state.linkPrev.linksQuantity);
  const tags = useSelector(state => state.linkPrev.tags);
  // const noStockLinks = useSelector(state => state.linkPrev.noStockLinks);

  const page = useSelector(state => state.linkPrev.page);
  const totalCount = useSelector(state => state.linkPrev.totalCount);
  const count = useSelector(state => state.linkPrev.count);
  const hasMore = useSelector(state => state.linkPrev.hasMore);
  // const inputSearch = useSelector(state => state.linkPrev.inputSearch);

  async function LoadNoStock() {
    await api.get('/linksbystock').then(response => {
      setNoStockLinks(response.data);
    });
  }

  async function LoadData() {
    dispatch(ListTagsRequest());
    dispatch(listPromotionRequest());
    dispatch(listPaymentMethodsRequest());
    dispatch(ListCountRequest());

    const proplan = await api.get('/proplan');
    setPro(proplan.data);
    // window.history.replaceState(
    //   null,
    //   '',
    //   `/dashboard?subscriber=${proplan.data > 0 ? 1 : 0}`
    // );

    await api.get('/userconfigurations').then(response => {
      setUserConfigurations(response.data);
    });

    await api.get('/attributes').then(response => {
      setAttributes(response.data);
    });

    await api.get('/subscription').then(response => {
      setSubscription(response.data);
    });

    LoadNoStock();

    // const ipv4 = await publicIp.v4();

    // await api.post(`/ip/${ipv4}`);

    // console.log(await publicIp.v6());
    //= > 'fe80::200:f8ff:fe21:67cf'
  }

  useEffect(() => {
    if (linkss && linkss.length === 0) {
      const show = sessionStorage.getItem('firstproductpopup') === 'true';
      setTimeout(() => {
        setShowFirstProductPopUp(!show);
      }, 3000);
    }

    if (linkss && linkss.length === 6) {
      const show = sessionStorage.getItem('copylinkpopup') === 'true';
      setTimeout(() => {
        setShowCopyLinkPopUp(!show);
      }, 2000);
    }

    // if (linkss && linkss.length === 4) {
    //   const show = sessionStorage.getItem('indicationpopup') === 'true';
    //   setTimeout(() => {
    //     setShowIndicationModal(!show);
    //   }, 2000);
    // }

    setLinks(linkss);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkss]);

  useEffect(() => {
    LoadData();
    // boot();

    if (!whatsapp) {
      history.push('/responsivecustomizesocialmedia');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (variationId > 0) {
      setNewSubs([]);
    } else {
      setNewSubs([
        {
          id: Math.floor(Math.random() * 1000) + 1,
          name: '',
          attr_id: 0,
          isNew: true,
        },
      ]);
    }
  }, [variationId]);

  function handleLoadMoreProducts() {
    if (count === totalCount) {
      dispatch(updateHasMoreSuccess(false));

      return 0;
    }

    const currentPage = page;

    dispatch(
      ListProductsPaginationRequest(links, count, currentPage + 1, search, 0)
    );
    dispatch(updatePageSuccess(currentPage + 1));

    return 0;
  }

  useEffect(() => {
    if (location && location.tab) {
      const { tab } = location;
      setIndexMenu(tab.tab);
    }
  }, [location]);

  function LoadProducts(hasSearch) {
    dispatch(clearStateInfiniteScrollSuccess());

    if (hasSearch) {
      dispatch(
        ListProductsPaginationRequest([], 0, 1, search.toLowerCase(), 0)
      );
    } else {
      dispatch(ListProductsPaginationRequest([], 0, 1, '', 0));
    }
  }

  useEffect(() => {
    // if (search.length > 0) {
    LoadProducts(true);

    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  async function refreshIndex(linksOrdened) {
    // await api.put('/order', linksOrdened);
    dispatch(UpdateOrderRequest(linksOrdened));
    // dispatch(UpdateOrderSuccess(linksOrdened));
  }

  async function handleDelete(id) {
    try {
      await api.post(`/removeproduct/${id}`);

      if (search.length > 0) {
        setSearch('');
      } else {
        LoadProducts(false);
      }

      // refreshIndex(linksReturned.data);
      toast.success('Produto removido');
    } catch (error) {
      toast.error('Não foi possível remover o produto, verifique seus dados');
    }

    // LoadData();
  }

  async function handleBlur(id, link) {
    try {
      if (link && link.link) {
        if (link.link.indexOf('http') === -1) {
          link.link = `http://${link.link}`;
        }
      }

      await api.put(`/links/${id}`, link);
    } catch (error) {
      toast.error('Não foi possível alterar o link, verifique seus dados');
    }

    LoadData();
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const linksReordered = reorder(
      links,
      result.source.index,
      result.destination.index
    );

    setLinks(linksReordered);
    refreshIndex(linksReordered);
  }

  function onDragEndVariations(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const VariationsReordered = reorder(
      newSubs,
      result.source.index,
      result.destination.index
    );

    setNewSubs(VariationsReordered);
  }

  function handleClickProduct(id) {
    history.push(`/productregister/${id}`);
  }

  function handleClickTagModal(id) {
    // setDivTagId('');
    setTagId(id);
    setShowEditTagModal(false);

    setTimeout(() => {
      setShowTagModal(!showTagModal);
    }, 500);
  }

  async function handleClickVariationModal(id) {
    // setDivTagId('');
    // setVariationId(id);

    await api.get(`/attributesbyid/${id}`).then(response => {
      setVariationId(response.data.id);
      setVariationName(response.data.name);
      setInputVariationName(response.data.name);
    });

    await api.get(`/subattributes/${id}`).then(response => {
      const arr = [...response.data];

      setNewSubs(arr);
    });

    setShowEditVariationModal(false);

    setTimeout(() => {
      setShowAddSubVariantModal(!showAddSubVariantModal);
    }, 500);
  }

  function handleClickEditTagModal(id, master_id, index) {
    setTagId(id);

    if (master_id) {
      setMasterId(master_id);
      setIndexSubCategory(index);
    }

    setTimeout(() => {
      setShowTagModal(!showTagModal);
      // setShowEditTagModal(!showEditTagModal);
    }, 500);
  }

  function handleClickEditVariationModal(id) {
    setVariationId(id);

    setTimeout(() => {
      setShowEditVariationModal(!showEditVariationModal);
    }, 500);
  }

  function tagModal(modal) {
    setTagId(0);
    setShowTagModal(modal);
  }

  function editTagModal(modal) {
    setShowEditTagModal(modal);
  }

  function editAttributeModal(modal) {
    setShowEditVariationModal(modal);
  }

  function productPopUp(modal) {
    setShowFirstProductPopUp(modal);
  }

  function LinkPopUp(modal) {
    setShowCopyLinkPopUp(modal);
  }

  function modalIndication(modal) {
    setShowIndicationModal(modal);
  }

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  function clearFields() {
    setNewSubs([{ id: 0, name: '', attr_id: 0, isNew: true }]);
    setInputVariationName('');
    setVariationId(0);
    setVariationName('');
  }

  function closeModalAddVariation() {
    setShowAddVariantModal(false);
    setShowAddSubVariantModal(false);
    clearFields();
  }

  async function handleClickDeleteVariation(id) {
    await api.delete(`/attributes/${id}`);

    const array = attributes && attributes.filter(att => att.id !== id);

    setAttributes(array);

    closeModalAddVariation();
  }

  // costante criada para remover a tag "Todos" da seleção
  const tagNoAll = useMemo(
    () => tags && tags.filter(tag => tag.tag !== 'Todos'),
    [tags]
  );

  async function handleAlterStock(productId, amount, variationid, index) {
    // dispatch(updateStockRequest(productId, amount, variationid));

    const array = [...noStockLinks];
    array[index].stock_quantity = amount >= 0 ? amount : 0;
    setNoStockLinks(array);

    await api.put(`linksfield/${productId}`, {
      stock_quantity: amount,
      variationId: variationid,
    });
  }

  // async function handleDeleteVariation(id) {
  //   await api.delete(`/attributes/${id}`);

  //   const array = attributes && attributes.filter(att => att.id !== id);

  //   setAttributes(array);
  // }

  async function handleDeleteSub(sub, index) {
    if (!sub.isNew) {
      await api.delete(`/subattributes/${sub.id}`);
    }

    const array = newSubs.filter((ns, ind) => ind !== index);

    setNewSubs(array);
  }

  function handleBlurSub(sub, index, text) {
    const array = [...newSubs];

    const existsName = newSubs.filter(
      (ns, ind) => ns.name === text && ind !== index
    );

    if (existsName.length > 0) {
      handleDeleteSub(sub, index);
    } else {
      array[index].name = text;

      setNewSubs(array);
    }
  }

  async function handleSubmitVariation() {
    try {
      setDisableButtonSaveVariation(true);

      if (!inputVariationName) {
        toast.error('Preencha o nome da variação');
        setDisableButtonSaveVariation(false);
        return 0;
      }

      const attribute = await api.post('/attributes', {
        name: inputVariationName,
      });

      if (attribute && attribute.data) {
        await Promise.all(
          newSubs &&
            newSubs.map(async ns => {
              if (ns.name) {
                await api.post('/subattributes', {
                  name: ns.name,
                  attribute_id: attribute.data.id,
                });
              }
            })
        );

        setAttributes([...attributes, attribute.data]);
      }

      setDisableButtonSaveVariation(false);
      closeModalAddVariation();
      return 0;
    } catch (err) {
      setDisableButtonSaveVariation(false);
      return 0;
    }
  }

  function updateAttributes(name) {
    const array = [...attributes];

    const findIndex = array.findIndex(attr => attr.id === variationId);

    if (findIndex >= 0) {
      array[findIndex].name = name;

      setAttributes(array);
    }
  }

  async function handleSubmitEditVariation() {
    try {
      setDisableButtonSaveVariation(true);
      if (!inputVariationName) {
        toast.error('Preencha o nome da variação');
        setDisableButtonSaveVariation(false);
        return 0;
      }

      const attribute = await api.put(`/attributes/${variationId}`, {
        name: inputVariationName,
      });

      if (attribute && attribute.data) {
        await Promise.all(
          newSubs &&
            newSubs.map(async (ns, index) => {
              if (ns.name) {
                if (ns.isNew) {
                  await api.post('/subattributes', {
                    name: ns.name,
                    attribute_id: variationId,
                    order: index,
                  });
                } else {
                  await api.put(`/subattributes/${ns.id}`, {
                    name: ns.name,
                    order: index,
                  });
                }
              }
            })
        );

        // setAttributes([...attributes, attribute.data]);
      }

      updateAttributes(inputVariationName);
      setDisableButtonSaveVariation(false);
      closeModalAddVariation();

      return 0;
    } catch (err) {
      setDisableButtonSaveVariation(false);
      return 0;
    }
  }

  // async function handleSubmitSubVariation() {
  //   if (variationId > 0) {
  //     await Promise.all(
  //       newSubs &&
  //         newSubs.map(async ns => {
  //           if (ns.id === 0 && ns.name) {
  //             await api.post('/subattributes', {
  //               name: ns.name,
  //               attribute_id: variationId,
  //             });
  //           }
  //         })
  //     );
  //   }

  //   closeModalAddVariation();

  //   return 0;
  // }

  async function handleSearchSubCategories(id, isSub, index) {
    if (
      subCategories &&
      subCategories[0] &&
      subCategories[0][0] &&
      subCategories[0][0].master_id === id
    ) {
      setSubCategories([]);
      return 0;
    }

    if (
      isSub &&
      subCategories &&
      subCategories[index + 1] &&
      subCategories[index + 1][0] &&
      subCategories[index + 1][0].master_id === id
    ) {
      const array = [...subCategories];
      const newArray = array.slice(0, index + 1);
      setSubCategories(newArray);
      return 0;
    }

    await api
      .post(`/subcategoriesbytag/${id}`, { user_id: userId })
      .then(response => {
        if (response.data) {
          if (isSub) {
            const array = [...subCategories];
            const newArray = array.slice(0, index + 1);

            newArray.push(response.data);
            setSubCategories(newArray);
          } else {
            const array = [];
            array.push(response.data);
            setSubCategories(array);
          }
        }
      });

    return 0;
  }

  function handleInsertNewSub() {
    const existsEmptyName = newSubs.filter(n => n.name === '');

    if (existsEmptyName.length === 0) {
      setNewSubs([
        {
          id: Math.floor(Math.random() * 1000) + 1,
          name: '',
          attr_id: 0,
          isNew: true,
        },
        ...newSubs,
      ]);
    }
  }

  function updateTagsComponent(master_id) {
    if (master_id) {
      let index;
      let isSub = false;

      for (let i = 0; i < subCategories.length; i++) {
        if (subCategories[i].find(c => c.id === master_id)) {
          index = i;
          isSub = true;

          break;
        }
      }

      if (subCategories && subCategories[0] && subCategories[0].length > 0) {
        handleSearchSubCategories(master_id, isSub, index || null);
      }
    }

    dispatch(ListTagsRequest());
  }

  async function handleClickDelete(id) {
    // setShowEditTagModal(false);
    setShowTagModal(false);
    dispatch(removeTagRequest(id));

    if (masterId) {
      const array = [...subCategories];
      const findIndex = array[indexSubCategory].findIndex(arr => arr.id === id);

      if (findIndex > -1) {
        // Verifica se existe alguma subcategoria aberta abaixo da que está sendo deletada

        if (array.length > indexSubCategory + 1) {
          // Verifica se a subcategoria de baixo é descendente da categoria que está sendo deleteda
          if (
            array[indexSubCategory][findIndex].id ===
            array[indexSubCategory + 1][0].master_id
          ) {
            array.splice(indexSubCategory + 1, array.length);
          }
        }

        array[indexSubCategory].splice(findIndex, 1);

        setSubCategories(array);
      }
    } else {
      setSubCategories([]);
    }
  }

  async function handleClickCreatePortalSession() {
    if (subscription && subscription.customer_id) {
      await api
        .post('/createportalsession', {
          customerId: subscription.customer_id,
        })
        .then(response => {
          window.location.href = response.data.url;
        });
    }
  }

  function revalidateStock() {
    const stock =
      noStockLinks && noStockLinks.filter(s => s.stock_quantity === 0);

    setNoStockLinks(stock);
  }

  return (
    <>
      <ProPlanModal showModal={showProPlanPopUp} Modal={ProPlanPopUp} />
      <TagModal
        showModal={showTagModal}
        Modal={tagModal}
        TagId={tagId}
        updateTagsComponent={updateTagsComponent}
        handleClickDelete={handleClickDelete}
        pro={pro}
        userId={userId}
      />

      <EditModal
        showModal={showEditTagModal}
        Modal={editTagModal}
        handleClickTagModal={handleClickTagModal}
        handleClickDelete={handleClickDelete}
        id={tagId}
        label="categoria"
      />

      <EditModal
        showModal={showEditVariationModal}
        Modal={editAttributeModal}
        handleClickTagModal={handleClickVariationModal}
        handleClickDelete={handleClickDeleteVariation}
        id={variationId}
        label="variação"
      />

      <FirstProductPopUp
        showModal={showFirstProductPopUp}
        Modal={productPopUp}
        handleClickProduct={handleClickProduct}
      />

      <CopyLinkPopUp
        showModal={showCopyLinkPopUp}
        Modal={LinkPopUp}
        username={username}
      />

      {/* <IndicationModal
        showModal={showIndicationModal}
        Modal={modalIndication}
        returnIndications={false}
        username={username}
      /> */}

      <Container
        fixed={showTagModal || showAddVariantModal || showAddSubVariantModal}
      >
        {/* {pro >= 1 &&
          (navigator.userAgent.match(/iPhone/i) ||
            navigator.userAgent.match(/iPad/i)) && <LabelDownloadApp />} */}

        {pro === 0 && (
          <DivProPlan>
            <header>
              <strong>Plano {dataPlans.pro.name}</strong>

              <div>{dataPlans.pro.price.yearly.economyPercent} OFF</div>
            </header>

            <div>
              <strong>R$ {dataPlans.pro.price.yearly.price}</strong>
              <span>R$ {dataPlans.pro.price.yearly.oldPrice}</span>
            </div>

            <button
              type="button"
              onClick={() => {
                seePlansTopbarBannerEvent();
                history.push('/pro');
              }}
            >
              <span>Quero ser {dataPlans.pro.name}</span>
            </button>
          </DivProPlan>
        )}

        {pro >= 1 &&
          subscription &&
          subscription.payment_status === 'past_due' && (
            <LabelSubscriptionExpired>
              <strong>Assinatura vencida</strong>
              <span>
                Sua assinatura está vencida, pois seu último pagamento falhou,
                atualize sua forma de pagamento
              </span>

              {/* <button type="button" onClick={handleClickCreatePortalSession}>
                Ver detalhes
              </button> */}
            </LabelSubscriptionExpired>
          )}

        {userConfigurations && userConfigurations.maintenance && (
          <LabelMaintenanceMode>
            <span>Você está com o modo manutenção ativado</span>
          </LabelMaintenanceMode>
        )}

        {/* subscription */}

        <Header
          boot={boot}
          shutdown={shutdown}
          productQuantity={links && links.length}
        />
        {/* <DivTabs>
          <ul>
            <li>
              <Tab
                className={indexMenu === 1 ? 'selected' : ''}
                onClick={() => setIndexMenu(1)}
              >
                <img
                  src={indexMenu === 1 ? gridIcon : gridIconDisabled}
                  alt="grid-icon"
                />
              </Tab>
            </li>

            <li>
              <Tab
                className={indexMenu === 3 ? 'selected' : ''}
                onClick={() => setIndexMenu(3)}
              >
                <img
                  src={indexMenu === 3 ? aboutIcon : aboutIconDisabled}
                  alt="about-icon"
                />
              </Tab>
            </li>
          </ul>
        </DivTabs> */}

        {/* {indexMenu === 1 ? ( */}
        <>
          <DivTags first>
            <span>Categorias</span>
            <div className="div-tags">
              <TagAdd onClick={() => handleClickTagModal()}>
                <img src={plusIcon} alt="plus" />
              </TagAdd>
              {tagNoAll &&
                tagNoAll.map(tag => (
                  <div className="div-internal-tag" key={tag.id}>
                    <Tag
                      key={tag.id}
                      selected={
                        !!(
                          subCategories &&
                          subCategories[0] &&
                          subCategories[0][0] &&
                          subCategories[0][0].master_id === tag.id
                        )
                      }
                    >
                      <div
                        className="body-tag"
                        onClick={() =>
                          handleSearchSubCategories(tag.id, false, null)
                        }
                      >
                        {tag.tag}
                      </div>

                      <div
                        onClick={() =>
                          handleClickEditTagModal(tag.id, null, null)
                        }
                      >
                        <svg
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.32139 1.45958C9.46709 1.31387 9.64007 1.19829 9.83044 1.11944C10.0208 1.04059 10.2248 1 10.4309 1C10.637 1 10.841 1.04059 11.0314 1.11944C11.2217 1.19829 11.3947 1.31387 11.5404 1.45958C11.6861 1.60528 11.8017 1.77826 11.8806 1.96863C11.9594 2.159 12 2.36304 12 2.5691C12 2.77515 11.9594 2.97919 11.8806 3.16956C11.8017 3.35993 11.6861 3.53291 11.5404 3.67861L4.05117 11.1679L1 12L1.83214 8.94883L9.32139 1.45958Z"
                            stroke={
                              subCategories &&
                              subCategories[0] &&
                              subCategories[0][0] &&
                              subCategories[0][0].master_id === tag.id
                                ? '#FFFFFF'
                                : '#9D9D9D'
                            }
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </Tag>
                  </div>
                ))}
            </div>
          </DivTags>

          {subCategories && subCategories[0] && subCategories[0].length > 0 && (
            <>
              {subCategories.map((categories, index) => (
                <>
                  {categories && categories[0] && (
                    <DivTags>
                      <span className="subcategorias">
                        Subcategorias em "{categories[0].master.value}"{' '}
                      </span>
                      <div className="div-tags">
                        {categories.map((tag, tagIndex) => (
                          <div className="div-internal-tag" key={tag.id}>
                            <Tag
                              key={tag.id}
                              selected={
                                !!(
                                  subCategories &&
                                  subCategories[index + 1] &&
                                  subCategories[index + 1][0] &&
                                  subCategories[index + 1][0].master_id ===
                                    tag.id
                                )
                              }
                              index={tagIndex}
                            >
                              <div
                                className="body-tag"
                                onClick={() =>
                                  handleSearchSubCategories(tag.id, true, index)
                                }
                              >
                                {tag.tag}
                              </div>

                              <div
                                onClick={() =>
                                  handleClickEditTagModal(
                                    tag.id,
                                    tag.master_id,
                                    index
                                  )
                                }
                              >
                                <svg
                                  width="13"
                                  height="13"
                                  viewBox="0 0 13 13"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.32139 1.45958C9.46709 1.31387 9.64007 1.19829 9.83044 1.11944C10.0208 1.04059 10.2248 1 10.4309 1C10.637 1 10.841 1.04059 11.0314 1.11944C11.2217 1.19829 11.3947 1.31387 11.5404 1.45958C11.6861 1.60528 11.8017 1.77826 11.8806 1.96863C11.9594 2.159 12 2.36304 12 2.5691C12 2.77515 11.9594 2.97919 11.8806 3.16956C11.8017 3.35993 11.6861 3.53291 11.5404 3.67861L4.05117 11.1679L1 12L1.83214 8.94883L9.32139 1.45958Z"
                                    stroke={
                                      subCategories &&
                                      subCategories[index + 1] &&
                                      subCategories[index + 1][0] &&
                                      subCategories[index + 1][0].master_id ===
                                        tag.id
                                        ? '#FFFFFF'
                                        : '#9D9D9D'
                                    }
                                    strokeWidth="1.25"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              </div>
                            </Tag>
                          </div>
                        ))}
                      </div>
                    </DivTags>
                  )}
                </>
              ))}
            </>
          )}

          {showSeeMore && (
            <DivTags>
              <div>
                <span>Variações</span>
                {pro === 0 && (
                  <LabelPro>
                    <span>PRO</span>
                  </LabelPro>
                )}

                {/* <LabelSoon>
                    <span>Em poucos dias</span>
                  </LabelSoon> */}
              </div>

              <div className="div-tags">
                <TagAdd
                  onClick={
                    pro >= 1
                      ? () => setShowAddVariantModal(true)
                      : () => setShowProPlanPopUp(true)
                  }
                >
                  <img src={plusIcon} alt="plus" />
                </TagAdd>

                {attributes &&
                  attributes.map(att => (
                    <div className="div-internal-tag" key={att.id}>
                      <Tag>
                        <div className="body-tag">{att.name}</div>

                        <div
                          onClick={
                            pro >= 1
                              ? // ? () => handleClickEditVariationModal(att.id)
                                () => handleClickVariationModal(att.id)
                              : () => setShowProPlanPopUp(true)
                          }
                        >
                          <svg
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M9.32139 1.45958C9.46709 1.31387 9.64007 1.19829 9.83044 1.11944C10.0208 1.04059 10.2248 1 10.4309 1C10.637 1 10.841 1.04059 11.0314 1.11944C11.2217 1.19829 11.3947 1.31387 11.5404 1.45958C11.6861 1.60528 11.8017 1.77826 11.8806 1.96863C11.9594 2.159 12 2.36304 12 2.5691C12 2.77515 11.9594 2.97919 11.8806 3.16956C11.8017 3.35993 11.6861 3.53291 11.5404 3.67861L4.05117 11.1679L1 12L1.83214 8.94883L9.32139 1.45958Z"
                              stroke="#9D9D9D"
                              strokeWidth="1.25"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </Tag>
                    </div>
                  ))}
              </div>
            </DivTags>
          )}

          {(showSeeMore && (
            <ButtonSeeMore type="button" onClick={() => setShowSeeMore(false)}>
              Menos opções
            </ButtonSeeMore>
          )) || (
            <ButtonSeeMore type="button" onClick={() => setShowSeeMore(true)}>
              Mais opções
            </ButtonSeeMore>
          )}
          <DivProductButton>
            <span>Produtos</span>

            <Link to="/productregister/0">
              <ButtonSubmit>Adicionar produto</ButtonSubmit>
            </Link>

            {linksQuantity > 0 && (
              <div className="div-serch-input">
                <StyledDebounceInput
                  placeholder="Pesquisar produtos"
                  minLength={1}
                  debounceTimeout={500}
                  onChange={e => setSearch(e.target.value)}
                  value={search}
                />
              </div>
            )}

            {noStockLinks && noStockLinks.length > 0 && (
              <DivTabStock>
                <div
                  onClick={() => {
                    setTabAllProducts(true);
                    setTabNoStock(false);
                    dispatch(ListProductsPaginationRequest());
                    revalidateStock();
                  }}
                >
                  <strong className={tabAllProducts && 'active'}>
                    Todos os produtos
                  </strong>
                </div>
                <div
                  onClick={() => {
                    setTabNoStock(true);
                    setTabAllProducts(false);
                  }}
                >
                  <strong className={tabNoStock && 'active'}>
                    Sem estoque
                  </strong>

                  {noStockLinks && noStockLinks.length > 0 && (
                    <div className="notification">
                      <strong>{noStockLinks.length}</strong>
                    </div>
                  )}
                </div>
              </DivTabStock>
            )}
          </DivProductButton>

          {(links && links.length > 0 && (
            <>
              {(tabAllProducts && (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <DivLinks
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <InfiniteScroll
                          dataLength={count} // This is important field to render the next data
                          next={handleLoadMoreProducts}
                          hasMore={hasMore}
                          loader={
                            <div className="loader">
                              <img src={Loader} alt="loader" />
                            </div>
                          }
                        >
                          {links &&
                            links.map((linkk, index) => (
                              <Draggable
                                key={String(linkk.id)}
                                draggableId={String(linkk.id)}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="div-linklist"
                                  >
                                    <LinkList
                                      key={String(linkk.id)}
                                      linkk={linkk}
                                      deleteLink={handleDelete}
                                      Blur={handleBlur}
                                      index={index}
                                      handleClickModal={handleClickProduct}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </InfiniteScroll>
                      </DivLinks>
                    )}
                  </Droppable>
                </DragDropContext>
              )) || (
                <DivStockProducts>
                  {noStockLinks &&
                    noStockLinks.map((p, index) => (
                      <div key={p.key}>
                        <img
                          src={
                            (p.images &&
                              p.images[0] &&
                              p.images[0].image_url) ||
                            (p.Link &&
                              p.Link.images &&
                              p.Link.images[0] &&
                              p.Link.images[0].image_url) ||
                            NoImage
                          }
                          alt="logo"
                        />
                        <DivStockProductsLeft
                          onClick={() => handleClickProduct(p.id)}
                        >
                          <span>{p.title || p.Link.title}</span>

                          <div>
                            {p.label ? (
                              <strong>{p.label}</strong>
                            ) : (
                              <strong>{p.promotion_price || p.price}</strong>
                            )}

                            {/* {p.stock_quantity === 0 ? (
                                <strong className="red-stock">
                                  Sem estoque
                                </strong>
                              ) : (
                                <strong>{p.promotion_price || p.price}</strong>
                              )} */}

                            {/* {p.stock_quantity !== null && (
                                <>
                                  {p.stock_quantity === 1 && (
                                    <>
                                      <StockIcon color="#FF6C4B" />
                                      <span className="red-stock">
                                        {p.stock_quantity}
                                      </span>
                                    </>
                                  )}

                                  {p.stock_quantity === 2 && (
                                    <>
                                      <StockIcon color="#ffb525" />
                                      <span className="yellow-stock">
                                        {p.stock_quantity}
                                      </span>
                                    </>
                                  )}

                                  {p.stock_quantity > 2 && (
                                    <>
                                      <StockIcon color="#9D9D9D" />
                                      <span>{p.stock_quantity}</span>
                                    </>
                                  )}
                                </>
                              )} */}
                          </div>
                        </DivStockProductsLeft>

                        <DivStockProductsRight>
                          <button
                            type="button"
                            onClick={() =>
                              handleAlterStock(
                                p.id,
                                parseInt(p.stock_quantity, 10) - 1,
                                p.grid_attribute_id || null,
                                index
                              )
                            }
                          >
                            -
                          </button>
                          <input
                            type="text"
                            className={p.stock_quantity === 0 ? 'no-stock' : ''}
                            value={p.stock_quantity}
                          />
                          <button
                            type="button"
                            onClick={() =>
                              handleAlterStock(
                                p.id,
                                parseInt(p.stock_quantity, 10) + 1,
                                p.grid_attribute_id || null,
                                index
                              )
                            }
                          >
                            +
                          </button>
                        </DivStockProductsRight>
                      </div>
                    ))}
                </DivStockProducts>
              )}
            </>
          )) || (
            <>
              {linksQuantity === 0 && (
                <DivEmpty>
                  <button
                    type="button"
                    className="button-icon"
                    onClick={() => handleClickProduct(0)}
                  >
                    <img src={addIcon} alt="add" />
                  </button>

                  <span>Você ainda não adicionou nenhum produto.</span>
                  <button type="button" onClick={() => handleClickProduct(0)}>
                    Adicionar primeiro produto
                  </button>
                </DivEmpty>
              )}
            </>
          )}

          {linksQuantity > 0 && <LabelDomain />}
        </>
        {/* ) : ( */}
        {/* '' */}
        {/* )} */}
        {/* {indexMenu === 2 ? <TabPromotion /> : ''} */}
        {/* {indexMenu === 3 ? <TabAbout /> : ''} */}

        {showAddVariantModal && (
          <AddVariation>
            <div className="div-bg" onClick={closeModalAddVariation} />
            <DivAddVariation>
              <header>
                <strong>Adicionar variação</strong>
                <img
                  src={CancelIcon}
                  alt="cancel"
                  onClick={closeModalAddVariation}
                />
              </header>

              <div>
                <strong>Nome da variação</strong>
                <span>Adicione o nome da variação. Exemplo: “Cor”.</span>
                <input
                  type="text"
                  value={inputVariationName}
                  onChange={e => setInputVariationName(e.target.value)}
                />

                <strong>Opções</strong>
                <span>
                  Adicione as opções da variação. Exemplo: “Azul”, “Amarelo”
                </span>

                <button type="button" onClick={handleInsertNewSub}>
                  + Adicionar nova opção
                </button>

                {/* <DivItems create>
                  <div>
                    {newSubs &&
                      newSubs.map((ns, index) => (
                        <DivItem key={ns.name}>
                          <input
                            type="text"
                            defaultValue={ns.name}
                            onBlur={e =>
                              handleBlurSub(ns, index, e.target.value)
                            }
                          />
                          <img
                            src={CancelGreyIcon}
                            alt="cancel"
                            onClick={() => handleDeleteSub(ns, index)}
                          />
                        </DivItem>
                      ))}
                  </div>
                </DivItems> */}

                <DragDropContext onDragEnd={onDragEndVariations}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <DivItems
                        create
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {newSubs &&
                          newSubs.map((ns, index) => (
                            <Draggable
                              key={String(ns.id)}
                              draggableId={String(ns.id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <DivItem key={ns.name}>
                                    <div>
                                      <img
                                        src={DragAndDropPointsIcon}
                                        alt="points"
                                      />
                                    </div>
                                    <input
                                      type="text"
                                      autoComplete="nope"
                                      defaultValue={ns.name}
                                      onBlur={e =>
                                        handleBlurSub(ns, index, e.target.value)
                                      }
                                    />
                                    <img
                                      src={CancelGreyIcon}
                                      alt="cancel"
                                      onClick={() => handleDeleteSub(ns, index)}
                                    />
                                  </DivItem>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </DivItems>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <footer>
                <button
                  type="button"
                  onClick={handleSubmitVariation}
                  disabled={disableButtonSaveVariation}
                >
                  Salvar
                </button>
              </footer>
            </DivAddVariation>
          </AddVariation>
        )}

        {showAddSubVariantModal && (
          <AddVariation>
            <div className="div-bg" onClick={closeModalAddVariation} />
            <DivAddVariation>
              <header>
                <strong>Adicionar variação</strong>
                {/* <img
                  src={CancelIcon}
                  alt="cancel"
                  onClick={closeModalAddVariation}
                /> */}
                <button
                  type="button"
                  onClick={() => handleClickDeleteVariation(variationId)}
                >
                  Excluir
                </button>
              </header>

              <div>
                <strong>Nome da variação</strong>
                <span>Exemplo: “Cor”.</span>
                <input
                  type="text"
                  value={inputVariationName}
                  onChange={e => setInputVariationName(e.target.value)}
                />

                <strong>Opções</strong>
                <span>Exemplo: “Azul”, “Amarelo”</span>

                <button type="button" onClick={handleInsertNewSub}>
                  + Adicionar nova opção
                </button>

                <DragDropContext onDragEnd={onDragEndVariations}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <DivItems
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        create
                      >
                        {newSubs &&
                          newSubs.map((ns, index) => (
                            <Draggable
                              key={String(ns.id)}
                              draggableId={String(ns.id)}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <DivItem>
                                    <div>
                                      <img
                                        src={DragAndDropPointsIcon}
                                        alt="points"
                                      />
                                    </div>

                                    <input
                                      type="text"
                                      autoComplete="nope"
                                      defaultValue={ns.name}
                                      onBlur={e =>
                                        handleBlurSub(ns, index, e.target.value)
                                      }
                                    />
                                    <img
                                      src={CancelGreyIcon}
                                      alt="cancel"
                                      onClick={() => handleDeleteSub(ns, index)}
                                    />
                                  </DivItem>
                                </div>
                              )}
                            </Draggable>
                          ))}
                        {provided.placeholder}
                      </DivItems>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>

              <footer>
                <button
                  type="button"
                  onClick={handleSubmitEditVariation}
                  disabled={disableButtonSaveVariation}
                >
                  Salvar
                </button>
              </footer>
            </DivAddVariation>
          </AddVariation>
        )}
      </Container>
      {/* <DivMaintenance>
        <span>Olá! estamos em manutenção, aguarde alguns minutos...</span>
      </DivMaintenance> */}
    </>
  );
}

import styled, { css } from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

export const Container = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  width: 600px;
  height: 750px;
  top: calc(50vh - (700px / 2));
  left: calc(50vw - (600px / 2));
  background: #fff;
  z-index: 600;
  border-radius: 20px;

  > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    border-top: 0.5px solid #d8dee5;
    background: #fff;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #2b8ff5;
      border-radius: 8px;
      height: 50px;
      border: none;
      color: #fff;

      &:disabled {
        background: #838383;
      }

      > img {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (max-width: 720px) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
    width: auto;
    border-radius: 0;
    overflow: hidden;
  }
`;

export const Header = styled.div`
  position: relative;
  min-height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 0.5px solid #d8dee5;

  > strong {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    text-align: center;
    color: #000000;
  }

  > div {
    position: absolute;
    right: 24px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;

  @media (max-width: 720px) {
    width: 100%;
    overflow: hidden;
  }
`;

export const DivInput = styled.div`
  display: flex;
  padding: 20px 16px;
  border-bottom: 0.5px solid #d8dee5;
`;

export const DivProducts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 223px);
  /* overflow-y: auto; */
  /* -webkit-overflow-scrolling: auto; */
  overflow: hidden;

  > div {
    > div {
      padding-bottom: 32px;
    }
  }
`;

export const Product = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
  border-bottom: 0.5px solid #d8dee5;
  cursor: pointer;

  /* &:first-child {
    border-top: 0.5px solid #d8dee5;
  } */

  > img {
    width: 48px;
    height: 48px;
    border-radius: 6px;
    object-fit: cover;
  }

  .middle {
    flex: 1;
    margin: 0 12px;
  }

  > div {
    display: flex;
    flex-direction: column;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: ${props => (props.selected ? '#0d85ff' : '#000000')};
    }

    > span {
      font-weight: 400;
      font-size: 13px;
      color: ${props => (props.selected ? '#0d85ff' : '#838383')};
    }
  }
`;

export const DivSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #d8dee5;
  border-radius: 50%;

  > svg {
    display: none;
  }

  ${props =>
    props.selected &&
    css`
      background: #0d85ff;
      border: 1px solid #0d85ff;

      > svg {
        display: flex;
      }
    `}
`;

export const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* position: absolute; */
  /* top: 200px; */
  /* left: 0; */
  margin-top: 8px;
  z-index: 999;
  background: none;

  img {
    background: none;
    width: 25px;
    height: 25px;
  }
`;

export const StyledDebounceInput = styled(DebounceInput)`
  background: #f5f5f5;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  border: none;
  display: flex;
  text-align: center;
  font-size: 13px;

  &::placeholder {
    color: #9d9d9d;
  }
`;

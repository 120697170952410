import styled from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(227, 229, 236, 0.65);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const DivHeader = styled.div`
  display: flex;
  height: 51px;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
  width: 100%;
  padding: 16px 0;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-bottom: 0.5px solid #d8dee5;

  .div-space {
    width: 15%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  span {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
  }

  img {
    width: 10px;
  }
`;

export const Div = styled.div`
  width: 392px;
  height: 157px;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background: rgba(227, 229, 236, 0.65);
  z-index: 550;
  position: fixed;
  top: calc(50vh - (157px / 2));
  left: calc(50vw - (392px / 2));
  border-radius: 16px;
  background: #fff;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    button {
      width: 100%;
      height: 53px;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #838383;
      font-size: 14px;
      border: none;
      padding: 12px 0;

      &:hover {
        filter: opacity(0.85);
        transition: all 300ms;
      }

      img {
        margin-right: 6px;
      }
    }

    .btn-delete {
      color: #ff303c;
      text-decoration-line: none;
      border-top: 0.5px solid #d8dee5;
    }
  }
`;

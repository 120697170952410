/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { PaperIcon } from '~/assets/icons/order/paperIcon';
import { PackageIcon } from '~/assets/icons/order/packageIcon';
import { TrafficIcon } from '~/assets/icons/order/trafficIcon';
import {
  ModalActive,
  DivInput,
} from '~/pages/Responsive/MyOrdersDetails/styles';
import { ReadyBoxcon } from '~/assets/icons/order/readyboxicon';
import api from '../../services/api';
import * as S from './styles';
// import { UniversalLoader } from '../UniversalLoader/UniversalLoader';

export default function OrderStatusSteps({
  setDetailsStatus,
  orderId,
  handleSendNotify,
  orderReceivement,
}) {
  const [currencyStatus, setCurrencyStatus] = useState();
  const [trackingCode, setTrackingCode] = useState('');

  const [modalInPreparationActive, setModalInPreparationActive] = useState(
    false
  );
  const [modalSendOrderActive, setModalSendOrderActive] = useState(false);
  const [modalOrderReadyActive, setModalOrderReadyActive] = useState(false);

  // const [initialLoading, setInitialLoading] = useState(false);

  // const initializeSteps = useCallback(() => {
  //   const steps = [
  //     {
  //       name: 'Pedido pago',
  //       nextStep: 'Em preparação',
  //       active: false,
  //       buttonStatus: 'disabled',
  //     },
  //     {
  //       name: 'Em preparação',
  //       nextStep: 'Pedido enviado',
  //       active: false,
  //       buttonStatus: 'disabled',
  //     },
  //     {
  //       name: 'Pedido enviado',
  //       active: false,
  //       buttonStatus: 'disabled',
  //       nextStep: 'Pedido enviado',
  //     },
  //   ];

  //   switch (status) {
  //     case 'Pagamento à combinar':
  //       steps[0].buttonStatus = 'enabled';
  //       break;
  //     case 'Pagamento pendente':
  //       steps[0].buttonStatus = 'enabled';
  //       break;
  //     case 'Pagamento confirmado':
  //       steps[0].active = true;
  //       steps[0].buttonStatus = 'confirmed';
  //       steps[1].buttonStatus = 'enabled';
  //       break;
  //     case 'Em preparação':
  //       steps[0].active = true;
  //       steps[1].active = true;
  //       steps[0].buttonStatus = 'confirmed';
  //       steps[1].buttonStatus = 'confirmed';
  //       steps[2].buttonStatus = 'enabled';
  //       break;
  //     case 'Pedido enviado':
  //       steps[0].active = true;
  //       steps[1].active = true;
  //       steps[2].active = true;
  //       steps[0].buttonStatus = 'confirmed';
  //       steps[1].buttonStatus = 'confirmed';
  //       steps[2].buttonStatus = 'confirmed';
  //       break;
  //     default:
  //       break;
  //   }

  //   return steps;
  // }, [status]);

  // const [listOfStatus, setListOfStatus] = useState(initializeSteps);

  // useEffect(() => {
  //   setListOfStatus(initializeSteps());
  // }, [initializeSteps, status]);

  useEffect(() => {
    async function load() {
      await api
        .get(`/order/status/${orderId}`)
        .then(response => setCurrencyStatus(response.data.status));
    }

    load();
  }, [orderId]);

  const handleChangeStatus = async nextStatus => {
    try {
      const response = await api.put('/order/status', {
        status: nextStatus,
        orderId,
        tracking_code: nextStatus === 'Pedido enviado' ? trackingCode : null,
      });

      if (response.status === 200) {
        setCurrencyStatus(nextStatus);
        setDetailsStatus(nextStatus);
        toast.success('Alteração salva');
      }
    } finally {
      setModalInPreparationActive(false);
      setModalOrderReadyActive(false);
    }
  };

  async function handleSendOrder(status) {
    try {
      // await handleSendNotify(trackingCode);
      await handleChangeStatus(status);
    } finally {
      setModalSendOrderActive(false);
    }
  }

  function stepOneStatus() {
    switch (currencyStatus) {
      case 'Pagamento confirmado':
        return true;

      case 'Em preparação':
        return true;

      case 'Pedido enviado':
        return true;

      case 'Pedido pronto':
        return true;

      default:
        return false;
    }
  }

  function stepTwoStatus() {
    switch (currencyStatus) {
      case 'Em preparação':
        return true;

      case 'Pedido enviado':
        return true;

      case 'Pedido pronto':
        return true;

      default:
        return false;
    }
  }

  function stepThreeStatus() {
    switch (currencyStatus) {
      case 'Pedido enviado':
        return true;

      case 'Pedido pronto':
        return true;

      default:
        return false;
    }
  }

  return (
    <>
      {/* <UniversalLoader open={initialLoading} /> */}
      <S.MainContainer disabled={currencyStatus === 'Cancelado'}>
        <S.Title>Status do pedido</S.Title>
        <S.Subtitle>
          Informe para seu cliente sobre o andamento do pedido. Seu cliente
          receberá um e-mail sempre que você alterar o status.
        </S.Subtitle>

        <S.StatusContainer>
          <S.MainContainerStatus>
            <S.ContainerStatusWrapper status={stepOneStatus()}>
              <PaperIcon color={stepOneStatus() ? '#06C86B' : '#9D9D9D'} />

              <p>Pedido pago</p>
              <S.Button
                width="107px"
                active={stepOneStatus()}
                disabled={stepOneStatus()}
                onClick={() => handleChangeStatus('Pagamento confirmado')}
              >
                {stepOneStatus() ? 'Confirmado' : 'Selecionar'}
              </S.Button>
            </S.ContainerStatusWrapper>
            <S.Divider />
          </S.MainContainerStatus>

          <S.MainContainerStatus>
            <S.ContainerStatusWrapper status={stepTwoStatus()}>
              <PackageIcon color={stepTwoStatus() ? '#06C86B' : '#9D9D9D'} />

              <p>Em preparação</p>
              <S.Button
                width="107px"
                active={stepTwoStatus()}
                disabled={stepTwoStatus()}
                onClick={() => setModalInPreparationActive(true)}
              >
                {stepTwoStatus() ? 'Confirmado' : 'Selecionar'}
              </S.Button>
            </S.ContainerStatusWrapper>
            <S.Divider />
          </S.MainContainerStatus>

          {orderReceivement === 0 && (
            <S.MainContainerStatus>
              <S.ContainerStatusWrapper status={stepThreeStatus()}>
                <TrafficIcon
                  color={stepThreeStatus() ? '#06C86B' : '#9D9D9D'}
                />

                <p>Pedido enviado</p>
                <S.Button
                  width="107px"
                  active={stepThreeStatus()}
                  disabled={stepThreeStatus()}
                  onClick={() => setModalSendOrderActive(true)}
                >
                  {stepThreeStatus() ? 'Confirmado' : 'Selecionar'}
                </S.Button>
              </S.ContainerStatusWrapper>
            </S.MainContainerStatus>
          )}

          {orderReceivement === 1 && (
            <S.MainContainerStatus>
              <S.ContainerStatusWrapper status={stepThreeStatus()}>
                <ReadyBoxcon
                  color={stepThreeStatus() ? '#06C86B' : '#9D9D9D'}
                />

                <p>Pedido pronto</p>
                <S.Button
                  width="107px"
                  active={stepThreeStatus()}
                  disabled={stepThreeStatus()}
                  onClick={() => setModalOrderReadyActive(true)}
                >
                  {stepThreeStatus() ? 'Confirmado' : 'Selecionar'}
                </S.Button>
              </S.ContainerStatusWrapper>
            </S.MainContainerStatus>
          )}
        </S.StatusContainer>
      </S.MainContainer>

      {modalInPreparationActive && (
        <ModalActive>
          <div
            className="container"
            onClick={() => setModalInPreparationActive(false)}
          />
          <div className="div">
            <strong>Alterar status para “Em preparação”?</strong>
            <span>
              Seu cliente receberá um e-mail informando que o pedido está “Em
              preparação”
            </span>

            <button
              type="button"
              onClick={() => handleChangeStatus('Em preparação')}
            >
              Confirmar alteração
            </button>
          </div>
        </ModalActive>
      )}

      {modalOrderReadyActive && (
        <ModalActive>
          <div
            className="container"
            onClick={() => setModalOrderReadyActive(false)}
          />
          <div className="div">
            <strong>Alterar status para “Pedido pronto”?</strong>
            <span>
              Seu cliente receberá um e-mail informando que o pedido está pronto
              para retirada
            </span>

            <button
              type="button"
              onClick={() => handleChangeStatus('Pedido pronto')}
            >
              Confirmar alteração
            </button>
          </div>
        </ModalActive>
      )}

      {modalSendOrderActive && (
        <ModalActive>
          <div
            className="container"
            onClick={() => setModalSendOrderActive(false)}
          />
          <div className="div">
            <strong>Notificar envio do pedido</strong>
            <span>
              Um e-mail será enviado para o comprador informando que o pedido
              foi enviado
            </span>

            <DivInput>
              <div>
                <strong>Código de rastreio</strong>
                <span>Opcional</span>
              </div>

              <input
                type="text"
                value={trackingCode}
                onChange={e => setTrackingCode(e.target.value)}
              />
            </DivInput>

            <button
              type="button"
              onClick={() => handleSendOrder('Pedido enviado')}
            >
              Confirmar envio do pedido
            </button>
          </div>
        </ModalActive>
      )}
    </>
  );
}

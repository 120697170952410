import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '55px 0px 0px 0px'};
  padding: 24px 16px 0px 16px;
`;

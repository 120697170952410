import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import api from '~/services/api';

import { copyLinkBtnEvent, seeStoreBtnEvent } from '~/functions/events';
import { ButtonLinkStore, Container } from './styles';

import linkIcon from '../../assets/icons/link-white.svg';

function LabelDomain() {
  const [domain, setDomain] = useState();
  const [showContainer, setShowContainer] = useState(false);

  const username = useSelector(
    state => state.user && state.user.profile && state.user.profile.username
  );

  async function LoadData() {
    await api.get('/domain').then(res => {
      if (res && res.data) {
        setDomain(res.data);
      }
    });
  }

  useEffect(() => {
    LoadData();

    // setTimeout(() => {
    setShowContainer(true);
    // }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container showContainer={showContainer}>
      <div className="div-info">
        <strong>Seu link:</strong>

        {domain && domain.domain && domain.active ? (
          <a
            href={`https://${domain.domain}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{domain.domain}</span>
            <img src={linkIcon} alt="link" />
          </a>
        ) : (
          <a
            href={`https://${username}.offstore.me`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span>{`${username}.offstore.me`}</span>
            {/* <img src={linkIcon} alt="link" /> */}
          </a>
        )}
      </div>

      <div className="buttons">
        {domain && domain.domain && domain.active ? (
          <CopyToClipboard text={domain.domain}>
            <button
              type="button"
              className="secundary-button"
              onClick={() => {
                copyLinkBtnEvent();
                toast.success('Link copiado');
              }}
            >
              Copiar link
            </button>
          </CopyToClipboard>
        ) : (
          <CopyToClipboard text={`${username}.offstore.me`}>
            <button
              type="button"
              className="secundary-button"
              onClick={() => {
                copyLinkBtnEvent();
                toast.success('Link copiado');
              }}
            >
              Copiar link
            </button>
          </CopyToClipboard>
        )}

        {/* <a
          href={
            domain && domain.domain && domain.active
              ? `https://${domain.domain}`
              : `https://${username}.offstore.me`
          }
          target="_blank"
          rel="noreferrer"
        >
          Ver loja
        </a> */}

        <ButtonLinkStore
          type="button"
          onClick={() => {
            seeStoreBtnEvent();

            if (domain && domain.domain && domain.active) {
              window.open(`https://${domain.domain}`, '_blank');
              return 0;
            }

            window.open(`https://${username}.offstore.me`, '_blank');
            return 0;
          }}
        >
          Ver loja
        </ButtonLinkStore>
      </div>
    </Container>
  );
}

export default LabelDomain;

import React, { useEffect, useState, useMemo, useRef } from 'react';

import { parseISO, format } from 'date-fns';
import Barcode from 'react-barcode';
import pt from 'date-fns/locale/pt-BR';

import { useReactToPrint } from 'react-to-print';

import { Divider } from '~/designsystem/Fonts';
import {
  Container,
  DivBody,
  DivSelectConfigurationLabel,
  Div,
  DivSelect,
  DivLabel,
} from './styles';

import { formatPrice } from '../../../../../util/format';

import api from '../../../../../services/api';

import Header from '../Header';

import TicketIcon from '../../../../../assets/icons/order/ticket.svg';
import CheckIcon from '../../../../../assets/icons/order/checkIcon';

function ViewLabel({ handleClickButton, id }) {
  const ref = useRef();

  const [showOrderInfo, setShowOrderInfo] = useState(true);
  const [showSenderInfo, setShowSenderInfo] = useState(true);

  const [order, setOrder] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    async function load() {
      await api.post(`/orderrequestbyid/${id}`).then(async response => {
        setOrder({
          ...response.data,
          date: format(parseISO(response.data.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
        });

        setProducts(
          response.data.products.map(r => ({
            ...r,
            totalPrice: formatPrice(r.price),
          }))
        );
      });
    }

    load();
  }, [id]);

  const subtotal = useMemo(() => {
    const value = order && order.subtotal && order.subtotal.replace('.', '');
    const response = value && parseFloat(value.slice(2)).toFixed(2);
    return response;
  }, [order]);

  const pageStyle = `
    @page {
      size: auto;
      margin: 10mm;
    }
  `;

  // function onBeforeGetContent() {
  //   setLabelDimenstion();
  // }

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle,
  });

  return (
    <Container>
      <Header
        title="Impressão de etiqueta"
        handleClickBack={handleClickButton}
      />
      <DivBody>
        <DivSelectConfigurationLabel>
          <h2>Informações da etiqueta</h2>

          <div>
            <span>Exibir remetente</span>

            <DivSelect
              enabled={showSenderInfo}
              onClick={() => setShowSenderInfo(!showSenderInfo)}
            >
              {showSenderInfo && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>

          <div>
            <span>Exibir informações do pedido</span>

            <DivSelect
              enabled={showOrderInfo}
              onClick={() => setShowOrderInfo(!showOrderInfo)}
            >
              {showOrderInfo && <CheckIcon color="#fff" />}
            </DivSelect>
          </div>

          <button
            type="button"
            // onClick={() =>
            //   exportComponentAsPDF(ref, {
            //     fileName: `pedido ${order && order.id}.pdf`,
            //     pdfOptions: { w: 95, h: 150, x: 15, y: 15 },
            //   })
            // }
            onClick={() => handlePrint()}
          >
            Imprimir etiqueta
            <img src={TicketIcon} alt="cancel" />
          </button>
        </DivSelectConfigurationLabel>

        <Div ref={ref}>
          <DivLabel>
            <div>
              <div className="title">
                <strong>Destinatário</strong>
              </div>
              <span>{order && order.name}</span>

              {order &&
                (order.address ||
                  order.number ||
                  order.complement ||
                  order.neighborhood ||
                  order.city ||
                  order.uf ||
                  order.cep) && (
                  <>
                    <span className="mg-top-4">
                      {order.address}, {order.number}{' '}
                      {order.complement && `, ${order.complement},`}
                    </span>
                    <span className="mg-top-4">{order.neighborhood}</span>
                    <span className="mg-top-4">
                      {order.city}, {order.uf}, {order.cep}
                    </span>
                    <span className="mg-top-4">Brasil</span>
                  </>
                )}

              {order && order.cep && (
                <div>
                  <Barcode
                    value={String(order.cep)}
                    height={74}
                    font='"Inter"'
                    fontSize={12}
                    textMargin={8}
                  />
                </div>
              )}
            </div>

            {showSenderInfo && (
              <div>
                <strong className="titleSender">Remetente</strong>
                {/* <span>{order && order.User.page_title}</span> */}

                {order &&
                  order.User &&
                  (order.User.address ||
                    order.User.number ||
                    order.User.district ||
                    order.User.city ||
                    order.User.uf ||
                    order.User.cep) && (
                    <>
                      <span>
                        {order.User.address}, {order.User.number},
                        {order.User.district}, {order.User.city},{' '}
                        {order.User.uf}, {order.User.cep}
                      </span>
                    </>
                  )}
              </div>
            )}

            {products && showOrderInfo && (
              <>
                <div className="divider-section">
                  <Divider margin='"0px"' />
                </div>

                <div>
                  <strong className="titleSender">Informações do pedido</strong>
                  {products.map(p => (
                    <div className="mg-bottom-8">
                      <span>
                        {p.amount}x {p.name}
                      </span>
                      <span>{p.totalPrice}</span>
                    </div>
                  ))}
                </div>

                <div className="divider-section">
                  <Divider margin='"0px"' />
                </div>
                <div>
                  <div>
                    <span>Subtotal</span>
                    <span>{order && order.subtotal}</span>
                  </div>

                  <div className="mg-top-4">
                    <span>Envio</span>
                    <span>{order && order.rate}</span>
                  </div>

                  {order && order.coupon && (
                    <div className="mg-top-4">
                      <span>Cupom de desconto({order.coupon.code})</span>
                      <span>
                        {order.coupon.type === 'P'
                          ? `- ${formatPrice(
                              (subtotal * order.coupon.value) / 100
                            )}`
                          : `- ${formatPrice(order.coupon.value)}`}
                      </span>
                    </div>
                  )}
                  <div className="mg-top-4">
                    <span className="bold">Total</span>
                    <span className="bold">{order && order.total}</span>
                  </div>
                </div>
              </>
            )}
          </DivLabel>
        </Div>
      </DivBody>
    </Container>
  );
}

export default ViewLabel;

import React from 'react';
import { useHistory } from 'react-router-dom';
import dataPlans from '../../../../../data/plans.json';

import {
  Container,
  DiscountLabel,
  DivItems,
  DivPrice,
  DivTitle,
} from './styles';

function ShortCardPremiumPlan({ buttonHandleClick, isDesktop }) {
  const history = useHistory();

  return (
    <Container>
      <DivTitle>
        <strong>Plano {dataPlans.premium.name} 🔥</strong>
        <span>{dataPlans.premium.description}</span>
      </DivTitle>

      <DivPrice>
        <div>
          <strong>
            R$ {dataPlans.premium.price.monthly.price}
            <span>/mês</span>
          </strong>

          <DiscountLabel>
            <span>{dataPlans.premium.price.monthly.economyPercent} OFF</span>
          </DiscountLabel>
        </div>

        <span>R$ {dataPlans.premium.price.monthly.oldPrice}/mês</span>
      </DivPrice>

      <strong>Tudo do plano PRO e mais:</strong>

      <DivItems>
        {dataPlans.premium.items.map(d => (
          <div key={d}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <div style={{ marginRight: '6px' }}>
                <svg
                  width="11"
                  height="8"
                  viewBox="0 0 11 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10 1L3.8125 7L1 4.27273"
                    stroke="#ffffff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>

              {d}
            </span>
          </div>
        ))}
      </DivItems>

      <button
        type="button"
        onClick={() =>
          isDesktop ? buttonHandleClick(7) : history.push('/pro')
        }
      >
        <span>Assinar plano {dataPlans.premium.name}</span>
      </button>
    </Container>
  );
}

export default ShortCardPremiumPlan;

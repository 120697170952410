export function loadOrderByIdRequest() {
  return {
    type: '@order/LOAD_ORDER_BY_ID_REQUEST',
  };
}

export function loadOrderByIdSuccess(data) {
  return {
    type: '@order/LOAD_ORDER_BY_ID_SUCCESS',
    payload: { data },
  };
}

export function loadProductsRequest() {
  return {
    type: '@order/LOAD_PRODUCTS_REQUEST',
  };
}

export function loadProductsSuccess(data) {
  return {
    type: '@order/LOAD_PRODUCTS_SUCCESS',
    payload: { data },
  };
}

export function AddAmountProductSuccess(id) {
  return {
    type: '@order/ADD_AMOUNT_PRODUCTS_SUCCESS',
    payload: { id },
  };
}

export function RemAmountProductSuccess(id) {
  return {
    type: '@order/REM_AMOUNT_PRODUCTS_SUCCESS',
    payload: { id },
  };
}

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* background: #fff; */
  /* min-height: 100vh; */

  > span {
    font-size: 13px;
    color: #838383;
    padding: 0 32px;
  }

  > button {
    width: 100%;
    height: 48px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const DivOrigin = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 32px;
  margin: ${props => props.margin || '0 0 24px 0'};

  > div {
    display: flex;
    align-items: center;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #000000;
      margin-right: 6px;
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 11px;
      line-height: 16px;
      color: #2b8ff5;
    }

    .optional {
      color: #9d9d9d !important;
    }
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 45px;
    padding: 0 12px;
    font-size: 13px;
    background: #f9f9f9;

    &::placeholder {
      color: #afafaf;
    }
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 32px;
  margin-top: 16px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-bottom: 12px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 12px;
  height: 62px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

export const SpanStatus = styled.span`
  font-style: normal !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  font-weight: ${props => props.fontWeight || 'normal'} !important;
  font-size: ${props => props.fontSize || '11px'} !important;
  color: ${props => props.color} !important;
`;

export const DivWarning = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(43, 143, 245, 0.04);
  border: 0.5px solid rgba(43, 143, 245, 0.04);
  border-radius: 8px;
  padding: 16px 12px;
  margin: 12px 32px 16px 32px;

  > span {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
  }

  ${props =>
    props.color === 'red' &&
    css`
      background: rgb(255, 108, 75, 0.1);
      border-radius: 0px 4px 4px 0px;
      border-left: solid 2px #ff6c4b;

      > span {
        color: #ff6c4b;
      }
    `}

  ${props =>
    props.color === 'yellow' &&
    css`
      background: rgba(255, 181, 37, 0.05);
      border: 0.5px solid rgba(255, 181, 37, 0.05);

      > span {
        color: #ffb525;
      }
    `}
`;

export const DivExcludeCity = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 24px;
  padding: 0 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
    width: 80%;
  }

  > label {
    position: absolute;
    top: 0;
    right: 32px;
  }
`;

export const DivTextDimensions = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 1px;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;
    /* margin-bottom: 32px; */
    font-size: 13px !important;

    > select {
      height: 45px;
      padding: 0 8px;
      border: 0.5px solid #d8dee5;
      border-radius: 8px;
      background: #fff;
      width: 100%;
      -webkit-appearance: none !important;
      color: #101010;
      font-size: 13px !important;
    }

    > img {
      position: absolute;
      right: 12px;
    }
  }
`;

export const DivDimensions = styled.div`
  display: flex;
  margin-bottom: 24px;
  padding: 1px 32px 0 32px;

  > div {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 0 3px;

    > strong {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 6px;
      min-width: 80px;
    }

    > span {
      color: ${props => (props.weight >= 10 ? '#FF303C' : '#838383')};
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
      text-align: center;
    }

    > div {
      display: flex;
      width: 100%;

      > input {
        display: flex;
        flex: 1;
        width: 100%;
        text-align: center;
        border: none;
        box-sizing: border-box;
        border-radius: 8px 0 0 8px;
        height: 44px;
        background: #f9f9f9;
        /* margin: 0 5px; */

        &::placeholder {
          font-size: 13px;
          color: #afafaf;
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px !important;
        height: 44px;
        background: #efefef;
        border-radius: 0 8px 8px 0;
        font-size: 13px;
        color: #101010;
      }
    }
  }
`;

export const DivHideButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  > button {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: #2b8ff5;
    background: transparent;
    border: none;
    padding: 16px 0;
    width: 120px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  border-radius: 18px;
  margin-top: 32px;
  background: linear-gradient(
    245deg,
    #ff6534 1.17%,
    #ff0e9f 41.04%,
    #a925fa 72.04%
  );
  padding: 24px;

  > strong {
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
  }

  > button {
    width: 100%;
    height: 48px;
    border-radius: 8px;
    background: #fff;
    margin-top: 32px;
    border: none;

    > span {
      background: linear-gradient(
        238deg,
        #ff6534 -14.3%,
        #ff0e9f 47.79%,
        #a925fa 92.21%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-weight: 600;
    }
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  > span {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    margin-top: 4px;
  }
`;

export const DivPrice = styled.div`
  display: flex;
  flex-direction: column;
  border-top: solid 0.5px rgba(255, 255, 255, 0.5);
  border-bottom: solid 0.5px rgba(255, 255, 255, 0.5);
  margin: 24px 0;
  padding: 16px 0;

  > div {
    display: flex;
    justify-content: space-between;

    > strong {
      color: #fff;
      font-size: 24px;
      font-weight: 600;

      > span {
        color: #fff;
        font-size: 13px;
        font-weight: 600;
      }
    }
  }

  > span {
    color: #fff;
    font-family: Inter;
    font-size: 13px;
    font-weight: 400;
    text-decoration: line-through;
    opacity: 0.5;
  }
`;

export const DiscountLabel = styled.div`
  border-radius: 24px;
  background: #ffffff;
  color: transparent;
  font-size: 10px;
  font-weight: 600;
  padding: 8px 16px;

  > span {
    background: var(
      --Cores-new-gradiet,
      linear-gradient(220deg, #ff6534 12.15%, #ff0e9f 64.72%, #a925fa 102.33%)
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const DivItems = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > div {
    display: flex;
    align-items: center;

    & + div {
      margin-top: 16px;
    }

    > span {
      font-size: 14px;
      color: #ffffff;
      font-weight: 500;

      > svg {
        margin-right: 6px;
      }
    }

    > div {
      display: flex;
      margin-left: 6px;

      > span {
        display: flex;
        align-items: center;
        color: #cfcfcf;
        font-size: 13px;
      }
    }
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  padding-bottom: 32px;
  width: 810px !important;
  border-radius: 16px;
`;

export const DivAddOrder = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 32px;

  > button {
    width: max-content;
    background: none;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #2b8ff5;
    margin-top: 16px;
  }

  > span {
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    align-self: center;
    color: #838383;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  /* padding: 24px 16px; */

  > strong {
    font-weight: 500;
    font-size: 20px;
    color: #000;
  }

  > span {
    color: #a5a9b1;
    font-size: 13px;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #d8dee5;

  > button {
    margin: auto;
    height: 50px;
    width: 400px;
    margin-top: 32px;
    border-radius: 8px;
    background: #efefef;
    border: none;
    color: #000;
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;

    > img {
      width: 25px;
      height: 25px;
    }
  }

  .loader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 20px 0;

    > img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  background: ${props => (props.canceled ? '#fafafa' : '')};
  cursor: pointer;
  -webkit-box-pack: center;
  border-bottom: 0.5px solid rgb(216, 222, 229);
  padding: 18px 32px;

  > span {
    font-size: 11px;
    color: #101010;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 6px;
    position: absolute;
    right: 16px;

    > img {
      width: 12px;
      height: 12px;
    }
  }

  > div {
    > strong {
      font-weight: 500;
      font-size: 13px;
      color: #000000;
      margin-right: 6px;
    }

    > span {
      font-size: 13px;
      color: #2b8ff5;
    }

    .viewed {
      color: #838383;
      font-weight: 400;
    }

    .canceled {
      color: #9d9d9d;
      font-weight: 400;
    }
  }

  .date-viewed {
    color: #9d9d9d;
  }

  .point {
    display: flex;
    width: 6px;
    height: 6px;
    margin-right: 16px;
    border-radius: 50%;
  }

  .span-cancel {
    position: absolute;
    right: 16px;
    font-weight: 500;
    font-size: 13px;
    color: #cfcfcf;
  }
`;

export const DivPaymentStatus = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;

  > div {
    width: max-content;
  }

  ${props =>
    props.color === 'approved' &&
    props.orderStatus !== 'Cancelado' &&
    css`
      > div {
        background: rgba(43, 143, 245, 0.06);
        color: #2b8ff5;
      }
    `}

  ${props =>
    props.color === 'Pagamento pendente' &&
    css`
      > div {
        background: rgba(157, 157, 157, 0.08);
        color: #9d9d9d;
      }
    `}
    ${props =>
      (props.color === 'Cancelado' ||
        props.color === 'Pagamento expirado' ||
        props.color === 'Pagamento reprovado') &&
      css`
        > div {
          background: rgba(255, 48, 60, 0.06);
          color: #ff303c;
        }
      `}
      ${props =>
        props.color === 'Pagamento confirmado' &&
        css`
          > div {
            background: rgba(6, 200, 107, 0.06);
            color: #06c86b;
          }
        `}

      ${props =>
        props.color === 'Pendente' &&
        css`
          > div {
            background: rgba(255, 181, 37, 0.06);
            color: #ffb525;
          }
        `}
        ${props =>
          props.color === 'Pagamento à combinar' &&
          css`
            > div {
              background: rgba(255, 181, 37, 0.06);
              color: #ffb525;
            }
          `}
        ${props =>
          props.color === 'Em preparação' &&
          css`
            > div {
              background: rgba(131, 72, 255, 0.06);
              color: #8348ff;
            }
          `}
          ${props =>
            (props.color === 'Pedido enviado' ||
              props.color === 'Pedido pronto') &&
            css`
              > div {
                background: rgba(13, 133, 255, 0.06);
                color: #0d85ff;
              }
            `}

            ${props =>
              props.color === 'Aprovado' &&
              css`
                > div {
                  background: rgba(13, 133, 255, 0.06);
                  color: #0d85ff;
                }
              `}


        ${props =>
          ((props.color === 'approved' && props.orderStatus !== 'Cancelado') ||
            (!props.color && props.orderStatus === 'Pedido recebido')) &&
          css`
            > div {
              background: rgba(16, 16, 16, 0.06);
              color: rgba(16, 16, 16, 0.5);
            }
          `}

              ${props =>
                ((props.color === 'approved' &&
                  props.orderStatus !== 'Cancelado') ||
                  (!props.color &&
                    props.orderStatus === 'Pagamento pendente')) &&
                css`
                  > div {
                    background: rgba(16, 16, 16, 0.06);
                    color: rgba(16, 16, 16, 0.5);
                  }
                `}

  > div {
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 13px;
  }
`;

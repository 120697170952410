import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
`;

export const DivHeader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 64px;

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #262626;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-space {
    width: 15%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      width: 10px;
      cursor: pointer;
    }
  }

  .div-text {
    width: 90%;
    flex-direction: column;
  }
`;

export const Div = styled.div`
  width: 100%;
  max-width: 480px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 24px;

  > img {
    margin-bottom: 12px;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 25px;
    color: #262626;
    margin-bottom: 4px;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
  }

  .span-whats {
    font-size: 13px;
    line-height: 19px;
    color: #262626;
    margin-top: 16px;
    margin-bottom: 12px;
  }
`;

export const DivInput = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 24px;

  > div {
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border: ${props =>
      props.inFocus ? '0.5px solid #2b8ff5' : '0.5px solid #d8dee5'};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #262626;
    }
  }

  input {
    display: flex;
    flex: 1;
    height: 42px;
    background: #ffffff;
    border: ${props =>
      props.inFocus ? '0.5px solid #2b8ff5' : '0.5px solid #d8dee5'};
    /* text-align: center; */
    font-size: 13px;
    box-sizing: border-box;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: none;

    /* &:focus {
      border: 0.5px solid #2b8ff5;
      border-left: none;
    } */

    &::placeholder {
      font-size: 13px;
      color: #c6cad2;
    }
  }
`;

export const SpanError = styled.div`
  width: 100%;
  text-align: end;
  margin-bottom: 24px;
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .button-continue {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => (props.block ? '#CACACA' : '#2b8ff5')};
    border-radius: 10px;
    color: #fff;
    border: none;
    width: 100%;
    height: 45px;
    margin-bottom: 15px;
  }

  .button-jump {
    width: 100%;
    background: none;
    border: none;
    font-size: 14px;
    color: #a2a8b2;
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const DivBody = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  .pro {
    border: 1px solid #2b8ff5;
    border-radius: 4px;
    padding: 1px 6px;
    margin-left: 8px;
    font-size: 13px;
    color: #2b8ff5;
    font-weight: 500;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  border-top: solid 0.5px #d8dee5;

  ${props =>
    props.first &&
    css`
      border: none;
      padding-top: 24px;
    `}
`;

export const ContainerModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export const Modal = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  /* height: 240px; */
  width: 100%;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  z-index: 12;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #131313;
    margin-bottom: 16px;
  }

  > span {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    height: 42px;
    padding: 0 12px;
    width: 100%;
    margin-bottom: 16px;
  }

  > button {
    background: #2b8ff5;
    border-radius: 8px;
    height: 50px;
    width: 100%;
    color: #fff;
    border: none;
  }
`;

import React, { useCallback } from 'react';

import { ContainerGeral, Container, Div, DivBody } from './styles';

function FirstProduct({ showModal, Modal, handleClickProduct }) {
  const handleModal = useCallback(() => {
    Modal(false);
    sessionStorage.setItem('firstproductpopup', true);
  }, [Modal]);

  const handleClick = useCallback(() => {
    handleModal();
    handleClickProduct(0);
  }, [handleClickProduct, handleModal]);

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivBody>
          <svg
            width="96"
            height="96"
            viewBox="0 0 96 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M48.4209 87.5791C70.5123 87.5791 88.4209 69.6705 88.4209 47.5791C88.4209 25.4877 70.5123 7.5791 48.4209 7.5791C26.3295 7.5791 8.4209 25.4877 8.4209 47.5791C8.4209 69.6705 26.3295 87.5791 48.4209 87.5791Z"
              stroke="#0D85FF"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M48.8418 32V64"
              stroke="#0D85FF"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M32.8418 48H64.8418"
              stroke="#0D85FF"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <strong>Adicione seu primeiro produto</strong>
          <span>É muito fácil e leva poucos segundos!</span>
          <button type="button" onClick={handleClick}>
            Adicionar produto
          </button>
        </DivBody>
      </Div>
    </ContainerGeral>
  );
}

export default FirstProduct;

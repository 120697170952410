/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { updateProfileRequest } from '../../store/modules/user/actions';
// import ArrowLeftIcon from '../../assets/icons/arrowLeft.svg';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivBody,
  DivButton,
} from './styles';

function AboutModal({ showModal, Modal, email, user, pAbout, isModal }) {
  const [about, setAbout] = useState();

  const dispatch = useDispatch();

  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  useEffect(() => {
    setAbout(pAbout);
  }, [pAbout]);

  async function handleSaveData() {
    try {
      const data = {
        about,
        email,
        user,
      };
      dispatch(updateProfileRequest(data));
      handleModal();
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal} isModal={isModal}>
        <DivTitle>
          <div className="div-space" />
          <div>
            <span>Sobre</span>
          </div>
          <div className="div-space" />
        </DivTitle>
        <DivBody isModal={isModal}>
          <div>
            <textarea
              rows={8}
              maxLength="1000"
              placeholder="Adicionar texto sobre a loja"
              onChange={e => setAbout(e.target.value)}
              value={about}
            />
          </div>
        </DivBody>
        <DivButton>
          <button type="button" onClick={handleSaveData}>
            Salvar alterações
          </button>
        </DivButton>
      </Div>
    </ContainerGeral>
  );
}

export default AboutModal;

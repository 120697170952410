/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '~/services/api';

import {
  Description,
  H4,
  H5,
  Input,
  Space,
  TextButton,
} from '~/designsystem/Fonts';
import {
  Container,
  DivTitle,
  DivBody,
  ContainerModal,
  Modal,
  Div,
} from './styles';

function ConversionPixel() {
  const [profile, setProfile] = useState();
  const [inputMetatag, setInpuMetatag] = useState('');
  const [showModal, setShowModal] = useState(false);

  const [metaPixel, setMetaPixel] = useState('');
  const [tiktokPixel, setTiktokPixel] = useState('');
  const [googleAdsTag, setGoogleAdsTag] = useState('');
  const [googleAnalyticsTag, setGoogleAnalyticsTag] = useState('');
  const [googleTagManager, setGoogleTagManager] = useState('');
  const [googleSearchConsoleTxt, setGoogleSearchConsoleTxt] = useState('');

  useEffect(() => {
    async function load() {
      await api.get('/getuser').then(async response => {
        setProfile(response.data);

        await api.get(`/domain/${response.data.user}`).then(res => {
          if (res && res.data) {
            setInpuMetatag(res.data.metatag || '');
          }
        });
      });
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function load() {
      try {
        // setInitialLoading(true);
        await api.get('/pixels').then(async response => {
          setMetaPixel(response.data.meta_pixel);
          setTiktokPixel(response.data.tiktok_pixel);
          setGoogleAdsTag(response.data.google_ads_tag);
          setGoogleAnalyticsTag(response.data.google_analytics_tag);
          setGoogleTagManager(response.data.google_tag_manager);
          setGoogleSearchConsoleTxt(response.data.google_search_console_txt);
        });
      } finally {
        // setInitialLoading(false);
      }
    }

    load();
  }, []);

  async function handleSubmitPixel() {
    try {
      const data = {
        meta_pixel: metaPixel,
        tiktok_pixel: tiktokPixel,
        google_ads_tag: googleAdsTag,
        google_analytics_tag: googleAnalyticsTag,
        google_tag_manager: googleTagManager,
        google_search_console_txt: googleSearchConsoleTxt,
      };

      await api.put('/pixels', data);

      toast.success('Dados alterados');
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  async function handleInsertHtmlCode() {
    if (inputMetatag && inputMetatag.length > 0) {
      await api.post('/domain', {
        metatag: inputMetatag,
      });
    }

    setShowModal(false);
  }

  return (
    <>
      <Container>
        <DivTitle>
          <strong>Pixels de conversão</strong>
        </DivTitle>

        <DivBody>
          <Div first>
            <div>
              <H4>Meta</H4>

              {profile && profile.pro === 0 && <span className="pro">PRO</span>}
            </div>

            <Space height="16px" />

            <H5>Pixel da Meta</H5>

            <Space height="4px" />

            <Description>
              Insira abaixo <b>somente o ID</b> do seu Pixel da Meta. Não sabe
              como encontrar o ID do Pixel?{' '}
              <a
                href="https://intercom.help/offstore/pt-BR/articles/6493137-como-encontrar-meu-pixel-id-do-facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver tutorial
              </a>
            </Description>

            <Input
              type="text"
              placeholder="Pixel ID"
              value={metaPixel}
              onChange={e => setMetaPixel(e.target.value)}
              onBlur={handleSubmitPixel}
              disabled={profile && profile.pro === 0}
            />

            <Space height="24px" />

            <H5>Verificação de domínio da Meta</H5>

            <Space height="4px" />

            <Description>
              Precisa verificar seu domínio no Facebook?{' '}
              <a
                href="https://intercom.help/offstore/pt-BR/articles/6526879-como-verificar-seu-dominio-no-gerenciador-de-negocios-do-facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver tutorial
              </a>
            </Description>

            <Space height="12px" />

            <TextButton onClick={() => setShowModal(true)} color="#0D85FF">
              {inputMetatag && inputMetatag.length > 0
                ? 'Editar código HTML'
                : '+ Inserir código HTML'}
            </TextButton>
          </Div>

          <Div>
            <div>
              <H4>Tiktok</H4>

              {profile && profile.pro === 0 && <span className="pro">PRO</span>}
            </div>

            <Space height="16px" />

            <H5>Tiktok Pixel</H5>

            <Space height="4px" />

            <Description>
              Insira abaixo <b>somente o ID</b> do seu Pixel do Tiktok Ads. Não
              sabe como encontrar o ID do Pixel?{' '}
              <a
                href="https://ads.tiktok.com/help/article/tiktok-pixel-faq?lang=pt"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver tutorial
              </a>
            </Description>

            <Input
              type="text"
              placeholder="Pixel ID"
              value={tiktokPixel}
              onChange={e => setTiktokPixel(e.target.value)}
              onBlur={handleSubmitPixel}
              disabled={profile && profile.pro === 0}
            />
          </Div>

          <Div>
            <div>
              <H4>Google</H4>

              {profile && profile.pro === 0 && <span className="pro">PRO</span>}
            </div>

            <Space height="16px" />

            <H5>Google Ads</H5>

            <Space height="4px" />

            <Description>
              Insira abaixo o ID de sua Tag do Google Ads. Não sabe como
              encontrar o ID de sua Google Tag?{' '}
              <a
                href="https://intercom.help/offstore/pt-BR/articles/6542588-como-encontrar-o-id-da-sua-google-tag-do-google-ads"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver tutorial
              </a>
            </Description>

            <Input
              type="text"
              placeholder="ex: AW-123456789"
              value={googleAdsTag}
              onChange={e => setGoogleAdsTag(e.target.value)}
              onBlur={handleSubmitPixel}
              disabled={profile && profile.pro === 0}
            />

            <Space height="24px" />

            <H5>Google Analytics</H5>

            <Space height="4px" />

            <Description>
              Insira abaixo o ID de sua Tag do Google Analytics. Não sabe como
              encontrar o ID de sua Google Tag?{' '}
              <a
                href="https://intercom.help/offstore/pt-BR/articles/9184673-como-encontrar-minha-tag-do-google-analytics"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver tutorial
              </a>
            </Description>

            <Input
              type="text"
              placeholder="ex: G-123456789"
              value={googleAnalyticsTag}
              onChange={e => setGoogleAnalyticsTag(e.target.value)}
              onBlur={handleSubmitPixel}
              disabled={profile && profile.pro === 0}
            />

            <Space height="24px" />

            <H5>Google Tag Manager</H5>

            <Space height="4px" />

            <Description>
              Insira abaixo o ID de sua Tag do Google Tag Manager. Não sabe como
              encontrar o ID de sua Google Tag?{' '}
              <a
                href="https://intercom.help/offstore/pt-BR/articles/9184739-como-encontrar-a-tag-do-google-tag-manager"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver tutorial
              </a>
            </Description>

            <Input
              type="text"
              placeholder="ex: GTM-123456789"
              value={googleTagManager}
              onChange={e => setGoogleTagManager(e.target.value)}
              onBlur={handleSubmitPixel}
              disabled={profile && profile.pro === 0}
            />
          </Div>
        </DivBody>

        {showModal && (
          <>
            <ContainerModal onClick={() => setShowModal(false)} />
            <Modal>
              {inputMetatag && inputMetatag.length > 0 ? (
                <strong>Editar código HTML</strong>
              ) : (
                <strong>Adicionar código HTML</strong>
              )}

              <span>
                Cole abaixo o código HTML do Facebook para fazer a verificação
                do domínio
              </span>

              <input
                type="text"
                placeholder="Insira o código HTML aqui"
                value={inputMetatag}
                onChange={e => setInpuMetatag(e.target.value)}
              />
              <button type="button" onClick={handleInsertHtmlCode}>
                Salvar alteração
              </button>
            </Modal>
          </>
        )}
      </Container>
    </>
  );
}

export default ConversionPixel;

import styled from 'styled-components';

export const Container = styled.div`
  .dropzone {
    display: flex;
    width: 650px;
    height: 100vh;
    border: 1px solid red;
    padding: 10px 0;
    /* align-items: center; */
    /* justify-content: center; */
    flex-wrap: wrap;
  }
  .grid-item {
    margin: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: solid 1px #000;
  }

  > div {
    background: #fff;
    display: flex;
    margin: 0 5px 10px 5px;
    border-radius: 10px;
    padding: 3px 0px 0px 0px;
    height: 75px;
    width: 100%;

    /* align-items: center; */
    box-shadow: 12px 22px 35px rgba(0, 0, 0, 0.05);
  }
`;

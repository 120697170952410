import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { updateProfileRequest } from '../../store/modules/user/actions';
import CancelImage from '../../assets/icons/cancelx.svg';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivBody,
  DivButton,
  StyledFaInstagram,
  StyledFaFacebook,
  StyledFaTwitter,
  StyledFaPinterest,
  StyledFaYoutube,
} from './styles';

function SocialMediaModal({
  showModal,
  Modal,
  url_facebook,
  url_twitter,
  url_instagram,
  url_pinterest,
  url_youtube,
  email,
  user,
  isModal,
}) {
  const [facebook, setFacebook] = useState();
  const [twitter, setTwitter] = useState();
  const [instagram, setInstagram] = useState();
  const [pinterest, setPinterest] = useState();
  const [youtube, setYoutube] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    setFacebook(url_facebook);
  }, [url_facebook]);

  useEffect(() => {
    setTwitter(url_twitter);
  }, [url_twitter]);

  useEffect(() => {
    setInstagram(url_instagram);
  }, [url_instagram]);

  useEffect(() => {
    setPinterest(url_pinterest);
  }, [url_pinterest]);

  useEffect(() => {
    setYoutube(url_youtube);
  }, [url_youtube]);

  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  async function handleSaveData() {
    try {
      const data = {
        facebook:
          facebook && facebook.trim() !== '' && facebook.indexOf('http') === -1
            ? `http://${facebook}`
            : facebook,
        twitter:
          twitter && twitter.trim() !== '' && twitter.indexOf('http') === -1
            ? `http://${twitter}`
            : twitter,
        instagram:
          instagram &&
          instagram.trim() !== '' &&
          instagram.indexOf('http') === -1
            ? `http://${instagram}`
            : instagram,
        pinterest:
          pinterest &&
          pinterest.trim() !== '' &&
          pinterest.indexOf('http') === -1
            ? `http://${pinterest}`
            : pinterest,
        youtube:
          youtube && youtube.trim() !== '' && youtube.indexOf('http') === -1
            ? `http://${youtube}`
            : youtube,
        email,
        user,
      };
      dispatch(updateProfileRequest(data));
      handleModal();
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal} isModal={isModal}>
        <DivTitle>
          <div className="div-space" />
          <div>
            <span>Adicionar redes sociais</span>
          </div>
          <div className="div-space" />
        </DivTitle>
        <DivBody isModal={isModal}>
          <div>
            <StyledFaInstagram size={25} color="#A5A9B1" />
            <input
              type="text"
              placeholder="instagram.com/username"
              value={instagram}
              onChange={e => setInstagram(e.target.value)}
            />
          </div>
          <div>
            <StyledFaFacebook size={25} color="#A5A9B1" />
            <input
              type="text"
              placeholder="facebook.com/username"
              value={facebook}
              onChange={e => setFacebook(e.target.value)}
            />
          </div>
          <div>
            <StyledFaTwitter size={25} color="#A5A9B1" />
            <input
              type="text"
              placeholder="twitter.com/username"
              value={twitter}
              onChange={e => setTwitter(e.target.value)}
            />
          </div>
          <div>
            <StyledFaPinterest size={25} color="#A5A9B1" />
            <input
              type="text"
              placeholder="pinterest.com/username"
              value={pinterest}
              onChange={e => setPinterest(e.target.value)}
            />
          </div>
          <div>
            <StyledFaYoutube size={25} color="#A5A9B1" />
            <input
              type="text"
              placeholder="youtube.com/channel"
              value={youtube}
              onChange={e => setYoutube(e.target.value)}
            />
          </div>
        </DivBody>

        <DivButton>
          <button type="button" onClick={handleSaveData}>
            Salvar alterações
          </button>
        </DivButton>
      </Div>
    </ContainerGeral>
  );
}

export default SocialMediaModal;

import styled from 'styled-components';

export const TagWrapper = styled.div`
  background: rgba(13, 133, 255, 0.08);
  color: #0d85ff;
  margin-left: 8px;
  font-size: 11px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 17px;
  padding: 4px 6px;
  border-radius: 4px;
`;

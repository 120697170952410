/* eslint-disable react/prop-types */
import React from 'react';
import ConfigurationOffstorePay from '~/pages/Responsive/PaymentMethods/ConfigurationOffstorePay';
import { Container, ModalWrapper } from './styles';

export function OffstorePayModal({ children, closeModal }) {
  return (
    <Container>
      <ModalWrapper>
        <ConfigurationOffstorePay desktopMode closeModal={closeModal} />
      </ModalWrapper>
    </Container>
  );
}

import offstorePay from '~/services/offstorePay';

// {
// 	"business_activity": "8999", DEFAULT
// 	"user_id": 4,
// 	"seller_type": "businesses",
// 	"zoop_sell_plan_id": "plano_pro_offs_padrao_d1", DEFAULT
// 	"updateUserData": {
// 		"name": "Henrique Pires",
// 		"document_number": "35441342000121",
// 		"birthdate": "07/02/1999",
// 		"postal_code": "13290612"
// 	}
// }

export function useCreateSeller() {
  const handleCreateSeller = async ({
    userId,
    sellerData,
    bankAccountData,
  }) => {
    const [bankName, bankCode] = bankAccountData.bank.split(' - ');
//
    const body = {
      business_activity: sellerData.productType.code,
      zoop_sell_plan_id: sellerData.productType.plan,
      user_id: userId,
      seller_type:
        sellerData?.documentType === 'cnpj' ? 'businesses' : 'individuals',
      updateUserData: {
        name: sellerData.name,
        document_number: sellerData.documentNumber.replace(/\D+/g, ''),
        birthdate: sellerData.birthdate,
        postal_code: sellerData.postalCode.replace(/\D+/g, ''),
        address: {
          city: sellerData.city,
          line1: sellerData?.address,
          line2: sellerData?.addressNumber,
          neighborhood: sellerData?.neighborhood,
          postal_code: sellerData?.postalCode?.replace(/\D+/g, ''),
          state: sellerData?.state,
          country_code: 'BR',
        },
        monthly_revenue: sellerData.monthlyRevenue
      },
      bank_account: {
        name: bankName,
        bank_code: bankCode,
        agency_number: bankAccountData.agency,
        account_number: bankAccountData.account,
        account_type: 'checking',
        // account_holder: sellerData.name,
        account_holder:
          sellerData?.documentType === 'cnpj'
            ? sellerData.companyName
            : sellerData.name,
        document_type: sellerData.documentType,
        document_number:
          sellerData?.documentType === 'cnpj'
            ? sellerData.companyDocumentNumber.replace(/\D+/g, '')
            : sellerData.documentNumber.replace(/\D+/g, ''),
      },
      companyData:
        sellerData?.documentType === 'cnpj'
          ? {
              companyName: sellerData.companyName,
              document_number: sellerData.companyDocumentNumber.replace(
                /\D+/g,
                ''
              ),
              address: {
                city: sellerData?.companyCity,
                country_code: 'BR',
                line1: sellerData?.companyAddress,
                line2: sellerData?.companyAddressNumber,
                neighborhood: sellerData?.companyNeighborhood,
                postal_code: sellerData?.companyPostalCode?.replace(/\D+/g, ''),
                state: sellerData?.companyState,
              },
            }
          : undefined,
    };

    try {
      const { data } = await offstorePay.post('/seller', body);
      return data;
    } catch (error) {
      return error.response;
    }
  };

  return {
    handleCreateSeller,
  };
}

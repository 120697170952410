import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.showContainer ? 'flex' : 'none')};
  width: 100%;
  height: 68px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #0d85ff;
  /* border-top: 0.5px solid #d8dee5; */

  .div-info {
    /* margin-left: 16px; */
    margin-right: 0;
    flex: 1;
    flex-direction: column;

    > a {
      display: flex;
      align-items: baseline;
      text-decoration: none;
      margin-top: 4px;

      > span {
        font-weight: 400;
        font-size: 13px;
        margin-left: 16px;
        color: #fff;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: underline;
      }

      > img {
        margin-left: 6px;
      }
    }

    strong {
      font-weight: 600;
      font-size: 15px;
      color: #fff;
      margin-right: 4px;
      margin-left: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .buttons {
    justify-content: flex-end;
    padding-right: 16px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 44px; */
      height: 34px;
      padding: 0 12px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      height: 34px;
      padding: 0 12px;
      border-radius: 6px;
      font-weight: 500;
      font-size: 13px;
      background: #ffffff;
      color: #0d85ff;
      font-weight: 600;
      margin-left: 8px;
      text-decoration: none;
    }

    .secundary-button {
      border: 1px solid #ffffff;
      background: transparent;
      color: #ffffff;
    }
  }

  div {
    display: flex;
    width: 50%;
  }
`;

export const ButtonLinkStore = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 34px;
  padding: 0 12px;
  border-radius: 6px;
  font-size: 13px;
  background: #ffffff;
  color: #0d85ff;
  font-weight: 600 !important;
  margin-left: 8px;
  text-decoration: none;
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { Header, Container, ModalWrapper, Divider } from './styles';
import { ChangeBankAccountComponent } from '../../../ChangeBankAccount/ChangeBankComponent';

// descontinuado

export function ModalChangeBank() {
  return (
    <Container>
      <ModalWrapper>
        <Header>Alterar dados da conta</Header>
        <Divider />
        <ChangeBankAccountComponent />
      </ModalWrapper>
    </Container>
  );
}

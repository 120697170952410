import React from 'react';

// import { Svg } from './styles';

function DesktopIcon({ color, borderColor }) {
  return (
    <>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 1L12.781 6.59516L19 7.4979L14.5 11.8507L15.562 18L10 15.0952L4.438 18L5.5 11.8507L1 7.4979L7.219 6.59516L10 1Z"
          fill={color}
          stroke={borderColor}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
}

export default DesktopIcon;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { FiTrash2 } from 'react-icons/fi';
// import history from '../../../services/history';

import trashIcon from '../../../../assets/icons/trashIcon.svg';

import { Container, DivPromotions, Card } from './styles';

import { removePromotionRequest } from '../../../../store/modules/linkPrev/actions';

function TabPromotion() {
  const { promotions } = useSelector(state => state.linkPrev);

  const history = useHistory();

  const dispatch = useDispatch();

  function handleClickAlterPromotion(id) {
    history.push(`/promotionregister/${id}`);
  }

  return (
    <Container>
      <strong>Promoções ativas</strong>
      <span>
        Crie promoções para incentivar seus clientes a comprar. Por exemplo:
        “Frete grátis”.
      </span>
      <Link to="/promotionregister/0">
        <button type="button">Adicionar promoção</button>
      </Link>

      <DivPromotions>
        {promotions &&
          promotions.map(p => (
            <Card>
              <div
                className="left"
                onClick={() => handleClickAlterPromotion(p.id)}
              >
                <strong>{p.name}</strong>
                <span>{p.description}</span>
              </div>
              <div className="right">
                {/* <FiTrash2
                  size={18}
                  onClick={() => dispatch(removePromotionRequest(p.id))}
                /> */}
                <img
                  src={trashIcon}
                  onClick={() => dispatch(removePromotionRequest(p.id))}
                  alt="trash"
                />
              </div>
            </Card>
          ))}
      </DivPromotions>
    </Container>
  );
}

export default TabPromotion;

import styled from 'styled-components';
import { FaWhatsapp } from 'react-icons/fa';
import { DebounceInput } from 'react-debounce-input';

export const ContainerPrev = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 249px;
  height: 442px;
  background: ${props => props.background || '#0f61f1'};
  border-radius: 10px;
  overflow-y: scroll;
  position: fixed;
  box-shadow: 12px 22px 35px rgba(0, 0, 0, 0.05);

  > span {
    font-size: 13px;
    color: '#fff';
    margin-bottom: 15px;
  }

  /* CUSTOMIZAÇÃO SCROLL */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const DivAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;

  #avatar {
    /* border-style: solid;
    border-radius: 50%;
    border-color: #ededed3d;
    border-width: 1px;
    margin: 10px; */
  }
`;

export const SpanName = styled.span`
  margin-top: -9px;
  margin-left: 3px;
  color: ${props => props.color || '#fff'}!important;
`;

export const SpanDetails = styled.span`
  font-size: 13px;
  margin-bottom: 15px;
  color: ${props => props.color || '#fff'}!important;
`;

export const DivIcons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  margin-bottom: 30px;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivFilters = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
  padding: 0 10px;
`;

export const DivLinks = styled.div`
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 0 20px;
`;

export const SpanPrice = styled.span`
  margin: 0;
  font-size: 11px;
  color: ${props => props.color || '#a2a8b3'};
  margin-right: 3px;
  text-decoration: ${props => (props.hasPromo ? 'line-through' : 'none')};
`;

export const DivCover = styled.div`
  width: 100%;
  min-height: 80px;
  background: none;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

export const DivDescription = styled.div`
  width: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-top: 15px;
  margin-bottom: 15px;
  padding: 0 10px;
  /* line-height: 15px; */

  > span {
    text-align: center;
    width: 100%;
    font-size: 10px;
    color: #757575;
  }
`;

export const WhatsAppButton = styled.a`
  display: flex;
  text-decoration: none;
  border-style: solid;
  border-width: 1px;
  align-items: center;
  padding: 7px 35px;
  font-size: 10px;
  margin-bottom: 5px;
  border-color: #cccccc;
  color: #000;
  border-radius: 5px;
`;

export const BuyButton = styled.a`
  display: flex;
  justify-content: center;
  background: #000;
  text-decoration: none;
  border-radius: 4px;
  padding: 7px 0;
  color: ${props => props.color || '#FFF'};
  font-size: 9px;
  transition: background 0.2s;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.color || '#FFF'} !important;
`;

export const StyledDebounceInput = styled(DebounceInput)`
  /* display: flex; */
  background: #f2f2f4;
  padding: 7px 5px;
  border-radius: 5px;
  font-size: 10px;
  color: #757575;
  border-style: none;
  /* min-height: 50px; */
  width: 180px;
  margin-bottom: 13px;
  text-align: center;
`;

export const StyledFaWhatsapp = styled(FaWhatsapp)`
  margin-right: 3px;
  margin-bottom: 1px;
`;

export const ImgAvatar = styled.img`
  margin: 10px;
  width: 85px;
  height: 85px;
  border-radius: 50px;
`;

export const ButtonTag = styled.a`
  text-decoration: none;
  border-radius: 4px;
  padding: 5px 15px;
  margin: 4px 4px;
  color: ${props => props.color || '#FFF'};
  font-size: 9px;
  transition: background 0.2s;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.color || '#FFF'} !important;
  text-transform: uppercase;

  &:hover {
    background: ${props => props.color || '#4978dd'} !important;
    border: solid 1px;
    border-color: ${props => props.color || '#FFF'} !important;
    color: ${props => props.background || '#FFF'} !important;
  }

  &:active {
    background: red;
  }
`;

export const ButtonTagAll = styled.a`
  display: ${props => (props.show ? 'flex' : 'none')};
  text-decoration: none;
  border-radius: 4px;
  padding: 5px 15px;
  margin: 4px 4px;
  color: ${props => props.color || '#FFF'};
  font-size: 9px;
  transition: background 0.2s;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.color || '#FFF'} !important;
  text-transform: uppercase;

  &:hover {
    background: ${props => props.color || '#4978dd'} !important;
    border: solid 1px;
    border-color: ${props => props.color || '#FFF'} !important;
    color: ${props => props.background || '#FFF'} !important;
  }

  &:active {
    background: red;
  }
`;

export const DivProduct = styled.div`
  width: 100px;
  height: 160px;
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  word-wrap: break-word;

  > div {
    margin-bottom: 5px;
  }

  strong {
    width: 100%;
    font-size: 11px;
    font-weight: 600;
  }

  > span {
    margin: 0;
    font-size: 10px;
  }
`;

export const Img = styled.div`
  width: 100%;
  height: 100px;
  border-radius: 7px;
  margin-bottom: 5px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
`;

import React from 'react';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import BackButtonIcon from '../../assets/icons/backButton.svg';

function HeaderMobile({ title, url, tab, hasLabelPro, hideBorder }) {
  return (
    <Container hideBorder={hideBorder}>
      <div className="div-space">
        {url && url !== 'back' && (
          <Link to={{ pathname: url, tab: { tab } }}>
            <img src={BackButtonIcon} alt="" />
          </Link>
        )}

        {url && url === 'back' && (
          <button
            type="button"
            onClick={e => {
              e.preventDefault();
              window.history.back();
            }}
          >
            <img src={BackButtonIcon} alt="" />
          </button>
        )}
      </div>
      <div>
        <span>{title}</span>
      </div>
      <div className="div-space" />

      {hasLabelPro && <strong>PRO</strong>}
    </Container>
  );
}

export default HeaderMobile;

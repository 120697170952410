import React from 'react';
// import SettingsDesktop from '../Settings';
import PersonalizeResponsive from '../Responsive/Personalize';
import Maintenance from '../Maintenance';

function Personalize({ location }) {
  return (
    <>
      {window.innerWidth > 720 ? (
        <Maintenance />
      ) : (
        <PersonalizeResponsive location={location} />
      )}
    </>
  );
}

export default Personalize;

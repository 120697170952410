import React from 'react';

export function ReadyBoxcon({ color }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.6445 1V8.78154"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.78149H26.29V24.3446C26.29 24.8605 26.085 25.3554 25.7202 25.7202C25.3554 26.0849 24.8605 26.29 24.3446 26.29H2.94538C2.42943 26.29 1.93462 26.0849 1.56979 25.7202C1.20496 25.3554 1 24.8605 1 24.3446V8.78149Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 8.78154L3.91808 3.15938C4.23267 2.52571 4.71407 1.98981 5.31051 1.60932C5.90694 1.22884 6.59582 1.01818 7.30305 1H19.987C20.7108 1.00038 21.4201 1.20269 22.0354 1.58416C22.6506 1.96563 23.147 2.51116 23.4692 3.15938L26.29 8.78154"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.505 13.645L12.6759 21.4266L8.78516 18.512"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

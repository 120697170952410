import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import history from '../../../services/history';

// import { apiResize } from '../../../services/api-gateway';

import api from '../../../services/api';

import {
  updateProfileSuccess,
  updateProfileFailure,
  saveImageSuccess,
  saveImageLoadingSuccess,
  removeImageSuccess,
  ImageBannerSuccess,
  ImageBannerDesktopSuccess,
  updateCustomizeLinkUserSuccess,
  updateCustomizeLinkUserFailure,
} from './actions';

export function* updateProfile({ payload }) {
  try {
    const response = yield call(api.put, 'users', payload.data);

    yield put(updateProfileSuccess(response.data));

    if (!payload.hiddenToast) {
      toast.success('Alteração salva');
    }
  } catch (err) {
    yield put(updateProfileFailure());
    toast.error('Erro ao alterar informação!');
  }
}

export function* updateCustomizeLinkUser({ payload }) {
  try {
    const response = yield call(api.put, 'customizelink', payload.data);

    yield put(updateCustomizeLinkUserSuccess(response.data));
    toast.info('Link salvo!');
  } catch (err) {
    if (err.response.status === 406) {
      toast.error('Já existe um link com esse nome');
    }

    yield put(updateCustomizeLinkUserFailure());
  }
}

export function* saveImage({ payload }) {
  try {
    const { avatarImage, imageType, page } = payload;

    const data = new FormData();
    data.append('file', avatarImage);

    if (imageType === 'avatar') {
      yield call(api.post, 'deletefiles');
      const response = yield call(api.post, 'files', data);

      // yield apiResize({ key: response.data.path });

      yield put(saveImageSuccess(response.data, imageType));

      yield put(saveImageLoadingSuccess());

      toast.success('Avatar alterado!');

      history.push(page);
    } else if (imageType === 'cover') {
      // yield call(api.post, 'deletefilecover');
      // const response = yield call(api.post, 'filecover', data);

      // yield put(saveImageSuccess(response.data, imageType));
      toast.success('Capa alterada!');
    } else if (imageType === 'banner') {
      const response = yield call(api.post, 'filebanner', data);

      // yield apiResize({ key: response.data.path });

      yield put(saveImageSuccess(response.data, imageType));
      toast.success('Banner inserido!');
    } else if (imageType === 'banner-desktop') {
      const response = yield call(api.post, 'filebannerdesktop', data);

      // yield apiResize({ key: response.data.path });

      yield put(saveImageSuccess(response.data, imageType));
      toast.success('Banner inserido!');
    }
  } catch (err) {
    toast.error('Erro ao atualizar, confira seus dados');
  }
}

export function* removeImage({ payload }) {
  try {
    const { imageType, imageId } = payload;

    if (imageType === 'avatar') {
      yield call(api.post, 'deletefiles');
    } else if (imageType === 'cover') {
      yield call(api.post, 'deletefilecover');
    } else if (imageType === 'banner') {
      const banners = yield call(api.post, `deletefilebanner/${imageId}`);

      yield put(ImageBannerSuccess((banners && banners.data) || null));
    } else if (imageType === 'banner-desktop') {
      const banners = yield call(
        api.post,
        `deletefilebannerdesktop/${imageId}`
      );

      yield put(ImageBannerDesktopSuccess((banners && banners.data) || null));
    }

    yield put(removeImageSuccess(imageType, imageId));
  } catch (err) {
    toast.error('Erro ao atualizar avatar, confira seus dados');
  }
}

export function* updateOrderBanner({ payload }) {
  try {
    const { banners } = payload;
    const newBanners = yield call(api.put, 'orderimagebanner', banners);

    yield put(ImageBannerSuccess((newBanners && newBanners.data) || null));
  } catch (err) {
    toast.error('Erro ao atualizar banner');
  }
}

export function* updateOrderBannerDesktop({ payload }) {
  try {
    const { banners } = payload;
    const newBanners = yield call(api.put, 'orderimagebannerdesktop', banners);

    yield put(
      ImageBannerDesktopSuccess((newBanners && newBanners.data) || null)
    );
  } catch (err) {
    toast.error('Erro ao atualizar banner desktop');
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest(
    '@user/UPDATE_CUSTOMIZE_LINK_USER_REQUEST',
    updateCustomizeLinkUser
  ),
  takeLatest('@user/SAVE_IMAGE_REQUEST', saveImage),
  takeLatest('@user/REMOVE_IMAGE_REQUEST', removeImage),
  takeLatest('@user/UPDATE_ORDER_BANNER_REQUEST', updateOrderBanner),
  takeLatest(
    '@user/UPDATE_ORDER_BANNER_DESKTOP_REQUEST',
    updateOrderBannerDesktop
  ),
]);

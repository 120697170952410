/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { toast } from 'react-toastify';

import { uniqueId } from 'lodash';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import IntlCurrencyInput from 'react-intl-currency-input';
import api from '../../../../services/api';

import imgIcon from '../../../../assets/icons/noimg.svg';
import loader from '../../../../assets/icons/loading-white.gif';
import loaderGrey from '../../../../assets/icons/loader-grey.gif';
import ArrowIcon from '../../../../assets/icons/downarrow.svg';

import MinusIcon from '../../../../assets/icons/order/minusBlack.svg';
import PlusIcon from '../../../../assets/icons/order/plusBlack.svg';

import ImgPreview from '../../../../components/ImagePreview';
import ProductHeader from './ProductHeader';

import { isValidUrl } from '../../../../services/functions';

import TagModal from '../../../../components/TagModal';
import AddPriceAllVariationsModal from '../../../../components/Modals/AddPriceAllVariationsModal';

import {
  InsertOrUpdateLinkRequest,
  ListLinkPreview,
} from '../../../../store/modules/linkPrev/actions';

import VariantsModal from '../../../../components/Variations';

import {
  ContainerGeral,
  Container,
  DivLoading,
  Div,
  DivBody,
  GeneralDiv,
  // DivCategories,
  // ButtonSeeAll,
  DivOpenClose,
  DivProduct,
  DivDimensions,
  Input,
  // Tag,
  DivInputPrice,
  Label,
  DivTitle,
  DescriptionSize,
  StyledSelect,
  DivMore,
  ButtonMore,
  DivFooter,
  LabelImage,
  Message,
  DivImages,
  DivButton,
  DivStock,
  ButtonAddVariations,
  TableVariations,
  DivInputValueVariation,
  DivInputStockVariation,
} from './styles';

function ProductModal({ showModal, Modal, setProductId, id, userId }) {
  const [pro, setPro] = useState();
  const [product, setProduct] = useState();
  const [description, setDescription] = useState();

  const [price, setPrice] = useState(null);
  const [promoPrice, setPromoPrice] = useState(null);
  const [oldPrice, setOldPrice] = useState(null);
  const [oldPromoPrice, setOldPromoPrice] = useState(null);
  const [oldPriceVariation, setOldPriceVariation] = useState(null);
  const [oldPromotionPriceVariation, setOldPromotionPriceVariation] = useState(
    null
  );

  const [tags, setTags] = useState();
  const [hasHighlighted, setHasHighlighted] = useState(false);
  const [payLink, setPayLink] = useState();
  const [images, setImages] = useState([]);
  const [weight, setWeight] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [showDivStock, setShowDivStock] = useState(false);
  const [showDivAttributes, setShowDivAttributes] = useState(false);
  const [showDivDelivery, setShowDivDelivery] = useState(false);
  const [stockQuantity, setStockQuantity] = useState();

  const [showTagModal, setShowTagModal] = useState(false);

  const [allTags, setAllTags] = useState([]);

  const [variationPriceforAll, setVariationPriceforAll] = useState(0);

  const [objPrice, setObjPrice] = useState({
    label: '', // price or promotionPrice
    price: null,
  });

  const [
    showAddPriceAllVariationsModal,
    setShowAddPriceAllVariationsModal,
  ] = useState(false);
  const [showVariantsModal, setShowVariantsModal] = useState(false);
  const [arrayVariations, setArrayVariations] = useState([]);

  const [loading, setLoading] = useState(false);
  const [loadingModal, setLoadingModal] = useState(false);

  const [allActive, setAllActive] = useState(false);
  const [
    activeOrInactiveAllVariations,
    setActiveOrInactiveAllVariations,
  ] = useState(true);

  const recursiveArray = [];

  const [hide, setHide] = useState(true);
  const refUploadImage = useRef(null);

  const isLoading = useSelector(state => state.linkPrev.loadingRegisterProduct);

  const dispatch = useDispatch();

  const cleanFields = useCallback(() => {
    setProduct('');
    setDescription('');
    setPrice('');
    setOldPrice(null);
    setHasHighlighted(false);
    setPromoPrice('');
    setOldPromoPrice(null);
    setTags([]);
    setProductId(0);
    setHide(true);
    setStockQuantity('');
    setArrayVariations([]);
    setWeight('');
    setLength('');
    setHeight('');
    setWidth('');
    setShowDivDelivery(false);
    setShowDivAttributes(false);
    setShowDivStock(false);

    images.map(image => image.preview && URL.revokeObjectURL(image.preview));
    setImages([]);
  }, [images, setProductId]);

  const handleModal = useCallback(() => {
    Modal(false);
    cleanFields();
  }, [Modal, cleanFields]);

  async function LoadData() {
    dispatch(ListLinkPreview());
  }

  useEffect(() => {
    LoadData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function verifyActiveOrInactiveAllVariations(array) {
    if (array.length > 0) {
      if (array.filter(arr => arr.active === true).length === array.length) {
        return true;
      }

      if (array.filter(arr => arr.active === false).length === array.length) {
        return false;
      }
    }

    return null;
  }

  useEffect(() => {
    async function findProducts() {
      try {
        setLoadingModal(true);

        await api.get('/proplan').then(response => {
          setPro(response.data);
        });

        await api.get('/alltagsbyuser').then(response => {
          const tagsNoAll =
            response.data && response.data.filter(tag => tag.tag !== 'Todos');

          const tagsLabelsDuplicated =
            tagsNoAll &&
            tagsNoAll.map(tag => {
              if (tag.isDuplicated && tag.master_id) {
                return {
                  ...tag,
                  label: `${tag.label}(${tag.master.label})`,
                  tag: `${tag.tag}(${tag.master.tag})`,
                  value: `${tag.value}(${tag.master.value})`,
                };
              }

              return tag;
            });

          setAllTags(tagsLabelsDuplicated);
        });

        if (id === 0) {
          return 0;
        }

        setLoading(true);

        const findProduct = await api.get(`/product/${id}`);

        if (!findProduct) {
          toast.error('Algo deu errado, contate o Administrador.');
          setLoading(false);
          return 0;
        }

        setProduct(findProduct.data && findProduct.data.title);
        setDescription(findProduct.data && findProduct.data.description);
        setPrice(
          findProduct.data && findProduct.data.price
            ? findProduct.data.price
            : null
        );

        setOldPrice(
          findProduct.data && findProduct.data.price
            ? findProduct.data.price
            : null
        );

        const tagsNoAll =
          findProduct.data &&
          findProduct.data.tags.filter(tag => tag.tag !== 'Todos');

        setTags(tagsNoAll);

        if (findProduct.data && findProduct.data.promotion_price > 0) {
          setPromoPrice(findProduct.data.promotion_price);
          setOldPromoPrice(findProduct.data.promotion_price);
          setHide(false);
        } else {
          setPromoPrice(null);
          setOldPromoPrice(null);
        }

        if (findProduct.data && findProduct.data.link) {
          setPayLink(findProduct.data.link);
          setHide(false);
        } else {
          setPayLink('');
        }

        setHasHighlighted(findProduct.data && findProduct.data.highlighted);

        setWeight(findProduct.data && findProduct.data.weight);
        setLength(findProduct.data && findProduct.data.length);
        setHeight(findProduct.data && findProduct.data.height);
        setWidth(findProduct.data && findProduct.data.width);

        setStockQuantity(findProduct.data && findProduct.data.stock_quantity);

        const findImagensLink = await api.get(`/filelinks/${id}`);

        setImages(findImagensLink.data);

        const arrayGridVariations = await api.get(`/gridattributes/${id}`);
        setArrayVariations(arrayGridVariations.data);

        if (arrayGridVariations.data.length > 0) {
          setShowDivAttributes(true);
          setHide(false);
        }

        const verifyAllVariations = verifyActiveOrInactiveAllVariations(
          arrayGridVariations.data
        );

        setAllActive(verifyAllVariations === null ? true : verifyAllVariations);
        setActiveOrInactiveAllVariations(verifyAllVariations);

        setLoading(false);

        return 0;
      } catch (err) {
        toast.error('Ocorreu um erro ao carregar produto');
      } finally {
        setLoadingModal(false);
      }
    }
    findProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorder(
      images,
      result.source.index,
      result.destination.index
    );

    setImages(reorderedImages);
  }

  async function handleSubmit() {
    if (!product | (product && product.trim() === '')) {
      toast.error('Digite o nome do produto');
      return 0;
    }

    if (!hide && !!payLink) {
      if (!isValidUrl(payLink)) {
        toast.error('Link de pagamento não é válido');
        return 0;
      }
    }

    // if (hasHighlighted) {
    //   if (images && images.length === 0) {
    //     toast.error('Insira uma imagem para destacar o produto');
    //     return 0;
    //   }
    // }

    if (weight > 30) {
      toast.error('Peso máximo permitido: 30kg');
      return 0;
    }

    if (length > 100) {
      toast.error('Comprimento máximo permitido: 100cm');
      return 0;
    }

    if (height > 100) {
      toast.error('Altura máxima permitida: 100cm');
      return 0;
    }

    if (width > 100) {
      toast.error('Largura máxima permitida: 100cm');
      return 0;
    }

    // if (arrayVariations && arrayVariations.length > 0) {
    //   if (arrayVariations.find(a => a.price === null)) {
    //     toast.error('Preencha o preço de todas as variações');
    //     return 0;
    //   }
    // }

    const link = {
      title: product,
      description,
      price: price === 0 || price === '' ? null : price,
      highlighted: hasHighlighted,
      promotion_price:
        promoPrice === 0 || promoPrice === '' ? null : promoPrice,
      link:
        !hide && !!payLink
          ? payLink.indexOf('http') === -1
            ? `http://${payLink}`
            : payLink
          : null,
      tags,
      weight: weight || null,
      length: length || null,
      height: height || null,
      width: width || null,
      stock_quantity:
        arrayVariations && arrayVariations.length > 0
          ? null
          : stockQuantity === ''
          ? null
          : stockQuantity,
    };

    const newArray = arrayVariations.map(av => {
      return {
        ...av,
        price: av.price === 0 || av.price === '' ? null : av.price,
        promotion_price:
          av.promotion_price === 0 || av.promotion_price === ''
            ? null
            : av.promotion_price,
      };
    });

    dispatch(InsertOrUpdateLinkRequest(link, id, images, newArray));
    handleModal();

    // history.push('/dashboard');
    return 0;
  }

  async function handleChangeImage(e) {
    const selectedImages = e.target.files;
    const arr = [];

    const maxImages = images.length >= 0 ? 8 - images.length : 0;

    const sizeArray =
      selectedImages.length >= maxImages ? maxImages : selectedImages.length;

    for (let i = 0; i < sizeArray; i += 1) {
      if (selectedImages[i].size < 15000000) {
        // 5120000 = 5mb

        arr.push({
          id: uniqueId('image_'),
          file: selectedImages[i],
          preview: URL.createObjectURL(selectedImages[i]),
        });
      } else {
        toast.error('Imagem muito grande');
        break;
      }
    }
    setImages(images.concat(arr));
  }

  const filterImages = useCallback(
    image => {
      const newImages = images.filter(img => img.id !== image.id);
      setImages(newImages);
    },
    [images]
  );

  async function deleteImages(image) {
    if (image.preview) {
      URL.revokeObjectURL(image.preview);
      filterImages(image);
    } else {
      const deleteImage = await api.post(`deletefilelinks/${image.id}`);

      if (deleteImage.status === 200) {
        filterImages(image);
      }
    }
  }

  const imageSize = useMemo(() => images.length, [images]);

  const descriptionSize = useMemo(
    () => (description && description.length) || 0,
    [description]
  );

  // costante criada para remover a tag "Todos" da seleção
  // const tagNoAll = useMemo(
  //   () => Alltags && Alltags.filter(tag => tag.tag !== 'Todos'),
  //   []
  // );

  function handleChangeStar(valueStar) {
    setHasHighlighted(valueStar);
  }

  function handleBlurPriceVariations(e, index) {
    const array = [...arrayVariations];

    if (oldPriceVariation !== e.target.value) {
      array[index].price = e.target.value;

      setObjPrice({ label: 'variationPrice', price: e.target.value });
      setShowAddPriceAllVariationsModal(true);

      setArrayVariations(array);
    }
  }

  function handleChangePriceVariations(e, index) {
    const array = [...arrayVariations];
    array[index].price = e.target.value;

    setArrayVariations(array);
  }

  function ChangePriceAllVariations() {
    const array = [...arrayVariations];

    if (objPrice.label === 'price' || objPrice.label === 'variationPrice') {
      setOldPrice(objPrice.price);
    }

    if (
      objPrice.label === 'promoPrice' ||
      objPrice.label === 'variationPromoPrice'
    ) {
      setOldPromoPrice(objPrice.price);
    }

    array.map((arr, index) => {
      if (objPrice.label === 'price' || objPrice.label === 'variationPrice') {
        array[index].price = objPrice.price;
      } else {
        array[index].promotion_price = objPrice.price;
      }

      if (objPrice.label === 'variationPrice') {
        setPrice(objPrice.price);
      }

      if (objPrice.label === 'variationPromoPrice') {
        setPromoPrice(objPrice.price);
      }

      setShowAddPriceAllVariationsModal(false);

      return arr;
    });

    setArrayVariations(array);
  }

  const handleBlurPrice = e => {
    if (arrayVariations && arrayVariations.length > 0 && price !== oldPrice) {
      setObjPrice({
        label: 'price',
        price: e.target.value,
      });
      setShowAddPriceAllVariationsModal(true);
    } else {
      setOldPrice(e.target.value);
    }
  };

  const handleBlurPromotionPrice = e => {
    if (
      arrayVariations &&
      arrayVariations.length > 0 &&
      promoPrice !== oldPromoPrice
    ) {
      setObjPrice({
        label: 'promoPrice',
        price: e.target.value,
      });
      setShowAddPriceAllVariationsModal(true);
    } else {
      setOldPromoPrice(e.target.value);
    }
  };

  function handleBlurPromotionPriceVariations(e, index) {
    const array = [...arrayVariations];

    if (oldPromotionPriceVariation !== e.target.value) {
      array[index].promotion_price = e.target.value;

      setObjPrice({
        label: 'variationPromoPrice',
        price: e.target.value,
      });
      setShowAddPriceAllVariationsModal(true);

      setArrayVariations(array);
    }
  }

  function handleChangePromotionPriceVariations(e, index) {
    const array = [...arrayVariations];

    array[index].promotion_price = e.target.value;

    setArrayVariations(array);
  }

  function handleBlurStockVariations(index, text) {
    const array = [...arrayVariations];

    array[index].stock_quantity = parseInt(text, 10);

    setArrayVariations(array);
  }

  function handleIncrementStockVariations(index, value, increment) {
    const array = [...arrayVariations];

    if (increment) {
      if (array[index].stock_quantity) {
        array[index].stock_quantity = +parseInt(value, 10) + 1;
      } else {
        array[index].stock_quantity = 1;
      }
    } else if (array[index].stock_quantity) {
      array[index].stock_quantity = +parseInt(value, 10) - 1;
    }

    setArrayVariations(array);
  }

  function variantsModal(variants_modal) {
    setShowVariantsModal(variants_modal);
  }

  function loadGrid(obj) {
    setArrayVariations(obj);
    setAllActive(true);
  }

  function recursive(master_id) {
    const response = allTags.find(t => t.id === master_id);

    // setTags([...tags, response]);

    if (response.master_id) {
      recursive(response.master_id);
    }

    const find = tags && tags.find(t => t.id === response.id);

    if (!find) {
      recursiveArray.push(response);
    }
  }

  function addTagOnArray(tag) {
    // const hasMaster = tags && tags.find(t => t.master_id === tag.id);

    // if (hasMaster) {
    //   return 0;
    // }

    const exists = tags && tags.find(t => t.id === tag.id);

    if (exists) {
      const newObj = tags.filter(t => t.id !== tag.id);
      setTags(newObj);
    } else if (tag.master_id) {
      recursive(tag.master_id);
      const concat = tags ? tags.concat(recursiveArray) : recursiveArray;
      setTags([...concat, tag]);
    } else {
      setTags(tags ? [...tags, tag] : [tag]);
    }
  }

  function createCategory(e) {
    if ((e && tags && e.length > tags.length) || !tags) {
      const last = e && e[e.length - 1];

      if (last && last.__isNew__) {
        const newLast = {
          ...last,
          id: uuidv4(),
        };

        addTagOnArray(newLast);
      } else {
        addTagOnArray(last);
      }
    } else {
      setTags(e);
    }
  }

  async function tagModal(modal) {
    setShowTagModal(modal);

    await api.get('/alltagsbyuser').then(response => {
      const tagsNoAll =
        response.data && response.data.filter(tag => tag.tag !== 'Todos');

      const tagsLabelsDuplicated =
        tagsNoAll &&
        tagsNoAll.map(tag => {
          if (tag.isDuplicated && tag.master_id) {
            return {
              ...tag,
              label: `${tag.label}(${tag.master.label})`,
              tag: `${tag.tag}(${tag.master.tag})`,
              value: `${tag.value}(${tag.master.value})`,
            };
          }

          return tag;
        });

      setAllTags(tagsLabelsDuplicated);
    });
  }

  function addPriceAllVariationsModal(modal, confirm) {
    setShowAddPriceAllVariationsModal(modal);

    if (confirm) {
      ChangePriceAllVariations();
    } else {
      if (objPrice.label === 'price') {
        setPrice(oldPrice);
      }

      if (objPrice.label === 'promoPrice') {
        setPromoPrice(oldPromoPrice);
      }
    }
  }

  const handleChangePrice = e => {
    setPrice(e.target.value);
    // event.preventDefault();

    // console.log(value); // value without mask (ex: 1234.56)
    // console.log(maskedValue); // masked value (ex: R$1234,56)
  };

  const handleChangePromoPrice = e => {
    setPromoPrice(e.target.value);
    // event.preventDefault();
  };

  const handleChangeTitle = e => {
    const value = e.target.value
      .replace('&', '')
      .replace('<', '')
      .replace('>', '');
    setProduct(value);
  };

  const handleChangeDescription = e => {
    const value = e.target.value
      .replace('&', '')
      .replace('<', '')
      .replace('>', '');
    setDescription(value);
  };

  function onChangeCheck(index) {
    const array = [...arrayVariations];

    array[index].active = !array[index].active;

    setArrayVariations(array);

    const verifyAllVariations = verifyActiveOrInactiveAllVariations(array);

    setAllActive(verifyAllVariations === null ? true : verifyAllVariations);

    setActiveOrInactiveAllVariations(verifyAllVariations);
  }

  function onChangeCheckAll(value) {
    const array =
      arrayVariations &&
      arrayVariations.map(arr => {
        arr.active = value;
        return arr;
      });

    setArrayVariations(array);
    setAllActive(value);
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <TagModal
        showModal={showTagModal}
        Modal={tagModal}
        TagId={0}
        updateTagsComponent={() => {}}
        handleClickDelete={() => {}}
        pro={pro}
        userId={userId}
      />
      <AddPriceAllVariationsModal
        showModal={showAddPriceAllVariationsModal}
        Modal={addPriceAllVariationsModal}
        obj={objPrice}
      />

      <Div showModal={showModal} moreOptions={!hide}>
        <ProductHeader
          title={id > 0 ? 'Editar produto' : 'Adicionar produto'}
          hasHighlighted={hasHighlighted}
          handleChangeStar={handleChangeStar}
          handleModal={handleModal}
          loading={loading}
        />

        <VariantsModal
          showModal={showVariantsModal}
          Modal={variantsModal}
          loadGrid={loadGrid}
          productId={id || 0}
          price={price}
          promoPrice={promoPrice}
        />

        {(loadingModal && (
          <DivLoading>
            <img src={loaderGrey} alt="imagem de carregamento" />
          </DivLoading>
        )) || (
          <>
            <DivBody
              hasImage={images && images.length > 0}
              manyImages={images && images.length > 6}
            >
              <GeneralDiv width="100%">
                <div className="div-line">
                  <DivProduct>
                    <span>Nome</span>
                    <Input
                      type="text"
                      placeholder="Ex: Camiseta preta"
                      tabIndex="1"
                      value={product}
                      onChange={handleChangeTitle}
                    />
                  </DivProduct>

                  <div className="div-price">
                    <DivTitle>
                      <span>Preço</span>

                      {/* {arrayVariations && arrayVariations.length > 0 && (
                        <Label>
                          <svg
                            width="9"
                            height="11"
                            viewBox="0 0 9 11"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.4 1.57143C3.97565 1.57143 3.56869 1.73699 3.26863 2.03169C2.96857 2.32639 2.8 2.72609 2.8 3.14286V3.92857H6V3.14286C6 2.72609 5.83143 2.32639 5.53137 2.03169C5.23131 1.73699 4.82435 1.57143 4.4 1.57143ZM1.2 3.14286V3.92857C0.88174 3.92857 0.576516 4.05274 0.351472 4.27377C0.126428 4.49479 0 4.79457 0 5.10714V9.82143C0 10.134 0.126428 10.4338 0.351472 10.6548C0.576516 10.8758 0.88174 11 1.2 11H7.6C7.91826 11 8.22348 10.8758 8.44853 10.6548C8.67357 10.4338 8.8 10.134 8.8 9.82143V5.10714C8.8 4.79457 8.67357 4.49479 8.44853 4.27377C8.22348 4.05274 7.91826 3.92857 7.6 3.92857V3.14286C7.6 2.30932 7.26286 1.50992 6.66274 0.920522C6.06263 0.331122 5.24869 0 4.4 0C3.55131 0 2.73737 0.331122 2.13726 0.920522C1.53714 1.50992 1.2 2.30932 1.2 3.14286ZM4.4 8.44643C4.53132 8.44643 4.66136 8.42102 4.78268 8.37167C4.90401 8.32231 5.01425 8.24997 5.10711 8.15877C5.19997 8.06756 5.27362 7.95929 5.32388 7.84014C5.37413 7.72098 5.4 7.59326 5.4 7.46429C5.4 7.33531 5.37413 7.20759 5.32388 7.08844C5.27362 6.96928 5.19997 6.86101 5.10711 6.76981C5.01425 6.67861 4.90401 6.60626 4.78268 6.5569C4.66136 6.50755 4.53132 6.48214 4.4 6.48214C4.13478 6.48214 3.88043 6.58562 3.69289 6.76981C3.50536 6.95399 3.4 7.2038 3.4 7.46429C3.4 7.72477 3.50536 7.97458 3.69289 8.15877C3.88043 8.34295 4.13478 8.44643 4.4 8.44643Z"
                              fill="#0D85FF"
                            />
                          </svg>

                          <span>Edite o preço nas variações</span>
                        </Label>
                      )} */}
                    </DivTitle>

                    <DivInputPrice
                      // disabled={arrayVariations && arrayVariations.length > 0}
                      warning={
                        arrayVariations &&
                        arrayVariations.find(a => a.price !== price)
                      }
                    >
                      <div>
                        <div>R$</div>
                      </div>

                      <input
                        type="number"
                        placeholder="0,00"
                        className="currency-input-price"
                        value={price}
                        onChange={handleChangePrice}
                        onBlur={handleBlurPrice}
                      />

                      {/* <IntlCurrencyInput
                        currency="BRL"
                        className="currency-input-price"
                        config={{
                          locale: 'pt-BR',
                          formats: {
                            number: {
                              BRL: {
                                // style: 'currency',
                                currency: 'BRL',
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              },
                            },
                          },
                        }}
                        value={price}
                        onChange={handleChangePrice}
                        onBlur={handleBlurPrice}
                      /> */}
                    </DivInputPrice>

                    {arrayVariations &&
                      arrayVariations.find(a => a.price !== price) && (
                        <span className="warning">
                          Você tem preços diferentes nas variações, o preço que
                          aparecerá no produto será o menor preço das variações
                        </span>
                      )}
                  </div>
                </div>
              </GeneralDiv>

              <GeneralDiv width="100%">
                <div className="div-description">
                  <span>Descrição</span>
                  <textarea
                    cols="30"
                    rows="30"
                    placeholder="Ex: Camiseta preta de algodão. Tamanhos P, M e G."
                    tabIndex="3"
                    maxLength="10000"
                    value={description}
                    onChange={handleChangeDescription}
                  />
                  <DescriptionSize>{descriptionSize}/10000</DescriptionSize>
                </div>
              </GeneralDiv>
              <GeneralDiv width="100%">
                {/* <span>Categorias</span> */}

                <div className="div-select">
                  <span>Categorias</span>
                  <StyledSelect
                    id="selected"
                    placeholder="Ex: Camisetas, calças, meias"
                    isMulti
                    name="tg"
                    styles={{
                      control: (provided, state) => ({
                        ...provided,
                        borderWidth: state.isFocused ? '0.5px' : '0.5px',
                      }),
                    }}
                    theme={theme => ({
                      ...theme,
                      // borderRadius: 0,
                      colors: {
                        ...theme.colors,
                        primary: '#D8DEE5',
                      },
                    })}
                    tabIndex={Number(4)}
                    options={allTags}
                    value={tags}
                    onChange={createCategory}
                    // onChange={addTagOnArray}
                    // onBlur={e => saveData(linkk.id)}
                  />
                </div>

                {/* <DivCategories>
            <button type="button" onClick={() => setShowTagModal(true)}>
              <img src={plusIcon} alt="plus" />
            </button>

            {allTags &&
              allTags.map((tag, index) => (
                <>
                  {index <= indexCategories && (
                    <Tag
                      // select={tags && tags.find(t => t.id === tag.id)}
                      className={
                        tags && tags.find(t => t.master_id === tag.id)
                          ? 'master'
                          : tags && tags.find(t => t.id === tag.id)
                          ? 'selected'
                          : 'false'
                      }
                      onClick={() => addTagOnArray(tag)}
                    >
                      {tag.value}{' '}
                      {tag.isDuplicated && tag.master_id && (
                        <span>&nbsp;{tag.master.value}</span>
                      )}
                    </Tag>
                  )}
                </>
              ))}

            {allTags && allTags.length > limitNumbersCategoriesForSeeAll && (
              <>
                {(seeAllCategories && (
                  <ButtonSeeAll
                    onClick={() => {
                      setSeeAllCategories(false);
                      setIndexCategories(limitNumbersCategoriesForSeeAll);
                    }}
                  >
                    Ver menos...
                  </ButtonSeeAll>
                )) || (
                  <ButtonSeeAll
                    onClick={() => {
                      setSeeAllCategories(true);
                      setIndexCategories(allTags.length);
                    }}
                  >
                    Ver todas...
                  </ButtonSeeAll>
                )}
              </>
            )}
          </DivCategories> */}
              </GeneralDiv>
              <GeneralDiv width="100%" hide={hide} nopadding>
                <DivMore>
                  <DivTitle>
                    <span>Preço promocional</span>

                    {/* {arrayVariations && arrayVariations.length > 0 && (
                      <Label>
                        <svg
                          width="9"
                          height="11"
                          viewBox="0 0 9 11"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M4.4 1.57143C3.97565 1.57143 3.56869 1.73699 3.26863 2.03169C2.96857 2.32639 2.8 2.72609 2.8 3.14286V3.92857H6V3.14286C6 2.72609 5.83143 2.32639 5.53137 2.03169C5.23131 1.73699 4.82435 1.57143 4.4 1.57143ZM1.2 3.14286V3.92857C0.88174 3.92857 0.576516 4.05274 0.351472 4.27377C0.126428 4.49479 0 4.79457 0 5.10714V9.82143C0 10.134 0.126428 10.4338 0.351472 10.6548C0.576516 10.8758 0.88174 11 1.2 11H7.6C7.91826 11 8.22348 10.8758 8.44853 10.6548C8.67357 10.4338 8.8 10.134 8.8 9.82143V5.10714C8.8 4.79457 8.67357 4.49479 8.44853 4.27377C8.22348 4.05274 7.91826 3.92857 7.6 3.92857V3.14286C7.6 2.30932 7.26286 1.50992 6.66274 0.920522C6.06263 0.331122 5.24869 0 4.4 0C3.55131 0 2.73737 0.331122 2.13726 0.920522C1.53714 1.50992 1.2 2.30932 1.2 3.14286ZM4.4 8.44643C4.53132 8.44643 4.66136 8.42102 4.78268 8.37167C4.90401 8.32231 5.01425 8.24997 5.10711 8.15877C5.19997 8.06756 5.27362 7.95929 5.32388 7.84014C5.37413 7.72098 5.4 7.59326 5.4 7.46429C5.4 7.33531 5.37413 7.20759 5.32388 7.08844C5.27362 6.96928 5.19997 6.86101 5.10711 6.76981C5.01425 6.67861 4.90401 6.60626 4.78268 6.5569C4.66136 6.50755 4.53132 6.48214 4.4 6.48214C4.13478 6.48214 3.88043 6.58562 3.69289 6.76981C3.50536 6.95399 3.4 7.2038 3.4 7.46429C3.4 7.72477 3.50536 7.97458 3.69289 8.15877C3.88043 8.34295 4.13478 8.44643 4.4 8.44643Z"
                            fill="#0D85FF"
                          />
                        </svg>

                        <span>Edite o preço nas variações</span>
                      </Label>
                    )} */}
                  </DivTitle>

                  <DivInputPrice
                    // disabled={arrayVariations && arrayVariations.length > 0}
                    warning={
                      arrayVariations &&
                      arrayVariations.find(
                        a => a.promotion_price !== promoPrice
                      )
                    }
                  >
                    <div>
                      <div>R$</div>
                    </div>

                    <input
                      type="number"
                      placeholder="0,00"
                      className="currency-input-price"
                      value={promoPrice}
                      onChange={handleChangePromoPrice}
                      onBlur={handleBlurPromotionPrice}
                    />

                    {/* <IntlCurrencyInput
                      currency="BRL"
                      className="currency-input-price"
                      config={{
                        locale: 'pt-BR',
                        formats: {
                          number: {
                            BRL: {
                              // style: 'currency',
                              currency: 'BRL',
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            },
                          },
                        },
                      }}
                      value={promoPrice}
                      onChange={handleChangePromoPrice}
                      onBlur={handleBlurPromotionPrice}
                    /> */}
                  </DivInputPrice>

                  {arrayVariations &&
                    arrayVariations.find(
                      a => a.promotion_price !== promoPrice
                    ) && (
                      <span className="warning">
                        Você tem preços diferentes nas variações, o preço que
                        aparecerá no produto será o menor preço das variações
                      </span>
                    )}
                </DivMore>

                {/* <DivMore>
            <span>Link de pagamento</span>
            <Input
              type="text"
              placeholder="Ex: pag.ae/seulink"
              tabIndex="6"
              value={payLink}
              onChange={e => setPayLink(e.target.value)}
            />
          </DivMore> */}

                {arrayVariations && arrayVariations.length === 0 && (
                  <DivOpenClose first>
                    <div
                      className="header"
                      onClick={
                        pro >= 1 ? () => setShowDivStock(!showDivStock) : ''
                      }
                    >
                      <div>
                        <div>
                          <strong>Estoque</strong>
                          <span>(Opcional)</span>
                        </div>
                      </div>

                      {pro === 0 && <div className="blue">PRO</div>}

                      <img src={ArrowIcon} alt="arrow" />
                    </div>

                    {showDivStock && (
                      <DivStock>
                        <div>
                          <strong>Quantidade em estoque</strong>

                          {arrayVariations && arrayVariations.length > 0 && (
                            <Label>
                              <svg
                                width="9"
                                height="11"
                                viewBox="0 0 9 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M4.4 1.57143C3.97565 1.57143 3.56869 1.73699 3.26863 2.03169C2.96857 2.32639 2.8 2.72609 2.8 3.14286V3.92857H6V3.14286C6 2.72609 5.83143 2.32639 5.53137 2.03169C5.23131 1.73699 4.82435 1.57143 4.4 1.57143ZM1.2 3.14286V3.92857C0.88174 3.92857 0.576516 4.05274 0.351472 4.27377C0.126428 4.49479 0 4.79457 0 5.10714V9.82143C0 10.134 0.126428 10.4338 0.351472 10.6548C0.576516 10.8758 0.88174 11 1.2 11H7.6C7.91826 11 8.22348 10.8758 8.44853 10.6548C8.67357 10.4338 8.8 10.134 8.8 9.82143V5.10714C8.8 4.79457 8.67357 4.49479 8.44853 4.27377C8.22348 4.05274 7.91826 3.92857 7.6 3.92857V3.14286C7.6 2.30932 7.26286 1.50992 6.66274 0.920522C6.06263 0.331122 5.24869 0 4.4 0C3.55131 0 2.73737 0.331122 2.13726 0.920522C1.53714 1.50992 1.2 2.30932 1.2 3.14286ZM4.4 8.44643C4.53132 8.44643 4.66136 8.42102 4.78268 8.37167C4.90401 8.32231 5.01425 8.24997 5.10711 8.15877C5.19997 8.06756 5.27362 7.95929 5.32388 7.84014C5.37413 7.72098 5.4 7.59326 5.4 7.46429C5.4 7.33531 5.37413 7.20759 5.32388 7.08844C5.27362 6.96928 5.19997 6.86101 5.10711 6.76981C5.01425 6.67861 4.90401 6.60626 4.78268 6.5569C4.66136 6.50755 4.53132 6.48214 4.4 6.48214C4.13478 6.48214 3.88043 6.58562 3.69289 6.76981C3.50536 6.95399 3.4 7.2038 3.4 7.46429C3.4 7.72477 3.50536 7.97458 3.69289 8.15877C3.88043 8.34295 4.13478 8.44643 4.4 8.44643Z"
                                  fill="#0D85FF"
                                />
                              </svg>

                              <span>Edite o estoque nas variações</span>
                            </Label>
                          )}
                        </div>

                        <span>
                          O estoque irá reduzir automticamente a cada pedido que
                          você receber. Se você deixar em branco, o estoque será
                          considerado infinito.
                        </span>

                        <div
                          className={
                            arrayVariations &&
                            arrayVariations.length > 0 &&
                            'disabled'
                          }
                        >
                          <button
                            type="button"
                            onClick={() =>
                              setStockQuantity(
                                stockQuantity && parseInt(stockQuantity, 10) - 1
                              )
                            }
                            disabled={
                              arrayVariations && arrayVariations.length > 0
                            }
                          >
                            <img src={MinusIcon} alt="minus" />
                          </button>

                          {/* {stockQuantity || } */}
                          {/* <img src={InfinityIcon} alt="infinity" /> */}
                          <input
                            type="number"
                            onChange={e => setStockQuantity(e.target.value)}
                            value={stockQuantity}
                            placeholder="∞"
                            disabled={
                              arrayVariations && arrayVariations.length > 0
                            }
                          />

                          <button
                            type="button"
                            onClick={() =>
                              setStockQuantity(
                                stockQuantity
                                  ? parseInt(stockQuantity, 10) + 1
                                  : 1
                              )
                            }
                            disabled={
                              arrayVariations && arrayVariations.length > 0
                            }
                          >
                            <img src={PlusIcon} alt="plus" />
                          </button>
                        </div>
                      </DivStock>
                    )}
                  </DivOpenClose>
                )}

                <DivOpenClose
                  first={arrayVariations && arrayVariations.length > 0}
                >
                  <div
                    className="header"
                    onClick={
                      pro >= 1
                        ? () => setShowDivAttributes(!showDivAttributes)
                        : () => {}
                    }
                  >
                    <div>
                      <div>
                        <strong>Variações</strong>
                        <span>(Opcional)</span>
                      </div>

                      <span>Exemplo: Cor, tamanho, etc</span>
                    </div>

                    {pro === 0 && <div className="blue">PRO</div>}
                    <img src={ArrowIcon} alt="arrow" />
                  </div>

                  {showDivAttributes && (
                    <>
                      <ButtonAddVariations
                        type="button"
                        onClick={() => setShowVariantsModal(true)}
                      >
                        <svg
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8.5649 1.2176C8.69736 1.08514 8.85461 0.980073 9.02767 0.908387C9.20074 0.836701 9.38623 0.799805 9.57355 0.799805C9.76087 0.799805 9.94636 0.836701 10.1194 0.908387C10.2925 0.980073 10.4497 1.08514 10.5822 1.2176C10.7147 1.35006 10.8197 1.50731 10.8914 1.68038C10.9631 1.85344 11 2.03893 11 2.22626C11 2.41358 10.9631 2.59907 10.8914 2.77213C10.8197 2.9452 10.7147 3.10245 10.5822 3.23491L3.7738 10.0433L1 10.7998L1.75649 8.02601L8.5649 1.2176Z"
                            stroke="white"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        {arrayVariations && arrayVariations.length > 0
                          ? 'Editar variações'
                          : 'Adicionar variações'}
                      </ButtonAddVariations>

                      {arrayVariations && arrayVariations.length > 0 && (
                        <TableVariations>
                          <thead>
                            <tr>
                              <th>Variações</th>
                              {/* Verificando se o campo é verdadeiro ou falso, porque existe a validação se ele é nulo também */}
                              <th
                                className={
                                  activeOrInactiveAllVariations === null
                                    ? 'title disabled-tr'
                                    : 'title'
                                }
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={allActive}
                                    onChange={() =>
                                      onChangeCheckAll(!allActive)
                                    }
                                    disabled={
                                      activeOrInactiveAllVariations === null
                                    }
                                  />
                                  <span className="slider round" />
                                </label>
                              </th>
                              <th>Preço</th>
                              <th>Preço promocional</th>
                              <th>Estoque</th>
                            </tr>
                          </thead>

                          <tbody>
                            {arrayVariations.map((arr, index) => (
                              <tr
                                key={arr.label}
                                className={!arr.active ? 'disabled-tr' : ''}
                              >
                                <td className="title">{arr.label}</td>

                                <td className="checkbox">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={arr.active}
                                      onChange={() => onChangeCheck(index)}
                                    />
                                    <span className="slider round" />
                                  </label>
                                </td>

                                <td>
                                  <DivInputValueVariation
                                  // emptyPrice={arr.price === null}
                                  >
                                    <div>R$</div>

                                    <input
                                      type="number"
                                      placeholder="0,00"
                                      className="currency-input"
                                      value={arr.price}
                                      onChange={e =>
                                        handleChangePriceVariations(e, index)
                                      }
                                      onBlur={e =>
                                        handleBlurPriceVariations(e, index)
                                      }
                                      onFocus={() =>
                                        setOldPriceVariation(arr.price)
                                      }
                                    />

                                    {/* <IntlCurrencyInput
                                      currency="BRL"
                                      className="currency-input"
                                      config={{
                                        locale: 'pt-BR',
                                        formats: {
                                          number: {
                                            BRL: {
                                              // style: 'currency',
                                              currency: 'BRL',
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            },
                                          },
                                        },
                                      }}
                                      defaultValue={arr.price}
                                      onBlur={e =>
                                        handleBlurPriceVariations(e, index)
                                      }
                                    /> */}
                                  </DivInputValueVariation>
                                </td>
                                <td>
                                  <DivInputValueVariation>
                                    <div>R$</div>

                                    <input
                                      type="number"
                                      placeholder="0,00"
                                      className="currency-input"
                                      value={arr.promotion_price}
                                      onChange={e =>
                                        handleChangePromotionPriceVariations(
                                          e,
                                          index
                                        )
                                      }
                                      onBlur={e =>
                                        handleBlurPromotionPriceVariations(
                                          e,
                                          index
                                        )
                                      }
                                      onFocus={() =>
                                        setOldPromotionPriceVariation(
                                          arr.promotion_price
                                        )
                                      }
                                    />

                                    {/* <IntlCurrencyInput
                                      currency="BRL"
                                      className="currency-input"
                                      config={{
                                        locale: 'pt-BR',
                                        formats: {
                                          number: {
                                            BRL: {
                                              // style: 'currency',
                                              currency: 'BRL',
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            },
                                          },
                                        },
                                      }}
                                      defaultValue={arr.promotion_price}
                                      onBlur={(e, value, maskedValue) =>
                                        handleBlurPromotionPriceVariations(
                                          value,
                                          index
                                        )
                                      }
                                    /> */}
                                  </DivInputValueVariation>
                                </td>
                                <td>
                                  <DivInputStockVariation>
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleIncrementStockVariations(
                                          index,
                                          arr.stock_quantity,
                                          false
                                        )
                                      }
                                    >
                                      -
                                    </button>
                                    <input
                                      type="number"
                                      defaultValue={arr.stock_quantity}
                                      value={arr.stock_quantity}
                                      placeholder="∞"
                                      onChange={e =>
                                        handleBlurStockVariations(
                                          index,
                                          e.target.value
                                        )
                                      }
                                      className={
                                        arr.stock_quantity === 0
                                          ? 'no-stock'
                                          : ''
                                      }
                                    />
                                    <button
                                      type="button"
                                      onClick={() =>
                                        handleIncrementStockVariations(
                                          index,
                                          arr.stock_quantity,
                                          true
                                        )
                                      }
                                    >
                                      +
                                    </button>
                                  </DivInputStockVariation>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </TableVariations>
                      )}
                    </>
                  )}
                </DivOpenClose>

                <DivOpenClose
                // first={arrayVariations && arrayVariations.length > 0}
                >
                  <div
                    className="header"
                    onClick={() => setShowDivDelivery(!showDivDelivery)}
                  >
                    <div>
                      <div>
                        <strong>Entrega</strong>
                        <span>(Opcional)</span>
                      </div>

                      {/* <span>Peso e dimensões do produto</span> */}
                    </div>

                    <img src={ArrowIcon} alt="arrow" />
                  </div>

                  {showDivDelivery && (
                    <>
                      <span>Peso e dimensões do produto</span>

                      <DivDimensions weight={weight}>
                        <div>
                          <strong>Peso</strong>

                          <div>
                            <input
                              type="number"
                              placeholder="1"
                              value={weight}
                              onChange={e => setWeight(e.target.value)}
                            />

                            <div>kg</div>
                          </div>

                          {weight && weight >= 1 && (
                            <span>{weight} quilo(s)</span>
                          )}

                          {weight && weight < 1 && (
                            <span>{weight * 1000} grama(s)</span>
                          )}
                        </div>

                        <div>
                          <strong>Comprimento</strong>

                          <div>
                            <input
                              type="number"
                              placeholder="0"
                              value={length}
                              onChange={e => setLength(e.target.value)}
                            />

                            <div>cm</div>
                          </div>
                        </div>

                        <div>
                          <strong>Altura</strong>

                          <div>
                            <input
                              type="number"
                              placeholder="0"
                              value={height}
                              onChange={e => setHeight(e.target.value)}
                            />

                            <div>cm</div>
                          </div>
                        </div>

                        <div>
                          <strong>Largura</strong>

                          <div>
                            <input
                              type="number"
                              placeholder="0"
                              value={width}
                              onChange={e => setWidth(e.target.value)}
                            />

                            <div>cm</div>
                          </div>
                        </div>
                      </DivDimensions>

                      <Message
                        background="rgb(255, 108, 75, 0.1)"
                        color="#FF6C4B"
                        borderLeft="2px solid #FF6C4B"
                      >
                        <span>
                          <b>Lembre-se:</b> Produtos que não tiverem informações
                          de peso e dimensões <b>não</b> poderão ter o frete
                          calculado pelos Correios
                        </span>
                      </Message>

                      <Message
                        background="rgb(132, 132, 132, 0.1)"
                        color="#848484"
                        margin="16px 0 0 0"
                      >
                        <svg
                          width="25"
                          height="25"
                          viewBox="0 0 25 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M16.9309 22.7834C16.6094 23.1049 16.1735 23.2856 15.7189 23.2857H8.56686C8.11224 23.2856 7.67627 23.1049 7.35486 22.7834L1.50229 16.9309C1.18077 16.6094 1.0001 16.1735 1 15.7189V8.56686C1.0001 8.11224 1.18077 7.67627 1.50229 7.35486L7.35486 1.50229C7.67627 1.18077 8.11224 1.0001 8.56686 1H15.7189C16.1735 1.0001 16.6094 1.18077 16.9309 1.50229L22.7834 7.35486C23.1049 7.67627 23.2856 8.11224 23.2857 8.56686V15.7189C23.2856 16.1735 23.1049 16.6094 22.7834 16.9309L16.9309 22.7834Z"
                            stroke="#848484"
                            strokeWidth="1.71429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.1426 7V12.5714"
                            stroke="#848484"
                            strokeWidth="1.71429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.1424 17.2859C12.0288 17.2859 11.9198 17.2407 11.8394 17.1603C11.759 17.08 11.7139 16.9709 11.7139 16.8573C11.7139 16.7436 11.759 16.6346 11.8394 16.5542C11.9198 16.4739 12.0288 16.4287 12.1424 16.4287"
                            stroke="#848484"
                            strokeWidth="1.71429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12.1426 17.2859C12.2562 17.2859 12.3653 17.2407 12.4456 17.1603C12.526 17.08 12.5711 16.9709 12.5711 16.8573C12.5711 16.7436 12.526 16.6346 12.4456 16.5542C12.3653 16.4739 12.2562 16.4287 12.1426 16.4287"
                            stroke="#848484"
                            strokeWidth="1.71429"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>

                        <span>
                          O ideal é que você{' '}
                          <b>
                            insira o peso e as dimensões do produto embalado
                          </b>
                          , pois caso seu cliente tenha mais de 1 produto no
                          carrinho, o peso e dimensões de duas ou mais
                          embalagens são somados e o valor pode ficar alto
                        </span>
                      </Message>
                    </>
                  )}
                </DivOpenClose>
              </GeneralDiv>
              <GeneralDiv width="100%">
                {hide ? (
                  <ButtonMore onClick={() => setHide(false)}>
                    Mais opções
                  </ButtonMore>
                ) : (
                  <ButtonMore onClick={() => setHide(true)} margin>
                    Menos opções
                  </ButtonMore>
                )}
              </GeneralDiv>
            </DivBody>

            <DivFooter>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <DivImages
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {images &&
                        images.map((image, index) => (
                          <Draggable
                            key={String(image.id)}
                            draggableId={String(image.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <ImgPreview
                                  image={image}
                                  deleteImages={deleteImages}
                                  src={image.preview || image.image_url}
                                  alt=""
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </DivImages>
                  )}
                </Droppable>
              </DragDropContext>
              <DivButton>
                <button
                  type="button"
                  className="button-img"
                  onClick={() => refUploadImage.current.click()}
                >
                  <img src={imgIcon} alt="img" />
                </button>
                <LabelImage htmlFor="image" hide={imageSize > 2}>
                  <input
                    type="file"
                    ref={refUploadImage}
                    id="image"
                    className="custom-file-input"
                    accept="image/*"
                    multiple
                    onChange={handleChangeImage}
                    style={{ display: 'none' }}
                  />
                </LabelImage>
                <button
                  type="button"
                  className="button-save"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <img src={loader} alt="" />
                  ) : id > 0 ? (
                    'Salvar'
                  ) : (
                    'Adicionar'
                  )}
                </button>
              </DivButton>
            </DivFooter>
          </>
        )}
      </Div>
    </ContainerGeral>
  );
}

export default ProductModal;

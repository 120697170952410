/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import {
  SelectButton,
  SelectButtonBottomInfoWrapper,
  SelectButtonWrapper,
  SelectButtonsWrapper,
} from './styles';

export function SelectButtons({
  onSelect,
  options = [],
  hasInfo = false,
  marginBottom,
  marginTop,
}) {
  const [selectedOption, setSelectedOption] = useState({
    id: 1,
    name: '',
    value: '',
    tax: '',
    way: '',
  });

  const handleSelectOption = option => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <SelectButtonsWrapper marginTop={marginTop} marginBottom={marginBottom}>
      {options.map((option, index) => {
        if (index === 0) {
          return (
            <SelectButtonWrapper>
              <SelectButton
                onClick={() => handleSelectOption(option)}
                left
                active={selectedOption.id === option.id}
                key={option.id}
              >
                <p>{option.name}</p>
              </SelectButton>
              {hasInfo && (
                <SelectButtonBottomInfoWrapper>
                  <p>{option.tax}</p>
                  <p>{option.way}</p>
                </SelectButtonBottomInfoWrapper>
              )}
            </SelectButtonWrapper>
          );
        }

        if (index === options.length - 1) {
          return (
            <SelectButtonWrapper>
              <SelectButton
                onClick={() => handleSelectOption(option)}
                right
                active={selectedOption.id === option.id}
                key={option.id}
              >
                <p>{option.name}</p>
              </SelectButton>
              {hasInfo && (
                <SelectButtonBottomInfoWrapper>
                  <p>{option.tax}</p>
                  <p>{option.way}</p>
                </SelectButtonBottomInfoWrapper>
              )}
            </SelectButtonWrapper>
          );
        }

        return (
          <SelectButtonWrapper>
            <SelectButton
              className={selectedOption.id === option.id ? 'is-selected' : ''}
              key={option.id}
              onClick={() => handleSelectOption(option)}
            >
              <p>{option.name}</p>
            </SelectButton>
            {hasInfo && (
              <SelectButtonBottomInfoWrapper>
                <p>{option.tax}</p>
                <p>{option.way}</p>
              </SelectButtonBottomInfoWrapper>
            )}
          </SelectButtonWrapper>
        );
      })}
    </SelectButtonsWrapper>
  );
}

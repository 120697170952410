import React from 'react';

import * as S from './styles';
// import loader from '../../assets/icons/loader-grey.gif';
import LoaderComponent from '../Loader';

export function UniversalLoader({ open }) {
  return (
    <>
      {open && (
        <S.Container>
          <LoaderComponent size={40} />
        </S.Container>
      )}
    </>
  );
}

import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from 'react-grid-dnd';

import { Container } from './styles';

function Teste() {
  const [items, setItems] = useState([
    { id: '1', text: 'Item1' },
    { id: '2', text: 'Item2' },
    { id: '3', text: 'Item3' },
    { id: '4', text: 'Item4' },
  ]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);
  }

  return (
    <Container id="div-teste">
      <GridContextProvider>
        <GridDropZone className="dropzone" boxesPerRow={2} rowHeight={85}>
          {items.map((item, index) => (
            <GridItem key={String(item.id)}>
              <div className="grid-item">{item.text}</div>
            </GridItem>
          ))}
        </GridDropZone>
      </GridContextProvider>
    </Container>
  );
}

export default Teste;

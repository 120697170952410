import React from 'react';

import * as S from './styles';

export default function OrderDetailsComponent({}) {
  return (
    <S.MainContainer>
      <S.TextContainer>
        <S.Text>Subtotal</S.Text>
        <S.Text>R$ 500,00</S.Text>
      </S.TextContainer>
      <S.TextContainer>
        <S.Text>Taxa de entrega</S.Text>
        <S.Text>R$ 16,00</S.Text>
      </S.TextContainer>
      <S.TextContainer>
        <S.Text>Cupom (CUPOM1234)</S.Text>
        <S.DiscountText>- R$ 10,00</S.DiscountText>
      </S.TextContainer>
      <S.TextContainer style={{ marginTop: '8px' }}>
        <S.TotalText>Total</S.TotalText>
        <S.TotalText>
          <b>R$ 506,00</b>
        </S.TotalText>
      </S.TextContainer>
    </S.MainContainer>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55px;
    margin-bottom: 25px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
    border-bottom: 0.5px solid #d8dee5;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;

      span {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }

      img {
        cursor: pointer;
      }
    }

    .div-space {
      width: 10%;
      height: 100%;

      > img {
        width: 10px;
        height: 35px;
        margin-left: 22px;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 45px;
        border: none;
        background: none;
        font-size: 15px;
        color: #ff303c;
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  margin-top: 55px;
  padding: 32px 24px;
  flex-direction: column;

  > a {
    margin-bottom: 24px;
    text-decoration: none;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }

  > button {
    width: max-content;
    border: none;
    background: none;
    font-size: 15px;
    color: #ff303c;
  }

  .disabled {
    color: #e5e5e5;
  }
`;

export const DivOtherLinks = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #d8dee5;
  padding: 24px 0;

  .pro {
    border: 1px solid #2b8ff5;
    border-radius: 4px;
    padding: 1px 6px;
    margin-left: 8px;
    font-size: 13px;
  }

  > a {
    font-weight: 500;
    font-size: 15px;
    color: #2b8ff5;
    text-decoration: none;

    & + a {
      margin-top: 28px;
    }
  }

  > button {
    width: max-content;
    display: flex;
    font-weight: 500;
    font-size: 15px;
    color: #2b8ff5;
    border: none;
    background: none;

    & + button {
      margin-top: 28px;
    }
  }
`;

export const DivMaintenance = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  border-top: 0.5px solid #d8dee5;
  padding-top: 24px;

  > strong {
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #000000;
  }
`;

import React, { useCallback } from 'react';

import { ContainerGeral, Container, Div } from './styles';
import { formatPrice } from '~/util/format';

function ProPlan({ showModal, Modal, obj }) {
  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  const handleModalConfirm = useCallback(() => {
    Modal(false, true);
  }, [Modal]);

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <div>
          {(obj && (obj.label === 'price' || obj.label === 'promoPrice') && (
            <>
              <h3>
                Todas as variações do produto terão o{' '}
                {obj.label === 'price' ? 'preço' : 'preço promocional'} de{' '}
                <span>{obj.price && formatPrice(obj.price)}</span>?
              </h3>

              <button type="button" className="secundary" onClick={handleModal}>
                Cancelar alteração
              </button>

              <button
                type="button"
                className="primary"
                onClick={handleModalConfirm}
              >
                Confirmar
              </button>
            </>
          )) || (
            <>
              <h3>Deseja adicionar esse preço para todas as variações?</h3>

              <button
                type="button"
                className="secundary"
                onClick={handleModalConfirm}
              >
                Alterar todas
              </button>

              <button type="button" className="primary" onClick={handleModal}>
                Alterar somente essa
              </button>
            </>
          )}
        </div>
      </Div>
    </ContainerGeral>
  );
}

export default ProPlan;

import React from 'react';

import { TextWithIconItem, TextWithIconList } from '~/designsystem/Fonts';
import { Container } from './styles';

import MoneyIcon from '../../../assets/icons/plan/money.svg';
import LockIcon from '../../../assets/icons/plan/lock.svg';
import HandsIcon from '../../../assets/icons/plan/hands.svg';
import TrophyIcon from '../../../assets/icons/plan/trophy.svg';

function GeneralItems() {
  return (
    <Container>
      <TextWithIconList>
        <TextWithIconItem color="#06C86B">
          <div>
            <img src={MoneyIcon} alt="dinheiro" />
          </div>

          <span>Garantia de 7 dias</span>
        </TextWithIconItem>

        <TextWithIconItem color="#06C86B">
          <div>
            <img src={HandsIcon} alt="dinheiro" />
          </div>

          <span>Você sempre pagará o valor promocional</span>
        </TextWithIconItem>

        <TextWithIconItem color="#06C86B">
          <div>
            <img src={LockIcon} alt="dinheiro" />
          </div>

          <span>Cancele quando quiser sem qualquer taxa</span>
        </TextWithIconItem>

        <TextWithIconItem color="#06C86B">
          <div>
            <img src={TrophyIcon} alt="dinheiro" />
          </div>

          <span>Plano escolhido por milhares de assinantes</span>
        </TextWithIconItem>
      </TextWithIconList>
    </Container>
  );
}

export default GeneralItems;

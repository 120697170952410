/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';

import api from '~/services/api';
import {
  Container,
  DivAddVariation,
  DivDevice,
  DivBannerImages,
  LabelImage,
  DivImages,
  DivLink,
} from './styles';

import {
  updateOrderBannerRequest,
  updateOrderBannerDesktopRequest,
} from '../../../store/modules/user/actions';

import ImgPreview from '../../ImagePreview';

import PhoneIcon from '../../../assets/icons/settings/PhoneIcon';
import DesktopIcon from '../../../assets/icons/settings/DesktopIcon';
import imgIcon from '../../../assets/icons/img.svg';
import loaderGrey from '../../../assets/icons/loader-grey.gif';
import loaderGif from '../../../assets/icons/loader.gif';

function AddMainModal({
  handleShowModalEditElement,
  component,
  handleDeleteCard,
}) {
  const [selectedDevice, setSelectedDevice] = useState(0);
  const [bannerFormatImage, setBannerFormatImage] = useState(0);
  const [banners, setBanners] = useState([]);
  const [bannersDesktop, setBannersDesktop] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  const [deletedBanners, setDeletedBanners] = useState([]);

  const loadingBanner = useSelector(state => state.user.profile.loadingBanner);
  const loadingBannerDesktop = useSelector(
    state => state.user.profile.loadingBannerDesktop
  );

  const [isLoading, setIsLoading] = useState(false);

  const refUploadImage = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    async function load() {
      await api
        .get(`/filebannerstore/${component && component.id}`)
        .then(response => {
          const bann = response.data.filter(r => !r.desktop);
          const banndesk = response.data.filter(r => !!r.desktop);

          setBanners(bann);
          setBannersDesktop(banndesk);

          if (bann && bann.length > 0) {
            setSelectedImage(bann[0]);
          }
        });
    }

    load();
  }, [component]);

  // async function handleChangeImage(e, imageType) {
  //   if (e.target.files[0]) {
  //     dispatch(saveImageRequest(e.target.files[0], imageType));
  //   }
  // }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function onDragEndBanners(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorder(
      banners,
      result.source.index,
      result.destination.index
    );

    setBanners(reorderedImages);

    dispatch(updateOrderBannerRequest(reorderedImages));
  }

  async function onDragEndBannersDesktop(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedImages = reorder(
      bannersDesktop,
      result.source.index,
      result.destination.index
    );

    setBannersDesktop(reorderedImages);

    dispatch(updateOrderBannerDesktopRequest(reorderedImages));
  }

  // async function deleteImages(imageType, imageId) {
  //   dispatch(removeImageRequest(imageType, imageId));
  // }

  async function handleChangeImage(e, type) {
    const selectedImages = e.target.files;
    const arr = [];

    let maxImages = 0;

    if (type === 'banner') {
      maxImages = banners.length >= 0 ? 8 - banners.length : 0;
    } else {
      maxImages = bannersDesktop.length >= 0 ? 8 - bannersDesktop.length : 0;
    }

    const sizeArray =
      selectedImages.length >= maxImages ? maxImages : selectedImages.length;

    for (let i = 0; i < sizeArray; i += 1) {
      if (selectedImages[i].size < 15000000) {
        // 5120000 = 5mb

        arr.push({
          id: uniqueId('image_'),
          file: selectedImages[i],
          preview: URL.createObjectURL(selectedImages[i]),
        });
      } else {
        toast.error('Imagem muito grande');
        break;
      }
    }

    if (type === 'banner') {
      setBanners(banners.concat(arr));
    } else {
      setBannersDesktop(bannersDesktop.concat(arr));
    }
  }

  const filterBanners = useCallback(
    image => {
      const newBanners = banners.filter(b => b.id !== image.id);
      setBanners(newBanners);
    },
    [banners]
  );

  const filterBannersDesktop = useCallback(
    image => {
      const newBanners = bannersDesktop.filter(b => b.id !== image.id);
      setBannersDesktop(newBanners);
    },
    [bannersDesktop]
  );

  async function deleteBanners(image) {
    if (image.preview) {
      URL.revokeObjectURL(image.preview);
      filterBanners(image);
    } else {
      const arr = [...deletedBanners];
      arr.push(image);
      setDeletedBanners(arr);
      filterBanners(image);
    }
  }

  async function deleteBannersDesktop(image) {
    if (image.preview) {
      URL.revokeObjectURL(image.preview);
      filterBannersDesktop(image);
    } else {
      const arr = [...deletedBanners];
      arr.push(image);
      setDeletedBanners(arr);
      filterBannersDesktop(image);
    }
  }

  function clearFields() {
    setSelectedDevice(0);
    setBannerFormatImage(0);
    setBanners([]);
    setBannersDesktop([]);
    setDeletedBanners([]);
  }

  async function sendImages(banner, index, imageData, desktop) {
    if (banner.file) {
      const data = new FormData();
      data.append('file', banner.file, banner.file.name);
      const createdFile = await api.post(
        `filebannerstorecreate/${component &&
          component.id}?desktop=${desktop}&link=${banner.link}`,
        data
      );

      if (createdFile.status === 200) {
        await api.put(
          `orderimagebannerstore/${createdFile.data.id}`,
          imageData
        );

        URL.revokeObjectURL(banner.preview);

        if (index === 0) {
          return createdFile.data;
        }
      }
    } else {
      await api.put(`orderimagebannerstore/${banner.id}`, imageData);

      if (index === 0) {
        return banner;
      }
    }

    return 0;
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);

      await Promise.all(
        banners.map(async (banner, index) => {
          const imageData = {
            imageIndex: index,
            componentId: component && component.id,
            link: banner.link || '',
          };

          await sendImages(banner, index, imageData, false);

          return 0;
        }),

        bannersDesktop.map(async (banner, index) => {
          const imageData = {
            imageIndex: index,
            componentId: component && component.id,
            link: banner.link || '',
          };

          await sendImages(banner, index, imageData, true);

          return 0;
        })
      );

      if (deletedBanners && deletedBanners.length > 0) {
        await api.post(`deletebannerstore`, { deletedBanners });
      }

      toast.success('Salvo');

      handleShowModalEditElement(false);
      clearFields();
    } catch (err) {
      toast.success('Ocorreu um erro ao salvar os banners');
    } finally {
      setIsLoading(false);
    }
  }

  function handleChangeLink(e) {
    const obj = selectedImage;
    obj.link = e.target.value;

    setSelectedImage(obj);

    if (selectedDevice === 0) {
      const index =
        banners && banners.findIndex(b => b.id === selectedImage.id);
      const arr = [...banners];

      arr[index].link = e.target.value;

      setBanners(arr);
    } else {
      const index =
        bannersDesktop &&
        bannersDesktop.findIndex(b => b.id === selectedImage.id);
      const arr = [...bannersDesktop];

      arr[index].link = e.target.value;

      setBannersDesktop(arr);
    }
  }

  function handleDeleteComponent(comp) {
    const c = comp;
    handleShowModalEditElement(false);
    clearFields();
    handleDeleteCard(c);
  }

  return (
    <Container>
      <div
        className="div-bg"
        onClick={() => handleShowModalEditElement(false)}
      />
      <DivAddVariation>
        <header>
          <strong>{component && component.PageComponent.title}</strong>

          {component && component.is_deleteable && (
            <button
              type="button"
              onClick={() => handleDeleteComponent(component)}
            >
              Excluir
            </button>
          )}
        </header>

        <div>
          <strong>Adicionar imagens</strong>
          <DivDevice>
            <div>
              <div
                onClick={() => {
                  setSelectedDevice(0);
                  setSelectedImage((banners && banners[0]) || null);
                }}
              >
                <PhoneIcon
                  color={selectedDevice === 0 ? '#2b8ff5' : '#9d9d9d'}
                />
                <span className={selectedDevice === 0 ? 'selected' : ''}>
                  Celular/tablet
                </span>
              </div>

              <div
                onClick={() => {
                  setSelectedDevice(1);
                  setSelectedImage(
                    (bannersDesktop && bannersDesktop[0]) || null
                  );
                }}
              >
                <DesktopIcon
                  color={selectedDevice === 1 ? '#2b8ff5' : '#9d9d9d'}
                />
                <span className={selectedDevice === 1 ? 'selected' : ''}>
                  Desktop
                </span>
              </div>
            </div>

            {selectedDevice === 0 ? (
              <>
                {component &&
                component.PageComponent.title === 'Banner principal' ? (
                  <span>Tamanho recomendado: 500x630(largura x altura)</span>
                ) : (
                  <span>Tamanho recomendado: 500x500(largura x altura)</span>
                )}
              </>
            ) : (
              <>
                {component &&
                component.PageComponent.title === 'Banner principal' ? (
                  <span>Tamanho recomendado: 1900x476(largura x altura)</span>
                ) : (
                  <span>Tamanho recomendado: 1280x300(largura x altura)</span>
                )}
              </>
            )}
          </DivDevice>

          {selectedDevice === 0 ? (
            <DivBannerImages>
              <button
                type="button"
                className="button-img"
                onClick={() => refUploadImage.current.click()}
              >
                <img src={imgIcon} alt="img" />
              </button>
              <LabelImage htmlFor="image">
                <input
                  type="file"
                  ref={refUploadImage}
                  id="image"
                  className="custom-file-input"
                  accept="image/*"
                  onChange={e => handleChangeImage(e, 'banner')}
                  style={{ display: 'none' }}
                />
              </LabelImage>

              <DragDropContext onDragEnd={onDragEndBanners}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <DivImages
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {banners &&
                        banners.map((banner, index) => (
                          <Draggable
                            key={String(banner.id)}
                            draggableId={String(banner.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => setSelectedImage(banner)}
                              >
                                <ImgPreview
                                  image={banner}
                                  type="banner"
                                  deleteImages={deleteBanners}
                                  src={banner.preview || banner.image_url}
                                  alt=""
                                  width="60px"
                                  height="80px"
                                  margin="0 0 0 0"
                                  selected={
                                    selectedImage &&
                                    selectedImage.id === banner.id
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      {loadingBanner && <img src={loaderGif} alt="loader" />}
                    </DivImages>
                  )}
                </Droppable>
              </DragDropContext>
            </DivBannerImages>
          ) : (
            <DivBannerImages desktop>
              <button
                type="button"
                className="button-img"
                onClick={() => refUploadImage.current.click()}
              >
                <img src={imgIcon} alt="img" />
              </button>
              <LabelImage htmlFor="image">
                <input
                  type="file"
                  ref={refUploadImage}
                  id="image"
                  className="custom-file-input"
                  accept="image/*"
                  onChange={e => handleChangeImage(e, 'banner-desktop')}
                  style={{ display: 'none' }}
                />
              </LabelImage>

              <DragDropContext onDragEnd={onDragEndBannersDesktop}>
                <Droppable droppableId="droppable" direction="horizontal">
                  {(provided, snapshot) => (
                    <DivImages
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {bannersDesktop &&
                        bannersDesktop.map((banner, index) => (
                          <Draggable
                            key={String(banner.id)}
                            draggableId={String(banner.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onClick={() => setSelectedImage(banner)}
                              >
                                <ImgPreview
                                  image={banner}
                                  type="banner-desktop"
                                  deleteImages={deleteBannersDesktop}
                                  src={banner.preview || banner.image_url}
                                  width="100px"
                                  height="80px"
                                  margin="0 0 0 0"
                                  desktop
                                  selected={
                                    selectedImage &&
                                    selectedImage.id === banner.id
                                  }
                                />
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                      {loadingBannerDesktop && (
                        <img src={loaderGif} alt="loader" />
                      )}
                    </DivImages>
                  )}
                </Droppable>
              </DragDropContext>
            </DivBannerImages>
          )}

          {selectedImage && (
            <DivLink>
              <div>
                <strong>Link</strong>
                <span>Opcional</span>
              </div>

              <input
                type="text"
                placeholder="Insira o link de destino do banner aqui"
                value={selectedImage.link || ''}
                onChange={handleChangeLink}
              />
            </DivLink>
          )}
        </div>

        <footer>
          <button type="button" onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? <img src={loaderGrey} alt="loader" /> : 'Salvar'}
          </button>
        </footer>
      </DivAddVariation>
    </Container>
  );
}

export default AddMainModal;

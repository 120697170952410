import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 0;
  gap: 8px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
  min-height: 100vh;
`;

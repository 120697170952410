import styled, { css } from 'styled-components';
import { device } from '../../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 440px;
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 6%;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);

  @media ${device.mobile} {
    width: 90%;
    margin-top: 0;
    top: 15%;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  padding: 24px 24px 13px 24px;
  justify-content: flex-end;
`;

export const DivBody = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  justify-content: center;
  flex-direction: column;

  strong {
    margin-top: 24px;
    font-weight: 600;
    font-size: 17px;
    text-align: center;
    color: #000000;
  }

  span {
    margin-top: 20px;
    font-size: 13px;
    text-align: center;
    color: #838383;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  background: #fff;
  border-radius: 10px;
  border: 0.5px solid #d8dee5;
  height: 50px;
  font-weight: 500;
  font-size: 14px;
  transition: all 0.2s;

  ${props =>
    props.copied &&
    css`
      background: #34c780;
      color: #fff;
    `}

  img {
    margin-right: 6px;
  }
`;

export const InstagramButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  background: #2b8ff5;
  color: #fff;
  border-radius: 10px;
  height: 50px;
  font-weight: 500;
  font-size: 13px;
  text-decoration: none;
`;

import React, { useState, useCallback } from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivBody,
  Button,
  InstagramButton,
} from './styles';
import CancelImage from '../../../assets/icons/cancelx.svg';
import CopyLinkImage from '../../../assets/icons/copyLink.svg';
import CopyIcon from '../../../assets/icons/copyIcon.svg';
import CopiedIcon from '../../../assets/icons/copiedIcon.svg';

function CopyLink({ showModal, Modal, username }) {
  const [copied, setCopied] = useState(false);
  const [showButtonInstagram, setShowButtonInstagram] = useState(false);

  const handleModal = useCallback(() => {
    Modal(false);
    sessionStorage.setItem('copylinkpopup', true);
  }, [Modal]);

  function handleCopiedClick() {
    setCopied(true);
    setTimeout(() => {
      setShowButtonInstagram(true);
    }, 1000);
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivTitle>
          <img src={CancelImage} alt="cancelImage" onClick={handleModal} />
        </DivTitle>
        <DivBody>
          <img src={CopyLinkImage} alt="copy-link" />
          <strong>
            Não esqueça de adicionar seu link as suas redes sociais
          </strong>
          <span>offstore.me/{username}</span>
          {(showButtonInstagram && (
            <InstagramButton href="http://instagram.com" target="_blank">
              Adicionar link a bio do instagram
            </InstagramButton>
          )) || (
            <CopyToClipboard text={`https://offstore.me/${username}`}>
              <Button type="button" copied={copied} onClick={handleCopiedClick}>
                <img src={copied ? CopiedIcon : CopyIcon} alt="copy" />
                {copied ? 'copiado' : 'Copiar link'}
              </Button>
            </CopyToClipboard>
          )}
        </DivBody>
      </Div>
    </ContainerGeral>
  );
}

export default CopyLink;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 677px;
  align-items: center;
  justify-content: flex-start;
  /* padding: 48px 0px; */
  padding: 24px 16px 16px 16px;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
    width: 100%;
    max-width: 674px;
    height: 677px;
  }

  @media (max-width: 720px) {
    height: 100vh;
    padding: 77px 16px 16px 16px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: #fff;

  span {
    color: #ff303c;
    font-size: 12px !important;
    font-weight: 500;
  }
`;

export const Title = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  text-align: center;
`;

export const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  margin-top: ${props => props.marginTop || '24px'};

  span {
    color: #ff303c;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const Input = styled.input`
  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  margin-top: 4px;
  border: solid 0.5px #f9f9f9;
  color: ${props => (props.disabled ? '#838383' : '#101010')};

  &:focus {
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
`;

export const WarningSpan = styled.div`
  color: #838383;
  font-size: 12px;
  margin-top: 4px;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border-width: 0px;
  background: #0d85ff;
  max-width: 565px;
  width: 100%;
  height: 50px;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: absolute;
  bottom: 32px;

  ${props =>
    props.disabled &&
    css`
      opacity: 50%;
    `}

  @media (max-width: 600px) {
    max-width: 92%;
    bottom: 10px;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  margin-top: 8px;
  display: flex;
`;

import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container } from './styles';
import BackButtonIcon from '../../../../../assets/icons/backButton.svg';

import Loader from '../../../../../assets/icons/loader.gif';

function HeaderMobile({ title, url, tab }) {
  const loading = useSelector(state => state.freight.loading);

  return (
    <Container>
      <div className="div-space">
        <Link to={{ pathname: url, tab: { tab } }}>
          <img src={BackButtonIcon} alt="" />
        </Link>
      </div>
      <div>
        <span>{title}</span>
      </div>
      <div className="div-space" />
      {loading && <img src={Loader} alt="loader" />}
    </Container>
  );
}

export default HeaderMobile;

import styled from 'styled-components';
import { FaLock } from 'react-icons/fa';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 100;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;

  padding: 40px;
  border-radius: 10px;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 200;
  position: fixed;
  margin-top: 10%;

  > h1 {
    font-size: 15px;
    margin-bottom: 15px;
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-width: 1px;
      margin: 0 4px;
      border-radius: 4px;
      border-color: #a5a2aa;
    }
  }
`;

export const Input = styled.input`
  margin: 5px 0;
  height: 30px;
  border-radius: 4px;
  border-style: none;
  background: #f3f0f7;
  padding: 10px 15px;
  font-size: 13px;
`;

export const StyledFaLock = styled(FaLock)`
  margin: 4px 10px;
`;

export const Button = styled.button`
  background: ${props => props.background || 'rgb(15, 97, 241, 0.87)'};
  border: 0;
  padding: 0 15px;
  border-radius: 4px;
  font-size: 13px;
  color: ${props => props.color || '#fff'};
  min-height: 30px;
  width: 100px;
  margin-top: 5px;
  margin-bottom: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.2s;

  &:hover {
    background: ${props => props.hover || 'rgb(15, 97, 241, 0.87)'};
  }
`;

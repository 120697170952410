/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  format,
  subDays,
  parseISO,
  isAfter,
  isBefore,
  isEqual,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { UniversalLoader } from '~/components/UniversalLoader/UniversalLoader';
import './style.css';
import arrowDownIcon from '~/assets/icons/offstorepay/arrow-down.svg';
// import ArrowBackIcon from '~/assets/icons/backBlackButton.svg';
import arrowUpIcon from '~/assets/icons/offstorepay/arrow-up.svg';
// import CalendarIco from '~/assets/icons/offstorepay/calendar-ico.svg';
import { useSelector } from 'react-redux';
import { cpfMask, cnpjMask, formatPrice } from '~/util/format';
// import DatePicker from 'react-datepicker';
import LoaderComponent from '~/components/Loader';
import { Space } from '~/designsystem/Fonts';
import { useGetSellerInfo } from '~/hooks/use-get-seller-info';
import 'date-fns/locale/pt-BR'; // Importe a localização para pt-BR
import DivHelpCenter from '~/components/OffstorePay/HelpCenter';
import * as S from './styles';
import {
  ConfigurationSectionSubtitle,
  ConfigurationSectionTitle,
  Container,
  Body,
  Divider,
  ButtonPill,
  ButtonContainer,
  // DatePickerContainer,
  DivOpenAndClose,
  ConfigurationSection,
  // CustomInputDatepicker,
  ButtonSeeMore,
  DivLoader,
} from './styles';
import BankIc from '../../../../assets/icons/paymentmethods/bank.svg';
import ResponsiveHeader from '../../../../components/HeaderMobile';

// const CustomInput = forwardRef(({ value, onClick }, ref) => (
//   <CustomInputDatepicker className="custom-input" onClick={onClick} ref={ref}>
//     <img src={CalendarIco} alt="calendar-icon" />
//     {value}
//   </CustomInputDatepicker>
// ));

const AccountBalanceItem = ({
  payment,
  first,
  last,
  date,
  status,
  userId,
  type,
  isDesktop,
  handlePageSelected,
  handleGetTransactionId,
  handleGetTransferId,
}) => {
  const router = useHistory();
  const formattedReceivedPaymentValue = formatPrice(payment.amount);

  const url =
    // eslint-disable-next-line no-nested-ternary
    type === 'transaction' || type === 'dispute' || type === 'charged_back'
      ? `/paymentmethods/configurationoffstorepay/payment/${payment.id}`
      : type === 'transfer'
      ? `/paymentmethods/configurationoffstorepay/transfer/${payment.id}`
      : null;

  const formattedDate = format(
    new Date(date || '2021-09-28T16:30:00.000Z'),
    "dd 'de' MMMM 'de' yyyy 'às' HH:mm'h'",
    { locale: ptBR }
  );

  function handleGetTransaction(id) {
    handleGetTransactionId(id);
    handlePageSelected(2);
  }

  function handleGetTransfer(id) {
    handleGetTransferId(id);
    handlePageSelected(3);
  }

  return (
    <>
      {first && <Space height="16px" />}
      <Divider />
      <div
        onClick={
          isDesktop
            ? () => {
                type === 'transaction'
                  ? handleGetTransaction(payment.id)
                  : handleGetTransfer(payment.id);
              }
            : () => (url ? router.push(url) : {})
        }
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: last ? '16px 16px 0 16px' : '16px',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',
            gap: 4,
          }}
        >
          {payment && payment.buyer && (
            <strong
              style={{
                fontWeight: 500,
                fontSize: 14,
                color: 'rgba(0, 0, 0, 1)',
              }}
            >
              {payment.buyer.name}
            </strong>
          )}

          {payment.bank_account && (
            <div style={{ display: 'flex' }}>
              <strong
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                  color: status === 'canceled' ? '#FF303C' : 'rgba(0, 0, 0, 1)',
                }}
              >
                {payment.bank_account.bank_name}. ****
                {payment.bank_account.account_number.slice(-4)}
              </strong>

              {status === 'canceled' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '4px',
                    background: 'rgba(255, 48, 60, 0.08)',
                    padding: '2px 4px',
                    marginLeft: '6px',
                  }}
                >
                  <span
                    style={{
                      fontSize: '11px',
                      fontWeight: '500',
                      color: '#FF303C',
                    }}
                  >
                    Falhou
                  </span>
                </div>
              )}
            </div>
          )}

          <span
            style={{
              fontWeight: 400,
              fontSize: 13,
              color: '#838383',
            }}
          >
            {formattedDate}
          </span>
        </div>
        <strong
          style={{
            color: type === 'dispute' ? '#FF303C' : 'rgba(6, 200, 107, 1)',
            fontWeight: 500,
            fontSize: 14,
            marginTop: 8,
            marginBottom: 16,
          }}
        >
          {formattedReceivedPaymentValue}
        </strong>
      </div>
      {/* {!last && <Divider />} */}
    </>
  );
};

export function AccountBalance({
  isDesktop,
  handlePageSelected,
  handleGetTransactionId,
  handleGetTransferId,
}) {
  const history = useHistory();
  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );
  const {
    handleGetSellerInfo,
    handleGetAccountRepass,
    handleGetTransactions,
    handleGetTransfers,
    handleGetFutureTransfers,
    handleGetDisputes,
    handleGetChargeBacks,
  } = useGetSellerInfo();
  const [transactions, setTransactions] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [transfersOnTheWay, setTransfersOnTheWay] = useState([]);
  const [transfersFailed, setTransfersFailed] = useState([]);
  const [futureTransfers, setFutureTransfers] = useState([]);
  const [disputes, setDisputes] = useState([]);
  const [chargeBacks, setChargeBacks] = useState([]);

  const [bankData, setBankData] = useState({
    account_number: '',
    name: '',
    agency_number: '',
    document_number: '',
  });
  const [seller, setSeller] = useState();
  const [balance, setBalance] = useState(0);
  const [showReceivedPayments, setShowReceivedPayments] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [showTransfersToYourAccount, setShowTransfersToYourAccount] = useState(
    false
  );
  const [showTransfersOnTheWay, setShowTransfersOnTheWay] = useState(false);
  const [showTransfersFailed, setShowTransfersFailed] = useState(false);
  const [sumTransfersOnTheWay, setSumTransfersOnTheWay] = useState(0);
  const [showChargeBacks, setShowChargeBacks] = useState(false);

  const [
    showFutureTransfersToYourAccount,
    setShowFutureTransfersToYourAccount,
  ] = useState(false);

  const [
    transfersToYourAccountFiltered,
    setTransfersToYourAccountFiltered,
  ] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  // const [receivedPaymentsStartDate, setReceivedPaymentsStartDate] = useState();
  // const [receivedPaymentsEndDate, setReceivedPaymentsEndDate] = useState();
  const [transfersToYourAccount, setTransfersToYourAccount] = useState([]);
  const [activeButton, setActiveButton] = useState({
    title: '7 dias',
    value: 7,
  });

  const [currentPageTransactions, setCurrentPageTransactions] = useState(1);
  const [lastDaysTransactions, setLastDaysTransactions] = useState();
  const [startDateTransactions, setStartDateTransactions] = useState();
  const [endDateTransactions, setEndDateTransactions] = useState();
  const [hasMoreTransactions, setHasMoreTransactions] = useState();

  const [currentPageTranfers, setCurrentPageTranfers] = useState(1);
  const [lastDaysTranfers, setLastDaysTranfers] = useState();
  const [startDateTranfers, setStartDateTranfers] = useState();
  const [endDateTranfers, setEndDateTranfers] = useState();
  // const [hasMoreTranfers, setHasMoreTranfers] = useState();

  const [currentPageTranfersFailed, setCurrentPageTranfersFailed] = useState(1);
  const [lastDaysTranfersFailed, setLastDaysTranfersFailed] = useState();
  const [startDateTranfersFailed, setStartDateTranfersFailed] = useState();
  const [endDateTranfersFailed, setEndDateTranfersFailed] = useState();

  const [currentPageFutureTranfers, setCurrentPageFutureTranfers] = useState(1);
  const [lastDaysFutureTranfers, setLastDaysFutureTranfers] = useState();
  const [startDateFutureTranfers, setStartDateFutureTranfers] = useState();
  const [endDateFutureTranfers, setEndDateFutureTranfers] = useState();
  const [hasMoreFutureTranfers, setHasMoreFutureTranfers] = useState();

  const [currentPageDisputes, setCurrentPageDisputes] = useState();
  const [lastDaysDisputes, setLastDaysDisputes] = useState();
  const [startDateDisputes, setStartDateDisputes] = useState();
  const [endDateDisputes, setEndDateDisputes] = useState();
  const [showDisputes, setShowDisputes] = useState();
  const [loaderDisputes, setLoaderDisputes] = useState();
  const [hasMoreDisputes, setHasMoreDisputes] = useState();
  const [buttonSeeMoreDisputes, setButtonSeeMoreDisputes] = useState();

  const [loaderChargeBacks, setLoaderChargeBacks] = useState();
  const [hasMoreChargeBacks, setHasMoreChargeBacks] = useState();
  const [buttonSeeMoreChargeBacks, setButtonSeeMoreChargeBacks] = useState();
  const [lastDaysChargeBacks, setLastDaysChargeBacks] = useState();
  const [startDateChargeBacks, setStartDateChargeBacks] = useState();
  const [endDateChargeBacks, setEndDateChargeBacks] = useState();
  const [currentPageChargeBacks, setCurrentPageChargeBacks] = useState();

  const [loaderPayment, setLoaderPayment] = useState(false);
  const [loaderTransfer, setLoaderTransfer] = useState(false);
  const [loaderTransferOnTheWay, setLoaderTransferOnTheWay] = useState(false);
  const [loaderTransfersFailed, setLoaderTransfersFailed] = useState(false);
  const [loaderFutureTransfer, setLoaderFutureTransfer] = useState(false);
  const [buttonSeeMorePayment, setButtonSeeMorePayment] = useState(false);
  // const [buttonSeeMoreTransfer, setButtonSeeMoreTransfer] = useState(false);

  const [buttonFilterListTransfers, setButtonFilterListTransfers] = useState([
    {
      title: 'Hoje',
      active: false,
      value: 1,
    },
    {
      title: 'Ontem',
      active: false,
      value: 2,
    },
    {
      title: '7 dias',
      active: true,
      value: 7,
    },
    {
      title: '14 dias',
      active: false,
      value: 14,
    },
    {
      title: '30 dias',
      active: false,
      value: 30,
    },
    {
      title: '90 dias',
      active: false,
      value: 90,
    },
    {
      title: 'Tudo',
      active: false,
      value: 365,
    },
    // {
    //   title: 'Personalizado',
    //   active: false,
    //   value: 1,
    // },
  ]);
  const [activeTransactionButton, setActiveTransactionButton] = useState({
    title: '7 dias',
    value: 7,
  });
  const [activeTransferButton, setActiveTransferButton] = useState({
    title: '7 dias',
    value: 7,
  });
  // const [receivedPayments, setReceivedPayments] = useState([]);
  const [
    receivedPaymentsButtonFilterList,
    setReceivedPaymentsButtonFilterList,
  ] = useState([
    {
      title: 'Hoje',
      active: false,
      value: 1,
    },
    {
      title: 'Ontem',
      active: false,
      value: 2,
    },
    {
      title: '7 dias',
      active: true,
      value: 7,
    },
    {
      title: '14 dias',
      active: false,
      value: 14,
    },
    {
      title: '30 dias',
      active: false,
      value: 30,
    },
    {
      title: '90 dias',
      active: false,
      value: 90,
    },
    {
      title: 'Tudo',
      active: false,
      value: 365,
    },
    // {
    //   title: 'Personalizado',
    //   active: false,
    //   value: 1,
    // },
  ]);

  const documentFormat = (type, number) => {
    if (type === 'cpf') return cpfMask(number);
    return cnpjMask(number);
  };

  const formatToBrazilianStyle = value => {
    const format = Number(value);
    return format.toLocaleString('pt-BR', {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const [
    formattedAccountBalanceValue,
    setFormattedAccountBalanceValue,
  ] = useState('');

  async function loadPaymentsReceived({
    zoopSellerId,
    page,
    lastDays,
    startDate,
    endDate,
  }) {
    const data = await handleGetTransactions({
      zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
    });

    if (page > 1) {
      const array = transactions.concat(data.transactions.data);
      setTransactions(array);
    } else {
      setTransactions(data.transactions.data);
    }

    setCurrentPageTransactions(page);
    setLastDaysTransactions(lastDays);
    setStartDateTransactions(startDate);
    setEndDateTransactions(endDate);
    setHasMoreTransactions(data.transactions.has_more);
  }

  async function loadTransfers({
    zoopSellerId,
    page,
    lastDays,
    startDate,
    endDate,
  }) {
    const data = await handleGetTransfers({
      zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
      confirmed: '1',
    });

    if (page > 1) {
      const array = transfers.concat(data.transfers);
      setTransfers(array);
    } else {
      setTransfers(data.transfers);
    }

    setCurrentPageTranfers(page);
    setLastDaysTranfers(lastDays);
    setStartDateTranfers(startDate);
    setEndDateTranfers(endDate);
    // setHasMoreTranfers(data.transactions.has_more);
  }

  function getSumTransfersOnTheWay(array) {
    const total = array.reduce((totalSum, a) => {
      return totalSum + parseFloat(a.amount);
    }, 0);

    setSumTransfersOnTheWay(total);
  }

  async function loadTransfersOnTheWay({ zoopSellerId, page }) {
    const data = await handleGetTransfers({
      zoopSellerId,
      page,
      lastDays: 90,
      status: 'pending',
      confirmed: '0',
    });

    if (page > 1) {
      const array = transfersOnTheWay.concat(data.transfers);
      setTransfersOnTheWay(array);
      getSumTransfersOnTheWay(array);
    } else {
      setTransfersOnTheWay(data.transfers);
      getSumTransfersOnTheWay(data.transfers);
    }

    // setCurrentPageTranfers(page);
  }

  async function loadTransfersFailed({
    zoopSellerId,
    page,
    lastDays,
    startDate,
    endDate,
  }) {
    const data = await handleGetTransfers({
      zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
      canceled: true,
    });

    if (page > 1) {
      const array = transfersFailed.concat(data.transfers);
      setTransfersFailed(array);
    } else {
      setTransfersFailed(data.transfers);
    }

    setCurrentPageTranfersFailed(page);
    setLastDaysTranfersFailed(lastDays);
    setStartDateTranfersFailed(startDate);
    setEndDateTranfersFailed(endDate);
  }

  async function loadFutureTransfers({
    zoopSellerId,
    page,
    nextDays,
    startDate,
    endDate,
  }) {
    const data = await handleGetFutureTransfers({
      zoopSellerId,
      page,
      nextDays,
      startDate,
      endDate,
    });

    if (page > 1) {
      const array = futureTransfers.concat(data.futureTransfers);
      setFutureTransfers(array);
    } else {
      setFutureTransfers(data.futureTransfers);
    }

    setCurrentPageFutureTranfers(page);
    setLastDaysFutureTranfers(nextDays);
    setStartDateFutureTranfers(startDate);
    setEndDateFutureTranfers(endDate);
    setHasMoreFutureTranfers(data.pagination.has_more);
  }

  async function loadDisputes({
    zoopSellerId,
    page,
    lastDays,
    startDate,
    endDate,
  }) {
    const data = await handleGetDisputes({
      zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
    });

    if (page > 1) {
      const array = transactions.concat(data.transactions.data);
      setDisputes(array);
    } else {
      setDisputes(data.transactions.data);
    }

    setCurrentPageDisputes(page);
    setLastDaysDisputes(lastDays);
    setStartDateDisputes(startDate);
    setEndDateDisputes(endDate);
    setHasMoreDisputes(data.transactions.has_more);
  }

  async function loadChargeBacks({
    zoopSellerId,
    page,
    lastDays,
    startDate,
    endDate,
  }) {
    const data = await handleGetChargeBacks({
      zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
    });

    if (page > 1) {
      const array = transactions.concat(data.transactions.data);
      setDisputes(array);
    } else {
      setDisputes(data.transactions.data);
    }

    setCurrentPageDisputes(page);
    setLastDaysDisputes(lastDays);
    setStartDateDisputes(startDate);
    setEndDateDisputes(endDate);
    setHasMoreDisputes(data.transactions.has_more);
  }

  useEffect(() => {
    async function loadSellerInfo() {
      try {
        const { sellerInfo } = await handleGetSellerInfo({ userId });
        setSeller(sellerInfo);
        const accountBalance = sellerInfo.account_balance / 100;
        const currentBalance = sellerInfo.current_balance / 100;

        setBalance(accountBalance + currentBalance);

        setFormattedAccountBalanceValue(accountBalance + currentBalance);

        await loadPaymentsReceived({
          zoopSellerId: sellerInfo.zoop_seller_id,
          lastDays: 7,
          page: 1,
        });

        await loadTransfers({
          zoopSellerId: sellerInfo.zoop_seller_id,
          lastDays: 7,
          page: 1,
        });

        await loadFutureTransfers({
          zoopSellerId: sellerInfo.zoop_seller_id,
          nextDays: 7,
          page: 1,
        });

        await loadTransfersOnTheWay({
          zoopSellerId: sellerInfo.zoop_seller_id,
          lastDays: 90,
          page: 1,
        });

        if (sellerInfo && sellerInfo.bank_account) {
          setBankData({
            account_number: sellerInfo.bank_account.account_number,
            name: sellerInfo.bank_account.name,
            agency_number: sellerInfo.bank_account.agency_number,
            document_number: sellerInfo.bank_account.document_number,
            confirmed: sellerInfo.bank_account.confirmed,
          });
        }

        const { data } = await handleGetAccountRepass({
          zoopSellerId: sellerInfo.zoop_seller_id,
        });

        const allItems = Object.values(data.records)
          .map(obj => obj.items)
          .reduce((acc, curr) => acc.concat(curr), []);
        setTransfersToYourAccount(
          allItems.map(repass => {
            return {
              id: repass.id,
              date: repass.date,
              name: repass.name || 'Sem identificação',
              value: Number(repass.amount),
              status: repass.status || 'Sem status',
            };
          })
        );

        setInitialLoading(false);
      } catch (err) {
        console.log(err);
      }
    }
    loadSellerInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // useEffect(() => {
  //   async function load() {
  //     if (seller && seller.zoop_seller_id) {
  //       await loadPaymentsReceived({
  //         zoopSellerId: seller.zoop_seller_id,
  //         lastDays: 7,
  //         page: 1,
  //       });

  //       await loadTransfers({
  //         zoopSellerId: seller.zoop_seller_id,
  //         lastDays: 7,
  //         page: 1,
  //       });

  //       await loadFutureTransfers({
  //         zoopSellerId: seller.zoop_seller_id,
  //         nextDays: 7,
  //         page: 1,
  //       });
  //     }
  //   }

  //   load();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [seller]);

  useEffect(() => {
    const filteredList = filterListByDaysAgo(
      transfersToYourAccount,
      activeButton.value,
      startDate,
      endDate
    );
    setTransfersToYourAccountFiltered(filteredList);
  }, [activeButton, transfersToYourAccount, startDate, endDate]);

  // useEffect(() => {
  //   var filteredList = filterListByDaysAgo(
  //     accountBalance,
  //     activeReceivedButton?.value,
  //     receivedPaymentsStartDate,
  //     receivedPaymentsEndDate
  //   );
  //   setReceivedPayments(filteredList);
  // }, [
  //   accountBalance,
  //   activeReceivedButton?.value,
  //   receivedPaymentsStartDate,
  //   receivedPaymentsEndDate,
  // ]);

  // const selectDateRange = buttonProp => {
  //   setActiveButton({ title: buttonProp.title, value: buttonProp.value });
  //   const newLabel = buttonFilterList.map(label => {
  //     if (label.title === buttonProp.title) {
  //       return { ...label, active: true };
  //     } else {
  //       return { ...label, active: false };
  //     }
  //   });
  //   setButtonFilterList(newLabel);
  // };

  const selectByReceivedPaymentsDateRange = buttonProp => {
    setActiveTransactionButton({
      title: buttonProp.title,
      value: buttonProp.value,
    });
    const newLabel = receivedPaymentsButtonFilterList.map(label => {
      if (label.title === buttonProp.title) {
        return { ...label, active: true };
      }
      return { ...label, active: false };
    });
    setReceivedPaymentsButtonFilterList(newLabel);
  };

  const selectByTransfersDateRange = buttonProp => {
    setActiveTransferButton({
      title: buttonProp.title,
      value: buttonProp.value,
    });
    const newLabel = buttonFilterListTransfers.map(label => {
      if (label.title === buttonProp.title) {
        return { ...label, active: true };
      }
      return { ...label, active: false };
    });
    setButtonFilterListTransfers(newLabel);
  };

  const filterListByDaysAgo = (list, days, fromDate = null, toDate = null) => {
    const today = new Date();
    const startDate = subDays(today, days);

    if (days === 9999) return list;
    if (days === 0) {
      const start = fromDate;
      const end = toDate;

      return list.filter(item => {
        const itemDate = parseISO(item.date);
        return (
          (isAfter(itemDate, start) || isEqual(itemDate, start)) &&
          (isBefore(itemDate, end) || isEqual(itemDate, end))
        );
      });
    }
    return list.filter(item => {
      const itemDate = parseISO(item.date); // Convertendo a string de data para um objeto Date
      return (
        isAfter(itemDate, startDate) ||
        itemDate.getTime() === startDate.getTime()
      );
    });
  };

  async function loadPaymentsReceivedWithDate(startDate, endDate) {
    if (startDate && endDate) {
      await loadPaymentsReceived({
        zoopSellerId: seller.zoop_seller_id,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        page: 1,
      });
    }
  }

  return (
    <Container>
      <UniversalLoader open={initialLoading} />
      <ResponsiveHeader title="Saldo da conta" url="/paymentmethods" />
      <Body>
        <S.DivBalance>
          <ConfigurationSectionTitle>Saldo da conta</ConfigurationSectionTitle>
          <ConfigurationSectionSubtitle>
            <div>
              <strong>Repasses futuros ao seu banco(estimados)</strong>

              <span>{(balance && formatPrice(balance)) || 0}</span>
            </div>

            <div>
              <strong>A caminho da sua conta bancária</strong>

              <span>
                {(sumTransfersOnTheWay && formatPrice(sumTransfersOnTheWay)) ||
                  0}
              </span>
            </div>
          </ConfigurationSectionSubtitle>

          <S.DivTotal>
            <span>Total</span>
            <p
              style={{
                color: 'rgba(6, 200, 107, 1)',
                fontWeight: 600,
                fontSize: 24,
                marginTop: 8,
                marginBottom: 16,
              }}
            >
              R$ {formatToBrazilianStyle(balance + sumTransfersOnTheWay)}
            </p>
          </S.DivTotal>

          <ConfigurationSectionSubtitle>
            Seus pagamentos são repassados para a conta abaixo:
          </ConfigurationSectionSubtitle>

          {/* <Bank
            style={{
              marginTop: 16,
            }}
            account={bankData.account_number}
            agency={bankData.agency_number}
            document={bankData.document_number}
            bank={bank.name}
          /> */}

          <S.BankDataWrapper confirmed={bankData && bankData.confirmed}>
            <S.BankInfosWrapper>
              <S.BankIcon src={BankIc} />
              <S.BankData>
                <p>
                  Banco {bankData.name} / Ag: {bankData.agency_number} / Conta:{' '}
                  {bankData.account_number}
                </p>
                <p>
                  CPF/CNPJ:{' '}
                  {documentFormat(
                    bankData.document_type,
                    bankData.document_number
                  )}
                </p>
              </S.BankData>
            </S.BankInfosWrapper>
          </S.BankDataWrapper>

          {(!bankData || (bankData && !bankData.confirmed)) && (
            <S.WarningSpan>
              Falha nos repasses para sua conta bancária. Adicione uma conta
              bancária válida.
            </S.WarningSpan>
          )}

          {seller && seller.blocked && (
            <S.WarningLabel>
              Sua conta está bloqueada devido ao alto número de contestações.
              Entre em contato com o suporte.
            </S.WarningLabel>
          )}
        </S.DivBalance>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection
            onClick={() => setShowReceivedPayments(!showReceivedPayments)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Pagamentos recebidos
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={showReceivedPayments ? arrowUpIcon : arrowDownIcon}
              />
            </div>
          </ConfigurationSection>
          {/* {showReceivedPayments && (
            <>
              <ButtonContainer>
                {receivedPaymentsButtonFilterList.map(button => {
                  return (
                    <ButtonPill
                      active={button.active}
                      onClick={() => selectByReceivedPaymentsDateRange(button)}
                      width={button.width}
                    >
                      {button.title}
                    </ButtonPill>
                  );
                })}
              </ButtonContainer>
              {activeReceivedButton.title === 'Personalizado' && (
                <DatePickerContainer>
                  <DatePicker
                    showIcon
                    selected={receivedPaymentsStartDate}
                    onChange={date => setReceivedPaymentsStartDate(date)}
                    calendarClassName="customDatePicker"
                    popperPlacement="bottom"
                    locale="pt-BR" // Defina a linguagem como pt-BR
                    icon={calendarIco}
                  />
                  <DatePicker
                    showIcon
                    selected={receivedPaymentsEndDate}
                    onChange={date => setReceivedPaymentsEndDate(date)}
                    calendarClassName="customDatePicker"
                    popperPlacement="bottom"
                    locale="pt-BR" // Defina a linguagem como pt-BR
                    minDate={receivedPaymentsStartDate}
                    icon={calendarIco}
                  />
                </DatePickerContainer>
              )}
              {receivedPayments.map(
                (payment, index) =>
                  payment.status === 'succeeded' && (
                    <AccountBalanceItem
                      key={payment.id}
                      payment={payment}
                      last={index === receivedPayments.length - 1}
                      date={payment.date}
                      status={payment.status}
                    />
                  )
              )}
            </>
          )} */}

          {showReceivedPayments && (
            <>
              <ButtonContainer>
                <>
                  {receivedPaymentsButtonFilterList.map(button => {
                    return (
                      <ButtonPill
                        active={button.active}
                        onClick={async () => {
                          try {
                            setLoaderPayment(true);
                            selectByReceivedPaymentsDateRange(button);

                            await loadPaymentsReceived({
                              zoopSellerId: seller && seller.zoop_seller_id,
                              lastDays: button.value,
                              page: 1,
                            });
                          } finally {
                            setLoaderPayment(false);
                          }
                        }}
                        // width={button.width}
                      >
                        {button.title}
                      </ButtonPill>
                    );
                  })}
                </>
              </ButtonContainer>

              {/* {activeTransactionButton &&
                activeTransactionButton.title === 'Personalizado' && (
                  <DatePickerContainer>
                    <DatePicker
                      showIcon
                      selected={receivedPaymentsStartDate}
                      withPortal
                      onChange={async date => {
                        setReceivedPaymentsStartDate(date);
                        await loadPaymentsReceivedWithDate(
                          date,
                          receivedPaymentsEndDate
                        );
                      }}
                      popperPlacement="bottom"
                      locale="pt-BR"
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <button
                            className="datepicker-button"
                            onClick={decreaseMonth}
                          >
                            <img src={ArrowBackIcon} alt="Voltar" />
                          </button>
                          <span
                            sx={{ textTransform: 'capitalize' }}
                            className="datepicker-span-name"
                          >
                            {format(date, 'MMMM yyyy', { locale: ptBR })}
                          </span>
                          <button
                            className="datepicker-button"
                            onClick={increaseMonth}
                          >
                            <img
                              style={{ transform: ' rotate(180deg)' }}
                              src={ArrowBackIcon}
                              alt="Voltar"
                            />
                          </button>
                        </div>
                      )}
                      customInput={<CustomInput />}
                    />
                    <DatePicker
                      showIcon
                      selected={receivedPaymentsEndDate}
                      withPortal
                      onChange={async date => {
                        setReceivedPaymentsEndDate(date);
                        await loadPaymentsReceivedWithDate(
                          receivedPaymentsStartDate,
                          date
                        );
                      }}
                      popperPlacement="bottom"
                      locale="pt-BR" // Defina a linguagem como pt-BR
                      minDate={receivedPaymentsStartDate}
                      renderCustomHeader={({
                        date,
                        decreaseMonth,
                        increaseMonth,
                      }) => (
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <button
                            className="datepicker-button"
                            onClick={decreaseMonth}
                          >
                            <img src={ArrowBackIcon} alt="Voltar" />
                          </button>
                          <span
                            sx={{ textTransform: 'capitalize' }}
                            className="datepicker-span-name"
                          >
                            {format(date, 'MMMM yyyy', { locale: ptBR })}
                          </span>
                          <button
                            className="datepicker-button"
                            onClick={increaseMonth}
                          >
                            <img
                              style={{ transform: ' rotate(180deg)' }}
                              src={ArrowBackIcon}
                              alt="Voltar"
                            />
                          </button>
                        </div>
                      )}
                      customInput={<CustomInput />}
                    />
                  </DatePickerContainer>
                )} */}

              {loaderPayment && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {!loaderPayment &&
                transactions &&
                transactions.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    last={index === transactions.length - 1}
                    date={payment.created_at}
                    status={payment.status}
                    userId={payment.id}
                    type="transaction"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransactionId={handleGetTransactionId}
                  />
                ))}

              {transactions.length === 0 && !loaderPayment && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderPayment &&
                hasMoreTransactions &&
                transactions.length > 0 && (
                  <ButtonSeeMore
                    type="button"
                    disabled={buttonSeeMorePayment}
                    onClick={async () => {
                      try {
                        setButtonSeeMorePayment(true);
                        await loadPaymentsReceived({
                          zoopSellerId: seller && seller.zoop_seller_id,
                          lastDays: lastDaysTransactions,
                          startDate: startDateTransactions,
                          endDate: endDateTransactions,
                          page: currentPageTransactions + 1,
                        });
                      } finally {
                        setButtonSeeMorePayment(false);
                      }
                    }}
                  >
                    {(buttonSeeMorePayment && <LoaderComponent size={20} />) ||
                      'Carregar mais'}
                  </ButtonSeeMore>
                )}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection onClick={() => setShowDisputes(!showDisputes)}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Pagamentos contestados
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={showDisputes ? arrowUpIcon : arrowDownIcon}
              />
            </div>
          </ConfigurationSection>

          {showDisputes && (
            <>
              <ButtonContainer>
                <>
                  {receivedPaymentsButtonFilterList.map(button => {
                    return (
                      <ButtonPill
                        active={button.active}
                        onClick={async () => {
                          try {
                            setLoaderDisputes(true);
                            selectByReceivedPaymentsDateRange(button);

                            await loadDisputes({
                              zoopSellerId: seller && seller.zoop_seller_id,
                              lastDays: button.value,
                              page: 1,
                            });
                          } finally {
                            setLoaderDisputes(false);
                          }
                        }}
                        // width={button.width}
                      >
                        {button.title}
                      </ButtonPill>
                    );
                  })}
                </>
              </ButtonContainer>

              {loaderDisputes && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {disputes.length === 0 && !loaderDisputes && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderDisputes &&
                disputes &&
                disputes.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    last={index === disputes.length - 1}
                    date={payment.created_at}
                    status={payment.status}
                    userId={payment.id}
                    type="dispute"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransactionId={handleGetTransactionId}
                  />
                ))}

              {!loaderDisputes && hasMoreDisputes && (
                <ButtonSeeMore
                  type="button"
                  disabled={buttonSeeMorePayment}
                  onClick={async () => {
                    try {
                      setButtonSeeMoreDisputes(true);
                      await loadPaymentsReceived({
                        zoopSellerId: seller && seller.zoop_seller_id,
                        lastDays: lastDaysDisputes,
                        startDate: startDateDisputes,
                        endDate: endDateDisputes,
                        page: currentPageDisputes + 1,
                      });
                    } finally {
                      setButtonSeeMoreDisputes(false);
                    }
                  }}
                >
                  {(buttonSeeMoreDisputes && <LoaderComponent size={20} />) ||
                    'Carregar mais'}
                </ButtonSeeMore>
              )}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection
            onClick={() => setShowChargeBacks(!showChargeBacks)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Contestações perdidas
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={showChargeBacks ? arrowUpIcon : arrowDownIcon}
              />
            </div>
          </ConfigurationSection>

          {showChargeBacks && (
            <>
              <ButtonContainer>
                <>
                  {receivedPaymentsButtonFilterList.map(button => {
                    return (
                      <ButtonPill
                        active={button.active}
                        onClick={async () => {
                          try {
                            setLoaderChargeBacks(true);
                            selectByReceivedPaymentsDateRange(button);

                            await loadChargeBacks({
                              zoopSellerId: seller && seller.zoop_seller_id,
                              lastDays: button.value,
                              page: 1,
                            });
                          } finally {
                            setLoaderChargeBacks(false);
                          }
                        }}
                        // width={button.width}
                      >
                        {button.title}
                      </ButtonPill>
                    );
                  })}
                </>
              </ButtonContainer>

              {loaderChargeBacks && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {chargeBacks.length === 0 && !loaderChargeBacks && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderChargeBacks &&
                chargeBacks &&
                chargeBacks.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    last={index === chargeBacks.length - 1}
                    date={payment.created_at}
                    status={payment.status}
                    userId={payment.id}
                    type="dispute"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransactionId={handleGetTransactionId}
                  />
                ))}

              {!loaderChargeBacks && hasMoreChargeBacks && (
                <ButtonSeeMore
                  type="button"
                  disabled={buttonSeeMorePayment}
                  onClick={async () => {
                    try {
                      setButtonSeeMoreChargeBacks(true);
                      await loadChargeBacks({
                        zoopSellerId: seller && seller.zoop_seller_id,
                        lastDays: lastDaysChargeBacks,
                        startDate: startDateChargeBacks,
                        endDate: endDateChargeBacks,
                        page: currentPageChargeBacks + 1,
                      });
                    } finally {
                      setButtonSeeMoreChargeBacks(false);
                    }
                  }}
                >
                  {(buttonSeeMoreChargeBacks && (
                    <LoaderComponent size={20} />
                  )) ||
                    'Carregar mais'}
                </ButtonSeeMore>
              )}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection
            onClick={() =>
              setShowFutureTransfersToYourAccount(
                !showFutureTransfersToYourAccount
              )
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Repasses futuros
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={
                  showFutureTransfersToYourAccount ? arrowUpIcon : arrowDownIcon
                }
              />
            </div>
          </ConfigurationSection>

          {showFutureTransfersToYourAccount && (
            <>
              <ButtonContainer>
                {buttonFilterListTransfers.map(button => {
                  return (
                    <ButtonPill
                      active={button.active}
                      onClick={async () => {
                        try {
                          setLoaderFutureTransfer(true);
                          selectByTransfersDateRange(button);

                          await loadFutureTransfers({
                            zoopSellerId: seller && seller.zoop_seller_id,
                            nextDays: button.value,
                            page: 1,
                          });
                        } finally {
                          setLoaderFutureTransfer(false);
                        }
                      }}
                      // width={button.width}
                    >
                      {button.title === 'Ontem' ? 'Amanhã' : button.title}
                    </ButtonPill>
                  );
                })}
              </ButtonContainer>

              {loaderFutureTransfer && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {futureTransfers.length === 0 && !loaderFutureTransfer && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderFutureTransfer &&
                futureTransfers &&
                futureTransfers.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    date={payment.items[0].expected_on}
                    status={payment.status}
                    userId={userId}
                    type="future-transfer"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransferId={handleGetTransferId}
                  />
                ))}

              {!loaderFutureTransfer && hasMoreFutureTranfers && (
                <ButtonSeeMore
                  type="button"
                  onClick={async () => {
                    await loadFutureTransfers({
                      zoopSellerId: seller && seller.zoop_seller_id,
                      nextDays: lastDaysFutureTranfers,
                      startDate: startDateFutureTranfers,
                      endDate: endDateFutureTranfers,
                      page: currentPageFutureTranfers + 1,
                    });
                  }}
                >
                  Carregar mais
                </ButtonSeeMore>
              )}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection
            onClick={() => setShowTransfersOnTheWay(!showTransfersOnTheWay)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Repasses à caminho
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={showTransfersOnTheWay ? arrowUpIcon : arrowDownIcon}
              />
            </div>
          </ConfigurationSection>

          {showTransfersOnTheWay && (
            <>
              {loaderTransferOnTheWay && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {transfersOnTheWay.length === 0 && !loaderTransferOnTheWay && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderTransferOnTheWay &&
                transfersOnTheWay &&
                transfersOnTheWay.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    date={payment.transfer_date}
                    status={payment.status}
                    userId={userId}
                    type="transfer"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransferId={handleGetTransferId}
                  />
                ))}

              {/* {!loaderTransferOnTheWay && (
                <ButtonSeeMore
                  type="button"
                  onClick={async () => {
                    await loadTransfersOnTheWay({
                      zoopSellerId: seller && seller.zoop_seller_id,
                      lastDays: 90,
                      page: currentPageTranfers + 1,
                    });
                  }}
                >
                  Carregar mais
                </ButtonSeeMore>
              )} */}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection
            onClick={() =>
              setShowTransfersToYourAccount(!showTransfersToYourAccount)
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Repasses concluídos
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={showTransfersToYourAccount ? arrowUpIcon : arrowDownIcon}
              />
            </div>
          </ConfigurationSection>

          {showTransfersToYourAccount && (
            <>
              <ButtonContainer>
                {buttonFilterListTransfers.map(button => {
                  return (
                    <ButtonPill
                      active={button.active}
                      onClick={async () => {
                        try {
                          setLoaderTransfer(true);
                          selectByTransfersDateRange(button);

                          await loadTransfers({
                            zoopSellerId: seller && seller.zoop_seller_id,
                            lastDays: button.value,
                            page: 1,
                          });
                        } finally {
                          setLoaderTransfer(false);
                        }
                      }}
                      // width={button.width}
                    >
                      {button.title}
                    </ButtonPill>
                  );
                })}
              </ButtonContainer>

              {loaderTransfer && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {transfers.length === 0 && !loaderTransfer && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderTransfer &&
                transfers &&
                transfers.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    date={payment.transfer_date}
                    status={payment.status}
                    userId={userId}
                    type="transfer"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransferId={handleGetTransferId}
                  />
                ))}

              {!loaderTransfer && (
                <ButtonSeeMore
                  type="button"
                  onClick={async () => {
                    await loadTransfers({
                      zoopSellerId: seller && seller.zoop_seller_id,
                      lastDays: lastDaysTranfers,
                      startDate: startDateTranfers,
                      endDate: endDateTranfers,
                      page: currentPageTranfers + 1,
                    });
                  }}
                >
                  Carregar mais
                </ButtonSeeMore>
              )}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <DivOpenAndClose>
          <ConfigurationSection
            onClick={() => setShowTransfersFailed(!showTransfersFailed)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 16px',
              }}
            >
              <ConfigurationSectionTitle>
                Repasses que falharam
              </ConfigurationSectionTitle>
              <img
                alt="Seta indicando lista"
                src={showTransfersFailed ? arrowUpIcon : arrowDownIcon}
              />
            </div>
          </ConfigurationSection>

          {showTransfersFailed && (
            <>
              <ButtonContainer>
                {buttonFilterListTransfers.map(button => {
                  return (
                    <ButtonPill
                      active={button.active}
                      onClick={async () => {
                        try {
                          setLoaderTransfersFailed(true);
                          selectByTransfersDateRange(button);

                          await loadTransfersFailed({
                            zoopSellerId: seller && seller.zoop_seller_id,
                            lastDays: button.value,
                            page: 1,
                          });
                        } finally {
                          setLoaderTransfersFailed(false);
                        }
                      }}
                      // width={button.width}
                    >
                      {button.title}
                    </ButtonPill>
                  );
                })}
              </ButtonContainer>

              {loaderTransfersFailed && (
                <DivLoader>
                  <LoaderComponent size={25} />
                </DivLoader>
              )}

              {transfersFailed.length === 0 && !loaderTransfersFailed && (
                <S.DivLabel>
                  <span>Nenhum registro encontrado</span>
                </S.DivLabel>
              )}

              {!loaderTransfersFailed &&
                transfersFailed &&
                transfersFailed.map((payment, index) => (
                  <AccountBalanceItem
                    key={payment.id}
                    payment={payment}
                    first={index === 0}
                    date={payment.transfer_date}
                    status={payment.status}
                    userId={userId}
                    type="transfer"
                    isDesktop={isDesktop}
                    handlePageSelected={handlePageSelected}
                    handleGetTransferId={handleGetTransferId}
                  />
                ))}

              {!loaderTransfersFailed && (
                <ButtonSeeMore
                  type="button"
                  onClick={async () => {
                    await loadTransfers({
                      zoopSellerId: seller && seller.zoop_seller_id,
                      lastDays: lastDaysTranfersFailed,
                      startDate: startDateTranfersFailed,
                      endDate: endDateTranfersFailed,
                      page: currentPageTranfersFailed + 1,
                    });
                  }}
                >
                  Carregar mais
                </ButtonSeeMore>
              )}
            </>
          )}
        </DivOpenAndClose>

        <Divider margin="0" />

        <Space height="40px" />

        <S.DivHelpCenterOffstorePay>
          <DivHelpCenter />
        </S.DivHelpCenterOffstorePay>

        <Space height="40px" />
      </Body>
    </Container>
  );
}

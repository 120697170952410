import styled from 'styled-components';

export const Container = styled.div`
  width: 810px !important;
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 40px 32px;
  margin-top: 16px;
  border: 0.5px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 12px;
`;

export const DivMinValue = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 150px;
    height: 45px;
    padding: 0 12px;
    font-size: 13px;

    &::placeholder {
      color: #afafaf;
    }
  }
`;

export const DivPrice = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 45px;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
    font-size: 13px;
    color: #101010;
  }

  input {
    background: #f9f9f9;
    border-radius: 0px 8px 8px 0px;
    border: none;
    width: 100px;
    padding: 0 12px;

    &::placeholder {
      font-size: 13px;
      color: #bfbfbf;
    }
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  position: relative;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-bottom: 4px;
  }

  > span {
    font-size: 13px;
    color: #838383;
  }

  > label {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const DivSelectConfigurationLabel = styled.div`
  margin-top: 24px;
  width: 100%;

  .has-opacity {
    opacity: 50%;
  }

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 14px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    & + div {
      margin-top: 16px;
    }

    > span {
      font-size: 13px;
      line-height: 19px;
      color: #101010;
    }
  }
`;

export const DivSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  cursor: pointer;
  background: ${props => (props.enabled ? '#2b8ff5' : '#EFEFEF')};
  border: ${props =>
    props.enabled ? '1px solid #2b8ff5' : '1px solid #EFEFEF'};
`;

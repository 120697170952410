import React from 'react';

function LockIcon() {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.8969 14.0171H8.32188C7.03955 14.0171 6 15.0566 6 16.339V30.2702C6 31.5526 7.03955 32.5921 8.32188 32.5921H26.8969C28.1792 32.5921 29.2188 31.5526 29.2188 30.2702V16.339C29.2188 15.0566 28.1792 14.0171 26.8969 14.0171Z"
        stroke="white"
        strokeWidth="2.49375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7365 14.0166V10.5338C25.7365 8.37848 24.8804 6.31146 23.3563 4.78745C21.8323 3.26342 19.7653 2.40723 17.61 2.40723C15.4547 2.40723 13.3876 3.26342 11.8636 4.78745C10.3396 6.31146 9.4834 8.37848 9.4834 10.5338V14.0166"
        stroke="white"
        strokeWidth="2.49375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6102 24.4649C18.2513 24.4649 18.7711 23.9452 18.7711 23.304C18.7711 22.6628 18.2513 22.1431 17.6102 22.1431C16.969 22.1431 16.4492 22.6628 16.4492 23.304C16.4492 23.9452 16.969 24.4649 17.6102 24.4649Z"
        stroke="white"
        strokeWidth="2.49375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default LockIcon;

import produce from 'immer';

const INITIAL_STATE = {
  links: null,
  linksQuantity: 0,
  noStockLinks: null,
  globalLinks: null,
  tags: null,
  promotions: null,
  payment_methods: null,
  loadingRegisterProduct: false,
  loadingCreateOrUpdateTag: false,
  exceededProducts: false,

  page: 1,
  totalCount: 0,
  count: 0,
  hasMore: true,
  inputSearch: '',
};

export default function linkPrev(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@linkPrev/LIST_LINK_PREVIEW_SUCCESS': {
        draft.links = action.payload.links;
        draft.globalLinks = action.payload.links;

        draft.noStockLinks = action.payload.links.filter(
          p =>
            p.stock_quantity === 0 ||
            (p.gridattributes.length > 0 &&
              p.gridattributes.find(pg => pg.stock_quantity === 0))
        );

        break;
      }
      case '@linkPrev/LIST_PRODUCTS_PAGINATION_SUCCESS': {
        draft.links = action.payload.products;
        draft.globalLinks = action.payload.products;

        draft.noStockLinks = action.payload.links.filter(
          p =>
            p.stock_quantity === 0 ||
            (p.gridattributes.length > 0 &&
              p.gridattributes.find(pg => pg.stock_quantity === 0))
        );
        break;
      }
      case '@linkPrev/LIST_TAGS_SUCCESS': {
        draft.tags = action.payload.tags;
        break;
      }
      case '@linkPrev/LIST_COUNT_SUCCESS': {
        draft.linksQuantity = action.payload.count;
        break;
      }
      case '@linkPrev/INSERT_TAG_SUCCESS': {
        draft.tags.push(action.payload.tag);
        draft.loadingCreateOrUpdateTag = false;
        break;
      }
      case '@linkPrev/UPDATE_TAG_SUCCESS': {
        const tagIndex = draft.tags.findIndex(
          tag => tag.id === action.payload.data.id
        );

        if (tagIndex >= 0) {
          draft.tags[tagIndex] = action.payload.data;
        }

        draft.loadingCreateOrUpdateTag = false;
        break;
      }
      case '@linkPrev/REMOVE_TAG_SUCCESS': {
        const tagsFiltered = draft.tags.filter(
          tag => tag.id !== action.payload.tagId
        );

        draft.tags = tagsFiltered;
        break;
      }
      case '@linkPrev/LOADING_CREATE_OR_UPDATE_TAG_SUCCESS': {
        draft.loadingCreateOrUpdateTag = action.payload.loading;
        break;
      }
      case '@linkPrev/ADD_SUBCATEGORIES_ON_CATEGORIES_SUCCESS': {
        const arrayConcat = draft.tags.concat(
          action.payload.subcategories.data
        );
        draft.tags = arrayConcat;
        break;
      }

      case '@linkPrev/UPDATE_ORDER_SEUCCESS': {
        draft.links = action.payload.links;
        draft.globalLinks = action.payload.links;
        break;
      }
      case '@linkPrev/FILTER_LINKS_BY_TAG': {
        draft.links = draft.globalLinks;

        const linksFiltered = draft.links.filter(
          link =>
            link.tags.findIndex(tag => tag.id === action.payload.tagId) > -1
        );

        draft.links = linksFiltered;

        break;
      }
      case '@linkPrev/INSERT_UPDATE_LINK_REQUEST': {
        draft.loadingRegisterProduct = true;
        break;
      }
      case '@linkPrev/SAVE_LINK_SUCCESS': {
        // action.payload.link.images.push(action.payload.images);
        draft.links.unshift(action.payload.link);
        draft.loadingRegisterProduct = false;
        break;
      }
      case '@linkPrev/UPDATE_LINK_SUCCESS': {
        const productIndex = draft.links.findIndex(
          p => p.id === action.payload.link.id
        );

        if (productIndex >= 0) {
          draft.links[productIndex] = action.payload.link;
          draft.links[productIndex].images = action.payload.images;
          // draft.links[productIndex].images.push(action.payload.images);
        }

        draft.lastProductId = null;
        draft.loadingRegisterProduct = false;
        break;
      }
      case '@linkPrev/INSERT_UPDATE_LINK_FAILURE': {
        draft.loadingRegisterProduct = false;

        draft.exceededProducts =
          (action && action.payload && action.payload.exceededProducts) ||
          false;
        break;
      }
      case '@linkPrev/UPDATE_ACTIVE_LINK_SUCCESS': {
        const productIndex = draft.links.findIndex(
          p => p.id === action.payload.linkId
        );

        if (productIndex >= 0) {
          draft.links[productIndex].active = action.payload.isActive;
        }

        draft.tags.map((tag, index) => {
          const linkIndex =
            tag.links &&
            tag.links.findIndex(link => link.id === action.payload.linkId);

          if (linkIndex >= 0) {
            draft.tags[index].links[linkIndex].active = action.payload.isActive;
          }
          return tag;
        });
        break;
      }

      case '@linkPrev/UPDATE_HIGHLIGHTED_LINK_SUCCESS': {
        const productIndex = draft.links.findIndex(
          p => p.id === action.payload.linkId
        );

        if (productIndex >= 0) {
          draft.links[productIndex].highlighted = action.payload.isHighlighted;
        }

        draft.tags.map((tag, index) => {
          const linkIndex =
            tag.links &&
            tag.links.findIndex(link => link.id === action.payload.linkId);

          if (linkIndex >= 0) {
            draft.tags[index].links[linkIndex].highlighted =
              action.payload.isHighlighted;
          }
          return tag;
        });
        break;
      }

      case '@linkPrev/SAVE_IMAGE_LINK_SUCCESS': {
        const productIndex = draft.links.findIndex(
          p => p.id === action.payload.linkId
        );

        if (productIndex >= 0) {
          draft.links[productIndex].images.push(action.payload.images);
        }
        break;
      }
      case '@linkPrev/REMOVE_IMAGE_LINK_SUCCESS': {
        const productIndex = draft.links.findIndex(
          p => p.id === action.payload.linkId
        );

        if (productIndex >= 0) {
          draft.links[productIndex].image = null;
          draft.links[productIndex].image_id = null;
        }
        break;
      }
      case '@linkPrev/UPDATE_TYPE_LINK_SUCCESS': {
        const productIndex = draft.links.findIndex(
          p => p.id === action.payload.link.id
        );

        if (productIndex >= 0) {
          draft.links[productIndex].type = action.payload.link.type;
        }
        break;
      }
      case '@linkPrev/LIST_PROMOTION_SUCCESS': {
        draft.promotions = action.payload.promotions;
        break;
      }
      case '@linkPrev/INSERT_PROMOTION_SUCCESS': {
        draft.promotions.push(action.payload.promotion);
        break;
      }
      case '@linkPrev/UPDATE_PROMOTION_SUCCESS': {
        const promotionIndex = draft.promotions.findIndex(
          p => p.id === action.payload.promotion.id
        );

        if (promotionIndex >= 0) {
          draft.promotions[promotionIndex] = action.payload.promotion;
        }
        break;
      }
      case '@linkPrev/REMOVE_PROMOTION_SUCCESS': {
        const promotionFiltered = draft.promotions.filter(
          p => p.id !== action.payload.promotionId
        );

        draft.promotions = promotionFiltered;
        break;
      }
      case '@linkPrev/LIST_PAYMENT_METHODS_SUCCESS': {
        draft.payment_methods = action.payload.payment_methods;
        break;
      }
      case '@linkPrev/UPDATE_PAYMENT_METHODS_SUCCESS': {
        draft.payment_methods = action.payload.payment_methods;
        break;
      }
      case '@linkPrev/UPDATE_STOCK_SUCCESS': {
        const productIndex = draft.noStockLinks.findIndex(
          p => p.id === action.payload.productId
        );

        if (productIndex >= 0 && action.payload.amount >= 0) {
          draft.noStockLinks[productIndex].stock_quantity =
            action.payload.amount;
        }
        break;
      }

      case '@linkPrev/UPDATE_PAGE_SUCCESS': {
        draft.page = action.payload.page;
        break;
      }

      case '@linkPrev/UPDATE_TOTAL_COUNT_SUCCESS': {
        draft.totalCount = action.payload.totalCount;
        break;
      }

      case '@linkPrev/UPDATE_COUNT_SUCCESS': {
        draft.count = action.payload.count;

        break;
      }
      case '@linkPrev/UPDATE_HAS_MORE_SUCCESS': {
        draft.hasMore = action.payload.hasMore;
        break;
      }

      case '@linkPrev/CLEAR_STATE_INFINITE_SCROLL_SUCCESS': {
        draft.page = 1;
        draft.totalCount = 0;
        draft.count = 0;
        draft.hasMore = true;
        break;
      }

      case '@linkPrev/UPDATE_INPUT_SEARCH_SUCCESS': {
        draft.inputSearch = action.payload.search;
        break;
      }

      default:
        return state;
    }
  });
}

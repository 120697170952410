import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;

  .div-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(227, 229, 236, 0.65);
    z-index: 550;
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;

  & + div {
    margin-top: 32px;
  }

  > div {
    display: flex;
    align-items: baseline;

    > span {
      margin-left: 4px;
    }
  }

  > input {
    width: 100%;
    height: 42px;
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    padding: 0 12px;
    font-size: 13px;
    color: #101010;
    margin-top: 12px;

    &::placeholder {
      color: #bfbfbf;
    }
  }
`;

export const DivSelectProducts = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .div-select-products {
    justify-content: space-between;

    > span {
      font-size: 13px;
      text-align: right;
      color: #838383;
    }
  }

  > span {
    font-size: 13px;
    color: #838383;
  }

  > div {
    display: flex;
    align-items: baseline;

    > span {
      margin-left: 4px;

      @media (max-width: 350px) {
        font-size: 11px;
      }
    }

    > button {
      border: none;
      background: none;
      font-weight: 500;
      font-size: 14px;
      color: #0d85ff;

      > svg {
        margin-right: 6px;
      }

      @media (max-width: 350px) {
        font-size: 13px;
      }
    }
  }

  > span {
    margin-top: 8px;
    margin-bottom: 12px;
  }
`;

export const Strong = styled.strong`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #000000;
`;

export const SpanLabel = styled.span`
  font-weight: 400;
  font-size: 11px;
  color: ${props => (props.required ? '#0d85ff' : '#9D9D9D')};
`;

export const DivImage = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .div-label-image {
    align-items: baseline;
  }

  > div {
    display: flex;

    > span {
      margin-left: 4px;
    }
  }
`;

export const Div = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 75vh;
  background: #fff;
  border-radius: 20px 20px 0 0;
  z-index: 550;

  > header {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > strong {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    > img {
      position: absolute;
      right: 24px;
    }

    > button {
      position: absolute;
      right: 24px;
      color: #ff303c;
      border: none;
      background: none;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 0 16px 100px 16px;
    overflow-y: auto;
    /* height: 450px; */

    > span {
      font-size: 11px;
      line-height: 16px;
      color: #9d9d9d;
      margin-bottom: 8px;
    }

    > input {
      background: #f9f9f9;
      border-radius: 8px;
      font-size: 13px;
      line-height: 19px;
      color: #101010;
      border: none;
      min-height: 42px;
      padding: 0 12px;
      margin-bottom: 16px;
    }

    > button {
      display: flex;
      align-self: flex-start;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
      margin-bottom: 16px;
      /* margin: auto; */
    }
  }

  > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    border-top: 0.5px solid #d8dee5;
    background: #fff;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #2b8ff5;
      border-radius: 8px;
      height: 50px;
      border: none;
      color: #fff;

      &:disabled {
        background: #838383;
      }

      > img {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (min-width: 720px) {
    height: auto;
    bottom: auto;
    max-width: 580px;
    top: calc(50vh - (500px / 2));
    left: calc(50vw - (580px / 2));
    border-radius: 12px;

    > header {
      max-width: 580px;
      border-radius: 12px 12px 0 0;
    }

    > div {
      height: 500px;
      padding: 82px 16px;
    }

    > footer {
      border-radius: 0 0 12px 12px;
    }
  }
`;

export const DivDevice = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0;

  > div {
    display: flex;
    margin-bottom: 8px;

    > div {
      display: flex;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;

      > span {
        margin-left: 6px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #9d9d9d;
      }

      .selected {
        color: #2b8ff5;
      }
    }
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }
`;

export const DivBannerImages = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #9d9d9d;
    box-sizing: border-box;
    box-shadow: none;
    min-width: ${props => (props.desktop ? '100px' : '80px')};
    height: 80px;
    margin-right: 10px;
    background: none;
    border-radius: 10px;

    > img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const LabelImage = styled.label`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;

export const DivImages = styled.div`
  display: flex;
  /* margin-top: 8px; */

  .selected {
    border: 1px solid #101010;
  }

  > img {
    width: 40px;
    height: 40px;
    align-self: center;
  }
`;

import styled, { css } from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  position: relative;

  header {
    display: flex;
    width: 100%;
    /* max-width: 1366px; */
    margin: 0 auto;
    height: 66px;
    align-items: center;
    padding: 0 40px;
    justify-content: space-between;
    background: #fff;
    position: fixed;
    z-index: 500;
    border-bottom: 0.5px solid #efefef;

    ${props =>
      props.environment === 'development' &&
      css`
        background: #b2e9df;
      `}

    ${props =>
      props.environment === 'homolog' &&
      css`
        background: #9cc7f3;
      `}

    .div-space {
      display: flex;
      width: 33%;
      span {
        color: #fff;
      }
    }
  }
`;

export const LabelPastDueSubscription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ff0f0f;
  padding: 0 32px;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 20%;
  width: 80%;
  z-index: 201;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #ffffff;
  }

  > span {
    font-size: 14px;
    color: #ffffff;
    margin-top: 4px;
  }
`;

export const LabelMaintenanceMode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ff6c4b;
  padding: 16px 16px 16px 16px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 501;

  > span {
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    text-align: center;
  }
`;

export const DivAvatar = styled.div`
  display: flex;
  width: 33%;
  justify-content: flex-start;

  img {
    margin-right: 12px;
    object-fit: cover;
    cursor: pointer;
  }

  span {
    align-self: center;
    font-weight: 600;
    font-size: 17px;
    color: #000000;
  }
`;

export const DivLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  input {
    margin-right: 7px;
    height: 43px;
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    padding: 0 12px;
    width: 300px;
    font-size: 13px;
    color: #2b8ff5;
    font-weight: 500;
    text-decoration: underline;
  }

  span {
    margin-right: 8px;
  }

  > a {
    display: flex;
    width: 42px;
    height: 42px;
    background: #2b8ff5;
    border: 0.5px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 8px;
    align-items: center;
    justify-content: center;

    &:hover {
      filter: opacity(0.85);
      transition: all 300ms;
    }
  }
`;

export const DivMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 18%);
  height: 52px;
  /* border-top: 0.5px solid #efefef; */
  border-bottom: 0.5px solid #efefef;
  position: fixed;
  top: 66px;
  left: 18%;
  background: #fff;
  z-index: 100;

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    list-style-type: none;
    width: 500px;
    height: 100%;

    li {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0;
      cursor: pointer;

      .selected {
        box-sizing: border-box;
        border-bottom: 0.5px solid #000000;
        padding-top: 1px;
        color: #000;
      }
    }
  }
`;

export const Tab = styled.div`
  color: #a5a9b1;
  text-decoration: none;
  font-size: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 24px;
`;

export const DivBody = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 100%;
  /* max-width: 1366px; */
  margin: 0 auto;
`;

export const LinkContact = styled.a`
  display: flex;
  margin-bottom: 32px;
  text-decoration: none;
`;

export const DivLeft = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 20% !important;
  min-height: 100%;
  /* border-top: 0.5px solid #efefef; */
  border-right: 0.5px solid #efefef;
  background: #fff;
  padding: 35px 40px 35px 40px;
  position: fixed;
  top: 66px;
  z-index: 101;

  > div {
    /* width: 230px; */
    display: flex;
    flex-direction: column;
    position: relative;

    .selected {
      color: #101010;
    }
  }

  .button-exit {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #efefef;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 16px;
    position: absolute;
    bottom: 50px;
    height: 50px;
    width: 70%;

    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #101010;

    &:hover {
      transition: all 300ms;
      filter: opacity(0.85);
    }
  }
`;

export const MenuItem = styled.button`
  display: flex;
  margin-bottom: 32px;
  font-size: 14px;
  color: #afafaf;
  background: none;
  border: none;
  /* transition: all 0.3s; */

  > span {
    text-align: left;
  }

  .span-link {
    color: #2b8ff5;
    font-weight: 500;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    margin-right: 15px;
  }

  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    color: #dedddd;

    &:hover {
      color: #dedddd;
    }
  }

  .span-new {
    color: #2b8ff5;
    border: 1px solid #2b8ff5;
    border-radius: 4px;
    font-size: 12px !important;
    padding: 0 4px;
    margin-left: 8px;
  }

  .soon-label {
    color: #ffb525;
    border: 1px solid #ffb525;
    border-radius: 4px;
    font-size: 12px !important;
    padding: 0 4px;
    margin-left: 8px;
  }
`;

export const OrderNotification = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 8px !important;
  height: 8px;
  background: #ff6c4b;
  /* background: ${props =>
    !props.color || props.color === '#fff' || props.color === '#ffffff'
      ? '#ff6c4b'
      : props.color}; */
  border-radius: 50%;
  margin-right: 0 !important;
  margin-left: 6px;

  > span {
    font-size: 13px;
    color: #ffffff;
    /* margin-top: 1px; */
  }
`;

export const DivPro = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > span {
    color: #ff1f89;
    font-size: 12px;
    margin-top: 8px;
    font-weight: 600;
  }
`;

export const DivInviteFriends = styled.button`
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  /* flex-direction: column; */
  padding: 0 12px;
  background: linear-gradient(
    238.72deg,
    #ff6534 -43.51%,
    #ff0e9f 37.82%,
    #a925fa 106.7%
  );
  height: 50px;
  border-radius: 8px;
  position: relative;
  border: none;
  margin-right: auto;
  width: 100%;

  > span {
    font-weight: 600;
    font-size: 14px;
    color: #ffffff;
  }

  > svg {
    margin-left: 8px;
  }

  .off {
    color: #34c780;
    border: 1px solid #34c780;
    padding: 2px 5px;
    border-radius: 4px;
    font-size: 11px;
    margin-left: 12px;
  }

  > img {
    margin-left: 8px;
  }
`;

export const DivRight = styled.div`
  /* padding: 0 4vw; */
  opacity: 1;
  width: 100%;
  /* padding-bottom: 72px; */
  min-height: calc(100vh - 66px);
  background: #fff;
`;

export const AddVariation = styled.div`
  display: flex;
  justify-content: center;

  .div-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(227, 229, 236, 0.65);
    z-index: 550;
  }
`;

export const DivAddVariation = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 80vh;
  top: calc(50vh - (550px / 2));
  left: calc(50vw - (600px / 2));
  width: 600px;
  background: #fff;
  border-radius: 20px;
  z-index: 560;

  > header {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 600px;
    border-radius: 20px 20px 0 0;

    > strong {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    > img {
      position: absolute;
      right: 24px;
      cursor: pointer;
    }

    > button {
      position: absolute;
      right: 24px;
      color: #ff303c;
      border: none;
      background: none;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 82px 16px 82px 16px;
    /* height: 450px; */

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 2px;
    }

    > span {
      font-size: 11px;
      line-height: 16px;
      color: #9d9d9d;
      margin-bottom: 8px;
    }

    > button {
      display: flex;
      align-self: flex-start;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
      margin-bottom: 16px;
    }

    > input {
      background: #f9f9f9;
      border-radius: 8px;
      font-size: 13px;
      line-height: 19px;
      color: #101010;
      border: none;
      min-height: 42px;
      padding: 0 12px;
      margin-bottom: 16px;
    }
  }

  > footer {
    position: absolute;
    bottom: 0;
    width: 600px;
    padding: 16px;
    border-top: 0.5px solid #d8dee5;
    background: #fff;
    border-radius: 0 0 20px 20px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #2b8ff5;
      border-radius: 8px;
      height: 50px;
      border: none;
      color: #fff;
    }
  }
`;

export const DivItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  height: ${props => (props.create ? '27vh' : '42vh')};
  padding-bottom: 24px;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const DivItem = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  padding-right: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
  }

  > input {
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 8px;
    font-size: 13px;
    line-height: 19px;
    color: #101010;
    height: 42px;
    padding: 0 12px;
    width: 100%;

    &:focus {
      border: 1px solid #2b8ff5;
      background: #fff;
    }
  }

  > img {
    margin-left: 20px;
    width: 12px;
    cursor: pointer;
  }

  > button {
    display: flex;
    align-self: flex-start;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
    border: none;
    background: none;
  }
`;

export const DivProPlan = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    270deg,
    #ff6534 -15.84%,
    #ff0e9f 62.42%,
    #a925fa 104.39%
  );
  padding: 12px 32px;
  position: fixed;
  bottom: 0;
  /* z-index: 9999; */
  left: 20%;
  width: 80%;
  /* border-radius: 12px; */
  /* margin-left: 45px; */
  z-index: 201;

  > header {
    display: flex;
    margin-bottom: 4px;

    > div {
      border-radius: 4px;
      border: 1px solid #fff;
      padding: 2px 4px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }

    > strong {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      margin-right: 6px;
    }
  }

  > div {
    display: flex;

    > strong {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    > span {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-decoration: line-through;
      margin-left: 4px;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 6px;
    width: 274px;
    height: 45px;
    position: absolute;
    right: 32px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    /* color: #2b8ff5; */
    text-decoration: none;
    border: none;

    > span {
      font-size: 14px;
      font-weight: 700;
      background: var(
        --Cores-new-gradiet,
        linear-gradient(220deg, #ff6534 12.15%, #ff0e9f 64.72%, #a925fa 102.33%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const DivStockProducts = styled.div`
  width: 100%;
  padding-bottom: 65px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 10px;
    margin: 0 0 8px 0;
    height: 62px;
    border: 0.5px solid #d8dee5;
    border-radius: 12px;
    cursor: pointer;

    > img {
      width: 50px;
      height: 47px;
      border-radius: 6px;
      margin-right: 5px;
      object-fit: cover;
      border: none;
      background: none;
      outline: none;
    }
  }
`;

export const DivStockProductsLeft = styled.div`
  /* width: 50%; */
  flex: 1;
  padding-left: 5px;
  display: flex;
  flex-direction: column;

  span {
    max-width: 300px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;

    > strong {
      color: #aaaaaa;
      font-weight: normal;
      font-size: 13px;
      margin-right: 12px;
    }

    span {
      color: #9d9d9d;
      margin-left: 4px;
      font-size: 13px;
    }

    .red-stock {
      color: #ff6c4b;
    }

    .yellow-stock {
      color: #ffb525;
    }
  }
`;

export const DivStockProductsRight = styled.div`
  /* width: 40%; */
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  .no-stock {
    background: rgba(255, 48, 60, 0.1);
    color: #ff303c;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 6px;
    height: 34px;
    min-width: 32px;
    border: none;
  }

  > input {
    display: flex;
    height: 34px;
    width: 60px;
    background: #f9f9f9;
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 6px;
    text-align: center;
  }
`;

export const DivBodyRight = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  width: calc(100% - 20%);
  flex-direction: column;
  background: #fafafa;
  position: ${props => (props.fixed ? 'fixed' : 'absolute')};
  top: ${props => props.top || '66px'};
  left: 20%;
  padding: 40px;
  /* overflow-y: auto; */
  height: calc(100vh - ${props => props.top || '118px'});

  > div {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 auto;

    > span {
      font-weight: 500;
      margin-bottom: 10px;
    }
  }
`;

export const ButtonSeeMore = styled.button`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  justify-content: center;
  /* margin: auto; */
  color: #2b8ff5;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
  }
`;

export const LabelPro = styled.div`
  display: flex;
  padding: 2px 6px;
  border: 1px solid #2b8ff5;
  border-radius: 4px;
  margin-left: 6px;
  margin-bottom: 10px;

  > span {
    font-weight: 500;
    font-size: 11px !important;
    line-height: 16px;
    color: #2b8ff5 !important;
    margin: 0 !important;
  }
`;

export const DivInputSearch = styled.div`
  display: flex;
  width: 1000%;

  input {
    /* width: 100%;
    height: 50px;
    background: #ffffff;
    border: 0.5px solid #efefef;
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    padding: 0 24px;
    font-size: 13px;

    &::placeholder {
      color: #afafaf;
    }

    &:focus {
      box-shadow: 0px 0px 13px 0px rgb(43 143 245 / 14%);
      border-color: #2b8ff53b;
      transition: all 300ms;
    } */
  }
`;

export const StyledDebounceInput = styled(DebounceInput)`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 0.5px solid #efefef;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 0 24px;
  font-size: 13px;

  &::placeholder {
    color: #afafaf;
  }

  &:focus {
    box-shadow: 0px 0px 13px 0px rgb(43 143 245 / 14%);
    border-color: #2b8ff53b;
    transition: all 300ms;
  }
`;

export const DivTags = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px !important;
  margin-bottom: 16px;

  .subcategorias {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }

  > div {
    display: flex;
    align-items: center;
  }

  span {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
  }

  .div-tags {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
`;

export const TagAdd = styled.button`
  width: 40px;
  height: 40px;
  border: none;
  font-size: 24px;
  background: transparent;
  border: 0.5px solid #efefef;
  box-sizing: border-box;
  border-radius: 10px;
  margin-right: 8px;
  margin-bottom: 8px;

  /* display: flex;
  align-items: center;
  justify-content: center; */

  img {
    width: 14px;
    height: 14px;
    margin-bottom: 2px;
  }
`;

export const Tag = styled.button`
  height: 40px;
  border: none;
  border-radius: 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  margin-bottom: 8px;
  background: #ededef;

  ${props =>
    props.selected &&
    css`
      background: #2b8ff5;
      color: #ffffff;

      .;
    `}

  .body-tag {
    padding-left: 12px;
    padding-right: 12px;
    border-right: ${props =>
      props.selected
        ? '0.5px solid rgba(255, 255, 255, 0.33)'
        : '0.5px solid #d8dee5'};
  }

  > div {
    display: flex;
    height: 40px;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
  }

  img {
    height: 17px;
  }

  .icons-variations {
    /* padding-left: 0 !important; */
    border-left: ${props =>
      props.selected
        ? '0.5px solid rgba(255, 255, 255, 0.33)'
        : '0.5px solid #d8dee5'};

    > img {
      & + img {
        margin-left: 6px !important;
      }
    }
  }
`;

export const DivAddProduct = styled.div`
  display: flex;
  flex-direction: column;

  span {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
  }

  button {
    width: 333px;
    height: 45px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;
    color: #fff;
    margin-bottom: 24px;
    font-size: 13px;
    font-weight: 500;

    &[disabled] {
      cursor: not-allowed;
      /* opacity: 0.6; */
      background: #cacaca;
    }

    > img {
      width: 25px;
      height: 25px;
      margin-top: 3px;
    }
  }
`;

export const DivTabStock = styled.div`
  display: flex;
  margin-bottom: 24px !important;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    & + div {
      margin-left: 20px;
    }
    /* padding: 20px 0; */

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #cfcfcf;
    }

    .active {
      color: #2b8ff5;
    }

    .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background: #2b8ff5;
      border-radius: 50%;
      color: #fff;
      margin-left: 4px;

      > strong {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        margin-top: 2px;
      }
    }
  }
`;

export const DivLinks = styled.div`
  display: flex;
  flex-direction: column;
  /* width: 100% !important; */
  padding-bottom: 65px;

  .loader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 12px 0;

    > img {
      width: 30px;
      height: 30px;
    }
  }
`;

export const ButtonCopyToClipboard = styled.button`
  width: 42px;
  height: 42px;
  margin-right: 8px;
  background: #efefef;
  border: 0.5px solid #efefef;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(0.85);
    transition: all 300ms;
  }
`;

export const ButtonLinkStore = styled.button`
  display: flex;
  width: 42px;
  height: 42px;
  background: #2b8ff5;
  border: 0.5px solid #2b8ff5;
  box-sizing: border-box;
  border-radius: 8px;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: opacity(0.85);
    transition: all 300ms;
  }
`;

export const DivMyAccount = styled.div`
  display: flex;
  height: 42px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: 16px;
  padding-left: 16px;
  border-left: 0.5px solid #d8dee5;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      font-size: 15px;
      line-height: 18px;
      color: #000000;
      margin-left: 8px;
      margin-right: 0;
    }

    > img {
      height: 24px;
      /* margin-top: 7px; */
    }
  }

  /* > div {
    display: flex;
    flex-direction: row !important;
    align-items: center;
    text-decoration: none;
    width: 44px !important;
    height: 44px !important;
    padding: 2px;
    border-radius: 50%;
    background: none !important;
    border: 0.5px solid #efefef;
    box-sizing: border-box;
    transition: all 0.3s;
    cursor: pointer;

    &:hover {
      border: 0.5px solid #8a8a8a;
      box-sizing: border-box;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  } */
`;

export const PromoNotification = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  /* background: ${props => props.color}; */
  background: #2B8FF5;
  border-radius: 50%;
  /* margin-top: -38px; */
  margin-left: 8px;
  /* position: absolute; */

  span {
    font-size: 11px;
    color: #ffffff;
    margin: auto;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  background-color: rgb(255, 255, 255, 1);
  position: fixed;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center !important;
  align-items: center !important;
  z-index: 9999;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  img {
    width: 50px;
    height: 50px;
  }
`;

import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import freight from './freight/sagas';
import user from './user/sagas';
import linkPrev from './linkPrev/sagas';
import order from './order/sagas';

export default function* rootSaga() {
  return yield all([auth, freight, user, linkPrev, order]);
}

import styled, { css } from 'styled-components';

export const SelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ marginBottom }) => marginBottom || '0'}px;
  margin-top: ${({ marginTop }) => marginTop || '0'}px;
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SelectButton = styled.button`
  padding: 0 24px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  background-color: #ffffff;

  p {
    font-size: 13px;
    font-weight: 500;
    color: #9d9d9d;
  }

  ${props =>
    props.left &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none;
    `}

  ${props =>
    props.right &&
    css`
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;

        p {
          color: #0d85ff;
        }
      `}


`;

export const SelectButtonBottomInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6px;

  p {
    font-size: 13px;
    color: #9d9d9d;
  }
`;

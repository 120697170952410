import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { device } from '../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 551;
  background: #000000;
  opacity: 20%;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;
  max-width: 580px;
  height: 66vh;
  border-radius: 24px;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background: #fff;
  z-index: 551;
  position: fixed;
  margin-top: 7vh;

  .div-header {
    position: relative;
    width: 100%;
    min-height: 60px;
    border-bottom: 0.5px solid #d8dee5;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      margin-bottom: 0 !important;
    }

    > div {
      position: absolute;
      right: 0;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      > button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #ff303c;
      }
    }
  }

  .div-body {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
    padding: 0 16px;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 8px;
    }
  }

  @media ${device.mobile} {
    bottom: 0;
    margin-top: 0;
    border-radius: 24px 24px 0 0;
  }
`;

export const SpanPro = styled.span`
  border: 1px solid #2b8ff5;
  border-radius: 4px;
  padding: 0 5px;
  color: #2b8ff5 !important;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  border: none;
  background: #f9f9f9;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 24px;

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const DivCategory = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    > strong {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
      margin-left: 6px;
    }
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 4px;
  font-size: 13px;
  margin-bottom: 16px;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      touch-action: none;
    `}

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }

  .css-g1d714-ValueContainer {
    padding: 6px 8px;
  }
`;

export const SeeMore = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #2b8ff5;
  margin: auto;
  cursor: pointer;
`;

export const Button = styled.button`
  width: 100%;
  height: 50px;
  /* box-shadow: 6px 11px 13px rgba(10, 12, 13, 0.1); */
  background: #2b8ff5;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  margin-top: 32px;

  > img {
    width: 25px;
    height: 25px;
  }
`;

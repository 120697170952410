import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(227, 229, 236, 0.65);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 500px;
  /* height: 227px; */
  border-radius: 16px;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background: #fff;
  z-index: 550;
  position: fixed;
  top: calc(50vh - (357px / 2));
  left: calc(50vw - (500px / 2));
  box-shadow: 16px 34px 58px rgba(172, 161, 161, 0.25);

  .div-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 24px 24px 24px;

    > span {
      margin-bottom: 5px;
      font-weight: 500;
      font-size: 13px;
      color: #000000;
    }
  }
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
  border-bottom: 0.5px solid #d8dee5;
  margin-bottom: 16px;
  width: 100%;

  > span {
    text-align: center;
    color: #000000;
    font-size: 15px;
    font-weight: 500;
  }

  > button {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #ff303c;
    padding: 0 24px;
  }

  img {
    cursor: pointer;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;
  background: #ffffff;
  box-sizing: border-box;
  padding: 0 15px;
  margin-bottom: 16px;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  font-size: 13px;

  &::placeholder {
    color: #afafaf;
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 42px;
  background: #2b8ff5;
  border-radius: 8px;
  border: none;
  color: #fff;
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;

  > img {
    width: 25px;
    height: 25px;
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 4px;
  font-size: 13px;
  margin-bottom: 16px;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      touch-action: none;
    `}

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }

  .css-g1d714-ValueContainer {
    padding: 6px 8px;
  }
`;

export const DivCategory = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    > strong {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
      margin-left: 6px;
    }
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }
`;

export const SeeMore = styled.div`
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #2b8ff5;
  margin: auto;
  cursor: pointer;
`;

export const SpanPro = styled.span`
  border: 1px solid #2b8ff5;
  border-radius: 4px;
  padding: 0 5px;
  color: #2b8ff5 !important;
`;

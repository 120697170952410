/* eslint-disable react/prop-types */
import React from 'react';

import {
  Container,
  ContentWrapper,
  StepBar,
  StepBarsWrapper,
  StepInfo,
} from './styles';

export function StepsHeader({ step }) {
  return (
    <Container>
      <ContentWrapper>
        <StepInfo>Passo {step}/2</StepInfo>
        <StepBarsWrapper>
          <StepBar active={step === '1'} />
          <StepBar active={step === '2'} />
        </StepBarsWrapper>
      </ContentWrapper>
    </Container>
  );
}

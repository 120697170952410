import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const DivHeader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 64px;

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #262626;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-space {
    width: 15%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      width: 10px;
      cursor: pointer;
    }
  }

  .div-text {
    width: 70%;
  }
`;

export const Div = styled.div`
  width: 100%;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: none;
  height: 100%;
  padding: 0 24px;

  .text {
    display: flex;
  }

  h1 {
    font-weight: 600;
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin-bottom: 24px;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .button-continue {
    background: ${props => (props.block ? '#CACACA' : '#2b8ff5')};
    border-radius: 10px;
    color: #fff;
    border: none;
    width: 100%;
    height: 45px;
    margin-bottom: 15px;
  }

  .button-jump {
    width: 100%;
    background: none;
    border: none;
    font-size: 14px;
    color: #a2a8b2;
  }
`;

export const DivCategories = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 24px;

  > button {
    background: #efefef;
    border-radius: 8px;
    padding: 8px 12px;
    border: none;
    margin-right: 8px;
    margin-bottom: 8px;
    font-size: 14px;
  }

  .selected {
    background: #1c1c1c;
    color: #fff;
  }
`;

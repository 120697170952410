import styled from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  top: 0;
  left: 0;
`;

export const Div = styled.div`
  width: 499px;
  height: 314px;
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (314px / 2));
  left: calc(50vw - (499px / 2));
`;

export const DivTitle = styled.div`
  display: flex;
  height: 55px;
  border-bottom: 0.5px solid #d8dee5;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: rgb(0, 0, 0);
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 10%;
    height: 100%;
  }
`;

export const DivProductsOrder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
  background: #ffffff;
  border-radius: 16px 16px 0 0;

  > span {
    margin-bottom: 12px;
    font-size: 13px;
    color: #838383;
  }
`;

export const DivProductOrder = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: 200px;

  ::-webkit-scrollbar {
    display: none;
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 14px;

    > img {
      width: 60px;
      height: 60px;
      object-fit: cover;
      border-radius: 8px;
      margin-right: 12px;
    }

    > div {
      display: flex;
      flex-direction: column;

      > strong {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #000000;
      }

      > span {
        font-size: 13px;
        color: #838383;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 0;
  padding: 24px 16px 0 16px;
`;

export const ConfigurationSection = styled.div``;

export const ConfigurationSectionTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const ConfigurationSectionSubtitle = styled.p`
  font-size: 13px;
  line-height: 18px;
  color: #838383;
  margin-top: 4px;
`;

export const GenericButton = styled.button`
  padding: 9px 28px;
  background: #2b8ff5;
  border: 1px solid #2b8ff5;
  box-sizing: border-box;
  border-radius: 6px;
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 50px;
  margin: 12px 0px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }

    > button {
      padding: 9px 28px;
      background: #2b8ff5;
      border: 1px solid #2b8ff5;
      box-sizing: border-box;
      border-radius: 6px;
      text-decoration: none;
      color: #fff;
    }
  }

  .offstore-pay-header {
    display: flex;
    align-items: center;

    .recommended-flag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      padding: 4px 6px;
      border-radius: 4px;
      background: #06c86b;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .offstore-pay-description {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin: 16px 0px;
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  > a {
    padding: 9px 28px;
    background: #2b8ff5;
    border: 1px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

export const BankDataWrapper = styled.div`
  height: 68px;
  padding: 16px;
  background-color: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 32px;
`;

export const BankIcon = styled.img``;

export const BankData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 13px;
    color: #838383;
  }
`;

// interface SelectButtonProps {
//   isEdge?: 'left' | 'right';
// }

export const SelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px 22px 0px;
  /* background-color: yellow; */
  /* justify-content: center; */

  .is-selected {
    border: 1px solid #0d85ff;
    p {
      color: #0d85ff;
    }
  }

  .left-edge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .right-edge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SelectButton = styled.button`
  padding: 12px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  background-color: #ffffff;

  p {
    font-size: 13px;
    font-weight: 500;
    color: #9d9d9d;
  }
`;

export const SelectButtonBottomInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6px;

  p {
    font-size: 13px;
    color: #9d9d9d;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 32px 0px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  min-height: 100vh;
  padding: 54px 18px 32px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;

  > button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #2b8ff5;
    color: #fff;
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 8px;

    > img {
      position: absolute;
      right: 20px;
    }
  }
`;

export const DivSelectConfigurationLabel = styled.div`
  margin-top: 24px;
  width: 100%;
  max-width: 375px;

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 14px;
  }

  > div {
    display: flex;
    justify-content: space-between;

    & + div {
      margin-top: 16px;
    }

    > span {
      font-size: 13px;
      line-height: 19px;
      color: #101010;
    }
  }
`;

export const DivSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: ${props => (props.enabled ? '#2b8ff5' : '#EFEFEF')};
  border: ${props =>
    props.enabled ? '1px solid #2b8ff5' : '1px solid #EFEFEF'};
`;

export const DivLabel = styled.div`
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  margin: 32px 0 24px 0;
  padding: 16px 16px;
  border: 1px dashed #101010;

  .title {
    display: flex;
    width: 316px;
    height: 27px;
    align-items: center;
    border-bottom: 0.5px solid #000000;
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: 16px;
  }

  .titleSender {
    font-style: normal;
    font-weight: 700;
    font-size: 13px; //11
    line-height: normal;
    color: #000000;
    margin-bottom: 8px;
    margin-left: -16px;
  }

  .small {
    font-size: 11px;
  }

  .divider-section {
    padding: 0px;
  }

  .mg-top-4 {
    margin-top: 4px;
  }

  .mg-bottom-8 {
    margin-bottom: 8px;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 16px 16px;
    width: 100%;
    border: 0.5px solid #000000;

    & + div {
      border: none;
    }

    > div {
      display: flex;
      justify-content: space-between;
    }

    span {
      font-size: 13px;
      line-height: 19px;
      color: #000000;
    }

    .bold {
      font-weight: 600;
    }

    strong {
      font-style: normal;
      font-weight: 700;
      font-size: 16px; //11
      line-height: normal;
      padding-left: 16px;

      & + strong {
        margin-top: 16px;
      }
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 810px !important;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #101010;
    margin-right: 6px;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  margin-bottom: 48px;
  padding: 32px;

  > button {
    font-size: 15px;
    color: #000000;
    width: max-content;
    border: none;
    background: none;

    & + button {
      margin-top: 28px;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 0.5px solid #d8dee5;

    > div {
      display: flex;

      & + div {
        margin-top: 28px;
      }

      > button {
        font-weight: 500;
        font-size: 15px;
        color: #2b8ff5;
        text-decoration: none;
        border: none;
        background: none;
      }

      > span {
        border: 1px solid #2b8ff5;
        border-radius: 4px;
        padding: 0 6px;
        color: #2b8ff5;
        margin-left: 8px;
      }
    }
  }
`;

export const DivMaintenance = styled.div`
  display: flex;
  flex-direction: row !important;
  width: 100%;
  justify-content: space-between;

  > strong {
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
    color: #000000;
  }
`;

import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { darken } from 'polished';
import { device } from '../../config/device';

import 'react-datepicker/dist/react-datepicker.css';

import { FaLock, FaRegTrashAlt } from 'react-icons/fa';

export const Div = styled.div`
  background: #fff;
  display: flex;
  margin: 0 5px 10px 5px;
  padding: 3px 0px 0px 0px;
  height: 75px;
  width: 312px;
  align-items: center;
  border: 0.5px solid #d8dee5;
  border-radius: 12px;

  ${props =>
    !props.active &&
    css`
      background: #fafafa;
      opacity: 0.5;
    `};

  input {
    /* width: 320px; */
    background: #f5f5f7;
  }

  #general-div {
    display: flex;
  }

  #div-select {
    display: flex;
  }

  ${props =>
    props.isDragging &&
    css`
      background: transparent;
      box-shadow: none;
      cursor: grabbing;

      div {
        opacity: 0;
      }
    `}

  @media (max-width: 1300px) {
    width: 100%;
  }

  @media ${device.mobile} {
    margin: 0 0 8px 0;
    padding: 0;
    width: 100%;
    height: 62px;
    border: 0.5px solid #d8dee5;
  }
`;

export const DivDrag = styled.div`
  width: 5%;
  cursor: grab;
  display: flex;
  justify-content: center;

  img {
    height: 20px;
  }
`;

export const DivAll = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 15px 10px 10px;
`;

export const Image = styled.img`
  width: 50px;
  height: 47px;
  border-radius: 6px;
  margin-right: 5px;
  object-fit: cover;
  border: none;
  background: none;
  outline: none;
`;

export const DivLeft = styled.div`
  width: 50%;
  padding-left: 5px;
  display: flex;
  flex-direction: column;

  span {
    max-width: 100%;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;

    > strong {
      color: #aaaaaa;
      font-weight: normal;
      font-size: 13px;
      margin-right: 12px;
    }

    span {
      color: #9d9d9d;
      margin-left: 4px;
      font-size: 13px;
    }

    .red-stock {
      color: #ff6c4b;
    }

    .yellow-stock {
      color: #ffb525;
    }
  }
`;

export const DivRight = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  a {
    display: flex;
    align-items: center;

    .loader {
      width: 25px;
      /* height: 20px; */
    }
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 7px;
  font-size: 13px;

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }
`;

export const SimpleSelect = styled(Select)`
  width: 80px;
  border-radius: 10px;

  .css-yk16xz-control {
    border-radius: 10px;
  }

  .css-1uccc91-singleValue {
    color: #b0abba;
    display: flex;
  }
`;

export const FaRegTrashAlts = styled(FaRegTrashAlt)`
  &:hover {
    color: ${darken(0.05, '#cccccc')} !important;
  }
`;

export const SpanPrice = styled.span`
  width: 100%;
  font-size: 14px;
  color: ${props => props.color || '#838383'};
  text-decoration: ${props => (props.hasPromo ? 'line-through' : 'none')};
  margin-right: 6px;
  font-weight: 400;
`;

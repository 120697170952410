// Botão do menu lateral de plano pro mobile
export const seePlansSidebarBtnEvent = () => {
  window.gtag('event', 'see_plans_sidebar_btn', {
    debug_mode: false,
  });
};

// Botão do menu lateral de plano pro desktop
export const seePlansSidebarBtnDesktopEvent = () => {
  window.gtag('event', 'see_plans_sidebar_desktop_btn', {
    debug_mode: false,
  });
};

export const seePlansTopbarBannerEvent = () => {
  // Banner superior de plano pro
  window.gtag('event', 'see_plans_topbar_banner', {
    debug_mode: false,
  });
};

export const seePlansBottombarBannerDesktopEvent = () => {
  // Banner superior de plano pro
  window.gtag('event', 'see_plans_bottombar_banner_desktop', {
    debug_mode: false,
  });
};

export const seePlansProModalEvent = () => {
  // Botão de assinar do modal de assinar plano PRO
  window.gtag('event', 'see_plans_pro_modal', {
    debug_mode: false,
  });
};

export const seePlansPremiumModalEvent = () => {
  // Botão de assinar do modal de assinar plano Premium
  window.gtag('event', 'see_plans_premium_modal', {
    debug_mode: false,
  });
};

export const seePlansMoreOptionsMenuEvent = () => {
  // Menu "Planos" do menu "Mais opções"
  window.gtag('event', 'see_plans_moreoptions_menu', {
    debug_mode: false,
  });
};

export const seePlansMoreOptionsMenuDesktopEvent = () => {
  // Menu "Planos" do menu "Mais opções"
  window.gtag('event', 'see_plans_moreoptions_menu_desktop', {
    debug_mode: false,
  });
};

export const signupEvent = () => {
  // Botão de "Criar conta" na tela de criação de conta
  window.gtag('event', 'signup', {
    debug_mode: false,
  });
};

export const whatsappAddEvent = () => {
  // Botão de cadastrar número do whatsapp na tela de criação de conta
  window.gtag('event', 'whatsapp_add', {
    debug_mode: false,
  });
};

export const productAddEvent = () => {
  // Botão de Adicionar na tela de Cadastro de produto
  window.gtag('event', 'product_add', {
    debug_mode: false,
  });
};

export const productAddDesktopEvent = () => {
  // Botão de Adicionar na tela de Cadastro de produto
  window.gtag('event', 'product_add_desktop', {
    debug_mode: false,
  });
};

export const activatedFreightEvent = () => {
  // Botão de adicionar CEP
  window.gtag('event', 'activated_freight', {
    debug_mode: false,
  });
};

export const activatedFreightDesktopEvent = () => {
  // Botão de adicionar CEP
  window.gtag('event', 'activated_freight_desktop', {
    debug_mode: false,
  });
};

export const seeStoreBtnEvent = () => {
  // Botão de "Ver loja"
  window.gtag('event', 'see_store_btn', {
    debug_mode: false,
  });
};

export const seeStoreBtnDesktopEvent = () => {
  // Botão de "Ver loja"
  window.gtag('event', 'see_store_btn_desktop', {
    debug_mode: false,
  });
};

export const copyLinkBtnEvent = () => {
  // Botao de copiar link
  window.gtag('event', 'copy_link_btn', {
    debug_mode: false,
  });
};

export const copyLinkBtnDesktopEvent = () => {
  // Botao de copiar link
  window.gtag('event', 'copy_link_btn_desktop', {
    debug_mode: false,
  });
};

export const subscribeProPlanYearlyBtnEvent = () => {
  // Subscription PRO anual
  window.gtag('event', 'subscribe_pro_plan_yearly_btn', {
    debug_mode: false,
  });
};

export const subscribeProPlanMonthlyBtnEvent = () => {
  // Subscription PRO mensal
  window.gtag('event', 'subscribe_pro_plan_monthly_btn', {
    debug_mode: false,
  });
};

export const subscribePremiumPlanYearlyBtnEvent = () => {
  // Subscription Premium anual
  window.gtag('event', 'subscribe_premium_plan_yearly_btn', {
    debug_mode: false,
  });
};

export const subscribePremiumPlanMonthlyBtnEvent = () => {
  // Subscription Premium mensal
  window.gtag('event', 'subscribe_premium_plan_monthly_btn', {
    debug_mode: false,
  });
};

export const subscribeEscalaPlanYearlyBtnEvent = () => {
  // Subscription Escala anual
  window.gtag('event', 'subscribe_escala_plan_yearly_btn', {
    debug_mode: false,
  });
};

export const subscribeEscalaPlanMonthlyBtnEvent = () => {
  // Subscription Escala mensal
  window.gtag('event', 'subscribe_escala_plan_monthly_btn', {
    debug_mode: false,
  });
};

export const successPaymentPageBtnEvent = () => {
  // Subscription realizada
  window.gtag('event', 'success_payment_page_btn', {
    debug_mode: false,
  });
};

import styled from 'styled-components';
import { device } from '../../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  float: 1;
  z-index: 99990;
  background: rgba(0, 0, 0, 0.2);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #ffffff;
    margin-top: 16px;

    > span {
      color: #ffffff;
      font-weight: 700;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
  margin-top: 24px;
  background: transparent;
  border-top: 0.5px solid rgba(255, 255, 255, 0.3);
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.3);
`;

export const DivPrice = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DiscountLabel = styled.div`
  display: flex;
  height: max-content;
  border-radius: 24px;
  background: #ffffff;
  font-size: 10px;
  font-weight: 500;
  padding: 8px 16px;

  > span {
    color: #ff2483;
  }
`;

export const Price = styled.span`
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;

  > span {
    font-size: 13px;
  }
`;

export const OldPrice = styled.span`
  color: #ffffff;
  font-size: 13px;
  font-weight: 400;
  text-decoration: line-through;
  opacity: 0.5;
`;

export const Div = styled.div`
  width: 440px;
  background: linear-gradient(
    245deg,
    #ff6534 1.17%,
    #ff0e9f 41.04%,
    #a925fa 72.04%
  );
  border-radius: 16px;
  z-index: 99991;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (300px / 2));
  left: calc(50vw - (440px / 2));
  padding: 24px 20px;
  margin-top: 0 !important;

  @media ${device.mobile} {
    width: 90%;
    left: auto;
  }
`;

export const DivButton = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 24px;

  > a {
    /* background: #2b8ff5; */
    background: #ffffff;
    color: #d718c9;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 50px;
    position: relative;
    font-weight: 600;
    font-size: 15px;

    > img {
      position: absolute;
      right: 20px;
    }
  }

  > button {
    /* background: #2b8ff5; */
    background: #ffffff;
    color: #d718c9;
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 50px;
    position: relative;
    font-weight: 600;
    font-size: 15px;

    > img {
      position: absolute;
      right: 20px;
    }
  }
`;

import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  /* margin-top: 16px; */

  .cancel {
    margin-top: -6px;
    margin-left: -10px;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.14);
  }

  .product-image {
    width: ${props => (props.desktop ? '190px' : '150px')};
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    border: none;
    background: none;
    outline: none;
  }
`;

export const Container = styled.div`
  min-width: 45px;
  min-height: 45px;
  border-radius: 10px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
  margin-top: 4px;
`;

import React from 'react';
import { useDispatch } from 'react-redux';

import { Link } from 'react-router-dom';

import BackButtonIcon from '../../../assets/icons/backButton.svg';
import ExitIcon from '../../../assets/icons/menu/exit.svg';

import { signOut } from '../../../store/modules/auth/actions';

import { Container, Body } from './styles';

function Configurations() {
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <Container>
      <header>
        <div className="div-space">
          <Link to="/settings">
            <img src={BackButtonIcon} alt="" />
          </Link>
        </div>
        <div>
          <span>Configurações</span>
        </div>
        <div className="div-space">
          <button type="button" onClick={handleSignOut}>
            <img src={ExitIcon} alt="exit" />
            <span>Sair</span>
          </button>
        </div>
      </header>

      <Body>
        <Link to="/myaccount" className="disabled">
          Configurações gerais
        </Link>

        <Link to="/configurationorder">Configurações de pedido</Link>

        <Link to="/myaccount" className="disabled">
          Configurações de frete
        </Link>

        {/* <Link to="#" className="disabled">
          Frete
        </Link> */}
      </Body>
    </Container>
  );
}

export default Configurations;

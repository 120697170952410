import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  border-bottom: 4px solid #fafafa;
  margin-top: ${props => (props.first ? '55px' : '0')};
  background: #fff;

  > div {
    display: flex;
    align-items: ${props => props.alignItems || 'center'};
    justify-content: space-between;
    margin-bottom: 8px;

    a {
      margin-left: 6px;
      font-size: 13px;
      color: #2b8ff5;
      text-decoration: none;
    }
  }
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: ${props => props.fontWeight || '500'};
  font-size: ${props => props.size || '14px'};
  color: #000000;
`;

export const Span = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: ${props => props.size || '13px'};
  color: #838383;
  margin: ${props => props.margin};

  > a {
    font-weight: 500;
    font-size: 13px;
    color: #0d85ff;
    text-decoration: none;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 16px 16px 16px;
  background: #ffffff;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 50px;
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    color: #101010;
    margin-bottom: 12px;
    position: relative;

    > img {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 16px;
    }
  }

  > button {
    width: 100%;
    height: 50px;
    background: #ff303c;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    margin-bottom: 8px;

    &:disabled {
      background: #838383;
    }
  }

  > span {
    font-size: 11px;
    color: #838383;
    text-align: center;
  }
`;

export const DivPaymentStatus = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 16px;

  ${props =>
    (props.color === 'Pedido enviado' || props.color === 'Pedido pronto') &&
    css`
      > div {
        background: rgba(43, 143, 245, 0.06);
        color: #2b8ff5;
      }
    `}
  ${props =>
    props.color === 'Aprovado' &&
    css`
      > div {
        background: rgba(43, 143, 245, 0.06);
        color: #2b8ff5;
      }
    `}

    ${props =>
      props.color === 'Pendente' &&
      css`
        > div {
          background: rgba(255, 181, 37, 0.06);
          color: #ffb525;
        }
      `}

    ${props =>
      props.color === 'Pagamento à combinar' &&
      css`
        > div {
          background: rgba(255, 181, 37, 0.06);
          color: #ffb525;
        }
      `}

    ${props =>
      props.color === 'Pagamento pendente' &&
      css`
        > div {
          background: rgba(16, 16, 16, 0.06);
          color: rgba(16, 16, 16, 0.5);
        }
      `}

      ${props =>
        props.color === 'Em preparação' &&
        css`
          > div {
            background: rgba(131, 72, 255, 0.06);
            color: #8348ff;
          }
        `}

      ${props =>
        props.color === 'Pagamento confirmado' &&
        css`
          > div {
            background: rgba(6, 200, 107, 0.06);
            color: #06c86b;
          }
        `}
        ${props =>
          (props.color === 'Cancelado' ||
            props.color === 'Pagamento expirado' ||
            props.color === 'Pagamento reprovado') &&
          css`
            > div {
              background: rgba(255, 48, 60, 0.1);
              color: #ff303c;
            }
          `}

  > div {
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 13px;
  }
`;

export const DivClient = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding};

  > button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    height: 50px;
    border: none;

    > img {
      position: absolute;
      right: 20px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  .paid {
    background: rgba(52, 199, 128, 0.06);
    color: #34c780;
    margin-top: 12px;
  }

  .ticket {
    background: #2b8ff5;
    color: #fff;
    margin-bottom: 12px;
  }

  .disabled {
    opacity: 0.5;
  }

  .notify {
    background: rgba(43, 143, 245, 0.06);
    color: #2b8ff5;
    margin-top: 12px;
  }

  .cancel {
    background: rgba(255, 48, 60, 0.06);
    color: #ff303c;
  }

  .not-viewed {
    height: 30px;
    margin: auto;
    margin-top: 18px;
    color: #2b8ff5;
    background: none;

    > img {
      position: initial;
      margin-right: 8px;
    }
  }

  .content-declaration-button {
    height: 30px;
    margin: auto;
    margin-top: 18px;
    color: #838383;
    background: none;

    > img {
      position: initial;
      margin-right: 8px;
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    border-radius: 8px;
    font-weight: 500;
    font-size: 13px;
    color: #010101;
    margin: 12px 0;
    position: relative;
    background: #f9f9f9;

    > img {
      width: 17px;
      height: 17px;
      position: absolute;
      right: 20px;
    }
  }
`;

export const ModalActive = styled.div`
  display: flex;
  align-items: center;
  /* position: fixed;
  top: 0;
  left: 0; */

  .container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(227, 229, 236, 0.65);
    top: 0;
    left: 0;
    opacity: 0.8;
    overflow: hidden;
    float: 1;
    z-index: 11;
    align-items: center;
    justify-content: center;
  }

  .div {
    position: fixed;
    /* width: 400px; */
    /* height: 270px; */
    background: #ffffff;
    border-radius: 12px;
    top: calc(50vh - (270px / 2));
    left: calc(50vw - (400px / 2));

    padding: 24px 16px;
    z-index: 12;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      margin-bottom: 22px;
    }

    > strong {
      font-style: normal;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      text-align: center;
      color: #838383;
      margin-bottom: 16px;
      margin-top: 12px;
    }

    > button {
      width: 100%;
      height: 50px;
      background: #2b8ff5;
      border-radius: 8px;
      color: #fff;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }
  }

  .grey-button {
    background: #efefef !important;
    color: #101010 !important;
  }

  .red-button {
    background: #ff303c !important;
    color: #ffffff;
    margin-top: 12px;
  }

  @media (max-width: 720px) {
    .div {
      top: auto;
      bottom: 0;
      left: 0;
      border-radius: 12px 12px 0 0;
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
    }

    > span {
      font-size: 11px;
      color: #838383;
      line-height: 15px;
      margin-left: 6px;
    }
  }

  > input {
    width: 100%;
    background: #f9f9f9;
    border-radius: 8px;
    height: 42px;
    border: none;
    padding: 0 12px;
  }
`;

export const DivCheckBox = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0 24px 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${props => (props.activeCheckBox ? '#2b8ff5' : '#ffffff')};
    border: 0.5px solid
      ${props => (props.activeCheckBox ? '#2b8ff5' : '#9d9d9d')};
    border-radius: 6px;
    width: 26px;
    height: 26px;
    margin-right: 8px;
  }

  > span {
    font-size: 13px;
    line-height: 19px;
    color: #101010;
  }
`;

export const OutlinedButton = styled.button`
  color: var(--blue-details, #0d85ff);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border-width: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

import React from 'react';

// import { Svg } from './styles';

export function PaperIcon({ color }) {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.5 14.001L9.5 17.001L16.5 9.00098"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 1H5C3.93914 1 2.92172 1.42143 2.17158 2.17158C1.42143 2.92172 1 3.93914 1 5V27L6 24L11 27L16 24L21 27V4C21 3.20436 21.316 2.44128 21.8786 1.87868C22.4412 1.31607 23.2044 1 24 1ZM24 1C24.7956 1 25.5588 1.31607 26.1214 1.87868C26.684 2.44128 27 3.20436 27 4V11H21"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

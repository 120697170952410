import React from 'react';

// import { Container } from './styles';

function CatalogIcon({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.53846 1H2.38462C1.61991 1 1 1.61991 1 2.38462V6.53846C1 7.30316 1.61991 7.92308 2.38462 7.92308H6.53846C7.30316 7.92308 7.92308 7.30316 7.92308 6.53846V2.38462C7.92308 1.61991 7.30316 1 6.53846 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6146 1H13.4608C12.6961 1 12.0762 1.61991 12.0762 2.38462V6.53846C12.0762 7.30316 12.6961 7.92308 13.4608 7.92308H17.6146C18.3793 7.92308 18.9992 7.30316 18.9992 6.53846V2.38462C18.9992 1.61991 18.3793 1 17.6146 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.53846 12.0781H2.38462C1.61991 12.0781 1 12.698 1 13.4627V17.6166C1 18.3813 1.61991 19.0012 2.38462 19.0012H6.53846C7.30316 19.0012 7.92308 18.3813 7.92308 17.6166V13.4627C7.92308 12.698 7.30316 12.0781 6.53846 12.0781Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6146 12.0781H13.4608C12.6961 12.0781 12.0762 12.698 12.0762 13.4627V17.6166C12.0762 18.3813 12.6961 19.0012 13.4608 19.0012H17.6146C18.3793 19.0012 18.9992 18.3813 18.9992 17.6166V13.4627C18.9992 12.698 18.3793 12.0781 17.6146 12.0781Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CatalogIcon;

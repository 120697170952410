import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export default function RedirectURIMercadoPago() {
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');

  // precisa passar essa rota de direcionamento pra fora do app.offstore,
  // pois aqui, obrigatoriamente a rota precisa ser privada para funcionar,
  // e para o retorno no mobile, a rota precisa ser pública

  useEffect(() => {
    // if (code) {
    //   if (navigator.userAgent.match(/(Chrome|Firefox|Safari|Edg)/)) {
    //     history.push(`/paymentmethods/configurationmercadopago?code=${code}`);
    //   } else {
    //     window.location.href = `offstore://mercadopago/${code}`;
    //   }
    // }

    if (code) {
      history.push(`/paymentmethods/configurationmercadopago?code=${code}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return <></>;
}

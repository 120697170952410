import styled from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
`;

export const Div = styled.div`
  width: 499px;
  height: 260px;
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: flex;
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (260px / 2));
  left: calc(50vw - (500px / 2));
`;

export const DivTitle = styled.div`
  display: flex;
  height: 55px;
  border-bottom: 0.5px solid #d8dee5;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      color: #000000;
      font-size: 15px;
      font-weight: 500;
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 10%;
    height: 100%;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 24px 24px 24px;

  .button-exit {
    color: #ff303c;
  }

  > button {
    width: max-content;
    display: flex;
    border: none;
    background: none;
    margin-top: 24px;
    font-weight: 500;
    font-size: 14px;
    color: #000000;

    &:disabled {
      color: #e5e5e5;
    }
  }
`;

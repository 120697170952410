import React, { useEffect, useState, useCallback } from 'react';
// import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import api from '~/services/api';

import LabelPremium from '~/components/ProPlan/Labels/LabelPremium';
import { isValidUserToPlan } from '~/functions/user';
import { seePlansMoreOptionsMenuDesktopEvent } from '~/functions/events';
import { Container, DivTitle, DivBody, DivMaintenance } from './styles';
import PremiumPlanModal from '../../../../components/Popups/PremiumPlan';

function MoreOptionsConfigurations({ SetIndexMenu }) {
  const [profile, setProfile] = useState();
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);
  // const [userConfigurations, setUserConfigurations] = useState(false);

  useEffect(() => {
    async function load() {
      await api.get('/getuser').then(response => {
        setProfile(response.data);
      });

      await api.get('/userconfigurations').then(response => {
        // setUserConfigurations(response.data);
        setMaintenanceMode(response.data.maintenance);
      });
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function ProPlanPopUp(pro_modal) {
    setShowProPlanPopUp(pro_modal);
  }

  function handleClickButton(indexMenu) {
    if (profile && isValidUserToPlan(profile)) {
      SetIndexMenu(indexMenu);
      return 0;
    }

    ProPlanPopUp(true);
    return 0;
  }

  const onChangeCheck = useCallback(
    async e => {
      setMaintenanceMode(e.target.checked);

      await api.put('/userconfigurations', {
        maintenance: e.target.checked,
      });

      // setUserConfigurations(response.data);
      toast.success('Alteração salva');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <>
      <Container>
        <DivTitle>
          <strong>Mais opções</strong>
        </DivTitle>

        <DivBody>
          {showProPlanPopUp && (
            <PremiumPlanModal
              showModal
              Modal={ProPlanPopUp}
              is_desktop
              SetIndexMenu={SetIndexMenu}
            />
          )}

          <button type="button" onClick={() => SetIndexMenu(1)}>
            Minha conta
          </button>
          <button type="button" onClick={() => SetIndexMenu(6)}>
            Meu plano
          </button>
          <button
            type="button"
            onClick={() => {
              seePlansMoreOptionsMenuDesktopEvent();
              SetIndexMenu(7);
            }}
          >
            Planos
          </button>
          <button type="button" onClick={() => SetIndexMenu(11)}>
            Configurações
          </button>

          <div>
            {/* <div>
              <button type="button" onClick={() => SetIndexMenu(8)}>
                Vincular ao Instagram e Facebook
              </button>

              {pro === 0 && <span>PRO</span>}
            </div> */}

            <div>
              <button type="button" onClick={() => handleClickButton(14)}>
                Domínio próprio
              </button>
              {profile && !isValidUserToPlan(profile) && <LabelPremium />}
            </div>

            <div>
              <button type="button" onClick={() => handleClickButton(13)}>
                Pixels de conversão
              </button>

              {profile && !isValidUserToPlan(profile) && <LabelPremium />}
            </div>
          </div>

          <DivMaintenance>
            <strong>Colocar site em manutenção</strong>

            <label className="switch">
              <input
                type="checkbox"
                checked={maintenanceMode}
                onChange={onChangeCheck}
              />
              <span className="slider round" />
            </label>
          </DivMaintenance>
        </DivBody>
      </Container>
    </>
  );
}

export default MoreOptionsConfigurations;

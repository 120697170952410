export function loadFreightRequest() {
  return {
    type: '@freight/LOAD_FREIGHT_REQUEST',
  };
}

export function loadFreightSuccess(data) {
  return {
    type: '@freight/LOAD_FREIGHT_SUCCESS',
    payload: { data },
  };
}

export function updateFreightRequest(field, value, allDisabled, hiddenToast) {
  return {
    type: '@freight/UPDATE_FREIGHT_REQUEST',
    payload: { field, value, allDisabled, hiddenToast },
  };
}

export function updateFreightSuccess(field, value, allDisabled) {
  return {
    type: '@freight/UPDATE_FREIGHT_SUCCESS',
    payload: { field, value, allDisabled },
  };
}

export function loadRegionsRequest() {
  return {
    type: '@freight/LOAD_REGIONS_REQUEST',
  };
}

export function loadRegionsSuccess(data) {
  return {
    type: '@freight/LOAD_REGIONS_SUCCESS',
    payload: { data },
  };
}

export function addCityRequest(regionId, ufId) {
  return {
    type: '@freight/ADD_CITY_REQUEST',
    payload: { regionId, ufId },
  };
}

export function addCitySuccess(regionId, ufId, newCity) {
  return {
    type: '@freight/ADD_CITY_SUCCESS',
    payload: { regionId, ufId, newCity },
  };
}

export function addCityFailure() {
  return {
    type: '@freight/ADD_CITY_FAILURE',
  };
}

export function addNeighborhoodRequest(regionId, ufId, cityId) {
  return {
    type: '@freight/ADD_NEIGHBORHOOD_REQUEST',
    payload: { regionId, ufId, cityId },
  };
}

export function addNeighborhoodSuccess(
  regionId,
  ufId,
  cityId,
  newNeighborhood
) {
  return {
    type: '@freight/ADD_NEIGHBORHOOD_SUCCESS',
    payload: { regionId, ufId, cityId, newNeighborhood },
  };
}

export function addNeighborhoodFailure() {
  return {
    type: '@freight/ADD_NEIGHBORHOOD_FAILURE',
  };
}

export function editValueRegionRequest(value, regionId) {
  return {
    type: '@freight/EDIT_VALUE_REGION_REQUEST',
    payload: { value, regionId },
  };
}

export function editValueUfRequest(value, ufId) {
  return {
    type: '@freight/EDIT_VALUE_UF_REQUEST',
    payload: { value, ufId },
  };
}

export function editNameCityRequest(name, cityId) {
  return {
    type: '@freight/EDIT_NAME_CITY_REQUEST',
    payload: { name, cityId },
  };
}

export function editNameNeighborhoodRequest(name, neighborhoodId) {
  return {
    type: '@freight/EDIT_NAME_NEIGHBORHOOD_REQUEST',
    payload: { name, neighborhoodId },
  };
}

export function editValueCityRequest(value, cityId) {
  return {
    type: '@freight/EDIT_VALUE_CITY_REQUEST',
    payload: { value, cityId },
  };
}

export function editValueNeighborhoodRequest(value, neighborhoodId) {
  return {
    type: '@freight/EDIT_VALUE_NEIGHBORHOOD_REQUEST',
    payload: { value, neighborhoodId },
  };
}

export function removeCityRequest(regionId, ufId, cityId) {
  return {
    type: '@freight/REMOVE_CITY_REQUEST',
    payload: { regionId, ufId, cityId },
  };
}

export function removeCitySuccess(regionId, ufId, cityId) {
  return {
    type: '@freight/REMOVE_CITY_SUCCESS',
    payload: { regionId, ufId, cityId },
  };
}

export function removeNeighborhoodRequest(
  regionId,
  ufId,
  cityId,
  neighborhoodId
) {
  return {
    type: '@freight/REMOVE_NEIGHBORHOOD_REQUEST',
    payload: { regionId, ufId, cityId, neighborhoodId },
  };
}

export function removeNeighborhoodSuccess(
  regionId,
  ufId,
  cityId,
  neighborhoodId
) {
  return {
    type: '@freight/REMOVE_NEIGHBORHOOD_SUCCESS',
    payload: { regionId, ufId, cityId, neighborhoodId },
  };
}

import styled from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: #000000;
  opacity: 20%;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const DivHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row !important;
  width: 100%;
  padding: 16px 0;

  .div-space {
    width: 15%;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
  }

  span {
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
  }

  img {
    width: 10px;
  }
`;

export const Div = styled.div`
  width: 100%;
  max-width: 580px;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  align-items: center;
  background: rgba(227, 229, 236, 0.65);
  z-index: 550;
  position: fixed;
  bottom: 0;

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    background: #fff;

    button {
      width: 100%;
      height: 100%;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #0b0b0b;
      font-size: 13px;
      border: none;
      padding: 12px 0;
      text-decoration-line: underline;

      &:hover {
        color: #129deb;
      }

      img {
        margin-right: 6px;
      }
    }

    .btn-delete {
      color: #ff303c;
      text-decoration-line: none;
    }
  }
`;

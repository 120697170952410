import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 26px 16px;
  background: #fff;
  gap: 8px;
`;
export const CollapseHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  button {
    background: transparent;
    border-width: 0px;
  }
`;

export const CollapseContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  gap: 12px;
`;
export const MainInformationsHeader = styled.h3`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;
export const ProductContainer = styled.div`
  display: flex;
  flex-direction: row;

  img {
    border-radius: 8px;
    width: 62px;
    height: 62px;
    margin-right: 10px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
  }
`;
export const ProductTitle = styled.h4`
  color: #000;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
export const BodyText = styled.p`
  color: #838383;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Divider = styled.div`
  width: 100%;
  height: 0.5px;
  background: #d8dee5;
  margin: 24px 0px;
`;

export const PaymentAndShippingSummary = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SpanText = styled.span`
  color: #0d85ff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
  cursor: pointer;
`;
// export const ProductPrice = styled.div`

// `;

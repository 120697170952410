import { combineReducers } from 'redux';

import auth from './auth/reducer';
import freight from './freight/reducer';
import user from './user/reducer';
import linkPrev from './linkPrev/reducer';
import order from './order/reducer';

export default combineReducers({
  auth,
  freight,
  user,
  linkPrev,
  order,
});

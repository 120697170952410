import styled, { keyframes } from 'styled-components';

const slideUp = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background: rgba(0, 0, 0, 0.2);
  width: 100%;
  margin-top: -80px;
  z-index: 999;
  position: fixed;
  margin-left: -16px;
  height: 100vh;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px 20px 0px 0px;
  background: #fff;
  height: 251px;
  padding: 25px 20px;

  animation: ${slideUp} 0.5s ease-out forwards;
`;

export const Header = styled.h1`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 26px;
`;

export const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
  margin-right: 8px;
  border-radius: 4px;
  border: 0.5px solid #0d85ff;
  background: #0d85ff;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
`;

export const CheckboxLabel = styled.span`
  color: var(--preto, #101010);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Button = styled.button`
  border-radius: 8px;
  background: ${props => (props.type === 'canceled' ? '#efefef' : '#0D85FF')};
  width: 100%;
  padding: 16px;
  border-width: 0px;
  color: ${props => (props.type === 'canceled' ? '#101010' : '#FFF')};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
`;

export const ButtonComponent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  margin-top: 30px;
`;

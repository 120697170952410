import React from 'react';

// import { Container } from './styles';

function CouponIcon({ color }) {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.93904 3.08398L6.38189 3.21256C6.43235 3.43214 6.43866 3.65957 6.40045 3.88161C6.36223 4.10365 6.28026 4.31589 6.1593 4.50597C6.03834 4.69605 5.8808 4.8602 5.69584 4.98885C5.51087 5.11751 5.30218 5.20812 5.08189 5.25541C4.6352 5.34938 4.16946 5.26434 3.78479 5.01858C3.40012 4.77282 3.12722 4.38595 3.02475 3.94113L1.91047 4.1697C1.75766 4.20304 1.61319 4.26702 1.4858 4.35776C1.35841 4.4485 1.25074 4.56413 1.16929 4.69765C1.08784 4.83117 1.0343 4.97982 1.01191 5.13462C0.989528 5.28941 0.998755 5.44714 1.03904 5.59827C1.03904 5.59827 1.55332 7.98399 2.15332 10.6697"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6536 3.06926C17.5682 3.30981 17.4349 3.53052 17.2617 3.71802C17.0885 3.90552 16.8791 4.05592 16.6461 4.16009C16.413 4.26426 16.1613 4.32005 15.9061 4.32406C15.6509 4.32807 15.3975 4.28023 15.1613 4.18344C14.9251 4.08665 14.7111 3.94291 14.5321 3.76095C14.3531 3.57898 14.2129 3.36257 14.12 3.12483C14.0271 2.88709 13.9835 2.63297 13.9917 2.37786C13.9999 2.12275 14.0598 1.87196 14.1678 1.64069L13.0107 1.09783C12.7017 0.969066 12.3544 0.967348 12.0442 1.09305C11.7339 1.21875 11.4858 1.46173 11.3536 1.76926L6.55355 13.0693L5.92498 14.4978C5.79621 14.8068 5.7945 15.1541 5.9202 15.4644C6.0459 15.7746 6.28888 16.0228 6.59641 16.155L12.3964 18.6264C12.7054 18.7552 13.0527 18.7569 13.3629 18.6312C13.6732 18.5055 13.9213 18.2625 14.0536 17.955L19.4821 5.18355C19.6018 4.88656 19.605 4.55535 19.4912 4.25608C19.3773 3.95682 19.1547 3.71154 18.8678 3.56926L17.6536 3.06926Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CouponIcon;

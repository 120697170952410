import styled, { css } from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  padding: 40px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #f6f6f6;
    cursor: pointer;

    > svg {
      height: 14px;
    }
  }

  > h1 {
    font-weight: 600;
    font-size: 32px;
    line-height: 1.5;
    color: #1b1b1b;
    margin-left: 12px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  min-height: 600px;
  margin-top: 24px;
  border-radius: 20px;
  border: 0.5px solid #efefef;
  padding: 0 32px 48px 32px;
`;

export const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 999;
  background: none;

  img {
    background: none;
    width: 25px;
    height: 25px;
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 66px;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  margin-top: 8px;
  padding: 0 16px;

  > img {
    width: 11px;
  }

  .loader {
    width: 20px;
    height: 20px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #000000;
    }
  }

  .middle {
    padding: 0 16px;

    > span {
      font-size: 10px;
      color: #838383;
      margin-left: 4px;
    }
  }

  .right {
    height: 66px;
    position: absolute;
    right: 16px;

    .delete {
      color: #ff303c;
    }

    > button {
      border: none;
      background: none;
      font-weight: 500;
      font-size: 13px;
      color: #0d85ff;
    }

    > img {
      margin-left: 16px;
    }
  }
`;

export const ContainerAddElement = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerBackground = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 551;
  background: #000000;
  opacity: 20%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  /* display: ${props => (props.showModal ? 'flex' : 'none')}; */
  align-items: center;
  justify-content: center;
`;

export const DivAddElement = styled.div`
  width: 100%;
  max-width: 580px;
  /* height: 66vh; */
  display: flex;
  /* display: ${props => (props.showModal ? 'flex' : 'none')}; */
  flex-direction: column;
  align-items: center;
  background: #fff;
  z-index: 551;
  position: fixed;
  /* bottom: 0; */
  top: calc(50vh - (450px / 2));
  left: calc(50vw - (580px / 2));
  margin-top: 0;
  border-radius: 24px 24px;
  padding-bottom: 20px;

  .header {
    position: relative;
    width: 100%;
    min-height: 60px;
    border-bottom: 0.5px solid #d8dee5;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      margin-bottom: 0 !important;
    }

    > div {
      position: absolute;
      right: 0;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      > button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #ff303c;
      }
    }
  }

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    height: 300px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      color: #838383;
      margin-top: 4px;
    }

    .collection {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
      padding: 12px 24px;
      border-radius: 8px 0px 0px 8px;
      border-right: 1px solid #0d85ff;
    }

    .banner {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
      padding: 12px 24px;
      border-radius: 0px 8px 8px 0px;
      border-left: none !important;
    }

    .active {
      border: 1px solid #0d85ff;
      color: #0d85ff;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 4px;
  font-size: 13px;
  margin-bottom: 16px;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      touch-action: none;
    `}

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }

  .css-g1d714-ValueContainer {
    padding: 6px 8px;
  }
`;

export const DivSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    color: #000000;
  }
`;

export const DivButton = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px;

  > button {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d85ff;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    margin-top: 32px;
  }
`;

export const DivSelectElement = styled.div`
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 8px;

  .selected {
    background: #0d85ff;
    font-weight: 500;
    color: #ffffff;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px;
    height: 40px;
    padding: 0 16px;
    font-size: 13px;
    color: #101010;
    cursor: pointer;

    /* & + div {
      margin-left: 8px;
    } */
  }
`;

export const DivItem = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${props =>
    !props.first &&
    css`
      border-top: 0.5px solid #efefef;
      /* margin-top: 32px; */
    `}

  ${props =>
    props.last &&
    css`
      border-bottom: 0.5px solid #efefef;
      /* margin-top: 32px; */
    `}

  > h2 {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }
`;

export const DivContentItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & + div {
    margin-top: 24px;
  }
`;

export const DivButtons = styled.div`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + div {
      margin-left: 19px;
    }
  }
`;

export const DivTitle = styled.div`
  display: flex;
  margin-bottom: 12px;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    line-height: 1.5;
  }
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0d85ff;
  color: #0d85ff;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const DivColorButton = styled.div`
  display: flex;
  width: 82px;
  height: 82px;
  padding: 5px;
  border-radius: 50%;
  border: ${props =>
    props.active ? '2px solid #0D85FF' : '2px solid #FFFFFF'};

  > div {
    width: 100%;
    height: 100%;
    background: ${props => props.background};
    border-radius: 50%;
    border: solid 0.5px ${props => props.borderColor || props.background};
  }
`;

export const Text = styled.span`
  font-size: 13px;
  line-height: 1.5;
  color: #838383;
`;

export const DivFonts = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  /* -webkit-overflow-scrolling: touch; */
  width: 834px;

  > div {
    &:nth-child(n + 10) {
      margin-top: 8px;
    }
  }

  /* &::-webkit-scrollbar {
    margin-top: 8px !important;
    height: 5px !important;
    border-radius: 50px !important;
  } */

  /* &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  } */
`;

export const Font = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-right: 8px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #d8dee5;
    border-radius: 8px;
    width: 80px;
    height: 50px;
    font-family: ${props => props.fontFamily};
    font-weight: ${props => props.fontWeight};

    ${props =>
      props.active &&
      css`
        border: 1.5px solid #0d85ff;
        color: #0d85ff;
      `}
  }

  > span {
    font-size: 13px;
    color: ${props => (props.active ? '#0d85ff' : '#9d9d9d')};
    line-height: 1.5;
    text-align: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FontWeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      color: #0d85ff;
    `}

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === '500' &&
          '1px solid #0d85ff'};
      `}

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: ${props.selectedFontWeigth === '600' && '1px solid #0d85ff'};
    `}
`;

export const DivImages = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 24px;
    }

    > div {
      height: 82px;
    }

    > span {
      font-size: 13px;
      color: #9d9d9d;
      line-height: 1.5;
    }
  }
`;

export const BuyButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 148px; */
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;
  padding: 0 24px;

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    /* ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === 'left' &&
          '1px solid #0d85ff'};
      `} */

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: none;
      /* border-left: ${props.selectedFontWeigth === 'middle' &&
        '1px solid #0d85ff'}; */
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;
      `}
`;

export const DivStyleButtons = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 20px;
    }

    > span {
      font-size: 13px;
      line-height: 1.5;
      text-align: center;
      color: #9d9d9d;
      margin-top: 8px;
    }
  }
`;

export const InputModel = styled.div`
  width: 100px;
  height: 32px;
  border: ${props =>
    props.selected ? '2px solid #0d85ff' : '2px solid #e0e0e0'};
  border-radius: ${props => props.borderRadius || 'none'};

  & + div {
    margin-left: 20px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 0;
  padding: 0 16px;

  > button {
    width: 100%;
    height: 48px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const DivMinValue = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 150px;
    height: 45px;
    padding: 0 12px;
    font-size: 13px;

    &::placeholder {
      color: #afafaf;
    }
  }
`;

export const DivValidLocales = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }
`;

export const DivMethod = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  > span {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;

export const Radio = styled.div`
  display: flex;
  margin-bottom: 12px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #efefef;
    border-radius: 50%;
    margin-right: 10px;

    > div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #efefef;
    }
  }

  .selected {
    background: #2b8ff5;

    > div {
      background: #fff;
    }
  }
`;

export const DivCep = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > div {
    display: flex;
    margin-bottom: 8px;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    .span-label {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: #2b8ff5;
      margin-left: 6px;
    }
  }

  > input {
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 45px;
    padding: 0 12px;
    font-size: 13px;

    &::placeholder {
      color: #afafaf;
    }
  }
`;

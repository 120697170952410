/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { toast } from 'react-toastify';
// import axios from 'axios';

import DiscountInput from '~/components/DiscountInput';
import { Space } from '~/designsystem/Fonts';
import { Connection } from '~/services/config';
import CardCommissions from '~/components/ProPlan/Cards/CardCommissions';
import ShortCardPremiumPlan from '~/components/ProPlan/Cards/CardPremiumPlan/Short';
import api from '../../../../../services/api';

import ActiveMercadoPagoIcon from '../../../../../assets/icons/activeMercadoPago.svg';

// import CreditCardIcon from '../../../../../assets/icons/paymentmethods/creditcard.svg';
// import BoletIcon from '../../../../../assets/icons/paymentmethods/bolet.svg';
// import QrCodeIcon from '../../../../../assets/icons/paymentmethods/qrcode.svg';
// import LinkIcon from '../../../../../assets/icons/paymentmethods/link.svg';

import Header from '../Header';

import LoadingContainer from '../../../../../components/LoadingContainer';

import {
  Container,
  Body,
  RedLabel,
  DivTax,
  DivIntegration,
  Card,
  ModalActive,
  ModalCancelIntegration,
  DivCards,
  CardPayType,
  SpanStatus,
  // DivLinks,
  DivPixDiscount,
  DivInstallments,
  DivOtherOptions,
} from './styles';

const creditCardInstallments = [
  {
    id: 1,
    value: '1',
    label: 'Nenhum',
  },
  {
    id: 2,
    value: '2',
    label: 'Até 2 vezes',
  },
  {
    id: 3,
    value: '3',
    label: 'Até 3 vezes',
  },
  {
    id: 4,
    value: '4',
    label: 'Até 4 vezes',
  },
  {
    id: 5,
    value: '5',
    label: 'Até 5 vezes',
  },
  {
    id: 6,
    value: '6',
    label: 'Até 6 vezes',
  },
  {
    id: 7,
    value: '7',
    label: 'Até 7 vezes',
  },
  {
    id: 8,
    value: '8',
    label: 'Até 8 vezes',
  },
  {
    id: 9,
    value: '9',
    label: 'Até 9 vezes',
  },
  {
    id: 10,
    value: '10',
    label: 'Até 10 vezes',
  },
  {
    id: 11,
    value: '11',
    label: 'Até 11 vezes',
  },
  {
    id: 12,
    value: '12',
    label: 'Até 12 vezes',
  },
];

function ConfigurationPagBank({ handleClickBackButton, setIndexMenu }) {
  const [token, setToken] = useState('');
  const [modalActive, setModalActive] = useState(false);
  const [modalCancelIntegration, setModalCancelIntegration] = useState(false);

  const [checkCreditCard, setCheckCreditCard] = useState(true);
  // const [checkBolet, setCheckBolet] = useState(true);
  const [checkPix, setCheckPix] = useState(true);

  const [redirectUri, setRedirectUri] = useState('');

  const [clientId, setClientId] = useState();
  const [initialLoading, setInitialLoading] = useState(true);
  const [pixDiscount, setPixDiscount] = useState(0);
  const [maxCreditCardInstallments, setMaxCreditCardInstallments] = useState();
  const [pro, setPro] = useState();

  const { username } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function load() {
      try {
        await api.get(`/proplan`).then(response => {
          const userPro = response && response.data;
          setPro(userPro);

          if (userPro === 0) {
            window.location.href = 'https://app.offstore.me/paymentmethods';
          }
        });

        const objToken = await api.get('/pagbank/marketplacecredentials');
        const { client_ID, redirect_uri_app } = objToken.data;

        setClientId(client_ID);
        setRedirectUri(redirect_uri_app);

        const pb = await api.get('/pagbank');
        setToken(pb && pb.data && pb.data.access_token);

        const maxInstallments = creditCardInstallments.find(
          c => c.id === pb.data.max_credit_card_installments
        );

        setMaxCreditCardInstallments(maxInstallments);

        setCheckCreditCard(pb.data && pb.data.credit_card);
        // setCheckBolet(pb.data && pb.data.bolet);
        setCheckPix(pb.data && pb.data.pix);
        setPixDiscount(pb.data && pb.data.pix_discount);

        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('codepb');

        if (code && (!pb.data || (pb.data && !pb.data.access_token))) {
          try {
            const newPb = await api.post('/pagbank/auth', {
              code,
            });

            setToken(newPb && newPb.data && newPb.data.access_token);

            await api.put('/verifypaymentmethods', {
              paymentMethod: 'pagbank',
              value: true,
            });

            setModalActive(true);
          } catch (err) {
            setInitialLoading(false);
            toast.error(
              'Não foi possível ativar a integração. Entre em contato com a Offstore'
            );
          }
        }

        setInitialLoading(false);
      } catch (err) {
        setInitialLoading(false);
      }
    }

    load();
  }, []);

  async function onChangeActiveCreditCard(value) {
    if (value === false && !checkPix) {
      toast.error('Você precisa de pelo menos 1 forma de pagamento');

      return 0;
    }

    setCheckCreditCard(value);

    await api.put('/pagbank', {
      credit_card: value,
    });

    return 0;
  }

  async function onChangeActivePix(value) {
    if (value === false && !checkCreditCard) {
      toast.error('Você precisa de pelo menos 1 forma de pagamento');

      return 0;
    }

    setCheckPix(value);

    await api.put('/pagbank', {
      pix: value,
    });

    return 0;
  }

  async function handleBlurDiscountPix(e) {
    try {
      await api.put('/pagbank', {
        pix_discount: e.target.value,
      });

      toast.success('Alteração salva');
    } catch (err) {
      console.log(err);
    }

    return 0;
  }

  async function handleChangeMaxInstallments(e) {
    try {
      setMaxCreditCardInstallments(e);

      await api.put('/pagbank', {
        max_credit_card_installments: e.id,
      });
    } catch (err) {
      console.log(err);
    }

    return 0;
  }

  async function handleRemoveToken() {
    await api.put('/pagbank', {
      active: false,
      access_token: null,
      expiration_token: null,
      user_id_mercado_pago: null,
      refresh_token: null,
      public_key: null,
      key_updated_at: null,
    });

    setToken('');
    setModalCancelIntegration(false);
  }

  function handleClickBack() {
    handleClickBackButton();
  }

  return (
    <Container>
      <LoadingContainer loading={initialLoading} />
      <Header title="PagBank" handleClickBack={handleClickBack} />

      <Body>
        {!token && (
          <RedLabel>
            <span>
              Para fazer a integração você precisa ter uma conta no PagBank,
              caso não tenha,{' '}
              <a
                href="https://pagseguro.uol.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                toque aqui
              </a>{' '}
              para criar.
            </span>
          </RedLabel>
        )}

        <DivIntegration first>
          {(token && (
            <>
              <strong>Selecionar formas de pagamento</strong>

              <span>Selecione as formas de pagamento que você irá aceitar</span>
            </>
          )) || (
            <>
              <strong>Integre sua conta do PagBank</strong>

              <span>
                Toque no botão abaixo para ativar a integração com sua conta do
                PagBank, leva poucos segundos
              </span>

              <a
                href={`${Connection.URL_PAGBANK_CONNECT}/oauth2/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=payments.read+payments.create+payments.refund+accounts.read+checkout.create+checkout.view+checkout.update&state=${username}`}
              >
                Ativar integração
              </a>
            </>
          )}
        </DivIntegration>

        {token && (
          <>
            <DivCards>
              <CardPayType enabled={checkCreditCard}>
                <div className="left">
                  <strong>
                    Cartão de crédito{' '}
                    {checkCreditCard && (
                      <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                    )}
                  </strong>

                  {/* {(checkCreditCard && (
                    <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                  )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>} */}

                  {/* <span>3,89%(Mercado Pago) + 1,75%(Offstore) = 5,64%</span> */}
                  <span>3,89%</span>
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checkCreditCard}
                      onChange={e => onChangeActiveCreditCard(e.target.checked)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </CardPayType>

              <CardPayType enabled={checkPix}>
                <div className="left">
                  <strong>
                    Pix{' '}
                    {checkPix && <SpanStatus color="#2B8FF5">Ativo</SpanStatus>}
                  </strong>

                  {/* {(checkPix && (
                    <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                  )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>} */}

                  {/* <span>R$ 3,49(Mercado Pago) + R$ 0,50(Offstore)</span> */}
                  <span>0,99%</span>
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checkPix}
                      onChange={e => onChangeActivePix(e.target.checked)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </CardPayType>

              {/* <CardPayType enabled={checkBolet}>
                <div className="left">
                  <strong>
                    Boleto{' '}
                    {checkBolet && (
                      <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                    )}
                  </strong>

                  <span>R$ 3,49 por boleto pago</span>
                </div>

                <div className="right">
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={checkBolet}
                      onChange={e => onChangeActiveBolet(e.target.checked)}
                    />
                    <span className="slider round" />
                  </label>
                </div>
              </CardPayType> */}

              <span>
                Comissão por venda da Offstore:{' '}
                <span className="blue">1,75%</span>
              </span>

              <Space height="12px" />

              <span>
                As taxas do PagBank apresentadas aqui são referentes ao
                recebimento em 30 dias. As taxas do PagBank podem divergir das
                informações apresentadas aqui, caso eles alterem suas taxas.
              </span>
            </DivCards>

            <DivPixDiscount>
              <strong>Desconto para pagamento via PIX</strong>
              <span>Defina um desconto para pagamentos via PIX</span>

              <DiscountInput
                defaDiscountInputultValue={pixDiscount}
                onChange={e => setPixDiscount(Number(e.target.value))}
                onBlur={handleBlurDiscountPix}
                placeholder="0"
                maxWidth="143px"
                marginTop="8px"
                value={pixDiscount}
              />
            </DivPixDiscount>

            <DivInstallments>
              <strong>Parcelamento sem juros no cartão</strong>
              <span>
                Defina em quantas vezes você quer parcelar sem juros para seu
                cliente.
              </span>

              <Select
                id="selected"
                className="selected"
                placeholder="Selecionar"
                name="tg"
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderWidth: '1px',
                    borderColor: state.isFocused ? '#000000' : '#cdcdcd',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    height: '40px',
                    padding: '0 8px 0 16px',
                  }),
                  indicatorSeparator: (provided, state) => ({
                    ...provided,
                    background: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    padding: '0',
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    padding: '0',
                  }),
                }}
                options={creditCardInstallments}
                value={maxCreditCardInstallments}
                onChange={e => handleChangeMaxInstallments(e)}
              />

              <span>
                Lembre-se que você também precisa configurar o parcelamento sem
                juros no painel do PagBank{' '}
              </span>

              <a
                href="https://faq.pagbank.com.br/duvida/como-configurar-o-recurso-parcelamento-comprador/85#rmcl"
                target="_blank"
                rel="noreferrer"
              >
                Configurar parcelamento no PagBank
                <svg
                  width="15"
                  height="12"
                  viewBox="0 0 15 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 6L13.8571 6"
                    stroke="#0D85FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.85718 1L13.8572 6L8.85718 11"
                    stroke="#0D85FF"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </DivInstallments>
          </>
        )}

        {!token && (
          <DivTax>
            <strong>Taxas por venda</strong>
            <span>Confira as taxas abaixo</span>

            <Space height="12px" />

            <Card>
              <div>
                {/* <div>
              <img src={CreditCardIcon} alt="credit card" />
            </div> */}

                <span>Cartão de crédito</span>
              </div>
              <span>3,89%</span>
            </Card>

            <Card>
              <div>
                {/* <div>
              <img src={QrCodeIcon} alt="qrcode" />
            </div> */}

                <span>PIX</span>
              </div>
              <span>0,99%</span>
            </Card>

            {/* <Space height="4px" />

            <span>
              Comissão por venda da Offstore:{' '}
              <span className="blue">1,75%</span>
            </span> */}

            <Space height="12px" />

            {/* <Card disabled>
              <div>
                <span>Boleto bancário</span>
              </div>
              <span>R$ 3,49(Pagbank) + R$ 0,50(Offstore)</span>
            </Card> */}

            <span>
              As taxas do PagBank apresentadas aqui são referentes ao
              recebimento em 30 dias. As taxas do PagBank podem divergir das
              informações apresentadas aqui, caso eles alterem suas taxas.
            </span>

            <CardCommissions
              paymentMethod="Pagbank"
              handleClickButtonSeePlan={() => setIndexMenu(7)}
              isDesktop
            />

            {pro === 1 && !initialLoading && (
              <ShortCardPremiumPlan
                buttonHandleClick={() => setIndexMenu(7)}
                isDesktop
              />
            )}
          </DivTax>
        )}

        {token && (
          <DivOtherOptions>
            <strong>Outras opções</strong>

            <div>
              <a
                href="https://minhaconta.pagbank.com.br/meu-negocio/taxas-e-tarifas?tab=venda_online&capture=novo_checkout_transparente"
                target="_blank"
                rel="noopener noreferrer"
              >
                Configurar recebimento
              </a>
              <a
                href="https://minhaconta.pagbank.com.br/meu-negocio/vendas-e-recebimentos?tab=sales&salesTab=report"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ver saldo
              </a>
              <a
                href="https://minhaconta.pagbank.com.br"
                target="_blank"
                rel="noopener noreferrer"
              >
                Acessar PagBank
              </a>
              <a
                href="https://minhaconta.pagbank.com.br/conta-digital/pix/registro"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cadastrar chave PIX
              </a>
            </div>
          </DivOtherOptions>
        )}

        {/* {token && (
          <DivLinks>
            <strong>Links úteis</strong>

            <a
              href="https://conteudo.mercadopago.com.br/passo-a-passo-cadastre-agora-sua-chave-pix-no-mercado-pago"
              target="_blank"
              rel="noopener noreferrer"
            >
              Cadastrar chave PIX no Mercado Pago{' '}
              <img src={LinkIcon} alt="link" />
            </a>
            <a
              href="https://www.mercadopago.com.br/costs-section"
              target="_blank"
              rel="noopener noreferrer"
            >
              Configure suas taxas <img src={LinkIcon} alt="link" />
            </a>
            <a
              href="https://www.mercadopago.com.br/banking/balance"
              target="_blank"
              rel="noopener noreferrer"
            >
              Consulte seu saldo <img src={LinkIcon} alt="link" />
            </a>
            <a
              href="https://www.mercadopago.com.br/costs-section/psj"
              target="_blank"
              rel="noopener noreferrer"
            >
              Definir parcelamento sem juros <img src={LinkIcon} alt="link" />
            </a>
          </DivLinks>
        )} */}

        <DivIntegration>
          {token && (
            <>
              <strong>Remover integração</strong>

              <span>
                Toque no botão abaixo para remover a integração com sua conta no
                PagBank
              </span>

              <button
                type="button"
                className="remove-token"
                onClick={() => setModalCancelIntegration(true)}
              >
                Remover integração
              </button>
            </>
          )}
        </DivIntegration>
      </Body>

      {modalActive && (
        <ModalActive>
          <div className="container" />
          <div className="div">
            <img src={ActiveMercadoPagoIcon} alt="active" />

            <strong>Parabéns, você já pode receber pagamentos no site!</strong>

            <span>
              Você ativou a integração com o PagBank, e agora seus clientes já
              podem pagar pelas compras diretamente no site.
            </span>

            <button type="button" onClick={handleClickBack}>
              Entendi
            </button>
          </div>
        </ModalActive>
      )}

      {modalCancelIntegration && (
        <ModalCancelIntegration>
          <div
            className="container"
            onClick={() => setModalCancelIntegration(false)}
          />
          <div className="div">
            <strong>Remover integração</strong>
            <span>Tem certeza que deseja remover a integração?</span>

            <button
              type="button"
              className="btn-cancel"
              onClick={handleRemoveToken}
            >
              Remover integração
            </button>
            <button
              type="button"
              onClick={() => setModalCancelIntegration(false)}
            >
              Cancelar
            </button>
          </div>
        </ModalCancelIntegration>
      )}
    </Container>
  );
}

export default ConfigurationPagBank;

import React from 'react';

// import { Svg } from './styles';

function CheckIcon({ color }) {
  return (
    <svg
      width="11"
      height="8"
      viewBox="0 0 11 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 1L3.8125 7L1 4.27273"
        stroke={color || '#fff'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckIcon;

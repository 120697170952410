import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select';
import { device } from '../../../config/device';

export const Container = styled.div`
  width: 100%;
  display: flex;
  background: #fff;
  flex-direction: column;
  margin-top: 54px;
  padding-bottom: 80px;
  min-height: 100vh;
  top: 0;

  ${props =>
    props.hasActiveModal &&
    css`
      position: fixed;
    `}

  .label {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }
`;

export const DivTabs = styled.div`
  display: flex;
  position: fixed;
  padding: 16px;
  left: 0;
  right: 0;
  background: #ffffff;
  z-index: 1;
  border-bottom: 0.5px solid #d8dee5;

  > div {
    display: flex;
    width: 100%;
    height: 48px;
    background: #efefef;
    border-radius: 8px;
    padding: 4px;

    > div {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #838383;
      font-weight: 500;
    }
  }

  .selected {
    background: #ffffff;
    border-radius: 8px;
    color: #101010;
  }
`;

export const DivTitulo = styled.div`
  width: 600px;
  margin-bottom: 10px;

  > span {
    font-weight: normal;
    font-size: 15px;
    color: #000;
    font-weight: 500;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;

export const DivTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 75px; */
  /* border-top: ${props => (props.first ? 'none' : '0.5px solid #D8DEE5')}; */

  ${props =>
    props.disabled &&
    css`
      opacity: 50%;
    `}

  .blue {
    color: #0d85ff;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const DivSubTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 75px; */
  padding: 0 16px 16px 16px;
  margin: ${props => props.margin};
  /* border-top: ${props => (props.first ? 'none' : '0.5px solid #D8DEE5;')}; */

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

export const ContainerTab = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  margin-top: 96px;
`;

export const DivHeadBar = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    margin-top: 28px;
  }
`;

export const DivHeader = styled.div`
  margin-top: 28px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    line-height: 1.5;
  }
`;

export const DivHead = styled.div`
  box-shadow: 0px 3.69082px 20.2995px rgba(0, 0, 0, 0.07);
  padding: 16px;
  margin-top: 16px;
  position: relative;
  background: ${props => props.backgroundColor};

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .body {
    margin-top: 16px;
    display: flex;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      background: #f4f4f4;
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    > input {
      width: 100%;
      height: 40px;
      background: #f4f4f4;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border: none;
      font-weight: 400;
      font-family: ${props => props.inputFontFamily};
      font-size: 13px;
      color: #bfbfbf;

      &::placeholder {
        color: ${props => props.inputColor || '#bfbfbf'};
      }
    }

    .white {
      background: #ffffff;
    }
  }
`;

// export const DivFonts = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   background: #fafafa;
//   padding: 16px 12px;
//   margin-top: 16px;
// `;

export const H1 = styled.h1`
  font-family: ${props => props.fontFamily};
  font-weight: ${props => props.fontWeight};
  color: ${props => props.color};
  font-size: 30px;
  line-height: 1.5;
`;

export const Span = styled.span`
  font-family: ${props => props.fontFamily};
  color: ${props => props.color};
  font-weight: 400;
  font-size: 13px;
  line-height: 1.5;
  margin-top: 16px;
`;

export const DivFooter = styled.div``;

export const DivSocialIcons = styled.div``;

export const DivStyleButton = styled.div`
  position: relative;
  margin-top: 8px;

  .button {
    font-family: ${props => props.fontFamily};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 50px;
    background: ${props => props.backgroundColor || '#000000'};
    border: none;
    font-weight: 500;
    font-size: 13px;
    color: ${props => props.textColor || '#ffffff'};

    ${props =>
      props.borderRadiusStyle === 'rounded' &&
      css`
        border-radius: 8px;
      `}

    ${props =>
      props.borderRadiusStyle === 'circle' &&
      css`
        border-radius: 50px;
      `}
  }
`;

export const DivHeadBarText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.fontFamily};
  background: ${props => props.background};
  height: 42px;
  font-weight: 400;
  font-size: 13px;
  color: ${props => props.color || '#ffffff'};
  margin-top: 12px;
`;

export const EditButton = styled.button`
  position: absolute;
  top: ${props => props.top || 'initial'};
  left: ${props => props.left || 'initial'};
  bottom: ${props => props.bottom || 'initial'};
  right: ${props => props.right || 'initial'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 32px;
  background: #0d85ff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  border: none;
  color: #ffffff;
  font-weight: 500;
  font-size: 13px;

  > img {
    margin-right: 6px;
  }
`;

export const DivProfile = styled.div`
  display: flex;
  width: 100%;
  background: #fff;
  flex-direction: column;
  /* margin: 0 0 24px 0; */
  padding: 0 16px;
  margin-top: 32px;

  > a {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    margin-top: 12px;

    > span {
      color: #2b8ff5;
    }
  }

  .input-user {
    text-transform: lowercase;
  }

  .div-categorias {
    display: flex;
    flex-direction: column;
    height: 80px;
  }

  .div-link {
    display: flex;
    align-items: center;

    > span {
      font-weight: normal;
      font-size: 13px;
      color: #2b8ff5;
      margin-bottom: 0;
      margin-right: 4px;
    }
  }

  /* span {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #000000;
  } */
`;

export const DivDescription = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 12px;
`;

export const DivUsername = styled.div`
  display: flex;
  margin-top: 8px;

  > div {
    height: 42px;
    display: flex;
    flex-direction: center;
    align-items: center;
    background: #efefef;
    border-radius: 0px 8px 8px 0px;
    padding: 0 12px;

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: #101010;
      margin: 0;
    }
  }

  > input {
    height: 42px;
    width: 100%;
    background: #f9f9f9;
    padding: 0 8px;
    font-size: 13px;
    /* margin-bottom: 16px; */
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    color: #101010;

    &:focus {
      /* border-bottom: 0.5px solid #010101; */
    }

    &::placeholder {
      color: #d1d1d1;
    }
  }
`;

export const DescriptionSize = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #838383 !important;
`;

// export const DivAvatarFormat = styled.div`
//   margin: 0 16px;

//   .span-description {
//     display: ${props => (props.enable ? 'flex' : 'none')};
//     font-size: 13px;
//     color: #838383;
//   }

//   .div-avatar-format {
//     border-radius: 12px;

//     > strong {
//       font-weight: 500;
//       font-size: 14px;
//       color: #000000;
//     }

//     > span {
//       font-size: 13px;
//       color: #838383;
//     }
//   }

//   .div-images {
//     display: flex;
//     justify-content: space-between;
//   }
// `;

export const DivAvatarFormat = styled.div`
  /* margin: 4px 0 0 0; */

  .span-description {
    display: ${props => (props.enable ? 'flex' : 'none')};
    font-size: 13px;
    color: #838383;
  }
  .div-avatar-format {
    border-radius: 12px;
    > strong {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }
    > span {
      font-size: 13px;
      color: #838383;
    }
  }
  .div-images {
    display: flex;
    justify-content: space-between;
  }
`;

export const DivImages = styled.div`
  display: flex;
  /* margin-top: 8px; */

  .selected {
    border: 1px solid #101010;
  }

  > img {
    width: 40px;
    height: 40px;
    align-self: center;
  }
`;

export const DivImageFormats = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 24px;
    }

    > div {
      height: 82px;
    }

    > span {
      font-size: 13px;
      color: #9d9d9d;
      line-height: 1.5;
    }
  }
`;

export const DivFImages = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;

  .selected {
    border: 1px solid #101010;
  }
`;

export const DivBanners = styled.div`
  opacity: ${props => (props.enable ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  border: 0.5px solid #d8dee5;
  border-radius: 12px;
  padding: 16px;
  margin-top: 8px;

  .span-format {
    font-size: 13px;
    color: #000000;
    margin-bottom: 6px;
  }

  .span-images {
    font-size: 13px;
    color: #000000;
    margin-bottom: 2px;
  }

  .span-description {
    font-size: 13px;
    color: #838383;
    margin-bottom: 6px;
  }
`;

export const Quadrado = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 50%;
  width: ${props => props.width || '48px'};
  height: ${props => props.height || '48px'};
  color: transparent;
  margin-right: 12px;
`;

export const QuadradoBanner = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${props => props.width || '48px'};
  height: ${props => props.height || '48px'};
  color: transparent;
  margin-right: 12px;
`;

export const Retangulo = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${props => props.width || '120px'};
  height: ${props => props.height || '48px'};
  color: transparent;
`;

export const RetanguloBanner = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${props => props.width || '90px'};
  height: ${props => props.height || '48px'};
  color: transparent;
`;

export const DivImagesBanners = styled.div`
  display: flex;
  margin-bottom: 16px;

  .selected {
    border: 1px solid #101010;
  }
`;

export const DivDevice = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0 16px; */
  margin-bottom: 12px;
  margin-top: 8px;

  > div {
    display: flex;
    margin-bottom: 12px;

    > div {
      display: flex;
      align-items: center;
      margin-right: 16px;

      > span {
        margin-left: 6px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #9d9d9d;
      }

      .selected {
        color: #2b8ff5;
      }
    }
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }
`;

export const DivBannerImages = styled.div`
  display: flex;
  /* padding-left: 16px; */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #9d9d9d;
    box-sizing: border-box;
    box-shadow: none;
    min-width: ${props => (props.desktop ? '190px' : '150px')};
    height: 80px;
    margin-right: 10px;
    background: none;
    border-radius: 10px;
  }
`;

export const LabelImage = styled.label`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;

export const DivPreview = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #2b8ff5;
  /* border-top: 0.5px solid #d8dee5; */

  .div-info {
    /* margin-left: 16px; */
    margin-right: 0;
    flex: 1;
    flex-direction: column;

    strong {
      font-weight: 500;
      font-size: 13px;
      color: #fff;
      margin-right: 4px;
      margin-left: 16px;
    }

    span {
      font-size: 13px;
      margin-left: 16px;
      color: #fff;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      width: 70%;
    }
  }

  .buttons {
    justify-content: flex-end;
  }

  div {
    display: flex;
    width: 50%;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 127px;
    height: 44px;
    margin-left: 8px;
    background: #ffffff;
    border: 0.5px solid #ffffff;
    border-radius: 8px;
    margin-right: 16px;

    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
    text-decoration: none;

    > img {
      margin-right: 6px;
    }
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    background: transparent;
    border: 1px solid #d8dee5;
    border-radius: 10px;
  }
`;

export const DivAvatarLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > span {
    margin-bottom: 8px !important;
    font-size: 13px;
    color: #838383;
  }
  > img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
`;

export const ImgBannerFormat = styled.img`
  opacity: ${props => (props.disabled ? '0.4' : '1')};
`;

// export const DivAvatar = styled.div`
//   display: flex;
//   /* flex-direction: column; */
//   align-items: center;
//   justify-content: flex-start;
//   position: relative;
//   /* width: ${props => (props.horizontal ? '175px' : '80px')}; */

//   > button {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 50px;
//     height: 50px;
//     margin-right: 8px;
//     border: 1px dashed #9D9D9D;
//     box-sizing: border-box;
//     border-radius: 8px;
//     background: none;
//   }

// `;

export const DivAvatar = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 40px;
    padding: 0 16px;
    /* margin-right: 8px; */
    /* border: 1px dashed #9d9d9d; */
    box-sizing: border-box;
    border-radius: 8px;
    background: #efefef;
    border: none;
    font-size: 13px;
    font-weight: 500;

    > svg {
      margin-right: 8px;
    }
  }
`;

// export const DivLogo = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: rgba(255, 255, 255, 0.25);
//   /* border: 0.5px solid rgba(133, 133, 133, 0.25); */
//   position: relative;

//   height: 52px;
//   width: 52px;

//   border-radius: 200px;
//   /* z-index: 200; */

//   ${props =>
//     !props.hasImage &&
//     css`
//       box-sizing: border-box;
//     `};

//   img {
//     width: 100%;
//     height: 100%;
//     border-radius: 8px;
//     object-fit: cover;
//   }

//   ${props =>
//     props.horizontal &&
//     css`
//       height: 50px;
//       width: 155px;
//       border-radius: 8px;

//       img {
//         border-radius: 8px;
//         object-fit: contain;
//         background: #fafafa;
//       }
//     `};

//   > button {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: absolute;
//     top: -7px;
//     right: -9px;
//     width: 24px;
//     height: 24px;
//     border-radius: 50%;
//     border: none;
//     box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.13);
//     background: #fff;

//     > img {
//       padding: 6px;
//     }
//   }
// `;

export const DivLoading = styled.div``;

export const DivLogo = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background: rgba(255, 255, 255, 0.25);
  /* border: 0.5px solid rgba(133, 133, 133, 0.25); */
  position: relative;
  /* height: 52px;
  width: 52px; */
  border-radius: 200px;
  margin-top: 8px;

  .selected-image {
    height: 52px;
    width: 52px;
  }

  /* z-index: 200; */
  ${props =>
    !props.hasImage &&
    css`
      box-sizing: border-box;
    `};

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  ${props =>
    props.horizontal &&
    css`
      border-radius: 8px;

      .selected-image {
        height: 47px;
        width: 113px;
      }

      img {
        border-radius: 8px;
        object-fit: contain;
        background: #fafafa;
      }
    `};

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* top: -7px;
    right: -9px; */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    /* box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.13); */
    background: none;
    margin-left: 8px;

    > img {
      padding: 6px;
    }
  }
`;

export const DivAvatarRight = styled.div`
  opacity: ${props => (props.enable ? '100%' : '50%')};

  > span {
    font-size: 13px;
    color: #000000;
  }

  /* .span-pro {
    font-size: 10px;
    color: #2b8ff5;
  } */
`;

export const DivSpanProPlan = styled.div`
  display: flex;
  align-items: center;
  height: auto;

  > img {
    margin-right: 7px;
  }

  > span {
    font-size: 10px;
    line-height: 15px;
    color: #2b8ff5;
  }

  > strong {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-right: 16px;
  }
`;

export const DivTheme = styled.div`
  display: flex;
  /* border-radius: 10px; */
  width: 100%;
  background: none;
  flex-direction: column;
  padding: 0 16px;

  > div {
    width: 100%;
    font-size: 13px;
    margin-bottom: 26px;

    > span {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }
  }
`;

export const DivHeaderType = styled.div`
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #d8dee5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  > span {
    color: #fff;
  }

  input {
    width: 90px;
    height: 30px;
    background: #f7f8f9;
    border-radius: 10px;
    border: none;
    padding: 0 15px;
    font-size: 13px;
    color: #a5a9b1;
  }

  > div {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
  }
`;

export const Icon = styled.img`
  opacity: ${props => (props.disable ? '50%' : '100%')};
  width: 40px;
  height: 40px;
  /* padding: 10px; */
  /* margin: 0 10px; */
`;

export const DivColor = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${props => props.color || '#000'};
  margin-right: 8px;
`;

export const DivColors = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* border: 1px solid #d8dee5;
  border-radius: 12px; */
  /* padding: ${props => props.margin || '12px 0'}; */
  /* margin: 0 16px; */

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 50%;
    `}

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 5px;
      margin-bottom: 6px;
      font-size: 13px;
      color: #000000;
    }

    input {
      display: flex;
      width: 85px;
      height: 32px;
      background: #fafafa;
      border-radius: 8px;
      border: none;
      font-size: 13px;
      color: #838383;
      text-align: center;
    }
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 7px;
  font-size: 13px;

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }

  .css-b8ldur-Input {
    height: 42px;
    margin-top: -25px;
  }
`;

export const DivEditar = styled.div``;

export const DivShare = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  border-radius: 10px;
  width: 100%;
  background: #fff;
  flex-direction: column;
  margin-top: ${props => (props.first ? '0' : '32px')};
  padding: 0 16px;
  margin-bottom: ${props => (props.last ? '24px' : '0')};
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  padding: 0 20px;
  margin-top: 32px;
  /* padding-bottom: ${props => (props.last ? '40px' : '20px')}; */

  .div-endereco {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;

      input {
        & + input {
          margin-left: 24px;
        }
      }
    }
  }

  .div-buttons-payment-methods {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    button {
      border: 0.5px solid #d8dee5;
      background: #fff;
      padding: 8px 18px;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .selected {
      background: #0d0a0a;
      border: 0.5px solid #0d0a0a;
      color: #fff;
    }
  }

  > div {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    strong {
      font-weight: 500;
      font-size: 15px;
      color: #000;
    }

    span {
      margin-top: 13px;
      font-weight: normal;
      font-size: 13px;
      color: #838383;
    }

    input {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }
  }
`;

export const DivCheckBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  padding: 0 20px;

  > span {
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }
`;

export const ButtonAdd = styled.button`
  display: flex;
  font-weight: 500;
  font-size: 13px;
  color: #2b8ff5;
  background: none;
  border: none;
  margin-top: 12px;
`;

export const DivInput = styled.div`
  display: flex;
  width: 100%;

  > div {
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    background: #efefef;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #262626;
    }
  }

  > input {
    display: flex;
    flex: 1;
    width: 100%;
    height: 42px;
    background: #f9f9f9;
    border: none;
    padding: 0 12px;
    font-size: 13px;
    box-sizing: border-box;
    border-radius: ${props => (props.hasLabelNumber ? '0 8px 8px 0' : '8px')};
    /* border-top-right-radius: 8px; */
    /* border-bottom-right-radius: 8px; */
    border-left: none;

    &::placeholder {
      font-size: 13px;
      color: #c6cad2;
    }
  }

  textarea {
    width: 100%;
    background: #f9f9f9;
    border: none;
    padding: 12px;
    border-radius: 8px;

    /* &:focus {
      border-bottom: 0.5px solid #000;
    } */
  }
`;

export const Button = styled.button`
  width: 100%;
  height: 50px;
  border-style: none;
  border-radius: 10px;
  color: ${props => props.color || '#000000'};
  background: ${props => props.background || '#eae8ed'};
  font-weight: 500;
  font-size: 14px;

  > img {
    right: 0;
  }
`;

export const Div = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin};
  /* padding: ${props => (props.first ? '0 0 24px 0' : '24px 0')}; */
  /* border-top: ${props => (props.first ? 'none' : '0.5px solid #d8dee5')}; */

  ${props =>
    props.disabled &&
    css`
      opacity: 50%;
      pointer-events: none;
    `}


  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 1.5;
    color: #838383;
    /* margin: 0 16px; */
    /* margin-bottom: 12px; */
  }

  .span-black {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000000;
    margin: 16px 16px 8px 16px;
  }

  #banner-image {
    opacity: 0.4;
  }
`;

export const DivBorder = styled.div`
  border-radius: 12px;
  border: 0.5px solid #d8dee5;
  padding: 24px 16px;
  margin-top: 8px;

  ${props =>
    props.first &&
    css`
      margin-top: 24px;
    `}
`;

export const DivItem = styled.div`
  display: flex;
  width: 100%;
  /* padding: 24px 0; */
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.first &&
    css`
      padding-top: 24px;
    `}

  /* ${props =>
    !props.first &&
    css`
      border-top: 0.5px solid #d8dee5;
    `} */

  /* ${props =>
    props.last &&
    css`
      border-bottom: 0.5px solid #d8dee5;
    `} */


  > div {
    display: flex;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }
`;

export const BuyButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 148px; */
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;
  padding: 0 24px;

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    /* ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === 'left' &&
          '1px solid #0d85ff'};
      `} */

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: none;
      /* border-left: ${props.selectedFontWeigth === 'middle' &&
        '1px solid #0d85ff'}; */
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;
      `}
`;

export const DivStyleButtons = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 20px;
    }

    > span {
      font-size: 13px;
      line-height: 1.5;
      text-align: center;
      color: #9d9d9d;
      margin-top: 8px;
    }
  }
`;

export const InputModel = styled.div`
  width: 100px;
  height: 32px;
  border: ${props =>
    props.selected ? '2px solid #0d85ff' : '2px solid #e0e0e0'};
  border-radius: ${props => props.borderRadius || 'none'};

  & + div {
    margin-left: 20px;
  }
`;

export const DivContentItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || '0 0 32px 0'};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 50%;
    `}

  > div {
    /* & + div { */
    margin-top: 24px;
    /* } */
  }
`;

export const DivLoader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  > img {
    width: 50px;
  }
`;

export const DivModel = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0 16px; */
  /* margin-bottom: 32px; */
  /* opacity: ${props => (!props.isPro ? 0.5 : 1)}; */

  .error {
    color: #ff303c;
    margin-top: 8px;
    font-size: 13px;
  }

  /* .catalog {
    color: #9d9d9d;
    border: 1px solid #9d9d9d;
    padding: 12px 24px;
  }

  .store {
    color: #9d9d9d;
    border: 1px solid #9d9d9d;
    padding: 12px 24px;
  } */
`;

export const DivStoreType = styled.div`
  display: flex;
  margin-top: 4px;

  > div {
    & + div {
      margin-left: 8px;
    }
  }
`;

export const ButtonStoreType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #838383;
  border: 1px solid #d8dee5;
  border-radius: 8px;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07); */
  padding: 0 24px;

  & + div {
    margin-left: 8px;
  }

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      background: #0d85ff;
      color: #ffffff;
      font-weight: 500;
    `}

  /* ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

  ${props =>
    props.right &&
    css`
      border-radius: 0 8px 8px 0;
      border-left: none;
    `} */


`;

export const DivEditPage = styled.div`
  display: flex;
  margin-top: 4px;
`;

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  width: 100%;
  height: 80px;
  padding: 0 16px;

  > span {
    font-size: 13px;
    line-height: 1.5;
    color: #838383;
    text-align: center;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d85ff;
    border-radius: 8px;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border: none;
    width: 160px;

    > svg {
      margin-right: 6px;
    }
  }
`;

export const PageHeader = styled.div`
  background: #efefef;
  height: 37px;
  padding: 8px 12px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 21px;
    height: 21px;
    position: relative;

    > div {
      background: #efefef;
      border-radius: 2px;
      height: 9px;
      width: 70px;
    }

    > svg {
      position: absolute;
      right: 6px;
    }
  }
`;

export const PageMiddle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 80px;
`;

export const DivThemes = styled.div`
  margin-top: 8px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const Theme = styled.div`
  /* height: 210px; */
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  padding: 20px 16px;
  border: 0.5px solid #d8dee5;
  border-radius: 12px;

  ${props =>
    props.disabled &&
    css`
      background: #fafafa;
      border: 0.5px solid #fafafa;
    `}

  ${props =>
    props.active &&
    css`
      border: 2px solid #0d85ff;
    `}

  & + div {
    margin-left: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    width: 100%;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 1.5;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #d8dee5;
      width: 19px;
      height: 19px;
      border-radius: 50%;

      ${props =>
        props.active &&
        css`
          border: 0.5px solid #0d85ff;
          background: #0d85ff;
        `}
    }
  }

  > span {
    margin-top: 4px;
  }

  /* > span {
    font-weight: 400;
    font-size: 13px;
    color: #838383;
    margin-top: 4px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  } */

  > a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    color: #838383;
    text-decoration: none;
    margin-top: 22px;

    > svg {
      margin-left: 6px;
    }
  }

  > button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: ${props =>
      props.active ? 'rgba(13, 133, 255, 0.06)' : '#0d85ff'};

    border-radius: 8px;
    height: 48px;
    font-weight: 500;
    font-size: 13px;
    color: ${props => (props.active ? '#0D85FF' : '#ffffff')};
    border: none;
    margin-top: 16px;

    > img {
      margin-right: 6px;
    }

    &:disabled {
      background: #cdcdcd;
      color: #ffffff;
    }
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  /* padding: 0 16px; */
  margin: ${props => props.margin || '0 0 8px 0'};

  > div {
    display: flex;
    line-height: 1.25;

    > h5 {
      font-weight: 500;
    }
  }

  > span {
    margin-top: 4px;
  }

  > h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #000000;
    line-height: 1.5;
  }

  .pro {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgb(43, 143, 245);
    border-radius: 4px;
    height: 24px;
    padding: 0 6px;
    color: rgb(43, 143, 245);
    font-size: 13px;
    margin-left: 6px;
  }
`;

export const DivFonts = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  > div {
    & + div {
      margin-left: 8px;
    }
  }

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const DivFontWeight = styled.div`
  display: flex;
`;

export const Font = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;

  /* & + div {
    margin-left: 8px;
  } */

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d8dee5;
    border-radius: 8px;
    width: 80px;
    height: 50px;
    font-family: ${props => props.fontFamily};
    font-weight: ${props => props.fontWeight};

    ${props =>
      props.active &&
      css`
        border: 1.5px solid #0d85ff;
        color: #0d85ff;
      `}
  }

  > span {
    font-size: 13px;
    color: ${props => (props.active ? '#0d85ff' : '#9d9d9d')};
    line-height: 1.5;
    text-align: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FontWeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      color: #0d85ff;
    `}

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === '500' &&
          '1px solid #0d85ff'};
      `}

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: ${props.selectedFontWeigth === '600' && '1px solid #0d85ff'};
    `}
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.borderColor || '#0d85ff'};
  color: ${props => props.color || '#0d85ff'};
  background: ${props => props.background};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const DivTitleColors = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 4px;
  /* padding: 0 16px; */

  > div {
    position: absolute;
    border: 1.5px solid rgb(43, 143, 245);
    border-radius: 4px;
    padding: 2px 6px;
    color: rgb(43, 143, 245);
    font-size: 13px;
    left: 121px;
    top: -3px;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    color: #000000;
    margin-bottom: 8px;
  }

  > span {
    font-size: 13px;
    line-height: 1.5;
    color: #9d9d9d;

    > a {
      color: #2b8ff5;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

import React from 'react';

import { Container } from './styles';

import ArrowLeftIcon from '../../../../../assets/icons/arrowLeft.svg';

function Header({ title, handleClickBack }) {
  function clickBack() {
    handleClickBack();
  }

  return (
    <Container>
      <div onClick={() => clickBack()}>
        <img src={ArrowLeftIcon} alt="arrow" />
      </div>
      <strong>{title}</strong>
    </Container>
  );
}

export default Header;

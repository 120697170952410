import React, { useCallback } from 'react';

import { ContainerGeral, Container, Div } from './styles';

export default function CancelModal({ showModal, Modal, id }) {
  // const dispatch = useDispatch();

  const handleModal = useCallback(
    canceled => {
      Modal(id, canceled);
    },
    [Modal, id]
  );

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={() => handleModal(false)} />
      <Div showModal={showModal}>
        <div>
          <strong>Excluir variação?</strong>
          <span>Você deseja excluir essa variação?</span>
        </div>
        <button
          type="button"
          className="confirm"
          onClick={() => handleModal(true)}
        >
          Confirmar exclusão
        </button>
        <button type="button" onClick={() => handleModal(false)}>
          Cancelar
        </button>
      </Div>
    </ContainerGeral>
  );
}

import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';

import { H3 } from '~/designsystem/Fonts';
import {
  ContainerGeral,
  Container,
  Div,
  Header,
  Card,
  DiscountLabel,
  Price,
  OldPrice,
  DivButton,
} from './styles';

// import CrownIcon from '../../../assets/icons/plan/crown.svg';
import ArrowRight from '../../../assets/icons/arrowRight.svg';

import dataPlans from '../../../data/plans.json';

function ProPlanProducts({ showModal, Modal, is_desktop }) {
  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  const handleModalDesktop = useCallback(() => {
    Modal(false, true);
  }, [Modal]);

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <Header color={dataPlans.premium.color}>
          <H3>Você atingiu o limite de produtos!</H3>

          <strong>
            Assine o <span>Plano {dataPlans.pro.name}</span> para ter acesso a
            esse recurso e muito mais!
          </strong>
          {/* <strong>
            Adicione até 1500 produtos com o plano {dataPlans.pro.name}
          </strong> */}
        </Header>

        <Card>
          <div>
            <div>
              <strong>Plano {dataPlans.pro.name}</strong>

              <DiscountLabel color={dataPlans.pro.color}>
                <span>
                  {dataPlans.pro.price.yearly.economyPercent} DE DESCONTO
                </span>
              </DiscountLabel>
            </div>

            <Price color={dataPlans.pro.color}>
              R$ {dataPlans.pro.price.yearly.price}
            </Price>
            <OldPrice>R$ {dataPlans.pro.price.yearly.oldPrice}</OldPrice>
          </div>
        </Card>

        <DivButton>
          {(is_desktop && (
            <button type="button" onClick={handleModalDesktop}>
              Assinar plano {dataPlans.pro.name}{' '}
              <img src={ArrowRight} alt="arrow" />
            </button>
          )) || (
            <Link to="/pro" onClick={handleModal}>
              Assinar plano {dataPlans.pro.name}{' '}
              <img src={ArrowRight} alt="arrow" />
            </Link>
          )}
        </DivButton>
      </Div>
    </ContainerGeral>
  );
}

export default ProPlanProducts;

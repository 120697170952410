import styled from 'styled-components';

export const DivWrapper = styled.div`
  display: flex;
  border-radius: 8px;
  background: #f9f9f9;
  max-width: ${props => props.maxWidth};
  margin-top: ${props => props.marginTop};
`;

export const DiscountBox = styled.div`
  border-radius: 8px 0px 0px 8px;
  background: #efefef;
  padding: 14px;
`;

export const Input = styled.input`
  border: none;
  background: transparent;
  padding: 14px;
  width: 100%;
`;

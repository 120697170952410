import React from 'react';

// import { Svg } from './styles';

function DesktopIcon({ color }) {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 1H2.5C1.67157 1 1 1.67157 1 2.5V10.75C1 11.5784 1.67157 12.25 2.5 12.25H14.5C15.3284 12.25 16 11.5784 16 10.75V2.5C16 1.67157 15.3284 1 14.5 1Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 15.25L8.5 12.25L12.25 15.25"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DesktopIcon;

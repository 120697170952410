/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { H4, SecundaryCard, Space, Span } from '~/designsystem/Fonts';
import { handleBuyStripe } from '~/functions/stripe';
import LoaderComponent from '~/components/Loader';
import {
  subscribeEscalaPlanMonthlyBtnEvent,
  subscribeEscalaPlanYearlyBtnEvent,
} from '~/functions/events';
import dataPlans from '../../../../data/plans.json';

import ArrowRightWhiteIcon from '../../../../assets/icons/arrowRight.svg';

import {
  Container,
  DivProPlan,
  DivPrice,
  DivItemsPro,
  DiscountLabel,
  GreyLabel,
  BodySecundaryCard,
  DivBolet,
  DivHeaderLabel,
} from './styles';
import GeneralItems from '../../GeneralItems';

function CardUltraPlan({
  className,
  pro,
  typePlan,
  handleClickCreatePortalSession = () => {},
  handleUpdatePlan = () => {},
  showBanner,
  showBorder,
  activePlanObject,
  isMyPlan,
}) {
  const [loading, setLoading] = useState();

  const { id: userId, email, whatsapp } = useSelector(
    state => state.user && state.user.profile && state.user.profile
  );

  async function handleBuy() {
    try {
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);

      if (typePlan === 'yearly') {
        subscribeEscalaPlanYearlyBtnEvent();
      } else {
        subscribeEscalaPlanMonthlyBtnEvent();
      }

      await handleBuyStripe(
        userId,
        email,
        whatsapp,
        dataPlans.ultra.price[typePlan].stripe_price_id,
        null
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  async function fHandleClickCreatePortalSession() {
    try {
      setLoading(true);
      await handleClickCreatePortalSession();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  const activePrice = useMemo(() => {
    if (activePlanObject && activePlanObject.id) {
      if (activePlanObject.typePlan === 'yearly') {
        const price = (activePlanObject.price / 12)
          .toFixed(2)
          .replace('.', ',');

        return price;
      }

      return activePlanObject.price.toFixed(2).replace('.', ',');
    }

    return 0;
  }, [activePlanObject]);

  return (
    <Container className={className}>
      <DivProPlan showBorder={showBorder && isMyPlan}>
        {/* {isMyPlan && showBanner && (
          <DivHeaderLabel>Seu plano atual</DivHeaderLabel>
        )} */}

        <body>
          {isMyPlan && showBanner && (
            <DiscountLabel color="#0D85FF">
              <span>Seu plano atual</span>
            </DiscountLabel>
          )}

          {!isMyPlan && (
            <DiscountLabel color={dataPlans.premium.color}>
              <span>
                {dataPlans.ultra.price[typePlan].economyPercent} DE DESCONTO
              </span>
            </DiscountLabel>
          )}

          <H4>Plano {dataPlans.ultra.name}</H4>

          <DivPrice isMyPlan={isMyPlan} color={dataPlans.premium.color}>
            {isMyPlan ? (
              <>
                <strong>
                  <span>R$</span>
                  {activePrice}
                  <span>/mês</span>
                </strong>
              </>
            ) : (
              <strong>
                <span>R$</span>
                {dataPlans.ultra.price[typePlan].price}
                <span>/mês</span>
              </strong>
            )}

            {!isMyPlan && (
              <span>R$ {dataPlans.ultra.price[typePlan].oldPrice}/mês</span>
            )}
          </DivPrice>

          {typePlan === 'yearly' && !isMyPlan && (
            <span className="span-premium">
              Economize R$ {dataPlans.ultra.price[typePlan].economyValue} por
              ano
            </span>
          )}

          {!isMyPlan && (
            <GreyLabel>
              <span>{dataPlans.ultra.price[typePlan].description}</span>
            </GreyLabel>
          )}

          <DivItemsPro>
            {isMyPlan && (
              <button type="button" onClick={fHandleClickCreatePortalSession}>
                Ver detalhes
              </button>
            )}

            {!isMyPlan && pro === dataPlans.ultra.id && (
              <button
                type="button"
                onClick={() =>
                  handleUpdatePlan({
                    plan_name: dataPlans.ultra.name,
                    stripe_price_id:
                      dataPlans.ultra.price[typePlan].stripe_price_id,
                    selectedTypePlan: typePlan,
                    price:
                      typePlan === 'yearly'
                        ? dataPlans.ultra.price[typePlan].year_price
                        : dataPlans.ultra.price[typePlan].price,
                  })
                }
                className="buy-button"
                disabled={loading}
              >
                {loading ? (
                  <LoaderComponent size={20} />
                ) : (
                  <>
                    Alterar para plano{' '}
                    {typePlan === 'yearly' ? 'anual' : 'mensal'}
                    <img src={ArrowRightWhiteIcon} alt="arrow" />
                  </>
                )}
              </button>
            )}

            {pro > 0 && pro !== 3 && (
              <button
                type="button"
                onClick={() =>
                  handleUpdatePlan({
                    plan_name: dataPlans.ultra.name,
                    stripe_price_id:
                      dataPlans.ultra.price[typePlan].stripe_price_id,
                    selectedTypePlan: typePlan,
                    price:
                      typePlan === 'yearly'
                        ? dataPlans.ultra.price[typePlan].year_price
                        : dataPlans.ultra.price[typePlan].price,
                  })
                }
                className="buy-button"
                disabled={loading}
              >
                {loading ? (
                  <LoaderComponent size={20} />
                ) : (
                  <>
                    Alterar para plano {dataPlans.ultra.name}
                    <img src={ArrowRightWhiteIcon} alt="arrow" />
                  </>
                )}
              </button>
            )}

            {pro === 0 && (
              <button
                type="button"
                onClick={handleBuy}
                disabled={loading}
                className="buy-button"
              >
                {loading ? (
                  <LoaderComponent size={20} />
                ) : (
                  <>
                    Assinar plano {dataPlans.ultra.name}
                    <img src={ArrowRightWhiteIcon} alt="arrow" />
                  </>
                )}
              </button>
            )}

            <strong>Tudo do plano Premium e mais:</strong>

            {dataPlans.ultra.items.map(d => (
              <div key={d}>
                <span
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: isMyPlan ? '#838383' : '#0D85FF',
                  }}
                >
                  <div style={{ marginRight: '6px' }}>
                    <svg
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1L3.8125 7L1 4.27273"
                        stroke={isMyPlan ? '#838383' : '#0D85FF'}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  {d}
                </span>
              </div>
            ))}
          </DivItemsPro>

          <SecundaryCard>
            <BodySecundaryCard>
              <strong>Comissão sobre vendas</strong>

              <div>
                <span>Offstore Pay</span>
                <span className="green">
                  {dataPlans.ultra.fees.offstorePay}
                </span>
              </div>

              <div>
                <span>Intermediadores de pagamento(ex: Mercado Pago)</span>
                <span className="green">{dataPlans.ultra.fees.gateways}</span>
              </div>

              <div>
                <span>Pagamento a combinar via Whatsapp</span>
                <span className="green">{dataPlans.ultra.fees.whatsapp}</span>
              </div>
            </BodySecundaryCard>
          </SecundaryCard>

          {!isMyPlan && (
            <>
              <Space height="32px" />

              <GeneralItems />
            </>
          )}
        </body>
      </DivProPlan>

      {/* <DivBolet>
        <button
          type="button"
          onClick={() => history.push('/subscriptionperiod')}
        >
          <img src={BarCodeIcon} alt="código de barras" />
          Pagar via boleto
        </button>
      </DivBolet> */}
    </Container>
  );
}

export default CardUltraPlan;

import styled, { css } from 'styled-components';
import { device } from '../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const Div = styled.div`
  background: #fff;
  border-radius: 25px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;

  ${props =>
    props.isModal &&
    css`
      height: auto;
      top: auto;
      left: auto;
      bottom: 0;
      border-radius: 30px 30px 0 0;

      @media (min-width: 720px) {
        width: 100%;
        max-width: 500px;
        height: auto;
        top: calc(50vh - (575px / 2));
        left: calc(50vw - (500px / 2));
        bottom: auto;
        border-radius: 16px;
      }
    `};
`;

export const DivTitle = styled.div`
  display: flex;
  height: 60px;
  border-bottom: 0.5px solid #d8dee5;
  width: 100%;
  /* margin-bottom: 25px; */

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      color: #262626;
      font-size: 16px;
      font-weight: 500;
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 10%;
    height: 100%;
  }

  @media ${device.mobile} {
    > div {
      width: 70%;
    }

    .div-space {
      width: 15%;
    }
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px 16px 75px 16px;

  ${props =>
    props.isModal &&
    css`
      /* height: 450px; */
      overflow-y: auto;
    `}

  @media (min-width: 720px) {
    padding: 16px 16px 30px 16px;
    max-width: 500px;
  }

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    /* height: 47px; */
    margin-bottom: 16px;
    /* padding: 0 16px; */

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 1.5;
      color: #000000;
    }

    textarea {
      width: 100%;
      border: 0.75px solid #d8dee5;
      border-radius: 8px;
      padding: 12px;
      font-size: 13px;
      border: none;
      background: #f9f9f9;
      /* margin-top: 12px; */

      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 13px;
        color: #c6cad2;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 13px;
        color: #c6cad2;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        font-size: 13px;
        color: #c6cad2;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        font-size: 13px;
        color: #c6cad2;
      }
    }
  }
`;

export const DivButton = styled.div`
  position: fixed;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;

  @media (min-width: 720px) {
    width: 100%;
    max-width: 500px;
    position: relative;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /* top: calc(50vh - (283px / 2)); */
    /* left: calc(50vw - (500px / 2)); */
  }

  > button {
    height: 50px;
    background: #2b8ff5;
    border-radius: 8px;
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;

    > img {
      width: 24px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin-bottom: 25px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  border-bottom: 0.5px solid #d8dee5;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;

    span {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 15%;
    height: 100%;

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff5f5f;
    }

    img {
      /* width: 10px; */
      /* height: 35px; */
      /* margin-left: 22px; */
    }
  }
`;

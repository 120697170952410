import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin-bottom: 24px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  border-bottom: ${props =>
    props.hideBorder ? 'none' : '0.5px solid #d8dee5'};

  > strong {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgb(43, 143, 245);
    border-radius: 4px;
    height: 100%;
    padding: 2px 6px;
    color: rgb(43, 143, 245);
    font-size: 13px;
    line-height: 1.25;
    font-weight: 500;
    font-style: normal;
    height: auto;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 15%;
    height: 100%;
    display: flex;

    > button {
      width: 100%;
      height: 100%;
      border: none;
      background: none;
    }

    img {
      width: 10px;
      height: 35px;
      /* margin-left: 22px; */
    }
  }
`;

import { useCallback } from 'react';
import offstorePay from '~/services/offstorePay';

export function useUpdateSellerPaymentConfiguration({
  bank_slip_is_active,
  credit_card_is_active,
  pix_is_active,
  max_credit_card_installments,
  pix_discount,
  seller_id,
  time_to_receive,
}) {
  const handleUpdateSellerPaymentConfiguration = useCallback(async () => {
    try {
      const { data } = await offstorePay.put(`/seller/paymentConfiguration`, {
        bank_slip_is_active,
        credit_card_is_active,
        pix_is_active,
        max_credit_card_installments,
        pix_discount,
        seller_id,
        time_to_receive,
      });

      return data;
    } catch (err) {
      console.log(err);
    }
  }, [
    bank_slip_is_active,
    credit_card_is_active,
    max_credit_card_installments,
    pix_discount,
    pix_is_active,
    seller_id,
    time_to_receive,
  ]);

  return {
    handleUpdateSellerPaymentConfiguration,
  };
}

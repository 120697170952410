import React from 'react';

import ChevronUp from '../../../../../assets/icons/chevronUp.svg';
import NoImage from '../../../../../assets/icons/noimage.png';
import * as S from './styles';

export default function OrderInformation({}) {
  return (
    <S.MainContainer>
      <S.CollapseHeader>
        Informações do pedido{' '}
        <button type="button">
          <img src={ChevronUp} alt="chevron" />
        </button>
      </S.CollapseHeader>
      <S.CollapseContainer>
        <S.MainInformationsHeader>Produtos</S.MainInformationsHeader>
        <S.ProductContainer>
          <img src={NoImage} alt="img_product" />
          <div>
            <S.ProductTitle>2x Tênis Nike</S.ProductTitle>
            <S.BodyText>R$ 500,00</S.BodyText>
          </div>
        </S.ProductContainer>
        <S.ProductContainer>
          <img src={NoImage} alt="img_product" />
          <div>
            <S.ProductTitle>2x Tênis Nike</S.ProductTitle>
            <S.BodyText>R$ 500,00</S.BodyText>
          </div>
        </S.ProductContainer>
        <S.Divider />
        <S.PaymentAndShippingSummary>
          <S.MainInformationsHeader>
            Dados do comprador
          </S.MainInformationsHeader>
          <S.BodyText>Ana Carolina</S.BodyText>
          <S.BodyText>
            (51) 999999-1234 <S.SpanText>Chamar no Whatsapp</S.SpanText>
          </S.BodyText>
          <S.BodyText>
            ana@gmail.com <S.SpanText>Enviar email</S.SpanText> -{' '}
            <S.SpanText>Ver cliente</S.SpanText>
          </S.BodyText>
          <S.BodyText>123456789</S.BodyText>
        </S.PaymentAndShippingSummary>
        <S.Divider />
        <S.PaymentAndShippingSummary>
          <S.MainInformationsHeader>Forma de entrega</S.MainInformationsHeader>
          <S.BodyText>PAC - Correios(via Melhor Envio)</S.BodyText>
        </S.PaymentAndShippingSummary>
        <S.Divider />
        <S.PaymentAndShippingSummary>
          <S.MainInformationsHeader>
            Endereço de entrega
          </S.MainInformationsHeader>
          <S.BodyText>
            Rua Herber Rhodenbusch, 139, Centro - Guaíba/RS
          </S.BodyText>
          <S.BodyText>12345-678</S.BodyText>
        </S.PaymentAndShippingSummary>
        <S.Divider />
        <S.PaymentAndShippingSummary>
          <S.MainInformationsHeader>Observações</S.MainInformationsHeader>
          <S.BodyText>lalalalalala</S.BodyText>
        </S.PaymentAndShippingSummary>
      </S.CollapseContainer>
    </S.MainContainer>
  );
}

import React, { useEffect, useState } from 'react';

import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

// import InfiniteScroll from 'react-infinite-scroll-component';
import api from '../../../../services/api';

import Loader from '../../../../assets/icons/loader-grey.gif';

// import WhatsappIcon from '../../../../assets/icons/whatsapp.svg';
import BluePointIcon from '../../../../assets/icons/order/bluePoint.svg';

import {
  Container,
  DivAddOrder,
  DivTitle,
  DivBody,
  Card,
  DivPaymentStatus,
} from './styles';

import MyOrdersDetails from './MyOrdersDetails';
import OrderRegister from './OrderRegister';
import ViewLabel from './ViewLabel';
import ContentDeclaration from './ContentDeclaration';

function MyOrders() {
  const [pageView, setPageView] = useState(0);

  const [orders, setOrders] = useState();
  const [orderId, setOrderId] = useState();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function load() {
      try {
        setLoading(true);
        await api.get(`/orderrequestpagination?page=${page}`).then(response => {
          if (page > 1) {
            setTimeout(() => {
              setCount(count + response.data.rows.length);
              setOrders([
                ...orders,
                ...response.data.rows.map(r => ({
                  ...r,
                  date: format(
                    parseISO(r.createdAt),
                    "dd 'de' MMMM 'de' yyyy ' às 'HH:mm'h'",
                    {
                      locale: pt,
                    }
                  ),
                })),
              ]);
            }, 1500);
          } else {
            setTotalCount(response.data.count);
            setCount(response.data.rows.length);
            setOrders(
              response.data.rows.map(r => ({
                ...r,
                date: format(
                  parseISO(r.createdAt),
                  "dd 'de' MMMM 'de' yyyy ' às 'HH:mm'h'",
                  {
                    locale: pt,
                  }
                ),
              }))
            );
          }
        });
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 1500);
      }
    }

    if (pageView === 0) {
      load();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function handleLoadMoreOrders() {
    if (count === totalCount) {
      setHasMore(false);
      return 0;
    }

    setPage(page + 1);
  }

  async function handleClickOrder(id) {
    setOrderId(id);
    setPageView(1);

    await api.put(`/orderrequest/${id}`, {
      viewed: true,
    });
  }

  function handleClickButton(number) {
    setPageView(number || 0);
  }

  return (
    <>
      <Container>
        {pageView === 0 && (
          <>
            <DivAddOrder>
              {/* <button type="button" onClick={() => setPage(2)}>
                Adicionar pedido
              </button>
              <span>
                O estoque dos produtos incluidos no pedido serão reduzidos
                automaticamente
              </span> */}

              <DivTitle>
                <strong>Pedidos recebidos</strong>
                <span>Aqui são exibidos seus pedidos recebidos.</span>
              </DivTitle>

              <button type="button" onClick={() => setPageView(2)}>
                + Adicionar pedido
              </button>
            </DivAddOrder>
            <DivBody>
              {orders && orders.length > 0 && (
                // <InfiniteScroll
                //   dataLength={count} // This is important field to render the next data
                //   next={handleLoadMoreOrders}
                //   hasMore={hasMore}
                //   loader={
                //     <div className="loader">
                //       <img src={Loader} alt="loader" />
                //     </div>
                //   }
                // >
                <>
                  {orders &&
                    orders.map(order => (
                      <Card
                        key={order.id}
                        onClick={() => handleClickOrder(order.id)}
                        canceled={order.status === 'Cancelado'}
                      >
                        <div>
                          {!order.viewed && (
                            <strong>{order.name || 'Registro manual'}</strong>
                          )}

                          {order.viewed && order.status !== 'Cancelado' && (
                            <strong className="viewed">
                              {order.name || 'Registro manual'}
                            </strong>
                          )}

                          {order.viewed && order.status === 'Cancelado' && (
                            <strong className="canceled">
                              {order.name || 'Registro manual'}
                            </strong>
                          )}

                          {order.status === 'Cancelado' && (
                            <span className="canceled">{order.total}</span>
                          )}

                          {order.status !== 'Cancelado' && order.viewed && (
                            <span className="canceled">{order.total}</span>
                          )}

                          {order.status !== 'Cancelado' && !order.viewed && (
                            <span>{order.total}</span>
                          )}
                        </div>
                        <span className={order.viewed ? 'date-viewed' : ''}>
                          {order.date}
                        </span>

                        <DivPaymentStatus color={order.label_status}>
                          {order.viewed === false && (
                            <img
                              src={BluePointIcon}
                              alt="point"
                              className="point"
                            />
                          )}
                          <div>{order.label_status}</div>
                        </DivPaymentStatus>

                        {/* <DivPaymentStatus
                          color={order.mercado_pago_payment_status}
                          orderStatus={order && order.status}
                        >
                          {order.viewed === false && (
                            <img
                              src={BluePointIcon}
                              alt="point"
                              className="point"
                            />
                          )}

                          {order.mercado_pago_payment_status === 'approved' &&
                            order.status !== 'Cancelado' && (
                              <div>Pago via Mercado Pago</div>
                            )}

                          {order &&
                            order.status === 'Aprovado' &&
                            !order.mercado_pago_payment_id && <div>Pago</div>}

                          {order.mercado_pago_payment_status === 'pending' && (
                            <div>Aguardando pgto.</div>
                          )}

                          {(order.mercado_pago_payment_status === 'cancelled' ||
                            order.status === 'Cancelado') && (
                            <div>Cancelado</div>
                          )}

                          {order.status === 'Pendente' &&
                            !order.mercado_pago_payment_id && (
                              <div>Pgto a combinar</div>
                            )}
                        </DivPaymentStatus> */}
                      </Card>
                    ))}

                  {hasMore && (
                    <button type="button" onClick={handleLoadMoreOrders}>
                      {loading ? <img src={Loader} alt="loader" /> : 'Ver mais'}
                    </button>
                  )}
                </>
              )}
            </DivBody>
          </>
        )}

        {pageView === 1 && (
          <MyOrdersDetails handleClickButton={handleClickButton} id={orderId} />
        )}

        {pageView === 2 && (
          <OrderRegister handleClickButton={handleClickButton} />
        )}

        {pageView === 3 && (
          <ViewLabel handleClickButton={handleClickButton} id={orderId} />
        )}

        {pageView === 4 && (
          <ContentDeclaration
            handleClickButton={handleClickButton}
            id={orderId}
          />
        )}
      </Container>
    </>
  );
}

export default MyOrders;

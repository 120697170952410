import React from 'react';

// import { Container } from './styles';

function FreightIcon({ color }) {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.69231 4.07617H4.07692C3.26087 4.07617 2.47824 4.40035 1.90121 4.97738C1.32417 5.55442 1 6.33704 1 7.1531V13.3069H3.30769"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.4616 13.3077H21.0001V2.53846C21.0001 2.13044 20.838 1.73912 20.5495 1.4506C20.261 1.16209 19.8696 1 19.4616 1H10.2308C9.82282 1 9.43151 1.16209 9.14299 1.4506C8.85447 1.73912 8.69238 2.13044 8.69238 2.53846V11.2769"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46191 13.3086H13.3081"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.38454 16.3843C8.08388 16.3843 9.46146 15.0067 9.46146 13.3074C9.46146 11.6081 8.08388 10.2305 6.38454 10.2305C4.6852 10.2305 3.30762 11.6081 3.30762 13.3074C3.30762 15.0067 4.6852 16.3843 6.38454 16.3843Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3845 16.3843C18.0839 16.3843 19.4615 15.0067 19.4615 13.3074C19.4615 11.6081 18.0839 10.2305 16.3845 10.2305C14.6852 10.2305 13.3076 11.6081 13.3076 13.3074C13.3076 15.0067 14.6852 16.3843 16.3845 16.3843Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default FreightIcon;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 4vw;
  padding-top: 25px;

  > strong {
    font-weight: 500;
    font-size: 15px;
    color: #000;
    margin-bottom: 5px;
  }

  > span {
    color: #a5a9b1;
    font-size: 13px;
    margin-bottom: 15px;
  }

  button {
    background: #2b8ff5;
    border-radius: 10px;
    color: #fff;
    border: none;
    width: 100%;
    height: 50px;
  }
`;

export const DivPromotions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 0.5px dashed #a5a9b1;
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 15px;

  strong {
    font-weight: 500;
    font-size: 15px;
    color: #000;
  }

  span {
    font-size: 13px;
    color: #858585;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .left {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

import React, { useEffect } from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { v4 as uuidv4 } from 'uuid';

import './config/ReactotronConfig';
import { Router } from 'react-router-dom';
import Cookies from 'js-cookie';
import GlobalStyle from './styles/global';
import history from './services/history';
import Routes from './routes';
// eslint-disable-next-line import/no-extraneous-dependencies

import { store, persistor } from './store';
import { CheckIfHaveNewFbc, ConversionApiMeta } from './functions/meta';
import { ConversionApiTiktok } from './functions/tiktok';

function App() {
  useEffect(() => {
    async function load() {
      const uuid = uuidv4();
      // const fbp = Cookies.get('_fbp');

      // const params = new URLSearchParams(window.location.search);
      // const fbclid = params.get('fbclid');
      //
      // if (fbclid) {
      //   const existingFbc = Cookies.get('_fbc');
      //   if (!existingFbc || existingFbc.split('.').pop() !== fbclid) {
      //     const newFbc = `fb.${Date.now()}.${fbclid}`;
      //     Cookies.set('_fbc', newFbc, { expires: 90 });
      //   }
      // }

      const state = store && store.getState();
      if (state && state.user && state.user.profile) {
        const { id, email, whatsapp } = store.getState().user.profile;

        const fbc = await CheckIfHaveNewFbc({ fbc: Cookies.get('_fbc') });

        ReactPixel.init('1775048205980627', {
          em: email,
          ph: whatsapp,
          external_id: id,
        });

        // Via Pixel
        ReactPixel.fbq('track', 'PageView', null, {
          eventID: uuid,
        });

        // Via Conversion API Meta
        await ConversionApiMeta({
          eventName: 'PageView',
          email,
          phone: whatsapp,
          externalId: id,
          fbc,
          eventId: uuid,
        });

        await ConversionApiTiktok({
          eventName: 'PageView',
          email,
          phone: whatsapp,
          externalId: id,
          eventId: uuid,
        });
      } else {
        const fbc = Cookies.get('_fbc');
        const ttclid = Cookies.get('ttclid');

        ReactPixel.init('1775048205980627');

        // Via Pixel
        ReactPixel.fbq('track', 'PageView', null, {
          eventID: uuid,
        });

        // Via Conversion API Meta
        await ConversionApiMeta({
          eventName: 'PageView',
          eventId: uuidv4(),
          fbc,
        });

        await ConversionApiTiktok({
          eventName: 'PageView',
          eventId: uuidv4(),
          ttclid,
        });
      }
    }

    load();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <GlobalStyle />
          <ToastContainer
            position="top-center"
            autoClose={1500}
            limit={1}
            hideProgressBar
            transition={Slide}
          />
          <Routes />
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;

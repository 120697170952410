import styled from 'styled-components';

export const Container = styled.button`
  padding: 19px 22px;
  background: #2b8ff5;
  border: 1px solid #2b8ff5;
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90%;

  p {
    color: #fff;
    text-decoration: none;
    flex: 1;
    font-size: 16px;
    font-weight: 600;
  }
`;

import styled, { css } from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

export const Container = styled.div`
  position: ${props => (props.fixed ? 'fixed' : 'static')};
  width: 100%;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: #fff;

  .div-serch-input {
    width: 100%;
    padding: 12px 0 0 0;
  }

  body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 2px;
    height: 2px;
  }

  body ::-webkit-scrollbar {
    -webkit-appearance: none;
  }
`;

export const DivProPlan = styled.div`
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    270deg,
    #ff6534 -15.84%,
    #ff0e9f 62.42%,
    #a925fa 104.39%
  );
  padding: 16px;
  position: relative;

  > header {
    display: flex;
    margin-bottom: 4px;

    > div {
      border-radius: 4px;
      border: 1px solid #fff;
      padding: 2px 4px;
      color: #fff;
      font-size: 12px;
      font-weight: 600;
    }

    > strong {
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 22px;
      color: #ffffff;
      margin-right: 6px;
    }
  }

  > div {
    display: flex;

    > strong {
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
    }

    > span {
      color: rgba(255, 255, 255, 0.7);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      text-decoration: line-through;
      margin-left: 4px;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    border-radius: 6px;
    padding: 12px 16px;
    position: absolute;
    right: 16px;
    font-weight: 500;
    font-size: 13px;
    border: none;

    > span {
      font-size: 14px;
      font-weight: 700;
      background: var(
        --Cores-new-gradiet,
        linear-gradient(220deg, #ff6534 12.15%, #ff0e9f 64.72%, #a925fa 102.33%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const DivMenu = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 75vw;
  background: #fff;
  border-left: 0.5px solid #d8dee5;
  top: 0;
  right: 0;
  z-index: 9999;

  header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 65px;
    align-items: center;
    padding: 0 24px;
    border-bottom: 0.5px solid #d8dee5;

    > div {
      display: flex;
      width: 50%;
    }

    > span {
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 37px 23px 0 23px;

  > a {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
    text-decoration: none;

    > img {
      margin-right: 15px;
    }

    > span {
      font-size: 14px;
      color: #000000;
    }
  }

  > button {
    display: flex;
    font-size: 14px;
    text-decoration-line: underline;
    color: #ff5f5f;
    border: none;
    background: transparent;
  }
`;

export const DivTabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 52px;
  border-top: 0.5px solid #d8dee5;
  border-bottom: 0.5px solid #d8dee5;
  /* margin-top: 32px; */

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    list-style-type: none;
    width: 100%;
    height: 100%;

    li {
      width: 100%;
      height: 100%;
      display: flex;
      margin: 0;

      .selected {
        box-sizing: border-box;
        border-bottom: 1px solid #000000;
        padding-top: 1px;
        color: #000;
      }
    }
  }
`;

export const LabelSubscriptionExpired = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ff303c;
  padding: 16px 16px 16px 20px;
  position: relative;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }

  > span {
    font-size: 13px;
    color: #ffffff;
    /* max-width: 250px; */
    margin-top: 2px;
  }

  > button {
    position: absolute;
    right: 16px;
    background: rgba(255, 255, 255, 0.19);
    border-radius: 6px;
    border: none;
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
    height: 36px;
    width: 103px;
  }
`;

export const LabelMaintenanceMode = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ff6c4b;
  padding: 16px 16px 16px 16px;
  position: relative;

  > span {
    font-weight: 400;
    font-size: 13px;
    color: #ffffff;
    text-align: center;
  }
`;

export const DivEmpty = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 166px;

  .button-icon {
    margin-bottom: 16px;
  }

  > span {
    font-size: 13px;
    text-align: center;
    color: #838383;
  }

  > button {
    border: none;
    background: transparent;
    font-size: 13px;
    font-weight: 500;
    color: #2b8ff5;
  }
`;

export const DivPreview = styled.div`
  display: flex;
  width: 100%;
  height: 68px;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  background: #2b8ff5;
  /* border-top: 0.5px solid #d8dee5; */

  .div-info {
    /* margin-left: 16px; */
    margin-right: 0;
    flex: 1;
    flex-direction: column;

    > a {
      display: flex;
      align-items: baseline;
      text-decoration: none;
      margin-top: 4px;

      > span {
        font-weight: 500;
        font-size: 14px;
        margin-left: 16px;
        color: #fff;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-decoration: underline;
      }

      > img {
        margin-left: 6px;
      }
    }

    strong {
      font-weight: 400;
      font-size: 11px;
      color: #fff;
      margin-right: 4px;
      margin-left: 16px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .buttons {
    justify-content: flex-end;
    padding-right: 16px;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      /* width: 44px; */
      height: 34px;
      padding: 0 12px;
      background: rgba(255, 255, 255, 0.19);
      border-radius: 6px;
      color: #ffffff;
      font-weight: 500;
      font-size: 13px;
      border: none;
    }
  }

  div {
    display: flex;
    width: 50%;
  }
`;

export const Tab = styled.div`
  color: #a5a9b1;
  text-decoration: none;
  font-size: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledDebounceInput = styled(DebounceInput)`
  background: #f5f5f5;
  border-radius: 8px;
  height: 44px;
  width: 100%;
  border: none;
  display: flex;
  text-align: center;
  font-size: 13px;

  &::placeholder {
    color: #9d9d9d;
  }
`;

export const DebounceInputStock = styled(DebounceInput)``;

export const DivTags = styled.div`
  display: flex;
  flex-direction: column;
  /* padding-left: 4vw; */
  padding-top: ${props => (props.first ? '24px' : '0')};
  border-top: ${props =>
    props.first ? '0.5px solid rgb(216, 222, 229)' : 'none'};
  margin-bottom: 16px;

  .subcategorias {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }

  > div {
    display: flex;
    align-items: center;
  }

  span {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
    margin-left: 4vw;
  }

  .div-tags {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    display: flex;
    padding-left: 4vw;

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      display: none !important;
      background-color: transparent;
    }
  }
`;

export const ButtonSeeMore = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: #2b8ff5;
  background: none;
  border: none;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 16px;
`;

export const LabelPro = styled.div`
  display: flex;
  padding: 2px 6px;
  border: 1px solid #2b8ff5;
  border-radius: 4px;
  margin-left: 6px;
  margin-bottom: 10px;
  > span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #2b8ff5 !important;
    margin: 0 !important;
  }
`;

export const LabelSoon = styled.div`
  display: flex;
  padding: 2px 6px;
  border: 1px solid #9d9d9d;
  border-radius: 4px;
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;
  margin-bottom: 10px;

  > span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #9d9d9d !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0 !important;
  }
`;

export const TagAdd = styled.button`
  min-width: 45px;
  height: 45px;
  border: none;
  font-size: 24px;
  background: transparent;
  border: 0.5px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
`;

export const Tag = styled.button`
  height: 45px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${props => (props.index === 0 ? '0' : '8px')};
  /* padding-left: 18px; */
  background: #efefef;
  color: #000000;

  ${props =>
    props.selected &&
    css`
      background: #2b8ff5;
      color: #ffffff;

      .;
    `}

  .body-tag {
    padding-left: 12px;
    padding-right: 12px;
    border-right: ${props =>
      props.selected
        ? '0.5px solid rgba(255, 255, 255, 0.33)'
        : '0.5px solid #d8dee5'};
  }

  > div {
    display: flex;
    height: 45px;
    align-items: center;
    justify-content: center;
    padding-left: 12px;
    padding-right: 12px;
  }

  img {
    height: 17px;
  }
`;

export const Loader = styled.img`
  width: 50px;
  height: 50px;
  align-self: center;
  margin-bottom: 65px;
`;

export const DivProductButton = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4vw 20px 4vw;

  span {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
  }
`;

export const ButtonSubmit = styled.button`
  width: 100%;
  height: 54px;
  border: none;
  background: #2b8ff5;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
`;

export const DivTabStock = styled.div`
  display: flex;
  padding: 20px 32px 0 32px;

  > div {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    /* padding: 20px 0; */

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #cfcfcf;
    }

    .active {
      color: #2b8ff5;
    }

    .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      background: #ff6c4b;
      border-radius: 50%;
      color: #fff;
      margin-left: 4px;

      > strong {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
        margin-top: 2px;
      }
    }
  }
`;

export const DivLinks = styled.div`
  width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 65px;

  .loader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 12px 0;

    > img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const DivStockProducts = styled.div`
  width: 100%;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 65px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px 10px 10px;
    margin: 0 0 8px 0;
    height: 62px;
    border: 0.5px solid #d8dee5;
    border-radius: 12px;

    > img {
      min-width: 50px;
      height: 47px;
      border-radius: 6px;
      margin-right: 5px;
      object-fit: cover;
      border: none;
      background: none;
      outline: none;
    }
  }
`;

export const DivStockProductsLeft = styled.div`
  max-width: 40%;
  flex: 1;
  padding-left: 5px;
  display: flex;
  flex-direction: column;

  span {
    max-width: 300px;
    font-weight: 500;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  > div {
    display: flex;
    align-items: center;

    > strong {
      color: #aaaaaa;
      font-weight: normal;
      font-size: 13px;
      margin-right: 12px;
    }

    span {
      color: #9d9d9d;
      margin-left: 4px;
      font-size: 13px;
    }

    .red-stock {
      color: #ff6c4b;
    }

    .yellow-stock {
      color: #ffb525;
    }
  }
`;

export const DivStockProductsRight = styled.div`
  /* width: 40%; */
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  .no-stock {
    background: rgba(255, 48, 60, 0.1);
    color: #ff303c;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 6px;
    height: 34px;
    min-width: 32px;
    border: none;
  }

  > input {
    display: flex;
    height: 34px;
    width: 60px;
    background: #f9f9f9;
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 6px;
    margin: 0 6px;
    text-align: center;
  }
`;

export const DivMaintenance = styled.div`
  display: flex;
  flex: 1;
  padding: 14px 20px;
  position: fixed;
  bottom: 24px;
  background: #2b8ff5;
  border-radius: 10px;
  margin: 0 16px;

  > span {
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }
`;

export const AddVariation = styled.div`
  display: flex;
  justify-content: center;

  .div-bg {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(227, 229, 236, 0.65);
    z-index: 500;
  }
`;

export const DivAddVariation = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  bottom: 0;
  width: 100%;
  height: 75vh;
  background: #fff;
  border-radius: 20px 20px 0 0;
  z-index: 550;

  > header {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > strong {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    > img {
      position: absolute;
      right: 24px;
    }

    > button {
      position: absolute;
      right: 24px;
      color: #ff303c;
      border: none;
      background: none;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 0 16px 82px 16px;
    /* height: 450px; */

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 2px;
    }

    > span {
      font-size: 11px;
      line-height: 16px;
      color: #9d9d9d;
      margin-bottom: 8px;
    }

    > input {
      background: #f9f9f9;
      border-radius: 8px;
      font-size: 13px;
      line-height: 19px;
      color: #101010;
      border: none;
      min-height: 42px;
      padding: 0 12px;
      margin-bottom: 16px;
    }

    > button {
      display: flex;
      align-self: flex-start;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
      margin-bottom: 16px;
      /* margin: auto; */
    }
  }

  > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    border-top: 0.5px solid #d8dee5;
    background: #fff;

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #2b8ff5;
      border-radius: 8px;
      height: 50px;
      border: none;
      color: #fff;

      &:disabled {
        background: #838383;
      }
    }
  }
`;

export const DivItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  max-height: ${props => (props.create ? '40vh' : '42vh')};
  padding-bottom: 70px;
  /* height: ${props => (props.create ? '27vh' : '42vh')}; */
  /* padding-bottom: 24px; */

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const DivItem = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  margin-bottom: 12px;
  padding-right: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
  }

  > input {
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 8px;
    font-size: 13px;
    line-height: 19px;
    color: #101010;
    height: 42px;
    padding: 0 12px;
    width: 100%;

    &:focus {
      border: 1px solid #2b8ff5;
      background: #fff;
    }
  }

  > img {
    margin-left: 20px;
    width: 12px;
  }

  > button {
    display: flex;
    align-self: flex-start;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
    border: none;
    background: none;
  }
`;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import CancelImage from '../../../../assets/icons/cancelx.svg';
import ArrowLeftIcon from '../../../../assets/icons/arrowLeft.svg';

import { signOut } from '../../../../store/modules/auth/actions';

import { ContainerGeral, Container, Div, DivTitle, DivBody } from './styles';

function ConfigurationModal({ SetShowConfigurationModal }) {
  const dispatch = useDispatch();

  const [showSettings, setShowSettings] = useState(false);

  const handleModal = useCallback(indexMenu => {
    SetShowConfigurationModal(indexMenu || 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleSignOut() {
    dispatch(signOut());
  }

  return (
    <ContainerGeral>
      <Container onClick={() => handleModal()} />
      <Div>
        <DivTitle>
          <div
            className="div-space"
            onClick={showSettings ? () => setShowSettings(false) : () => {}}
          >
            {showSettings && <img src={ArrowLeftIcon} alt="arrow" />}
          </div>
          <div>
            <span>Configurações</span>
          </div>
          <div className="div-space">
            <img
              src={CancelImage}
              alt="cancelImage"
              onClick={() => handleModal()}
            />
          </div>
        </DivTitle>
        <DivBody>
          <button type="button" onClick={() => handleModal(1)}>
            Minha conta
          </button>

          <button type="button" onClick={() => handleModal(6)}>
            Meu plano
          </button>

          <button type="button" onClick={() => handleModal(7)}>
            Planos
          </button>

          <button type="button" onClick={() => handleModal(11)}>
            Ajustes
          </button>

          <button type="button" className="button-exit" onClick={handleSignOut}>
            Sair
          </button>
        </DivBody>
      </Div>
    </ContainerGeral>
  );
}

export default ConfigurationModal;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 55px;
    margin-bottom: 25px;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
    border-bottom: 0.5px solid #d8dee5;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80%;

      span {
        text-align: center;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
      }

      img {
        cursor: pointer;
      }
    }

    .div-space {
      width: 10%;
      height: 100%;

      > img {
        width: 10px;
        height: 35px;
        margin-left: 22px;
      }

      > button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 45px;
        border: none;
        background: none;

        > span {
          font-weight: 500;
          font-size: 14px;
          color: #ff303c;
          margin-left: 6px;
        }
      }
    }
  }
`;

export const Body = styled.div`
  display: flex;
  margin-top: 55px;
  padding: 32px 24px;
  flex-direction: column;

  > a {
    margin-bottom: 24px;
    text-decoration: none;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }

  .disabled {
    color: #e5e5e5;
  }
`;

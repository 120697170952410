import React, { useCallback } from 'react';

import { ContainerGeral, Container, Div } from './styles';

export default function DeleteModal({
  showModal,
  Modal,
  id,
  title,
  body,
  textConfirmButton,
  textCancelButton,
}) {
  // const dispatch = useDispatch();

  const handleModal = useCallback(
    canceled => {
      Modal(false, canceled);
    },
    [Modal]
  );

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={() => handleModal(false)} />
      <Div showModal={showModal}>
        <div>
          <strong>{title || 'Excluir?'}</strong>
          <span>{body || 'Deseja excluir esse item?'}</span>
        </div>
        <button
          type="button"
          className="confirm"
          onClick={() => handleModal(true)}
        >
          {textConfirmButton || 'Confirmar'}
        </button>
        <button type="button" onClick={() => handleModal(false)}>
          {textCancelButton || 'Cancelar'}
        </button>
      </Div>
    </ContainerGeral>
  );
}

import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 24px;
  border: 1px solid #d8dee5;
  box-shadow: 6px 23px 28px rgba(0, 0, 0, 0.05);

  strong {
    margin-top: 15px;
    font-weight: 600;
    font-size: 13px;
    color: #2b2b2b;
    line-height: 19px;
  }

  > img {
    margin-bottom: 40px;
  }

  > span {
    font-size: 13px;
    line-height: 19px;
    color: #838383;
  }

  .input-user {
    text-transform: lowercase;
  }

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #262626;

    svg {
      margin-right: 10px;
    }
  }

  a {
    font-size: 13px;
    text-decoration-line: underline;
    color: #129eed;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
    max-width: 500px;

    span {
      color: #e53030;
      margin-bottom: 5px;
      font-weight: bold;
    }
  }

  .terms {
    font-size: 10px !important;
    line-height: 15px !important;
    color: #838383 !important;
    text-align: center;
    margin-bottom: 12px !important;

    > a {
      font-size: 10px !important;
    }
  }
`;

export const DivInput = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;

  > div {
    display: flex;
    width: 100%;
    position: relative;
    justify-content: center;

    img {
      /* width: 5%; */
      margin-left: -15px;
    }
  }

  input {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: ${props =>
      props.hasError ? '1px solid #ff303c' : '1px solid #d8dee5'};
    background: none;
    font-size: 13px;
    width: 100%;
    padding: 4px 0;

    &:focus {
      border-bottom: ${props =>
        props.hasError ? '1px solid #ff303c' : '1px solid #000000'};
    }

    &::placeholder {
      color: #cccccc;
    }
  }
`;

export const SpanError = styled.span`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  font-size: 11px;
  color: #ff303c;
  font-weight: normal !important;
  margin-bottom: 0 !important;
  /* padding-right: 18px; */
`;

export const Label = styled.div`
  position: absolute;
  right: 0;
  top: 4px;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  color: #838383;
`;

export const Title = styled.h1`
  color: #fff;
`;

export const ButtonSubmit = styled.button`
  background: #2b8ff5;
  border-radius: 10px;
  color: #fff;
  border: none;
  padding: 0 15px;
  height: 50px;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;

  > img {
    width: 25px;
    height: 25px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
    background: #cacaca;
  }
`;

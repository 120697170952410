import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ResponsiveHeader from '../../../../../components/HeaderMobile';
import * as S from './styles';
import DisputePageComponent from './DisputePageComponent';

export default function DisputePage({ match }) {
  const history = useHistory();
  const transactionId = match.params.id;
  const [paymentId, setPaymentId] = useState(null);

  useEffect(() => {
    const id = history.location.pathname.split('/');
    setPaymentId(id.pop());
  }, [history.location.pathname]);

  return (
    <S.Container>
      {/* <UniversalLoader open={initialLoading} /> */}
      <ResponsiveHeader
        title="Responder contestação"
        url={`/paymentmethods/configurationoffstorepay/payment/${paymentId}`}
      />
      <S.Body>
        <DisputePageComponent
          transactionId={transactionId}
          paymentId={transactionId}
        />
      </S.Body>
    </S.Container>
  );
}

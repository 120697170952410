import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const ModalWrapper = styled.div`
  width: 600px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  height: 747px;
  overflow: auto;
`;

export const Header = styled.h1`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 24px;
`;

export const Divider = styled.div`
  height: 0.5px;
  background-color: #d8dee5;
  margin-top: 24px;
`;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect } from 'react';

import { toast } from 'react-toastify';
import { uniqueId } from 'lodash';

import api from '~/services/api';
import SelectProductsModal from '../../SelectProductsModal/Responsive';

import {
  Container,
  Strong,
  SpanLabel,
  DivInput,
  DivImage,
  Div,
  DivDevice,
  DivBannerImages,
  LabelImage,
  DivImages,
  DivSelectProducts,
} from './styles';

import ImgPreview from '../../../ImagePreview';

import PhoneIcon from '../../../../assets/icons/settings/PhoneIcon';
import DesktopIcon from '../../../../assets/icons/settings/DesktopIcon';
import imgIcon from '../../../../assets/icons/img.svg';
import loaderGrey from '../../../../assets/icons/loader-grey.gif';
import loaderGif from '../../../../assets/icons/loader.gif';

function AddMainModal({
  handleShowModalCollection,
  component,
  handleDeleteCard,
}) {
  const [selectedDevice, setSelectedDevice] = useState(0);

  const [collection, setCollection] = useState([]);

  const [imageCollection, setImageCollection] = useState();
  const [imageCollectionDesktop, setImageCollectionDesktop] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingImageDesktop, setIsLoadingImageDesktop] = useState(false);

  const [showSelectProductsModal, setShowSelectProductsModal] = useState(false);
  const [selectedProductIds, setSelectedProductIds] = useState([]);

  const refUploadImage = useRef(null);
  const refUploadImageDesktop = useRef(null);

  const refInputTitle = useRef(null);
  const refInputDescription = useRef(null);

  useEffect(() => {
    async function load() {
      await api
        .get(`/collections/${component && component.id}`)
        .then(response => {
          setCollection(response.data);

          if (response.data && response.data.products) {
            setSelectedProductIds(response.data.products.map(p => p.id));
          }

          if (response.data.image) {
            const image = response.data.image.filter(i => !i.desktop);

            if (image && image.length > 0) {
              setImageCollection(image[0]);
            }

            const imageDesktop = response.data.image.filter(i => i.desktop);

            if (imageDesktop && imageDesktop.length > 0) {
              setImageCollectionDesktop(imageDesktop[0]);
            }
          }
        });
    }

    load();
  }, [component]);

  async function handleChangeImage(e, type) {
    try {
      const image = e.target.files[0];

      if (type === 'banner-desktop') {
        setIsLoadingImageDesktop(true);

        setImageCollectionDesktop({
          id: uniqueId('image_'),
          file: image,
          preview: URL.createObjectURL(image),
        });
      } else {
        setIsLoadingImage(true);
        setImageCollection({
          id: uniqueId('image_'),
          file: image,
          preview: URL.createObjectURL(image),
        });
      }
    } finally {
      setIsLoadingImage(false);
      setIsLoadingImageDesktop(false);
    }
  }

  async function deleteBanners(type) {
    if (type === 'banner-desktop') {
      if (imageCollectionDesktop.preview) {
        URL.revokeObjectURL(imageCollectionDesktop.preview);
      }

      setImageCollectionDesktop();
    } else {
      if (imageCollection.preview) {
        URL.revokeObjectURL(imageCollection.preview);
      }

      setImageCollection();
    }
  }

  function clearFields() {
    setSelectedDevice(0);
    setImageCollection();
    setImageCollectionDesktop();
    setCollection([]);
  }

  async function handleSubmit() {
    try {
      setIsLoading(true);

      if (!refInputTitle.current.value) {
        toast.error('Nome da coleção é obrigatório');
        return 0;
      }

      if (selectedProductIds.length === 0) {
        toast.error('Selecione no mínimo um produto');
        return 0;
      }

      const updatedCollection = await api.put(
        `/collections/${component && component.id}`,
        {
          title: refInputTitle.current.value,
          description: refInputDescription.current.value,
        }
      );

      await api.post(
        `/saveproductscollections/${collection && collection.id}`,
        {
          productIds: selectedProductIds,
        }
      );

      if (imageCollection) {
        if (imageCollection.file) {
          const data = new FormData();

          data.append('file', imageCollection.file, imageCollection.file.name);

          await api.post(
            `filecollectionscreate/${collection &&
              collection.id}?desktop=false`,
            data
          );
        }

        if (imageCollection && imageCollection.preview) {
          URL.revokeObjectURL(imageCollection.preview);
        }
      } else {
        await api.post(`filecollectionsdelete/${collection && collection.id}`, {
          desktop: false,
        });
      }

      if (imageCollectionDesktop) {
        if (imageCollectionDesktop.file) {
          const dataDesktop = new FormData();

          dataDesktop.append(
            'file',
            imageCollectionDesktop.file,
            imageCollectionDesktop.file.name
          );

          await api.post(
            `filecollectionscreate/${collection && collection.id}?desktop=true`,
            dataDesktop
          );
        }

        if (imageCollectionDesktop && imageCollectionDesktop.preview) {
          URL.revokeObjectURL(imageCollectionDesktop.preview);
        }
      } else {
        await api.post(`filecollectionsdelete/${collection && collection.id}`, {
          desktop: true,
        });
      }

      toast.success('Salvo');

      handleShowModalCollection(false, updatedCollection.data);
      clearFields();
    } catch (err) {
      toast.success('Ocorreu um erro ao salvar os banners');
    } finally {
      setIsLoading(false);
    }
  }

  function handleDeleteComponent(comp) {
    const c = comp;
    handleShowModalCollection(false);
    clearFields();
    handleDeleteCard(c);
  }

  function handleSelectedProducts(cancel, productIds) {
    setShowSelectProductsModal(false);

    if (cancel) {
      return 0;
    }

    setSelectedProductIds(productIds);
    return 0;
  }

  return (
    <>
      <Container>
        <div
          className="div-bg"
          onClick={() => handleShowModalCollection(false)}
        />
        <Div>
          <header>
            <strong>{component && component.PageComponent.title}</strong>

            {component && component.is_deleteable && (
              <button
                type="button"
                onClick={() => handleDeleteComponent(component)}
              >
                Excluir
              </button>
            )}
          </header>

          <div>
            <DivInput>
              <div>
                <Strong>Nome da coleção</Strong>
                <SpanLabel required>Obrigatório</SpanLabel>
              </div>

              <input
                type="text"
                placeholder="Insira o nome da coleção"
                ref={refInputTitle}
                defaultValue={collection && collection.title}
              />
            </DivInput>

            <DivInput>
              <div>
                <Strong>Descrição</Strong>
                <SpanLabel required={false}>Opcional</SpanLabel>
              </div>

              <input
                type="text"
                placeholder="Insira o descrição da coleção"
                ref={refInputDescription}
                defaultValue={collection && collection.description}
              />
            </DivInput>

            <DivSelectProducts>
              <div>
                <Strong>Selecionar produtos</Strong>
                <SpanLabel required>Obrigatório</SpanLabel>
              </div>

              <span>
                Selecione os produtos que irão fazer parte dessa coleção
              </span>

              <div className="div-select-products">
                <button
                  type="button"
                  onClick={() => setShowSelectProductsModal(true)}
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.5649 1.4178C8.69736 1.28534 8.85461 1.18027 9.02767 1.10858C9.20074 1.0369 9.38623 1 9.57355 1C9.76087 1 9.94636 1.0369 10.1194 1.10858C10.2925 1.18027 10.4497 1.28534 10.5822 1.4178C10.7147 1.55026 10.8197 1.70751 10.8914 1.88057C10.9631 2.05364 11 2.23913 11 2.42645C11 2.61377 10.9631 2.79926 10.8914 2.97233C10.8197 3.14539 10.7147 3.30265 10.5822 3.4351L3.7738 10.2435L1 11L1.75649 8.22621L8.5649 1.4178Z"
                      stroke="#0D85FF"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Selecionar produtos
                </button>

                <span>
                  {selectedProductIds.length > 1 &&
                    `${selectedProductIds.length} produtos selecionados`}

                  {selectedProductIds.length === 1 &&
                    `${selectedProductIds.length} produto selecionado`}

                  {selectedProductIds.length === 0 &&
                    'Nenhum produto selecionado'}
                </span>
              </div>
            </DivSelectProducts>

            <DivImage>
              <div className="div-label-image">
                <Strong>Imagem</Strong>
                <SpanLabel required={false}>Opcional</SpanLabel>
              </div>

              <DivDevice>
                <div>
                  <div
                    onClick={() => {
                      setSelectedDevice(0);
                    }}
                  >
                    <PhoneIcon
                      color={selectedDevice === 0 ? '#2b8ff5' : '#9d9d9d'}
                    />
                    <span className={selectedDevice === 0 ? 'selected' : ''}>
                      Celular/tablet
                    </span>
                  </div>

                  <div
                    onClick={() => {
                      setSelectedDevice(1);
                    }}
                  >
                    <DesktopIcon
                      color={selectedDevice === 1 ? '#2b8ff5' : '#9d9d9d'}
                    />
                    <span className={selectedDevice === 1 ? 'selected' : ''}>
                      Desktop
                    </span>
                  </div>
                </div>

                {selectedDevice === 0 ? (
                  <span>Tamanho recomendado: 500x500(largura x altura)</span>
                ) : (
                  <span>Tamanho recomendado: 1280x300(largura x altura)</span>
                )}
              </DivDevice>

              {selectedDevice === 0 ? (
                <DivBannerImages>
                  <button
                    type="button"
                    className="button-img"
                    onClick={() => refUploadImage.current.click()}
                  >
                    <img src={imgIcon} alt="img" />
                  </button>
                  <LabelImage htmlFor="image">
                    <input
                      type="file"
                      ref={refUploadImage}
                      id="image"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={e => handleChangeImage(e, 'banner')}
                      style={{ display: 'none' }}
                    />
                  </LabelImage>

                  <DivImages>
                    {imageCollection && (
                      <div>
                        <ImgPreview
                          image={imageCollection}
                          type="banner"
                          deleteImages={() => deleteBanners('banner')}
                          src={
                            imageCollection.preview ||
                            imageCollection.image_url ||
                            ''
                          }
                          alt=""
                          width="80px"
                          height="80px"
                          margin="0 0 0 0"
                        />
                      </div>
                    )}

                    {isLoadingImage && <img src={loaderGif} alt="loader" />}
                  </DivImages>
                </DivBannerImages>
              ) : (
                <DivBannerImages desktop>
                  <button
                    type="button"
                    className="button-img"
                    onClick={() => refUploadImageDesktop.current.click()}
                  >
                    <img src={imgIcon} alt="img" />
                  </button>
                  <LabelImage htmlFor="image">
                    <input
                      type="file"
                      ref={refUploadImageDesktop}
                      id="image"
                      className="custom-file-input"
                      accept="image/*"
                      onChange={e => handleChangeImage(e, 'banner-desktop')}
                      style={{ display: 'none' }}
                    />
                  </LabelImage>

                  <DivImages>
                    {imageCollectionDesktop && (
                      <div>
                        <ImgPreview
                          image={imageCollectionDesktop}
                          type="banner"
                          deleteImages={() => deleteBanners('banner-desktop')}
                          src={
                            imageCollectionDesktop.preview ||
                            imageCollectionDesktop.image_url ||
                            ''
                          }
                          alt=""
                          width="80px"
                          height="80px"
                          margin="0 0 0 0"
                        />
                      </div>
                    )}

                    {isLoadingImageDesktop && (
                      <img src={loaderGif} alt="loader" />
                    )}
                  </DivImages>
                </DivBannerImages>
              )}
            </DivImage>
          </div>

          <footer>
            <button type="button" onClick={handleSubmit} disabled={isLoading}>
              {isLoading ? <img src={loaderGrey} alt="loader" /> : 'Salvar'}
            </button>
          </footer>
        </Div>

        {showSelectProductsModal && (
          <SelectProductsModal
            handleSelectedProducts={handleSelectedProducts}
            selectedProductIds={selectedProductIds}
          />
        )}
      </Container>
    </>
  );
}

export default AddMainModal;

import styled, { css } from 'styled-components';

export const FontH1 = styled.h1`
  font-weight: 600;
  font-size: 28px;
  color: #202020;
  line-height: 1.25;
`;

export const FontH2 = styled.h2`
  font-weight: 600;
  font-size: 24px;
  color: #202020;
  line-height: 1.25;

  @media (max-width: 720px) {
    font-size: 24px;
  }
`;

export const FontH3 = styled.h3`
  font-size: 18px;
  color: #202020;
  font-weight: 600;
  line-height: 1.25;

  @media (max-width: 720px) {
    font-size: 18px;
  }
`;

export const FontH4 = styled.h4`
  font-size: 16px;
  color: #202020;
  font-weight: 600;
  line-height: 1.25;

  @media (max-width: 720px) {
    font-size: 16px;
  }
`;

export const FontH5 = styled.h5`
  font-size: 14px;
  color: #202020;
  font-weight: 600;
  line-height: 1.25;

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const FontDescription = styled.span`
  color: #838383;
  font-size: 12px;

  > a {
    color: #2b8ff5;
    font-weight: 500;
    text-decoration: none;
  }

  @media (max-width: 720px) {
    font-size: 12px;
  }
`;

export const FontSpan = styled.span`
  color: #838383;
  font-size: ${props => props.fontSize};

  ${props =>
    props.color &&
    css`
      color: ${props.color} !important;
    `}
`;

export const FontDivider = styled.div`
  height: 0.5px;
  /* border-top: 0.5px solid #d8dee5; */
  background: #d8dee5;
  margin: ${props => props.margin || '40px 0'};
`;

export const FontSpace = styled.div`
  height: ${props => props.height || '2px'};
  width: 100%;
`;

export const FLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.borderColor || '#0d85ff'};
  color: ${props => props.color || '#0d85ff'};
  background: ${props => props.background};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
  height: 21px;
`;

export const DivInputText = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > span {
    margin-top: 4px;
  }

  > input {
    margin-top: 8px;
  }
`;

export const DivInputTel = styled.div`
  display: flex;
  margin-top: 8px;

  > div {
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;
    background: #efefef;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #262626;
    }
  }

  > input {
    display: flex;
    flex: 1;
    width: 100%;
    border-radius: 0 8px 8px 0;
    margin-top: 0;
  }
`;

export const BButton = styled.button`
  width: 100%;
  max-width: 500px;
  background: #0d85ff;
  border: none;
  color: #ffffff;
  font-weight: 600;
  font-size: ${props => props.fontSize || '16px'};
  height: 50px;

  &:disabled {
    background: ${props => props.disabledColor || '#fafafa'};
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const BDivButton = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: ${props => props.fontSize || '14px'};
  border-radius: 8px;
  border: 1px solid #d8dee5;
  background: #fff;
  color: #838383;
  padding: 12px 20px;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      background: #0d85ff;
      color: #ffffff;
      font-weight: 500;
    `}
`;

export const BDivButtonTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: ${props => props.width || '80px'};

  > span {
    color: #9d9d9d;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    line-height: 1.5;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    ${props =>
      props.active &&
      css`
        color: #0d85ff;
      `}
  }
`;

export const BDivButtonText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: ${props => props.fontSize || '14px'};
  border-radius: 8px;
  border: 1px solid #d8dee5;
  background: #fff;
  width: ${props => props.width || '80px'};
  height: ${props => props.height || '50px'};
  font-family: ${props => props.fontFamily};
  font-weight: ${props => props.fontWeight};
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      color: #0d85ff;
      font-weight: 500;
    `}
`;

export const BSecundaryButton = styled.button`
  width: 100%;
  background: ${props =>
    props.hasBackground ? props.theme.button.text_color : 'none'};
  border: 1px solid
    ${props => props.borderColor || props.theme.button.background_color};
  font-family: ${props => `'${props.theme.text.paragraph_font_family}'`};
  color: ${props => props.color || props.theme.button.background_color};
  font-weight: 700;
  font-size: ${props => props.fontSize || '16px'};
  height: 50px;
  /* text-transform: uppercase; */

  ${props =>
    props.theme.button.border_style === 'rounded' &&
    css`
      border-radius: 8px;
    `}

  ${props =>
    props.theme.button.border_style === 'circle' &&
    css`
      border-radius: 50px;
    `}

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const BTextButton = styled.button`
  width: 100%;
  display: flex;
  color: ${props => props.color};
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  border: none;
  background: none;
  width: max-content;
`;

export const IInput = styled.input`
  height: 42px;
  background: #f9f9f9;
  padding: 0 12px;
  font-size: 13px;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #101010;
  margin-top: 8px;


  &::placeholder {
    color: #bfbfbf;
  }

  /* ${props =>
    props.red &&
    css`
      border: 1px solid #ff303c;
      background: rgba(255, 48, 60, 0.05);

      &::placeholder {
        color: #ff303c;
      }
    `} */
`;

export const DivInputWithTextButton = styled.div`
  display: flex;
  flex-direction: row;
  background: #f9f9f9;
  padding-right: 16px;
  align-items: center;
`;

export const InputButton = styled.button`
  display: contents;
  background: #f9f9f9;
  height: 42px;
  border: 1px solid #f9f9f9;
  align-self: center;
  font-weight: 600;
  font-size: 13px;
  color: ${props => props.colorButtonText || '#0d85ff'};
`;

export const IInputWithTextButton = styled.input`
  height: 42px;
  width: 100%;
  background: #f9f9f9;
  padding: 0 12px;
  font-size: 13px;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #101010;
  margin-top: 8px;

  &::placeholder {
    color: #bfbfbf;
  }
`;

export const ITextArea = styled.textarea`
  background: #f9f9f9;
  padding: 12px;
  font-size: 13px;
  margin-bottom: 5px;
  margin-top: 8px;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 8px;
  color: #101010;

  &::placeholder {
    color: #bfbfbf;
  }
`;

export const DCard = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 12px;
  border: 0.5px solid #d8dee5;
  background: #fff;
  padding: 20px;
  cursor: pointer;

  > img {
    position: absolute;
    right: 20px;
  }
`;

export const DSecundaryCard = styled.div`
  border-radius: 18px;
  background: #fafafa;
  padding: 20px;
  width: 100%;
  border: ${props => (props.border ? '0.5px solid #D8DEE5' : 'none')};
`;

export const BSeeMore = styled.button`
  border: none;
  background: none;
  color: #0d85ff;
  font-size: 14px;
  font-weight: 600;
  margin-top: 20px;
`;

export const BBack = styled.button`
  height: 42px;
  width: 42px;
  border: none;
  background: #efefef;
  border-radius: 50px;
`;

export const DTextWithIconList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  white-space: normal;

  > div {
    & + div {
      margin-top: 16px;
    }
  }
`;

export const DTextWithIconItem = styled.div`
  display: flex;
  align-items: center;

  > div {
    width: 20px;
    height: 20px;
  }

  > span {
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.color};
    margin-left: 8px;
  }
`;

import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

// import * as fixRotation from 'fix-image-rotation';
import { Container, Div, Bar, DivAvatar, DivLogo, DivButtons } from './styles';

import loadingGif from '../../../../assets/icons/loading.gif';
import penIcon from '../../../../assets/icons/penIcon.svg';
// import cancelIcon from '../../../../assets/icons/cancelx-white.svg';
// import userIcon from '../../../../assets/icons/userIcon.svg';
import noImage from '../../../../assets/icons/noimage.svg';

// import { resizeFile } from '../../../../services/functions';

import {
  saveImageRequest,
  removeImageRequest,
} from '../../../../store/modules/user/actions';

// import api from '../../services/api';

export default function CustomizeAvatar() {
  const dispatch = useDispatch();
  const refUploadAvatarInput = useRef(null);
  const [loadImage, setLoadImage] = useState(false);

  const { avatar } = useSelector(state => state.user.profile);
  const loadingAvatar = useSelector(state => state.user.profile.loadingAvatar);

  useEffect(() => {
    setTimeout(() => {
      setLoadImage(true);
    }, 3000);
  }, [avatar]);

  async function handleChangeImage(e, imageType) {
    if (e.target.files[0]) {
      // const convertedImage = await fixRotation.fixRotation(e.target.files[0]);
      // const image = await resizeFile(convertedImage, 100, 400);
      dispatch(
        saveImageRequest(e.target.files[0], imageType, '/customizeavatar')
      );
    }
  }

  async function handleClickDeleteImage(imageType) {
    dispatch(removeImageRequest(imageType));
  }

  const injectGA = () => {
    if (typeof window === 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    // gtag('config', 'AW-491873348');

    gtag('event', 'conversion', {
      send_to: 'AW-491873348/yP0lCIzd3NcCEMTIxeoB',
    });
  };

  return (
    <Container>
      <script>{injectGA()}</script>
      <Div>
        <span>Adicionar logo</span>
        <DivAvatar>
          <DivLogo hiddenBorder={avatar && avatar.url}>
            <img
              className="logo"
              src={
                loadingAvatar
                  ? loadingGif
                  : (avatar && avatar.image_url) || noImage
              }
              alt="logo"
            />
          </DivLogo>

          <button
            type="button"
            onClick={() => refUploadAvatarInput.current.click()}
          >
            <img src={penIcon} alt="pen-icon" />
          </button>

          <input
            type="file"
            ref={refUploadAvatarInput}
            accept="image/*"
            onChange={e => handleChangeImage(e, 'avatar')}
            style={{ display: 'none' }}
          />
        </DivAvatar>
        <Bar size="60%">-</Bar>
        <Bar size="100%">-</Bar>
        <Bar size="100%">-</Bar>

        <DivButtons>
          <Link to="/responsivecustomizesocialmedia">
            <button
              className="button-continue"
              type="button"
              disabled={!avatar}
            >
              Continuar
            </button>
          </Link>

          <Link to="/responsivecustomizesocialmedia">
            <button className="button-jump" type="button">
              Pular essa etapa
            </button>
          </Link>
        </DivButtons>
      </Div>
    </Container>
  );
}

import React, { useCallback } from 'react';

import { ContainerGeral, Container, Div, DivHeader } from './styles';

import cancelIcon from '../../../assets/icons/cancelx-black.svg';
import penIcon from '../../../assets/icons/penIcon-black.svg';
import trashIcon from '../../../assets/icons/trashIcon-red.svg';

export default function EditTagPanelResponsive({
  showModal,
  Modal,
  id,
  handleClickTagModal,
  handleClickDelete,
  label,
}) {
  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  function handleClickAlterTag() {
    handleClickTagModal(id);
  }

  function handleClickDeleteTag() {
    handleClickDelete(id);
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivHeader>
          <div className="div-space" />
          <div>
            <span>Alterar {label}</span>
          </div>

          <div className="div-space">
            <img src={cancelIcon} alt="cancel-icon" onClick={handleModal} />
          </div>
        </DivHeader>
        <div>
          <button type="button" onClick={handleClickAlterTag}>
            <img src={penIcon} alt="" />
            Editar {label}
          </button>
          {/* <button type="button">Adicionar produtos</button> */}
          <button
            className="btn-delete"
            type="button"
            onClick={handleClickDeleteTag}
          >
            <img src={trashIcon} alt="" />
            Excluir {label}
          </button>
        </div>
      </Div>
    </ContainerGeral>
  );
}

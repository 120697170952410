import React, { useState, useEffect } from 'react';
import { MainOffstorePayComponent } from '~/pages/Responsive/PaymentMethods/OffstorePayEdit/Components/MainOffstorePayComponent';
import Header from '../Header';
import * as S from './styles';

function ConfigurationOffstorePay({ handleClickBackButton }) {
  return (
    <S.Container>
      <Header
        title="Offstore Pay - Editar"
        handleClickBack={handleClickBackButton}
      />
      <S.Body>
        <MainOffstorePayComponent />
      </S.Body>
    </S.Container>
  );
}

export default ConfigurationOffstorePay;

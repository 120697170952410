import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 18px;
  padding: 0 4px;
  border-radius: 5.11px;
  background: linear-gradient(
    240deg,
    #ff6534 -2.8%,
    #ff0e9f 42.75%,
    #a925fa 75.34%
  );
  color: #fff;
  font-size: 10px;
  font-weight: 500;
  margin-left: 8px;
`;

import React from 'react';
import { DivWrapper, DiscountBox, Input } from './styles';

export default function DiscountInput({ maxWidth, marginTop, ...props }) {
  return (
    <DivWrapper maxWidth={maxWidth} marginTop={marginTop}>
      <DiscountBox>%</DiscountBox>
      <Input {...props} />
    </DivWrapper>
  );
}

/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import ResponsiveHeader from '../../../../components/HeaderMobile';

import { Container, Body } from './styles';

import { MainOffstorePayComponent } from './Components/MainOffstorePayComponent';

export function OffstorePayEdit() {
  return (
    <Container>
      <ResponsiveHeader
        title="Offstore Pay - Configurações"
        url="/paymentmethods"
      />
      <Body>
        <MainOffstorePayComponent />
      </Body>
    </Container>
  );
}

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
import Select from 'react-select';

import axios from 'axios';

import { toast } from 'react-toastify';

import IntlCurrencyInput from 'react-intl-currency-input';
import api from '../../../../../services/api';
import { formatPrice } from '../../../../../util/format';

import CancelIcon from '../../../../../assets/icons/cancelx-black.svg';
// import DeleteIcon from '../../../../../assets/icons/freight/delete.svg';

import Header from '../Header';

import {
  Container,
  Body,
  DivDeliveryPlace,
  Card,
  DivDeliveryName,
  DivValidLocales,
  DivMethod,
  Radio,
  DivSelectedCities,
  // DivInputCities,
  AddPlace,
  SeeMore,
  DivBackground,
  DivAddPlace,
  Div,
  DivPrice,
  DivDatePeriod,
} from './styles';

function ConfigurationCustomFreight({ handleClickBackButton }) {
  // const history = useHistory();

  const [radioSelected, setRadioSelected] = useState('all');
  const [radioDeliveryTimeSelected, setRadioDeliveryTimeSelected] = useState(
    'hours'
  );
  const [inputName, setInputName] = useState('');
  const [freight, setFreight] = useState([]);
  const [customFreights, setCustomFreights] = useState([]);
  // const [customFreightsCities, setCustomFreightsCities] = useState([]);
  const [seeMore, setSeeMore] = useState(false);
  const [seeMoreModal, setSeeMoreModal] = useState(false);

  const [placeId, setPlaceId] = useState(0);

  const [price, setPrice] = useState(0);
  const [placeName, setPlaceName] = useState('');
  const [inputPlaceFrom, setInputPlaceFrom] = useState('');
  const [inputPlaceUntil, setInputPlaceUntil] = useState('');

  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [showAddPlaceModal, setShowAddPlaceModal] = useState(false);

  const { uf } = useSelector(state => state.user.profile);

  function handleClickBack() {
    handleClickBackButton();
  }

  async function load() {
    const response = await api.get('/configfreight');

    if (response && response.data) {
      setFreight(response.data);
      setRadioSelected(response.data.custom_freight_valid_locations);
      setInputName(response.data.custom_freight_name_to_client);
    }

    const custom_freights = await api.get('/customfreight');
    setCustomFreights(custom_freights && custom_freights.data);

    await api.get('/customfreightscities').then(r => {
      if (r && r.data) {
        // setCustomFreightsCities(r.data);
        setSelectedCities(r.data);
      }
    });

    const searchCities = await axios.get(
      `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`
    );

    const newSearchCities =
      searchCities &&
      searchCities.data &&
      searchCities.data.map(s => {
        return {
          id: s.id,
          name: s.nome,
          label: s.nome,
        };
      });

    setCities(newSearchCities);
  }

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cleanFields = useCallback(() => {
    setPrice(0);
    setPlaceName('');
    setInputPlaceFrom('');
    setInputPlaceUntil('');
    setRadioDeliveryTimeSelected('hours');
  }, []);

  useEffect(() => {
    async function loadCustomFreight() {
      if (placeId > 0) {
        const response = await api.get(`/customfreightbyid/${placeId}`);

        if (response && response.data) {
          setPrice(response.data.price);
          setPlaceName(response.data.name);
          setInputPlaceFrom(response.data.delivery_time_from);
          setInputPlaceUntil(response.data.delivery_time_until);
          setRadioDeliveryTimeSelected(response.data.delivery_time_measure);

          if (
            response.data.delivery_time_from ||
            response.data.delivery_time_until
          ) {
            setSeeMoreModal(true);
          } else {
            setSeeMoreModal(false);
          }
        }
      } else {
        cleanFields();
      }
    }

    loadCustomFreight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeId]);

  async function handleSaveData(data) {
    try {
      await api.post('/updatefreight', data);
      toast.success('Dados salvos');
    } catch (err) {
      toast.error('Erro ao salvar informações');
    }
  }

  function saveValidLocations(value) {
    setRadioSelected(value);

    const data = {
      custom_freight_valid_locations: value,
    };

    handleSaveData(data);
  }

  function saveTimeMeasure(value) {
    setRadioDeliveryTimeSelected(value);

    // const data = {
    //   delivery_time_measure: value,
    // };

    // handleSaveData(data);
  }

  function saveDeliveryName() {
    const data = {
      custom_freight_name_to_client: inputName,
    };

    handleSaveData(data);
  }

  const handleChangePrice = (event, value, maskedValue) => {
    setPrice(value);
    event.preventDefault();

    // console.log(value); // value without mask (ex: 1234.56)
    // console.log(maskedValue); // masked value (ex: R$1234,56)
  };

  async function handleDeletePlace(id) {
    try {
      await api.delete(`/customfreight/${id}`);

      setShowAddPlaceModal(false);
      toast.success('Local removido');
      setPlaceId(0);
      load();
    } catch (err) {
      toast.error('Erro ao remover local');
    }
  }

  async function handleSaveCustomFreight() {
    try {
      if (!placeName) {
        toast.error('Informe o nome do local');
        return 0;
      }

      // if (!price) {
      //   toast.error('Informe o preço');
      //   return 0;
      // }

      const data = {
        freight_id: freight && freight.id,
        name: placeName,
        price,
        delivery_time_measure: radioDeliveryTimeSelected,
        delivery_time_from: inputPlaceFrom || null,
        delivery_time_until: inputPlaceUntil || null,
      };

      if (placeId > 0) {
        await api.put(`/customfreight/${placeId}`, data);
      } else {
        await api.post('/customfreight', data);
      }

      setShowAddPlaceModal(false);
      toast.success('Local adicionado');
      cleanFields();
      load();
    } catch (err) {
      toast.error('Erro ao salvar informações');
    }
  }

  async function onChangeCheck(id, value) {
    await api.put(`/customfreight/${id}`, { active: value });
  }

  function handleShowAddOrEditPlace(id) {
    setPlaceId(id);
    setShowAddPlaceModal(true);
  }

  async function handleAddCity(e) {
    const newCity = await api.post('/customfreightscities', {
      name: e.name,
      freight_id: freight.id,
    });

    // setCustomFreightsCities([...customFreightsCities, newCity.data]);
    setSelectedCities([...selectedCities, newCity.data]);

    return 0;
  }

  async function handleDeleteCity(id) {
    await api.delete(`/customfreightscities/${id}`);

    // const newCustomFreightsCities = customFreightsCities.filter(
    //   cfc => cfc.id !== id
    // );

    const newCustomSelectedsCities = selectedCities.filter(
      cfc => cfc.id !== id
    );

    // setCustomFreightsCities(newCustomFreightsCities);
    setSelectedCities(newCustomSelectedsCities);
  }

  // async function handleChangeCity(id, value) {
  //   if (!value) {
  //     handleDeleteCity(id);
  //   }

  //   await api.put(`/customfreightscities/${id}`, {
  //     name: value,
  //     freight_id: freight.id,
  //   });
  // }

  return (
    <Container>
      <Header title="Frete personalizado" handleClickBack={handleClickBack} />

      <Body>
        <DivDeliveryPlace>
          <strong>Locais de entrega</strong>
          <span>
            Adicione locais como bairros, zonas ou cidades e defina a taxa de
            entrega para esses locais.
          </span>

          <button type="button" onClick={() => handleShowAddOrEditPlace(0)}>
            Adicionar local
          </button>
        </DivDeliveryPlace>

        {customFreights &&
          customFreights.map(cf => (
            <Card key={cf.id}>
              <div className="left">
                <span>{cf.name}</span>
                <span className="price">{formatPrice(cf.price)}</span>
                {/* {(activeCustomFreight && ( */}
                {/* <SpanStatus color="#2B8FF5">Ativo</SpanStatus> */}
                {/* )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>} */}
              </div>

              <div className="right">
                <button
                  type="button"
                  onClick={() => handleShowAddOrEditPlace(cf.id)}
                >
                  Editar
                </button>
                <label className="switch">
                  <input
                    type="checkbox"
                    defaultChecked={cf.active}
                    onChange={e => onChangeCheck(cf.id, e.target.checked)}
                  />
                  <span className="slider round" />
                </label>
              </div>
            </Card>
          ))}

        {seeMore && (
          <>
            <DivDeliveryName>
              <strong>Nome da entrega</strong>

              <span>
                Nome que irá aparecer para o cliente, por exemplo: “Motoboy”
              </span>

              <input
                type="text"
                placeholder="Ex: “Motoboy”"
                value={inputName}
                onChange={e => setInputName(e.target.value)}
                onBlur={saveDeliveryName}
              />
            </DivDeliveryName>

            <DivValidLocales>
              <strong>Locais válidos</strong>
              <span>
                Em quais locais essas opções de entrega deverão ser exibidas?
              </span>

              <DivMethod>
                <Radio>
                  <div
                    className={radioSelected === 'all' && 'selected'}
                    onClick={() => saveValidLocations('all')}
                  >
                    <div />
                  </div>
                  <span>Todo o país</span>
                </Radio>

                <Radio>
                  <div
                    className={radioSelected === 'just_my_state' && 'selected'}
                    onClick={() => saveValidLocations('just_my_state')}
                  >
                    <div />
                  </div>
                  <span>Apenas em meu estado</span>
                </Radio>

                <Radio>
                  <div
                    className={
                      radioSelected === 'just_some_cities' && 'selected'
                    }
                    onClick={() => saveValidLocations('just_some_cities')}
                  >
                    <div />
                  </div>
                  <span>Apenas em algumas cidades</span>
                </Radio>
              </DivMethod>

              {radioSelected === 'just_some_cities' && (
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  name="color"
                  options={cities}
                  value={null}
                  onChange={handleAddCity}
                />
              )}

              {/* <span>
                Insira o nome exato da cidade, por exemplo, se for “São Paulo”,
                digite exatamente “São Paulo”
              </span> */}
            </DivValidLocales>

            {radioSelected === 'just_some_cities' && (
              <DivSelectedCities>
                {selectedCities &&
                  selectedCities.map(sc => (
                    <div key={sc.id}>
                      {sc.name}

                      <div onClick={() => handleDeleteCity(sc.id)}>
                        <svg
                          width="10"
                          height="10"
                          viewBox="0 0 10 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M1 8.99976L8.99987 0.999886"
                            stroke="#9D9D9D"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M9 8.99976L1.00013 0.999886"
                            stroke="#9D9D9D"
                            strokeWidth="1.25"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </div>
                  ))}
              </DivSelectedCities>

              // <DivInputCities>
              //   {customFreightsCities &&
              //     customFreightsCities.map(cfc => (
              //       <div key={cfc.id}>
              //         <input
              //           type="text"
              //           placeholder="Ex: São Paulo"
              //           defaultValue={cfc.name}
              //           onBlur={e => handleChangeCity(cfc.id, e.target.value)}
              //         />

              //         <div onClick={() => handleDeleteCity(cfc.id)}>
              //           <img src={DeleteIcon} alt="delete" />
              //         </div>
              //       </div>
              //     ))}

              //   <button type="button" onClick={handleAddCity}>
              //     + Adicionar cidade
              //   </button>
              // </DivInputCities>
            )}
          </>
        )}

        <SeeMore onClick={() => setSeeMore(!seeMore)}>
          {seeMore ? 'Menos opções' : 'Mais opções'}
        </SeeMore>
      </Body>

      {showAddPlaceModal && (
        <AddPlace>
          <DivBackground onClick={() => setShowAddPlaceModal(false)} />
          <DivAddPlace>
            <div className="header">
              <strong>Adicionar local</strong>

              {(placeId > 0 && (
                <button
                  type="button"
                  onClick={() => handleDeletePlace(placeId)}
                >
                  Excluir
                </button>
              )) || (
                <img
                  src={CancelIcon}
                  alt="cancel"
                  onClick={() => setShowAddPlaceModal(false)}
                />
              )}
            </div>

            <div className="body">
              <Div>
                <div>
                  <strong>Nome do local</strong>
                  <span className="required">Obrigatório</span>
                </div>
              </Div>

              <input
                type="text"
                placeholder="Ex: “Zona sul de São Paulo”"
                value={placeName}
                onChange={e => setPlaceName(e.target.value)}
              />

              <Div>
                <div>
                  <strong>Preço</strong>
                  <span className="required">Obrigatório</span>
                </div>

                <span>Defina a taxa de entrega para esse local</span>
              </Div>

              <DivPrice>
                <div>R$</div>
                <IntlCurrencyInput
                  currency="BRL"
                  className="currency-input"
                  config={{
                    locale: 'pt-BR',
                    formats: {
                      number: {
                        BRL: {
                          // style: 'currency',
                          currency: 'BRL',
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      },
                    },
                  }}
                  value={price}
                  onChange={handleChangePrice}
                />
              </DivPrice>

              {seeMoreModal && (
                <>
                  <Div marginTop="24px">
                    <div>
                      <strong>Prazo de entrega</strong>
                      <span>Opcional</span>
                    </div>

                    <span>
                      Defina o prazo de entrega, ou deixe em branco para não
                      exibir
                    </span>
                  </Div>

                  <DivMethod>
                    <Radio>
                      <div
                        className={
                          radioDeliveryTimeSelected === 'minutes' && 'selected'
                        }
                        onClick={() => saveTimeMeasure('minutes')}
                      >
                        <div />
                      </div>
                      <span>Minutos</span>
                    </Radio>

                    <Radio>
                      <div
                        className={
                          radioDeliveryTimeSelected === 'hours' && 'selected'
                        }
                        onClick={() => saveTimeMeasure('hours')}
                      >
                        <div />
                      </div>
                      <span>Horas</span>
                    </Radio>

                    <Radio>
                      <div
                        className={
                          radioDeliveryTimeSelected === 'days' && 'selected'
                        }
                        onClick={() => saveTimeMeasure('days')}
                      >
                        <div />
                      </div>
                      <span>Dias</span>
                    </Radio>
                  </DivMethod>

                  <DivDatePeriod>
                    <span>de</span>
                    <input
                      type="text"
                      value={inputPlaceFrom}
                      onChange={e => setInputPlaceFrom(e.target.value)}
                    />
                    <span>até</span>
                    <input
                      type="text"
                      value={inputPlaceUntil}
                      onChange={e => setInputPlaceUntil(e.target.value)}
                    />

                    {radioDeliveryTimeSelected === 'minutes' && (
                      <span>minutos</span>
                    )}

                    {radioDeliveryTimeSelected === 'hours' && (
                      <span>horas</span>
                    )}

                    {radioDeliveryTimeSelected === 'days' && <span>dias</span>}
                  </DivDatePeriod>
                </>
              )}

              <SeeMore onClick={() => setSeeMoreModal(!seeMoreModal)}>
                {seeMoreModal ? 'Menos opções' : 'Mais opções'}
              </SeeMore>
            </div>

            <footer>
              <button type="button" onClick={handleSaveCustomFreight}>
                Adicionar
              </button>
            </footer>
          </DivAddPlace>
        </AddPlace>
      )}
    </Container>
  );
}

export default ConfigurationCustomFreight;

import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const ButtonRepayment = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  background: #efefef;
  height: 44px;
  width: 200px;
  font-size: 13px;
  color: #101010;
  font-weight: 500;
  margin-top: 16px;

  > svg {
    margin-left: 8px;
  }
`;

export const Title = styled.h1`
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`;

export const Subtitle = styled.span`
  color: var(--Blue-details, #0d85ff);
  text-align: right;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 16px; */
  width: 100%;
`;

export const DivStatus = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 8px;

  > span {
    margin-top: 2px;
  }
`;

export const DivDate = styled.div`
  display: flex;
  height: 33px;
`;

export const StatusTitle = styled.strong`
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: ${props =>
    props.status === 'Pagamento aprovado' ? '#06C86B' : '#9d9d9d'};
`;

export const StatusDescription = styled.span`
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: ${props =>
    props.status === 'Pagamento aprovado' ? '#06C86B' : '#9d9d9d'};
`;

export const StatusDot = styled.div`
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background-color: ${props =>
    props.status === 'Pagamento aprovado' ? '#06C86B' : '#D8DEE5'};
  border-radius: 50%;
`;
export const StatusLineWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  align-items: baseline;
  /* gap: 5px; */
`;

export const LineStroke = styled.div`
  width: 1px;
  background: #d8dee5;
  height: 35px;
  margin-left: 2.5px;
  margin-top: -30px;
  margin-bottom: 5px;
`;

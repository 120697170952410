import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
`;

export const DivBody = styled.div`
  /* margin-top: 55px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 55px 16px 0 16px;
  min-height: 100vh;

  > h2 {
    margin-top: 40px;
  }

  > span {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    margin-top: 8px;
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;

  > div {
    & + div {
      margin-top: 8px;
    }
  }
`;

export const SelectButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
  border-radius: 8px;
  background: #0d85ff;
  width: 100px;
  height: 32px;
  /* padding: 8px 16px; */
  border: none;
  color: #fff;
  font-size: 13px;
  font-weight: 600;

  &:disabled {
    background: #fafafa;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  border-radius: 12px;
  border: 0.5px solid #d8dee5;
  background: #fff;
  padding: 20px;

  > img {
    position: absolute;
    right: 20px;
  }
`;

export const DivImage = styled.div``;

export const DivBodyCard = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  max-width: 50%;

  > strong {
    color: #262626;
    font-size: 16px;
    font-weight: 600;
  }

  > span {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }

  .green {
    color: #06c86b;
    margin-top: 8px;
  }
`;

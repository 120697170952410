import styled from 'styled-components';
import { device } from '../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const Div = styled.div`
  width: 440px;
  /* height: 310px; */
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (310px / 2));
  left: calc(50vw - (440px / 2));
  padding: 32px 20px;

  @media ${device.mobile} {
    width: 90%;
    left: auto;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  /* padding: 32px 24px 0 24px; */
  /* height: 60px; */

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    z-index: 500;

    span {
      text-align: center;
      color: #262626;
      font-size: 16px;
      font-weight: 500;
    }

    img {
      cursor: pointer;
    }
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* padding: 0 24px; */

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 138.1%;
    color: #000000;
    max-width: 290px;
    margin-top: -18px;
    margin-bottom: 8px;
  }

  > div {
    display: flex;
    flex-direction: column;

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 18px;
      color: #838383;
      margin-bottom: 24px;
    }

    > div {
      display: flex;
      align-items: center;
      width: 100%;
      line-height: 2;
      background: #fafafa;
      border-radius: 8px;
      padding-left: 12px;

      > span {
        margin-right: 6px;
        -webkit-text-decoration: none;
        text-decoration: none;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 13px;
        color: #2b8ff5;
        flex: 1;
      }

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 45px;
        padding: 0 16px;
        background: #efefef;
        border-radius: 8px;
        border: none;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        text-align: center;
        color: #101010;

        > img {
          margin-right: 4px;
        }

        > span {
          margin-top: -1px;
        }
      }
    }
  }
`;

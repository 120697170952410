import React from 'react';

// import { Container } from './styles';

function InstagramIcon({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5 1H5.5C3.01472 1 1 3.01472 1 5.5V14.5C1 16.9853 3.01472 19 5.5 19H14.5C16.9853 19 19 16.9853 19 14.5V5.5C19 3.01472 16.9853 1 14.5 1Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6 9.433C13.7111 10.182 13.5832 10.947 13.2344 11.6191C12.8857 12.2912 12.3339 12.8363 11.6575 13.1767C10.9811 13.5171 10.2146 13.6356 9.46704 13.5153C8.71944 13.395 8.02881 13.0421 7.49338 12.5066C6.95795 11.9712 6.60499 11.2806 6.48469 10.533C6.36439 9.78539 6.48289 9.0189 6.82332 8.34253C7.16376 7.66617 7.7088 7.11436 8.38092 6.76561C9.05303 6.41686 9.81801 6.28893 10.567 6.4C11.3311 6.51329 12.0384 6.86931 12.5846 7.41547C13.1307 7.96163 13.4867 8.66897 13.6 9.433Z"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.95 5.05H14.96"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InstagramIcon;

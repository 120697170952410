import React from 'react';

// import { Container } from './styles';

function ProfileIcon({ color }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.79398 17.0041C6.6759 19.136 3.07655 19.842 1 17.7101C3.76873 14.8445 1 13.4601 3.07655 11.3835C3.44117 10.9774 3.88479 10.6498 4.38028 10.4209C4.87578 10.192 5.41275 10.0665 5.95838 10.0522C6.50402 10.0378 7.04684 10.1349 7.5537 10.3374C8.06055 10.5399 8.52078 10.8437 8.90626 11.2301C9.29174 11.6165 9.59438 12.0775 9.79567 12.5849C9.99697 13.0922 10.0927 13.6353 10.077 14.1809C10.0613 14.7265 9.93453 15.2631 9.70441 15.7581C9.47429 16.253 9.14566 16.6958 8.7386 17.0594L8.79398 17.0041Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1943 1.80465C17.9258 1.53787 17.6055 1.32899 17.2531 1.19088C16.9007 1.05278 16.5237 0.9884 16.1455 1.00171C15.7675 1.01199 15.3956 1.09959 15.0527 1.25911C14.7099 1.41863 14.4033 1.6467 14.152 1.92924L6.7041 10.2354C7.50276 10.3936 8.23294 10.7949 8.79449 11.3845C9.29956 11.8875 9.66665 12.512 9.86045 13.198L18.0697 5.84699C18.3506 5.59725 18.5777 5.29294 18.7372 4.95259C18.8966 4.61225 18.9851 4.243 18.9973 3.86735C19.0125 3.48679 18.949 3.10719 18.8109 2.75227C18.6727 2.39735 18.4628 2.07475 18.1943 1.80465V1.80465Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProfileIcon;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

export const ModalWrapper = styled.div`
  width: 600px;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  height: 80vh;
  overflow: auto;
`;

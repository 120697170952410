import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { toast } from 'react-toastify';
import ResponsiveHeader from '../../../../components/HeaderMobile';

import dropdownIcon from '../../../../assets/icons/freight/dropdown.svg';

import api from '../../../../services/api';

import { updateFreightRequest } from '../../../../store/modules/freight/actions';

import {
  Container,
  Body,
  DivOrigin,
  DivCards,
  Card,
  SpanStatus,
  DivWarning,
  DivExcludeCity,
  DivDimensions,
  DivTextDimensions,
  DivHideButton,
} from './styles';

function ConfigurationCorreios() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [activeExcludeCity, setActiveExcludeCity] = useState(false);
  // const [cep, setCep] = useState();
  const [weight, setWeight] = useState();
  const [length, setLength] = useState();
  const [height, setHeight] = useState();
  const [width, setWidth] = useState();
  const [valueMoreDays, setValueMoreDays] = useState(0);
  const [hide, setHide] = useState(true);

  const [activePac, setActivePac] = useState(false);
  const [activeSedex, setActiveSedex] = useState(false);
  const [activeMiniEnvios, setActiveMiniEnvios] = useState(false);
  const [activeMelhorEnvio, setActiveMelhorEnvio] = useState(false);
  const [hasCepPacSedex, setHasCepPacSedex] = useState(false);

  const freight = useSelector(state => state.freight.freight);

  const { cep } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function load() {
      const response = await api.get('/configfreight');

      if (response && response.data) {
        setWeight(response.data.weight);
        setLength(response.data.length);
        setHeight(response.data.height);
        setWidth(response.data.width);
        setActiveExcludeCity(response.data.exclude_my_city);
        setValueMoreDays(response.data.more_days);

        setActivePac(response.data.pac);
        setActiveSedex(response.data.sedex);
        setActiveMiniEnvios(response.data.mini_envios);

        if (response.data.cep_origin_pac_sedex) {
          setHasCepPacSedex(true);
          // setCep(response.data.cep_origin_pac_sedex);
        } else {
          setHasCepPacSedex(false);
        }
      }

      await api.get('/melhorenvio').then(async me => {
        setActiveMelhorEnvio(me.data && me.data.active);
      });
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSaveData(data) {
    try {
      await api.post('/updatefreight', data);
      toast.success('Dados salvos');
    } catch (err) {
      toast.error('Erro ao salvar informações');
    }
  }

  // function saveCep() {
  //   if (cep && cep.length < 8) {
  //     toast.error('Preencha um cep válido para salvar as alterações');
  //     return 0;
  //   }

  //   const data = {
  //     cep_origin_pac_sedex: cep,
  //   };

  //   handleSaveData(data);

  //   return 0;
  // }

  function saveExcludeMyCity(checked) {
    const data = {
      exclude_my_city: checked,
    };

    handleSaveData(data);
  }

  function saveWeight() {
    if (weight > 30) {
      toast.error('Peso máximo permitido: 30kg');
      return 0;
    }

    const data = {
      weight: weight || null,
    };

    handleSaveData(data);
  }

  function saveLength() {
    if (length > 100) {
      toast.error('Comprimento máximo permitido: 100cm');
      return 0;
    }

    const data = {
      length: length || null,
    };

    handleSaveData(data);
  }

  function saveHeight() {
    if (height > 100) {
      toast.error('Altura máxima permitida: 100cm');
      return 0;
    }

    const data = {
      height: height || null,
    };

    handleSaveData(data);
  }

  function saveWidth() {
    if (width > 100) {
      toast.error('Largura máxima permitida: 100cm');
      return 0;
    }

    const data = {
      width: width || null,
    };

    handleSaveData(data);
  }

  function saveMoreDays(selected) {
    setValueMoreDays(selected);

    const data = {
      more_days: selected,
    };

    handleSaveData(data);
  }

  const onChangeActiveExcludeCity = useCallback(
    e => {
      setActiveExcludeCity(e.target.checked);
      saveExcludeMyCity(e.target.checked);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  function onChangeActivePac(value) {
    setActivePac(value);

    if (
      freight.flatrate === false &&
      freight.sedex === false &&
      freight.free === false &&
      freight.custom_freight === false &&
      activeMelhorEnvio === false &&
      freight.mini_envios === false &&
      value === false
    ) {
      dispatch(updateFreightRequest('PAC', value, true));
    } else {
      dispatch(updateFreightRequest('PAC', value, false));
    }
  }

  function onChangeActiveSedex(value) {
    setActiveSedex(value);

    if (
      freight.flatrate === false &&
      freight.pac === false &&
      freight.free === false &&
      activeMelhorEnvio === false &&
      freight.custom_freight === false &&
      freight.mini_envios === false &&
      value === false
    ) {
      dispatch(updateFreightRequest('SEDEX', value, true));
    } else {
      dispatch(updateFreightRequest('SEDEX', value, false));
    }
  }

  function onChangeActiveMiniEnvios(value) {
    setActiveMiniEnvios(value);

    if (
      freight.flatrate === false &&
      freight.pac === false &&
      freight.sedex === false &&
      freight.free === false &&
      activeMelhorEnvio === false &&
      freight.custom_freight === false &&
      value === false
    ) {
      dispatch(updateFreightRequest('MINI_ENVIOS', value, true));
    } else {
      dispatch(updateFreightRequest('MINI_ENVIOS', value, false));
    }
  }

  return (
    <Container>
      <ResponsiveHeader title="Correios" url="/freight/configuration" />
      <Body>
        {/* <DivOrigin>
          <div>
            <strong>CEP de origem</strong>
            <span>Obrigatório</span>
          </div>

          <span>
            Digite o CEP de onde suas entregas partirão, por exemplo, o CEP da
            sua loja ou da agência dos Correios da sua cidade
          </span>
          <input
            type="number"
            placeholder="00000 000"
            value={cep}
            onChange={e => setCep(e.target.value)}
            onBlur={saveCep}
          />
        </DivOrigin> */}
        <span>Seus custos de envio serão calculados a partir do CEP {cep}</span>
        <DivCards>
          <strong>Selecione as formas de entrega</strong>

          <Card
            switchColorActive={
              (activePac && !hasCepPacSedex) || (activePac && activeMelhorEnvio)
                ? '#FFB525'
                : '#2B8FF5'
            }
          >
            <div className="left">
              <span>PAC</span>
              {/* {activePac && hasCepPacSedex && (
                <>
                  {activeMelhorEnvio ? (
                    <SpanStatus color="#FFB525">
                      Ativo no Melhor Envio
                    </SpanStatus>
                  ) : (
                    <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                  )}
                </>
              )}

              {activePac && !hasCepPacSedex && (
                <SpanStatus color="#FFB525">Não configurado</SpanStatus>
              )}

              {!activePac && (
                <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
              )} */}
            </div>

            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activePac}
                  onChange={e => onChangeActivePac(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>

          <Card
            switchColorActive={
              (activeSedex && !hasCepPacSedex) ||
              (activeSedex && activeMelhorEnvio)
                ? '#FFB525'
                : '#2B8FF5'
            }
          >
            <div className="left">
              <span>SEDEX</span>
              {/* {activeSedex && hasCepPacSedex && (
                <>
                  {activeMelhorEnvio ? (
                    <SpanStatus color="#FFB525">
                      Ativo no Melhor Envio
                    </SpanStatus>
                  ) : (
                    <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                  )}
                </>
              )}

              {activeSedex && !hasCepPacSedex && (
                <SpanStatus color="#FFB525">Não configurado</SpanStatus>
              )}

              {!activeSedex && (
                <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
              )} */}
            </div>

            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeSedex}
                  onChange={e => onChangeActiveSedex(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>

          <Card
            switchColorActive={
              (activeMiniEnvios && !hasCepPacSedex) ||
              (activeMiniEnvios && activeMelhorEnvio)
                ? '#FFB525'
                : '#2B8FF5'
            }
          >
            <div className="left">
              <span>Mini envios</span>
            </div>

            <div className="right">
              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeMiniEnvios}
                  onChange={e => onChangeActiveMiniEnvios(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </div>
          </Card>
        </DivCards>
        <DivWarning color="red">
          <span>
            <b>Lembre-se: </b>
            Produtos que não tiverem informações de peso e dimensões
            <b> não</b> poderão ter o frete calculado.
          </span>
        </DivWarning>

        <DivOrigin margin="32px 0 0 0">
          <div>
            <strong>
              Peso e dimensões padrão <span className="optional">Opcional</span>
            </strong>
          </div>

          <span>
            Insira abaixo um peso e dimensões padrão, que pode ser usado caso
            algum produto seu esteja sem informação de peso ou dimensões
          </span>
        </DivOrigin>
        <DivDimensions weight={weight}>
          <div>
            <strong>Peso</strong>
            <div>
              <input
                type="number"
                placeholder="kg"
                value={weight}
                onChange={e => setWeight(e.target.value)}
                onBlur={saveWeight}
              />
              <div>kg</div>
            </div>

            {weight && weight >= 1 && <span>{weight} quilo(s)</span>}

            {weight && weight < 1 && <span>{weight * 1000} grama(s)</span>}
          </div>

          <div>
            <strong>Comprim...</strong>

            <div>
              <input
                type="number"
                placeholder="cm"
                value={length}
                onChange={e => setLength(e.target.value)}
                onBlur={saveLength}
              />
              <div>cm</div>
            </div>
          </div>

          <div>
            <strong>Altura</strong>

            <div>
              <input
                type="number"
                placeholder="cm"
                value={height}
                onChange={e => setHeight(e.target.value)}
                onBlur={saveHeight}
              />
              <div>cm</div>
            </div>
          </div>

          <div>
            <strong>Largura</strong>

            <div>
              <input
                type="number"
                placeholder="cm"
                value={width}
                onChange={e => setWidth(e.target.value)}
                onBlur={saveWidth}
              />
              <div>cm</div>
            </div>
          </div>
        </DivDimensions>

        <DivWarning color="red">
          <span>
            O peso é adicionado em quilos, ou seja, se você quiser adicionar o
            peso de 100 gramas, deve inserir o valor “0,100”
          </span>
        </DivWarning>

        <DivWarning color="red">
          <span>
            O ideal é que você insira o peso e as dimensões do produto e não da
            embalagem, pois caso tenha mais de 1 produto no carrinho, o peso e
            as dimensões serão somados
          </span>
        </DivWarning>

        {!hide && (
          <>
            <DivExcludeCity>
              <strong>Desabilitar PAC/Sedex para minha cidade</strong>

              <label className="switch">
                <input
                  type="checkbox"
                  checked={activeExcludeCity}
                  onChange={onChangeActiveExcludeCity}
                />
                <span className="slider round" />
              </label>
            </DivExcludeCity>

            <DivTextDimensions>
              <strong>Tempo adicional de entrega</strong>
              <span>
                Quanto tempo você leva para preparar e postar seus produtos em
                uma agência dos corrreios? Adicione esse tempo adicional abaixo.
              </span>
              <div>
                <select
                  name="select"
                  value={valueMoreDays}
                  onChange={e => saveMoreDays(e.target.value)}
                >
                  <option value="0">0 dias</option>
                  <option value="1">1 dia</option>
                  <option value="2">2 dias</option>
                  <option value="3">3 dias</option>
                  <option value="4">4 dias</option>
                  <option value="5">5 dias</option>
                  <option value="6">6 dias</option>
                  <option value="7">7 dias</option>
                  <option value="8">8 dias</option>
                  <option value="9">9 dias</option>
                  <option value="10">10 dias</option>
                  <option value="11">11 dias</option>
                  <option value="12">12 dia</option>
                  <option value="13">13 dias</option>
                  <option value="14">14 dias</option>
                  <option value="15">15 dias</option>
                  <option value="16">16 dias</option>
                  <option value="17">17 dias</option>
                  <option value="18">18 dias</option>
                  <option value="19">19 dias</option>
                  <option value="20">20 dias</option>
                  <option value="21">21 dia</option>
                  <option value="22">22 dias</option>
                  <option value="23">23 dias</option>
                  <option value="24">24 dias</option>
                  <option value="25">25 dias</option>
                  <option value="26">26 dias</option>
                  <option value="27">27 dias</option>
                  <option value="28">28 dias</option>
                  <option value="29">29 dias</option>
                  <option value="30">30 dias</option>
                </select>
                <img src={dropdownIcon} alt="drop" />
              </div>
            </DivTextDimensions>
          </>
        )}
        <DivHideButton>
          {hide ? (
            <button type="button" onClick={() => setHide(false)}>
              Mais opções
            </button>
          ) : (
            <button type="button" onClick={() => setHide(true)}>
              Menos opções
            </button>
          )}
        </DivHideButton>
      </Body>
    </Container>
  );
}

export default ConfigurationCorreios;

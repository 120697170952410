import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  /* min-height: 100vh; */

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
  }
`;

export const Header = styled.h1`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Description = styled.span`
  color: var(--Body-Font, #838383);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 4px;
`;

export const ContainerInput = styled.div`
  margin-top: 32px;
`;

export const ContainerReceipt = styled.div`
  margin: 32px 0;
  display: flex;
  flex-direction: column;
`;

export const TextArea = styled.textarea`
  width: 100%;
  height: 84px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f9f9f9;
  border: none;
  margin-top: 8px;
  padding: 12px;
`;

export const Span = styled.span`
  color: var(--Body-Font, #838383);
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  background: #0d85ff;
  width: 100%;
  color: #ffffff;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 17px;
  border-width: 0px;
  margin-top: 32px;
`;

export const DivAttachment = styled.div`
  display: flex;
  flex-direction: column;

  .documents-length {
    color: var(--Body-Font, #838383);
    font-size: 13px;
    font-weight: 400;
    margin-top: 12px;
    margin-bottom: 8px;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #efefef;
    border: none;
    height: 36px;
    width: 156px;
    color: #000;
    font-size: 13px;
    font-weight: 500;
    margin-top: 12px;

    > svg {
      margin-right: 8px;
    }
  }
`;

export const LabelImage = styled.label`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;

export const DivDocuments = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 4px;
  }

  > span {
    color: var(--Blue-details, #0d85ff);
    font-size: 13px;
    font-weight: 600;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 20px;
    border-radius: 2px;
    border: none;
    background: rgba(255, 48, 60, 0.06);
    color: #ff303c;
    margin-left: 8px;
  }
`;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import { FiTrash2 } from 'react-icons/fi';
// import history from '../../../services/history';

import trashIcon from '../../../../assets/icons/trashIcon.svg';

import { Container, DivPromotions, Card } from './styles';

import PromotionModal from '../PromotionModal';

import { removePromotionRequest } from '../../../../store/modules/linkPrev/actions';

function TabPromotion() {
  const [idPromotion, setIdPromotion] = useState(0);
  const [showPromotionModal, setShowPromotionModal] = useState(false);

  const { promotions } = useSelector(state => state.linkPrev);

  const history = useHistory();

  const dispatch = useDispatch();

  function handleClickAlterPromotion(id) {
    // history.push(`/promotionregister/${id}`);
  }

  function promotionModal(modal) {
    setShowPromotionModal(modal);
  }

  function handleClickPromotion(id) {
    setIdPromotion(id);
    setShowPromotionModal(!showPromotionModal);
  }

  return (
    <>
      <PromotionModal
        showModal={showPromotionModal}
        Modal={promotionModal}
        id={idPromotion}
        setIdPromotion={setIdPromotion}
      />

      <Container>
        <strong>Promoções ativas</strong>
        <span>
          Crie promoções para incentivar seus clientes a comprar. Por exemplo:
          “Frete grátis”.
        </span>

        <button type="button" onClick={handleClickPromotion}>
          Adicionar promoção
        </button>

        <DivPromotions>
          {promotions &&
            promotions.map(p => (
              <Card>
                <div
                  className="left"
                  onClick={() => handleClickPromotion(p.id)}
                >
                  <strong>{p.name}</strong>
                  <span>{p.description}</span>
                </div>
                <div className="right">
                  {/* <FiTrash2
                  size={18}
                  onClick={() => dispatch(removePromotionRequest(p.id))}
                /> */}
                  <img
                    src={trashIcon}
                    onClick={() => dispatch(removePromotionRequest(p.id))}
                    alt="trash"
                  />
                </div>
              </Card>
            ))}
        </DivPromotions>
      </Container>
    </>
  );
}

export default TabPromotion;

import styled from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  top: 0;
  left: 0;
`;

export const Div = styled.div`
  width: 499px;
  height: 314px;
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (314px / 2));
  left: calc(50vw - (499px / 2));
`;

export const DivTitle = styled.div`
  display: flex;
  height: 55px;
  border-bottom: 0.5px solid #d8dee5;
  margin-bottom: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: rgb(0, 0, 0);
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 10%;
    height: 100%;
  }
`;

export const DivBody = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  padding: 0 24px;

  span {
    color: #000;
    font-weight: 600;
    font-size: 13px;
  }

  textarea {
    border: none;
    border-bottom: 0.5px solid #d8dee5;
    background: none;
    padding: 8px 0;
    font-size: 13px;
    resize: none;
    width: 100%;
    height: 60px;

      &:focus {
        border-bottom: 0.5px solid rgb(43 143 246);
      }

      &::placeholder {
        color: #d1d1d1;
      }
`;

export const Input = styled.input`
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #d8dee5;
  background: none;
  padding: 8px 0;
  font-size: 13px;
  margin-bottom: 24px;

  &:focus {
    border-bottom: 0.5px solid rgb(43 143 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const DivFooter = styled.div`
  display: flex;
  width: 100%;
  background: #f7f8f9;
  padding: 0 20px;
  flex-direction: column;

  span {
    display: none;
  }
`;

export const DivButton = styled.div`
  width: 100%;
  border-style: none;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  button {
    width: 100%;
    height: 42px;
    background: #129eed;
    border-radius: 10px;
    color: #ffffff;
    border: none;
  }
`;

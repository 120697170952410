/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { UniversalLoader } from '~/components/UniversalLoader/UniversalLoader';
import ResponsiveHeader from '../../../../components/HeaderMobile';
import * as S from './styles';
import { TransferPageComponent } from './TransferPageComponent';

export default function PaymentPage() {
  // const history = useHistory();
  const [initialLoading, setInitialLoading] = useState(false);

  return (
    <S.Container>
      <UniversalLoader open={initialLoading} />
      <ResponsiveHeader
        title="Detalhes do repasse"
        url="/paymentmethods/configurationoffstorepay/accountbalance"
      />
      <S.Body>
        <TransferPageComponent />
      </S.Body>
    </S.Container>
  );
}

export function signInRequest(user_email, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { user_email, password },
  };
}

export function signInSuccess(token, user) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user },
  };
}

export function signUpRequest(
  user,
  email,
  password,
  indicatedUser,
  meta_fbc,
  meta_fbp,
  tiktok_ttp,
  tiktok_ttclid
) {
  return {
    type: '@auth/SIGN_UP_REQUEST',
    payload: {
      user,
      email,
      password,
      indicatedUser,
      meta_fbc,
      meta_fbp,
      tiktok_ttp,
      tiktok_ttclid,
    },
  };
}

export function signFailure() {
  return {
    type: '@auth/SIGN_FAILURE',
  };
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  };
}

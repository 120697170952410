import React, { useState } from 'react';
import * as S from './styles';

const CheckboxComponent = ({ label, ...props }) => (
  <S.CheckboxWrapper>
    <S.StyledCheckbox {...props} />
    <S.CheckboxLabel>{label}</S.CheckboxLabel>
  </S.CheckboxWrapper>
);

export function Bottlesheet({ close }) {
  // const history = useHistory();
  const [checked, setChecked] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);

  return (
    <S.ContainerWrapper>
      <S.Card>
        <S.Header>Deseja reembolsar o pagamento?</S.Header>
        <CheckboxComponent
          label="Enviar e-mail para o cliente informando o reembolso"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <S.ButtonComponent>
          <S.Button type="canceled" onClick={close}>
            Cancelar
          </S.Button>
          <S.Button>Reembolsar pagamento</S.Button>
        </S.ButtonComponent>
      </S.Card>
    </S.ContainerWrapper>
  );
}

import styled from 'styled-components';

export const Div = styled.div`
  display: flex;
  flex-direction: column;

  > span {
    width: 100%;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
  }
`;

export const DivColor = styled.div`
  display: flex;
  align-items: center;
  justify-content: left;
  /* margin: 0 5px; */

  span {
    margin-bottom: 10px;
  }

  #color-hex {
    margin-left: 10px;
    width: 120px;
    height: 44px;
    font-size: 13px;
    border-radius: 6px;
    border: none;
    text-align: left;
    background: #fafafa;
    color: #838383;
    padding: 0 12px;

    &::placeholder {
      color: #b8b5bf;
    }
  }

  .flexbox-fix {
    svg {
      display: none;
    }

    input {
      display: none;
    }

    span {
      display: none;
    }

    > div {
      > div {
        > div {
          span {
            display: none !important;
          }
        }
      }
    }
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background: #fff;

  ${props =>
    props.isModal &&
    css`
      height: auto;
      top: auto;
      left: auto;
      bottom: 0;
      border-radius: 30px 30px 0 0;

      @media (min-width: 720px) {
        width: 100%;
        max-width: 500px;
        height: auto;
        top: calc(50vh - (575px / 2));
        left: calc(50vw - (500px / 2));
        bottom: auto;
        border-radius: 16px;
      }
    `};

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 64px;
    border-bottom: 0.75px solid #d8dee5;
    margin-bottom: 20px;

    @media (min-width: 720px) {
      margin-bottom: 0;
      height: 54px;
    }

    > h2 {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 64px;
  align-items: center;
  justify-content: center;

  > div {
    position: absolute;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
  }

  > span {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
  }
`;

export const Background = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100vh;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px 100px 16px;

  ${props =>
    props.isModal &&
    css`
      height: 450px;
      overflow-y: auto;
    `}

  @media (min-width: 720px) {
    padding: 16px 16px 30px 16px;
    max-width: 500px;
  }

  > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    text-align: center;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 20px;
    text-align: center;
  }

  > input {
    background: #f9f9f9;
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 8px;
    height: 45px;
    width: 100%;
    padding: 12px;

    & + input {
      margin-top: 8px;
    }
  }

  > div {
    display: flex;
    width: 100%;
    margin-top: 8px;

    .first-input {
      width: 120px;
      margin-right: 8px;
    }

    > input {
      background: #f9f9f9;
      border: 0.5px solid #f9f9f9;
      box-sizing: border-box;
      border-radius: 8px;
      height: 45px;
      width: 100%;
      padding: 12px;
    }
  }
`;

export const DivButton = styled.div`
  position: fixed;
  bottom: 0;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 12px 16px;

  @media (min-width: 720px) {
    width: 100%;
    max-width: 500px;
    position: relative;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    /* top: calc(50vh - (283px / 2)); */
    /* left: calc(50vw - (500px / 2)); */
  }

  > button {
    height: 50px;
    background: #2b8ff5;
    border-radius: 8px;
    width: 100%;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;

    > img {
      width: 24px;
    }
  }
`;

export const DivCheckBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 24px !important;

  > span {
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }
`;

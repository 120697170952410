import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { Input, Form } from '@rocketseat/unform';

import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';

import { ButtonSubmit, Container, Div } from './styles';

export default function ResponsiveChangePassword({ match }) {
  const { token } = match.params;

  const [loading, setLoading] = useState(false);

  async function handleSubmit({ password, confirmpassword }) {
    try {
      setLoading(true);

      if (password !== confirmpassword) {
        toast.error('Senhas não coincidem');
        setLoading(false);
        return 0;
      }
      const getToken = await api.get(`/token/${token}`);

      if (getToken && getToken.data) {
        const { id, name, email } = getToken.data;

        const data = {
          id,
          name,
          email,
          password,
        };

        await api.post('/changepasswordbytoken', data);
        setLoading(false);

        toast.success('Senha alterada!');
        history.push('/');

        return 0;
      }

      toast.error('Não foi possível alterar a senha!');
      setLoading(false);
      return 0;
    } catch (err) {
      toast.error('Não foi possível alterar a senha!');
      setLoading(false);
      return 0;
    }
  }

  return (
    <Container>
      <Div>
        <h1>Preencha sua nova senha</h1>
        <Form onSubmit={handleSubmit}>
          <strong>Nova senha</strong>
          <div className="div-input">
            <Input
              name="password"
              type="password"
              placeholder="Digite sua nova senha"
            />
          </div>

          <strong>Confirmar senha</strong>
          <div className="div-input">
            <Input
              name="confirmpassword"
              type="password"
              placeholder="Confirme sua senha"
            />
          </div>

          <ButtonSubmit disabled={loading} block={loading}>
            Confirmar
          </ButtonSubmit>
        </Form>
      </Div>
    </Container>
  );
}

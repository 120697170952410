/* eslint-disable react/prop-types */
import React from 'react';
import { Container } from './styles';
// import arrowRightIcon from '../../assets/icons/offstorepay/arrow-right.svg';

export function NextButton({ type, onNextClick }) {
  return (
    <Container type={type} onClick={onNextClick}>
      <p>Continuar</p>
      {/* <img alt="seta para direita" src={arrowRightIcon} /> */}
    </Container>
  );
}

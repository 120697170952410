/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useCallback, useEffect } from 'react';

import { toast } from 'react-toastify';
// import ArrowLeftIcon from '../../assets/icons/arrowLeft.svg';

import api from '~/services/api';
import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivBody,
  DivButton,
} from './styles';

function ReturnPolicyModal({ showModal, Modal, pPolicy, isModal }) {
  const [policy, setPolicy] = useState();

  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  useEffect(() => {
    setPolicy(pPolicy);
  }, [pPolicy]);

  async function handleSaveData() {
    try {
      await api.put('/userconfigurations', {
        exchange_and_return_policy: policy,
      });

      toast.success('Alteração salva');

      handleModal();
    } catch (err) {
      toast.error('Erro ao processar requisição');
    }
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal} isModal={isModal}>
        <DivTitle>
          <div className="div-space" />
          <div>
            <span>Políticas de troca e devolução</span>
          </div>
          <div className="div-space" />
        </DivTitle>
        <DivBody isModal={isModal}>
          <div>
            {/* <strong>Políticas de troca e devolução</strong> */}

            <textarea
              rows={8}
              maxLength="10000"
              placeholder="Adicionar políticas"
              onChange={e => setPolicy(e.target.value)}
              value={policy}
            />
          </div>
        </DivBody>

        <DivButton>
          <button type="button" onClick={handleSaveData}>
            Salvar alterações
          </button>
        </DivButton>
      </Div>
    </ContainerGeral>
  );
}

export default ReturnPolicyModal;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { AccountBalance } from '~/pages/Responsive/PaymentMethods/AccountBalance';
import Header from '../Header';
import * as S from './styles';
import { PaymentPageComponent } from '../PaymentPageComponent';
import { TransferPageComponent } from '../TransferPageComponent';

function ConfigurationAccountBalance({ handleClickBackButton }) {
  const [page, setPage] = useState(1);
  const [transactionId, setTransactionId] = useState('');
  const [transferId, setTransferId] = useState('');

  return (
    <S.Container>
      {page === 1 && (
        <>
          <Header
            title="Saldo da conta"
            handleClickBack={handleClickBackButton}
          />
          <div style={{ marginTop: '-80px' }}>
            <S.Body>
              <AccountBalance
                handlePageSelected={p => setPage(p)}
                handleGetTransactionId={t => setTransactionId(t)}
                handleGetTransferId={t => setTransferId(t)}
                isDesktop
              />
            </S.Body>
          </div>
        </>
      )}

      {page === 2 && (
        <>
          <Header
            title="Detalhes do pagamento"
            handleClickBack={() => {
              setPage(1);
              setTransactionId(0);
            }}
          />
          <div>
            <S.Body>
              {transactionId && (
                <PaymentPageComponent transactionId={transactionId} />
              )}
            </S.Body>
          </div>
        </>
      )}

      {page === 3 && (
        <>
          <Header
            title="Detalhes do repasse"
            handleClickBack={() => {
              setPage(1);
              setTransferId(0);
            }}
          />
          <div>
            <S.Body>
              {transferId && <TransferPageComponent transferId={transferId} />}
            </S.Body>
          </div>
        </>
      )}
    </S.Container>
  );
}

export default ConfigurationAccountBalance;

import React, { useCallback } from 'react';
import { toast } from 'react-toastify';
import api from '../../../../../../services/api';

import { ContainerGeral, Container, Div } from './styles';

export default function CancelModal({ showModal, Modal, OrderId }) {
  // const dispatch = useDispatch();

  const handleModal = useCallback(
    canceled => {
      Modal(false, canceled);
    },
    [Modal]
  );

  async function handleSubmit() {
    try {
      await api.delete(`/orderrequest/${OrderId}`);

      handleModal(true);
    } catch (err) {
      toast.error('Erro ao cancelar pedido');
    }
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={() => handleModal(false)} />
      <Div showModal={showModal}>
        <div>
          <strong>Cancelar pedido?</strong>
          <span>
            Todos os produtos incluidos no pedido voltarão para o estoque
          </span>
        </div>
        <button type="button" className="confirm" onClick={handleSubmit}>
          Confirmar cancelamento
        </button>
        <button type="button" onClick={() => handleModal(false)}>
          Não cancelar
        </button>
      </Div>
    </ContainerGeral>
  );
}

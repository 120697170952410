import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  margin-bottom: 25px;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10;
  border-bottom: 0.5px solid #d8dee5;

  > strong {
    position: absolute;
    right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1.5px solid rgb(43, 143, 245);
    border-radius: 4px;
    height: 100%;
    padding: 2px 6px;
    color: rgb(43, 143, 245);
    font-size: 13px;
    line-height: 1.25;
    font-weight: 500;
    font-style: normal;
    height: auto;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 10%;
    height: 100%;

    img {
      width: 10px;
      height: 35px;
      margin-left: 22px;
    }
  }
`;

export const DivAddOrder = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  padding: 0 16px 8px 16px;

  > button {
    width: 100%;
    height: 50px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  > span {
    font-size: 11px;
    line-height: 16px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #838383;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #d8dee5;

  .loader {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    padding: 20px 0;

    > img {
      width: 40px;
      height: 40px;
    }
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  /* padding: 24px 16px; */

  > strong {
    font-weight: 500;
    font-size: 15px;
    color: #000;
    margin-bottom: 5px;
  }

  > span {
    color: #a5a9b1;
    font-size: 13px;
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  border-bottom: 0.5px solid #d8dee5;
  padding: 18px 16px;
  background: ${props => (props.canceled ? '#fafafa' : '#fff')};
  cursor: pointer;

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 6px;

    > img {
      width: 12px;
      height: 12px;
    }
  }

  .date-viewed {
    color: #9d9d9d;
  }

  .point {
    display: flex;
    width: 6px;
    height: 6px;
    margin-right: 16px;
    border-radius: 50%;
  }

  .span-cancel {
    font-weight: 500;
    font-size: 13px;
    color: #cfcfcf;
  }
`;

export const DivTextOrder = styled.div`
  display: flex;
  flex-direction: column;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  > div {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    > strong {
      font-weight: 500;
      font-size: 13px;
      color: #000000;
      margin-right: 6px;
    }

    > span {
      font-size: 13px;
      color: #2b8ff5;
    }

    .viewed {
      color: #838383;
      font-weight: 400;
    }

    .canceled {
      color: #9d9d9d;
      font-weight: 400;
    }
  }

  > span {
    font-size: 11px;
    color: #101010;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const DivPaymentStatus = styled.div`
  display: flex;
  align-items: center;
  padding-left: 16px;
  /* position: absolute; */
  /* right: 16px; */

  > div {
    width: max-content;
  }

  ${props =>
    props.color === 'approved' &&
    props.orderStatus !== 'Cancelado' &&
    css`
      > div {
        background: rgba(43, 143, 245, 0.06);
        color: #2b8ff5;
      }
    `}

  ${props =>
    props.color === 'Pagamento pendente' &&
    css`
      > div {
        background: rgba(157, 157, 157, 0.08);
        color: #9d9d9d;
      }
    `}
    ${props =>
      (props.color === 'Cancelado' ||
        props.color === 'Pagamento expirado' ||
        props.color === 'Pagamento reprovado') &&
      css`
        > div {
          background: rgba(255, 48, 60, 0.06);
          color: #ff303c;
        }
      `}
      ${props =>
        props.color === 'Pagamento confirmado' &&
        css`
          > div {
            background: rgba(6, 200, 107, 0.06);
            color: #06c86b;
          }
        `}

      ${props =>
        props.color === 'Pendente' &&
        css`
          > div {
            background: rgba(255, 181, 37, 0.06);
            color: #ffb525;
          }
        `}
        ${props =>
          props.color === 'Pagamento à combinar' &&
          css`
            > div {
              background: rgba(255, 181, 37, 0.06);
              color: #ffb525;
            }
          `}
        ${props =>
          props.color === 'Em preparação' &&
          css`
            > div {
              background: rgba(131, 72, 255, 0.06);
              color: #8348ff;
            }
          `}
          ${props =>
            (props.color === 'Pedido enviado' ||
              props.color === 'Pedido pronto') &&
            css`
              > div {
                background: rgba(13, 133, 255, 0.06);
                color: #0d85ff;
              }
            `}

            ${props =>
              props.color === 'Aprovado' &&
              css`
                > div {
                  background: rgba(13, 133, 255, 0.06);
                  color: #0d85ff;
                }
              `}


        ${props =>
          ((props.color === 'approved' && props.orderStatus !== 'Cancelado') ||
            (!props.color && props.orderStatus === 'Pedido recebido')) &&
          css`
            > div {
              background: rgba(16, 16, 16, 0.06);
              color: rgba(16, 16, 16, 0.5);
            }
          `}

              ${props =>
                ((props.color === 'approved' &&
                  props.orderStatus !== 'Cancelado') ||
                  (!props.color &&
                    props.orderStatus === 'Pagamento pendente')) &&
                css`
                  > div {
                    background: rgba(16, 16, 16, 0.06);
                    color: rgba(16, 16, 16, 0.5);
                  }
                `}

  > div {
    border-radius: 6px;
    padding: 8px 12px;
    font-size: 13px;
  }
`;

export const SearchOrdersInput = styled.input`
  display: inline-flex;
  padding: 14px 94px 14px 93px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #f9f9f9;
  border-width: 0px;
  margin-top: 12px;

  ::placeholder {
    color: var(--Placeholder, #bfbfbf);
    text-align: center;
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const ButtonWrapperFilter = styled.div`
  display: flex;
  margin: 16px 0px;
  gap: 8px;
`;

export const ButtonFilter = styled.button`
  display: flex;
  height: 36px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 50px;
  border: 0;
  background: ${props => (props.active ? '#0d85ff' : '#EFEFEF')};
  color: ${props => (props.active ? '#FFF' : '#1b1b1b')};
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-weight: 800;

  span {
    color: var(--Blue-details, #0d85ff);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

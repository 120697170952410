import styled, { css } from 'styled-components';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.15);
  z-index: 501;
  top: 0;
  left: 0;
`;

export const DivModal = styled.div`
  width: 90%;
  position: fixed;
  bottom: 0;
  z-index: 502;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 2px 8px 31px 0px rgba(0, 0, 0, 0.08);

  opacity: ${props => (props.active ? '1' : '0.85')};
  transform: ${props => (props.active ? 'translateY(0)' : 'translateY(200%)')};

  ${props =>
    !props.isDesktop &&
    css`
      transition: all 300ms linear;
    `};

  ${props =>
    props.active &&
    css`
      width: 90%;
      max-width: 500px;
      height: auto;
      top: calc(50vh - (318px / 2));
      bottom: auto;
      left: ${props.isDesktop ? 'calc(50vw - (500px / 2))' : 'auto'};
    `};
`;

export const Header = styled.div`
  /* height: 70px; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 32px;

  > h3 {
    max-width: 260px;
    text-align: center;
  }
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 24px 20px 32px 20px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 100%;
    height: 48px;
  }
`;

export const DivPrice = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  background: #fafafa;
  width: 100%;
  height: 57px;
  padding: 0 16px;
  margin-bottom: 24px;

  > span {
    color: #838383;
    font-size: 14px;
    font-weight: 400;
  }

  .span-blue {
    color: #0d85ff;
  }
`;

export const SecundaryButton = styled.button`
  background: #efefef;
  color: #101010;
  font-size: 14px;
  font-weight: 500;
  border: none;
  margin-bottom: 8px;
`;

import React from 'react';

function CartIcon({ selected }) {
  return (
    <svg
      width="84"
      height="73"
      viewBox="0 0 84 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="84" height="55" fill={selected ? '#0D85FF' : '#F0F0F0'} />
      <rect y="61" width="38" height="5" fill="#F0F0F0" />
      <rect y="68" width="17" height="5" fill="#F0F0F0" />
    </svg>
  );
}

export default CartIcon;

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useReactToPrint } from 'react-to-print';

import { parseISO, format } from 'date-fns';
import Barcode from 'react-barcode';
import pt from 'date-fns/locale/pt-BR';
import { Divider } from '~/designsystem/Fonts';
import api from '../../../services/api';
import { formatPrice } from '../../../util/format';

import {
  Container,
  DivSelectConfigurationLabel,
  DivSelect,
  DivLabel,
} from './styles';

import CheckIcon from '../../../assets/icons/order/checkIcon';
import TicketIcon from '../../../assets/icons/order/ticket.svg';

import ResponsiveHeader from '../../../components/HeaderMobile';

function ViewLabel({ match }) {
  const history = useHistory();
  const ref = useRef(null);

  const { id } = match.params;

  const [showOrderInfo, setShowOrderInfo] = useState(true);
  const [showSenderInfo, setShowSenderInfo] = useState(true);
  // const [showNoteInfo, setShowNoteInfo] = useState(true);

  const [order, setOrder] = useState();
  const [products, setProducts] = useState();

  useEffect(() => {
    async function load() {
      window.scrollTo({
        top: 0,
      });

      await api.post(`/orderrequestbyid/${id}`).then(async response => {
        setOrder({
          ...response.data,
          date: format(parseISO(response.data.createdAt), "dd'/'MM'/'yyyy", {
            locale: pt,
          }),
        });

        setProducts(
          response.data.products.map(r => ({
            ...r,
            totalPrice: formatPrice(r.price),
          }))
        );
      });
    }

    load();
  }, [id]);

  const subtotal = useMemo(() => {
    const value = order && order.subtotal && order.subtotal.replace('.', '');
    const response = value && parseFloat(value.slice(2)).toFixed(2);
    return response;
  }, [order]);

  async function handleClickPdfGenerate() {
    // visualizarImpressaoPdf(order);
    history.push('/viewlabelpdf');
  }

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    pageStyle: '@page { size: auto;  margin: 10mm;}',
  });

  return (
    <Container>
      <ResponsiveHeader
        title="Imprimir etiqueta"
        url={`/myorders/${order && order.id}`}
        tab={1}
      />

      <DivSelectConfigurationLabel>
        <h2>Informações da etiqueta</h2>
        <div>
          <span>Exibir remetente</span>

          <DivSelect
            enabled={showSenderInfo}
            onClick={() => setShowSenderInfo(!showSenderInfo)}
          >
            {showSenderInfo && <CheckIcon color="#fff" />}
          </DivSelect>
        </div>
        <div>
          <span>Exibir informações do pedido</span>

          <DivSelect
            enabled={showOrderInfo}
            onClick={() => setShowOrderInfo(!showOrderInfo)}
          >
            {showOrderInfo && <CheckIcon color="#fff" />}
          </DivSelect>
        </div>
      </DivSelectConfigurationLabel>

      <DivLabel ref={ref}>
        <div>
          <div className="title">
            <strong>Destinatário</strong>
          </div>
          <span>{order && order.name}</span>

          {order &&
            (order.address ||
              order.number ||
              order.complement ||
              order.neighborhood ||
              order.city ||
              order.uf ||
              order.cep) && (
              <>
                <span className="mg-top-4">
                  {order.address}, {order.number}{' '}
                  {order.complement && `, ${order.complement},`}
                </span>
                <span className="mg-top-4">{order.neighborhood}</span>
                <span className="mg-top-4">
                  {order.city}, {order.uf}, {order.cep}
                </span>
                <span className="mg-top-4">Brasil</span>
              </>
            )}

          {order && order.cep && (
            <div>
              <Barcode
                value={String(order.cep)}
                height={74}
                font='"Inter"'
                fontSize={12}
                textMargin={8}
              />
            </div>
          )}
        </div>

        {showSenderInfo && (
          <div>
            <strong className="titleSender">Remetente</strong>
            {/* <span>{order && order.User.page_title}</span> */}

            {order &&
              order.User &&
              (order.User.address ||
                order.User.number ||
                order.User.district ||
                order.User.city ||
                order.User.uf ||
                order.User.cep) && (
                <span>
                  {order.User.address}, {order.User.number},
                  {order.User.district}, {order.User.city}, {order.User.uf},{' '}
                  {order.User.cep}
                </span>
              )}
          </div>
        )}

        {products && showOrderInfo && (
          <>
            <div className="divider-section">
              <Divider margin='"0px"' />
            </div>

            <div>
              <strong className="titleSender">Informações do pedido</strong>
              {products.map(p => (
                <div className="mg-bottom-8">
                  <span>
                    {p.amount}x {p.name}
                  </span>
                  <span>{p.totalPrice}</span>
                </div>
              ))}
            </div>

            <div className="divider-section">
              <Divider margin='"0px"' />
            </div>
            <div>
              <div>
                <span>Subtotal</span>
                <span>{order && order.subtotal}</span>
              </div>

              <div className="mg-top-4">
                <span>Envio</span>
                <span>{order && order.rate}</span>
              </div>

              {order && order.coupon && (
                <div className="mg-top-4">
                  <span>Cupom de desconto({order.coupon.code})</span>
                  <span>
                    {order.coupon.type === 'P'
                      ? `- ${formatPrice(
                          (subtotal * order.coupon.value) / 100
                        )}`
                      : `- ${formatPrice(order.coupon.value)}`}
                  </span>
                </div>
              )}
              <div className="mg-top-4">
                <span className="bold">Total</span>
                <span className="bold">{order && order.total}</span>
              </div>
            </div>
          </>
        )}

        {/* {order && order.observation && showNoteInfo && (
          <div>
            <strong className="title">Observação</strong>
            <span>{order.observation}</span>
          </div>
        )} */}
      </DivLabel>

      <button
        type="button"
        // onClick={() =>
        //   exportComponentAsPDF(ref, {
        //     fileName: `pedido ${order && order.id}.pdf`,
        //     pdfOptions: { w: 70, h: 105, x: 15, y: 15 },
        //   })
        // }
        onClick={() => handlePrint()}
      >
        Imprimir etiqueta
        <img src={TicketIcon} alt="cancel" />
      </button>
    </Container>
  );
}

export default ViewLabel;

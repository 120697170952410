import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 55px;
  padding: 32px 16px 0 16px;

  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 138.1%;
    color: #000000;
    margin-bottom: 4px;
  }
`;

export const DivCustomLink = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 4px;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #101010;
    margin-bottom: 16px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 45px;
    background: #fafafa;
    border-radius: 8px;

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #2b8ff5;
      margin: 0 12px;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 92px;
      background: #efefef;
      border-radius: 8px;
      border: none;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      text-align: center;
      color: #101010;

      > img {
        margin-right: 4px;
      }
    }
  }
`;

export const DivSpace = styled.div`
  display: flex;
  align-items: center;
  margin: 48px auto;

  > span {
    margin: 0 10px;
    color: #cfcfcf;
    font-size: 14px;
  }

  > strong {
    width: 70px;
    background: #d8dee5;
    height: 1px;
  }
`;

export const DivSendForEmail = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  padding-top: 12px;

  > strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 4px;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }

  .invitations {
    display: flex;
    /* justify-content: space-between; */
    align-items: baseline;
    margin-top: 24px;

    > strong {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #000000;
      margin-right: 4px;
    }

    > span {
      font-size: 13px;
      line-height: 19px;
      text-align: right;
      color: #2b8ff5;
    }
  }

  > button {
    background: #2b8ff5;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    width: 100%;
    max-width: 480px;
    height: 45px;
    align-self: center;
    margin-top: 16px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    margin-bottom: 12px;

    > strong {
      font-size: 13px;
      line-height: 19px;
      color: #838383;
      font-style: normal;
      font-weight: normal;
    }

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 19px;
      text-align: right;
      color: #cdcdcd;
    }

    .span-confirmed {
      color: #2b8ff5;
    }

    .span-invalid {
      color: #cfcfcf;
    }
  }
`;

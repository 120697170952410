import styled from 'styled-components';

export const ContainerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Header = styled.h1`
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const ModalActive = styled.div`
  display: flex;
  align-items: center;
  /* position: fixed;
  top: 0;
  left: 0; */

  .container {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(227, 229, 236, 0.65);
    top: 0;
    left: 0;
    opacity: 0.8;
    overflow: hidden;
    float: 1;
    z-index: 11;
    align-items: center;
    justify-content: center;
  }

  .div {
    position: fixed;
    /* width: 400px; */
    /* height: 270px; */
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    /* top: calc(75vh - (270px / 2)); */
    /* left: calc(50vw - (400px / 2)); */
    bottom: 0;
    left: 0;
    padding: 24px 16px;
    z-index: 12;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      margin-bottom: 22px;
    }

    > strong {
      font-style: normal;
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      text-align: center;
      color: #838383;
      margin-bottom: 16px;
      margin-top: 12px;
    }

    > button {
      width: 100%;
      height: 50px;
      background: #2b8ff5;
      border-radius: 8px;
      color: #fff;
      border: none;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }
  }

  .grey-button {
    background: #efefef !important;
    color: #101010 !important;
  }

  .red-button {
    background: #ff303c !important;
    color: #ffffff;
    margin-top: 12px;
  }
`;

export const Payment = styled.h1`
  color: #06c86b;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-top: 4px;
`;

export const Description = styled.span`
  color: #838383;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Divider = styled.div`
  background: #d8dee5;
  height: 1px;
  width: 100%;
  margin: 24px 0px;
`;

export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  color: #0d85ff;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  border-width: 0px;
  /* width: 92px; */
  margin-top: 4px;

  > svg {
    margin-left: 6px;
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 7px;
  border-radius: 8px;
  margin: ${props => props.margin};
  background: ${props => (props.type === 'repay' ? '#EFEFEF' : '#0D85FF')};
  width: 100%;
  color: ${props => (props.type === 'repay' ? '#101010' : '#ffffff')};
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 17px;
  border-width: 0px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding: ${props => props.padding || '6px 12px'};
  border-radius: 6px;
  background: #fff3f3;
  color: #ff303c;
  /* text-align: center; */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const DivDisputeButton = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  > button {
    border-radius: 8px;
  }

  .grey {
    background: #efefef;
    color: #101010;
    height: 50px;
    border: none;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const PaymentWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const HistoryHeader = styled.p`
  color: #9d9d9d;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Dot = styled.div`
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  background: #d8dee5;
  border-radius: 50%;
  margin-right: 8px;
`;

export const HistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HistoryLabel = styled.label`
  color: #9d9d9d;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

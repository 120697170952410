import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 0;
  padding: 0 16px;

  > button {
    width: 100%;
    height: 48px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const DivDeliveryPlace = styled.div`
  display: flex;
  flex-direction: column;
  margin: 24px 0 0 0;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }

  > button {
    width: 100%;
    height: 50px;
    border: none;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    background: #2b8ff5;
    border-radius: 8px;
    margin-bottom: 8px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  height: 62px;
  width: 100%;
  padding: 0 16px;
  background: ${props => props.background || 'none'};
  margin-top: 8px;

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    .price {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #9d9d9d;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

export const DivPrice = styled.div`
  display: flex;
  /* margin-bottom: 24px; */

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 45px;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
    font-size: 13px;
    color: #101010;
  }

  input {
    background: #f9f9f9;
    border-radius: 0px 8px 8px 0px;
    border: none;
    width: 100px;
    padding: 0 12px;

    &::placeholder {
      font-size: 13px;
      color: #bfbfbf;
    }
  }
`;

export const Div = styled.div`
  margin-bottom: 4px;
  margin-top: ${props => props.marginTop || '0'};

  > div {
    display: flex;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #9d9d9d;
      margin-left: 6px;
    }
  }

  > span {
    font-size: 13px;
    color: #838383;
  }
`;

export const SpanStatus = styled.span`
  font-style: normal;
  line-height: 16px;
  display: flex;
  align-items: center;
  font-weight: ${props => props.fontWeight || 'normal'};
  font-size: ${props => props.fontSize || '11px'};
  color: ${props => props.color};
`;

export const DivDeliveryName = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 0 0 0;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    width: 100%;
    max-width: 600px;
    height: 50px;
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    padding: 0 12px;

    &::placeholder {
      font-size: 13px;
      color: #bfbfbf;
    }
  }
`;

export const DivValidLocales = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
  }
`;

export const DivInputCities = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div {
    display: flex;
    width: 100%;

    & + div {
      margin-top: 8px;
    }

    > input {
      background: #f9f9f9;
      border-radius: 8px;
      border: none;
      padding: 12px;
      font-size: 13px;
      color: #000000;
      width: 100%;
      max-width: 600px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 44px;
      margin-left: 8px;
    }
  }

  > button {
    font-weight: 500;
    font-size: 13px;
    color: #2b8ff5;
    border: none;
    background: none;
    margin-top: 12px;
  }
`;

export const DivMethod = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > span {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;

export const Radio = styled.div`
  display: flex;

  & + div {
    margin-top: 12px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #efefef;
    border-radius: 50%;
    margin-right: 10px;

    > div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #efefef;
    }
  }

  .selected {
    background: #2b8ff5;

    > div {
      background: #fff;
    }
  }
`;

export const DivSelectedCities = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px;
    height: 42px;
    padding: 0 12px;
    font-size: 13px;
    color: #000000;
    margin-right: 8px;
    margin-bottom: 8px;

    > div {
      margin-left: 12px;
      cursor: pointer;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const DivCep = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > div {
    display: flex;
    margin-bottom: 8px;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    .span-label {
      font-weight: normal;
      font-size: 10px;
      line-height: 15px;
      color: #2b8ff5;
      margin-left: 6px;
    }
  }

  > input {
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 45px;
    padding: 0 12px;
    font-size: 13px;

    &::placeholder {
      color: #afafaf;
    }
  }
`;

export const AddPlace = styled.div`
  display: flex;
`;

export const SeeMore = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #2b8ff5;
  margin: auto;
  cursor: pointer;
  margin-top: 20px;
`;

export const DivAddPlace = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  height: 75vh;
  top: auto;
  bottom: 0;
  width: 100%;
  background: #fff;
  border-radius: 20px 20px 0 0;
  z-index: 11;

  > header {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > strong {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    > button {
      position: absolute;
      right: 24px;
      cursor: pointer;
      background: none;
      border: none;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #ff303c;
    }

    > img {
      position: absolute;
      right: 24px;
      cursor: pointer;
    }
  }

  .required {
    font-size: 13px;
    line-height: 18px;
    color: #2b8ff5;
    margin-left: 6px;
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 0 16px 106px 16px;
    overflow-y: scroll;

    /* height: 450px; */

    > button {
      display: flex;
      align-self: flex-start;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
      margin-bottom: 16px;
    }

    > input {
      background: #f9f9f9;
      border-radius: 8px;
      min-height: 42px;
      border: none;
      padding: 0 12px;
      margin-bottom: 24px;

      &::placeholder {
        color: #bfbfbf;
      }
    }
  }

  > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    border-top: 0.5px solid #d8dee5;
    background: #fff;
    border-radius: ${props => (props.isDesktop ? '0 0 20px 20px' : '0')};

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #2b8ff5;
      border-radius: 8px;
      height: 50px;
      border: none;
      color: #fff;
    }
  }
`;

export const DivBackground = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  /* background: rgba(227, 229, 236, 0.65); */
  background: #000;
  opacity: 15%;
  z-index: 10;
  top: 0;
  left: 0;
`;

export const DivDatePeriod = styled.div`
  display: flex;
  align-items: center;

  > input {
    width: 80px;
    height: 42px;
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    text-align: center;
    margin: 0 8px;
  }
`;

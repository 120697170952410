import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivName = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 16px 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 2px;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    height: 42px;
    border: none;
    padding: 12px;
    margin-top: 8px;

    &::placeholder {
      color: #bfbfbf;
    }
  }
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 0.5px solid #d8dee5;
  padding: 0 32px 24px 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 2px;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }
`;

export const DivRegion = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-bottom: 0.5px solid #d8dee5;
  padding: 16px 32px;
`;

export const InputNumber = styled.input`
  height: 45px;
  width: 80px;
  background: #fff;
  border: 0.5px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0 12px;

  &:focus {
    border: 1px solid #2b8ff5;
    transition: all 300ms;
  }

  &::placeholder {
    color: #cfcfcf;
  }
`;

export const DivMain = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* height: 69px; */

  .left {
    display: flex;
    width: 100%;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 8px;
      width: 21px;
      height: 21px;
    }

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      display: flex;
      align-items: center;

      color: #131313;
    }
  }

  .right {
    display: flex;
  }
`;

export const DivExpansive = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

export const DivState = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & + div {
    margin-top: 32px;
  }

  > div {
    display: flex;
    width: 100%;

    > span {
      display: flex;
      align-items: center;
      width: 100%;
      height: 45px;
      background: #fafafa;
      border: 0.5px solid #fafafa;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #131313;
    }

    > input {
      height: 45px;
      background: #fff;
      border: 0.5px solid #d8dee5;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 12px;

      &:focus {
        border: 1px solid #2b8ff5;
        transition: all 300ms;
      }

      &::placeholder {
        color: #cfcfcf;
      }
    }

    .inputNumber {
      width: 80px;
      margin-left: 12px;
    }
  }

  > button {
    display: flex;
    width: max-content;
    margin-top: 6px;
    margin-left: 12px;
    background: none;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
  }
`;

export const DivCity = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;

  & + div {
    margin-top: 24px !important;
  }

  > div {
    display: flex;
    width: 100%;

    > span {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background: #fafafa;
      border: 0.5px solid #fafafa;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #131313;
      margin-left: 12px;
    }

    > input {
      height: 45px;
      width: 100%;
      background: #fff;
      border: 0.5px solid #d8dee5;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 12px;
      margin-left: 12px;

      &:focus {
        border: 1px solid #2b8ff5;
        transition: all 300ms;
      }

      &::placeholder {
        color: #cfcfcf;
      }
    }

    .inputNumber {
      width: 80px;
      margin-left: 12px;
    }

    > img {
      margin: 0 16px;
      cursor: pointer;
    }
  }

  > button {
    display: flex;
    width: max-content;
    margin-top: 6px;
    margin-left: 36px;
    background: none;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
  }
`;

export const DivNeighborhood = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;

  /* & + div {
    margin-top: 32px;
  } */

  > div {
    display: flex;
    width: 100%;

    > span {
      display: flex;
      align-items: center;
      width: 100%;
      height: 40px;
      background: #fafafa;
      border: 0.5px solid #fafafa;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 12px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 21px;
      color: #131313;
    }

    > input {
      height: 45px;
      width: 100%;
      background: #fff;
      border: 0.5px solid #d8dee5;
      box-sizing: border-box;
      border-radius: 10px;
      padding: 0 12px;
      margin-left: 36px;

      &:focus {
        border: 1px solid #2b8ff5;
        transition: all 300ms;
      }

      &::placeholder {
        color: #cfcfcf;
      }
    }

    .inputNumber {
      width: 80px;
      margin-left: 12px;
    }

    > img {
      margin: 0 16px;
      cursor: pointer;
    }
  }
`;

/* eslint-disable react/prop-types */
import React from 'react';
import { Container, ModalWrapper } from './styles';

export function Modal({ children }) {
  return (
    <Container>
      <ModalWrapper>{children}</ModalWrapper>
    </Container>
  );
}

import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import {
  ContainerGeral,
  Container,
  Div,
  Input,
  StyledFaLock,
  Button,
} from './styles';

import api from '../../services/api';
import { updateProfileRequest } from '../../store/modules/user/actions';

export default function PasswordModal({
  showModal,
  Modal,
  name,
  user,
  email,
  newEmail,
}) {
  const dispatch = useDispatch();
  const [password, setPassword] = useState();

  const handleModal = useCallback(() => {
    setPassword('');
    Modal(false);
  }, [Modal]);

  function handleChangeEmail() {
    const data = { name, user, email: newEmail };
    dispatch(updateProfileRequest(data));

    handleModal();
  }

  async function handleSubmit() {
    try {
      await api.post('/confirmpassword', {
        email,
        password,
      });
      handleChangeEmail();
    } catch (err) {
      toast.error('Senha inválida');
    }
  }

  function verifyOnEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }

    if (e.key === 'Escape') {
      handleModal();
    }
  }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <h1>Digite sua senha para continuar</h1>
        <div>
          <div>
            <StyledFaLock color="#a5a2aa" size={20} />
          </div>
          <Input
            type="password"
            placeholder="Senha"
            value={password}
            onChange={e => setPassword(e.target.value)}
            onKeyDown={verifyOnEnter}
          />
        </div>
        <Button onClick={handleSubmit}>Ok</Button>
      </Div>
    </ContainerGeral>
  );
}

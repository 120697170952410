import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: #fff;
`;

export const Div = styled.div`
  width: 100%;
  height: 100vh;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 24px;
  font-weight: 500;
  /* border: 1px solid #d8dee5; */
  /* box-shadow: 6px 23px 28px rgba(0, 0, 0, 0.05); */

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
  }

  > span {
    font-weight: 600;
    font-size: 18px;
    color: #262626;
    margin-bottom: 24px;
  }
`;

export const Bar = styled.strong`
  width: ${props => props.size};
  color: #fafafa;
  background: #fafafa;
  border-radius: 4px;
  margin-bottom: 10px;
`;

export const DivAvatar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  button {
    width: 24px;
    height: 24px;
    background: #2b8ff5;
    border: none;
    font-size: 13px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background 0.2s;
    border-radius: 50%;
    box-shadow: 2px 6px 11px rgba(0, 0, 0, 0.17);
    z-index: 200;
    margin-top: -20px;
    margin-left: 47px;
  }

  .cancel-button {
    background: #ff3945;

    img {
      width: 8px;
      height: 8px;
    }
  }
`;

export const DivLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  /* border: ${props =>
    props.hiddenBorder ? 'none' : '1px dashed #a5a9b1'}; */
  height: 75px;
  width: 75px;
  border-radius: 200px;
  z-index: 200;

  .logo {
    width: 75px;
    height: 75px;
    border-radius: 200px;
    object-fit: cover;
  }

  .user-icon {
    width: 80px;
    height: 30px;
  }
`;

export const DivButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  width: 100%;

  > a {
    margin-bottom: 15px;
  }

  .button-continue {
    background: #2b8ff5;
    border-radius: 8px;
    color: #fff;
    border: none;
    height: 45px;
    width: 100%;

    &:disabled {
      background: #cacaca;
    }
  }

  .button-jump {
    width: 100%;
    background: none;
    border: none;
    font-size: 14px;
    color: #a2a8b2;
  }
`;

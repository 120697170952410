/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
// import history from '../../../../services/history';

import { Container, DivHeader, Div, DivButtons, DivCategories } from './styles';

import backBlackButton from '../../../../assets/icons/backBlackButton.svg';

import { updateProfileRequest } from '../../../../store/modules/user/actions';

import api from '../../../../services/api';

export default function CustomizeCategory() {
  const [category, setCategory] = useState([]);
  const [idSelected, setIdSelected] = useState(0);

  const history = useHistory();

  const dispatch = useDispatch();

  const { email, username } = useSelector(state => state.user.profile);

  useEffect(() => {
    async function getCategories() {
      const categories = await api.get('/categories');
      setCategory(categories.data);
    }

    getCategories();
  }, []);

  async function handleSubmit() {
    const data = {
      user: username,
      email,
      category_id: idSelected,
    };
    dispatch(updateProfileRequest(data));

    history.push('/responsivecustomizeplan');
    // history.push('/');
  }

  return (
    <Container>
      <DivHeader>
        <div className="div-space">
          <Link to="/responsivecustomizesocialmedia">
            <img src={backBlackButton} alt="back-button" />
          </Link>
        </div>
        <div className="div-text">{/* <h1>Produto</h1> */}</div>
        <div />
      </DivHeader>
      <Div>
        <div className="text">
          <h1>Qual o seu tipo de negócio?</h1>
        </div>
        <DivCategories>
          {category &&
            category.map(cat => (
              <button
                type="button"
                className={cat.id === idSelected ? 'selected' : ''}
                onClick={() => setIdSelected(cat.id)}
              >
                {cat.name}
              </button>
            ))}
        </DivCategories>

        <DivButtons block={idSelected === 0}>
          <button
            className="button-continue"
            type="button"
            onClick={handleSubmit}
            disabled={idSelected === 0}
          >
            Continuar
          </button>

          {/* <Link to="/">
            <button className="button-jump" type="button">
              Pular essa etapa
            </button>
          </Link> */}
        </DivButtons>
      </Div>
    </Container>
  );
}

import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  top: 0;
  left: 0;
`;

export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  > img {
    width: 30px;
    height: 30px;
  }
`;

export const Div = styled.div`
  display: ${props => (props.showModal ? 'flex' : 'none')};
  width: 800px;
  height: 80%;
  background: rgb(255, 255, 255);
  z-index: 550;
  position: fixed;
  flex-direction: column;
  top: calc(50vh - 45%);
  left: calc(50vw - 400px);
  box-shadow: rgb(172 161 161 / 25%) 16px 34px 58px;
  border-radius: 16px;

  ${props =>
    props.moreOptions &&
    css`
      background: rgb(255, 255, 255);
      height: 80%;
      z-index: 550;
      position: fixed;
      display: ${props => (props.showModal ? 'flex' : 'none')};
      flex-direction: column;
      left: calc(50vw - 400px);
      box-shadow: rgba(172, 161, 161, 0.25) 16px 34px 58px;
    `}
`;

export const DivBody = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  padding-bottom: ${props =>
    props.hasImage ? (props.manyImages ? '192px' : '139px') : '79px'};
  position: relative;
  overflow-y: scroll;
  border-radius: 16px;
  padding-top: 16px;
`;

export const GeneralDiv = styled.div`
  padding: ${props => (props.nopadding ? '0' : '0 24px')};
  width: ${props => props.width || '100%'};
  border-style: none;
  border-width: 1px;
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: column;
  background: #fff;
  position: relative;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  span {
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }

  .warning {
    color: #ffb525;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }

  .div-line {
    display: flex;
  }

  .div-price {
    display: flex;
    flex-direction: column;
    width: 33%;
    margin-left: 24px;
  }

  .disabled {
    opacity: 50%;
  }

  .currency-input-price {
    width: 100%;
    max-width: 220px;
    border-bottom: none;
    background: #f9f9f9;
    padding: 12px 12px;
    border-radius: 0 8px 8px 0;
    font-size: 13px;
    border: solid 0.5px #f9f9f9;

    &:focus {
      /* border-bottom: 0.5px solid rgb(43 143 246); */
      border: 0.5px solid rgb(43, 143, 246);
    }

    &::placeholder {
      color: #d1d1d1;
    }
  }

  .div-description {
    width: 100%;
    height: 100%;

    textarea {
      resize: none;
      height: 80px;
      width: ${props => props.width || '100%'};
      border-bottom: none;
      background: #f9f9f9;
      padding: 12px 12px;
      border-radius: 8px;
      font-size: 13px;
      margin-top: 4px;
      border: solid 0.5px #f9f9f9;

      &:focus {
        border: 0.5px solid rgb(43, 143, 246);
      }

      &::placeholder {
        color: #d1d1d1;
      }
    }
  }

  .div-select {
    width: 100%;
    margin-bottom: 14px;
  }
`;

export const DivCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100px;
    margin-right: 8px;

    > img {
      width: 15px;
    }
  }

  .master {
    color: #fff;
    background: #9d9d9d;
    border: 0.5px solid #9d9d9d;

    > span {
      color: #fff;
    }
  }

  .selected {
    color: #fff;
    background: #2b8ff6;
    border: 0.5px solid #2b8ff6;

    > span {
      color: #fff;
    }
  }
`;

export const ButtonSeeAll = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  width: auto !important;
  border: none !important;
  /* identical to box height */
  color: #9d9d9d;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background: #efefef;
  border: 0.5px solid #efefef;
  box-sizing: border-box;
  border-radius: 132px;
  height: 36px;
  padding: 0 18px;
  margin-bottom: 8px;
  margin-right: 8px;
  font-size: 13px;
  cursor: pointer;

  > span {
    font-size: 9px;
    color: #838383;
    font-weight: normal;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export const DivOpenClose = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 32px;
  border-top: ${props => (props.first && '0.5px solid #EFEFEF') || 'none'};
  border-bottom: 0.5px solid #efefef;
  margin-top: ${props => (props.first && '32px') || '0'};
  cursor: pointer;

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    margin-top: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    > img {
      position: absolute;
      right: 0;
    }

    > div {
      display: flex;
      flex-direction: column;

      > span {
        color: #838383;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 4px;
      }

      > div {
        > strong {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
          margin-right: 4px;
        }

        > span {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          color: #9d9d9d;
        }
      }
    }

    .blue {
      color: #2b8ff5;
      border: 1px solid #2b8ff5;
    }
  }
`;

export const DivStock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .disabled {
    opacity: 50%;
  }

  > span {
    font-size: 13px;
    color: #838383;
    margin-bottom: 12px;
    font-weight: 400 !important;
  }

  > div {
    display: flex;

    > strong {
      font-style: normal;
      font-weight: 500 !important;
      font-size: 13px;
      color: #000000;
      margin-bottom: 4px;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #efefef;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 6px;
      height: 34px;
      width: 32px;

      &:disabled {
        opacity: 50%;
      }
    }

    > input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      width: 60px;
      background: #f9f9f9;
      border: 0.5px solid #f9f9f9;
      box-sizing: border-box;
      border-radius: 6px;
      margin: 0 6px;
      text-align: center;

      &:disabled {
        opacity: 50%;
      }
    }
  }
`;

export const ButtonAddVariations = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: #2b8ff5;
  height: 44px;
  width: 180px;
  border: none;
  margin-top: 8px;
  border-radius: 8px;

  > svg {
    margin-right: 6px;
  }
`;

export const TableVariations = styled.table`
  width: 100%;
  border-spacing: 0 7px;
  /* display: block; */
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 20px;

  thead {
    background: #fafafa;

    .switch {
      margin-left: 6px;
    }

    .disabled-tr {
      opacity: 50%;
    }

    .title {
      padding-left: 4px;
    }

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      display: none !important;
      background-color: transparent;
    }

    > thead {
      background: #fafafa;
      border-radius: 7px;
      height: 45px;
    }

    th {
      padding: 0 12px;
      font-weight: 500;
      font-size: 13px;
      color: #010101;
      padding: 12px 16px;

      &:first-child {
        border-collapse: collapse;
        border-radius: 8px 0 0 8px;
        border-style: hidden; /* hide standard table (collapsed) border */
      }

      &:last-child {
        border-collapse: collapse;
        border-radius: 0 8px 8px 0;
        border-style: hidden; /* hide standard table (collapsed) border */
      }
    }

    > tr {
      text-align: left;
    }
  }

  > tbody {
    .checkbox {
      padding-left: 0;
    }

    .title {
      padding-left: 16px;
      padding-right: 16px;
    }

    td {
      padding: 0 12px 7px 12px;
      border-bottom: 0.5px solid #d8dee5;
      font-size: 13px;
      color: #010101;
    }
  }
`;

export const DivInputValueVariation = styled.div`
  display: flex;
  width: 97px;

  > div {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    background: #efefef;
    border-radius: 8px 0 0 8px;
    font-weight: 500;
    font-size: 13px;

    ${props =>
      props.emptyPrice &&
      css`
        background: rgb(255, 48, 60, 0.2);
        color: #ff303c;
      `}
  }

  > input {
    background: #f9f9f9;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 0 8px;
    width: 65px;
    font-size: 13px;

    ${props =>
      props.emptyPrice &&
      css`
        background: rgb(255, 48, 60, 0.1);
        color: #ff303c;
      `}

    &::placeholder {
      font-size: 13px;
      color: #cfcfcf;
    }
  }
`;

export const DivInputStockVariation = styled.div`
  display: flex;

  .no-stock {
    /* background: rgba(255, 48, 60, 0.1); */
    color: #ff303c;
  }

  > button {
    background: #efefef;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 6px;
    width: 32px;
    height: 34px;
  }

  > input {
    background: #f9f9f9;
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    width: 46px;
    margin: 0 6px;
    font-size: 13px;
    color: #101010;

    &::placeholder {
      font-size: 13px;
      color: #cfcfcf;
    }
  }
`;

export const DivDimensions = styled.div`
  display: flex;
  padding-top: 1px;
  margin-bottom: 24px;
  margin-top: 16px;

  > div {
    display: flex;
    flex-direction: column;
    width: 25%;
    padding: 0 8px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 6px;
      min-width: 80px;
    }

    > span {
      color: ${props => (props.weight >= 10 ? '#FF303C' : '#838383')};
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
      text-align: center;
    }

    > div {
      display: flex;

      > input {
        text-align: center;
        border: none;
        background: #f9f9f9;
        box-sizing: border-box;
        border-radius: 8px 0 0 8px;
        height: 44px;
        width: 100%;

        &::placeholder {
          font-size: 13px;
          color: #afafaf;
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        background: #efefef;
        font-size: 13px;
        color: #101010;
        border-radius: 0 8px 8px 0;
      }
    }
  }
`;

export const Message = styled.div`
  display: flex;
  border-left: ${props => props.borderLeft};
  width: 100%;
  padding: 16px;
  background: ${props => props.background};
  align-items: center;
  margin: ${props => props.margin};

  > svg {
    margin-right: 16px;
    width: 18px;
    height: 18px;
  }

  > span {
    color: ${props => props.color};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    flex: 1;

    > b {
      font-weight: 600;
    }
  }
`;

export const DivProduct = styled.div`
  display: flex;
  flex-direction: column;
  width: 66%;
`;

export const DescriptionSize = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #9d9d9d !important;
  /* margin-top: 4px; */
  font-weight: 400 !important;
  font-size: 11px !important;
`;

export const DivFooter = styled.div`
  display: flex;
  width: 100%;
  background: #f7f8f9;
  padding: 0 24px;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-top: 0.5px solid #efefef;

  span {
    display: none;
  }
`;

export const DivImages = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* flex-wrap: nowrap; */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const LabelImage = styled.label`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;

export const DivButton = styled.div`
  width: 100%;
  border-style: none;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;

  .button-img {
    background: transparent;
    border: 1px dashed rgb(165, 169, 177);
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;
    width: 45px;
    height: 45px;
    margin-right: 16px;
  }

  button {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    border-radius: 8px;
    background: rgb(43, 143, 246);
    color: rgb(255, 255, 255);
    border: none;
    font-size: 14px;
    height: 45px;
    width: 303px;
    font-weight: 500;
  }

  .button-save {
    > img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const Input = styled.input`
  /* width: ${props => props.width || '100%'};
  border: none;
  border-bottom: 0.5px solid #d8dee5;
  background: none;
  padding: 8px 0;
  font-size: 13px;
  margin-bottom: 24px; */

  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  margin-bottom: 24px;
  margin-top: 4px;
  border: solid 0.5px #f9f9f9;

  &:focus {
    /* border-bottom: 0.5px solid rgb(43 143 246); */
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const DivInputPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  /* padding-bottom: 32px; */
  opacity: ${props => (props.disabled ? '50%' : '100%')};

  > div {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
  }

  /* > input {
    color: ${props => (props.warning ? '#FFB525' : '#101010')};
  } */
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  > span {
    color: #0d85ff;
    margin-left: 4px;
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  margin-top: 4px;
  font-size: 13px;

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: rgb(216, 222, 229);
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1wa3eu0-placeholder {
    color: #bfbfbf;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }
`;

export const DivMore = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 24px;
`;

export const DivTitle = styled.div`
  display: flex;
`;

export const ButtonMore = styled.button`
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #2b8ff5;
  background: transparent;
  border: none;
  padding-bottom: 16px;
  margin-top: ${props => (props.margin ? '24px' : '0')};
`;

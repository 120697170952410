import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { toast } from 'react-toastify';

import api from '../../../../../services/api';

import Header from '../Header';

import { updatePaymentMethodsRequest } from '../../../../../store/modules/linkPrev/actions';

import { Container, Body, Div, DivInput, Card } from './styles';

function FlatRate({ handleClickBackButton }) {
  const [profile, setProfile] = useState([]);
  const [inputWhatsApp, setInputWhatsApp] = useState();

  const [money, setMoney] = useState(false);
  const [credit_card, setCreditCard] = useState(false);
  const [debit_card, setDebitCard] = useState(false);
  const [billet, setBillet] = useState(false);
  const [bank_transfer, setBankTransfer] = useState(false);
  const [picpay, setPicpay] = useState(false);
  const [mealTicket, setMealTicket] = useState(false);
  const [pix, setPix] = useState(false);

  const dispatch = useDispatch();

  const { payment_methods } = useSelector(state => state.linkPrev);

  useEffect(() => {
    async function load() {
      await api.get('/getuser').then(response => {
        setProfile(response.data);
        setInputWhatsApp(response.data.whatsapp);
      });
      await api.get('/paymentmethods').then(response => {
        setMoney(response.data && response.data.money);
        setCreditCard(response.data && response.data.credit_card);
        setDebitCard(response.data && response.data.debit_card);
        setBillet(response.data && response.data.billet);
        setBankTransfer(response.data && response.data.bank_transfer);
        setPicpay(response.data && response.data.picpay);
        setMealTicket(response.data && response.data.meal_ticket);
        setPix(response.data && response.data.pix);
      });
    }

    load();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleUpdateWhatsApp() {
    // eslint-disable-next-line no-restricted-globals
    const isNotNumber = isNaN(inputWhatsApp);

    if (isNotNumber || inputWhatsApp.trim().length < 10) {
      toast.error('Digite um whatsapp válido Ex: 51999999999');
      return;
    }

    await api.put('/updateuser', {
      whatsapp: inputWhatsApp,
      has_whatsapp: profile && profile.has_whatsapp,
    });

    setProfile({ ...profile, whatsapp: inputWhatsApp });
  }

  async function handleUpdatePaymentMethods(value) {
    try {
      switch (value) {
        case 'money': {
          setMoney(!money);

          const data = {
            id: payment_methods && payment_methods.id,
            money: !money,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'credit_card': {
          setCreditCard(!credit_card);

          const data = {
            id: payment_methods && payment_methods.id,
            credit_card: !credit_card,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'debit_card': {
          setDebitCard(!debit_card);

          const data = {
            id: payment_methods && payment_methods.id,
            debit_card: !debit_card,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'billet': {
          setBillet(!billet);

          const data = {
            id: payment_methods && payment_methods.id,
            billet: !billet,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'bank_transfer': {
          setBankTransfer(!bank_transfer);

          const data = {
            id: payment_methods && payment_methods.id,
            bank_transfer: !bank_transfer,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'picpay': {
          setPicpay(!picpay);

          const data = {
            id: payment_methods && payment_methods.id,
            picpay: !picpay,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'meal_ticket': {
          setMealTicket(!mealTicket);

          const data = {
            id: payment_methods && payment_methods.id,
            meal_ticket: !mealTicket,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }
        case 'pix': {
          setPix(!pix);

          const data = {
            id: payment_methods && payment_methods.id,
            pix: !pix,
          };

          dispatch(updatePaymentMethodsRequest(data));

          break;
        }

        default:
      }
    } catch (err) {
      // toast.error('Erro ao processar requisição');
    }
  }

  function handleClickBack() {
    handleClickBackButton();
  }

  return (
    <Container>
      <Header
        title="Configuração do WhatsApp"
        handleClickBack={handleClickBack}
      />
      <Body>
        <Div>
          <strong>Número do WhatsApp</strong>

          <span>
            Através desse número seus clientes enviarão os pedidos e entrarão em
            contato
          </span>

          <DivInput>
            <div>+55</div>
            <input
              type="tel"
              value={inputWhatsApp}
              maxLength={11}
              onChange={e => setInputWhatsApp(e.target.value)}
              onBlur={handleUpdateWhatsApp}
            />
          </DivInput>

          <strong>Formas de pagamento aceitas</strong>

          <span>
            Selecione as formas de pagamento aceitas, assim seus clientes
            poderão selecionar como querem pagar ao enviar o pedido
          </span>

          <Card>
            <div className="div-buttons-payment-methods">
              <button
                type="button"
                className={money ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('money')}
              >
                Dinheiro
              </button>
              <button
                type="button"
                className={credit_card ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('credit_card')}
              >
                Cartão de crédito
              </button>
              <button
                type="button"
                className={billet ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('billet')}
              >
                Boleto
              </button>
              <button
                type="button"
                className={bank_transfer ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('bank_transfer')}
              >
                Transferência bancária
              </button>
              <button
                type="button"
                className={debit_card ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('debit_card')}
              >
                Cartão de débito
              </button>
              <button
                type="button"
                className={picpay ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('picpay')}
              >
                Picpay
              </button>
              <button
                type="button"
                className={mealTicket ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('meal_ticket')}
              >
                Vale refeição
              </button>
              <button
                type="button"
                className={pix ? 'selected' : ''}
                onClick={() => handleUpdatePaymentMethods('pix')}
              >
                Pix
              </button>
            </div>
          </Card>
        </Div>
      </Body>
    </Container>
  );
}

export default FlatRate;

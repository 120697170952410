import axios from 'axios';
import { Connection } from './config';
//
const api = axios.create({
  baseURL: Connection.url_server,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Access-Control-Allow-Headers': 'Authorization',
  //   'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
  //   'Access-Control-Allow-Credentials': true,
  //   crossorigin: true,
  //   'Content-Type': 'application/json;charset=UTF-8',
  // },
  //
});
//
export default api;

import React, { useCallback } from 'react';

import CancelImage from '../../../../../../assets/icons/cancelx.svg';
import NoImage from '../../../../../../assets/icons/noimage.png';

import {
  ContainerGeral,
  Container,
  Div,
  DivTitle,
  DivProductsOrder,
  DivProductOrder,
} from './styles';

function SeeProductsModal({ showModal, Modal, products }) {
  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivTitle>
          <div className="div-space" />
          <div>
            <span>Informações do pedido</span>
          </div>
          <div className="div-space">
            <img src={CancelImage} alt="cancelImage" onClick={handleModal} />
          </div>
        </DivTitle>
        <DivProductsOrder>
          <span>Produtos</span>

          <DivProductOrder>
            {products &&
              products.map(p => (
                <div key={p.id}>
                  <img
                    src={
                      (p.images && p.images[0] && p.images[0].image_url) ||
                      NoImage
                    }
                    alt="images"
                  />

                  <div>
                    <strong>
                      {p.amount > 1 ? `${p.amount}x` : ''} {p.title}
                    </strong>
                    <span>{p.promotion_price || p.price}</span>
                  </div>
                </div>
              ))}
          </DivProductOrder>
        </DivProductsOrder>
      </Div>
    </ContainerGeral>
  );
}

export default SeeProductsModal;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';

import ProPlanModal from '../../../../components/Popups/ProPlan';
import LoadingContainer from '../../../../components/LoadingContainer';

import api from '../../../../services/api';

import ModalInsertOrUpdateCoupon from './ModalInsertOrUpdateCoupon';
import Plans from '../Plans';

import { Container, Body, DivText, DivCards, Card } from './styles';

function Coupons() {
  const [coupons, setCoupons] = useState([]);
  const [couponId, setCouponId] = useState(0);
  const [pro, setPro] = useState();
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [showCardPro, setShowCardPro] = useState(false);

  const [initialLoading, setInitialLoading] = useState(true);

  const [page, setPage] = useState(0);

  useEffect(() => {
    async function load() {
      try {
        setInitialLoading(true);

        const proplan = await api.get('/proplan');
        setPro(proplan.data);

        await api.get('/coupons').then(response => setCoupons(response.data));

        setInitialLoading(false);
      } catch (err) {
        setInitialLoading(false);
      }
    }

    load();
  }, []);

  function ProPlanPopUp(pro_modal, confirm) {
    setShowProPlanPopUp(pro_modal);

    if (confirm) {
      setShowCardPro(true);
    }
  }

  async function handleClickBackButton() {
    setPage(0);

    await api.get('/coupons').then(response => setCoupons(response.data));
  }

  async function onChangeCheck(id) {
    const index = coupons.findIndex(t => t.id === id);
    const arr = [...coupons];

    arr[index].active = !arr[index].active;

    setCoupons(arr);

    await api.put(`/coupons/${id}`, {
      active: arr[index].active,
      code: arr[index].code,
    });
  }

  function handleAddOrUpdateCoupon(id) {
    if (pro === 0) {
      setShowProPlanPopUp(true);
      return 0;
    }

    setCouponId(id);
    setPage(1);
    return 0;
  }

  return (
    <>
      {(showCardPro && <Plans />) || (
        <Container>
          {page === 0 && (
            <>
              <LoadingContainer loading={initialLoading} />
              <Body>
                <ProPlanModal
                  showModal={showProPlanPopUp}
                  Modal={ProPlanPopUp}
                  is_desktop
                />

                <DivText>
                  <strong>Cupons de desconto</strong>
                  <span>
                    Adicione cupons de desconto para que seus clientes possam
                    utilizar na hora da compra
                  </span>

                  <button
                    type="button"
                    onClick={() => handleAddOrUpdateCoupon(0)}
                  >
                    Adicionar cupom
                  </button>
                </DivText>

                <DivCards>
                  {coupons &&
                    coupons.map(c => (
                      <Card>
                        <div
                          className="left"
                          onClick={
                            pro >= 1
                              ? () => handleAddOrUpdateCoupon(c.id)
                              : () => setShowProPlanPopUp(true)
                          }
                        >
                          <strong>{c.code}</strong>

                          {(c.type === 'I' && (
                            <span>R$ {c.value} de desconto</span>
                          )) || <span>{Math.trunc(c.value)}% de desconto</span>}
                        </div>

                        <div className="right">
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={c.active}
                              onChange={() => onChangeCheck(c.id)}
                            />
                            <span className="slider round" />
                          </label>
                        </div>
                      </Card>
                    ))}
                </DivCards>
              </Body>
            </>
          )}

          {page === 1 && (
            <ModalInsertOrUpdateCoupon
              id={couponId}
              handleClickBackButton={handleClickBackButton}
            />
          )}
        </Container>
      )}
    </>
  );
}

export default Coupons;

import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  width: ${props => (props.isDesktop ? '800px' : '100%')};
  height: ${props => (props.isDesktop ? '67%' : '100vh')};
  background: #fff;
  z-index: 200;
  border-radius: ${props => (props.isDesktop ? '22px 22px 0 0' : '0')};

  > header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    /* top: 30px; */
    top: ${props => (props.isDesktop ? 'calc(50vh - 45%)' : '0')};
    width: ${props => (props.isDesktop ? '800px' : '100%')};
    height: ${props => (props.isDesktop ? '48px' : '55px')};
    border-bottom: 0.5px solid #d8dee5;

    > img {
      position: absolute;
      left: 24px;
      cursor: pointer;
    }

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }
  }

  > footer {
    display: flex;
    position: fixed;
    bottom: ${props => (props.isDesktop ? 'calc(50vh - 35%)' : '0')};
    width: ${props => (props.isDesktop ? '800px' : '100%')};
    padding: 24px 16px;
    background: #fff;
    border-radius: ${props => (props.isDesktop ? '0 0 22px 22px' : '0')};

    > button {
      width: 100%;
      height: 50px;
      background: #2b8ff5;
      border-radius: 8px;
      color: #fff;
      border: none;
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 100px 16px;
  margin-top: 55px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > span {
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #838383;
    margin-bottom: 16px;
  }

  > button {
    margin: auto;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    background: none;
    border: none;
    color: #2b8ff5;
    margin-bottom: 16px;
  }

  .cardSelected {
    background: rgba(43, 143, 245, 0.04);
    border: 1px solid #2b8ff5;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #d8dee5;
  border-radius: 8px;
  padding: 20px 16px;
  margin-bottom: 12px;

  > span {
    font-size: 13px;
    line-height: 19px;
    color: #000000;
  }

  > header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #010101;
    }
  }

  > div {
    margin-top: 8px;
    width: 100%;
    /* overflow-x: scroll; */
    /* overflow-y: hidden; */
    /* white-space: nowrap; */
    /* -webkit-overflow-scrolling: touch; */
    display: flex;
    flex-wrap: wrap;

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      display: none !important;
      background-color: transparent;
    }

    .selected {
      background: #101010;
      color: #fff;
      border-color: #000;
    }

    > button {
      min-width: 38px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 0.5px solid #d8dee5;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;
    }

    > div {
      border: 0.5px solid #d8dee5;
      box-sizing: border-box;
      border-radius: 10px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      margin-right: 8px;
      margin-bottom: 8px;
      cursor: pointer;

      &:first-of-type {
        margin-left: 8px;
      }
    }
  }
`;

export const AddVariation = styled.div`
  display: flex;
  position: fixed;
  height: ${props => (props.isDesktop ? '80vh' : '100vh')};
  /* width: 100%; */
  width: ${props => (props.isDesktop ? '800px' : '100%')};
  background: rgba(227, 229, 236, 0.65);
`;

export const DivAddVariation = styled.div`
  display: flex;
  position: ${props => (props.isDesktop ? 'relative' : 'fixed')};
  flex-direction: column;
  /* bottom: 0; */
  height: 75vh;
  top: ${props => (props.isDesktop ? 'calc(50vh - (800px / 2))' : 'auto')};
  bottom: ${props => (props.isDesktop ? 'calc(50vh - (400px / 2))' : '0')};
  width: 100%;
  background: #fff;
  border-radius: ${props => (props.isDesktop ? '20px' : '20px 20px 0 0')};

  > header {
    min-height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    > strong {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
    }

    > img {
      position: absolute;
      right: 24px;
      cursor: pointer;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    padding: 0 16px 82px 16px;
    /* height: 450px; */

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #000000;
      margin-bottom: 2px;
    }

    > span {
      font-size: 11px;
      line-height: 16px;
      color: #9d9d9d;
      margin-bottom: 8px;
    }

    > button {
      display: flex;
      align-self: flex-start;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
      margin-bottom: 16px;
    }

    > input {
      background: #f9f9f9;
      border-radius: 8px;
      font-size: 13px;
      line-height: 19px;
      color: #101010;
      border: none;
      min-height: 42px;
      padding: 0 12px;
      margin-bottom: 16px;
    }
  }

  > footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 16px;
    border-top: 0.5px solid #d8dee5;
    background: #fff;
    border-radius: ${props => (props.isDesktop ? '0 0 20px 20px' : '0')};

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      background: #2b8ff5;
      border-radius: 8px;
      height: 50px;
      border: none;
      color: #fff;
    }
  }
`;

export const DivItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: scroll;
  max-height: ${props => (props.create ? '40vh' : '42vh')};
  padding-bottom: 70px;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const DivItem = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  margin-bottom: 12px;
  padding-right: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 16px;
  }

  > input {
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    border-radius: 8px;
    font-size: 13px;
    line-height: 19px;
    color: #101010;
    height: 42px;
    padding: 0 12px;
    width: 100%;

    &:focus {
      border: 1px solid #2b8ff5;
      background: #fff;
    }
  }

  > img {
    margin-left: 20px;
    width: 12px;
  }

  > button {
    display: flex;
    align-self: flex-start;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
    border: none;
    background: none;
  }
`;

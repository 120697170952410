import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fafafa;
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 87px 0 0 0;
  margin-bottom: 24px;
  background: #fafafa;

  > span {
    margin-top: 8px;
    font-size: 13px;
  }

  .dots {
    display: flex;
    /* padding: 10px 0; */
    margin-top: 16px;
    justify-content: center;
  }

  .dot {
    border: none;
    width: 8px;
    height: 8px;
    background: #efefef;
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }

  .dot:focus {
    outline: none;
  }

  .dot.active {
    background: #0d85ff;
  }
`;

export const DivPlanOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  padding: 0 20px;
  margin-top: 24px;
`;

export const DivLoader = styled.div`
  margin-top: 32px;
`;

export const DivButtonsPlanOptions = styled.div`
  display: flex;
  width: 100%;
  border-radius: 100px;
  background: #efefef;
  height: 50px;
  padding: 4px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    border-radius: 100px;
    border: none;
  }
`;

export const MonthlyButton = styled.button`
  background: #efefef;
  color: #000000;

  ${props =>
    props.active &&
    css`
      background: #ffffff;
      font-weight: 600;
      color: #000000;
    `}
`;

export const YearlyButton = styled.button`
  background: #efefef;
  color: #000000;

  ${props =>
    props.active &&
    css`
      font-weight: 600;
      color: #000000;
      background: #ffffff;
    `}

  > div {
    border-radius: 24.5px;
    background: var(
      --Cores-new-gradiet,
      linear-gradient(220deg, #ff6534 12.15%, #ff0e9f 64.72%, #a925fa 102.33%)
    );
    font-size: 11px;
    color: #fff;
    font-weight: 500;
    padding: 2px 6px;
    margin-left: 6px;
  }
`;

export const DivDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff6c4b;
  margin-top: 24px;

  > span {
    margin-left: 6px;
  }
`;

export const DivProCards = styled.div`
  display: flex;
  width: 100%;

  > div {
    display: flex;
    margin-top: 16px;
    background: #fafafa;

    > div {
      padding-left: 16px;
    }

    /* display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; */
  }
`;

/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import Cookies from 'js-cookie';
import api from '~/services/api';
import {
  // CONVERSION_API_META_PIXEL_ID,
  // CONVERSION_API_META_TOKEN,
  Connection,
} from '~/services/config';
// import { generateHashSHA256 } from './crypto';

export async function CheckIfHaveNewTtclid({ ttclid }) {
  try {
    const userregistrationtracking = await api.get('/userregistrationtracking');

    if (userregistrationtracking && userregistrationtracking.data) {
      const { tiktok_ttclid } = userregistrationtracking.data.trackings;

      if (ttclid && ttclid !== '' && ttclid !== tiktok_ttclid) {
        const result = await api.put('/userregistrationtracking', {
          tiktok_ttclid: ttclid,
        });

        return result.data.trackings.tiktok_ttclid;
      }

      return userregistrationtracking.data.trackings.tiktok_ttclid;
    }

    return ttclid;
  } catch (err) {
    console.log('Erro ao verificar ttclid', err);
  }
}

export async function ConversionApiTiktok({
  eventId,
  eventName,
  email,
  phone,
  externalId,
  ttclid,
}) {
  try {
    const ttp = Cookies.get('_ttp');

    const tiktok_ttclid =
      ttclid || (await CheckIfHaveNewTtclid({ ttclid: Cookies.get('ttclid') }));

    const data = {
      eventName,
      ttp,
      ttclid: tiktok_ttclid,
    };

    if (eventId) {
      data.eventId = eventId;
    }

    if (externalId) {
      data.externalId = externalId;
    }

    if (email) {
      data.email = email;
    }

    if (phone) {
      data.phone = phone;
    }

    try {
      await axios.get('https://api64.ipify.org/?format=json').then(response => {
        if (response.data && response.data.ip) {
          data.clientIpAddress = response.data.ip;
        }
      });
    } catch (err) {
      console.log(err);
    }

    const result = await axios.post(
      `${Connection.url_conversion_api}/tiktok/apiconversion`,
      data
    );

    return result;
  } catch (err) {
    console.log('Erro ao enviar evento do Pixel Tiktok', err);
  }
}

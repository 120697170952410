import { loadStripe } from '@stripe/stripe-js';
import api from '~/services/api';
// import ReactPixel from 'react-facebook-pixel';
// import toast from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';
import { STRIPE_PK } from '../services/config';
import { CheckIfHaveNewFbc, ConversionApiMeta } from './meta';
import { ConversionApiTiktok } from './tiktok';

export async function handleBuyStripe(
  userId,
  email,
  whatsapp,
  stripe_price_id,
  urlCancel
) {
  const stripePromise = loadStripe(STRIPE_PK);

  const subscriptionObj = await api.get('/subscription');
  const subscription = subscriptionObj && subscriptionObj.data;

  if (
    !subscription ||
    (subscription && subscription.payment_status !== 'active')
  ) {
    const uuid = uuidv4();

    // const fbp = Cookies.get('_fbp');
    const fbc = await CheckIfHaveNewFbc({ fbc: Cookies.get('_fbc') });

    // ReactPixel.fbq(
    //   'track',
    //   'InitiateCheckout',
    //   {
    //     external_id: userId,
    //     fbp,
    //     fbc,
    //   },
    //   {
    //     eventID: uuid,
    //   }
    // );

    try {
      ConversionApiMeta({
        eventId: uuid,
        eventName: 'InitiateCheckout',
        email,
        phone: whatsapp,
        externalId: userId,
        fbc,
      });
    } catch (err) {
      console.log('Erro ao enviar InitiateCheckout da Meta', err);
    }

    try {
      ConversionApiTiktok({
        eventId: uuid,
        eventName: 'InitiateCheckout',
        email,
        phone: whatsapp,
        externalId: userId,
      });
    } catch (err) {
      console.log('Erro ao enviar InitiateCheckout do Tiktok', err);
    }

    const stripe = await stripePromise;

    const session = await api.post('/createsession', {
      email,
      stripe_price_id,
      urlCancel,
    });

    // if (type) sendWelcomeMessage();

    const sessionId = session.data.id;

    await stripe.redirectToCheckout({
      sessionId,
    });
  } else {
    // toast.success('Você já é PRO');
  }

  return 0;
}

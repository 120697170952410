import offstorePay from '~/services/offstorePay';

// {
// 	"business_activity": "8999", DEFAULT
// 	"user_id": 4,
// 	"seller_type": "businesses",
// 	"zoop_sell_plan_id": "plano_pro_offs_padrao_d1", DEFAULT
// 	"updateUserData": {
// 		"name": "Henrique Pires",
// 		"document_number": "35441342000121",
// 		"birthdate": "07/02/1999",
// 		"postal_code": "13290612"
// 	}
// }

export function useGetSellerInfo() {
  const handleGetSellerInfo = async ({ userId, document }) => {
    const { data } = await offstorePay.get(`/seller/${userId || document}`);
    return data;
  };

  const handleChangeOffstorePay = async ({
    userId,
    document,
    offstore_pay_enabled,
  }) => {
    const data = await offstorePay.put(`/seller/${userId || document}`, {
      offstore_pay_enabled,
    });
    return data;
  };

  const handleDeleteSeller = async ({ userId, document }) => {
    const data = await offstorePay.delete(`/seller/${userId || document}`);
    return data;
  };

  const handleGetAccountRepass = async ({ zoopSellerId }) => {
    const data = await offstorePay.get(
      `/seller/accountRecords/${zoopSellerId}`
    );
    return data;
  };

  const handleGetTransactions = async ({
    zoopSellerId,
    page,
    lastDays = 1,
    startDate,
    endDate,
  }) => {
    const { data } = await offstorePay.post(`/seller/transactions`, {
      seller_id: zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
      limit: 12,
    });

    return data;
  };

  const handleGetDisputes = async ({
    zoopSellerId,
    page,
    lastDays = 1,
    startDate,
    endDate,
  }) => {
    const { data } = await offstorePay.post(`/seller/transactions`, {
      seller_id: zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
      limit: 12,
      status: 'dispute',
    });

    return data;
  };

  const handleGetChargeBacks = async ({
    zoopSellerId,
    page,
    lastDays = 1,
    startDate,
    endDate,
  }) => {
    const { data } = await offstorePay.post(`/seller/transactions`, {
      seller_id: zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
      limit: 12,
      status: 'charged_back',
    });

    return data;
  };

  const handleGetTransfers = async ({
    zoopSellerId,
    page,
    lastDays = 1,
    startDate,
    endDate,
    status,
    confirmed,
    canceled,
  }) => {
    const { data } = await offstorePay.post(`/seller/transfers`, {
      seller_id: zoopSellerId,
      page,
      lastDays,
      startDate,
      endDate,
      limit: 12,
      status,
      confirmed,
      canceled,
    });

    return data;
  };

  const handleGetFutureTransfers = async ({
    zoopSellerId,
    page,
    nextDays = 1,
    startDate,
    endDate,
  }) => {
    const { data } = await offstorePay.post(`/seller/futureTransfers`, {
      seller_id: zoopSellerId,
      page,
      nextDays,
      startDate,
      endDate,
      limit: 12,
    });

    return data;
  };

  return {
    handleGetSellerInfo,
    handleChangeOffstorePay,
    handleDeleteSeller,
    handleGetAccountRepass,
    handleGetTransactions,
    handleGetTransfers,
    handleGetChargeBacks,
    handleGetFutureTransfers,
    handleGetDisputes,
  };
}

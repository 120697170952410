/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// import ReactPixel from 'react-facebook-pixel';

import { useSelector } from 'react-redux';

import { CheckIfHaveNewFbc, ConversionApiMeta } from '~/functions/meta';
import Cookies from 'js-cookie';
import { ConversionApiTiktok } from '~/functions/tiktok';
import ImageIcon from '../../../assets/icons/paymentsuccess/image.svg';

import { Container } from './styles';

function PaymentSuccess() {
  const uuid = uuidv4();

  const { id, email, whatsapp } = useSelector(
    state => state.user && state.user.profile && state.user.profile
  );
  const injectGA = () => {
    if (typeof window === 'undefined') {
      return;
    }
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());

    // gtag('config', 'AW-491873348');
    gtag('event', 'conversion', {
      send_to: 'AW-491873348/unLrCP7Jo9kCEMTIxeoB',
    });

    gtag('event', 'success_payment_page_btn', {
      debug_mode: false,
    });
  };

  useEffect(() => {
    async function load() {
      // const fbp = Cookies.get('_fbp');
      const fbc = await CheckIfHaveNewFbc({ fbc: Cookies.get('_fbc') });

      // ReactPixel.fbq(
      //   'track',
      //   'Subscribe',
      //   {
      //     value: 29.9, // valor da assinatura
      //     currency: 'BRL', // moeda
      //     subscription_plan: 'Premium', // plano de assinatura
      //     subscription_period: 'Mensal',
      //     external_id: id,
      //     fbp,
      //     fbc,
      //   },
      //   {
      //     eventID: uuid,
      //   }
      // );

      try {
        ConversionApiMeta({
          eventName: 'Subscribe',
          eventId: uuid,
          email,
          phone: whatsapp,
          externalId: id,
          fbc,
        });
      } catch (err) {
        console.log('Erro ao enviar Subscribe Meta', err);
      }

      try {
        ConversionApiTiktok({
          eventName: 'CompletePayment',
          eventId: uuid,
          email,
          phone: whatsapp,
          externalId: id,
        });
      } catch (err) {
        console.log('Erro ao enviar CompletePayment Tiktok', err);
      }
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <script>{injectGA()}</script>
      <div>
        <img src={ImageIcon} alt="icon" />
        <h2>Parabéns</h2>
        <strong>Sua assinatura foi concluída 🎉</strong>

        <Link to="/">Voltar para o dashboard</Link>
      </div>
    </Container>
  );
}

export default PaymentSuccess;

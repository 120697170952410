import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  align-items: center;
  justify-content: space-between;
  /* min-height: calc(100vh - 55px); */
  height: 100vh;
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 24px 0;
  padding: 0 20px 0 20px;
  align-items: center;
  justify-content: center;
  /* min-height: 100%; */

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: ${props => (props.isPro ? 'none' : '100vh')};

    > img {
      max-width: 295px;
    }

    > strong {
      margin-bottom: 8px;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;

      background: -webkit-linear-gradient(
        239.62deg,
        #ff4026 8.67%,
        #9025fa 96.41%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    > span {
      text-align: center;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #838383;
      /* margin-bottom: 32px; */
    }

    > a {
      background: #2b8ff5;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      color: #fff;
      width: 100%;
      height: 50px;
      margin-top: 16px;
      position: relative;
      font-weight: 500;
      font-size: 14px;

      > img {
        position: absolute;
        right: 20px;
      }
    }
  }
`;

export const DivFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* position: fixed; */
  /* bottom: 0px; */
  padding: 0 24px;
  background: #fff;

  > a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #2b8ff5;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border: none;
    text-decoration: none;
  }
`;

export const LabelPro = styled.div`
  display: flex;
  position: fixed;
  right: 24px;
  top: 16px;
  z-index: 100;
  padding: 2px 6px;
  border: 1px solid #ffb525;
  border-radius: 4px;

  > span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #ffb525;
  }
`;

export const DivSpace = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
`;

export const DivUrlFeed = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: 100%;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    background: #fafafa;
    border-radius: 8px 0 0 8px;
    padding: 0 12px;
    font-size: 13px;
    color: #2b8ff5;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 90px; */
    height: 50px;
    border-radius: 0 8px 8px 0;
    border: none;
    font-size: 13px;
    padding: 0 12px;

    > img {
      margin-right: 6px;
    }
  }
`;

export const DivCodeHtml = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* height: 50px; */
  padding: 24px 0;
  background: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2b8ff5;
  border: none;
`;

export const ContainerModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;

export const Modal = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  /* height: 240px; */
  width: 100%;
  border-radius: 16px 16px 0 0;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  z-index: 12;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #131313;
    margin-bottom: 16px;
  }

  > span {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    height: 42px;
    padding: 0 12px;
    width: 100%;
    margin-bottom: 16px;
  }

  > button {
    background: #2b8ff5;
    border-radius: 8px;
    height: 50px;
    width: 100%;
    color: #fff;
    border: none;
  }
`;

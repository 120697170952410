/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';

import api from '~/services/api';
import { formatPrice } from '../../../util/format';

import {
  Container,
  Header,
  Body,
  DivInput,
  DivProducts,
  Product,
  DivSelect,
  Loader,
  StyledDebounceInput,
} from './styles';

import loaderGrey from '../../../assets/icons/loader-grey.gif';
import NoImage from '../../../assets/icons/noimage.png';

function SelectProductsModal({
  handleSelectedProducts,
  selectedProductIds: productIds,
}) {
  const [products, setProducts] = useState([]);
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [count, setCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [hasMoreProducts, setHasMoreProducts] = useState(true);
  const [search, setSearch] = useState('');

  function clearFields() {
    setPage(1);
    setTotalCount(0);
    setCount(0);
    setHasMoreProducts(true);
  }

  async function load() {
    clearFields();

    await api
      .post(`/linkspagination/?page=${1}`, {
        tagId: 0,
        stringSearch: search || '',
      })
      .then(response => {
        if (response.data && response.data.rows) {
          setProducts(response.data.rows);
          setCount(response.data.rows.length);
          setTotalCount(response.data.count);
        }
      });
  }

  async function loadMoreProducts() {
    if (count === totalCount) {
      setHasMoreProducts(false);

      return 0;
    }

    const currentPage = page;

    await api
      .post(`linkspagination/?page=${currentPage + 1}`, {
        tagId: 0,
        stringSearch: search || '',
      })
      .then(response => {
        if (response.data && response.data.rows) {
          setProducts([...products, ...response.data.rows]);
          setCount(count + response.data.rows.length);
          setPage(currentPage + 1);
        }
      });

    return 0;
  }

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    setSelectedProductIds(productIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function selectProduct(product) {
    if (selectedProductIds.find(p => p === product.id)) {
      const filterProducts = selectedProductIds.filter(p => p !== product.id);
      setSelectedProductIds(filterProducts);

      return 0;
    }

    const newProduct = [...selectedProductIds];
    newProduct.push(product.id);

    setSelectedProductIds(newProduct);

    return 0;
  }

  async function handleSubmit() {
    if (selectedProductIds.length === 0) {
      toast.error('Selecione no mínimo 1 produto');
      return 0;
    }

    handleSelectedProducts(false, selectedProductIds);

    return 0;
  }

  // useEffect(() => {
  //   LoadProducts(true);

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [search]);

  return (
    <Container>
      <Header>
        <strong>Selecionar produtos</strong>

        <div onClick={() => handleSelectedProducts(true)}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 15L14.9998 1.00022"
              stroke="#101010"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15 15L1.00022 1.00022"
              stroke="#101010"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </Header>

      <Body>
        <DivInput>
          <StyledDebounceInput
            placeholder="Pesquisar produtos"
            minLength={1}
            debounceTimeout={500}
            onChange={e => setSearch(e.target.value)}
            value={search}
          />
        </DivInput>

        <DivProducts>
          <InfiniteScroll
            dataLength={count} // This is important field to render the next data
            next={loadMoreProducts}
            hasMore={hasMoreProducts}
            height="530px"
            loader={
              <Loader>
                <img src={loaderGrey} alt="loader" />
              </Loader>
            }
          >
            {products &&
              products.map(p => (
                <Product
                  key={String(p.id)}
                  onClick={() => selectProduct(p)}
                  selected={selectedProductIds.find(sp => sp === p.id)}
                >
                  <img
                    src={
                      (p.images && p.images[0] && p.images[0].image_url) ||
                      NoImage
                    }
                    alt="noimage"
                  />

                  <div className="middle">
                    <strong>{p.title}</strong>

                    {p.gridattributes.length > 0 ? (
                      <span>Contém variações</span>
                    ) : (
                      <span>
                        {(p.promotion_price &&
                          formatPrice(p.promotion_price)) ||
                          (p.price && formatPrice(p.price)) ||
                          'Sob consulta'}
                      </span>
                    )}
                  </div>

                  <DivSelect
                    selected={selectedProductIds.find(sp => sp === p.id)}
                  >
                    <svg
                      width="11"
                      height="8"
                      viewBox="0 0 11 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 1L3.8125 7L1 4.27273"
                        stroke="white"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </DivSelect>
                </Product>
              ))}
          </InfiniteScroll>
        </DivProducts>
      </Body>

      <footer>
        <button type="button" onClick={handleSubmit}>
          Confirmar
        </button>
      </footer>
    </Container>
  );
}

export default SelectProductsModal;

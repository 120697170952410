import React from 'react';

import { Container } from './styles';

// import maintenanceIcon from '../../assets/icons/maintenance/maintenance.svg';

function Maintenance() {
  return (
    <Container>
      {/* Estamos em manutenção */}
      {/* <img src={maintenanceIcon} alt="maintenanceIcon" /> */}

      <div>
        <strong>Site em manutenção</strong>
        <span>Retorne em instantes</span>
      </div>
    </Container>
  );
}

export default Maintenance;

import React from 'react';

import {
  FontH1,
  FontH2,
  FontH3,
  FontH4,
  FontH5,
  FontDescription,
  FontSpan,
  FontDivider,
  DivInputText,
  DivInputTel,
  BButton,
  IInput,
  ITextArea,
  BDivButton,
  FLabel,
  FontSpace,
  BTextButton,
  BDivButtonText,
  BDivButtonTextContainer,
  IInputWithTextButton,
  DivInputWithTextButton,
  InputButton,
  DCard,
  BSeeMore,
  BBack,
  DSecundaryCard,
  DTextWithIconList,
  DTextWithIconItem,
} from './styles';

export const globalVariations = {
  maxWidth: '1280px',
};

export function H1({ children }) {
  return <FontH1>{children}</FontH1>;
}

export function H2({ children, className }) {
  return <FontH2 className={className}>{children}</FontH2>;
}

export function H3({ children }) {
  return <FontH3>{children}</FontH3>;
}

export function H4({ children }) {
  return <FontH4>{children}</FontH4>;
}

export function H5({ children }) {
  return <FontH5>{children}</FontH5>;
}

export function Description({ children }) {
  return <FontDescription>{children}</FontDescription>;
}

export function Span({ children, fontSize, color = null }) {
  return (
    <FontSpan fontSize={fontSize} color={color}>
      {children}
    </FontSpan>
  );
}

export function Divider({ margin }) {
  return <FontDivider margin={margin} />;
}

export function Space({ height }) {
  return <FontSpace height={height} />;
}

export function Label({ children, background, borderColor, color }) {
  return (
    <FLabel background={background} borderColor={borderColor} color={color}>
      {children}
    </FLabel>
  );
}

export function InputText({
  title,
  description,
  type = 'text',
  placeholder,
  value,
  color,
  onChange,
  onBlur,
  maxLength,
  disabled,
  style = 'input', // input textarea tel
}) {
  return (
    <DivInputText>
      <FontH5>{title}</FontH5>
      {description && <FontDescription>{description}</FontDescription>}

      {style === 'input' && (
        <IInput
          type={type}
          placeholder={placeholder}
          value={value}
          color={color}
          onChange={onChange}
          maxLength={maxLength}
          // red={red}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}

      {style === 'textarea' && (
        <ITextArea
          cols={30}
          rows={4}
          maxLength={maxLength}
          type={type}
          placeholder={placeholder}
          value={value}
          color={color}
          onChange={onChange}
          // red={red}
          onBlur={onBlur}
          disabled={disabled}
        />
      )}

      {style === 'tel' && (
        <DivInputTel>
          <div>
            <span>+55</span>
          </div>

          <IInput
            maxLength={maxLength}
            type="tel"
            placeholder={placeholder}
            value={value}
            color={color}
            onChange={onChange}
            // red={red}
            onBlur={onBlur}
            disabled={disabled}
          />
        </DivInputTel>
      )}
    </DivInputText>
  );
}

export function Input({
  type = 'text',
  placeholder,
  value,
  color,
  onChange,
  onBlur,
  maxLength,
  // red = false,
  disabled,
}) {
  return (
    <IInput
      type={type}
      placeholder={placeholder}
      value={value}
      color={color}
      onChange={onChange}
      maxLength={maxLength}
      // red={red}
      onBlur={onBlur}
      disabled={disabled}
    />
  );
}

export function InputWithTextButton({
  type = 'text',
  placeholder,
  value,
  buttonText,
  colorButtonText,
  onClick,
  color,
  onChange,
  onBlur,
  maxLength,
  disabled,
}) {
  return (
    <DivInputWithTextButton>
      <IInputWithTextButton
        type={type}
        placeholder={placeholder}
        value={value}
        color={color}
        onChange={onChange}
        maxLength={maxLength}
        onBlur={onBlur}
        disabled={disabled}
      />
      <InputButton onClick={onClick}>{buttonText}</InputButton>
    </DivInputWithTextButton>
  );
}

export function TextArea({
  placeholder,
  value,
  color,
  onChange,
  red = false,
  onBlur = () => {},
}) {
  return (
    <ITextArea
      placeholder={placeholder}
      value={value}
      color={color}
      onChange={onChange}
      red={red}
      onBlur={onBlur}
    />
  );
}

export function Button({
  children,
  onClick,
  fontSize = null,
  disabled = false,
  disabledColor = null,
}) {
  return (
    <BButton
      onClick={onClick}
      disabled={disabled}
      disabledColor={disabledColor}
      fontSize={fontSize}
    >
      {children}
    </BButton>
  );
}

export function DivButton({ children, onClick, active }) {
  return (
    <BDivButton onClick={onClick} active={active}>
      {children}
    </BDivButton>
  );
}

export function DivButtonText({
  children,
  onClick,
  textUnderBox,
  active,
  width,
  height,
  fontFamily,
  fontWeight,
}) {
  return (
    <BDivButtonTextContainer active={active} width={width}>
      <BDivButtonText
        onClick={onClick}
        active={active}
        width={width}
        height={height}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
      >
        {children}
      </BDivButtonText>

      {textUnderBox && <span>{textUnderBox}</span>}
    </BDivButtonTextContainer>
  );
}

// export function SecundaryButton({
//   children,
//   onClick,
//   fontSize = null,
//   disabled = false,
//   hasBackground = true,
//   color = null,
//   borderColor = null,
// }) {
//   return (
//     <BSecundaryButton
//       onClick={onClick}
//       disabled={disabled}
//       color={color}
//       borderColor={borderColor}
//     >
//       {children}
//     </BSecundaryButton>
//   );
// }

export function TextButton({
  children,
  onClick,
  color,
  fontSize = null,
  disabled = false,
}) {
  return (
    <BTextButton onClick={onClick} disabled={disabled} color={color}>
      {children}
    </BTextButton>
  );
}

export function Card({ children, onClick }) {
  return <DCard onClick={onClick}>{children}</DCard>;
}

export function SecundaryCard({ children, onClick, border }) {
  return (
    <DSecundaryCard onClick={onClick} border={border}>
      {children}
    </DSecundaryCard>
  );
}

export function TextWithIconList({ children }) {
  return <DTextWithIconList>{children}</DTextWithIconList>;
}

export function TextWithIconItem({ color, children }) {
  return <DTextWithIconItem color={color}>{children}</DTextWithIconItem>;
}

export function ButtonSeeMore({ children, onClick }) {
  return (
    <BSeeMore onClick={onClick} type="button">
      {children}
    </BSeeMore>
  );
}

export function ButtonBack({ onClick }) {
  return (
    <BBack onClick={onClick} type="button">
      <svg
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7 13L1 7L7 1"
          stroke="#101010"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </BBack>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  > strong {
    font-weight: 500;
    font-size: 15px;
    color: #000;
    margin-bottom: 4px;
  }

  > span {
    color: #838383;
    font-size: 13px;
    font-weight: 400 !important;
    margin-bottom: 16px;
  }

  button {
    background: rgb(43, 143, 245);
    border-radius: 8px;
    color: rgb(255, 255, 255);
    border: none;
    width: calc(33% - 8px);
    height: 45px;
    font-size: 13px;
    font-weight: 500;

    &:hover {
      transition: all 300ms;
      filter: opacity(0.85);
    }
  }
`;

export const DivPromotions = styled.div`
  width: 100%;
  display: flex;
  margin-top: 24px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: calc(50% - 8px);
  display: flex;
  background: rgb(255, 255, 255);
  border: 0.5px dashed #c3c3c3;
  border-radius: 10px;
  margin-bottom: 10px;
  margin-right: 8px;
  padding: 16px 24px 16px 16px;
  cursor: pointer;

  &:hover {
    transition: all 300ms;
    filter: opacity(0.85);
  }

  &:nth-of-type(2n) {
    margin-right: 0px;
  }

  strong {
    font-weight: 500;
    font-size: 13px;
    color: rgb(0, 0, 0);
  }

  span {
    font-size: 13px;
    color: #858585;
    word-wrap: break-word;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .left {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .right {
    width: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

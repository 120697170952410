import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin || '55px 0px 0px 0px'};
`;

export const DivBalance = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px 0 16px;
  margin-bottom: 32px;
`;

export const DivOpenAndClose = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 0;
`;

export const DivLoader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
`;

export const DivLabel = styled.div`
  padding: 0 16px;
  margin-top: 24px;

  > span {
    color: #838383;
    font-size: 13px;
    font-weight: 400;
  }
`;

export const ButtonSeeMore = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 144px;
  align-self: center;
  border: none;
  background: #efefef;
  border-radius: 7px;
  font-weight: 600;
  margin-top: 16px;
`;

export const ConfigurationSection = styled.div`
  width: 100%;
  cursor: pointer;
`;

export const ConfigurationSectionTitle = styled.strong`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #101010;
`;

export const ConfigurationSectionSubtitle = styled.span`
  font-size: 13px;
  line-height: 18px;
  color: #838383;
  margin-top: 8px;
  width: 100%;

  > div {
    & + div {
      margin-top: 8px;
    }

    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    > span {
      color: #06c86b;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }
`;

export const DivTotal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;

  > span {
    color: #838383;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }
`;

export const Divider = styled.div`
  display: flex;
  /* width: 100%; */
  height: 1px;
  background: rgba(216, 222, 229, 1);
  margin: ${props => props.margin || '0 16px'};
`;

export const BankDataWrapper = styled.div`
  background-color: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  ${props =>
    !props.confirmed &&
    css`
      border: 1.5px solid #ff303c;
      background: rgba(255, 48, 60, 0.06);
    `}
`;

export const WarningSpan = styled.div`
  color: #ff303c;
  font-size: 13px;
  margin-top: 4px;
`;

export const WarningLabel = styled.div`
  background: #ff303c0f;
  color: #ff303c;
  font-size: 12px;
  font-weight: 500;
  padding: 16px;
  margin-top: 12px;
`;

export const BankInfosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  align-items: flex-start;
`;

export const BankIcon = styled.img`
  margin-top: 8px;
`;

export const BankData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 13px;
    color: #838383;
  }
`;

export const OutlinedButton = styled.button`
  color: var(--blue-details, #0d85ff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  border-width: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
`;

export const ButtonPill = styled.button`
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  border-radius: 50px;
  border: 0.5px solid ${({ active }) => (active ? '#0d85ff' : '#efefef')};
  background: ${({ active }) => (active ? '#0d85ff' : '#EFEFEF;')};
  color: ${({ active }) => (active ? '#fff' : '#1b1b1b')};
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: ${({ active }) => (active ? 600 : 400)};
  line-height: normal;
  /* width: 100%; */
  min-width: max-content;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 0px 0 16px;
  margin-top: 8px;
  justify-content: left;
  align-items: center;
  gap: 8px;
  overflow: auto;
  width: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
  }

  @media (max-width: 425px) {
    /* padding-left: 178px; */
  }
`;

export const DatePickerContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  padding: 0 16px;

  .react-datepicker__input-container {
    display: flex;
    align-items: center;
  }
`;

//
export const CalendarWrapper = styled.div`
  width: 250px;
  border: 1px solid #ddd;
  padding: 10px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
`;

export const CustomInput = styled.input`
  width: 100%;
  border: none;
  background: none;
  font-size: 16px;
  outline: none;

  &::-webkit-calendar-picker-indicator {
    background: rgba(0, 0, 0, 0.2);
    border: none;
    cursor: pointer;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-clear-button {
    display: none;
  }
`;

export const CustomInputDatepicker = styled.button`
  display: flex;
  align-items: center;
  gap: 12px;
  border-width: 0px;
  width: 100%;
  min-width: 173px;
  height: 42px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #f9f9f9;
  padding: 12px;
`;

export const DivHelpCenterOffstorePay = styled.div`
  padding: 0 16px;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const DivBody = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 8px 16px;

  > span {
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
    margin-bottom: 24px;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 48px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 48px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }

  > div {
    margin-top: 24px;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  padding: 14px 16px;

  & + div {
    margin-top: 8px;
  }

  .left {
    display: flex;
    flex-direction: column;
    width: 70%;
    justify-content: flex-start;
    /* align-items: center; */
  }

  .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 30%;
  }

  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #101010;
  }

  span {
    font-size: 13px;
    line-height: 18px;
    color: #9d9d9d;
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 54px;

  > button {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 24px;
    margin-top: 24px;
    justify-content: center;
    background: #2b8ff5;
    color: #fff;
    height: 50px;
    border: none;
    border-radius: 8px;

    > img {
      position: absolute;
      right: 20px;
    }
  }
`;

export const DivHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  border: 1px solid #000000;

  span {
    color: #000000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
`;

export const DivAddress = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 8px;
`;

export const Gap = styled.div`
  width: 8px;
`;

export const DivSenderOrAddressee = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  .title {
    justify-content: center;
    border: 1px solid #000000;
  }

  .titleText {
    font-size: 10px;
    font-weight: 600;
  }

  .label {
    display: flex;
    padding-left: 8px;
    padding-right: 8px;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-direction: row;
  }

  .labelCityUf {
    padding-left: 8px;
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
    display: flex;
    flex-direction: row;
  }

  .cityLabel {
    width: 70%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    text-overflow: ellipsis;

    > b {
      font-size: 9px;
      font-weight: 500;
      max-width: 60%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
      padding-left: 5px;
    }
  }

  .ufLabel {
    width: 30%;
    padding-left: 8px;
    border-left: 1px solid #000000;
    flex-direction: row;

    > b {
      font-size: 9px;
      font-weight: 500;
      padding-left: 5px;
    }
  }

  > div {
    display: flex;
    align-items: center;
    height: 18px;

    > strong {
      font-size: 10px;
      font-weight: 600;
    }

    > b {
      font-size: 9px;
      font-weight: 500;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      align-items: center;
      padding-left: 5px;
    }
  }
`;

export const DivProducts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 8px;

  .label52 {
    display: flex;
    align-items: center;
    width: 52%;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .label52Colored {
    display: flex;
    align-items: center;
    width: 52%;
    height: 18px;
    background-color: #d0cece;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid #000000;
  }

  .label16 {
    display: flex;
    align-items: center;
    border-left: 1px solid #000000;
    width: 16%;
    height: 18px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .label16Total {
    display: flex;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
    border-left: 1px solid #000000;
    width: 16%;
    height: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .coloredLabel {
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    background-color: #d0cece;
  }

  .label {
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: 600;
    width: 100%;
    padding-left: ${props => (props.padding ? '8px' : '0px')};
    padding-right: '8px';
    border-left: 1px solid #000000;
    border-bottom: 1px solid #000000;
    border-right: 1px solid #000000;
  }

  .titleText {
    font-size: 10px;
    font-weight: 600;
    padding-left: 8px;
    padding-right: 8px;
  }

  .totalText {
    font-size: 10px;
    font-weight: 600;
    padding-right: 8px;
  }

  > div {
    display: flex;
    align-items: center;
    height: 18px;

    > strong {
      font-size: 10px;
      font-weight: 600;
    }
  }
`;

export const DivTextDeclaration = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  margin-top: 8px;
  font-size: 10px;
  font-weight: 600;

  .coloredLabel {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    background-color: #d0cece;
  }

  .paddingTop12 {
    padding-top: 12px;
  }

  .padding40 {
    padding-right: 40px;
    padding-top: 4px;
    text-align: right;
  }

  .textLabel {
    padding: 8px;
    border: 1px solid #000000;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
`;

export const DivComments = styled.div`
  border: 1px solid #000000;
  padding: 8px;
  margin-top: 8px;

  > strong {
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  > p {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 4px;
  }
`;

export const DivPageTitle = styled.div`
  margin-top: 24px;
  margin-bottom: 18px;
  margin-left: 18px;
  margin-right: 18px;

  > strong {
    font-size: 18px;
    font-weight: 600;
  }
`;

export const DivAlert = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 18px;
  margin-right: 18px;
  background-color: rgba(255, 108, 75, 0.04);
  border-left: 2px solid #ff6c4b;
  padding: 16px 16px 18px 18px;

  > span {
    color: #ff6c4b;
    font-size: 12px;
    font-weight: 400;
  }

  > a {
    color: #ff6c4b;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const DivInfoInput = styled.div`
  margin-left: 18px;
  margin-right: 18px;

  > strong {
    font-size: 14px;
    font-weight: 600;
  }

  > p {
    margin-top: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #838383;
  }
`;

export const DivEditButton = styled.div`
  display: flex;
  align-items: center;

  > button {
    display: flex;
    align-items: center;
    color: #0d85ff;
    background-color: transparent;
    font-size: 14px;
    font-weight: 600;
    border: none;

    > img {
      margin-right: 6px;
    }
  }
`;

export const DivDeclarationContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 18px;
  margin-left: 18px;
`;

export const DescriptionInfoInput = styled.div``;

export const TitleInfoInput = styled.div``;

export const DivDisclaimer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  margin-left: 18px;
  margin-right: 18px;

  > p {
    font-size: 12px;
    font-weight: 400;
    color: #838383;
    text-align: center;
  }
`;

export const DivToggleButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const ToggleButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;
  padding: 0 24px;

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: none;
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;
      `}
`;

export const ContainerGeneralModal = styled.div`
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.15);
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 316px;
  border-radius: 16px 16px 0px 0px;
  background-color: #fff;
`;

export const TitleModal = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-top: 22px;
  margin-left: 22px;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 16px;

  .errorMessage {
    color: #ff6c4b;
    margin-top: 4px;
  }
`;

export const Divider = styled.div`
  display: flex;
  margin-top: 24px;
  margin-bottom: 24px;
  height: 0.5px;
  width: 100%;
  background: #d8dee5;
`;

export const ContainerButtonModal = styled.div`
  margin-top: 32px;
  display: flex;
  margin-left: 24px;
  margin-right: 24px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-color: #0d85ff;
    width: 100%;
    border: none;
    height: 50px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
  }
`;

import styled from 'styled-components';
import { device } from '../../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 440px;
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 6%;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);

  @media ${device.mobile} {
    width: 90%;
    margin-top: 0;
    top: 25%;
  }
`;

export const DivBody = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > strong {
    margin-top: 24px;
    font-weight: 600;
    font-size: 24px;
    text-align: center;
    color: #000000;
  }

  > span {
    font-size: 14px;
    text-align: center;
    color: #838383;
    margin-top: 12px;
  }

  > button {
    width: 100%;
    margin-top: 32px;
    background: #0d85ff;
    border-radius: 10px;
    border: none;
    height: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
  }
`;

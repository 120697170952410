import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import api from '../../../services/api';

import {
  addCitySuccess,
  loadRegionsSuccess,
  loadFreightSuccess,
  updateFreightSuccess,
  removeCitySuccess,
  removeNeighborhoodSuccess,
  addNeighborhoodSuccess,
} from './actions';

export function* loadFreight() {
  const response = yield call(api.get, 'configfreight');

  yield put(loadFreightSuccess(response.data));
}

export function* updateFreight({ payload }) {
  switch (payload.field) {
    case 'PAC': {
      const data = {
        pac: payload.value,
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    case 'SEDEX': {
      const data = {
        sedex: payload.value,
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    case 'MINI_ENVIOS': {
      const data = {
        mini_envios: payload.value,
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    case 'FLATRATE': {
      const data = {
        flatrate: payload.value,
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    case 'CUSTOM_FREIGHT': {
      const data = {
        custom_freight: payload.value,
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    case 'FREE': {
      const data = {
        free: payload.value,
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    case 'MELHORENVIO': {
      const data = {
        enabled: !payload.allDisabled,
      };

      yield call(api.post, '/updatefreight', data);

      break;
    }

    default:
      break;
  }

  yield put(
    updateFreightSuccess(payload.field, payload.value, payload.allDisabled)
  );

  if (!payload.hiddenToast) {
    toast.success('Informação salva');
  }
}

export function* loadRegions() {
  const response = yield call(api.get, 'userregion');

  yield put(loadRegionsSuccess(response.data));
}

export function* addCity({ payload }) {
  try {
    const response = yield call(api.post, `/usercity/${payload.ufId}`);
    yield put(addCitySuccess(payload.regionId, payload.ufId, response.data));
  } catch (err) {
    toast.error('Erro ao criar cidade');
  }
}

export function* addNeighborhood({ payload }) {
  try {
    const response = yield call(
      api.post,
      `/userneighborhood/${payload.cityId}`
    );
    yield put(
      addNeighborhoodSuccess(
        payload.regionId,
        payload.ufId,
        payload.cityId,
        response.data
      )
    );
  } catch (err) {
    toast.error('Erro ao criar bairro');
  }
}

export function* editValueRegion({ payload }) {
  try {
    const data = {
      value: payload.value,
      regionId: payload.regionId,
    };

    yield call(api.post, `/editvalueregion`, data);
  } catch (err) {
    toast.error('Erro ao alterar valor da região');
  }
}

export function* editValueUf({ payload }) {
  try {
    const data = {
      value: payload.value,
      ufId: payload.ufId,
    };

    yield call(api.post, `/editvalueuf`, data);
  } catch (err) {
    toast.error('Erro ao alterar valor da UF');
  }
}

export function* editNameCity({ payload }) {
  try {
    const data = {
      name: payload.name,
      cityId: payload.cityId,
    };

    yield call(api.post, `/editnamecity`, data);
  } catch (err) {
    toast.error((err && err.response.data) || 'Erro ao alterar cidade');
  }
}

export function* editNameNeighborhood({ payload }) {
  try {
    const data = {
      name: payload.name,
      neighborhoodId: payload.neighborhoodId,
    };

    yield call(api.post, `/editnameneighborhood`, data);
  } catch (err) {
    toast.error((err && err.response.data) || 'Erro ao alterar bairro');
  }
}

export function* editValueCity({ payload }) {
  try {
    const data = {
      value: payload.value,
      cityId: payload.cityId,
    };

    yield call(api.post, `/editvaluecity`, data);
  } catch (err) {
    toast.error('Erro ao alterar valor da cidade');
  }
}

export function* editValueNeighborhood({ payload }) {
  try {
    const data = {
      value: payload.value,
      neighborhoodId: payload.neighborhoodId,
    };

    yield call(api.post, `/editvalueneighborhood`, data);
  } catch (err) {
    toast.error('Erro ao alterar valor do bairro');
  }
}

export function* removeCity({ payload }) {
  try {
    yield call(api.post, `/deleteusercity/${payload.cityId}`);
    yield put(
      removeCitySuccess(payload.regionId, payload.ufId, payload.cityId)
    );
  } catch (err) {
    toast.error('Erro ao remover cidade');
  }
}

export function* removeNeighborhood({ payload }) {
  try {
    yield call(api.post, `/deleteuserneighborhood/${payload.neighborhoodId}`);
    yield put(
      removeNeighborhoodSuccess(
        payload.regionId,
        payload.ufId,
        payload.cityId,
        payload.neighborhoodId
      )
    );
  } catch (err) {
    toast.error('Erro ao remover bairro');
  }
}

export default all([
  takeLatest('@freight/LOAD_REGIONS_REQUEST', loadRegions),
  takeLatest('@freight/LOAD_FREIGHT_REQUEST', loadFreight),
  takeLatest('@freight/UPDATE_FREIGHT_REQUEST', updateFreight),
  takeLatest('@freight/ADD_CITY_REQUEST', addCity),
  takeLatest('@freight/ADD_NEIGHBORHOOD_REQUEST', addNeighborhood),
  takeLatest('@freight/EDIT_VALUE_REGION_REQUEST', editValueRegion),
  takeLatest('@freight/EDIT_VALUE_UF_REQUEST', editValueUf),
  takeLatest('@freight/EDIT_NAME_CITY_REQUEST', editNameCity),
  takeLatest('@freight/EDIT_VALUE_CITY_REQUEST', editValueCity),
  takeLatest('@freight/EDIT_NAME_NEIGHBORHOOD_REQUEST', editNameNeighborhood),
  takeLatest('@freight/EDIT_VALUE_NEIGHBORHOOD_REQUEST', editValueNeighborhood),
  takeLatest('@freight/REMOVE_CITY_REQUEST', removeCity),
  takeLatest('@freight/REMOVE_NEIGHBORHOOD_REQUEST', removeNeighborhood),
]);

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 64px;
  border-bottom: 0.5px solid #d8dee5;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: 24px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    width: 73px;
    height: 100%;
    cursor: pointer;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
  }
`;

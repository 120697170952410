import React from 'react';

function TextAlignCenterIcon({ selected }) {
  return (
    <svg
      width="84"
      height="58"
      viewBox="0 0 84 58"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" fill="#F0F0F0" />
      <rect x="44" width="40" height="40" fill="#F0F0F0" />
      <rect
        x="4"
        y="46"
        width="32"
        height="5"
        fill={selected ? '#0D85FF' : '#F0F0F0'}
      />
      <rect
        x="13"
        y="53"
        width="15"
        height="5"
        fill={selected ? '#0D85FF' : '#F0F0F0'}
      />
      <rect
        x="57"
        y="53"
        width="15"
        height="5"
        fill={selected ? '#0D85FF' : '#F0F0F0'}
      />
      <rect
        x="48"
        y="46"
        width="32"
        height="5"
        fill={selected ? '#0D85FF' : '#F0F0F0'}
      />
    </svg>
  );
}

export default TextAlignCenterIcon;

/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/tabindex-no-positive */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// import { SelectButtons } from '~/components/SelectButtons';
import { NextButton } from '~/components/NextButton';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import * as yup from 'yup';
import { UniversalLoader } from '~/components/UniversalLoader/UniversalLoader';
import { Button } from '~/components/Button';
import { Modal } from '~/components/Modal';
import errorIco from '~/assets/icons/light-error-ico.svg';
// import cancelGrey from '~/assets/icons/cancel-grey.svg';
import arrowDownIcon from '~/assets/icons/offstorepay/arrow-down.svg';
import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';
import { Space } from '~/designsystem/Fonts';
import { useCreateSeller } from '../ThirdStepBankData/hooks/use-create-seller';
import { StepsHeader } from '../StepsHeader';
import * as S from './styles';
import plansOffstorepay from '../../../../../../data/plans_offstorepay.json';

const documents = [
  {
    id: 1,
    name: 'CPF',
    value: 'cpf',
  },
  {
    id: 2,
    name: 'CNPJ',
    value: 'cnpj',
  },
];

const statesList = [
  { label: 'Selecionar', value: 'Selecionar' },
  { label: 'Acre', value: 'AC' },
  { label: 'Alagoas', value: 'AL' },
  { label: 'Amapá', value: 'AP' },
  { label: 'Amazonas', value: 'AM' },
  { label: 'Bahia', value: 'BA' },
  { label: 'Ceará', value: 'CE' },
  { label: 'Distrito Federal', value: 'DF' },
  { label: 'Espírito Santo', value: 'ES' },
  { label: 'Goiás', value: 'GO' },
  { label: 'Maranhão', value: 'MA' },
  { label: 'Mato Grosso', value: 'MT' },
  { label: 'Mato Grosso do Sul', value: 'MS' },
  { label: 'Minas Gerais', value: 'MG' },
  { label: 'Pará', value: 'PA' },
  { label: 'Paraíba', value: 'PB' },
  { label: 'Paraná', value: 'PR' },
  { label: 'Pernambuco', value: 'PE' },
  { label: 'Piauí', value: 'PI' },
  { label: 'Rio de Janeiro', value: 'RJ' },
  { label: 'Rio Grande do Norte', value: 'RN' },
  { label: 'Rio Grande do Sul', value: 'RS' },
  { label: 'Rondônia', value: 'RO' },
  { label: 'Roraima', value: 'RR' },
  { label: 'Santa Catarina', value: 'SC' },
  { label: 'São Paulo', value: 'SP' },
  { label: 'Sergipe', value: 'SE' },
  { label: 'Tocantins', value: 'TO' },
];

const schemaForCpf = yup.object().shape({
  documentNumber: yup.string().required('Número do documento é obrigatório'),
  name: yup.string().required('Nome completo é obrigatório'),
  birthdate: yup.string().required('Data de nascimento é obrigatória'),
  postalCode: yup.string().required('CEP é obrigatório'),
  address: yup.string().required('Endereço é obrigatório'),
  addressNumber: yup.string().required('Número é obrigatório'),
  neighborhood: yup.string().required('Bairro é obrigatório'),
  city: yup.string().required('Cidade é obrigatória'),
  state: yup.string().required('Estado é obrigatório'),
  monthlyRevenue: yup.string().required('Receita é obrigatória'),
});
const schemaForCnpj = yup.object().shape({
  documentNumber: yup.string().required('Número do documento é obrigatório'),
  companyDocumentNumber: yup
    .string()
    .required('Número do cnpj documento é obrigatório'),
  name: yup.string().required('Nome completo é obrigatório'),
  birthdate: yup.string().required('Data de nascimento é obrigatória'),
  postalCode: yup.string().required('CEP é obrigatório'),
  address: yup.string().required('Endereço é obrigatório'),
  addressNumber: yup.string().required('Número é obrigatório'),
  neighborhood: yup.string().required('Bairro é obrigatório'),
  city: yup.string().required('Cidade é obrigatória'),
  state: yup.string().required('Estado é obrigatório'),
  companyPostalCode: yup.string().required('CEP é obrigatório'),
  companyName: yup.string().required('Nome  é obrigatório'),
  companyAddress: yup.string().required('Endereço  é obrigatório'),
  companyAddressNumber: yup.string().required('Número  é obrigatório'),
  companyNeighborhood: yup.string().required('Bairro  é obrigatório'),
  companyCity: yup.string().required('Cidade  é obrigatória'),
  companyState: yup.string().required('Estado  é obrigatório'),
  monthlyRevenue: yup.string().required('Receita é obrigatória'),
});

export function SecondStepPersonalData({
  onNextClick,
  desktopMode,
  closeModal,
  sellerData,
}) {
  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );
  const { handleCreateSeller } = useCreateSeller();
  const [documentMask, setDocumentMask] = useState('999.999.999-99');
  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [schema, setSchema] = useState('');
  const [checkTerms, setCheckTerms] = useState(true);

  const history = useHistory();

  const combinedSchema = () => {
    if (schema === 'cnpj') return schemaForCnpj;
    return schemaForCpf;
  };

  const {
    control,
    setValue,
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    shouldFocusError: false,
    resolver: yupResolver(combinedSchema()),
  });

  const documentType = useWatch({
    control,
    name: 'documentType',
  });
  const postalCode = useWatch({
    control,
    name: 'postalCode',
  });
  const companyPostalCode = useWatch({
    control,
    name: 'companyPostalCode',
  });
  const state = useWatch({
    control,
    name: 'state',
  });
  const companyState = useWatch({
    control,
    name: 'companyState',
  });

  useEffect(() => {
    if (sellerData) {
      setValue('address', sellerData.address);
      setValue('addressNumber', sellerData.addressNumber);
      setValue('birthdate', sellerData.birthdate);
      setValue('city', sellerData.city);
      setValue('complement', sellerData.complement);
      setValue('documentNumber', sellerData.documentNumber);
      setValue('documentType', sellerData.documentType || documents[0].value);
      setValue('name', sellerData.name);
      setValue('neighborhood', sellerData.neighborhood);
      setValue('postalCode', sellerData.postalCode);
      setValue('state', sellerData.state || statesList[0].value);

      setValue('companyAddress', sellerData.companyAddress);
      setValue('companyAddressNumber', sellerData.companyAddressNumber);
      setValue('companyCity', sellerData.companyCity);
      setValue('companyComplement', sellerData.companyComplement);
      setValue('companyDocumentNumber', sellerData.companyDocumentNumber);
      setValue('companyName', sellerData.companyName);
      setValue('companyNeighborhood', sellerData.companyNeighborhood);
      setValue('companyPostalCode', sellerData.companyPostalCode);
      setValue('companyState', sellerData.companyState || statesList[0].value);
      setValue('document', sellerData.document);
      setValue('monthlyRevenue', sellerData.monthlyRevenue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchByPostalCode = async (postalCode, type) => {
    setShowLoader(true);
    try {
      const response = await axios.get(
        `https://viacep.com.br/ws/${postalCode}/json/`
      );
      if (response.status === 200) {
        if (type === 'cpf') {
          setValue('address', response.data.logradouro);
          setValue('neighborhood', response.data.bairro);
          setValue('city', response.data.localidade);
          setValue('state', response.data.uf);
          clearErrors('address');
          clearErrors('neighborhood');
          clearErrors('city');
          clearErrors('state');
        } else {
          setValue('companyAddress', response.data.logradouro);
          setValue('companyNeighborhood', response.data.bairro);
          setValue('companyCity', response.data.localidade);
          setValue('companyState', response.data.uf);
          clearErrors('companyAddress');
          clearErrors('companyNeighborhood');
          clearErrors('companyCity');
          clearErrors('companyState');
        }
      }
    } catch (error) {
      console.error('Erro ao buscar dados do CEP:', error);
    } finally {
      setShowLoader(false);
    }
  };

  useEffect(() => {
    if (documentType === 'cnpj') return setDocumentMask('99.999.999/9999-99');
    return setDocumentMask('999.999.999-99');
  }, [documentType]);

  useEffect(() => {
    if (postalCode && postalCode.length === 9)
      searchByPostalCode(postalCode, 'cpf');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postalCode]);

  useEffect(() => {
    if (companyPostalCode && companyPostalCode.length === 9)
      searchByPostalCode(companyPostalCode, 'cnpj');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyPostalCode]);

  const onSubmit = async data => {
    if (state === 'Selecionar') {
      toast.error('Selecione o estado do seu endereço');
      return 0;
    }

    if (documentType === 'cnpj' && companyState === 'Selecionar') {
      toast.error('Selecione o estado do sua empresa');
      return 0;
    }

    if (checkTerms) {
      return onNextClick(data);
    }

    toast.error('Aceite os termos para continuar');
    return 0;
  };

  const handleCloseModal = () => {
    setShowError(false);
  };

  const handleSelectDocumentType = docType => {
    setValue('documentType', docType);
    setValue('document');
    setSchema(docType);
    // setCpfOrCnpj(docType)
    setTimeout(() => {
      clearErrors();
    }, 1);
  };

  return (
    <>
      {showError && (
        <Modal>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 24,
            }}
          >
            <img src={errorIco} alt="check-icon" width="70px" />
            <p
              style={{
                color: 'rgba(0, 0, 0, 1)',
                fontWeight: 600,
                fontSize: 24,
                textAlign: 'center',
              }}
            >
              Ocorreu um erro
            </p>
            <p
              style={{
                color: 'rgba(131, 131, 131, 1)',
                fontSize: 16,
                textAlign: 'center',
                fontWeight: 400,
              }}
            >
              Verifique se suas informações estão corretas e tente novamente
            </p>
            <Button onClick={handleCloseModal}>
              <p>Entendi</p>
            </Button>
          </div>
        </Modal>
      )}

      <UniversalLoader open={showLoader} />
      <S.Container desktopMode={desktopMode}>
        <div
          style={{
            width: '100%',
            paddingLeft: '16px',
            zIndex: 10,
            marginBottom: '-48px',
          }}
        >
          <S.GoBackButton
            onClick={
              desktopMode
                ? () => closeModal()
                : () => history.push('/paymentmethods')
            }
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="35"
              height="35"
              viewBox="0 0 35 35"
              fill="none"
            >
              <circle cx="17.5" cy="17.5" r="17.5" fill="#EFEFEF" />
              <path
                d="M12 23L24 11"
                stroke="#101010"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M24 23L12 11"
                stroke="#101010"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </S.GoBackButton>
        </div>

        <StepsHeader step="1" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.Body>
            <S.Title>Insira seus dados abaixo</S.Title>

            <Space height="32px" />

            <S.InputLabel>
              Tipo de pessoa<span>*</span>
            </S.InputLabel>
            <Controller
              name="documentType"
              control={control}
              render={({ field }) => (
                <>
                  <S.DivSelecteButtons>
                    <S.SelectButton
                      type="button"
                      left
                      onClick={() => handleSelectDocumentType('cpf')}
                      active={documentType === 'cpf'}
                    >
                      Pessoa física
                    </S.SelectButton>
                    <S.SelectButton
                      type="button"
                      right
                      onClick={() => handleSelectDocumentType('cnpj')}
                      active={documentType === 'cnpj'}
                    >
                      Pessoa jurídica
                    </S.SelectButton>
                  </S.DivSelecteButtons>
                </>
              )}
            />

            <Space height="32px" />

            {/* {cpfOrCnpj} */}

            <S.InputLabel>
              CPF<span>*</span>
            </S.InputLabel>
            <Controller
              name="documentNumber"
              control={control}
              render={({ field }) => (
                <S.MaskInput
                  maskChar=""
                  mask="999.999.999-99"
                  placeholder="Número do documento"
                  {...field}
                />
              )}
            />
            <span>
              {errors && errors.documentNumber && errors.documentNumber.message}
            </span>

            {documentType === 'cpf' && (
              <S.WarningSpan>
                Essa informação <b>não</b> poderá ser alterada depois, insira o
                CPF corretamente
              </S.WarningSpan>
            )}

            <Space height="32px" />

            <S.InputLabel>
              Nome completo<span>*</span>
            </S.InputLabel>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <S.MaskInput
                  maskChar=""
                  mask=""
                  placeholder="Nome completo"
                  {...field}
                />
              )}
            />
            <span>{errors && errors.name && errors.name.message}</span>

            <Space height="32px" />

            <S.InputLabel>
              Data de nascimento<span>*</span>
            </S.InputLabel>
            <S.InputContainer width="127px">
              <Controller
                name="birthdate"
                control={control}
                render={({ field }) => (
                  <S.MaskInput
                    maskChar=""
                    mask="99/99/9999"
                    placeholder="dd/mm/aaaa"
                    {...field}
                  />
                )}
              />
            </S.InputContainer>
            <span>
              {errors && errors.birthdate && errors.birthdate.message}
            </span>

            {documentType === 'cnpj' && (
              <>
                <Space height="32px" />

                <S.InputLabel>
                  CNPJ<span>*</span>
                </S.InputLabel>
                <Controller
                  name="companyDocumentNumber"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput
                      maskChar=""
                      mask="99.999.999/9999-99"
                      placeholder="CNPJ da empresa"
                      {...field}
                    />
                  )}
                />

                <span>
                  {errors &&
                    errors.companyDocumentNumber &&
                    errors.companyDocumentNumber.message}
                </span>

                <S.WarningSpan>
                  Essa informação <b>não</b> poderá ser alterada depois, insira
                  o CNPJ corretamente
                </S.WarningSpan>

                <Space height="32px" />

                <S.InputLabel>
                  Nome fantasia<span>*</span>
                </S.InputLabel>
                <Controller
                  name="companyName"
                  control={control}
                  render={({ field }) => (
                    <S.Input placeholder="Nome fantasia" {...field} />
                  )}
                />
                <span>
                  {errors && errors.companyName && errors.companyName.message}
                </span>

                <S.WarningSpan>
                  Essa informação <b>não</b> poderá ser alterada depois, insira
                  o Nome fantasia corretamente
                </S.WarningSpan>
              </>
            )}

            <Space height="32px" />

            <S.Box gap="16px">
              <S.InputContainer>
                <S.InputLabel>
                  Tipo de produto que você vende<span>*</span>
                </S.InputLabel>

                <Controller
                  name="productType"
                  control={control}
                  render={({ field }) => (
                    <S.SelectWrapper>
                      <S.Select
                        {...register('productType', {
                          required: 'Campo obrigatório',
                        })}
                        defaultChecked
                      >
                        {plansOffstorepay.map(state => (
                          <option value={state.code}>{state.value}</option>
                        ))}
                      </S.Select>
                      <img alt="Seta indicando lista" src={arrowDownIcon} />
                    </S.SelectWrapper>
                  )}
                />
                <span>{errors && errors.state && errors.state.message}</span>
              </S.InputContainer>
            </S.Box>

            <Space height="32px" />

            <S.InputLabel>
              Faturamento mensal aproximado<span>*</span>
            </S.InputLabel>
            <Controller
              name="monthlyRevenue"
              control={control}
              render={({ field }) => (
                <S.DivInputNumber>
                  <div>R$</div>
                  <S.InputNumber
                    width="100px"
                    placeholder="50000"
                    type="number"
                    {...field}
                  />
                </S.DivInputNumber>
              )}
            />
            <span>
              {errors && errors.monthlyRevenue && errors.monthlyRevenue.message}
            </span>

            <Space height="32px" />

            <S.HeaderSection>Seu endereço</S.HeaderSection>

            <Space height="16px" />

            <S.InputLabel>
              CEP<span>*</span>
            </S.InputLabel>
            <Controller
              name="postalCode"
              control={control}
              render={({ field }) => (
                <S.MaskInput
                  maskChar=""
                  mask="99999-999"
                  placeholder="Seu CEP"
                  {...field}
                />
              )}
            />
            <span>
              {errors && errors.postalCode && errors.postalCode.message}
            </span>

            <Space height="32px" />

            <S.Box gap="16px">
              <S.InputContainer>
                <S.InputLabel>
                  Endereço<span>*</span>
                </S.InputLabel>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput
                      maskChar=""
                      placeholder="Rua ou Avenida"
                      {...field}
                    />
                  )}
                />
                <span>
                  {errors && errors.address && errors.address.message}
                </span>
              </S.InputContainer>

              <S.InputContainer maxWidth="126px">
                <S.InputLabel>
                  Nº<span>*</span>
                </S.InputLabel>
                <Controller
                  name="addressNumber"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput maskChar="" placeholder="Nº" {...field} />
                  )}
                />
                <span>
                  {errors &&
                    errors.addressNumber &&
                    errors.addressNumber.message}
                </span>
              </S.InputContainer>
            </S.Box>

            <Space height="32px" />

            <S.Box gap="16px">
              <S.InputContainer>
                <S.InputLabel>
                  Bairro<span>*</span>
                </S.InputLabel>
                <Controller
                  name="neighborhood"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput
                      maskChar=""
                      placeholder="Rua ou Avenida"
                      {...field}
                    />
                  )}
                />
                <span>
                  {errors && errors.neighborhood && errors.neighborhood.message}
                </span>
              </S.InputContainer>

              <S.InputContainer>
                <S.InputLabel>
                  Cidade<span>*</span>
                </S.InputLabel>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput maskChar="" placeholder="Nº" {...field} />
                  )}
                />
                <span>{errors && errors.city && errors.city.message}</span>
              </S.InputContainer>
            </S.Box>

            <Space height="32px" />

            <S.Box gap="16px">
              <S.InputContainer>
                <S.InputLabel>
                  Estado<span>*</span>
                </S.InputLabel>

                <Controller
                  name="state"
                  control={control}
                  render={({ field }) => (
                    <S.SelectWrapper>
                      <S.Select
                        {...register('state', {
                          required: 'Campo obrigatório',
                        })}
                        defaultChecked
                      >
                        {statesList.map(state => (
                          <option value={`${state.value}`}>
                            {state.value}
                          </option>
                        ))}
                      </S.Select>
                      <img alt="Seta indicando lista" src={arrowDownIcon} />
                    </S.SelectWrapper>
                  )}
                />
                <span>{errors && errors.state && errors.state.message}</span>
              </S.InputContainer>

              <S.InputContainer>
                <S.InputLabel>Complemento</S.InputLabel>
                <Controller
                  name="complement"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput
                      maskChar=""
                      placeholder="Complemento"
                      {...field}
                    />
                  )}
                />
                <span>
                  {errors && errors.complement && errors.complement.message}
                </span>
              </S.InputContainer>
            </S.Box>

            {documentType === 'cnpj' && (
              <>
                <Space height="32px" />

                <S.HeaderSection>Endereço da empresa</S.HeaderSection>

                <Space height="16px" />

                <S.InputLabel>
                  CEP<span>*</span>
                </S.InputLabel>
                <Controller
                  name="companyPostalCode"
                  control={control}
                  render={({ field }) => (
                    <S.MaskInput
                      maskChar=""
                      mask="99999-999"
                      placeholder="Seu CEP"
                      {...field}
                    />
                  )}
                />
                <span>
                  {errors &&
                    errors.companyPostalCode &&
                    errors.companyPostalCode.message}
                </span>

                <Space height="32px" />

                <S.Box gap="16px">
                  <S.InputContainer>
                    <S.InputLabel>
                      Endereço<span>*</span>
                    </S.InputLabel>
                    <Controller
                      name="companyAddress"
                      control={control}
                      render={({ field }) => (
                        <S.MaskInput
                          maskChar=""
                          placeholder="Rua ou Avenida"
                          {...field}
                        />
                      )}
                    />
                    <span>
                      {errors &&
                        errors.companyAddress &&
                        errors.companyAddress.message}
                    </span>
                  </S.InputContainer>

                  <S.InputContainer maxWidth="126px">
                    <S.InputLabel>
                      Nº<span>*</span>
                    </S.InputLabel>
                    <Controller
                      name="companyAddressNumber"
                      control={control}
                      render={({ field }) => (
                        <S.MaskInput maskChar="" placeholder="Nº" {...field} />
                      )}
                    />
                    <span>
                      {errors &&
                        errors.companyAddressNumber &&
                        errors.companyAddressNumber.message}
                    </span>
                  </S.InputContainer>
                </S.Box>

                <Space height="32px" />

                <S.Box gap="16px">
                  <S.InputContainer>
                    <S.InputLabel>
                      Bairro<span>*</span>
                    </S.InputLabel>
                    <Controller
                      name="companyNeighborhood"
                      control={control}
                      render={({ field }) => (
                        <S.MaskInput
                          maskChar=""
                          placeholder="Rua ou Avenida"
                          {...field}
                        />
                      )}
                    />
                    <span>
                      {errors &&
                        errors.companyNeighborhood &&
                        errors.companyNeighborhood.message}
                    </span>
                  </S.InputContainer>

                  <S.InputContainer>
                    <S.InputLabel>
                      Cidade<span>*</span>
                    </S.InputLabel>
                    <Controller
                      name="companyCity"
                      control={control}
                      render={({ field }) => (
                        <S.MaskInput maskChar="" placeholder="Nº" {...field} />
                      )}
                    />
                    <span>
                      {errors &&
                        errors.companyCity &&
                        errors.companyCity.message}
                    </span>
                  </S.InputContainer>
                </S.Box>

                <Space height="32px" />

                <S.Box gap="16px">
                  <S.InputContainer>
                    <S.InputLabel>
                      Estado<span>*</span>
                    </S.InputLabel>
                    <Controller
                      name="companyState"
                      control={control}
                      render={({ field }) => (
                        <S.SelectWrapper>
                          <S.Select
                            {...register('companyState', {
                              required: 'Campo obrigatório',
                            })}
                            defaultChecked
                          >
                            {statesList.map(state => (
                              <option value={`${state.value}`}>
                                {state.value}
                              </option>
                            ))}
                          </S.Select>
                          <img alt="Seta indicando lista" src={arrowDownIcon} />
                        </S.SelectWrapper>
                      )}
                    />

                    <span>
                      {errors &&
                        errors.companyState &&
                        errors.companyState.message}
                    </span>
                  </S.InputContainer>

                  <S.InputContainer>
                    <S.InputLabel>Complemento</S.InputLabel>
                    <Controller
                      name="companyComplement"
                      control={control}
                      render={({ field }) => (
                        <S.MaskInput
                          maskChar=""
                          placeholder="Complemento"
                          {...field}
                        />
                      )}
                    />
                    <span>
                      {errors &&
                        errors.companyComplement &&
                        errors.companyComplement.message}
                    </span>
                  </S.InputContainer>
                </S.Box>
              </>
            )}

            <S.DivTerms>
              <S.CheckBoxTerms
                onClick={() => setCheckTerms(!checkTerms)}
                active={checkTerms}
              >
                <svg
                  width="13"
                  height="9"
                  viewBox="0 0 13 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.44 1L4.2625 7.71143L1 4.66078"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </S.CheckBoxTerms>
              <span>
                Ao prosseguir você concorda com os{' '}
                <a
                  href="https://www.offstore.me/terms-and-conditions-offstorepay"
                  target="_blank"
                  rel="noreferrer"
                >
                  termos de uso
                </a>
              </span>
            </S.DivTerms>
          </S.Body>

          <S.Footer>
            <NextButton type="submit" />
          </S.Footer>
        </form>
      </S.Container>
    </>
  );
}

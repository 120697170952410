import React from 'react';
import { Span } from '~/designsystem/Fonts';
import { Container } from './styles';

function DivHelpCenter() {
  return (
    <Container>
      <Span>Tire suas dúvidas sobre o Offstore Pay</Span>

      <a
        href="https://intercom.help/offstore/pt-BR/articles/8950480-sobre-o-offstore-pay"
        target="_blank"
        rel="noreferrer"
      >
        Ir para central de ajuda
      </a>
    </Container>
  );
}

export default DivHelpCenter;

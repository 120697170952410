import React from 'react';

import { Space } from '~/designsystem/Fonts';
import { useHistory } from 'react-router-dom';
import { Container } from './styles';

import dataPlans from '../../../../data/plans.json';

function CardCommissions({
  paymentMethod,
  isDesktop,
  handleClickButtonSeePlan,
}) {
  const history = useHistory();

  function handleClick() {
    if (isDesktop) {
      handleClickButtonSeePlan(7);
    } else {
      history.push('/pro');
    }
  }
  return (
    <Container>
      <strong>Comissão sobre vendas feitas via {paymentMethod}</strong>

      <Space height="12px" />

      <div>
        <span>Plano {dataPlans.pro.name}</span>
        <span>{dataPlans.pro.fees.gateways}</span>
      </div>

      <div>
        <span>Plano {dataPlans.premium.name}</span>
        <span>{dataPlans.premium.fees.gateways}</span>
      </div>

      <div>
        <span>Plano {dataPlans.ultra.name}</span>
        <span>{dataPlans.ultra.fees.gateways}</span>
      </div>

      <button type="button" onClick={handleClick}>
        Ver planos
      </button>
    </Container>
  );
}

export default CardCommissions;

/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import InfiniteScroll from 'react-infinite-scroll-component';
import BackButtonIcon from '../../../assets/icons/backButton.svg';

import api from '../../../services/api';

import {
  Container,
  Header,
  DivAddOrder,
  DivBody,
  DivTitle,
  Card,
  DivTextOrder,
  DivPaymentStatus,
} from './styles';

import BluePointIcon from '../../../assets/icons/order/bluePoint.svg';
import Loader from '../../../assets/icons/loader.gif';

const HeaderComponent = () => {
  const history = useHistory();

  return (
    <Header>
      <div className="div-space">
        <Link to={{ pathname: '/dashboard', tab: { tab: 1 } }}>
          <img src={BackButtonIcon} alt="" />
        </Link>
      </div>
      <div>
        <span>Pedidos</span>
      </div>
      <div className="div-space" onClick={() => history.push(`/orderregister`)}>
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 1V19"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 10H19"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </Header>
  );
};

function MyOrders() {
  const history = useHistory();

  const [orders, setOrders] = useState();
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [count, setCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    async function load() {
      await api.get(`/orderrequestpagination?page=${page}`).then(response => {
        if (page > 1) {
          setTimeout(() => {
            setCount(count + response.data.rows.length);
            setOrders([
              ...orders,
              ...response.data.rows.map(r => ({
                ...r,
                date: format(
                  parseISO(r.createdAt),
                  "dd 'de' MMMM 'de' yyyy ' às 'HH:mm'h'",
                  {
                    locale: pt,
                  }
                ),
              })),
            ]);
          }, 1500);
        } else {
          setTotalCount(response.data.count);
          setCount(response.data.rows.length);
          setOrders(
            response.data.rows.map(r => ({
              ...r,
              date: format(
                parseISO(r.createdAt),
                "dd 'de' MMMM 'de' yyyy ' às 'HH:mm'h'",
                {
                  locale: pt,
                }
              ),
            }))
          );
        }
      });
    }

    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  function handleLoadMoreOrders() {
    if (count === totalCount) {
      setHasMore(false);
      return 0;
    }

    return setPage(page + 1);
  }

  async function handleClickOrder(id) {
    await api.put(`/orderrequest/${id}`, {
      viewed: true,
    });

    history.push(`/myorders/${id}`);
  }
  return (
    <Container>
      <HeaderComponent />
      {/* <ResponsiveHeader title="Pedidos" url="/dashboard" tab={1} /> */}
      <DivAddOrder>
        {/* <button type="button" onClick={() => history.push(`/orderregister`)}>
          Adicionar pedido
        </button>
        <span>
          O estoque dos produtos incluidos no pedido serão reduzidos
          automaticamente
        </span> */}

        <DivTitle>
          <strong>Pedidos recebidos</strong>
          <span>Aqui são exibidos seus pedidos recebidos.</span>
        </DivTitle>
      </DivAddOrder>
      <DivBody>
        {orders && orders.length > 0 && (
          <InfiniteScroll
            dataLength={count} // This is important field to render the next data
            next={handleLoadMoreOrders}
            hasMore={hasMore}
            loader={
              <div className="loader">
                <img src={Loader} alt="loader" />
              </div>
            }
          >
            {orders &&
              orders.map(order => (
                <Card
                  key={order.id}
                  onClick={() => handleClickOrder(order.id)}
                  canceled={order.status === 'Cancelado'}
                >
                  <DivTextOrder>
                    <div>
                      {!order.viewed && (
                        <strong>{order.name || 'Registro manual'}</strong>
                      )}
                      {order.viewed && order.status !== 'Cancelado' && (
                        <strong className="viewed">
                          {order.name || 'Registro manual'}
                        </strong>
                      )}
                      {order.viewed && order.status === 'Cancelado' && (
                        <strong className="canceled">
                          {order.name || 'Registro manual'}
                        </strong>
                      )}
                      {order.status === 'Cancelado' && (
                        <span className="canceled">{order.total}</span>
                      )}
                      {order.status !== 'Cancelado' && order.viewed && (
                        <span className="canceled">{order.total}</span>
                      )}
                      {order.status !== 'Cancelado' && !order.viewed && (
                        <span>{order.total}</span>
                      )}
                    </div>
                    <span className={order.viewed ? 'date-viewed' : ''}>
                      {order.date}
                    </span>
                  </DivTextOrder>

                  <DivPaymentStatus color={order.label_status}>
                    {order.viewed === false && (
                      <img src={BluePointIcon} alt="point" className="point" />
                    )}
                    <div>{order.label_status}</div>
                  </DivPaymentStatus>
                </Card>
              ))}
          </InfiniteScroll>
        )}
      </DivBody>
    </Container>
  );
}

export default MyOrders;

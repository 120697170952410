export function updateProfileRequest(data, hiddenToast) {
  return {
    type: '@user/UPDATE_PROFILE_REQUEST',
    payload: { data, hiddenToast },
  };
}

export function updateProfileSuccess(profile) {
  return {
    type: '@user/UPDATE_PROFILE_SUCCESS',
    payload: { profile },
  };
}

export function updateProfileFailure() {
  return {
    type: '@user/UPDATE_PROFILE_FAILURE',
  };
}

export function updateCustomizeLinkUserRequest(data) {
  return {
    type: '@user/UPDATE_CUSTOMIZE_LINK_USER_REQUEST',
    payload: { data },
  };
}

export function updateCustomizeLinkUserSuccess(profile) {
  return {
    type: '@user/UPDATE_CUSTOMIZE_LINK_USER_SUCCESS',
    payload: { profile },
  };
}

export function updateCustomizeLinkUserFailure() {
  return {
    type: '@user/UPDATE_CUSTOMIZE_LINK_USER_FAILURE',
  };
}

export function updateProfileColor(title, data) {
  return {
    type: '@user/UPDATE_PROFILE_COLOR',
    payload: { title, data },
  };
}

export function saveImageRequest(avatarImage, imageType, page) {
  return {
    type: '@user/SAVE_IMAGE_REQUEST',
    payload: { avatarImage, imageType, page },
  };
}

export function saveImageSuccess(avatar, imageType) {
  return {
    type: '@user/SAVE_IMAGE_SUCCESS',
    payload: { avatar, imageType },
  };
}

export function saveImageLoadingSuccess() {
  return {
    type: '@user/SAVE_IMAGE_LOADING_SUCCESS',
  };
}

export function removeImageRequest(imageType, imageId) {
  return {
    type: '@user/REMOVE_IMAGE_REQUEST',
    payload: { imageType, imageId },
  };
}

export function removeImageSuccess(imageType, imageId) {
  return {
    type: '@user/REMOVE_IMAGE_SUCCESS',
    payload: { imageType, imageId },
  };
}

export function ImageBannerSuccess(banners) {
  return {
    type: '@user/IMAGE_BANNER_SUCCESS',
    payload: { banners },
  };
}

export function ImageBannerDesktopSuccess(banners) {
  return {
    type: '@user/IMAGE_BANNER_DESKTOP_SUCCESS',
    payload: { banners },
  };
}

export function updateOrderBannerRequest(banners) {
  return {
    type: '@user/UPDATE_ORDER_BANNER_REQUEST',
    payload: { banners },
  };
}

export function updateOrderBannerDesktopRequest(banners) {
  return {
    type: '@user/UPDATE_ORDER_BANNER_DESKTOP_REQUEST',
    payload: { banners },
  };
}

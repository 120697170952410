import styled, { css } from 'styled-components';
import { device } from '../../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #0e0e0e;
    margin-right: 4px;
    margin-top: 12px;

    > span {
      background: ${props => props.color};
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 20px;
  margin-top: 24px;
  border-radius: 8px;
  background: #fafafa;

  > div {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const DiscountLabel = styled.div`
  border-radius: 24px;
  background: ${props => props.color};
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  padding: 8px 16px;
`;

export const Price = styled.span`
  font-size: 28px;
  font-weight: 700;
  color: ${props => props.color};
`;

export const OldPrice = styled.span`
  color: #9d9d9d;
  font-size: 13px;
  font-weight: 400;
  text-decoration: line-through;
`;

export const Div = styled.div`
  width: 440px;
  background: #fff;
  border-radius: 16px;
  z-index: 99991;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (300px / 2));
  left: calc(50vw - (440px / 2));
  padding: 24px 20px;
  margin-top: 0 !important;

  @media ${device.mobile} {
    width: 90%;
    left: auto;
  }
`;

export const DivButton = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 24px;

  > a {
    /* background: #2b8ff5; */
    background: linear-gradient(
      238.72deg,
      #ff6534 -43.51%,
      #ff0e9f 37.82%,
      #a925fa 106.7%
    );
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    width: 100%;
    height: 50px;
    position: relative;
    font-weight: 500;
    font-size: 14px;

    > img {
      position: absolute;
      right: 20px;
    }
  }

  > button {
    /* background: #2b8ff5; */
    background: linear-gradient(
      238.72deg,
      #ff6534 -43.51%,
      #ff0e9f 37.82%,
      #a925fa 106.7%
    );
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
    width: 100%;
    height: 50px;
    position: relative;
    font-weight: 500;
    font-size: 14px;

    > img {
      position: absolute;
      right: 20px;
    }
  }
`;

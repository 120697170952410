import React from 'react';

function Download({ color = 'white' }) {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 11.3984V14.7317C16 15.1737 15.8244 15.5977 15.5118 15.9102C15.1993 16.2228 14.7754 16.3984 14.3333 16.3984H2.66667C2.22464 16.3984 1.80072 16.2228 1.48816 15.9102C1.17559 15.5977 1 15.1737 1 14.7317V11.3984"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.33301 7.23181L8.49967 11.3985L12.6663 7.23181"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 11.3984V1.39844"
        stroke={color}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Download;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { Button, H3 } from '~/designsystem/Fonts';
import api from '~/services/api';
import { toast } from 'react-toastify';

import LoaderComponent from '~/components/Loader';
import {
  ContainerGeral,
  Container,
  DivModal,
  Header,
  Body,
  SecundaryButton,
  DivPrice,
} from './styles';

function UpdatePlanModal({ active, setActive, isDesktop, planObject = {} }) {
  const [loading, setLoading] = useState();
  async function handleUpdatePlan() {
    try {
      setLoading(true);
      if (planObject && planObject.stripe_price_id) {
        await api.post('/stripe/plans/update', {
          stripe_price_id: planObject.stripe_price_id,
        });

        toast.success('Plano alterado com sucesso!');

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao alterar o plano');
    } finally {
      setLoading(false);
    }
  }

  return (
    <ContainerGeral>
      {active && <Container onClick={() => setActive(false)} />}

      <DivModal active={active} isDesktop={isDesktop}>
        <Header>
          <H3>
            Deseja alterar seu plano para o plano{' '}
            {planObject && planObject.plan_name}?
          </H3>
        </Header>

        <Body>
          <DivPrice>
            <span>Valor do plano</span>

            <span className="span-blue">
              R$ {planObject.price}{' '}
              {planObject.selectedTypePlan === 'yearly' ? 'por ano' : 'por mês'}
            </span>
          </DivPrice>
          <SecundaryButton onClick={() => setActive(false)}>
            Cancelar
          </SecundaryButton>

          <Button onClick={() => handleUpdatePlan()} disabled={loading}>
            {loading ? <LoaderComponent size={20} /> : 'Confirmar alteração'}
          </Button>
        </Body>
      </DivModal>
    </ContainerGeral>
  );
}

export default UpdatePlanModal;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: 100vh;
  background: #fafafa;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* margin-top: -50px; */

    > strong {
      font-size: 24px;
      font-weight: 500;
    }

    > span {
      font-size: 15px;
    }
  }

  img {
    width: 90%;
  }

  @media (min-width: 720px) {
    img {
      width: 35%;
    }
  }
`;

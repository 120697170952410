import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none; /* Isso evitará que todos os cliques aconteçam na div e em seus filhos */
      opacity: 0.5;
    `}
`;

export const Title = styled.h1`
  color: #000;
  font-family: Inter;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: left;
`;

export const Subtitle = styled.span`
  color: var(--Body-Font, #838383);
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 3px;
`;

export const StatusContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  margin-top: 36px;
  overflow: auto;
`;

export const ContainerStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  opacity: ${props => (props.buttonStatus === 'disabled' ? 0.5 : 1)};
  height: 107px;

  p {
    color: ${props => (props.status ? '#06C86B' : '#9d9d9d')};
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 12px 0px;
  }

  svg {
    width: 31px;
    color: red;
  }
`;

export const Button = styled.button`
  border-radius: 8px;
  background: ${props =>
    props.active ? 'rgba(6, 200, 107, 0.06)' : '#0d85ff'};
  border-width: 0px;
  height: 40px;
  width: ${props => props.width || 'auto'};
  padding: 12px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  color: ${props => (props.active ? '#06C86B' : '#fff')};
  font-weight: ${props => (props.active ? 400 : 600)};
  text-align: center;
  font-family: Inter;
  font-size: 13px;
  font-style: normal;
  line-height: normal;
`;

export const OutlinedButton = styled.button`
  font-family: Inter;
  background: transparent;
  border-width: 0px;
  color: ${props => (props.color ? props.color : '#838383')};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 21px;
`;

export const Divider = styled.div`
  margin-top: 15px;
  margin-left: 90px;
  position: absolute;
  width: 11%;
  height: 1px;
  flex-shrink: 0;
  background: var(--border-line-color, #d8dee5);
`;

export const MainContainerStatus = styled.div`
  display: flex;
  width: 33%;
`;

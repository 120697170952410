import styled from 'styled-components';

export const Container = styled.div`
  width: 810px !important;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 100px;
  position: relative;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    width: ${props => props.spanWidth || '100%'};
    margin-bottom: 8px;
  }

  > label {
    position: absolute;
    top: 0;
    right: 0;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 48px;
    background: #2b8ff5;
    border-radius: 8px;
    border: none;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;

  > button {
    background: none;
    color: #2b8ff5;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-top: 8px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  height: 65px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};
  cursor: pointer;

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  > button {
    padding: 9px 28px;
    background: #2b8ff5;
    border: 1px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

import styled from 'styled-components';
import { FiCheck } from 'react-icons/fi';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 55px;
  background: #fff;
  z-index: 10;
  border-bottom: 0.5px solid #d8dee5;
  border-radius: 22px 22px 0 0;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;

    > span {
      text-align: center;
      font-weight: 500;
      font-size: 15px;
      color: #000000;
    }

    img {
      cursor: pointer;
    }
  }
`;

export const DivSpace = styled.div`
  /* width: 50px !important; */
  height: 100% !important;
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none;
  }

  .destaque {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: #2b8ff5 !important;
    background: transparent;
    border: none;
  }

  .destacado {
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    color: #bbbbbb !important;
    background: transparent;
    border: none;
  }

  span {
    font-size: 13px !important;
    color: #ff5f5f !important;
    font-weight: 400;
    cursor: pointer;
    /* text-decoration: underline; */
  }

  .left {
    width: 100%;
    height: 100%;

    /* padding-left: 20px; */

    div {
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 32px;
    }
  }

  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 32px;
  }
`;

export const FiCheckStyled = styled(FiCheck)`
  margin-right: 4px;
`;

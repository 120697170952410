import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Card, H2 } from '~/designsystem/Fonts';
import { handleBuyStripe } from '~/functions/stripe';
import LoaderComponent from '~/components/Loader';
import ResponsiveHeader from '../../../../components/HeaderMobile';

import { Container, DivBody, DivBodyCard, DivCards } from './styles';

// import arrowRightIcon from '../../../../assets/icons/plan/arrowRight.svg';
import { SelectButton } from '../styles';

function SubscriptionPeriod() {
  const [loading, setLoading] = useState({
    trimestral: false,
    semestral: false,
    anual: false,
  });

  const email = useSelector(
    state => state.user && state.user.profile && state.user.profile.email
  );

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        setLoading({
          trimestral: false,
          semestral: false,
          anual: false,
        });
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  async function handleBuy(period, label) {
    try {
      setLoading({ ...loading, [label]: true });
      // await handleBuyStripe(email, 'bolet', period, 'subscriptionperiod');
    } catch (err) {
      console.log(err);
    } finally {
      setLoading({
        trimestral: false,
        semestral: false,
        anual: false,
      });
    }
  }

  return (
    <Container>
      <ResponsiveHeader
        title="Escolha o período de assinatura"
        url="/pro"
        tab={1}
      />

      <DivBody>
        <H2>Pagamento via Boleto</H2>

        <DivCards>
          <Card>
            <DivBodyCard>
              <strong>Trimestral</strong>
              <span>R$ 19,90/mês(R$ 79,70 no total)</span>
              <span className="green">50% de desconto</span>
            </DivBodyCard>

            <SelectButton
              type="button"
              onClick={() => handleBuy('3months', 'trimestral')}
              disabled={loading.trimestral}
            >
              {loading.trimestral ? (
                <LoaderComponent size={20} />
              ) : (
                'Selecionar'
              )}
            </SelectButton>
            {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
          </Card>

          <Card>
            <DivBodyCard>
              <strong>Semestral</strong>
              <span>R$ 19,90/mês(R$ 119,40 no total)</span>
              <span className="green">50% de desconto</span>
            </DivBodyCard>

            <SelectButton
              type="button"
              onClick={() => handleBuy('6months', 'semestral')}
              disabled={loading.semestral}
            >
              {loading.semestral ? <LoaderComponent size={20} /> : 'Selecionar'}
            </SelectButton>
            {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
          </Card>

          <Card>
            <DivBodyCard>
              <strong>Anual</strong>
              <span>R$ 19,90/mês(R$ 238,80 no total)</span>
              <span className="green">50% de desconto</span>
            </DivBodyCard>

            <SelectButton
              type="button"
              onClick={() => handleBuy('year', 'anual')}
              disabled={loading.anual}
            >
              {loading.anual ? <LoaderComponent size={20} /> : 'Selecionar'}
            </SelectButton>
            {/* <img src={arrowRightIcon} alt="seta para a direita" /> */}
          </Card>
        </DivCards>
      </DivBody>
    </Container>
  );
}

export default SubscriptionPeriod;

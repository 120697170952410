import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 26px 16px;
  background: #fff;
  gap: 8px;
`;

export const Title = styled.h1`
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export const Subtitle = styled.h2`
  color: var(--body-font, #838383);
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 8px;
`;

export const ButtonStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  /* Estilos para o status "inPreparation" */
  ${props =>
    props.status === 'inPreparation' &&
    css`
      background: rgba(52, 199, 128, 0.06);
      color: #06c86b;
    `}

  /* Estilos para o status "dispatched" */
  ${props =>
    props.status === 'dispatched' &&
    css`
      background: rgba(43, 143, 245, 0.06);
      color: #0d85ff;
    `}

  /* Estilos para o status "canceled" */
  ${props =>
    props.status === 'canceled' &&
    css`
      background: rgba(255, 48, 60, 0.06);
      color: #ff303c;
    `}

  img {
    position: absolute;
    right: 36px;
  }
`;

export const Button = styled.button`
  border-radius: 8px;
  background: #0d85ff;
  border-width: 0px;
  color: #fff;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 16px;

  svg {
    position: absolute;
    right: 36px;
  }
`;

export const OutlinedButton = styled.button`
  font-family: Inter;
  background: transparent;
  border-width: 0px;
  color: ${props => (props.color ? props.color : '#838383')};
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  margin-top: 21px;
`;

import React from 'react';
import Header from '../../Header';
import * as S from './styles';
import { Configuration } from '~/pages/Responsive/PaymentMethods/ConfigurationOffstorePay/components/Configuration';

function ConfigurationOffstorePayConfig({ handleClickBackButton }) {
  return (
    <S.Container>
      <Header
        title="Offstore Pay - Ativar"
        handleClickBack={handleClickBackButton}
      />
      <S.Body>
        <Configuration />
      </S.Body>
    </S.Container>
  );
}

export default ConfigurationOffstorePayConfig;

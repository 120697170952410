import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ResponsiveHeader from '../../../components/HeaderMobile';

import IndicationModal from '../../../components/IndicationModal';

import api from '../../../services/api';

import CopyIcon from '../../../assets/icons/indication/copy.svg';

import {
  Container,
  DivBody,
  DivCustomLink,
  DivSpace,
  DivSendForEmail,
  List,
} from './styles';

function MyIndications() {
  const [showIndicationModal, setShowIndicationModal] = useState(false);
  const [indications, setIndications] = useState([]);

  const { username } = useSelector(state => state.user.profile);

  async function getIndications() {
    const myIndications = await api.get('/indications');
    setIndications(myIndications && myIndications.data);
  }

  useEffect(() => {
    getIndications();
  }, []);

  // function modalCall(modal) {
  //   setShowIndicationModal(modal);
  // }

  return (
    <Container>
      {/* <IndicationModal
        showModal={showIndicationModal}
        Modal={modalCall}
        returnIndications
        getIndications={getIndications}
      /> */}

      <ResponsiveHeader title="Indicações" url="/dashboard" tab={1} />

      <DivBody>
        <h3>Indique e ganhe o plano PRO grátis</h3>

        <DivCustomLink>
          {/* <strong>Envie seu link personalizado</strong> */}

          <span>
            Copie seu link personalizado da offstore e envie para seus amigos.
            Caso 5 deles cliquem no seu link e criem uma conta, você ganha
            automaticamente acesso gratuito ao plano PRO.
          </span>

          <div>
            <span>offstore.me/invite/{username}</span>
            <CopyToClipboard text={`offstore.me/invite/${username}`}>
              <button
                type="button"
                onClick={() => toast.success('Link copiado')}
              >
                <img src={CopyIcon} alt="copy" />
                copiar
              </button>
            </CopyToClipboard>
          </div>
        </DivCustomLink>

        {/* <DivSpace>
          <strong />
          <span>OU</span>
          <strong />
        </DivSpace> */}

        <DivSendForEmail>
          {/* <strong>Envie por email</strong> */}

          <span>
            A indicação é válida apenas somente para usuários legitimos.
            Qualquer indício de violação dessa regra, poderá levar a perda do
            acesso gratuito ao plano PRO.
          </span>

          <div className="invitations">
            <strong>Usuários cadastrados:</strong>
            <span>{(indications && indications.length) || 0}</span>
          </div>

          <List>
            {indications &&
              indications.map(ind => (
                <div key={ind.id}>
                  <strong>{ind.email}</strong>
                  {ind.confirmed && !ind.invalid && (
                    <span className="span-confirmed">Confirmado</span>
                  )}

                  {!ind.confirmed && !ind.invalid && <span>Aguardando</span>}

                  {!ind.confirmed && ind.invalid && (
                    <span className="span-invalid">Inválido</span>
                  )}
                </div>
              ))}
          </List>

          {/* <button
            type="button"
            onClick={() => {
              setShowIndicationModal(!showIndicationModal);
            }}
          >
            Convidar amigos
          </button> */}
        </DivSendForEmail>
      </DivBody>
    </Container>
  );
}

export default MyIndications;

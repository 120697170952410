import styled, { css } from 'styled-components';

export const ConfigurationSection = styled.div``;

export const ConfigurationSectionTitle = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #000000;
`;

export const ConfigurationSectionSubtitle = styled.p`
  font-size: 12px;
  color: #838383;
  margin-top: 4px;

  > button {
    color: #0d85ff;
    font-size: 12px;
    font-weight: 600;
    border: none;
    background: none;
  }
`;

export const GenericButton = styled.button`
  padding: 9px 28px;
  background: #2b8ff5;
  border: 1px solid #2b8ff5;
  box-sizing: border-box;
  border-radius: 6px;
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 50px;
  margin: 12px 0px;
`;

export const ButtonFeesAndTerms = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: #0d85ff;
  border: none;
  background: none;
  margin-top: 16px;
  width: 100%;
  justify-content: center;
  cursor: pointer;
`;

export const Modal = styled.div`
  display: flex;
`;

export const BackgroundModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #000000;
  opacity: 0.2;
  z-index: 500;
`;

export const DivFeesAndTerms = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(50vh - (270px / 2));
  z-index: 501;
  background: #ffffff;
  padding: 24px;
  width: 100%;
  border-radius: 12px;
  max-width: 746px;

  @media (max-width: 720px) {
    left: 16px;
    right: 16px;
    width: auto;
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 4px;

    > span {
      color: #0d85ff;
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

export const DivInstallmentTable = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(50vh - (380px / 2));
  z-index: 501;
  background: #ffffff;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 746px;

  @media (max-width: 720px) {
    left: 16px;
    right: 16px;
    width: auto;
  }
`;

export const InstallmentTableBody = styled.div`
  height: 280px;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const Installment = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;

  > h5 {
    font-weight: 400;
  }

  ${props =>
    props.border &&
    css`
      border-top: 1px solid #d8dee5;
      margin-top: 16px;
      padding-top: 16px;
    `}

  > span {
    color: #838383;
    font-size: 13px;
    font-weight: 400;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};
  opacity: ${props => (props.active ? 1 : 0.5)};

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }

    > button {
      padding: 9px 28px;
      background: #2b8ff5;
      border: 1px solid #2b8ff5;
      box-sizing: border-box;
      border-radius: 6px;
      text-decoration: none;
      color: #fff;
    }
  }

  .offstore-pay-header {
    display: flex;
    align-items: center;

    .recommended-flag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      padding: 4px 6px;
      border-radius: 4px;
      background: #06c86b;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .offstore-pay-description {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin: 16px 0px;
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  > a {
    padding: 9px 28px;
    background: #2b8ff5;
    border: 1px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

export const BankDataWrapper = styled.div`
  background-color: #fafafa;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;
  margin-top: 16px;

  ${props =>
    !props.confirmed &&
    css`
      border: 1.5px solid #ff303c;
      background: rgba(255, 48, 60, 0.06);
    `}
`;

export const BankIcon = styled.img`
  margin-top: 8px;
`;

export const BankData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    font-size: 13px;
    color: #838383;
  }
`;

// interface SelectButtonProps {
//   isEdge?: 'left' | 'right';
// }

export const SelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 12px 0px 0px 0px;
  /* background-color: yellow; */
  /* justify-content: center; */

  .is-selected {
    border: 1px solid #0d85ff;
    p {
      color: #0d85ff;
    }
  }

  .left-edge {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .right-edge {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .disabled {
    opacity: 0.3;
  }
`;

export const SelectButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SelectButton = styled.button`
  padding: 12px 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  background-color: #ffffff;

  p {
    font-size: 13px;
    font-weight: 500;
    color: #9d9d9d;
  }
`;

export const SelectButtonBottomInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 6px;

  p {
    font-size: 13px;
    color: ${props => (props.active ? '#0d85ff' : '#9d9d9d')};
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 0px 0px;
`;

export const BankInfosWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 16px;
  align-items: ${props => (props.hasBankAccount ? 'flex-start' : 'center')};
`;

export const WarningSpan = styled.div`
  color: #ff303c;
  font-size: 13px;
  margin-top: 4px;
`;

export const OutlinedButton = styled.button`
  color: var(--blue-details, #0d85ff);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  background: transparent;
  border-width: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
  margin-top: 4px;
`;

export const ExcludeText = styled.div`
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #d8dee5;
  padding-top: 40px;
  margin-top: 40px;
  p {
    color: #838383;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

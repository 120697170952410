/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { toast } from 'react-toastify';

import api from '../../services/api';

import {
  Container,
  DivBody,
  Card,
  AddVariation,
  DivAddVariation,
  DivItems,
  DivItem,
} from './styles';

import CancelIcon from '../../assets/icons/cancelx-black.svg';
import CancelGreyIcon from '../../assets/icons/cancelx.svg';
import PlusIcon from '../../assets/icons/plusIcon.svg';
import DragAndDropPointsIcon from '../../assets/icons/dashboard/dragPoints.svg';

function Variations({
  showModal,
  Modal,
  loadGrid,
  productId,
  price,
  promoPrice,
}) {
  const [showAddVariantModal, setShowAddVariantModal] = useState(false);
  const [showAddSubVariantModal, setShowAddSubVariantModal] = useState(false);
  const [attributes, setAttributes] = useState();
  const [inputVariationName, setInputVariationName] = useState('');

  const [variationName, setVariationName] = useState('');
  const [variationId, setVariationId] = useState(0);

  const [newSubs, setNewSubs] = useState([]);

  // const [selectedItemsArray1, setSelectedItemsArray1] = useState([]);
  // const [selectedItemsArray2, setSelectedItemsArray2] = useState([]);
  // const [selectedItemsArray3, setSelectedItemsArray3] = useState([]);

  let resultGrid = [];

  const [selectedItemsArray, setSelectedItemsArray] = useState([]);

  const handleModal = useCallback(() => {
    Modal(false);
  }, [Modal]);

  // função que organiza o array por group by
  const groupedMap = selectedItemsArray.reduce(
    (entryMap, e) =>
      entryMap.set(e.attribute_id, [
        ...(entryMap.get(e.attribute_id) || []),
        e,
      ]),
    new Map()
  );

  // funcão que gera as combinações do grid
  const getAllCombinations = arraysToCombine => {
    const divisors = [];
    for (let i = arraysToCombine.length - 1; i >= 0; i--) {
      divisors[i] = divisors[i + 1]
        ? divisors[i + 1] * arraysToCombine[i + 1].length
        : 1;
    }

    function getPermutation(n, arraysToCombines) {
      const result = [];
      let curArray;
      for (let i = 0; i < arraysToCombines.length; i++) {
        curArray = arraysToCombines[i];
        result.push(curArray[Math.floor(n / divisors[i]) % curArray.length]);
      }
      return result;
    }

    let numPerms = arraysToCombine[0].length;
    for (let i = 1; i < arraysToCombine.length; i++) {
      numPerms *= arraysToCombine[i].length;
    }

    const combinations = [];
    for (let i = 0; i < numPerms; i++) {
      combinations.push(getPermutation(i, arraysToCombine));
    }
    return combinations;
  };

  useEffect(() => {
    if (variationId > 0) {
      setNewSubs([]);
    } else {
      setNewSubs([
        {
          id: Math.floor(Math.random() * 1000) + 1,
          name: '',
          attr_id: 0,
          isNew: true,
        },
      ]);
    }
  }, [variationId]);

  function geraGrid() {
    if (groupedMap.size === 0) {
      // toast.error('Selecione uma opção');
      loadGrid([]);
      handleModal();
      return 0;
    }

    const grouped = Array.from(groupedMap.values());

    resultGrid = getAllCombinations(grouped);

    if (resultGrid.length > 500) {
      toast.error('Seu produto pode ter no máximo 500 combinações possíveis');
      return 0;
    }

    let obj = {};
    let label = '';
    let sub_attribute_ids = [];
    const arrayTotalGrid = [];

    resultGrid.map((response, gridIndex) => {
      response.map((r, index) => {
        label += label ? ` - ${r.name}` : r.name;
        obj = { ...obj, [`sub_attribute_id_${index + 1}`]: r.id };
        // sub_attribute_ids.push(r.id);
        return 0;
      });
      obj = {
        id: 0,
        // sub_attribute_ids,
        ...obj,
        label,
        price: price || null,
        promotion_price: promoPrice || null,
        stock_quantity: null,
        active: true,
        order: gridIndex + 1,
      };
      arrayTotalGrid.push(obj);
      obj = {};
      sub_attribute_ids = [];
      label = '';
      return 0;
    });

    loadGrid(arrayTotalGrid);
    handleModal();

    setSelectedItemsArray([]);
    return 0;
  }

  async function loadAttributes() {
    await api.get('attributeswithsubs').then(response => {
      setAttributes(response.data);
    });

    if (productId > 0) {
      await api
        .get(`subattributesselecteditems/${productId}`)
        .then(response => {
          setSelectedItemsArray(response.data);
        });
    }
  }

  useEffect(() => {
    loadAttributes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  function clearFields() {
    setNewSubs([
      {
        id: Math.floor(Math.random() * 1000) + 1,
        name: '',
        attr_id: 0,
        isNew: true,
      },
    ]);
    setInputVariationName('');
    setVariationId(0);
    setVariationName('');
  }

  function closeModalAddVariation() {
    setShowAddVariantModal(false);
    setShowAddSubVariantModal(false);
    clearFields();
  }

  async function handleDeleteVariation(id) {
    await api.delete(`/attributes/${id}`);

    const array = attributes && attributes.filter(att => att.id !== id);

    setAttributes(array);
  }

  async function handleDeleteSub(sub, index) {
    if (sub.id > 0) {
      await api.delete(`/subattributes/${sub.id}`);
    }

    const array = newSubs.filter((ns, ind) => ind !== index);

    setNewSubs(array);
  }

  function handleBlurSub(sub, index, text) {
    const array = [...newSubs];

    const existsName = newSubs.filter(
      (ns, ind) => ns.name === text && ind !== index
    );

    if (existsName.length > 0) {
      handleDeleteSub(sub, index);
    } else {
      array[index].name = text;

      setNewSubs(array);
    }
  }

  async function handleSubmitSubVariation() {
    let newSubAttr = null;
    //
    if (variationId > 0) {
      await Promise.all(
        newSubs &&
          newSubs.map(async (ns, index) => {
            if (ns.name) {
              if (ns.isNew) {
                newSubAttr = await api.post('/subattributes', {
                  name: ns.name,
                  attribute_id: variationId,
                  order: index,
                });
              } else {
                newSubAttr = await api.put(`/subattributes/${ns.id}`, {
                  name: ns.name,
                  order: index,
                });
              }
            }
          })
      );
    }

    const findIndex =
      attributes && attributes.findIndex(ns => ns.id === variationId);

    if (newSubAttr && newSubAttr.data) {
      const findIndexNewSubs =
        newSubs && newSubs.findIndex(ns => ns.name === newSubAttr.data.name);

      const newSubs2 = newSubs;
      newSubs2[findIndexNewSubs] = newSubAttr.data;

      const copyAttributes = attributes;
      copyAttributes[findIndex].sub_attributes = newSubs2;

      // console.log(copyAttributes);

      setAttributes(copyAttributes);
    }

    await loadAttributes();
    closeModalAddVariation();

    return 0;
  }

  async function handleSubmitVariation() {
    if (!inputVariationName) {
      toast.error('Preencha o nome da variação');
      return 0;
    }

    const attribute = await api.post('/attributes', {
      name: inputVariationName,
    });

    if (attribute && attribute.data) {
      await Promise.all(
        newSubs &&
          newSubs.map(async (ns, index) => {
            if (ns.name) {
              await api.post('/subattributes', {
                name: ns.name,
                attribute_id: attribute.data.id,
                order: index,
              });
            }
          })
      );

      // setAttributes([...attributes, attribute.data]);
    }

    closeModalAddVariation();
    loadAttributes();
    return 0;
  }

  async function handleShowSubVariationsModal(id, name) {
    setVariationId(id);
    setVariationName(name);

    await api.get(`/subattributes/${id}`).then(response => {
      const arr = [...response.data];
      setNewSubs(arr);
    });

    setShowAddSubVariantModal(true);
  }

  function hasMoreThreeVariations() {
    const grouped = Array.from(groupedMap.values());

    if (grouped.length >= 3) {
      return true;
    }

    return false;
  }

  function handleSelectedItemArray(obj, index) {
    // const arrayGrids = selectedItemsArray;

    const existsObjInArray = selectedItemsArray.findIndex(
      arr => arr.id === obj.id
    );

    if (existsObjInArray > -1) {
      const response = selectedItemsArray.filter(arr => arr.id !== obj.id);
      setSelectedItemsArray(response);
      // arrayGrids.splice(existsObjInArray, 1);
    } else {
      const response = selectedItemsArray.filter(
        arr => arr.attribute_id === obj.attribute_id
      );

      if (response.length > 0) {
        setSelectedItemsArray([...selectedItemsArray, obj]);
      } else {
        const hasMoreThree = hasMoreThreeVariations();

        if (hasMoreThree) {
          toast.error('Você só pode inserir 3 atributos por produto');
        } else {
          setSelectedItemsArray([...selectedItemsArray, obj]);
        }
      }

      // arrayGrids.push(obj);
    }
  }

  function handleSave() {
    geraGrid();
  }

  function handleInsertNewSub() {
    const existsEmptyName = newSubs.filter(n => n.name === '');

    if (existsEmptyName.length === 0) {
      setNewSubs([
        {
          id: Math.floor(Math.random() * 1000) + 1,
          name: '',
          // attr_id: 0,
          attribute_id: (newSubs && newSubs[0] && newSubs[0].attribute_id) || 0,
          isNew: true,
        },
        ...newSubs,
      ]);
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  function onDragEndVariations(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const VariationsReordered = reorder(
      newSubs,
      result.source.index,
      result.destination.index
    );

    setNewSubs(VariationsReordered);
  }

  return (
    <Container showModal={showModal} isDesktop={window.innerWidth > 720}>
      <header>
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img src={CancelIcon} alt="cancel" onClick={handleModal} />
        <strong>Adicionar variações</strong>
      </header>

      <DivBody>
        <span>
          Adicione ao seu produto variantes como &quot;cores&quot;,
          &quot;tamanhos&quot;, e outros.
        </span>

        <button type="button" onClick={() => setShowAddVariantModal(true)}>
          Criar nova variação
        </button>

        {attributes &&
          attributes.map((att, index) => (
            <Card
              key={att.id}
              className={
                selectedItemsArray.filter(s => s.attribute_id === att.id)
                  .length > 0
                  ? 'cardSelected'
                  : ''
              }
            >
              <header>
                <strong>{att.name}</strong>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                {/* <img src={ArrowDownIcon} alt="arrow" /> */}
              </header>

              <span>Selecione as opções</span>

              <div>
                <button
                  type="button"
                  onClick={() => handleShowSubVariationsModal(att.id, att.name)}
                >
                  <img src={PlusIcon} alt="plus" />
                </button>

                {/* selectedItemsArray1.includes(obj) */}

                {att.sub_attributes &&
                  att.sub_attributes.map(sub => (
                    <div
                      // className={
                      //   // selectedItemsArray &&
                      //   selectedItemsArray.length > 0 &&
                      //   // eslint-disable-next-line prettier/prettier
                      //   selectedItemsArray[index].filter(a => a.id === sub.id).length > 0
                      //     ? 'selected'
                      //     : ''
                      // }
                      className={
                        selectedItemsArray.filter(s => s.id === sub.id).length >
                        0
                          ? 'selected'
                          : ''
                      }
                      onClick={() => handleSelectedItemArray(sub, index)}
                      key={sub.id}
                    >
                      <span>{sub.name}</span>
                    </div>
                  ))}
              </div>
            </Card>
          ))}
      </DivBody>

      <footer>
        {/* botão de salvar da tela de adicionar variações */}
        <button type="button" onClick={handleSave}>
          Salvar
        </button>
      </footer>

      {showAddVariantModal && (
        <AddVariation isDesktop={window.innerWidth > 720}>
          <DivAddVariation isDesktop={window.innerWidth > 720}>
            <header>
              <strong>Adicionar variação</strong>
              <img
                src={CancelIcon}
                alt="cancel"
                onClick={closeModalAddVariation}
              />
            </header>

            <div>
              <strong>Nome da variação</strong>
              <span>Exemplo: “Cor”.</span>
              <input
                type="text"
                value={inputVariationName}
                onChange={e => setInputVariationName(e.target.value)}
              />

              <strong>Opções</strong>
              <span>Exemplo: “Azul”, “Amarelo”</span>

              <button type="button" onClick={handleInsertNewSub}>
                + Adicionar nova opção
              </button>

              {/* <DivItems create>
                <div>
                  {newSubs &&
                    newSubs.map((ns, index) => (
                      <DivItem key={ns.name}>
                        <input
                          type="text"
                          defaultValue={ns.name}
                          onBlur={e => handleBlurSub(ns, index, e.target.value)}
                        />
                        <img
                          src={CancelGreyIcon}
                          alt="cancel"
                          onClick={() => handleDeleteSub(ns, index)}
                        />
                      </DivItem>
                    ))}
                </div>
              </DivItems> */}

              <DragDropContext onDragEnd={onDragEndVariations}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <DivItems
                      create
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {newSubs &&
                        newSubs.map((ns, index) => (
                          <Draggable
                            key={String(ns.id)}
                            draggableId={String(ns.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DivItem key={ns.name}>
                                  <div>
                                    <img
                                      src={DragAndDropPointsIcon}
                                      alt="points"
                                    />
                                  </div>
                                  <input
                                    type="text"
                                    autoComplete="nope"
                                    defaultValue={ns.name}
                                    onBlur={e =>
                                      handleBlurSub(ns, index, e.target.value)
                                    }
                                  />
                                  <img
                                    src={CancelGreyIcon}
                                    alt="cancel"
                                    onClick={() => handleDeleteSub(ns, index)}
                                  />
                                </DivItem>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </DivItems>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <footer>
              <button type="button" onClick={handleSubmitVariation}>
                Salvar
              </button>
            </footer>
          </DivAddVariation>
        </AddVariation>
      )}

      {showAddSubVariantModal && (
        <AddVariation isDesktop={window.innerWidth > 720}>
          <DivAddVariation isDesktop={window.innerWidth > 720}>
            <header>
              <strong>{variationName}</strong>
              <img
                src={CancelIcon}
                alt="cancel"
                onClick={closeModalAddVariation}
              />
            </header>

            <div>
              <strong>Opções</strong>
              <span>Exemplo: “Azul”, “Amarelo”</span>

              <button type="button" onClick={handleInsertNewSub}>
                + Adicionar nova opção
              </button>

              {/* <DivItems>
                {newSubs &&
                  newSubs.map((ns, index) => (
                    <DivItem key={ns.name}>
                      <input
                        type="text"
                        defaultValue={ns.name}
                        onBlur={e => handleBlurSub(ns, index, e.target.value)}
                      />
                      <img
                        src={CancelGreyIcon}
                        alt="cancel"
                        onClick={() => handleDeleteSub(ns, index)}
                      />
                    </DivItem>
                  ))}
              </DivItems> */}

              <DragDropContext onDragEnd={onDragEndVariations}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <DivItems
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      create
                    >
                      {newSubs &&
                        newSubs.map((ns, index) => (
                          <Draggable
                            key={String(ns.id)}
                            draggableId={String(ns.id)}
                            index={index}
                          >
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <DivItem>
                                  <div>
                                    <img
                                      src={DragAndDropPointsIcon}
                                      alt="points"
                                    />
                                  </div>

                                  <input
                                    type="text"
                                    autoComplete="nope"
                                    defaultValue={ns.name}
                                    onBlur={e =>
                                      handleBlurSub(ns, index, e.target.value)
                                    }
                                  />
                                  <img
                                    src={CancelGreyIcon}
                                    alt="cancel"
                                    onClick={() => handleDeleteSub(ns, index)}
                                  />
                                </DivItem>
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </DivItems>
                  )}
                </Droppable>
              </DragDropContext>
            </div>

            <footer>
              <button type="button" onClick={() => handleSubmitSubVariation()}>
                Salvar
              </button>
            </footer>
          </DivAddVariation>
        </AddVariation>
      )}
    </Container>
  );
}

export default Variations;

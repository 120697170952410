import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const DivBody = styled.div`
  margin-top: 55px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px 8px 16px;

  strong {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    margin-bottom: 2px;
  }

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 24px;
    padding: 12px;
    border: none;

    &::placeholder {
      font-size: 13px;
      line-height: 19px;
      color: #afafaf;
    }
  }

  > button {
    margin: auto;
    margin-top: 16px;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #2b8ff5;
    background: none;
    border: none;
  }
`;

export const DivRadio = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 20px;
`;

export const Radio = styled.div`
  display: flex;

  & + div {
    margin-left: 16px;
  }

  > span {
    font-size: 13px !important;
    line-height: 19px !important;
    color: #0e0e0e !important;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #efefef;
    border-radius: 50%;
    margin-right: 6px;

    > div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #efefef;
    }
  }

  .selected {
    background: #2b8ff5;

    > div {
      background: #fff;
    }
  }
`;

export const DivSingleUse = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
`;

export const DivSingleUseHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DivSwitchSingleUse = styled.div`
  margin-horizontal: 16px;
`;

export const DivValue = styled.div`
  display: flex;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
  }

  > input {
    width: 100px;
    height: 42px;
    background: #f9f9f9;
    border-radius: 0 8px 8px 0;
    border: none;
    padding: 12px;

    &::placeholder {
      font-size: 13px;
      line-height: 19px;
      color: #afafaf;
    }
  }
`;

export const DivMore = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  margin-top: 24px;

  > span {
    margin-bottom: 12px;
  }
`;

export const ButtonSubmit = styled.button`
  background: #2b8ff5 !important;
  border-radius: 8px !important;
  color: #fff !important;
  width: 100%;
  height: 50px;

  font-weight: 500;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff;
`;

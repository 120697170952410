/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import { useKeenSlider } from 'keen-slider/react';
import { H3, Span } from '~/designsystem/Fonts';
import CardProPlan from '~/components/ProPlan/Cards/CardProPlan';
import CardPremiumPlan from '~/components/ProPlan/Cards/CardPremiumPlan';
import CardUltraPlan from '~/components/ProPlan/Cards/CardUltraPlan';

import 'keen-slider/keen-slider.min.css';

import CardFreePlan from '~/components/ProPlan/Cards/CardFreePlan';
import api from '~/services/api';
import UpdatePlanModal from '~/components/Popups/UpdatePlan';
import ResponsiveHeader from '../../../components/HeaderMobile';

import dataPlans from '../../../data/plans.json';

import {
  Container,
  DivBody,
  DivButtonsPlanOptions,
  DivCardMyPlan,
  DivDiscount,
  DivMyPlan,
  DivOtherPlans,
  DivPlanOptions,
  DivProCards,
  MonthlyButton,
  YearlyButton,
} from './styles';

function MyPlan({ location }) {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [pro, setPro] = useState();
  const [typePlan, setTypePlan] = useState('monthly');
  const [planObject, setPlanObject] = useState({});
  const [activePlanObject, setActivePlanObject] = useState({});
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);

  const [ref, instanceRef] = useKeenSlider({
    slides: {
      perView: 1.12,
      // spacing: 16,
    },
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  // async function handleClickCreatePortalSession() {
  //   const subscription = await api.get('/subscription');

  //   if (subscription && subscription.data && subscription.data.customer_id) {
  //     await api
  //       .post('/createportalsession', {
  //         customerId: subscription.data.customer_id,
  //       })
  //       .then(response => {
  //         window.location.href = response.data.url;
  //       });
  //   }
  // }

  useEffect(() => {
    async function loadPro() {
      await api.get('/proplan').then(response => {
        setPro(response.data);
      });
    }

    loadPro();
  }, []);

  useEffect(() => {
    async function load() {
      await api.get('/stripe/plan/active').then(response => {
        setActivePlanObject(response.data);
      });
    }

    load();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (pro) {
      instanceRef.current.moveToIdx(pro);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pro]);

  async function handleClickCreatePortalSession() {
    const subscription = await api.get('/subscription');

    if (subscription && subscription.data && subscription.data.customer_id) {
      await api
        .post('/createportalsession', {
          customerId: subscription.data.customer_id,
        })
        .then(response => {
          window.location.href = response.data.url;
        });
    }
  }

  function handleUpdatePlan({
    plan_name,
    stripe_price_id,
    selectedTypePlan,
    price,
  }) {
    setShowUpdatePlanModal(true);
    console.log('plan_name', plan_name, 'stripe_price_id', stripe_price_id);
    setPlanObject({ plan_name, stripe_price_id, selectedTypePlan, price });
  }

  function handleShowUpdatePlanModal(value) {
    setShowUpdatePlanModal(value);
    setPlanObject({});
  }

  return (
    <Container>
      <ResponsiveHeader
        title="Meu plano"
        url={(location && location.url) || '/settings'}
        tab={1}
      />

      <DivBody>
        <DivMyPlan>
          <H3>Seu plano atual</H3>

          <DivCardMyPlan>
            {pro === 0 && <CardFreePlan pro={pro} />}

            {activePlanObject && activePlanObject.id && (
              <>
                {pro === 1 && (
                  <CardProPlan
                    pro={pro}
                    typePlan={typePlan}
                    handleClickCreatePortalSession={
                      handleClickCreatePortalSession
                    }
                    activePlanObject={activePlanObject}
                    isMyPlan
                  />
                )}

                {pro === 2 && (
                  <CardPremiumPlan
                    pro={pro}
                    typePlan={typePlan}
                    handleClickCreatePortalSession={
                      handleClickCreatePortalSession
                    }
                    activePlanObject={activePlanObject}
                    isMyPlan
                  />
                )}

                {pro === 3 && (
                  <CardUltraPlan
                    pro={pro}
                    typePlan={typePlan}
                    handleClickCreatePortalSession={
                      handleClickCreatePortalSession
                    }
                    activePlanObject={activePlanObject}
                    isMyPlan
                  />
                )}
              </>
            )}
          </DivCardMyPlan>
        </DivMyPlan>

        <DivOtherPlans>
          <H3>Outros planos disponíveis</H3>

          <Span>Deslize para o lado para ver todos os planos</Span>

          <DivPlanOptions>
            <DivButtonsPlanOptions>
              <MonthlyButton
                type="button"
                onClick={() => setTypePlan('monthly')}
                active={typePlan === 'monthly'}
              >
                Mensal
              </MonthlyButton>

              <YearlyButton
                type="button"
                onClick={() => setTypePlan('yearly')}
                active={typePlan === 'yearly'}
              >
                Anual
                <div>até 63% OFF</div>
              </YearlyButton>
            </DivButtonsPlanOptions>
          </DivPlanOptions>

          <DivDiscount>
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
                stroke="#FF6C4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M9 4.2002V9.0002L12.2 10.6002"
                stroke="#FF6C4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span>Descontos por tempo limitado</span>
          </DivDiscount>

          <div className="dots">
            {[
              ...Array(
                instanceRef &&
                  instanceRef.current &&
                  instanceRef.current.track.details.slides.length
              ).keys(),
            ].map(idx => {
              return (
                <button
                  key={idx}
                  onClick={() => {
                    instanceRef.current.moveToIdx(idx);
                  }}
                  className={`dot${currentSlide === idx ? ' active' : ''}`}
                />
              );
            })}
          </div>

          {pro > -1 && (
            <DivProCards>
              <div className="keen-slider" ref={ref}>
                <CardFreePlan
                  className="keen-slider__slide"
                  pro={pro}
                  showBorder
                />

                <CardProPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    (dataPlans.pro.price[typePlan].stripe_price_id ===
                      activePlanObject.id ||
                      dataPlans.oldPro.stripe_price_id === activePlanObject.id)
                  }
                />

                <CardPremiumPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    dataPlans.premium.price[typePlan].stripe_price_id ===
                      activePlanObject.id
                  }
                />

                <CardUltraPlan
                  className="keen-slider__slide"
                  pro={pro}
                  typePlan={typePlan}
                  handleClickCreatePortalSession={
                    handleClickCreatePortalSession
                  }
                  handleUpdatePlan={handleUpdatePlan}
                  showBanner
                  showBorder
                  activePlanObject={activePlanObject}
                  isMyPlan={
                    activePlanObject &&
                    dataPlans.ultra.price[typePlan].stripe_price_id ===
                      activePlanObject.id
                  }
                />
              </div>
            </DivProCards>
          )}
        </DivOtherPlans>
      </DivBody>

      <UpdatePlanModal
        active={showUpdatePlanModal}
        setActive={handleShowUpdatePlanModal}
        planObject={planObject}
      />
    </Container>
  );
}

export default MyPlan;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px 0 32px;
`;

export const RedLabel = styled.div`
  display: flex;
  background: rgba(255, 48, 60, 0.06);
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 24px;

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #ff303c;

    > span {
      font-weight: 500;
    }
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #101010;
    margin-bottom: 4px;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;

    > a {
      color: #2b8ff5;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  margin-bottom: 32px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
    padding: 12px;
    height: 42px;
  }

  > input {
    width: 100%;
    background: #f9f9f9;
    border-radius: 0 8px 8px 0;
    border: none;
    padding: 0 8px;
    height: 42px;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;

  .div-endereco {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;

      input {
        & + input {
          margin-left: 24px;
        }
      }
    }
  }

  .div-buttons-payment-methods {
    display: flex;
    flex-wrap: wrap;

    button {
      border: 0.5px solid #d8dee5;
      background: #fff;
      padding: 0 18px;
      height: 45px;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .selected {
      background: #2b8ff5;
      border: 0.5px solid #2b8ff5;
      color: #fff;
    }
  }

  > div {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    strong {
      font-weight: 500;
      font-size: 15px;
      color: #000;
    }

    span {
      margin-top: 13px;
      font-weight: normal;
      font-size: 13px;
      color: #838383;
    }

    input {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }
  }
`;

/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
// import arrowDownIcon from '~/assets/icons/offstorepay/arrow-down.svg';
import { NextButton } from '~/components/NextButton';
import { UniversalLoader } from '~/components/UniversalLoader/UniversalLoader';
import { Button } from '~/components/Button';
import { Modal } from '~/components/Modal';
import errorIco from '~/assets/icons/light-error-ico.svg';
import arrowLeft from '~/assets/icons/arrowLeft.svg';
import Select from 'react-select';
import { useGetBanksData } from './hooks/use-get-bank-data';
import plansOffstorepay from '../../../../../../data/plans_offstorepay.json';

import {
  Body,
  Container,
  Input,
  InputLabel,
  // Select,
  SelectWrapper,
  MaskInput,
  Subtitle,
  Title,
  GoBackButton,
  Footer,
  WarningSpan,
} from './styles';
import { StepsHeader } from '../StepsHeader';
import { useCreateSeller } from './hooks/use-create-seller';

export function ThirdStepBankData({
  onNextClick,
  desktopMode,
  sellerData,
  onGoBackClick,
}) {
  const { banksData } = useGetBanksData();
  const { handleCreateSeller } = useCreateSeller();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useFormContext();

  const userId = useSelector(
    state => state.user && state.user.profile && state.user.profile.id
  );

  const [showLoader, setShowLoader] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showErrorCpfCnpj, setShowErrorCpfCnpj] = useState(false);
  const [selectedBankAccount, setSelectedBankAccount] = useState();
  const [cpfOrCnpj, setCpfOrCnpj] = useState();

  const handleCloseModal = () => {
    setShowError(false);
  };

  function handleCpfCnpjValidate() {
    if (sellerData.documentType === 'cnpj') {
      if (cpfOrCnpj !== sellerData.companyDocumentNumber) {
        setShowErrorCpfCnpj(true);

        return { error: true };
      }
    }

    if (sellerData.documentType === 'cpf') {
      if (cpfOrCnpj !== sellerData.documentNumber) {
        setShowErrorCpfCnpj(true);

        return { error: true };
      }
    }

    setShowErrorCpfCnpj(false);
    return { ok: true };
  }

  const onSubmit = async data => {
    const validate = handleCpfCnpjValidate();

    if (validate.error) {
      return 0;
    }

    const filterMcc = plansOffstorepay.find(
      p => p.code === sellerData.productType
    );

    const newSellerData = { ...sellerData, productType: filterMcc };

    setShowLoader(true);
    const response = await handleCreateSeller({
      userId,
      sellerData: newSellerData,
      bankAccountData: data,
    });

    setShowLoader(false);

    if (!response.seller) {
      setShowError(true);
    } else {
      return onNextClick();
    }
  };

  function handleChangeBank(data) {
    setSelectedBankAccount(data);
    setValue('bank', data.value);
  }

  return (
    <>
      {showError && (
        <Modal>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={errorIco} alt="check-icon" width="70px" />
            <p
              style={{
                color: 'rgba(0, 0, 0, 1)',
                fontWeight: 600,
                fontSize: 24,
                textAlign: 'center',
                marginTop: '16px',
              }}
            >
              Ocorreu um erro
            </p>
            <p
              style={{
                color: 'rgba(131, 131, 131, 1)',
                fontSize: 16,
                textAlign: 'center',
                fontWeight: 400,
                marginTop: '12px',
                marginBottom: '24px',
              }}
            >
              Verifique se suas informações estão corretas e tente novamente
            </p>
            <Button onClick={handleCloseModal}>
              <p>Entendi</p>
            </Button>
          </div>
        </Modal>
      )}
      <UniversalLoader open={showLoader} />
      <Container desktopMode={desktopMode}>
        <div
          style={{
            width: '100%',
            paddingLeft: '16px',
            zIndex: 10,
            marginBottom: '-48px',
          }}
        >
          <GoBackButton onClick={() => onGoBackClick('secondStep')}>
            <img src={arrowLeft} alt="arrowLeft" />
          </GoBackButton>
        </div>
        <StepsHeader step="2" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Body>
            <Title>Conta bancária</Title>
            <Subtitle>
              Adicione os dados de sua conta bancária para receber os pagamentos
              feitos por seus clientes
            </Subtitle>

            {sellerData && (
              <>
                <InputLabel>
                  {sellerData.documentType === 'cnpj' ? 'CNPJ' : 'CPF'} da conta
                  bancária
                </InputLabel>
                <MaskInput
                  maskChar=""
                  mask={
                    sellerData.documentType === 'cnpj'
                      ? '99.999.999/9999-99'
                      : '999.999.999-99'
                  }
                  placeholder={
                    sellerData.documentType === 'cnpj' ? 'CNPJ' : 'CPF'
                  }
                  onChange={e => setCpfOrCnpj(e.target.value)}
                  onBlur={() => handleCpfCnpjValidate()}
                />
                {showErrorCpfCnpj && (
                  <span>
                    {sellerData.documentType === 'cnpj' ? 'CNPJ' : 'CPF'} da
                    conta bancária precisa ser igual a do titular da conta
                  </span>
                )}

                <WarningSpan>
                  A conta bancária precisa obrigatoriamente pertencer ao{' '}
                  <b>{sellerData.documentType === 'cnpj' ? 'CNPJ' : 'CPF'}</b>
                  {sellerData.documentType === 'cnpj'
                    ? '. Não utilize sua conta bancária de pessoa física, ou haverá falha nos repasses.'
                    : ', ou haverá falha nos repasses.'}
                </WarningSpan>
              </>
            )}

            <InputLabel>Banco</InputLabel>
            <SelectWrapper>
              {/* <Select {...register('bank', { required: 'Campo obrigatório' })}>
                {banksData.map(bank => (
                  <option value={`${bank.name}-${bank.code}`}>
                    {bank.fullName} - {bank.code}
                  </option>
                ))}
              </Select> */}
              <Select
                id="selected"
                placeholder="Selecionar"
                name="bank"
                {...register('bank', { required: 'Campo obrigatório' })}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderWidth: '1px',
                    width: '100%',
                    borderColor: '#cdcdcd',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    height: '48px',
                    padding: '0 8px 0 16px',
                    ':hover': {
                      borderColor: '#2B8FF5',
                    },
                  }),

                  container: provided => ({
                    ...provided,
                    width: '100%',
                  }),

                  indicatorSeparator: provided => ({
                    ...provided,
                    background: 'none',
                  }),
                  indicatorsContainer: (provided, state) => ({
                    ...provided,
                    padding: '0',
                  }),
                  valueContainer: (provided, state) => ({
                    ...provided,
                    padding: '0',
                  }),
                  menu: (provided, state) => ({
                    ...provided,
                    zIndex: 2,
                  }),
                }}
                options={banksData}
                value={selectedBankAccount}
                onChange={e => handleChangeBank(e)}
              />
              {/* <img alt="Seta indicando lista" src={arrowDownIcon} /> */}
            </SelectWrapper>
            {errors.bankCode && <span>{errors.bankCode.message}</span>}
            <InputLabel>Agência</InputLabel>
            <Input
              placeholder="Agência"
              {...register('agency', { required: 'Campo obrigatório' })}
            />
            {errors.agency && <span>{errors.agency.message}</span>}
            <InputLabel>Nº da conta</InputLabel>
            <Input
              placeholder="Número da conta"
              {...register('account', { required: 'Campo obrigatório' })}
            />
            {errors.account && <span>{errors.account.message}</span>}
          </Body>

          <Footer>
            <NextButton type="submit" />
          </Footer>
        </form>
      </Container>
    </>
  );
}

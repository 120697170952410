import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 16px 0 16px;
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  position: relative;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    width: ${props => props.spanWidth || '100%'};
  }

  > label {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;

  > button {
    background: none;
    color: #2b8ff5;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-top: 8px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  /* height: 65px; */
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};

  > div {
    .offstore-pay-description {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
      margin: 16px 0px;
    }

    span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }

    .offstore-pay-activate-button {
      padding: 9px 28px;
      background: #2b8ff5;
      border: 1px solid #2b8ff5;
      box-sizing: border-box;
      border-radius: 6px;
      text-decoration: none;
      color: #fff;
      width: 100%;
    }

    .w-full {
      width: 100%;
    }
  }

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }

    > button {
      padding: 9px 28px;
      background: #2b8ff5;
      border: 1px solid #2b8ff5;
      box-sizing: border-box;
      border-radius: 6px;
      text-decoration: none;
      color: #fff;
    }
  }

  .offstore-pay-header {
    display: flex;
    align-items: center;

    > strong {
      font-weight: 500;
    }

    .recommended-flag {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      padding: 4px 6px;
      border-radius: 4px;
      /* background: rgba(6, 200, 107, 0.1); */
      border: 1px solid #06c86b;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 500;
      color: #06c86b;
    }

    .pending-flag {
      background-color: #ffb5250f;
      color: #ffb525;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      padding: 4px 6px;
      border-radius: 4px;
    }

    .active-flag {
      background: rgba(13, 133, 255, 0.08);
      color: #0d85ff;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      padding: 4px 6px;
      border-radius: 4px;
    }

    .denied-flag {
      background: rgba(255, 48, 60, 0.1);
      color: #ff303c;
      margin-left: 8px;
      font-size: 11px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 17px;
      padding: 4px 6px;
      border-radius: 4px;
    }
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  > a {
    padding: 9px 28px;
    background: #2b8ff5;
    border: 1px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }

  .full-right {
    justify-content: flex-end;
  }
  .item-center {
    align-items: center;
  }
`;

export const ButtonSeeMore = styled.button`
  display: flex;
  align-self: center;
  font-size: 14px;
`;

export const DivDescriptionOffstorePay = styled.div`
  display: flex;
  flex-direction: column;

  .green {
    color: #06c86b;
    margin: 16px 0;
  }
`;

export const DescriptionOffstorePay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 20px;

  > div {
    display: flex;

    > img {
      width: 12px;
      /* height: 20px; */
      margin-right: 6px;
    }

    > span {
      color: #06c86b;
      font-size: 13px;
      font-weight: 400;
    }
  }
`;

export const FeesOffstorePay = styled.div`
  display: flex;
  flex-direction: column;
  /* margin: 20px 0; */
  margin-top: 8px;

  > span {
    color: var(--Body-Font, #838383);
    font-size: 11px;
    font-weight: 400;
  }

  > div {
    display: flex;
    margin-top: 4px;
    gap: 12px;

    > div {
      display: flex;
      flex-direction: column;
      border-radius: 6px;
      border: 0.5px solid #d8dee5;
      background: #fff;
      padding: 8px 12px;

      > strong {
        color: #101010;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
      }

      > span {
        color: #06c86b;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
`;

export const DivZoop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;

  > span {
    color: #b6b6b6;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
  }

  > svg {
    margin-left: 4px;
  }
`;

export const NewLabel = styled.div`
  border-radius: 3px;
  border: solid 1px #03ca9a;
  color: #03ca9a;
  font-weight: 500;
  font-size: 10px;
  padding: 2px 8px;
  margin-left: 6px;
`;

export const LabelPro = styled.div`
  display: flex;
  padding: 2px 4px;
  border: 1px solid #2b8ff5;
  border-radius: 4px;
  margin-left: 8px;

  > span {
    font-weight: 500;
    font-size: 11px !important;
    color: #2b8ff5 !important;
    line-height: 1 !important;
  }
`;

export const LabelSoon = styled.div`
  display: flex;
  padding: 2px 6px;
  border: 1px solid #9d9d9d;
  border-radius: 4px;
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;

  > span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #9d9d9d !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const SpanStatus = styled.span`
  font-style: normal !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  font-weight: ${props => props.fontWeight || 'normal'} !important;
  font-size: ${props => props.fontSize || '11px'} !important;
  color: ${props => props.color} !important;
`;

export const BuyTest = styled.div`
  display: flex;
  align-items: center;
  background: rgba(13, 133, 255, 0.08);
  width: 100%;
  padding: 16px;
  gap: 16px;
  span {
    color: #0d85ff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 810px !important;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #101010;
    margin-right: 6px;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  margin-bottom: 48px;
  padding: 32px;

  .pro {
    border: 1px solid #2b8ff5;
    border-radius: 4px;
    padding: 1px 6px;
    margin-left: 8px;
    font-size: 13px;
    color: #2b8ff5;
    font-weight: 500;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #000000;
    margin-bottom: 4px;
  }

  .span-red {
    color: #ff303c;
    margin-top: 12px;
  }

  .strong-domain {
    margin-top: 32px;
  }

  > span {
    font-size: 13px;
    color: #838383;

    > a {
      color: #2b8ff5;
      font-weight: 500;
      text-decoration: none;
    }
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d85ff;
    border-radius: 8px;
    height: 50px;
    color: #fff;
    font-weight: 500;
    font-size: 14px;
    text-decoration: none;
    margin-top: 16px;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 12px;
    background: #f9f9f9;
    border-radius: 8px;

    > input {
      height: 42px;
      width: 100%;
      background: #f9f9f9;
      /* border-radius: 8px; */
      border: none;
      padding: 0 12px;
      font-weight: 400;
      font-size: 13px;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
      color: #101010;

      &::placeholder {
        color: #bfbfbf;
      }

      /* &:disabled {
        opacity: 0.5;
      } */
    }

    > button {
      border: none;
      background: none;
      display: flex;
      align-items: center;
      justify-content: center;

      /* &:disabled {
        opacity: 0.5;
      } */

      > img {
        width: 20px;
      }
    }

    .add-domain-button {
      font-weight: 600;
      font-size: 13px;
      color: #2b8ff5;
      padding: 0 12px;

      ${props =>
        props.disableInput &&
        css`
          font-weight: 400;
          font-size: 13px;
          background: rgba(43, 143, 245, 0.1);
          height: 24px;
          padding: 0 8px;
          border-radius: 4px;
          margin-right: 12px;
          color: #34c780;
        `}
    }

    .remove-domain-button {
      font-weight: 500;
      font-size: 13px;
      color: #838383;
      padding-right: 12px;
    }
  }

  .div-buy-domain {
    display: flex;
    flex-direction: column;
    background: none;
    margin-top: 24px;

    > span {
      font-weight: 400;
      font-size: 13px;
      color: #101010;
    }

    > a {
      color: #2b8ff5;
      font-size: 13px;
    }
  }
`;

export const DivInput = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  width: 100%;
  background: #f9f9f9;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  padding: 0 12px;

  > a {
    text-decoration: none;

    > span {
      font-weight: 500;
      font-size: 13px;
      color: #2b8ff5;
      cursor: pointer;
      margin-right: 6px;
    }
  }
`;

export const DivDomainSuccess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: none !important;

  > span {
    font-size: 13px;
    color: #0d85ff;
    margin-left: 6px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 0 24px;
  border: 1px solid #d8dee5;
  box-shadow: 6px 23px 28px rgba(0, 0, 0, 0.05);

  strong {
    margin-top: 15px;
    font-weight: 600;
    font-size: 13px;
    color: #2b2b2b;
  }

  > span {
    font-size: 13px;
    color: #a2a8b2;
  }

  .forgot-password {
    margin: 16px 0;
    font-size: 13px;
    text-align: right;
    text-decoration-line: underline;
    font-weight: normal;
    color: #129eed;
    align-self: end;
  }

  .link-register {
    margin-top: 12px;
  }

  .div-input {
    margin-top: 10px;
    display: flex;

    > div {
      /* border-bottom: 1px solid #d8dee5; */
    }

    img {
      margin-right: -20px;
    }
  }

  h1 {
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #262626;

    svg {
      margin-right: 10px;
    }
  }

  a {
    font-size: 13px;
    text-decoration-line: underline;
    color: #129eed;
  }

  form {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
    max-width: 500px;

    span {
      color: #e53030;
      margin-bottom: 5px;
      font-weight: bold;
    }

    input {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 1px solid #d8dee5;
      background: none;
      font-size: 13px;
      width: 100%;
      padding: 4px 0;

      &:focus {
        border-bottom: 1px solid #000000;
      }

      &::placeholder {
        color: #cccccc;
      }
    }
  }
`;

export const Title = styled.h1`
  color: #fff;
`;

export const ButtonSubmit = styled.button.attrs(props => ({
  type: 'submit',
  disabled: props.loading,
}))`
  background: ${props => (props.block ? '#CACACA' : '#2b8ff5')};
  color: #fff;
  border: none;
  padding: 0 15px;
  height: 40px;
  box-shadow: 6px 11px 13px rgba(10, 12, 13, 0.1);
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;

  display: flex;
  justify-content: center;
  align-items: center;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
`;

import styled from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: ${({ desktopMode }) => (desktopMode ? '100%' : '100vh')};
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  position: relative;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 674px;
  }
`;

export const Body = styled.div`
  display: flex;
  margin: 24px 16px 24px 16px;
  flex-direction: column;
  flex: 1;
  width: 90%;

  span {
    color: #ff303c;
    font-size: 12px !important;
    font-weight: 500;
    margin-top: 4px;
  }
`;

export const Title = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-bottom: 12px;
`;

export const Subtitle = styled.p`
  font-size: 13px;
  font-weight: 400;
  color: #838383;
  text-align: center;
  margin-bottom: 8px;
`;

export const InputLabel = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #000000;
  margin-top: 24px;

  span {
    color: #ff303c;
    font-size: 14px;
    font-weight: 500;
  }
`;

export const WarningSpan = styled.div`
  color: #838383;
  font-size: 12px;
  margin-top: 4px;
`;

export const Input = styled.input`
  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  margin-top: 8px;
  border: solid 0.5px #f9f9f9;

  &:focus {
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const MaskInput = styled(InputMask)`
  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  margin-top: 8px;
  border: solid 0.5px #f9f9f9;

  &:focus {
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const SelectWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  margin-top: 8px;
  display: flex;
`;

export const Select = styled.select`
  font-size: 13px;
  appearance: none;
  background: #ffffff;
  border: 0;
  flex: 1;
  margin: 0px;
  padding: 0 12px;
  height: 100%;
  width: 100% !important;
  text-overflow: ellipsis;
`;

export const GoBackButton = styled.button`
  width: 35px;
  height: 35px;
  background: #efefef;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  padding: 8px 16px 16px 16px;
`;

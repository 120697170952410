import styled, { css } from 'styled-components';
import InputMask from 'react-input-mask';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fff;
  min-height: ${({ desktopMode }) => (desktopMode ? '' : '100vh')};
  max-height: ${({ desktopMode }) => (desktopMode ? '' : '100%')};
  height: ${({ desktopMode }) => (desktopMode ? '' : '100&')};
  align-items: center;
  justify-content: center;
  padding: 24px 0px;
  overflow: auto;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    width: 100%;
    max-width: 674px;
  }
`;

export const Body = styled.div`
  display: flex;
  margin: 24px 16px 78px 16px;
  flex-direction: column;
  flex: 1;
  width: 90%;

  span {
    color: #ff303c;
    font-size: 12px !important;
    font-weight: 500;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  background: #ffffff;
  padding: 8px 16px 16px 16px;
`;

export const Title = styled.p`
  font-size: 28px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  margin-bottom: 8px;
`;

export const InputLabel = styled.p`
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  span {
    color: #ff303c;
    font-size: 14px;
    font-weight: 500;
    margin-left: 4px;
  }
`;

export const WarningSpan = styled.div`
  color: #838383;
  font-size: 12px;
  margin-top: 4px;
`;

export const DivSelecteButtons = styled.div`
  display: flex;
  margin-top: 8px;
`;

export const SelectButton = styled.button`
  padding: 0 24px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #9d9d9d;
  background-color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  color: #9d9d9d;


  ${props =>
    props.left &&
    css`
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-right: none;
    `}

  ${props =>
    props.right &&
    css`
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-left: none;
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;

        p {
          color: #0d85ff;
        }
      `}
`;

export const Input = styled.input`
  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  border: solid 0.5px #f9f9f9;
  /* margin-bottom: 24px; */
  margin-top: 8px;

  &:focus {
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const DivInputNumber = styled.div`
  display: flex;
  margin-top: 8px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px 0px 0px 8px;
    background: #efefef;
    height: 42px;
    width: 42px;
    font-size: 13px;
    font-weight: 400;
  }
`;

export const InputNumber = styled.input`
  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 0px 8px 8px 0px;
  font-size: 13px;
  border: solid 0.5px #f9f9f9;
  /* margin-bottom: 24px; */

  &:focus {
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const MaskInput = styled(InputMask)`
  width: ${props => props.width || '100%'};
  border-bottom: none;
  background: #f9f9f9;
  padding: 12px 12px;
  border-radius: 8px;
  font-size: 13px;
  margin-top: 8px;
  border: solid 0.5px #f9f9f9;

  &:focus {
    border: 0.5px solid rgb(43, 143, 246);
  }

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 32px 0px;
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  width: 100%;
  padding: 14px 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #838383;
    }

    > button {
      padding: 9px 28px;
      background: #2b8ff5;
      border: 1px solid #2b8ff5;
      box-sizing: border-box;
      border-radius: 6px;
      text-decoration: none;
      color: #fff;
    }
  }
`;

export const Box = styled.div`
  display: flex;
  gap: ${props => props.gap || '0px'};
`;

export const InputContainer = styled.div`
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '100%'};
`;

export const HeaderSection = styled.h1`
  color: #000;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const GoBackButton = styled.button`
  width: 35px;
  height: 35px;
  background: #efefef;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0px;
`;

export const SelectWrapper = styled.div`
  width: ${props => props.width || '100%'};
  background: #ffffff;
  padding: 12px 12px 12px 0;
  border-radius: 8px;
  margin-top: 8px;
  border: solid 1px #d8dee5;
  display: flex;
  justify-content: space-between;
`;

export const Select = styled.select`
  font-size: 13px;
  background: #ffffff;
  appearance: none;
  border: 0;
  flex: 1;
  margin: 0px;
  padding: 0 12px;
  height: 100%;
  width: 100% !important;
  text-overflow: ellipsis;
  color: #101010;
`;

export const DivTerms = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  > span {
    margin-left: 8px;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    color: #838383 !important;

    > a {
      font-weight: 600;
      color: #0d85ff;
    }
  }
`;

export const CheckBoxTerms = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border: solid 1px #efefef;
  background: #ffffff;

  ${props =>
    props.active &&
    css`
      border: solid 1px #0d85ff;
      background: #0d85ff;
    `}
`;

export function isValidUserToPlan(user) {
  const inputDate = new Date(user.createdAt);

  // Definir a data específica para comparação (03/06/2024 às 18:00)
  // Note que os meses em JavaScript são baseados em zero (0 = Janeiro, 1 = Fevereiro, etc.)
  const comparisonDate = new Date(2024, 5, 4, 19, 0, 0); // 3 de Junho de 2024 às 19:00 UTC 00:00

  const isNewUpdateDate = inputDate > comparisonDate;

  if (isNewUpdateDate && user.pro >= 2) {
    return true;
  }

  if (!isNewUpdateDate && user.pro >= 1) {
    return true;
  }

  return false;
}

import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 810px !important;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #101010;
    margin-right: 6px;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  margin-bottom: 48px;
  padding: 32px;

  .pro {
    border: 1px solid #2b8ff5;
    border-radius: 4px;
    padding: 1px 6px;
    margin-left: 8px;
    font-size: 13px;
    color: #2b8ff5;
    font-weight: 500;
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 0;
  border-top: solid 0.5px #d8dee5;

  ${props =>
    props.first &&
    css`
      border: none;
      padding-top: 24px;
    `}
`;

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 990;
`;

export const Modal = styled.div`
  position: fixed;
  margin: auto;
  background: #fff;
  top: calc(50vh - (222px / 2));
  left: calc(50vw - (522px / 2));
  box-shadow: rgb(172 161 161 / 25%) 16px 34px 58px;
  width: 100%;
  max-width: 522px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  z-index: 999;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #131313;
    margin-bottom: 16px;
  }

  > span {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    height: 42px;
    padding: 0 12px;
    width: 100%;
    margin-bottom: 16px;
  }

  > button {
    background: #2b8ff5;
    border-radius: 8px;
    height: 50px;
    width: 100%;
    color: #fff;
    border: none;
  }
`;

import produce from 'immer';

const INITIAL_STATE = {
  regions: null,
  loading: false,
  freight: {},
};

export default function freight(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@freight/LOAD_REGIONS_SUCCESS': {
        draft.regions = action.payload.data;
        break;
      }

      case '@freight/LOAD_FREIGHT_SUCCESS': {
        draft.freight = action.payload.data;
        break;
      }

      case '@freight/UPDATE_FREIGHT_SUCCESS': {
        if (action.payload.field === 'PAC') {
          draft.freight.pac = action.payload.value;
        } else if (action.payload.field === 'SEDEX') {
          draft.freight.sedex = action.payload.value;
        } else if (action.payload.field === 'FLATRATE') {
          draft.freight.flatrate = action.payload.value;
        } else if (action.payload.field === 'FREE') {
          draft.freight.free = action.payload.value;
        }

        draft.freight.enabled = !action.payload.allDisabled;

        break;
      }

      case '@freight/ADD_CITY_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@freight/ADD_CITY_SUCCESS': {
        const indexRegion =
          draft.regions &&
          draft.regions.findIndex(d => d.id === action.payload.regionId);

        const indexUf =
          draft.regions &&
          draft.regions[indexRegion] &&
          draft.regions[indexRegion].ufs &&
          draft.regions[indexRegion].ufs.findIndex(
            d => d.id === action.payload.ufId
          );

        draft.regions[indexRegion].ufs[indexUf].cities.unshift(
          action.payload.newCity
        );

        draft.loading = false;
        break;
      }

      case '@freight/ADD_CITY_FAILURE': {
        draft.loading = false;

        break;
      }

      case '@freight/ADD_NEIGHBORHOOD_REQUEST': {
        draft.loading = true;

        break;
      }

      case '@freight/ADD_NEIGHBORHOOD_SUCCESS': {
        const indexRegion =
          draft.regions &&
          draft.regions.findIndex(d => d.id === action.payload.regionId);

        const indexUf =
          draft.regions &&
          draft.regions[indexRegion] &&
          draft.regions[indexRegion].ufs &&
          draft.regions[indexRegion].ufs.findIndex(
            d => d.id === action.payload.ufId
          );

        const indexCity = draft.regions[indexRegion].ufs[
          indexUf
        ].cities.findIndex(c => c.id === action.payload.cityId);

        draft.regions[indexRegion].ufs[indexUf].cities[
          indexCity
        ].neighborhoods.unshift(action.payload.newNeighborhood);

        draft.loading = false;
        break;
      }

      case '@freight/ADD_NEIGHBORHOOD_FAILURE': {
        draft.loading = false;

        break;
      }

      case '@freight/REMOVE_CITY_SUCCESS': {
        const indexRegion =
          draft.regions &&
          draft.regions.findIndex(d => d.id === action.payload.regionId);

        const indexUf =
          draft.regions &&
          draft.regions[indexRegion] &&
          draft.regions[indexRegion].ufs &&
          draft.regions[indexRegion].ufs.findIndex(
            d => d.id === action.payload.ufId
          );

        const filteredCities = draft.regions[indexRegion].ufs[
          indexUf
        ].cities.filter(c => c.id !== action.payload.cityId);

        draft.regions[indexRegion].ufs[indexUf].cities = filteredCities;

        break;
      }

      case '@freight/REMOVE_NEIGHBORHOOD_SUCCESS': {
        const indexRegion =
          draft.regions &&
          draft.regions.findIndex(d => d.id === action.payload.regionId);

        const indexUf =
          draft.regions &&
          draft.regions[indexRegion] &&
          draft.regions[indexRegion].ufs &&
          draft.regions[indexRegion].ufs.findIndex(
            d => d.id === action.payload.ufId
          );

        const indexCity = draft.regions[indexRegion].ufs[
          indexUf
        ].cities.findIndex(c => c.id === action.payload.cityId);

        const filteredNeighborhood = draft.regions[indexRegion].ufs[
          indexUf
        ].cities[indexCity].neighborhoods.filter(
          n => n.id !== action.payload.neighborhoodId
        );

        draft.regions[indexRegion].ufs[indexUf].cities[
          indexCity
        ].neighborhoods = filteredNeighborhood;

        break;
      }
      default:
        return state;
    }
  });
}

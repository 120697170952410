import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  min-height: 100vh;
`;

export const DivInitialLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  min-height: 100vh;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin: 55px 0;
  padding: 24px 16px 0 16px;
`;

export const DivBlueLabel = styled.div`
  display: flex;
  background: rgba(43, 143, 245, 0.06);
  border-radius: 2px;
  padding: 16px;

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #2b8ff5;

    > b {
      font-weight: 600;
    }
  }
`;

export const DivTextDimensions = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    margin-bottom: 4px;
  }

  > span {
    font-weight: 400;
    font-size: 12px;
    color: #838383;
    margin-bottom: 12px;
  }

  > div {
    position: relative;
    display: flex;
    align-items: center;
    /* margin-bottom: 32px; */
    font-size: 13px !important;

    > select {
      height: 45px;
      padding: 0 8px;
      border: 0.5px solid #d8dee5;
      border-radius: 8px;
      background: #fff;
      width: 100%;
      -webkit-appearance: none !important;
      color: #101010;
      font-size: 13px !important;
    }

    > img {
      position: absolute;
      right: 12px;
    }
  }
`;

export const DivExcludeCity = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  margin-top: 32px;
  /* padding: 0 32px; */

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;
    width: 80%;
  }

  > label {
    /* position: absolute;
    top: 0; */
    /* right: 32px; */
  }
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom || '0'};
  opacity: ${props => (props.active ? '1' : '0.33')};

  > header {
    display: flex;
    align-items: center;

    > span {
      font-size: 10px;
      line-height: 15px;
      color: #2b8ff5;
      margin-left: 6px;
    }
  }

  strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 2px;
  }

  > span {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    /* margin-bottom: 12px; */
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    height: 45px;
    padding: 0 12px;
    margin-top: 12px;
    color: #101010;

    &::placeholder {
      color: #bfbfbf;
    }
  }
`;

export const RedLabel = styled.div`
  display: flex;
  background: rgba(255, 48, 60, 0.06);
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 24px;

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #ff303c;

    > span {
      font-weight: 500;
    }
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 32px;
  opacity: ${props => (props.active ? '1' : '0.33')};
`;

export const DivDimensions = styled.div`
  display: flex;
  width: 100%;
  margin-top: 12px;

  > div {
    display: flex;
    flex-direction: column;
    width: 83px;

    & + div {
      margin-left: 16px;
    }

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
    }

    > span {
      color: ${props => (props.weight >= 10 ? '#FF303C' : '#838383')};
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
      text-align: center;
    }
  }
`;

export const DivInputDimension = styled.div`
  display: flex;
  width: 100%;

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  > input {
    width: 50%;
    height: 45px;
    background: #f9f9f9;
    border-radius: 8px 0px 0px 8px;
    border: none;
    padding: 0 10px;
    color: #101010;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 0px 8px 8px 0px;
    /* padding: 0 9px; */
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #101010;
    width: 40px;
  }
`;

export const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  padding: 12px 16px;
  justify-content: center;

  & + div {
    margin-top: 8px;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
  }

  > span {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    color: #9d9d9d;
  }

  > div {
    position: absolute;
    right: 16px;
  }
`;

export const DivLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  background: #fafafa;
  border-radius: 2px;
  padding: 16px;

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }
`;

export const DivIntegration = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #101010;
    margin-bottom: 4px;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 12px;

    > a {
      color: #2b8ff5;
      text-decoration: none;
      font-weight: 600;
    }
  }

  > input {
    /* height: 45px; */
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    padding: 12px;
    background: #f9f9f9;
    border-radius: 8px;
    font-size: 13px;
    line-height: 19px;
    color: #101010;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  > button {
    width: 100%;
    height: 50px;
    border: none;
    background: #2b8ff5;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin-top: 8px;
  }

  > div {
    display: flex;
    justify-content: end;
    margin-top: 8px;

    > span {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #2b8ff5;
    }

    > img {
      margin-left: 6px;
    }
  }

  .error {
    > span {
      color: #ff303c;
    }
  }
`;

export const TextDeleteButton = styled.span`
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
`;

export const DeleteButtonContainer = styled.div`
  display: flex;
  height: 48px;
  width: 100%;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  background-color: #ff303c;
  cursor: pointer;
`;

export const ContainerCallToIntegration = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CallToIntegrationText = styled.div`
  display: flex;
  width: 100%;
  /* margin-bottom: 24px; */

  > span {
    font-size: 12px;
    line-height: 18px;
    color: #838383;
    font-weight: 400;
    text-align: center;
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  margin-top: 16px;

  > button {
    width: 100%;
    border-radius: 8px;
  }
`;

import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';
import { toast } from 'react-toastify';

import { loadStripe } from '@stripe/stripe-js';

import { STRIPE_PK } from '../../services/config';

import ArrowRight from '../../assets/icons/arrowRight.svg';

import api from '../../services/api';

import { Container } from './styles';

const stripePromise = loadStripe(STRIPE_PK);

function BuyButtonStripe({ text, type }) {
  const [pro, setPro] = useState();
  const [subscription, setSubscription] = useState();
  const whatsapp = useSelector(state => state.user.profile.whatsapp);

  const email = useSelector(
    state => state.user && state.user.profile && state.user.profile.email
  );

  useEffect(() => {
    async function loadPro() {
      await api.get('/proplan').then(response => {
        setPro(response.data);
      });

      await api.get('/subscription').then(response => {
        setSubscription(response.data);
      });
    }

    loadPro();
  }, []);

  async function sendWelcomeMessage() {
    if (whatsapp) {
      const payload = {
        to: whatsapp,
        template: type,
      };
      await api
        .post('/send-whatsapp-message', payload)
        .catch(err => console.log(err));

      return true;
    }
    return false;
  }

  const handleBuy = async () => {
    if (!subscription || (subscription && subscription.status !== 'active')) {
      ReactPixel.trackCustom('ClickToCheckout');

      const stripe = await stripePromise;

      const session = await api.post('/createsession', {
        email,
        // urlSuccess: 'https://app.offstore.me/stripemobile',
        // urlCancel: 'https://app.offstore.me/stripemobile',
        // urlSuccess: 'offstore://stripe/success',
        // urlCancel: 'offstore://stripe/cancel',
      });

      console.log(session.data);

      if (type) sendWelcomeMessage();

      const sessionId = session.data.id;

      await stripe.redirectToCheckout({
        sessionId,
      });
    } else {
      toast.success('Você já é PRO');
    }

    return 0;
  };

  return (
    <Container>
      <button type="button" onClick={handleBuy} disabled={pro >= 1}>
        {text || 'Assinar plano PRO'}
        <img src={ArrowRight} alt="arrow" />
      </button>
    </Container>
  );
}

export default BuyButtonStripe;

const size = {
  mobile: '720px',
  tablet: '1024px',
  // desktop: '1024px',
};

export const device = {
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  // desktop: `(min-width: ${size.desktop})`,
};

import React from 'react';
import { Oval } from 'react-loader-spinner';

function LoaderComponent({ size, white }) {
  return (
    <Oval
      height={size || 40}
      width={size || 40}
      color={white ? '#FFFFFF' : '#9d9d9d'}
      wrapperStyle={{}}
      wrapperClass=""
      visible
      ariaLabel="oval-loading"
      secondaryColor={white ? '#FFFFFF' : '#9d9d9d'}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  );
}

export default LoaderComponent;

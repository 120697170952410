/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import api from '../../../services/api';

import {
  Container,
  DivBody,
  DivRadio,
  Radio,
  DivValue,
  DivMore,
  ButtonSubmit,
  DivSingleUse,
  DivSingleUseHeader,
  DivSwitchSingleUse,
} from './styles';

import ResponsiveHeader from '../../../components/HeaderMobile';

function CoupomRegister({ match }) {
  const { id } = match.params;
  const [code, setCode] = useState('');
  const [radioSelected, setRadioSelected] = useState('P');
  const [value, setValue] = useState();
  const [minValue, setMinValue] = useState();
  const [showDivMore, setShowDivMore] = useState(false);
  const [singleUseActive, setSingleUseActive] = useState(true);

  const history = useHistory();

  useEffect(() => {
    async function load() {
      if (id > 0) {
        await api.get(`/coupons/${id}`).then(response => {
          if (response.status === 200) {
            setCode(response.data.code);
            setRadioSelected(response.data.type);
            setSingleUseActive(response.data.single_use);

            if (response.data.type === 'I') {
              setValue(response.data.value);
            } else {
              setValue(Math.trunc(response.data.value));
            }

            setMinValue(response.data.min_value);
          }
        });
      }
    }

    load();
  }, [id]);

  async function handleSubmit() {
    if (!code) {
      toast.error('Digite o código do cupom');

      return 0;
    }

    if (!value) {
      toast.error('Digite um valor para o cupom');
      return 0;
    }

    const data = {
      code,
      type: radioSelected,
      value,
      min_value: minValue || 0,
      active: true,
      single_use: singleUseActive,
    };

    if (id > 0) {
      await api
        .put(`/coupons/${id}`, data)
        .then(response => {
          if (response.status === 200) {
            toast.success('Cupom alterado');

            history.push('/coupons');
          }
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao alterar cupom');
        });
    } else {
      await api
        .post('/coupons', data)
        .then(response => {
          if (response.status === 200) {
            toast.success('Cupom cadastrado');

            history.push('/coupons');
          }
        })
        .catch(err => {
          toast.error(err.response.data);
        });
    }

    return 0;
  }

  return (
    <Container>
      <ResponsiveHeader
        title={id > 0 ? 'Editar cupom' : 'Adicionar cupom'}
        url="/coupons"
        tab={1}
      />
      <DivBody>
        <strong>Código do cupom</strong>
        <span>
          Digite o código que seu cliente terá que adicionar para obter o
          desconto, exemplo: “CUPOM1234”
        </span>

        <input
          type="text"
          placeholder="Ex: CUPOM1234"
          value={code}
          onChange={e => setCode(e.target.value)}
        />

        <strong>Desconto</strong>
        <span>Defina qual o tipo e o valor do desconto do cupom</span>

        <DivRadio>
          <Radio>
            <div
              className={radioSelected === 'P' && 'selected'}
              onClick={() => setRadioSelected('P')}
            >
              <div />
            </div>
            <span>Percentual</span>
          </Radio>

          <Radio>
            <div
              className={radioSelected === 'I' && 'selected'}
              onClick={() => setRadioSelected('I')}
            >
              <div />
            </div>
            <span>Inteiro</span>
          </Radio>
        </DivRadio>

        <DivValue>
          <div>{radioSelected === 'I' ? 'R$' : '%'}</div>
          <input
            type="number"
            placeholder="0"
            value={value}
            onChange={e => setValue(e.target.value)}
          />
        </DivValue>

        <DivMore show={showDivMore}>
          <strong>Valor mínimo em compras</strong>
          <span>
            Defina o valor mínimo que seu cliente precisa comprar para poder
            utilizar o cupom. Deixe em branco para habilitar para qualquer
            valor.
          </span>

          <DivValue>
            <div>R$</div>
            <input
              type="number"
              placeholder="0"
              value={minValue}
              onChange={e => setMinValue(e.target.value)}
            />
          </DivValue>
          <DivSingleUse>
            <DivSingleUseHeader>
              <strong>Cupom único por comprador</strong>
              <span>
                Ative essa opção se você quer que esse cupom seja utilizado
                somente uma vez por comprador
              </span>
            </DivSingleUseHeader>

            <DivSwitchSingleUse>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={singleUseActive}
                  onChange={e => setSingleUseActive(e.target.checked)}
                />
                <span className="slider round" />
              </label>
            </DivSwitchSingleUse>
          </DivSingleUse>
        </DivMore>

        <button type="button" onClick={() => setShowDivMore(!showDivMore)}>
          {(showDivMore && 'Mostrar menos') || 'Mostrar mais'}
        </button>

        <ButtonSubmit type="button" onClick={handleSubmit}>
          {id > 0 ? 'Salvar alterações' : 'Adicionar cupom'}
        </ButtonSubmit>
      </DivBody>
    </Container>
  );
}

export default CoupomRegister;

export function ListLinkPreview() {
  return {
    type: '@linkPrev/LIST_LINK_PREVIEW',
  };
}

export function ListLinkPreviewSuccess(links) {
  return {
    type: '@linkPrev/LIST_LINK_PREVIEW_SUCCESS',
    payload: { links },
  };
}

export function ListCountRequest() {
  return {
    type: '@linkPrev/LIST_COUNT_REQUEST',
  };
}

export function ListCountSuccess(count) {
  return {
    type: '@linkPrev/LIST_COUNT_SUCCESS',
    payload: { count },
  };
}

export function ListProductsPaginationRequest(
  products,
  count,
  page,
  stringSearch,
  tagId
) {
  return {
    type: '@linkPrev/LIST_PRODUCTS_PAGINATION_REQUEST',
    payload: { products, count, page, stringSearch, tagId },
  };
}

export function ListProductsPaginationSuccess(products) {
  return {
    type: '@linkPrev/LIST_PRODUCTS_PAGINATION_SUCCESS',
    payload: { products },
  };
}

export function ListTagsRequest() {
  return {
    type: '@linkPrev/LIST_TAGS_REQUEST',
  };
}

export function ListTagsSuccess(tags) {
  return {
    type: '@linkPrev/LIST_TAGS_SUCCESS',
    payload: { tags },
  };
}

export function InsertOrUpdateTagRequest(tagId, tagName, subcategories) {
  return {
    type: '@linkPrev/INSERT_OR_UPDATE_TAG_REQUEST',
    payload: { tagId, tagName, subcategories },
  };
}

export function InsertTagSuccess(tag) {
  return {
    type: '@linkPrev/INSERT_TAG_SUCCESS',
    payload: { tag },
  };
}

export function UpdateTagSuccess(data) {
  return {
    type: '@linkPrev/UPDATE_TAG_SUCCESS',
    payload: { data },
  };
}

export function removeTagRequest(tagId) {
  return {
    type: '@linkPrev/REMOVE_TAG_REQUEST',
    payload: { tagId },
  };
}

export function removeTagSuccess(tagId) {
  return {
    type: '@linkPrev/REMOVE_TAG_SUCCESS',
    payload: { tagId },
  };
}

export function LoadingCreateOrUpdateTagSuccess(loading) {
  return {
    type: '@linkPrev/LOADING_CREATE_OR_UPDATE_TAG_SUCCESS',
    payload: { loading },
  };
}

export function AddSubCategoriesOnCategoriesSuccess(subcategories) {
  return {
    type: '@linkPrev/ADD_SUBCATEGORIES_ON_CATEGORIES_SUCCESS',
    payload: { subcategories },
  };
}

export function FilterLinksByTagId(tagId) {
  return {
    type: '@linkPrev/FILTER_LINKS_BY_TAG',
    payload: { tagId },
  };
}

export function SaveLinkSuccess(link, images) {
  return {
    type: '@linkPrev/SAVE_LINK_SUCCESS',
    payload: { link, images },
  };
}

export function InsertOrUpdateLinkRequest(
  link,
  productId,
  images,
  arrayVariations
) {
  return {
    type: '@linkPrev/INSERT_UPDATE_LINK_REQUEST',
    payload: { link, productId, images, arrayVariations },
  };
}

export function InsertOrUpdateLinkFailure(exceededProducts) {
  return {
    type: '@linkPrev/INSERT_UPDATE_LINK_FAILURE',
    payload: { exceededProducts },
  };
}

export function UpdateLinkRequest(link, productId) {
  return {
    type: '@linkPrev/UPDATE_LINK_REQUEST',
    payload: { link, productId },
  };
}

export function UpdateActiveLinkRequest(linkId, isActive) {
  return {
    type: '@linkPrev/UPDATE_ACTIVE_LINK_REQUEST',
    payload: { linkId, isActive },
  };
}

export function UpdateActiveLinkSuccess(linkId, isActive) {
  return {
    type: '@linkPrev/UPDATE_ACTIVE_LINK_SUCCESS',
    payload: { linkId, isActive },
  };
}

export function UpdateHighlightedLinkRequest(linkId, isHighlighted) {
  return {
    type: '@linkPrev/UPDATE_HIGHLIGHTED_LINK_REQUEST',
    payload: { linkId, isHighlighted },
  };
}

export function UpdateHighlightedLinkSuccess(linkId, isHighlighted) {
  return {
    type: '@linkPrev/UPDATE_HIGHLIGHTED_LINK_SUCCESS',
    payload: { linkId, isHighlighted },
  };
}

export function UpdateOrderRequest(links) {
  return {
    type: '@linkPrev/UPDATE_ORDER_REQUEST',
    payload: { links },
  };
}

export function UpdateOrderSuccess(links) {
  return {
    type: '@linkPrev/UPDATE_ORDER_SEUCCESS',
    payload: { links },
  };
}

export function UpdateLinkSuccess(link, images) {
  return {
    type: '@linkPrev/UPDATE_LINK_SUCCESS',
    payload: { link, images },
  };
}

// export function saveImageLinkRequest(linkImage, linkId) {
//   return {
//     type: '@linkPrev/SAVE_IMAGE_LINK_REQUEST',
//     payload: { linkImage, linkId },
//   };
// }

export function SaveImageLink(images, linkId) {
  return {
    type: '@linkPrev/SAVE_IMAGE_LINK_SUCCESS',
    payload: { images, linkId },
  };
}

export function removeImageLinkRequest(linkId) {
  return {
    type: '@linkPrev/REMOVE_IMAGE_LINK_REQUEST',
    payload: { linkId },
  };
}

export function removeImageLinkSuccess(linkId) {
  return {
    type: '@linkPrev/REMOVE_IMAGE_LINK_SUCCESS',
    payload: { linkId },
  };
}

export function updateTypeLinkRequest(linkId, typeId) {
  return {
    type: '@linkPrev/UPDATE_TYPE_LINK_REQUEST',
    payload: { linkId, typeId },
  };
}

export function updateTypeLinkSuccess(link) {
  return {
    type: '@linkPrev/UPDATE_TYPE_LINK_SUCCESS',
    payload: { link },
  };
}

export function listPromotionRequest() {
  return {
    type: '@linkPrev/LIST_PROMOTION_REQUEST',
  };
}

export function listPromotionSuccess(promotions) {
  return {
    type: '@linkPrev/LIST_PROMOTION_SUCCESS',
    payload: { promotions },
  };
}

export function insertPromotionRequest(data) {
  return {
    type: '@linkPrev/INSERT_PROMOTION_REQUEST',
    payload: { data },
  };
}

export function insertPromotionSuccess(promotion) {
  return {
    type: '@linkPrev/INSERT_PROMOTION_SUCCESS',
    payload: { promotion },
  };
}

export function updatePromotionRequest(data) {
  return {
    type: '@linkPrev/UPDATE_PROMOTION_REQUEST',
    payload: { data },
  };
}

export function updatePromotionSuccess(promotion) {
  return {
    type: '@linkPrev/UPDATE_PROMOTION_SUCCESS',
    payload: { promotion },
  };
}

export function removePromotionRequest(promotionId) {
  return {
    type: '@linkPrev/REMOVE_PROMOTION_REQUEST',
    payload: { promotionId },
  };
}

export function removePromotionSuccess(promotionId) {
  return {
    type: '@linkPrev/REMOVE_PROMOTION_SUCCESS',
    payload: { promotionId },
  };
}

export function listPaymentMethodsRequest() {
  return {
    type: '@linkPrev/LIST_PAYMENT_METHODS_REQUEST',
    // payload: { data },
  };
}

export function listPaymentMethodsSuccess(payment_methods) {
  return {
    type: '@linkPrev/LIST_PAYMENT_METHODS_SUCCESS',
    payload: { payment_methods },
  };
}

export function updatePaymentMethodsRequest(data) {
  return {
    type: '@linkPrev/UPDATE_PAYMENT_METHODS_REQUEST',
    payload: { data },
  };
}

export function updatePaymentMethodsSuccess(payment_methods) {
  return {
    type: '@linkPrev/UPDATE_PAYMENT_METHODS_SUCCESS',
    payload: { payment_methods },
  };
}

export function updateStockRequest(productId, amount) {
  return {
    type: '@linkPrev/UPDATE_STOCK_REQUEST',
    payload: { productId, amount },
  };
}

export function updateStockSuccess(productId, amount) {
  return {
    type: '@linkPrev/UPDATE_STOCK_SUCCESS',
    payload: { productId, amount },
  };
}

export function updatePageSuccess(page) {
  return {
    type: '@linkPrev/UPDATE_PAGE_SUCCESS',
    payload: { page },
  };
}

export function updateTotalCountSuccess(totalCount) {
  return {
    type: '@linkPrev/UPDATE_TOTAL_COUNT_SUCCESS',
    payload: { totalCount },
  };
}

export function updateCountSuccess(count) {
  return {
    type: '@linkPrev/UPDATE_COUNT_SUCCESS',
    payload: { count },
  };
}

export function updateHasMoreSuccess(hasMore) {
  return {
    type: '@linkPrev/UPDATE_HAS_MORE_SUCCESS',
    payload: { hasMore },
  };
}

export function updateInputSearchSuccess(search) {
  return {
    type: '@linkPrev/UPDATE_INPUT_SEARCH_SUCCESS',
    payload: { search },
  };
}

export function clearStateInfiniteScrollSuccess() {
  return {
    type: '@linkPrev/CLEAR_STATE_INFINITE_SCROLL_SUCCESS',
  };
}

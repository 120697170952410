import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 8px;
  background: #fafafa;
  padding: 20px;
  margin-top: 32px;

  > strong {
    color: #838383;
    font-size: 14px;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: normal;

    & + div {
      border-top: 1px solid #efefef;
      margin-top: 12px;
      padding-top: 12px;
    }

    > span {
      color: #838383;
      font-size: 12px;
      font-weight: 400;
      max-width: 180px;
    }
  }

  > button {
    color: #0d85ff;
    font-size: 13px;
    font-weight: 600;
    border: none;
    background: none;
    margin-top: 16px;
    width: max-content;
    align-self: center;
  }
`;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import ResponsiveHeader from '../../../../../../components/HeaderMobile';

import { ChangeBankAccountComponent } from './ChangeBankComponent';

export function ChangeBankAccount() {
  return (
    <>
      <ResponsiveHeader title="Dados da conta bancária" url="/paymentmethods" />
      <ChangeBankAccountComponent />
    </>
  );
}

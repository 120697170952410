/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import LoaderComponent from '~/components/Loader';
import ProPlanModal from '~/components/Popups/ProPlan';
import AddAddress from '../../../../components/AddAddress';
import api from '../../../../services/api';
import { updateFreightRequest } from '../../../../store/modules/freight/actions';
import Correios from './ConfigurationCorreios';
import CustomFreight from './ConfigurationCustomFreight';
import FlatRate from './ConfigurationFlatRate';
// import Sedex from './ConfigurationSedex';
import FreeFreight from './ConfigurationFreeFreight';
import MelhorEnvio from './ConfigurationMelhorEnvio';
import {
  Body,
  Card,
  Container,
  DivCards,
  DivCep,
  DivMethod,
  DivText,
  LabelPro,
  Radio,
  SpanStatus,
  DivInitialLoader,
} from './styles';
import ConfigurationFreightPerKm from './ConfigurationFreightPerKm';
import Plans from '../Plans';

function Freight(props) {
  const [pro, setPro] = useState();

  const selectedItemParam = props.selectedItem;

  // const [active, setActive] = useState();
  const [radioSelected, setRadioSelected] = useState(1);
  const [activeFlatRate, setActiveFlatRate] = useState(false);
  const [activePac, setActivePac] = useState(false);
  const [activeSedex, setActiveSedex] = useState(false);
  const [activeMiniEnvios, setActiveMiniEnvios] = useState(false);
  const [activeFree, setActiveFree] = useState(false);
  const [activeMelhorEnvio, setActiveMelhorEnvio] = useState(false);
  const [activeFreightPerKm, setActiveFreightPerKm] = useState(false);
  const [activeCustomFreight, setActiveCustomFreight] = useState(false);
  const [hasCepPacSedex, setHasCepPacSedex] = useState(false);
  const [melhorEnvio, setMelhorEnvio] = useState();
  const [melhorEnvioList, setMelhorEnvioList] = useState([]);
  const [showAddAddress, setShowAddAddress] = useState(false);
  const [customFreights, setCustomFreights] = useState([]);
  const [existsRegion, setExistsRegion] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activeTokenME, setActiveTokenME] = useState(false);
  const [showProPlanPopUp, setShowProPlanPopUp] = useState(false);
  const [showCardPro, setShowCardPro] = useState(false);
  const [hasGoogleAddressee, setHasGoogleAddressee] = useState(false);
  const [hasGoogleFullAddress, setHasGoogleFullAddress] = useState(false);

  const [hasRefreshToken, setHasRefreshToken] = useState(false);

  // const [showMoreCards, setShowMoreCards] = useState(false);

  const [page, setPage] = useState(0);

  // const freight = useSelector(state => state.freight.freight);

  const {
    id,
    cep,
    address,
    number,
    city,
    district,
    uf,
    // complement,
  } = useSelector(state => state.user.profile);

  const dispatch = useDispatch();

  async function saveData(data) {
    await api.post('/updatefreight', data);
    toast.success('Informação salva');
  }

  async function loadData() {
    const response = await api.get('/configfreight');

    await api
      .post('/melhorenvio/checkrefreshtoken')
      .then(res => {
        if (res.data === 'Has refresh token') setHasRefreshToken(true);
        else setHasRefreshToken(false);
      })
      .catch(() => setHasRefreshToken(false));

    await api
      .post('/melhorenvio/checktokenisvalid')
      .then(res => {
        if (res.data === 'Success') setActiveTokenME(true);
        else setActiveTokenME(false);
      })
      .catch(err => setActiveTokenME(false));

    if (response && response.data) {
      // setActive(response.data.enabled);
      setActiveFlatRate(response.data.flatrate);
      setActivePac(response.data.pac);
      setActiveSedex(response.data.sedex);
      setActiveMiniEnvios(response.data.mini_envios);
      setActiveFree(response.data.free);
      setRadioSelected(response.data.receivement);
      setActiveCustomFreight(response.data.custom_freight);
      setActiveFreightPerKm(response.data.has_freight_per_km);

      if (response.data.cep_origin_pac_sedex) {
        setHasCepPacSedex(true);
      } else {
        setHasCepPacSedex(false);
      }
    }

    const custom_freights = await api.get('/customfreight');

    if (
      custom_freights &&
      custom_freights.data &&
      custom_freights.data.length > 0
    ) {
      setCustomFreights(custom_freights.data);
    } else {
      setActiveCustomFreight(false);
      dispatch(updateFreightRequest('CUSTOM_FREIGHT', false, false, true));
    }

    const getPro = await api.get(`/proplan`);
    setPro(getPro && getPro.data);

    // await api.get('/existsregion').then(res => {
    //   setExistsRegion(res && res.data);
    // });

    await api.get('/melhorenvio').then(async me => {
      setMelhorEnvio(me.data);
      setActiveMelhorEnvio(me.data && me.data.active);

      if (me.data && getPro && getPro.data === 0) {
        await api.put('/melhorenvio', { active: false });
      }
    });

    await api
      .get('/melhorenviolist')
      .then(services => setMelhorEnvioList(services.data));

    await api
      .post('/google-addresses/list', { user_id: id })
      .then(res => {
        if (res.data && res.data.id) {
          setHasGoogleAddressee(true);
          setHasGoogleFullAddress(!!res.data.full_address);
        } else setHasGoogleAddressee(false);
      })
      .catch(() => setHasGoogleAddressee(false));
    setLoading(false);

    return 0;
  }

  useEffect(() => {
    async function load() {
      await loadData();

      // dispatch(loadFreightRequest());
    }
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedItemParam) setPage(selectedItemParam);
  }, [selectedItemParam]);

  async function updateOrderConfigurations() {
    await api.put('/orderconfigurations', {
      show_delivery_address: true,
    });
  }

  async function changeFreightStatus(value, type) {
    await api
      .post('/freight/status', {
        value,
        type,
      })
      .then(response => {
        setActiveFree(response.data.free);
        setActiveFlatRate(response.data.flatrate);
        setActiveCustomFreight(response.data.custom_freight);
        setActivePac(response.data.pac);
        setActiveSedex(response.data.sedex);
        setActiveMiniEnvios(response.data.mini_envios);
        setActiveFreightPerKm(response.data.has_freight_per_km);
        setActiveMelhorEnvio(response.data.melhor_envio_active);
      });
  }

  function onChangeActiveFlatRate(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'FLATRATE');
  }

  function onChangeActiveCustomFreight(value) {
    if (value) {
      if (customFreights && customFreights.length === 0) {
        toast.error('Cadastre pelo menos um local antes de habilitar o frete');
        return 0;
      }

      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'CUSTOM_FREIGHT');
  }

  function onChangeActiveCorreios(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'PAC_SEDEX_MINI_ENVIOS');
  }

  function onChangeActiveFree(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'FREE');
  }

  async function onChangeActiveMelhorEnvio(value) {
    if (value) {
      updateOrderConfigurations();
    }

    changeFreightStatus(value, 'MELHORENVIO');
  }

  async function onChangeFreightPerKm(value) {
    changeFreightStatus(value, 'FREIGHT_PER_KM');
  }

  async function createInitialRecordsFromFreightPerKm() {
    await api.post('/freight-per-km/initial-records', {
      user_id: id,
    });
  }

  function updateRadio(numberRadio) {
    setRadioSelected(numberRadio);

    const data = {
      receivement: numberRadio,
    };

    saveData(data);
  }

  function handleClickBackButton() {
    setPage(0);
    loadData();
  }

  function handleSetShowAddAddress(value, onSubmit) {
    setShowAddAddress(value);
  }

  function ProPlanPopUp(pro_modal, confirm) {
    setShowProPlanPopUp(pro_modal);

    if (confirm) {
      setShowCardPro(true);
    }
  }

  const activeServicesMEFind =
    melhorEnvioList.find(mel => mel.active) !== undefined;

  const checkServicesME =
    hasCepPacSedex &&
    melhorEnvio &&
    melhorEnvio.token !== null &&
    melhorEnvio.refresh_token !== null &&
    melhorEnvioList &&
    activeServicesMEFind;

  const checkTokenAndRefreshTokenME =
    activeMelhorEnvio &&
    melhorEnvio &&
    melhorEnvio.token !== null &&
    melhorEnvio.refresh_token !== null;

  const SpanStatusComponent = () => {
    if (!activeMelhorEnvio)
      return <SpanStatus color="#AFAFAF">Desativado</SpanStatus>;
    if (checkTokenAndRefreshTokenME && checkServicesME && activeTokenME)
      return <SpanStatus color="#2B8FF5">Ativo</SpanStatus>;
    if (!checkTokenAndRefreshTokenME && activeTokenME)
      return (
        <SpanStatus color="#FFB525">
          O Melhor Envio mudou, integre novamente
        </SpanStatus>
      );
    if (!checkServicesME && activeTokenME)
      return (
        <SpanStatus color="#FFB525">Nenhuma transportadora ativa</SpanStatus>
      );
    if (!activeTokenME || !checkTokenAndRefreshTokenME)
      return (
        <SpanStatus color="#FFB525">
          Token inválido, integre novamente
        </SpanStatus>
      );
    return null;
  };

  if (loading)
    return (
      <DivInitialLoader>
        <LoaderComponent size={32} white={false} />
      </DivInitialLoader>
    );
  return (
    <>
      {(showCardPro && <Plans />) || (
        <Container>
          <ProPlanModal
            showModal={showProPlanPopUp}
            Modal={ProPlanPopUp}
            is_desktop
          />
          {page === 0 && (
            <Body>
              <DivText>
                <strong>Tipos de entrega</strong>
                <span>Selecione os tipos de entrega que você irá utilizar</span>
              </DivText>

              <DivCards>
                <Card>
                  <div className="left" onClick={() => setPage(6)}>
                    <span>Frete personalizado</span>
                    {(activeCustomFreight && (
                      <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                    )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>}
                  </div>

                  <div className="right">
                    <button type="button" onClick={() => setPage(6)}>
                      Editar
                    </button>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={activeCustomFreight}
                        onChange={e =>
                          onChangeActiveCustomFreight(e.target.checked)
                        }
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </Card>

                <Card
                  switchColorActive={
                    ((activePac || activeSedex || activeMiniEnvios) &&
                      !hasCepPacSedex) ||
                    ((activePac || activeSedex || activeMiniEnvios) &&
                      activeMelhorEnvio)
                      ? '#FFB525'
                      : '#2B8FF5'
                  }
                >
                  <div className="left" onClick={() => setPage(2)}>
                    <span>Correios</span>
                    {(activePac || activeSedex || activeMiniEnvios) &&
                      hasCepPacSedex && (
                        <>
                          {activeMelhorEnvio ? (
                            <SpanStatus color="#FFB525">
                              Ativo no Melhor Envio
                            </SpanStatus>
                          ) : (
                            <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                          )}
                        </>
                      )}

                    {(activePac || activeSedex || activeMiniEnvios) &&
                      !hasCepPacSedex && (
                        <SpanStatus color="#FFB525">Não configurado</SpanStatus>
                      )}

                    {!activePac && !activeSedex && !activeMiniEnvios && (
                      <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
                    )}
                  </div>

                  <div className="right">
                    <button type="button" onClick={() => setPage(2)}>
                      Editar
                    </button>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={activePac || activeSedex || activeMiniEnvios}
                        onChange={e => onChangeActiveCorreios(e.target.checked)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </Card>

                <Card
                  switchColorActive={
                    !hasGoogleAddressee && activeFreightPerKm
                      ? '#FFB525'
                      : '#2B8FF5'
                  }
                >
                  <div
                    className="left"
                    onClick={
                      pro >= 1
                        ? () => {
                            createInitialRecordsFromFreightPerKm();
                            setPage(7);
                          }
                        : () => setShowProPlanPopUp(true)
                    }
                  >
                    <div>
                      <span>Frete por KM de distância</span>
                      {pro === 0 && (
                        <div className="label-pro">
                          <span>PRO</span>
                        </div>
                      )}
                    </div>
                    <SpanStatus
                      color={
                        !hasGoogleAddressee && activeFreightPerKm
                          ? '#FFB525'
                          : '#AFAFAF'
                      }
                    >
                      {!hasGoogleAddressee && activeFreightPerKm
                        ? 'Não configurado'
                        : activeFreightPerKm
                        ? 'Ativado'
                        : 'Desativado'}
                    </SpanStatus>
                  </div>

                  <div className="right">
                    <button
                      type="button"
                      onClick={
                        pro >= 1
                          ? () => {
                              createInitialRecordsFromFreightPerKm();
                              onChangeFreightPerKm(true);
                              setPage(7);
                            }
                          : () => setShowProPlanPopUp(true)
                      }
                    >
                      Editar
                    </button>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={activeFreightPerKm}
                        onChange={
                          pro >= 1
                            ? e => onChangeFreightPerKm(e.target.checked)
                            : () => setShowProPlanPopUp(true)
                        }
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </Card>

                <Card
                  switchColorActive={
                    checkTokenAndRefreshTokenME &&
                    checkServicesME &&
                    activeTokenME
                      ? '#2B8FF5'
                      : '#FFB525'
                  }
                >
                  <div
                    className="left"
                    onClick={pro >= 1 ? () => setPage(5) : () => {}}
                  >
                    <span>Melhor Envio</span>
                    <SpanStatusComponent />

                    {pro === 0 && (
                      <LabelPro>
                        <span>PRO</span>
                      </LabelPro>
                    )}
                  </div>

                  <div className="right">
                    <button
                      type="button"
                      onClick={pro >= 1 ? () => setPage(5) : () => {}}
                    >
                      {melhorEnvio && melhorEnvio.token ? 'Editar' : 'Integrar'}
                    </button>
                    {melhorEnvio && melhorEnvio.token ? (
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={activeMelhorEnvio}
                          onChange={
                            pro >= 1
                              ? e => onChangeActiveMelhorEnvio(e.target.checked)
                              : () => {}
                          }
                        />
                        <span className="slider round" />
                      </label>
                    ) : null}
                  </div>
                </Card>

                {existsRegion && existsRegion.length > 0 && (
                  <Card>
                    <div className="left" onClick={() => setPage(1)}>
                      <span>Taxa fixa</span>
                      {(activeFlatRate && (
                        <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                      )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>}
                    </div>

                    <div className="right">
                      <button type="button" onClick={() => setPage(1)}>
                        Editar
                      </button>
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={activeFlatRate}
                          onChange={e =>
                            onChangeActiveFlatRate(e.target.checked)
                          }
                        />
                        <span className="slider round" />
                      </label>
                    </div>
                  </Card>
                )}

                {/* <Card
                  switchColorActive={
                    (activeSedex && !hasCepPacSedex) ||
                    (activeSedex && activeMelhorEnvio)
                      ? '#FFB525'
                      : '#2B8FF5'
                  }
                >
                  <div className="left" onClick={() => setPage(3)}>
                    <span>Sedex - Correios</span>
                    {activeSedex && hasCepPacSedex && (
                      <>
                        {activeMelhorEnvio ? (
                          <SpanStatus color="#FFB525">
                            Ativo no Melhor Envio
                          </SpanStatus>
                        ) : (
                          <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                        )}
                      </>
                    )}

                    {activeSedex && !hasCepPacSedex && (
                      <SpanStatus color="#FFB525">Não configurado</SpanStatus>
                    )}

                    {!activeSedex && (
                      <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
                    )}
                  </div>

                  <div className="right">
                    <button type="button" onClick={() => setPage(3)}>
                      Editar
                    </button>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={activeSedex}
                        onChange={e => onChangeActiveSedex(e.target.checked)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </Card> */}

                <Card>
                  <div className="left" onClick={() => setPage(4)}>
                    <span>Frete grátis</span>
                    {(activeFree && (
                      <SpanStatus color="#2B8FF5">Ativo</SpanStatus>
                    )) || <SpanStatus color="#AFAFAF">Desativado</SpanStatus>}
                  </div>

                  <div className="right">
                    <button type="button" onClick={() => setPage(4)}>
                      Editar
                    </button>
                    <label className="switch">
                      <input
                        type="checkbox"
                        checked={activeFree}
                        onChange={e => onChangeActiveFree(e.target.checked)}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </Card>

                {/* {showMoreCards && (
                  <>
                    <Card background="#fafafa">
                      <div className="left">
                        <div>
                          <span>Melhor envio</span>
                          <LabelPro>
                            <span>PRO</span>
                          </LabelPro>
                          <LabelSoon>
                            <span>Em breve</span>
                          </LabelSoon>
                        </div>

                        <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
                      </div>

                      <div className="right">
                        <SpanStatus
                          color="#AFAFAF"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          Integrar
                        </SpanStatus>
                        <label className="switch">
                          <input type="checkbox" disabled />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Card>

                    <Card background="#fafafa">
                      <div className="left">
                        <div>
                          <span>Frenet</span>
                          <LabelPro>
                            <span>PRO</span>
                          </LabelPro>
                          <LabelSoon>
                            <span>Em breve</span>
                          </LabelSoon>
                        </div>

                        <SpanStatus color="#AFAFAF">Desativado</SpanStatus>
                      </div>

                      <div className="right">
                        <SpanStatus
                          color="#AFAFAF"
                          fontSize="13px"
                          fontWeight="500"
                        >
                          Integrar
                        </SpanStatus>
                        <label className="switch">
                          <input type="checkbox" disabled />
                          <span className="slider round" />
                        </label>
                      </div>
                    </Card>
                  </>
                )} */}

                {/* {(showMoreCards && (
                  <button type="button" onClick={() => setShowMoreCards(false)}>
                    Menos opções
                  </button>
                )) || (
                  <button type="button" onClick={() => setShowMoreCards(true)}>
                    Mais opções
                  </button>
                )} */}
              </DivCards>

              <DivMethod>
                <span>Como seus clientes podem receber seus produtos?</span>

                <Radio>
                  <div
                    className={radioSelected === 0 && 'selected'}
                    onClick={() => updateRadio(0)}
                  >
                    <div />
                  </div>
                  <span>Somente entrega</span>
                </Radio>

                <Radio>
                  <div
                    className={radioSelected === 1 && 'selected'}
                    onClick={() => updateRadio(1)}
                  >
                    <div />
                  </div>
                  <span>Entrega e retirada no local</span>
                </Radio>

                <Radio>
                  <div
                    className={radioSelected === 2 && 'selected'}
                    onClick={() => updateRadio(2)}
                  >
                    <div />
                  </div>
                  <span>Somente retirada no local</span>
                </Radio>
              </DivMethod>

              <DivCep>
                <strong>Endereço da loja</strong>
                <span>Endereço de origem das entregas e/ou retirada</span>

                <div>
                  <span>{cep}</span>
                  {address && (
                    <span>
                      {address}, {number}
                    </span>
                  )}
                  <span>
                    {district && `${district},`} {city} - {uf}
                  </span>
                </div>

                <button type="button" onClick={() => setShowAddAddress(true)}>
                  Alterar endereço
                </button>
              </DivCep>
            </Body>
          )}

          {page === 1 && (
            <FlatRate handleClickBackButton={handleClickBackButton} />
          )}
          {page === 2 && (
            <Correios handleClickBackButton={handleClickBackButton} />
          )}
          {/* {page === 3 && <Sedex handleClickBackButton={handleClickBackButton} />} */}
          {page === 4 && (
            <FreeFreight handleClickBackButton={handleClickBackButton} />
          )}
          {page === 5 && (
            <MelhorEnvio handleClickBackButton={handleClickBackButton} />
          )}
          {page === 6 && (
            <CustomFreight handleClickBackButton={handleClickBackButton} />
          )}
          {page === 7 && (
            <ConfigurationFreightPerKm
              handleClickBackButton={handleClickBackButton}
            />
          )}

          {showAddAddress && (
            <AddAddress
              handleSetShowAddAddress={handleSetShowAddAddress}
              hasFunction={false}
              isModal
            />
          )}
        </Container>
      )}
    </>
  );
}

export default Freight;

import styled from 'styled-components';
import { device } from '../../config/device';

export const ContainerGeral = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 550;
  background: rgba(238, 239, 239, 0.7);
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`;

export const Div = styled.div`
  width: 440px;
  /* height: 310px; */
  background: #fff;
  border-radius: 16px;
  z-index: 550;
  position: fixed;
  display: ${props => (props.showModal ? 'flex' : 'none')};
  flex-direction: column;
  box-shadow: 15px 31px 59px rgba(0, 0, 0, 0.1);
  top: calc(50vh - (283px / 2));
  left: calc(50vw - (440px / 2));

  @media ${device.mobile} {
    width: 90%;
    left: auto;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  height: 60px;
  border-bottom: 0.5px solid #d8dee5;
  margin-bottom: 25px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;

    span {
      text-align: center;
      color: #262626;
      font-size: 16px;
      font-weight: 500;
    }

    img {
      cursor: pointer;
    }
  }

  .div-space {
    width: 10%;
    height: 100%;
  }

  @media ${device.mobile} {
    > div {
      width: 70%;
    }

    .div-space {
      width: 15%;
    }
  }
`;

export const DivBody = styled.div`
  padding: 0 24px 24px 24px;
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    color: #030303;
    text-align: center;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
  }

  button {
    width: 100%;
    height: 45px;
    box-shadow: 6px 11px 13px rgba(10, 12, 13, 0.1);
    border-radius: 10px;
    color: #ffffff;
    border: none;
    background: ${props => (props.block ? '#CACACA' : '#2b8ff5')};
  }
`;

export const DivInput = styled.div`
  display: flex;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 24px;

  > div {
    display: flex;
    width: 42px;
    height: 42px;
    justify-content: center;
    align-items: center;
    border: ${props =>
      props.inFocus ? '0.5px solid #2b8ff5' : '0.5px solid #d8dee5'};
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    border-right: none;

    > span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #262626;
    }
  }

  > input {
    display: flex;
    flex: 1;
    width: 100%;
    height: 42px;
    background: #ffffff;
    border: ${props =>
      props.inFocus ? '0.5px solid #2b8ff5' : '0.5px solid #d8dee5'};

    font-size: 13px;
    box-sizing: border-box;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-left: none;

    &::placeholder {
      font-size: 13px;
      color: #c6cad2;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.button`
  height: 50px;
  background: #2b8ff5;
  border: 1px solid #2b8ff5;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
    color: #fff;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: 6px;
  }
  @media (max-width: 414px) {
    p {
      color: #fff;
      text-decoration: none;
      flex: 1;
      font-size: 15px;
      font-weight: 600;
    }
  }
`;

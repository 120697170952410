import React from 'react';

// import { Svg } from './styles';

function DesktopIcon({ color }) {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 5.22562L3.5 2.34229"
        stroke={color}
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 8.89187V4.44743C10.9998 4.25258 10.9484 4.06121 10.8509 3.89252C10.7533 3.72383 10.6132 3.58374 10.4444 3.48632L6.55556 1.26409C6.38665 1.16657 6.19504 1.11523 6 1.11523C5.80496 1.11523 5.61335 1.16657 5.44444 1.26409L1.55556 3.48632C1.38681 3.58374 1.24666 3.72383 1.14915 3.89252C1.05163 4.06121 1.0002 4.25258 1 4.44743V8.89187C1.0002 9.08672 1.05163 9.27809 1.14915 9.44678C1.24666 9.61548 1.38681 9.75556 1.55556 9.85298L5.44444 12.0752C5.61335 12.1727 5.80496 12.2241 6 12.2241C6.19504 12.2241 6.38665 12.1727 6.55556 12.0752L10.4444 9.85298C10.6132 9.75556 10.7533 9.61548 10.8509 9.44678C10.9484 9.27809 10.9998 9.08672 11 8.89187Z"
        stroke={color}
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.15002 3.86963L6.00002 6.67518L10.85 3.86963"
        stroke={color}
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 12.2699V6.66992"
        stroke={color}
        strokeWidth="0.833333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DesktopIcon;

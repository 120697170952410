import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select';
import { darken } from 'polished';

import 'react-datepicker/dist/react-datepicker.css';

import { FaRegTrashAlt } from 'react-icons/fa';

export const Div = styled.div`
  background: #fff;
  display: flex;
  width: 100%;
  align-items: center;
  border: 0.5px solid #efefef;
  border-radius: 12px;
  margin-right: 8px;
  margin-bottom: 8px;
  cursor: pointer;

  &:hover {
    filter: opacity(0.65);
    transition: all 300ms;
  }

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  ${props =>
    !props.active &&
    css`
      background: #fafafa;
      opacity: 0.5;
    `};

  input {
    /* width: 320px; */
    background: #f5f5f7;
  }

  #general-div {
    display: flex;
  }

  #div-select {
    display: flex;
  }

  ${props =>
    props.isDragging &&
    css`
      background: transparent;
      box-shadow: none;
      cursor: grabbing;

      div {
        opacity: 0;
      }
    `}
`;

export const DivDrag = styled.div`
  width: 5%;
  cursor: grab;
  display: flex;
  justify-content: center;

  img {
    height: 20px;
  }
`;

export const DivAll = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 24px 8px 8px;
`;

export const Image = styled.img`
  width: 50px;
  height: 47px;
  border-radius: 6px;
  margin-right: 5px;
  object-fit: cover;
  border: none;
  background: none;
  outline: none;
`;

export const DivLeft = styled.div`
  width: 50%;
  padding-left: 5px;
  display: flex;
  flex-direction: column;

  > span {
    max-width: 100%;
    font-weight: 400;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 13px;
  }

  > strong {
    color: #2b8ff5;
    font-weight: normal;
    font-size: 11px;
  }
`;

export const DivRight = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    margin-right: 20px;
  }

  > img {
    margin-right: 10px;
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 7px;
  font-size: 13px;

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }
`;

export const SimpleSelect = styled(Select)`
  width: 80px;
  border-radius: 10px;

  .css-yk16xz-control {
    border-radius: 10px;
  }

  .css-1uccc91-singleValue {
    color: #b0abba;
    display: flex;
  }
`;

export const FaRegTrashAlts = styled(FaRegTrashAlt)`
  &:hover {
    color: ${darken(0.05, '#cccccc')} !important;
  }
`;

export const DivPrice = styled.div`
  display: flex;
  width: 100%;
`;

export const SpanPrice = styled.span`
  display: flex;
  width: auto;
  font-size: 13px;
  color: ${props => props.color || '#838383'};
  text-decoration: ${props => (props.hasPromo ? 'line-through' : 'none')};
  margin-right: 6px;
  font-weight: 400;
`;

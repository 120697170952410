import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 720px;
  display: flex;
  flex-direction: column;
  margin-bottom: 5vw;
  padding-top: 24px;
  background: #fafafa;

  > span {
    margin-left: 20px;
    font-weight: 500;
    font-size: 15px;
    color: #000;
    margin-bottom: 11px;
  }
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin-top: 5px;
  padding: 20px;

  .div-endereco {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;

      input {
        & + input {
          margin-left: 24px;
        }
      }
    }
  }

  .div-buttons-payment-methods {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    button {
      border: 0.5px solid #d8dee5;
      background: #fff;
      padding: 8px 18px;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .selected {
      background: #0d0a0a;
      border: 0.5px solid #0d0a0a;
      color: #fff;
    }
  }

  > div {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    strong {
      font-weight: 500;
      font-size: 15px;
      color: #000;
    }

    span {
      margin-top: 13px;
      font-weight: normal;
      font-size: 13px;
      color: #838383;
    }

    input {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }
  }
`;

export const ButtonAddSocialMedia = styled.button`
  font-weight: 500;
  font-size: 13px;
  color: #2b8ff5;
  background: none;
  border: none;
  margin-top: 14px;
`;

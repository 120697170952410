import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { seePlansProModalEvent } from '~/functions/events';
import {
  ContainerGeral,
  Container,
  Div,
  Header,
  Card,
  DiscountLabel,
  Price,
  OldPrice,
  DivButton,
  DivPrice,
} from './styles';

// import CrownIcon from '../../../assets/icons/plan/crown.svg';
import ArrowRight from '../../../assets/icons/arrowRight.svg';
import LockIcon from '../../../assets/icons/popups/lock';

import dataPlans from '../../../data/plans.json';

function ProPlan({ showModal, Modal, is_desktop }) {
  const history = useHistory();

  const handleModal = useCallback(
    buttonClicked => {
      Modal(false);

      if (buttonClicked) {
        history.push('/pro');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Modal]
  );

  const handleModalDesktop = useCallback(() => {
    Modal(false, true);
  }, [Modal]);

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={() => handleModal(false)} />
      <Div showModal={showModal}>
        <Header>
          <LockIcon />

          <strong>
            <span>Assine o Plano {dataPlans.pro.name}</span> para ter acesso a
            esse recurso e muito mais!
          </strong>
        </Header>

        <Card>
          <DivPrice>
            <Price color={dataPlans.pro.color}>
              R$ {dataPlans.pro.price.yearly.price}
              <span>/mês</span>
            </Price>
            <OldPrice>R$ {dataPlans.pro.price.yearly.oldPrice}/mês</OldPrice>
          </DivPrice>

          <DiscountLabel color={dataPlans.pro.color}>
            <span>{dataPlans.pro.price.yearly.economyPercent} DE DESCONTO</span>
          </DiscountLabel>
        </Card>

        <DivButton>
          {(is_desktop && (
            <button type="button" onClick={handleModalDesktop}>
              Assinar plano {dataPlans.pro.name}{' '}
              <img src={ArrowRight} alt="arrow" />
            </button>
          )) || (
            // <Link to="/pro" onClick={handleModal}>
            //   Assinar plano {dataPlans.pro.name}{' '}
            //   <img src={ArrowRight} alt="arrow" />
            // </Link>
            <button
              type="button"
              onClick={() => {
                seePlansProModalEvent();
                handleModal(true);
              }}
            >
              Assinar plano {dataPlans.pro.name}{' '}
              <img src={ArrowRight} alt="arrow" />
            </button>
          )}
        </DivButton>
      </Div>
    </ContainerGeral>
  );
}

export default ProPlan;

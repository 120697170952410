import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`
 /* @import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap'); */
 /* @import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;1,400;1,500&family=Dancing+Script:wght@400;500;600;700;800&family=Inter:wght@100;200;300;400;500;600;700;800&family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Taviraj:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap'); */
 @import url('https://fonts.googleapis.com/css2?family=Crimson+Text:wght@400;600;700&family=DM+Sans:ital,wght@0,400;0,500;1,400;1,500&family=Dancing+Script:wght@400;500;600&family=Exo+2:wght@400;500;600;700&family=Fredoka:wght@400;500;600;700&family=IBM+Plex+Mono:wght@400;500;600;700&family=Inter:wght@300;400;500;600&family=Kaushan+Script&family=Montserrat:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&family=Playfair+Display:wght@400;500;600;700&family=Poppins:wght@400;500;600;700&family=Roboto:wght@400;500;700&family=Rubik:wght@400;500;600;700&family=Source+Serif+4:wght@400;500;600;700&family=Taviraj:ital,wght@0,400;0,500;0,600;1,400;1,500;1,600&display=swap');

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }

  body {
    background: #fafafa;
    -webkit-font-smoothing: antialiased !important;
  }

  body, input, button, textarea {
    color: #222;
    font-size: 14px;
    font-family: 'Inter', sans-serif;

    &:hover {
      transition: all 300ms;
    }


  }

  button {
    cursor: pointer;
  }



  /* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 21px;
  margin-left: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* -webkit-transition: .1s; */
  /* transition: .1s; */
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 2px;
  top: 2px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 7px 0px;
}

input:checked + .slider {
  background-color: #288ff5;
}

input:focus + .slider {
  /* box-shadow: 0 0 1px #3F76FC; */
}

input:checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 20px;
}

/* CUSTOMIZAÇÃO SCROLL */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #E0E1E2;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #E0E1E2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #C7CED8;
}

/* video {
    border-radius: 10px;
} */

.Toastify__toast-body {
  display: flex;
  align-items: center;
  justify-content: center;
  }

  .Toastify__toast {
    min-height: 40px !important;
    width: 100%;
    font-family: 'Inter', BlinkMacSystemFont, 'Segoe UI', Roboto !important;
    box-shadow: 3px 6px 12px 0 rgba(0, 0, 0, 0.07);
    position: absolute;
    font-size: 13px;
    /* top: 0; */


    @media (min-width: 720px) {
      border-radius: 50px;
    }
  }

  .Toastify__close-button {
    display: none !important;
  }

  .Toastify__toast-container {
    top: 24px;

    @media (max-width: 720px) {
      top: 0;
    }
  }

  .Toastify__toast--success {
    /* background: #34C780 !important; */
    background: #2B8FF5 !important;

  }

  .Toastify__toast--error {
    background: #FF303C !important;
  }


/* @keyframes fadeIn {
  from {
    transform: translateY(-45px);
  }

  to {
    transform: translateY(0px);
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeOut {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-70px);
  }
}

.fadeOut {
  animation-name: fadeOut;
} */

`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 810px !important;
`;

export const DivTitle = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    color: #101010;
    margin-right: 6px;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 16px;
  background: rgb(255, 255, 255);
  margin-bottom: 48px;
  padding: 32px 0;

  > img {
    max-width: 295px;
    align-self: center;
  }
`;

export const DivInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding: 25px 24px 0 0; */
  width: 100%;

  > strong {
    margin-bottom: 8px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    max-width: 500px;

    background: -webkit-linear-gradient(
      239.62deg,
      #ff4026 8.67%,
      #9025fa 96.41%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  > span {
    text-align: center;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 32px;
    max-width: 500px;
  }

  > a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #2b8ff5;
    border-radius: 8px;
    max-width: 500px;

    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border: none;
    text-decoration: none;
  }

  > button {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 50px;
    background: #2b8ff5;
    border-radius: 8px;
    max-width: 500px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
    border: none;

    > img {
      position: absolute;
      right: 20px;
    }
  }
`;

export const DivUrlFeed = styled.div`
  display: flex;
  margin-bottom: 12px;
  width: 100%;
  max-width: 500px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
    background: #fafafa;
    border-radius: 8px 0 0 8px;
    padding: 0 12px;
    font-size: 13px;
    color: #2b8ff5;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* width: 90px; */
    height: 50px;
    border-radius: 0 8px 8px 0;
    border: none;
    font-size: 13px;
    padding: 0 12px;

    > img {
      margin-right: 6px;
    }
  }
`;

export const DivCodeHtml = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* height: 50px; */
  padding: 24px 0;
  background: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #2b8ff5;
  border: none;
  cursor: pointer;
`;

export const LabelPro = styled.div`
  display: flex;
  padding: 2px 6px;
  border: 1px solid #ffb525;
  border-radius: 4px;
  align-items: center;

  > span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #ffb525;
  }
`;

export const ContainerModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 990;
`;

export const Modal = styled.div`
  position: fixed;
  margin: auto;
  background: #fff;
  top: calc(50vh - (222px / 2));
  left: calc(50vw - (522px / 2));
  box-shadow: rgb(172 161 161 / 25%) 16px 34px 58px;
  width: 100%;
  max-width: 522px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 24px 16px;
  justify-content: center;
  align-items: center;
  z-index: 999;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #131313;
    margin-bottom: 16px;
  }

  > span {
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #838383;
    margin-bottom: 12px;
  }

  > input {
    background: #f9f9f9;
    border-radius: 8px;
    border: none;
    height: 42px;
    padding: 0 12px;
    width: 100%;
    margin-bottom: 16px;
  }

  > button {
    background: #2b8ff5;
    border-radius: 8px;
    height: 50px;
    width: 100%;
    color: #fff;
    border: none;
  }
`;

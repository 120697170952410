import styled, { css } from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 550;
  background: #fff;
`;

export const Header = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 64px;
  border-bottom: 0.5px solid #d8dee5;

  > div {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 64px;
    height: 64px;
  }

  > h3 {
    font-weight: 500;
    font-size: 16px;
    color: #000000;
  }
`;

export const DivBody = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
  overflow-y: auto;
  height: 100vh;
  padding: 78px 16px 100px 16px;

  > span {
    font-weight: 400;
    font-size: 13px;
    color: #838383;
    text-align: center;
  }

  > button {
    min-height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d85ff;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    margin: 20px 0;
  }
`;

export const Loader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 200px;
  left: 0;
  z-index: 999;
  background: none;

  img {
    background: none;
    width: 25px;
    height: 25px;
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 80px;
  border: 1px dashed #d8dee5;
  margin-top: 8px;
  padding: 0 16px;

  > img {
    width: 16px;
    opacity: ${props => props.opacity};
  }

  .loader {
    width: 20px;
    height: 20px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #000000;
    }
  }

  .middle {
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 0 16px;

    > div {
      > span {
        font-size: 10px;
        color: #838383;
        margin-left: 4px;
      }
    }

    > button {
      border: none;
      background: none;
      font-weight: 500;
      font-size: 13px;
      color: #0d85ff;
      margin-top: 4px;

      > svg {
        margin-right: 4px;
      }
    }
  }

  .right {
    height: 80px;
    /* position: absolute; */
    right: 16px;

    .delete {
      color: #ff303c;
    }

    > button {
      border: none;
      background: none;
      font-weight: 500;
      font-size: 13px;
      color: #0d85ff;
    }

    > img {
      margin-left: 16px;
    }
  }
`;

export const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 90px;
  background: #ffffff;
  z-index: 551;
  padding: 0 16px;

  > button {
    width: 100%;
    height: 50px;
    background: #0d85ff;
    border-radius: 8px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }
`;

export const ContainerAddElement = styled.div`
  display: flex;
  justify-content: center;
`;

export const ContainerBackground = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  float: 1;
  z-index: 551;
  background: #000000;
  opacity: 20%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  /* display: ${props => (props.showModal ? 'flex' : 'none')}; */
  align-items: center;
  justify-content: center;
`;

export const DivAddElement = styled.div`
  width: 100%;
  max-width: 580px;
  /* height: 66vh; */
  display: flex;
  /* display: ${props => (props.showModal ? 'flex' : 'none')}; */
  flex-direction: column;
  align-items: center;
  background: #fff;
  z-index: 551;
  position: fixed;
  bottom: 0;
  margin-top: 0;
  border-radius: 24px 24px 0 0;

  .header {
    position: relative;
    width: 100%;
    min-height: 60px;
    border-bottom: 0.5px solid #d8dee5;
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      text-align: center;
      color: #000000;
      margin-bottom: 0 !important;
    }

    > div {
      position: absolute;
      right: 0;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      padding: 0 24px;

      > button {
        background: none;
        border: none;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #ff303c;
      }
    }
  }

  .body {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 16px;
    height: 300px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }

    > span {
      font-size: 13px;
      color: #838383;
      margin-top: 4px;
    }

    .collection {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
      padding: 12px 24px;
      border-radius: 8px 0px 0px 8px;
      border-right: 1px solid #0d85ff;
    }

    .banner {
      color: #9d9d9d;
      border: 1px solid #9d9d9d;
      padding: 12px 24px;
      border-radius: 0px 8px 8px 0px;
      border-left: none !important;
    }

    .active {
      border: 1px solid #0d85ff;
      color: #0d85ff;
    }
  }
`;

export const StyledSelect = styled(Select)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-top: 4px;
  font-size: 13px;
  margin-bottom: 16px;

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      touch-action: none;
    `}

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }

  .css-g1d714-ValueContainer {
    padding: 6px 8px;
  }
`;

export const DivSelect = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    line-height: 1.5;
    color: #000000;
  }
`;

export const DivButton = styled.div`
  display: flex;
  width: 100%;
  padding: 0 16px;

  > button {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d85ff;
    border-radius: 8px;
    border: none;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    margin-top: 32px;
  }
`;

export const DivSelectElement = styled.div`
  display: flex;
  margin-top: 12px;
  flex-wrap: wrap;
  gap: 8px;

  .selected {
    background: #0d85ff;
    font-weight: 500;
    color: #ffffff;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px;
    height: 40px;
    padding: 0 16px;
    font-size: 13px;
    color: #101010;

    /* & + div {
      margin-left: 8px;
    } */
  }
`;

import React, { useState, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { toast } from 'react-toastify';

// import api from '../../services/api';

import CancelImage from '../../assets/icons/cancelx.svg';
import CopyIcon from '../../assets/icons/indication/copy.svg';

import { ContainerGeral, Container, Div, DivTitle, DivBody } from './styles';

function IndicationModal({
  showModal,
  Modal,
  returnIndications,
  getIndications,
  username,
}) {
  // const [inputEmail, setInpuEmail] = useState('');

  const handleModal = useCallback(() => {
    Modal(false);
    sessionStorage.setItem('indicationpopup', true);
  }, [Modal]);

  // async function handleSubmit() {
  //   try {
  //     const data = {
  //       email: inputEmail && inputEmail.toLowerCase(),
  //     };

  //     await api.post('/indication', data);

  //     if (returnIndications) {
  //       getIndications();
  //     }

  //     handleModal();
  //   } catch (err) {
  //     toast.error(err.response.data.error);
  //   }
  // }

  return (
    <ContainerGeral>
      <Container showModal={showModal} onClick={handleModal} />
      <Div showModal={showModal}>
        <DivTitle>
          <div>
            <img src={CancelImage} alt="cancelImage" onClick={handleModal} />
          </div>
        </DivTitle>
        <DivBody>
          <h2>Indique e ganhe o plano PRO grátis</h2>

          <div>
            <span>
              Copie seu link personalizado da offstore e envie para seus amigos.
              Caso 5 deles cliquem no seu link e criem uma conta, você ganha
              automaticamente acesso gratuito ao plano PRO.
            </span>

            <div>
              <span>offstore.me/invite/{username}</span>
              <CopyToClipboard text={`offstore.me/invite/${username}`}>
                <button
                  type="button"
                  onClick={() => toast.success('Link copiado')}
                >
                  <img src={CopyIcon} alt="copy" />
                  <span>copiar</span>
                </button>
              </CopyToClipboard>
            </div>
          </div>
        </DivBody>
      </Div>
    </ContainerGeral>
  );
}

export default IndicationModal;

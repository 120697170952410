import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const DivBody = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  /* overflow-y: scroll; */
  border-radius: 16px;
`;

export const DivInput = styled.div`
  display: flex;
  padding: 24px 16px;
`;

export const StyledDebounceInput = styled(DebounceInput)`
  background: #fafafa;
  border-radius: 15px;
  height: 50px;
  width: 100%;
  border: none;
  display: flex;
  text-align: center;

  &::placeholder {
    color: #a5a9b1;
  }
`;

export const DivProducts = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px 108px 16px;
`;

export const DivRem = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1.05263px solid #d8dee5;
    box-sizing: border-box;
    border-radius: 3.15789px;
    background: none;
    color: #d8dee5;
  }
`;

export const Card = styled.div`
  display: flex;
  margin-bottom: 8px;
  cursor: pointer;

  .body {
    display: flex;
    flex: 1;
    align-items: center;
    padding: 8px;
    border: 0.5px solid #d8dee5;
    border-radius: 8px;
    width: 100%;
    position: relative;

    > img {
      width: 46px;
      height: 46px;
      border-radius: 6px;
      object-fit: cover;
      margin-right: 8px;
    }

    > div {
      display: flex;
      flex-direction: column;

      > strong {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #000000;
      }

      > span {
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        color: #9d9d9d;
      }
    }
  }
`;

export const ButtonAdd = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: ${props =>
    props.active ? '1.05263px solid #2B8FF5' : '1.05263px solid #d8dee5'};
  box-sizing: border-box;
  border-radius: 3.15789px;
  background: none;
  color: ${props => (props.active ? '#2B8FF5' : '#d8dee5')};
  right: 20px;
  font-weight: 500;
  line-height: 1.2;
`;

export const ButtonRem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1.05263px solid #ff303c;
  box-sizing: border-box;
  border-radius: 3.15789px;
  background: none;
  color: #ff303c;
`;

export const DivFooter = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  bottom: 0;
  padding: 0 16px 0 16px;
  /* background: #ffffff; */
  background: ${props => (props.hasBackground ? '#ffffff' : 'none')};

  > button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    border: none;
    background: #2b8ff5;
    color: #fff;
    padding: 0 20px;
    border-radius: 8px;

    &:disabled {
      background: #838383;
    }

    > span {
      font-size: 13px;
      color: #ffffff;
      width: 20px;
    }

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
    }
  }

  .see-order {
    justify-content: center;
    background: none;

    > strong {
      color: #9d9d9d;
    }
  }
`;

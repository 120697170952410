import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
// import history from '../../../services/history';

import api from '../../../services/api';

import HeaderMobile from '../../../components/HeaderMobile';

import { Div, DivButton, DivBody, DivFooter, Input } from './styles';

import {
  insertPromotionRequest,
  updatePromotionRequest,
} from '../../../store/modules/linkPrev/actions';

export default function PromotionRegister({ match }) {
  const { id } = match.params;
  const [promoName, setPromoName] = useState();
  const [promoDescription, setPromoDescription] = useState();

  const history = useHistory();

  const dispatch = useDispatch();

  useEffect(() => {
    async function load() {
      if (id > 0) {
        const promo = await api.get(`/promotionbyid/${id}`);

        setPromoName(promo.data.name);
        setPromoDescription(promo.data.description);
      } else {
        setPromoName('');
        setPromoDescription('');
      }
    }

    load();
  }, [id]);

  async function handleSubmit() {
    try {
      if (!promoName || !promoDescription) {
        toast.error('Preencha todos os campos');
        return 0;
      }

      if (id > 0) {
        const data = {
          id,
          name: promoName,
          description: promoDescription,
        };

        dispatch(updatePromotionRequest(data));
      } else {
        const data = {
          name: promoName,
          description: promoDescription,
        };

        dispatch(insertPromotionRequest(data));
      }
    } catch (err) {
      toast.error('Erro ao inserir ou alterar Promoção');
    }
  }

  return (
    <Div>
      <HeaderMobile title="Adicionar promoção" tab={2} />

      <DivBody>
        <span>Titulo</span>
        <Input
          type="text"
          placeholder="Título da promoção"
          value={promoName}
          onChange={e => setPromoName(e.target.value)}
        />

        <span>Descrição</span>
        <textarea
          rows={5}
          placeholder="Descreva sua promoção"
          value={promoDescription}
          onChange={e => setPromoDescription(e.target.value)}
        />
      </DivBody>

      <DivFooter>
        <DivButton>
          <button type="button" onClick={handleSubmit}>
            Confirmar
          </button>
        </DivButton>
      </DivFooter>
    </Div>
  );
}

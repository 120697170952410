import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';

export const Div = styled.div`
  width: 100%;
  min-height: 100vh;
  /* display: flex; */
  display: block;
  background: #fff;
  /* flex-direction: column; */
  position: relative;

  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 400;
  }
`;

export const DivLoader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 10;

  > img {
    width: 50px;
    /* margin-bottom: 8px; */
  }

  > span {
    font-size: 13px;
    line-height: 19px;
    color: #9d9d9d;
  }
`;

export const DivBody = styled.div`
  width: 100%;
  height: 100%;
  /* margin-top: 55px; */
  display: block;
  padding-top: 79px;
  padding-bottom: ${props =>
    props.hasImage ? (props.manyImages ? '192px' : '139px') : '79px'};
  position: relative;
`;

export const GeneralDiv = styled.div`
  padding: 0 16px;
  width: ${props => props.width || '100%'};
  border-style: none;
  border-width: 1px;
  display: ${props => (props.hide ? 'none' : 'flex')};
  flex-direction: column;
  background: #fff;
  position: relative;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }

  span {
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }

  .warning {
    color: #ffb525;
    font-size: 12px;
    font-weight: 400;
    margin-top: 4px;
  }

  .div-price {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    > span {
      margin-bottom: 5px;
    }

    .input-price {
      border-radius: 0px 8px 8px 0px;
    }

    .currency-input {
      width: 100px;
      height: 42px;
      background: #f9f9f9;
      border: 1px solid #f9f9f9;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 0 12px;
      font-size: 13px;

      &::placeholder {
        color: #d1d1d1;
      }
    }
  }

  .div-description {
    width: 100%;
    height: 100%;

    textarea {
      background: #f9f9f9;
      border: 1px solid #f9f9f9;
      box-sizing: border-box;
      border-radius: 6px;
      padding: 11px 12px;
      font-size: 13px;
      resize: none;
      width: 100%;
      height: 130px;
      margin-top: 5px;

      &::placeholder {
        color: #d1d1d1;
      }
    }
  }

  .div-select {
    width: 100%;
  }
`;

export const DivProduct = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  > span {
    margin-bottom: 5px;
  }
`;

export const DivTitle = styled.div`
  display: flex;
`;

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;

  > span {
    color: #0d85ff;
    margin-left: 4px;
    font-size: 13px;
    font-weight: 500;
  }
`;

export const DivInputPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
  opacity: ${props => (props.disabled ? '50%' : '100%')};

  > div {
    width: 42px;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #efefef;
    border-radius: 8px 0px 0px 8px;
  }

  /* > input {
    color: ${props => (props.warning ? '#FFB525' : '#101010')};
  } */
`;

export const DescriptionSize = styled.span`
  display: flex;
  /* margin-bottom: 20px; */
  justify-content: flex-end;
  color: #9d9d9d !important;
`;

export const DivFooter = styled.div`
  display: flex;
  width: 100%;
  background: #f7f8f9;
  padding: 0 20px;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  left: 0;

  span {
    display: none;
  }
`;

export const DivSelectedCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;

  .selected {
    color: #9d9d9d;
    border: 1px solid #9d9d9d;

    > span {
      color: #9d9d9d;
    }
  }

  > div {
    display: flex;
    align-items: center;
    height: 32px;
    border: 1px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 62px;
    padding: 0 18px;
    color: #2b8ff5;
    margin-right: 8px;
    margin-bottom: 8px;

    > span {
      font-size: 9px;
      color: #2b8ff5;
      font-weight: normal;
    }

    &:last-child {
      margin-right: 0;
    }

    > img {
      margin-left: 10px;
    }
  }
`;

export const DivCategories = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 16px;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    background: #ffffff;
    border: 0.5px solid #d8dee5;
    box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 100px;
    margin-right: 8px;

    > img {
      width: 15px;
    }
  }

  .master {
    color: #fff;
    background: #9d9d9d;
    border: 0.5px solid #9d9d9d;

    > span {
      color: #fff;
    }
  }

  .selected {
    color: #fff;
    background: #2b8ff6;
    border: 0.5px solid #2b8ff6;

    > span {
      color: #fff;
    }
  }
`;

export const ButtonSeeAll = styled.button`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  width: auto !important;
  border: none !important;
  /* identical to box height */
  color: #9d9d9d;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border: 0.5px solid #D8DEE5;
  box-sizing: border-box;
  border-radius: 132px;
  height: 36px;
  padding: 0 18px;
  margin-bottom: 8px;
  margin-right: 8px;
  font-size: 13px;

  > span {
    font-size: 9px;
    color: #838383;
    font-weight: normal;
  }

  /* ${props =>
    props.select &&
    css`
      color: #fff;
      background: #2b8ff6;
      border: 0.5px solid #2b8ff6;

      > span {
        color: #fff;
      }
    `} */

  &:last-child {
    margin-right: 0;
  }
`;

export const DivImages = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  /* flex-wrap: nowrap; */
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const LabelImage = styled.label`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;

export const DivButton = styled.div`
  width: 100%;
  border-style: none;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 20px 0;

  .button-img {
    background: transparent;
    border: 1px dashed #a5a9b1;
    box-sizing: border-box;
    border-radius: 8px;
    box-shadow: none;
    width: 45px !important;
    height: 45px;
    margin-right: 16px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: #2b8ff6;
    color: #ffffff;
    border: none;
    font-size: 15px;
    height: 45px;
    font-weight: 500;
  }

  .button-save {
    flex: 1;

    > img {
      width: 25px;
      height: 25px;
    }
  }
`;

export const Input = styled.input`
  width: ${props => props.width || '100%'};
  height: 42px;
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 0 12px;
  font-size: 13px;

  &::placeholder {
    color: #d1d1d1;
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  margin-top: 4px;
  font-size: 13px;

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-1wa3eu0-placeholder {
    color: #bfbfbf;
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    box-sizing: border-box;
  }

  .css-12jo7m5 {
    color: #fff;
  }
`;

export const DivOpenClose = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || '24px 16px'};
  border-top: ${props => (props.first && '0.5px solid #d8dee5') || 'none'};
  border-bottom: 0.5px solid #d8dee5;
  margin-top: ${props => (props.first && '24px') || '0'};

  > span {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: #000000;
    margin-top: 20px;
  }

  .header {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;

    > img {
      position: absolute;
      right: 0;
    }

    > div {
      display: flex;
      flex-direction: column;

      > span {
        color: #838383;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-top: 4px;
      }

      > div {
        > strong {
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          color: #000000;
          margin-right: 4px;
        }

        > span {
          font-style: normal;
          font-weight: normal;
          font-size: 10px;
          line-height: 15px;
          color: #9d9d9d;
        }
      }
    }

    .blue {
      color: #2b8ff5;
      border: 1px solid #2b8ff5;
      border-radius: 4px;
      font-size: 13px;
      padding: 2px 8px;
      margin-left: 4px;
    }
  }

  .header-variations {
    padding: 0 16px;

    > img {
      position: absolute;
      right: 16px;
    }
  }
`;

export const ButtonAddVariations = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: #2b8ff5;
  height: 44px;
  width: 180px;
  border: none;
  margin-top: 16px;
  border-radius: 8px;
  margin-left: 16px;

  > svg {
    margin-right: 6px;
  }
`;

export const TableVariations = styled.table`
  width: 100%;
  border-spacing: 0 7px;
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 16px;

  thead {
    .switch {
      margin-left: 6px;
    }

    .stock {
      text-align: center;
    }
  }

  .disabled-tr {
    opacity: 50%;
  }

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > thead {
    background: #fafafa;
    border-radius: 7px;
    height: 45px;

    .title {
      padding-left: 4px;
    }

    .fixed {
      position: sticky;
      left: 0;
      z-index: 50;
      background: #fafafa;
      opacity: 90%;
    }

    th {
      padding: 0 12px;
      font-weight: 500;
      font-size: 13px;
      color: #010101;
      padding: 12px 16px;

      &:first-child {
        border-collapse: collapse;
        border-radius: 8px 0 0 8px;
        border-style: hidden; /* hide standard table (collapsed) border */
      }

      &:last-child {
        border-collapse: collapse;
        border-radius: 0 8px 8px 0;
        border-style: hidden; /* hide standard table (collapsed) border */
      }
    }

    > tr {
      text-align: left;
    }
  }

  > tbody {
    .stock {
      padding-left: 0;
    }

    .checkbox {
      padding-left: 0;
    }

    .title {
      padding-left: 16px;
      padding-right: 16px;
      background: #ffffff;
    }

    .fixed {
      position: sticky;
      left: 0;
      z-index: 50;
      opacity: 90%;
    }

    td {
      padding: 0 12px 7px 12px;
      border-bottom: 0.5px solid #d8dee5;
      font-size: 13px;
      color: #010101;
      max-width: 130px;
      padding-right: 8px;
    }
  }
`;

export const DivInputValueVariation = styled.div`
  display: flex;
  width: 97px;

  > div {
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    background: #efefef;
    border-radius: 8px 0 0 8px;
    font-weight: 500;
    font-size: 13px;

    ${props =>
      props.emptyPrice &&
      css`
        background: rgb(255, 48, 60, 0.2);
        color: #ff303c;
      `}
  }

  > input {
    background: #f9f9f9;
    border: none;
    border-radius: 0 8px 8px 0;
    padding: 0 8px;
    width: 65px;
    font-size: 13px;

    ${props =>
      props.emptyPrice &&
      css`
        background: rgb(255, 48, 60, 0.1);
        color: #ff303c;
      `}

    &::placeholder {
      font-size: 13px;
      color: #cfcfcf;
    }
  }
`;

export const DivInputStockVariation = styled.div`
  display: flex;

  .no-stock {
    background: rgba(255, 48, 60, 0.1);
    color: #ff303c;
  }

  > button {
    background: #efefef;
    border: 1px solid #efefef;
    box-sizing: border-box;
    border-radius: 6px;
    width: 32px;
    min-width: 32px;
    height: 34px;
  }

  > input {
    background: #f9f9f9;
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 6px;
    text-align: center;
    width: 46px;
    margin: 0 6px;
    font-size: 13px;
    color: #101010;

    &::placeholder {
      font-size: 13px;
      color: #cfcfcf;
    }
  }
`;

export const DivStock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  > span {
    font-size: 13px;
    color: #838383;
    margin-bottom: 12px;
  }

  .disabled {
    opacity: 50%;
  }

  > div {
    display: flex;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #000000;
      margin-bottom: 4px;
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      background: #efefef;
      border: 1px solid #efefef;
      box-sizing: border-box;
      border-radius: 6px;
      height: 34px;
      width: 32px;

      &:disabled {
        opacity: 50%;
      }
    }

    > input {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      width: 60px;
      background: #f9f9f9;
      border: 0.5px solid #f9f9f9;
      box-sizing: border-box;
      border-radius: 6px;
      margin: 0 6px;
      text-align: center;

      &:disabled {
        opacity: 50%;
      }
    }
  }
`;

export const DivPromotionalPrice = styled.div`
  width: 100%;
  margin-top: 16px;
  /* margin-bottom: 24px; */

  .currency-input {
    width: 100px;
    height: 42px;
    background: #f9f9f9;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 0 12px;
    font-size: 13px;

    &::placeholder {
      color: #d1d1d1;
    }
  }

  > span {
    display: flex;
    margin-bottom: 5px;
  }

  /* input {
    width: 100%;
  } */
`;

export const DivPay = styled.div`
  width: 100%;

  > span {
    display: flex;
    margin-bottom: 5px;
  }

  input {
    margin-bottom: 0;
  }
`;

export const DivDimensions = styled.div`
  display: flex;
  padding-top: 1px;
  margin-bottom: 24px;
  margin-top: 16px;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 200px;
    padding: 0 8px;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #000000;
      margin-bottom: 6px;
      min-width: 80px;
    }

    > span {
      color: ${props => (props.weight >= 10 ? '#FF303C' : '#838383')};
      font-size: 12px;
      font-style: italic;
      font-weight: 400;
      line-height: normal;
      margin-top: 8px;
      text-align: center;
    }

    > div {
      display: flex;

      > input {
        text-align: center;
        border: none;
        background: #f9f9f9;
        box-sizing: border-box;
        border-radius: 8px 0 0 8px;
        height: 44px;
        width: 100%;

        &::placeholder {
          font-size: 13px;
          color: #afafaf;
        }
      }

      > div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        background: #efefef;
        font-size: 13px;
        color: #101010;
        border-radius: 0 8px 8px 0;
      }
    }
  }
`;

export const Message = styled.div`
  display: flex;
  border-left: ${props => props.borderLeft};
  width: 100%;
  padding: 16px;
  background: ${props => props.background};
  align-items: center;
  margin: ${props => props.margin};

  > svg {
    margin-right: 16px;
    width: 18px;
    height: 18px;
  }

  > span {
    color: ${props => props.color};
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    flex: 1;

    > b {
      font-weight: 600;
    }
  }
`;

export const ButtonMore = styled.button`
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  color: #2b8ff5;
  background: transparent;
  border: none;
  padding: 16px 0;
`;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 8px;
  background: #fafafa;
  padding: 24px 16px;

  > span {
    font-size: 13px;
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 12px;
    border-radius: 7px;
    background: #efefef;
    height: 44px;
    width: 100%;
    color: #101010;
    font-weight: 600;
    text-decoration: none;
  }
`;

import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 26px 16px;
  background: #fff;
`;

export const MainCode = styled.span`
  color: #000;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const Text = styled.span`
  color: var(--body-font, #838383);
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ContainerActions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
`;

export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border-radius: 6px;
  background: rgba(43, 143, 245, 0.06);
  color: var(--blue-details, #0d85ff);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: fit-content;
`;

export const OutlinedButton = styled.button`
  color: var(--blue-details, #0d85ff);
  text-align: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: transparent;
  border-width: 0px;
  display: flex;
  align-items: center;
  gap: 6px;
`;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

import React from 'react';

export function TrafficIcon({ color }) {
  return (
    <svg
      width="33"
      height="26"
      viewBox="0 0 33 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1387 5.78467H27.3156C28.5845 5.78467 29.8014 6.28875 30.6988 7.18606C31.5962 8.08334 32.1002 9.30033 32.1002 10.5693V20.1385H28.5117"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.39231 20.1385H1V3.39231C1 2.75782 1.25205 2.14934 1.70069 1.70068C2.14934 1.25205 2.75782 1 3.39231 1H17.7462C18.3806 1 18.9891 1.25205 19.4378 1.70068C19.8864 2.14934 20.1385 2.75782 20.1385 3.39231V20.1385"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9417 20.1382H12.9609"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.3252 24.9229C22.013 24.9229 20.1387 23.0485 20.1387 20.7363C20.1387 18.4242 22.013 16.5498 24.3252 16.5498C26.6374 16.5498 28.5117 18.4242 28.5117 20.7363C28.5117 23.0485 26.6374 24.9229 24.3252 24.9229Z"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.17677 24.9229C5.86461 24.9229 3.99023 23.0485 3.99023 20.7363C3.99023 18.4242 5.86461 16.5498 8.17677 16.5498C10.4889 16.5498 12.3633 18.4242 12.3633 20.7363C12.3633 23.0485 10.4889 24.9229 8.17677 24.9229Z"
        stroke={color ?? '#9D9D9D'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

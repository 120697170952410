import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: max-content;

  /* padding-left: 16px; */
  /* background: #ffffff; */
`;

export const DiscountLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: ${props => props.color};
  color: #838383;
  width: 160px;
  font-size: 12px;
  font-weight: 600;
  padding: 8px 16px;
  margin-bottom: 16px;
`;

export const GreyLabel = styled.div`
  background: #efefef;
  border-radius: 100px;
  padding: 4px 16px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    color: #101010;
    text-align: center;
    font-size: 11px;
    font-weight: 400;
  }
`;

export const DivProPlan = styled.div`
  display: flex;
  /* width: calc(100vw - 48px); */
  /* width: 88vw; */
  max-width: 390px;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  position: relative;
  border-radius: 18px;
  border: ${props =>
    props.showBorder ? '2px solid #0D85FF' : '0.5px solid #d8dee5'};
  /* box-shadow: 0px 8px 90px -30px rgba(0, 0, 0, 0.11); */

  body {
    padding: 24px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 18px;
    background: #ffffff;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #101010;
      margin-bottom: 1px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #acacac;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;

export const DivHeaderLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 56px;
  border-radius: 18px 18px 0 0;
  background: #0d85ff;

  color: #fff;
  font-size: 14px;
  font-weight: 600;
`;

export const DivBolet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;

  > button {
    display: flex;
    border: none;
    background: none;
    align-items: center;
    justify-content: center;
    color: #9d9d9d;
    font-size: 14px;
    font-weight: 600;

    > img {
      margin-right: 8px;
    }
  }
`;

export const DivPrice = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;

  > strong {
    font-size: 37px;
    font-style: normal;
    font-weight: 800;
    color: ${props => props.color};
    margin-bottom: 4px;

    > span {
      font-size: 13px;
      color: ${props => props.color};
      font-weight: 500;
    }
  }

  > span {
    font-weight: 400;
    font-size: 13px;
    text-align: center;
    text-decoration-line: line-through;
    color: #9d9d9d;
  }
`;

export const DivItemsPro = styled.div`
  display: flex;
  width: 100%;
  /* align-items: center; */
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 24px;
  white-space: normal;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    border: none;
    background: #0d85ff;
    border-radius: 8px;
    color: #fff;
    font-size: 15px;
    position: relative;
    font-weight: 600;

    > img {
      position: absolute;
      right: 20px;
    }

    &:disabled {
      background: #cdcdcd;
    }
  }

  .breve {
    color: #bfbfbf !important;
  }

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #101010;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 20px;

    > span {
      font-size: 14px;
      color: #838383;
      font-weight: 500;

      > svg {
        margin-right: 6px;
      }
    }

    > div {
      display: flex;
      /* border: 1px solid #ffb525; */
      margin-left: 6px;
      /* border-radius: 4px; */
      /* padding: 0 4px; */

      > span {
        display: flex;
        align-items: center;
        color: #cfcfcf;
        font-size: 13px;
      }
    }
  }
`;

export const BodySecundaryCard = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  > strong {
    color: #838383;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: normal;

    & + div {
      border-top: 1px solid #efefef;
      margin-top: 12px;
      padding-top: 12px;
    }

    > span {
      color: #838383;
      font-size: 12px;
      font-weight: 400;
      max-width: 180px;
    }

    .green {
      color: #06c86b;
      text-align: right;
    }

    .red {
      color: #ff303c;
      text-align: right;
    }
  }
`;

/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';
import Cookies from 'js-cookie';
import api from '~/services/api';
import {
  // CONVERSION_API_META_PIXEL_ID,
  // CONVERSION_API_META_TOKEN,
  Connection,
} from '~/services/config';
// import { generateHashSHA256 } from './crypto';

// export async function ConversionApiMeta({
//   eventId,
//   eventName,
//   email,
//   phone,
//   externalId,
// }) {
//   try {
//     const hashEmail = await generateHashSHA256(email);
//     const hashPhone = await generateHashSHA256(phone);
//     // const hashUserId = await generateHashSHA256(userId);

//     const fbp = Cookies.get('_fbp');
//     const fbc = Cookies.get('_fbc');

//     const now = new Date();

//     // Obtém o timestamp Unix em milissegundos e divide por 1000 para converter para segundos
//     const unixTimestampSeconds = Math.floor(now.getTime() / 1000);

//     const data = [
//       {
//         event_name: eventName,
//         event_time: unixTimestampSeconds,
//         action_source: 'website',
//         event_source_url: window.location.href,
//         user_data: {
//           em: [
//             hashEmail, // email
//           ],
//           ph: [
//             hashPhone, // phone
//           ],
//           fbp,
//           fbc,
//           external_id: externalId,
//         },
//       },
//     ];

//     if (eventName === 'Subscribe') {
//       data[0].custom_data = {
//         value: 29.9, // valor da assinatura
//         currency: 'BRL', // moeda
//         subscription_plan: 'Premium', // plano de assinatura
//         subscription_period: 'Mensal',
//       };
//     }

//     if (eventId) {
//       data[0].event_id = eventId;
//     }

//     try {
//       await axios.get('https://api.ipify.org/?format=json').then(response => {
//         if (response.data && response.data.ip) {
//           data[0].user_data.client_ip_address = response.data.ip;
//           data[0].user_data.client_user_agent = window.navigator.userAgent;
//         }
//       });
//     } catch (err) {
//       console.log(err);
//     }

//     const result = await axios.post(
//       `https://graph.facebook.com/v20.0/${CONVERSION_API_META_PIXEL_ID}/events`,
//       {
//         data,
//         test_event_code: 'TEST15160',
//       },
//       {
//         params: {
//           access_token: CONVERSION_API_META_TOKEN,
//         },
//       }
//     );

//     // console.log('eventSucess', result);
//     return result;
//   } catch (err) {
//     console.log('Erro ao enviar evento do Pixel', err);
//   }
// }

export async function CheckIfHaveNewFbc({ fbc }) {
  try {
    const userregistrationtracking = await api.get('/userregistrationtracking');

    if (userregistrationtracking && userregistrationtracking.data) {
      const { meta_fbc } = userregistrationtracking.data.trackings;

      if (fbc && fbc !== '' && fbc !== meta_fbc) {
        const result = await api.put('/userregistrationtracking', {
          meta_fbc: fbc,
        });

        return result.data.trackings.meta_fbc;
      }

      return userregistrationtracking.data.trackings.meta_fbc;
    }

    return fbc;
  } catch (err) {
    console.log('Erro ao verificar fbc', err);
  }
}

export async function ConversionApiMeta({
  eventId,
  eventName,
  email,
  phone,
  externalId,
  fbc,
}) {
  try {
    const fbp = Cookies.get('_fbp');

    const meta_fbc =
      fbc || (await CheckIfHaveNewFbc({ fbc: Cookies.get('_fbc') }));

    const data = {
      eventName,
      fbp,
      fbc: meta_fbc,
    };

    if (eventId) {
      data.eventId = eventId;
    }

    if (externalId) {
      data.externalId = externalId;
    }

    if (email) {
      data.email = email;
    }

    if (phone) {
      data.phone = phone;
    }

    try {
      // await axios.get('https://api.ipify.org/?format=json').then(response => {
      //   if (response.data && response.data.ip) {
      //     console.log('IPV4', response.data);
      //     data.clientIpAddress = response.data.ip;
      //   }
      // });

      await axios.get('https://api64.ipify.org/?format=json').then(response => {
        if (response.data && response.data.ip) {
          data.clientIpAddress = response.data.ip;
        }
      });
    } catch (err) {
      console.log(err);
    }

    const result = await axios.post(
      `${Connection.url_conversion_api}/meta/apiconversion`,
      data
    );

    // console.log('eventSucess', result);
    return result;
  } catch (err) {
    console.log('Erro ao enviar evento do Pixel', err);
  }
}

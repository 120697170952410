import React from 'react';

// import { Container } from './styles';

function InstagramIcon({ color }) {
  return (
    <svg
      width="21"
      height="16"
      viewBox="0 0 21 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1429 1H2.42857C1.63959 1 1 1.63959 1 2.42857V13.1429C1 13.9318 1.63959 14.5714 2.42857 14.5714H18.1429C18.9318 14.5714 19.5714 13.9318 19.5714 13.1429V2.42857C19.5714 1.63959 18.9318 1 18.1429 1Z"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6H19.5714"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8574 11H16.0003"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default InstagramIcon;

import styled from 'styled-components';

export const Container = styled.div`
  display: ${props => (props.loading && 'flex') || 'none'};
  /* display: flex; */
  position: absolute;
  width: 100%;
  height: 100vh;
  flex: 1;
  align-items: center;
  justify-content: center;
  background: #fff;
  z-index: 9999;

  img {
    width: 50px;
    height: 50px;
    opacity: 50%;
  }
`;

/* eslint-disable no-nested-ternary */
import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import api from '../../services/api';

import {
  ContainerGeral,
  Container,
  Div,
  SpanPro,
  Input,
  DivCategory,
  StyledSelect,
  SeeMore,
  Button,
} from './styles';

import { InsertOrUpdateTagRequest } from '../../store/modules/linkPrev/actions';
import loading from '../../assets/icons/loading-white.gif';

import DeleteModal from '../DeleteModal';

export default function TagModal({
  showModal,
  Modal,
  TagId,
  updateTagsComponent,
  handleClickDelete,
  pro,
  userId,
}) {
  const dispatch = useDispatch();
  const [tagName, setTagName] = useState();
  const [allTags, setAllTags] = useState([]);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [seeMore, setSeeMore] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  // const isLoading = useSelector(
  //   state => state.linkPrev.loadingCreateOrUpdateTag
  // );

  const handleModal = useCallback(
    confirm => {
      if (confirm) {
        setTimeout(() => {
          setTagName('');
          Modal(false);
          updateTagsComponent((tags && tags.id) || null);
          setIsLoading(false);

          if (TagId > 0) {
            toast.success('Alteração salva');
          } else {
            toast.success('Categoria criada');
          }
        }, 2000);
      } else {
        setTagName('');
        Modal(false);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [Modal, TagId, tags, updateTagsComponent]
  );

  useEffect(() => {
    async function loadTag() {
      if (TagId > 0) {
        await api.get(`tags/${TagId}`).then(response => {
          setTagName(response.data.tag);

          if (response.data.master_id) {
            setTags(response.data.master);
          }
        });
      } else {
        setTagName('');
        setTags([]);
      }

      await api.get('/alltagsbyuser').then(response => {
        const tagsNoAll =
          response.data &&
          response.data.filter(
            tag =>
              tag.tag !== 'Todos' && tag.id !== TagId && tag.master_id !== TagId
          );

        const result = tagsNoAll.map(tag => {
          if (tag.isDuplicated && tag.master_id) {
            return {
              ...tag,
              label: `${tag.label} (${tag.master.label})`,
            };
          }

          return tag;
        });

        setAllTags(result);
      });
    }

    loadTag();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TagId]);

  async function handleSubmit() {
    try {
      if (tagName) {
        setIsLoading(true);
        dispatch(
          InsertOrUpdateTagRequest(
            TagId,
            tagName,
            tags.length === 0 ? null : tags
          )
        );
        handleModal(true);
      } else {
        toast.error('Digite o nome da Categoria');
      }
    } catch (err) {
      toast.error('Erro ao inserir ou alterar Tag');
    }
  }

  function handleClickDeleteTag() {
    setShowDeleteModal(true);
    // handleClickDelete(TagId);
  }

  function verifyOnEnter(e) {
    if (e.key === 'Enter') {
      handleSubmit();
    }

    if (e.key === 'Escape') {
      handleModal(false);
    }
  }

  function deleteModal(modal, canceled) {
    setShowDeleteModal(modal);

    if (canceled) {
      handleClickDelete(TagId);
    }
  }

  return (
    <ContainerGeral>
      <DeleteModal
        showModal={showDeleteModal}
        Modal={deleteModal}
        title="Excluir categoria?"
        body="Tem certeza que deseja excluir essa categoria?"
        textConfirmButton="Excluir categoria"
      />
      <Container showModal={showModal} onClick={() => handleModal(false)} />
      <Div showModal={showModal}>
        <div className="div-header">
          <h1>{TagId > 0 ? 'Editar categoria' : 'Adicionar categoria'}</h1>

          <div>
            {TagId > 0 && (
              <button type="button" onClick={handleClickDeleteTag}>
                Excluir
              </button>
            )}
          </div>
        </div>

        <div className="div-body">
          <span>Nome</span>
          <Input
            type="text"
            placeholder="Ex: Calçados"
            value={tagName}
            onChange={e => setTagName(e.target.value)}
            onKeyDown={verifyOnEnter}
          />

          {seeMore && (
            <DivCategory>
              <div>
                <strong>Categoria mãe</strong>
                <span>Opcional</span>
                {pro === 0 && <SpanPro>PRO</SpanPro>}
              </div>

              <span>Exemplo: “Roupas” é a categoria mãe de “Calças”</span>

              <StyledSelect
                id="selected"
                placeholder="Selecionar"
                name="tg"
                // isMulti
                options={allTags}
                value={tags}
                onChange={e => setTags(e)}
                isClearable
                disabled={pro === 0}
              />
            </DivCategory>
          )}

          <SeeMore onClick={() => setSeeMore(!seeMore)}>
            {seeMore ? 'Mostrar menos' : 'Mostrar mais'}
          </SeeMore>

          <Button onClick={handleSubmit}>
            {isLoading ? <img src={loading} alt="" /> : 'Confirmar'}
            {/* {isLoading ? (
              <img src={loading} alt="" />
            ) : TagId > 0 ? (
              'Alterar'
            ) : (
              'Confirmar'
            )} */}
          </Button>
        </div>
      </Div>
    </ContainerGeral>
  );
}

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ModalActive = styled.div`
  display: flex;
  align-items: center;

  .container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #000;
    top: 0;
    left: 0;
    z-index: 501;
    opacity: 0.15;
  }

  .div {
    position: fixed;
    width: 366px;
    height: 479px;
    background: #ffffff;
    border-radius: 12px;
    top: calc(50vh - (479px / 2));
    left: calc(50vw - (366px / 2));
    padding: 16px;
    z-index: 502;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > img {
      margin-bottom: 22px;
    }

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.25;
      text-align: center;
      color: #0e0e0e;
      margin-bottom: 8px;
    }

    > span {
      font-size: 13px;
      line-height: 1.25;
      text-align: center;
      color: #838383;
      margin-bottom: 40px;
    }

    > button {
      width: 100%;
      height: 50px;
      background: #2b8ff5;
      border-radius: 8px;
      color: #fff;
      border: none;
      font-weight: 500;
      font-size: 14px;
      color: #ffffff;
    }
  }
`;

export const ModalCancelIntegration = styled.div`
  display: flex;
  align-items: center;

  .container {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: #000;
    top: 0;
    left: 0;
    z-index: 501;
    opacity: 0.15;
  }

  .div {
    position: fixed;
    width: 366px;
    height: 185;
    background: #ffffff;
    border-radius: 12px;
    top: calc(50vh - (185px / 2));
    left: calc(50vw - (366px / 2));
    padding-top: 16px;
    z-index: 502;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.25;
      text-align: center;
      color: #0e0e0e;
      margin-bottom: 4px;
    }

    .btn-cancel {
      color: #ff303c;
    }

    > span {
      font-size: 13px;
      line-height: 1.25;
      text-align: center;
      color: #838383;
      margin-bottom: 16px;
      max-width: 260px;
    }

    > button {
      width: 100%;
      height: 45px;
      border: none;
      background: none;
      border-top: 0.5px solid #d8dee5;
      font-size: 14px;
      color: #838383;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 32px;
`;

export const RedLabel = styled.div`
  display: flex;
  background: rgba(255, 48, 60, 0.06);
  border-radius: 2px;
  padding: 16px;
  margin-bottom: 24px;

  > span {
    font-size: 13px;
    line-height: 1.25;
    color: #ff303c;

    > a {
      font-weight: 500;
      text-decoration: none;
      color: #ff303c;
    }
  }
`;

export const DivTax = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  .blue {
    color: #0d85ff;
    margin-left: 2px;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 15px;
    line-height: 1.25;
    color: #101010;
  }

  > span {
    & + span {
      margin-top: 12px;
    }

    font-size: 13px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    color: #838383;
  }
`;

export const DivInstallments = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25;
    color: #101010;
  }

  > span {
    margin-top: 4px;
    font-size: 13px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    color: #838383;
    /* margin-bottom: 12px; */
  }

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background: none;
    color: #0d85ff;
    font-size: 14px;
    font-weight: 500;
    text-decoration: none;
    margin-top: 12px;
    width: max-content;

    > svg {
      margin-left: 8px;
    }
  }

  .selected {
    margin-top: 12px;
    margin-bottom: 8px;
  }
`;

export const DivOtherOptions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25;
    color: #101010;
  }

  > div {
    width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    display: flex;
    margin-top: 12px;

    &::-webkit-scrollbar {
      display: none !important;
      width: 0 !important;
      height: 0 !important;
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      display: none !important;
      background-color: transparent;
    }

    > a {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      background: #efefef;
      height: 42px;
      color: #101010;
      font-size: 14px;
      font-weight: 500;
      text-decoration: none;
      padding: 0 16px;

      & + a {
        margin-left: 8px;
      }
    }
  }
`;

export const DivPixDiscount = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4px;
    font-size: 15px;
    line-height: 1.25;
    color: #101010;
  }

  > span {
    font-size: 13px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    color: #838383;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  /* justify-content: space-between; */
  /* align-items: center; */
  height: 60px;
  background: #fafafa;
  border-radius: 8px;
  margin-bottom: 8px;
  padding: 0 20px;
  opacity: ${props => (props.disabled ? '0.5' : '1')};

  > div {
    display: flex;
    align-items: center;

    > span {
      color: #131313;
      font-weight: 500;
      font-size: 13px;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 30px;
      margin-right: 5px;
    }
  }

  > span {
    font-size: 13px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    text-align: right;
    color: #838383;
    margin-top: 4px;
  }
`;

export const DivIntegration = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => (props.first ? '0' : '32px')};

  > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    height: 50px;
    border: none;
    background: #2b8ff5;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
  }

  .remove-token {
    background: rgba(255, 48, 60, 0.06);
    color: #ff303c;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25;
    color: #101010;
    margin-bottom: 4px;
  }

  > span {
    font-size: 13px;
    line-height: 1.25;
    color: #838383;
    margin-bottom: 12px;

    > a {
      color: #2b8ff5;
      text-decoration: none;
      font-weight: 600;
    }
  }

  > input {
    /* height: 45px; */
    border: 0.5px solid #f9f9f9;
    box-sizing: border-box;
    padding: 12px;
    background: #f9f9f9;
    border-radius: 8px;
    font-size: 13px;
    color: #101010;

    &::placeholder {
      color: #bfbfbf;
    }
  }

  > button {
    width: 100%;
    height: 50px;
    border: none;
    background: #2b8ff5;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    margin-top: 8px;
  }

  > div {
    display: flex;
    justify-content: end;
    margin-top: 8px;

    > span {
      font-weight: 500;
      font-size: 13px;
      line-height: 1.25;
      color: #2b8ff5;
    }

    > img {
      margin-left: 6px;
    }
  }

  .error {
    > span {
      color: #ff303c;
    }
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;

  .blue {
    margin-bottom: 16px;
    color: #0d85ff;
  }

  > button {
    background: none;
    color: #2b8ff5;
    border: none;
    font-weight: 500;
    font-size: 13px;
    margin-top: 8px;
  }

  > span {
    color: #838383;
    font-size: 12px;
    font-weight: 400;
  }
`;

export const CardPayType = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  height: 65px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 8px;
  background: ${props => props.background || 'none'};
  opacity: ${props => (props.enabled ? 1 : 0.5)};

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    strong {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      display: flex;
      align-items: center;
      color: #000000;
      height: 20px;
    }

    > div {
      display: flex;
      align-items: center;
    }

    > span {
      font-size: 13px;
      line-height: 1.25;
      color: #838383;
      margin-top: 4px;
    }
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  > a {
    padding: 9px 28px;
    background: #2b8ff5;
    border: 1px solid #2b8ff5;
    box-sizing: border-box;
    border-radius: 6px;
    text-decoration: none;
    color: #fff;
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

export const SpanStatus = styled.span`
  font-style: normal !important;
  line-height: 1.25 !important;
  display: flex !important;
  align-items: center !important;
  font-weight: ${props => props.fontWeight || 'normal'} !important;
  font-size: ${props => props.fontSize || '11px'} !important;
  color: ${props => props.color} !important;
  border-radius: 4px;
  background: rgba(13, 133, 255, 0.08);
  padding: 0 8px;
  height: 20px;
  margin-left: 6px;
`;

export const DivLinks = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  margin-top: 32px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 1.25;
    color: #000000;
    margin-bottom: 12px;
  }

  > a {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    color: #2b8ff5;
    margin-bottom: 8px;
    text-decoration: none;

    > img {
      margin-left: 6px;
    }
  }
`;

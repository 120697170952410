import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 85px;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

export const StepInfo = styled.p`
  color: #0d85ff;
  font-size: 14px;
  font-weight: 500;
`;

export const StepBarsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;
`;

export const StepBar = styled.div`
  width: 20%;
  height: 4px;
  background-color: ${({ active }) => (active ? '#0d85ff' : '#EFEFEF')};
  /* background-color: yellow; */
  border-radius: 23px;
`;

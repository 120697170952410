import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  min-height: 100vh;
  padding-bottom: 70px;
`;

export const DivBody = styled.div`
  margin-top: 55px;
  padding: 0 16px;
  margin-bottom: 24px;
`;

export const DivItem = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0;
  align-items: center;
  justify-content: space-between;

  ${props =>
    !props.first &&
    css`
      border-top: 0.5px solid #d8dee5;
      /* margin-top: 32px; */
    `}

  ${props =>
    props.last &&
    css`
      border-bottom: 0.5px solid #d8dee5;
      /* margin-top: 32px; */
    `}

  > h2 {
    color: #000;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  > div {
    display: flex;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }
`;

export const DivContentItem = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    line-height: 1.5;
    margin-bottom: 12px;
  }

  & + div {
    margin-top: 24px;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  margin-bottom: 12px;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: #000000;
    line-height: 1.5;
  }
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #0d85ff;
  color: #0d85ff;
  height: fit-content;
  line-height: 1.5;
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const DivImages = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 24px;
    }

    > div {
      height: 82px;
    }

    > span {
      font-size: 13px;
      color: #9d9d9d;
      line-height: 1.5;
    }
  }
`;

export const DivButtons = styled.div`
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;

    & + div {
      margin-left: 19px;
    }
  }
`;

export const DivColorButton = styled.div`
  display: flex;
  width: 82px;
  height: 82px;
  padding: 5px;
  border-radius: 50%;
  border: ${props =>
    props.active ? '2px solid #0D85FF' : '2px solid #FFFFFF'};

  > div {
    width: 100%;
    height: 100%;
    background: ${props => props.background};
    border-radius: 50%;
    border: solid 0.5px ${props => props.borderColor || props.background};
  }
`;

export const Text = styled.span`
  font-size: 13px;
  line-height: 1.5;
  color: #838383;
`;

export const DivFonts = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }
`;

export const BuyButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 148px; */
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;
  padding: 0 24px;



  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    /* ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === 'left' &&
          '1px solid #0d85ff'};
      `} */

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: none;
      /* border-left: ${props.selectedFontWeigth === 'middle' &&
        '1px solid #0d85ff'}; */
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;
      `}
`;

export const Font = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;

  & + div {
    margin-left: 8px;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #d8dee5;
    border-radius: 8px;
    width: 80px;
    height: 50px;
    font-family: ${props => props.fontFamily};
    font-weight: ${props => props.fontWeight};

    ${props =>
      props.active &&
      css`
        border: 1.5px solid #0d85ff;
        color: #0d85ff;
      `}
  }

  > span {
    font-size: 13px;
    color: ${props => (props.active ? '#0d85ff' : '#9d9d9d')};
    line-height: 1.5;
    text-align: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const FontWeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      color: #0d85ff;
    `}

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === '500' &&
          '1px solid #0d85ff'};
      `}

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: ${props.selectedFontWeigth === '600' && '1px solid #0d85ff'};
    `}
`;

export const DivStyleButtons = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;

    & + div {
      margin-left: 20px;
    }

    > span {
      font-size: 13px;
      line-height: 1.5;
      text-align: center;
      color: #9d9d9d;
      margin-top: 8px;
    }
  }
`;

export const InputModel = styled.div`
  width: 100px;
  height: 32px;
  border: ${props =>
    props.selected ? '2px solid #0d85ff' : '2px solid #e0e0e0'};
  border-radius: ${props => props.borderRadius || 'none'};

  & + div {
    margin-left: 20px;
  }
`;

import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 40px 40px 80px 40px;
  border-radius: 16px;

  > h1 {
    margin-bottom: 40px;
  }

  > div {
    & + div {
      margin-top: 24px;
    }
  }

  ${props =>
    props.hasActiveModal &&
    css`
      position: fixed;
      left: 20%;
      right: 0;
    `}

  .label {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }
`;

export const DivTitulo = styled.div`
  width: 600px;
  margin-bottom: 10px;

  > span {
    font-weight: normal;
    font-size: 15px;
    color: #000;
    font-weight: 500;
  }
`;

export const DivTab = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* height: 90px; */
  padding: 32px;
  cursor: pointer;

  > h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: #000000;
  }
`;

export const Tab = styled.div`
  border: 0.5px solid #efefef;
  border-radius: 20px;
  /* padding: 32px 0; */
  max-width: 900px;
`;

export const ContainerTab = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 100px;
  padding: 0 32px 32px 32px;
`;

export const DivContainerStyle = styled.div`
  display: flex;
  flex-direction: column;

  ${props =>
    props.disabled &&
    css`
      opacity: 50%;
      pointer-events: none;
    `}
`;

export const DivItem = styled.div`
  display: flex;
  width: 100%;
  /* padding: 24px 0; */
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  ${props =>
    props.first &&
    css`
      padding-top: 32px;
    `}

  > div {
    display: flex;
    align-items: center;

    > img {
      cursor: pointer;
    }
  }
`;

export const DivContentItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || '0 0 32px 0'};

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 50%;
    `}

  > div {
    /* & + div { */
    margin-top: 24px;
    /* } */
  }
`;

export const DivBorder = styled.div`
  border-radius: 12px;
  border: 0.5px solid #d8dee5;
  padding: 24px 16px;
  margin-top: 8px;

  ${props =>
    props.first &&
    css`
      margin-top: 24px;
    `}
`;

export const DivStyleButtons = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    & + div {
      margin-left: 20px;
    }

    > span {
      font-size: 13px;
      line-height: 1.5;
      text-align: center;
      color: #9d9d9d;
      margin-top: 8px;
    }
  }
`;

export const InputModel = styled.div`
  width: 100px;
  height: 32px;
  border: ${props =>
    props.selected ? '2px solid #0d85ff' : '2px solid #e0e0e0'};
  border-radius: ${props => props.borderRadius || 'none'};

  & + div {
    margin-left: 20px;
  }
`;

export const DivFonts = styled.div`
  display: flex;
  flex-wrap: wrap;
  white-space: nowrap;
  width: 834px;

  > div {
    & + div {
      margin-left: 8px;
    }

    &:nth-of-type(10n) {
      margin-left: 0;
      margin-top: 8px;
    }
  }
`;

export const BuyButtonStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 148px; */
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;
  padding: 0 24px;
  cursor: pointer;

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    /* ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === 'left' &&
          '1px solid #0d85ff'};
      `} */

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: none;
      /* border-left: ${props.selectedFontWeigth === 'middle' &&
        '1px solid #0d85ff'}; */
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;
      `}
`;

export const FontWeight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 84px;
  height: 40px;
  border: 1px solid #9d9d9d;
  font-size: 13px;
  color: #9d9d9d;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      border: 1px solid #0d85ff;
      color: #0d85ff;
    `}

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

    ${props =>
      props.middle &&
      css`
        border-right: none;
        border-left: ${props.selectedFontWeigth === '500' &&
          '1px solid #0d85ff'};
      `}

  ${props =>
    props.right &&
    css`
      border-radius: 0px 8px 8px 0px;
      border-left: ${props.selectedFontWeigth === '600' && '1px solid #0d85ff'};
    `}
`;

export const Font = styled.div`
  display: flex;
  flex-direction: column;
  width: 80px;
  margin-right: 8px;
  cursor: pointer;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #d8dee5;
    border-radius: 8px;
    width: 80px;
    height: 50px;
    font-family: ${props => props.fontFamily};
    font-weight: ${props => props.fontWeight};

    ${props =>
      props.active &&
      css`
        border: 1.5px solid #0d85ff;
        color: #0d85ff;
      `}
  }

  > span {
    font-size: 13px;
    color: ${props => (props.active ? '#0d85ff' : '#9d9d9d')};
    line-height: 1.5;
    text-align: center;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.borderColor || '#0d85ff'};
  color: ${props => props.color || '#0d85ff'};
  background: ${props => props.background};
  padding: 2px 6px;
  border-radius: 4px;
  margin-left: 8px;
  font-size: 12px;
  font-weight: 500;
`;

export const DivProfile = styled.div`
  display: flex;
  width: 100%;
  max-width: 470px;
  background: #fff;
  flex-direction: column;
  margin-top: 32px;
  /* padding: 0 32px; */

  > input {
    height: 42px;
    background: #f9f9f9;
    padding: 0 12px;
    font-size: 13px;
    /* margin-top: 12px; */
    margin-bottom: 16px;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 8px;
    color: #101010;

    &:focus {
      /* border-bottom: 0.5px solid #010101; */
    }

    &::placeholder {
      color: #d1d1d1;
    }
  }

  .input-user {
    text-transform: lowercase;
  }

  .div-categorias {
    display: flex;
    flex-direction: column;
    height: 80px;
  }

  .div-link {
    display: flex;
    align-items: center;
    margin: auto;

    > span {
      font-weight: normal;
      font-size: 13px;
      color: #2b8ff5;
      margin-bottom: 0;
      margin-right: 4px;
    }
  }

  span {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }
`;

export const DivDescription = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  flex-direction: column;
  margin-top: 32px;
  /* margin-bottom: 16px; */

  textarea {
    background: #f9f9f9;
    padding: 12px;
    font-size: 13px;
    margin-bottom: 5px;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 8px;
    color: #101010;

    &:focus {
      /* border-bottom: 0.5px solid #010101; */
    }

    &::placeholder {
      color: #d1d1d1;
    }
  }
`;

export const DivUsername = styled.div`
  display: flex;
  width: 100%;
  max-width: 470px;
  margin-top: 8px;

  > div {
    height: 42px;
    display: flex;
    flex-direction: center;
    align-items: center;
    background: #efefef;
    border-radius: 0px 8px 8px 0px;
    padding: 0 12px;

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      color: #101010;
      margin: 0;
    }
  }

  > input {
    height: 42px;
    width: 100%;
    background: #f9f9f9;
    padding: 0 8px;
    font-size: 13px;
    margin-bottom: 16px;
    border: 1px solid #f9f9f9;
    box-sizing: border-box;
    border-radius: 8px 0px 0px 8px;
    color: #101010;

    &:focus {
      /* border-bottom: 0.5px solid #010101; */
    }

    &::placeholder {
      color: #d1d1d1;
    }
  }
`;

export const DescriptionSize = styled.span`
  display: flex;
  justify-content: flex-end;
  color: #bfbfbf !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
`;

export const DivAvatarFormat = styled.div`
  /* margin: 0 16px; */
  margin-top: 12px;

  .span-description {
    display: ${props => (props.enable ? 'flex' : 'none')};
    font-size: 13px;
    color: #838383;
  }
  .div-avatar-format {
    border-radius: 12px;
    > strong {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }
    > span {
      font-size: 13px;
      color: #838383;
    }
  }
  .div-images {
    display: flex;
    justify-content: space-between;
  }
`;

export const DivImages = styled.div`
  display: flex;
  /* margin-top: 8px; */

  .selected {
    border: 1px solid #101010;
  }

  > img {
    width: 40px;
    height: 40px;
    align-self: center;
  }
`;

export const DivFImages = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;

  .selected {
    border: 1px solid #101010;
  }
`;

export const DivBanners = styled.div`
  opacity: ${props => (props.enable ? '100%' : '50%')};
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  border: 0.5px solid #d8dee5;
  border-radius: 12px;
  padding: 16px;
  margin-top: 8px;

  .span-format {
    font-size: 13px;
    color: #000000;
    margin-bottom: 6px;
  }

  .span-images {
    font-size: 13px;
    color: #000000;
    margin-bottom: 2px;
  }

  .span-description {
    font-size: 13px;
    color: #838383;
    margin-bottom: 6px;
  }
`;

export const Quadrado = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 50%;
  width: ${props => props.width || '48px'};
  height: ${props => props.height || '48px'};
  color: transparent;
  margin-right: 12px;
`;

export const QuadradoBanner = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${props => props.width || '48px'};
  height: ${props => props.height || '48px'};
  color: transparent;
  margin-right: 12px;
`;

export const Retangulo = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${props => props.width || '120px'};
  height: ${props => props.height || '48px'};
  color: transparent;
`;

export const RetanguloBanner = styled.div`
  border: 1px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 8px;
  width: ${props => props.width || '90px'};
  height: ${props => props.height || '48px'};
  color: transparent;
`;

export const DivImagesBanners = styled.div`
  display: flex;
  margin-bottom: 16px;

  .selected {
    border: 1px solid #101010;
  }
`;

export const DivDevice = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  margin-bottom: 12px;

  > div {
    display: flex;
    margin-bottom: 12px;

    > div {
      display: flex;
      align-items: center;
      margin-right: 16px;
      cursor: pointer;

      > span {
        margin-left: 6px;
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #9d9d9d;
      }

      .selected {
        color: #2b8ff5;
      }
    }
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
  }
`;

export const DivBannerImages = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  opacity: ${props => (props.disabled ? '0.4' : '1')};

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 1px dashed #a5a9b1; */
    box-sizing: border-box;
    box-shadow: none;
    min-width: ${props => (props.desktop ? '190px' : '150px')};
    height: 80px;
    margin-right: 10px;
    background: #f9f9f9;
    border-radius: 10px;
    border: none;
  }
`;

export const LabelImage = styled.label`
  display: ${props => (props.hide ? 'none' : 'flex')};
  justify-content: center;
  align-items: center;
`;

export const DivAvatarLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  > span {
    margin-bottom: 8px !important;
    font-size: 13px;
    color: #838383;
  }

  > img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
`;

export const DivAvatar = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: flex-start;
  position: relative;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: max-content;
    height: 40px;
    padding: 0 16px;
    /* margin-right: 8px; */
    /* border: 1px dashed #9d9d9d; */
    box-sizing: border-box;
    border-radius: 8px;
    background: #efefef;
    border: none;
    font-size: 13px;
    font-weight: 500;

    > svg {
      margin-right: 8px;
    }
  }
`;

export const DivLoading = styled.div``;

export const DivLogo = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: center; */
  background: rgba(255, 255, 255, 0.25);
  /* border: 0.5px solid rgba(133, 133, 133, 0.25); */
  border-radius: 200px;
  margin-top: 8px;

  .selected-image {
    height: 52px;
    width: 52px;
  }

  ${props =>
    !props.hasImage &&
    css`
      box-sizing: border-box;
    `};

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }

  ${props =>
    props.horizontal &&
    css`
      border-radius: 8px;

      .selected-image {
        height: 47px;
        width: 113px;
      }

      img {
        border-radius: 8px;
        object-fit: contain;
        background: #fafafa;
      }
    `};

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    /* position: absolute; */
    /* top: -7px;
    right: -9px; */
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: none;
    /* box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.13); */
    background: none;
    margin-left: 8px;

    > img {
      padding: 6px;
    }
  }
`;

export const DivAvatarRight = styled.div`
  opacity: ${props => (props.enable ? '100%' : '50%')};

  > span {
    font-size: 13px;
    color: #000000;
  }

  /* .span-pro {
    font-size: 10px;
    color: #2b8ff5;
  } */
`;

export const DivSpanProPlan = styled.div`
  display: flex;
  align-items: center;
  height: auto;

  > img {
    margin-right: 7px;
  }

  > span {
    font-size: 10px;
    line-height: 15px;
    color: #2b8ff5;
  }

  > strong {
    font-weight: 500;
    font-size: 14px;
    color: #000000;
    margin-right: 16px;
  }
`;

export const DivTheme = styled.div`
  display: flex;
  /* border-radius: 10px; */
  width: 100%;
  background: none;
  flex-direction: column;
  padding: 0 16px;

  > div {
    width: 100%;
    font-size: 13px;
    margin-bottom: 26px;

    > span {
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }
  }
`;

export const DivHeaderType = styled.div`
  width: 100%;
  height: 100px;
  background: #fff;
  border-radius: 15px;
  border: 1px solid #d8dee5;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;

  > span {
    color: #fff;
  }

  input {
    width: 90px;
    height: 30px;
    background: #f7f8f9;
    border-radius: 10px;
    border: none;
    padding: 0 15px;
    font-size: 13px;
    color: #a5a9b1;
  }

  > div {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: center;
  }
`;

export const Icon = styled.img`
  opacity: ${props => (props.disable ? '50%' : '100%')};
  width: 40px;
  height: 40px;
  /* padding: 10px; */
  /* margin: 0 10px; */
`;

export const DivColors = styled.div`
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  /* border: 1px solid #d8dee5;
  border-radius: 12px; */
  padding: ${props => props.padding || '12px 0'};
  /* margin: 0 16px; */

  ${props =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 50%;
    `}

  > div {
    display: flex;
    align-items: center;

    > span {
      margin-left: 5px;
      margin-bottom: 6px;
      font-size: 13px;
      color: #000000;
    }

    input {
      display: flex;
      width: 85px;
      height: 32px;
      background: #fafafa;
      border-radius: 8px;
      border: none;
      font-size: 13px;
      color: #838383;
      text-align: center;
    }
  }
`;

export const StyledSelect = styled(CreatableSelect)`
  width: 100%;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 7px;
  font-size: 13px;

  .css-yk16xz-control {
    border-radius: 8px;
    border-color: #d4d8dd;
  }

  .css-1pahdxg-control {
    border-radius: 8px;
    border-color: #000 !important;
    outline: none !important;
    border: none;
  }

  .css-1rhbuit-multiValue {
    border-radius: 4px;
    background-color: #aab0ba;
    color: #fff;
  }

  .css-1alnv5e:hover {
    border-radius: 10px;
  }

  .css-xb97g8:hover {
    background-color: #d4d8dd;
    color: #fff;
    border-radius: 50%;
    cursor: pointer;
  }

  .css-12jo7m5 {
    color: #fff;
  }

  .css-b8ldur-Input {
    height: 42px;
    margin-top: -25px;
  }
`;

export const DivEditar = styled.div``;

export const DivShare = styled.div`
  display: ${props => (props.hidden ? 'none' : 'flex')};
  /* border-radius: 0 0 10px 10px; */
  width: 100%;
  background: #fff;
  flex-direction: column;
  /* margin-bottom: 24px; */
  padding-top: ${props => (props.first ? '0' : '32px')};

  > strong {
    margin-bottom: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #9d9d9d;
    margin-bottom: 12px;
  }
`;

export const DivSubTab = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  /* height: 75px; */
  padding: 0 0 16px 0;
  /* border-top: 0.5px solid #D8DEE5; */
  /* margin-top: ${props => (props.first ? '50px' : '0')}; */
  /* border-top: ${props => (props.first ? 'none' : '0.5px solid #D8DEE5;')}; */

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    > h4 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      color: #000000;
    }
  }
`;

export const ButtonAdd = styled.button`
  display: flex;
  font-weight: 500;
  font-size: 13px;
  color: #2b8ff5;
  background: none;
  border: none;
  margin-top: 12px;
`;

export const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  /* padding: 0 20px; */
  margin-top: 32px;
  /* padding-bottom: ${props => (props.last ? '40px' : '20px')}; */

  .div-endereco {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;

      input {
        & + input {
          margin-left: 24px;
        }
      }
    }
  }

  .div-buttons-payment-methods {
    display: flex;
    flex-wrap: wrap;
    margin-top: 15px;

    button {
      border: 0.5px solid #d8dee5;
      background: #fff;
      padding: 8px 18px;
      box-sizing: border-box;
      border-radius: 8px;
      margin-right: 8px;
      margin-bottom: 8px;
    }

    .selected {
      background: #0d0a0a;
      border: 0.5px solid #0d0a0a;
      color: #fff;
    }
  }

  > div {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }

    strong {
      font-weight: 500;
      font-size: 15px;
      color: #000;
    }

    span {
      margin-top: 13px;
      font-weight: normal;
      font-size: 13px;
      color: #838383;
    }

    input {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }

    textarea {
      width: 100%;
      margin-top: 10px;
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: 0.5px solid #d8dee5;
      margin-top: 11px;
      padding: 4px 0;

      &:focus {
        border-bottom: 0.5px solid #000;
      }
    }
  }
`;

export const DivCheckBox = styled.div`
  display: flex;
  width: 100%;
  max-width: 470px;
  justify-content: space-between;
  margin-top: 32px;

  > span {
    font-weight: 500;
    font-size: 13px;
    color: #000000;
  }
`;

export const DivInput = styled.div`
  display: flex;
  width: 100%;
  max-width: 470px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin};
  background: #fff;
  position: relative;

  /* &:last-child {
    padding-bottom: 100px;
  } */

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    /* margin-bottom: 12px; */
  }

  .error {
    color: #ff303c;
    margin-top: 8px;
  }

  .span-black {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    color: #000000;
    margin: 16px 0 8px 0;
  }

  #banner-image {
    opacity: 0.4;
  }
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${props => props.margin || '0 0 8px 0'};
  /* margin-bottom: 8px; */

  > div {
    display: flex;
    line-height: 1.25;
  }

  > span {
    color: #838383;
    font-size: 13px;
    margin-top: 4px;

    > a {
      color: #2b8ff5;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

export const DivFontWeight = styled.div`
  display: flex;
`;

export const DivModel = styled.div`
  display: flex;
  flex-direction: column;
  /* opacity: ${props => (!props.isPro ? 0.5 : 1)}; */
`;

export const DivStoreType = styled.div`
  display: flex;
  margin-top: 12px;

  > div {
    & + div {
      margin-left: 8px;
    }
  }
`;

export const ButtonStoreType = styled.div`
  color: #838383;
  border: 1px solid #d8dee5;
  /* box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.07); */
  padding: 12px 24px;
  cursor: pointer;

  ${props =>
    props.left &&
    css`
      border-radius: 8px 0 0 8px;
      border-right: none;
    `}

  ${props =>
    props.right &&
    css`
      border-radius: 0 8px 8px 0;
      border-left: none;
    `}

    ${props =>
      props.active &&
      css`
        border: 1px solid #0d85ff;
        color: #0d85ff;
      `}
`;

export const DivImageFormats = styled.div`
  display: flex;

  .selected {
    color: #0d85ff;
  }

  > div {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    & + div {
      margin-left: 24px;
    }

    > div {
      height: 82px;
    }

    > span {
      font-size: 13px;
      color: #9d9d9d;
      line-height: 1.5;
    }
  }
`;

export const DivEditPage = styled.div`
  display: flex;
  margin-top: 12px;
`;

export const Page = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  border: 0.5px solid #d8dee5;
  border-radius: 8px;
  width: 100%;
  height: 80px;
  padding: 0 16px;

  > strong {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
    color: #000000;
    text-align: center;
  }

  > span {
    font-size: 13px;
    line-height: 1.5;
    color: #838383;
    text-align: center;
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #0d85ff;
    border-radius: 8px;
    height: 44px;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    border: none;
    width: 160px;

    > svg {
      margin-right: 6px;
    }
  }
`;

export const DivThemes = styled.div`
  margin-top: 12px;
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    display: none !important;
    background-color: transparent;
  }

  > img {
    margin: auto;
    width: 60px;
    height: 60px;
  }
`;

export const Theme = styled.div`
  /* height: 210px; */
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  background: #fafafa;
  padding: 20px 16px;
  border: 2px solid #fafafa;
  border-radius: 12px;

  ${props =>
    props.active &&
    css`
      border: 2px solid #0d85ff;
      background: #ffffff;
    `}

  & + div {
    margin-left: 12px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 1.5;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      border: solid 1px #d8dee5;
      width: 19px;
      height: 19px;
      border-radius: 50%;

      ${props =>
        props.active &&
        css`
          border: 0.5px solid #0d85ff;
          background: #0d85ff;
        `}
    }
  }

  > span {
    font-weight: 400;
    font-size: 13px;
    color: #838383;
    margin-top: 4px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.5;
  }

  > a {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 13px;
    color: #838383;
    text-decoration: none;
    margin-top: 22px;

    > svg {
      margin-left: 6px;
    }
  }

  > button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: ${props =>
      props.active ? 'rgba(13, 133, 255, 0.06)' : '#0d85ff'};

    border-radius: 8px;
    height: 48px;
    font-weight: 500;
    font-size: 13px;
    color: ${props => (props.active ? '#0D85FF' : '#ffffff')};
    border: none;
    margin-top: 16px;

    > img {
      margin-right: 6px;
    }

    &:disabled {
      background: #cdcdcd;
      color: #ffffff;
    }
  }
`;

export const DivTitleColors = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > div {
    position: absolute;
    border: 1.5px solid rgb(43, 143, 245);
    border-radius: 4px;
    padding: 2px 6px;
    color: rgb(43, 143, 245);
    font-size: 13px;
    left: 96px;
    top: -3px;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #000000;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    margin-top: 4px;
    color: #838383;

    > a {
      color: #2b8ff5;
      text-decoration: none;
      font-weight: 600;
    }
  }
`;

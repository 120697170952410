import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
import { device } from '../../../config/device';

export const Div = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  background: #fff;
  flex-direction: column;

  h1 {
    font-size: 16px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    font-weight: 400;
  }
`;

export const DivBody = styled.div`
  width: 100%;
  height: 100%;
  top: 54px;
  margin-top: 74px;
  display: flex;
  flex-direction: column;
  padding: 0 4vw;

  span {
    color: #000;
    font-weight: 600;
    margin-bottom: 10px;
  }

  textarea {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 0.5px solid #d8dee5;
    font-size: 13px;

    ::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      font-size: 13px;
      color: #d1d1d1;
    }
    ::-moz-placeholder {
      /* Firefox 19+ */
      font-size: 13px;
      color: #d1d1d1;
    }
    :-ms-input-placeholder {
      /* IE 10+ */
      font-size: 13px;
      color: #d1d1d1;
    }
    :-moz-placeholder {
      /* Firefox 18- */
      font-size: 13px;
      color: #d1d1d1;
    }
  }
`;

export const DivFooter = styled.div`
  display: flex;
  width: 100%;
  background: #f7f8f9;
  padding: 0 20px;
  flex-direction: column;

  span {
    display: none;
  }
`;

export const DivButton = styled.div`
  width: 100%;
  border-style: none;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    background: #2b8ff6;
    color: #ffffff;
    border: none;
    font-size: 15px;
    height: 42px;
    width: 100%;
    box-shadow: 6px 11px 13px rgba(14, 15, 17, 0.1);
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 25px;
  background: #ffffff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 0.5px solid #d8dee5;
  box-sizing: border-box;
  margin-bottom: 20px;
  font-size: 13px;

  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: 13px;
    color: #d1d1d1;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: 13px;
    color: #d1d1d1;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: 13px;
    color: #d1d1d1;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: 13px;
    color: #d1d1d1;
  }
`;

import React from 'react';

import { Container, Div } from './styles';
import CancelIcon from '../../assets/icons/cancel-grey.svg';

function ImagePreview({
  src,
  image,
  deleteImages,
  height,
  width,
  margin,
  selected,
}) {
  async function handleClickDelete() {
    deleteImages(image);
  }

  return (
    <Div height={height} width={width} margin={margin} selected={selected}>
      {selected && <div className="mask" />}
      <img src={src} className="product-image" alt="productimage" />
      <img
        src={CancelIcon}
        className="cancel"
        alt="cancel"
        onClick={handleClickDelete}
      />
      {/* <Container src={src} /> */}
    </Div>
  );
}

export default ImagePreview;

/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import ResponsiveHeader from '../../../components/HeaderMobile';

import { Container, Body } from './styles';
import OrderDetailsComponent from './Components/OrderDetailsComponent';
import SubTotal from './Components/SubTotal';
import OrderInformation from './Components/OrderInformation';
import OrderStatus from './Components/OrderStatus';

export function OrderDetails() {
  return (
    <Container>
      <ResponsiveHeader title="Detalhes do pedido" url="/paymentmethods" />
      <Body>
        <OrderDetailsComponent />
        <SubTotal />
        <OrderInformation />
        <OrderStatus />
      </Body>
    </Container>
  );
}

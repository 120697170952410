import React from 'react';
// import Dashboard from '../Dashboard';
import { IntercomProvider } from 'react-use-intercom';
import DashboardResponsive from '../Responsive/Dashboard';
import DashboardDesktop from '../Desktop/Dashboard';

function Dash({ location }) {
  return (
    <IntercomProvider appId="wkeayfjy">
      {window.innerWidth > 720 ? (
        <DashboardDesktop />
      ) : (
        <DashboardResponsive location={location} />
      )}
    </IntercomProvider>
  );
}

export default Dash;

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 24px;

    > h2 {
      color: #000;
      font-size: 24px;
      font-weight: 600;
      margin-top: 24px;
    }

    > strong {
      color: #000000;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      margin-top: 8px;
      text-align: center;
    }

    > a {
      background: #0d85ff;
      border-radius: 8px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: 100%;
      max-width: 360px;
      height: 50px;
      font-weight: 600;
      font-size: 14px;
      margin-top: 24px;
    }
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 810px !important;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 100px;
`;

export const DivInitialLoader = styled.div`
  width: 810px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 100px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 32px;
`;

export const DivText = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }

  > span {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    width: ${props => props.spanWidth || '100%'};
  }

  > label {
    position: absolute;
    top: 0;
    right: 0;
  }
`;

export const DivCards = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  margin-bottom: 24px;

  > button {
    background: none;
    color: #2b8ff5;
    border: none;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-top: 8px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 0.5px solid #d8dee5;
  border-radius: 12px;
  height: 62px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 8px;
  cursor: pointer;
  background: ${props => props.background || 'none'};

  .left {
    display: flex;
    position: relative;
    width: 100%;
    flex-direction: column;

    .label-pro {
      display: flex;
      margin-left: 8px;
      padding: 2px 6px;
      border: 1px solid #2b8ff5;
      border-radius: 4px;

      > span {
        font-weight: 500;
        font-size: 11px;
        line-height: 16px;
        color: #2b8ff5 !important;
      }
    }

    span {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      display: flex;
      align-items: center;
      color: #000000;
    }

    > div {
      display: flex;
      align-items: center;
    }
  }

  .right {
    display: flex;

    > button {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      border: none;
      background: none;
    }
  }

  input:checked + .slider {
    background-color: ${props => props.switchColorActive || '#2B8FF5'};
  }
`;

export const LabelPro = styled.div`
  display: flex;
  padding: 2px 4px;
  border: 1px solid #2b8ff5;
  border-radius: 4px;
  position: absolute;
  left: 89px;

  > span {
    font-weight: 500;
    font-size: 11px !important;
    color: #2b8ff5 !important;
    line-height: 1 !important;
  }
`;

export const LabelSoon = styled.div`
  display: flex;
  padding: 2px 6px;
  border: 1px solid #9d9d9d;
  border-radius: 4px;
  margin-left: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-height: 25px;

  > span {
    font-weight: 500;
    font-size: 11px;
    line-height: 16px;
    color: #9d9d9d !important;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const SpanStatus = styled.span`
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 11px !important;
  line-height: 16px !important;
  display: flex !important;
  align-items: center !important;
  color: ${props => props.color} !important;
`;

export const DivMethod = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;

  > span {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
  }
`;

export const Radio = styled.div`
  display: flex;
  margin-bottom: 12px;

  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    background: #efefef;
    border-radius: 50%;
    margin-right: 10px;

    > div {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #efefef;
    }
  }

  .selected {
    background: #2b8ff5;

    > div {
      background: #fff;
    }
  }
`;

export const DivCep = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #000000;
    margin-bottom: 2px;
  }

  > span {
    font-size: 13px;
    line-height: 18px;
    color: #838383;
    margin-bottom: 8px;
  }

  > div {
    display: flex;
    flex-direction: column;
    width: 100%;

    > span {
      font-size: 13px;
      color: #838383;
    }
  }

  > button {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    font-weight: 500;
    font-size: 14px;
    color: #2b8ff5;
    border: none;
    background: none;
  }
`;

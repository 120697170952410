import styled from 'styled-components';
import { device } from '../../config/device';

export const Div = styled.div`
  display: flex;
  margin: ${props => props.margin || '16px 0 0 0'};
  position: relative;

  .mask {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 7px;
    border-radius: 10px;

    background: linear-gradient(
      0deg,
      rgba(13, 133, 255, 0.2),
      rgba(13, 133, 255, 0.2)
    );

    border: 2px solid #0d85ff;
  }

  .cancel {
    margin-top: -6px;
    margin-left: -10px;
    width: 18px;
    height: 18px;
    padding: 5px;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.14);
    z-index: 1;
  }

  .product-image {
    width: ${props => props.width || '45px'};
    height: ${props => props.height || '45px'};
    border-radius: 10px;
    object-fit: cover;
    border: none;
    background: none;
    outline: none;
  }
`;

export const Container = styled.div`
  min-width: 45px;
  min-height: 45px;
  border-radius: 10px;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
  margin-top: 4px;

  @media ${device.mobile} {
    margin-top: 15px;
  }
`;

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  /* height: 100vh; */
  background: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const DivHeader = styled.div`
  /* position: absolute; */
  display: flex;
  width: 100%;
  height: 64px;
  background: #fff;
  border-bottom: 0.75px solid #d8dee5;
  /* top: 0; */

  h1 {
    text-align: center;
    font-weight: 600;
    font-size: 18px;
    color: #262626;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .div-space {
    width: 15%;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
    }

    img {
      width: 10px;
      cursor: pointer;
    }
  }

  .div-text {
    width: 70%;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #000000;
    }
  }
`;

export const Div = styled.div`
  width: 100%;
  height: 100%;
  /* max-width: 480px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* background: #fafafa; */
  padding: 24px 16px;
  overflow-y: scroll;

  > strong {
    font-weight: 600;
    font-size: 18px;
    color: #262626;
    margin-bottom: 2px;
  }

  > span {
    font-size: 13px;
    color: #838383;
    text-align: center;
  }
`;

export const DivFreePlan = styled.div`
  background: #ffffff;
  border: 0.5px solid #d8dee5;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  max-width: 480px;
  /* height: 167px; */
  padding: 24px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;

  > button {
    width: 100%;
    background: #efefef;
    border-radius: 8px;
    height: 45px;
    border: none;
    font-size: 14px;
    color: #101010;
    font-weight: 500;
  }

  > span {
    font-size: 13px;
    color: #afafaf;
    text-align: center;
  }

  .span-label {
    margin-top: 16px;
    text-align: left;
    color: #838383;
  }

  > div {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    margin-bottom: 16px;

    .span-plan {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 22px;
      color: #262626;
    }

    .span-free {
      font-size: 13px;
      line-height: 19px;
      color: #9d9d9d;
    }
  }
`;

export const DivProPlan = styled.div`
  display: flex;
  width: 100%;
  max-width: 480px;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 12px;
  position: relative;
  box-shadow: 0px 8px 68px -10px rgba(0, 0, 0, 0.17);

  header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #34c780;
    border-radius: 12px 12px 0px 0px;
    height: 48px;

    > span {
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      color: #ffffff;
    }
  }

  body {
    padding: 24px 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > strong {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      color: #101010;
      margin-bottom: 1px;
      line-height: 24px;
      margin-bottom: 8px;
    }

    > span {
      font-size: 13px;
      line-height: 18px;
      color: #acacac;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }
`;

export const ButtonIsPro = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border: none;
  background: #2b8ff5;
  border-radius: 8px;
  color: #fff;
  font-size: 14px;
  position: relative;
  margin: 8px 0;

  > img {
    position: absolute;
    right: 20px;
  }

  &:disabled {
    background: #efefef;
  }
`;

export const DivPrice = styled.div`
  display: flex;
  flex-direction: column;
  /* margin-top: 4px; */
  line-height: 19px;

  > strong {
    font-size: 36px;
    line-height: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    color: #34c780;
    margin-bottom: 4px;

    > span {
      font-size: 13px;
      /* text-decoration-line: line-through; */
      color: #34c780;
    }
  }

  > span {
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    text-decoration-line: line-through;
    color: #9d9d9d;
  }
`;

export const LabelPromotion = styled.div`
  display: flex;
  position: absolute;
  top: 24px;
  right: 20px;
  background: #34c780;
  border-radius: 70px;
  padding: 4px 10px;

  > span {
    font-weight: 500;
    font-size: 13px;
    color: #ffffff;
  }
`;

export const DivItemsPro = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 20px;
  /* padding-top: 12px; */
  border-top: 0.5px solid #d8dee5;

  .breve {
    color: #bfbfbf !important;
  }

  > strong {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: #101010;
  }

  > div {
    display: flex;
    align-items: center;
    margin-top: 12px;

    > span {
      font-size: 13px;
      line-height: 19px;
      color: #2b8ff5;
      margin-left: 6px;
    }

    > div {
      display: flex;
      /* border: 1px solid #ffb525; */
      margin-left: 6px;
      /* border-radius: 4px; */
      /* padding: 0 4px; */

      > span {
        display: flex;
        align-items: center;
        color: #cfcfcf;
        font-size: 13px;
      }
    }
  }
`;

export const SpanMore = styled.span`
  font-size: 13px;
  color: #afafaf !important;
  text-align: center;
  margin-top: 12px;
`;

export const DivDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 12px !important;
  margin-bottom: 0 !important;

  > span {
    font-size: 13px;
    line-height: 222.3%;
    color: #838383;
  }
`;
